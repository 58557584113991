import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-directlink',
  templateUrl: './directlink.component.html',
  styleUrls: ['./directlink.component.scss'],
})
export class DirectlinkComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit(): void {
    this.router.navigate(['/login', { queryParamsHandling: 'preserve' }]);
  }
}
