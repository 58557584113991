import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { Config } from 'src/app/config';
import { Observable } from 'rxjs';
import { DataElement, ServiceCallInfo } from '@shared/models/common';
import { catchError, map, take } from 'rxjs/operators';
import { actionsList, AppState } from '@shared/redux/store';
import { NgRedux } from '@shared/redux/redux-compatibility.service';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  
  scaiServiceUrl: string;

  constructor(
    private ngRedux: NgRedux<AppState>,
    private http: HttpClient,
    private authService: AuthService
  ) { 
    this.scaiServiceUrl = Config.assetConfigData['scaiServiceUrl'];
  }

  getContentTypeList(): Observable<DataElement[]> {
    const url = this.scaiServiceUrl + `content-types`;

    return this.http
      .get(url, {
        headers: this.authService.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json'
      })
      .pipe(
        map((response: Array<any>) => {
          let results = response.map(el => new DataElement(el.content_type_code, el.content_type_desc));
          
          this.ngRedux.dispatch({
            type: actionsList.GET_CONTENT_TYPE_LIST,
            data: results,
          });

          return results;
        }),
        catchError((err) => {
          return this.handleError(err, url)
        })
      );
  }

  getRuleSimilarityEventList(): Observable<void> {
    const url = this.scaiServiceUrl + `rule-similarity-events`;

    return this.http
      .get(url, {
        headers: this.authService.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json'
      })
      .pipe(
        map((response: Array<any>) => {
          let revResults = response.map(el => new DataElement(el.rule_similarity_event_code, el.rule_similarity_event_desc));
          let indResults = response.map(el => new DataElement(el.rule_similarity_event_code, el.ind_similarity_event_desc));
          
          this.ngRedux.dispatch({
            type: actionsList.GET_REV_RULE_SIMILARITY_EVENT_LIST,
            data: revResults,
          });

          this.ngRedux.dispatch({
            type: actionsList.GET_IND_RULE_SIMILARITY_EVENT_LIST,
            data: indResults,
          });
        }),
        catchError((err) => {
          return this.handleError(err, url)
        })
      );
  }

  private handleError(error: any, url: string): Observable<never> {
    const info = new ServiceCallInfo(url, url, null);
    error.message = error.error.description || error.statusText;

    return this.authService.handleError(error, info);
  }

  addAlgRunHead(algRunHeadData: object): Observable<number> {
    let url = `${this.scaiServiceUrl}alg-run-heads`;
    return this.http
      .post(url, algRunHeadData, {
        headers: this.authService.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json',
      })
      .pipe(
        take(1),
        map((data: any) => {
          if (data) {
            return data.run_num;
          }
        }),
        catchError((err) => {
          return this.handleError(err, url);
        })
      );
  }
}
