import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { DataElement } from '../../models/common';
import { NgRedux } from '@redux/redux-compatibility.service';
import { AppState, actionsList } from '../../redux/store';
import { Router } from '@angular/router';
import { SharedService } from '../../services/shared.service';
import { RevService } from '../../services/rev.service';
import { Env } from '../../models/user.model';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'select-env',
  templateUrl: './select-env.component.html',
  styleUrls: ['./select-env.component.scss'],
})
export class SelectEnvComponent implements OnInit {
  @Output() onClose = new EventEmitter();
  @Output() onSelectEnv = new EventEmitter();
  displayEnvList: Env[] = [];
  searchTxt: string;
  loading: boolean;

  get envList(): Env[] {
    return this.ngRedux.getState().envList;
  }

  constructor(
    private ngRedux: NgRedux<AppState>,
    private router: Router,
    private sharedService: SharedService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.displayEnvList = this.envList;
  }

  close() {
    this.onClose.emit();
  }

  onSearchChange() {
    if (!this.searchTxt) {
      this.displayEnvList = this.envList;
    } else
      this.displayEnvList = this.envList.filter((e) =>
        e.descr.toLowerCase().includes(this.searchTxt.toLowerCase())
      );
  }

  onSelect(env: Env) {
    this.loading = true;

    this.authService.updateUserDetails(env).subscribe(
      () => {
        this.loading = false;
        this.ngRedux.dispatch({
          type: actionsList.SELECT_ENV,
          data: env,
        });

        this.ngRedux.dispatch({
          type: actionsList.GET_CATALOG_LIST,
          data: [],
        });

        this.ngRedux.dispatch({
          type: actionsList.GET_REV_DOCS,
          data: [],
        });

        this.sharedService.putCookie('env', env.id.toString(), false);
        this.sharedService.clearLastCatCookies();
        let cookieMenu = this.sharedService.getCookie('menu', false);
        console.log('cookie menu: ' + cookieMenu);
        if (cookieMenu == '' || cookieMenu == 'rev') {
          this.router.navigate([this.sharedService.routeEnum.URL_REV_DOC_LIST]);
        } else {
          this.router.navigate([this.sharedService.routeEnum.URL_CATALOG_LIST]);
        }
        this.onSelectEnv.emit();
      },
      () => (this.loading = false)
    );
  }
}
