import { DataElement, PaginationParams } from './common';

export class User {
  username: string;
  userId: number;
  userDescr: string;
  password: string;
  activeSw: boolean;
  cognitoActiveSw: boolean;
  userEnvList: UserEnv[] = [];
  lastSeen: string;
  cognitoSyncStatus: CognitoSyncStatusEnum;
  cognitoSyncError: string;
  timezoneName: string;

  original: string;

  saveOriginal() {
    this.original = JSON.stringify(this);
  }

  restoreOriginal() {
    let original = JSON.parse(this.original) as User;
    Object.keys(this).forEach((k) => {
      if (k !== 'original') this[k] = original[k];
    });
  }

  isDirty(): boolean {
    let dirty = false;

    let original = JSON.parse(this.original) as User;
    Object.keys(this).forEach((k) => {
      if (k == 'userEnvList') {
        if (JSON.stringify(this.userEnvList) !== JSON.stringify(original[k]))
          dirty = true;
      } else if (k !== 'original' && this[k] !== original[k]) dirty = true;
    });

    return dirty;
  }
}

export class UserEnv {
  env: Env;
  role: DataElement;
}

export class UserSearchParams extends PaginationParams {
  username?: string;
  env?: Env;
  activeSw?: boolean;
  exactUsername?: string;

  constructor() {
    super();
  }
}

export class Env {
  id: number;
  descr: string;
  ruleConfigTemplate: string;
  defaultSw: boolean;
  tmpS3BucketUrl: string;
  docS3Bucket: string;
  tmpS3Bucket: string;
  original: string;

  saveOriginal() {
    this.original = JSON.stringify(this);
  }

  restoreOriginal() {
    let original = JSON.parse(this.original) as User;
    Object.keys(this).forEach((k) => {
      if (k !== 'original') this[k] = original[k];
    });
  }

  isDirty(): boolean {
    let dirty = false;

    let original = JSON.parse(this.original) as User;
    Object.keys(this).forEach((k) => {
      if (k !== 'original' && this[k] !== original[k]) dirty = true;
    });

    return dirty;
  }
}

export class CognitoSyncStatusEnum {
  public static PENDING: string = 'PENDING';
  public static PROCESSING: string = 'PROCESSING';
  public static SUCCESS: string = 'SUCCESS';
  public static FAILED: string = 'FAILED';
}
