<div *ngIf="curCat">
  <ng-container *ngTemplateOutlet="filterArea"></ng-container>
  <loader *ngIf="loading"></loader>

  <ui-table
    *ngIf="data"
    [isWrapped]="false"
    [data]="data"
    [numberOfRows]="numberOfRows"
    [totalRecords]="totalRecords"
    [showPaginator]="true"
    (requestUpdate)="onPagination($event)"
    [selectedRow]="selectedRow"
    (onRowSelect)="onRowSelect($event)"
    [observeSelection]="selectMode"
    [selectMode]="'single'"
    [templates]="[
      {
        title: 'translate.general.phraseId',
        template: phraseId
      },
      {
        title: 'translate.general.descr',
        template: descr
      },
      {
        title: 'translate.general.actions',
        template: actions,
        permission: allowEdit
      }
    ]"
    [more]="expandRow"
  ></ui-table>

  <ng-template #phraseId let-row="row">
    {{ row.catRefAltId }}
  </ng-template>

  <ng-template #descr let-row="row">
    {{ row.txt }}
  </ng-template>

  <ng-template #actions let-row="row">
    <div *ngIf="allowEdit">
      <a (click)="edit(row)">{{ "translate.general.edit" | translate }}</a>
      <!-- <span class="action-divider">|</span>
      <a (click)="delete($event, row)">{{
        "translate.general.delete" | translate
      }}</a> -->
    </div>
  </ng-template>

  <ng-template #expandRow let-row="row">
    <tabs>
      <tab-single
        tabTitle="{{ 'translate.cat.references' | translate }}"
        [tabId]="tabIdList.CAT__ALT_REFS__REF"
      >
        <ui-table
          *ngIf="row.phrases"
          class="t-small"
          [isWrapped]="false"
          [data]="row.phrases"
          [displayFilter]="['refPhraseId']"
          [templates]="[
            {
              title: 'translate.general.phraseText',
              template: refPhraseText
            }
          ]"
        ></ui-table>

        <ng-template #refPhraseText let-row="row">
          <div class="l-align-spaceBetween">
            <formatted-phrase
              *ngIf="row.phraseDispWords.length > 0"
              [phraseWords]="row.phraseDispWords"
            ></formatted-phrase>
          </div>
        </ng-template>
      </tab-single>
    </tabs>
  </ng-template>

  <ng-template #filterArea>
    <div *ngIf="allowEdit" class="filter-bar l-align-end">
      <button class="t-button" [disabled]="loading" (click)="newEntry()">
        {{ "translate.cat.newEntry" | translate }}
      </button>
    </div>
  </ng-template>
</div>

<edit-alt-ref
  *ngIf="displayEdit"
  [data]="editData"
  (onClose)="displayEdit = false"
  (onCreate)="onCreated()"
></edit-alt-ref>
