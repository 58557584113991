import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

import { Input } from '@angular/core';
import { AnnotationElement, Doc } from '../../models/common';
import { BoundingBox, CatDoc } from '../../models/cat.model';
import { DocToolbarComponent } from '../doc-viewer/doc-toolbar/doc-toolbar.component';
import { PdfJsViewerComponent } from 'ng2-pdfjs-viewer';
import { Annotation } from 'aws-sdk/clients/configservice';
import { NgIfContext } from '@angular/common';
import { CatService } from '../../services/cat.service';
import { faBoxOpen, faThumbsDown } from '@fortawesome/free-solid-svg-icons';
import { ɵallowPreviousPlayerStylesMerge } from '@angular/animations/browser';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';

@Component({
  selector: 'pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss'],
})
export class PdfViewerComponent {
  @Input() doc: Doc;
  @Input() initialPage: number;
  @Input() displayPaging: boolean = true;
  @Input() displayDrawMode: boolean;
  @Input() displayToggleBoundingBoxes: boolean;
  @Input() displayToggleAnnotations: boolean = true;
  @Input() displayRefreshingIcon: boolean;
  @Input() ignoreMouseEvents: boolean;
  @Input() singlePage: boolean;

  @Output() onPageChanged = new EventEmitter<number>();
  @Output() onToggleAnnotations = new EventEmitter<boolean>();
  @Output() onToggleImageAnnotations = new EventEmitter<boolean>();
  @Output() onToggleBoundingBoxes = new EventEmitter<boolean>();
  @Output() onIframeLoaded = new EventEmitter();
  @Output() onBoundingBoxDraw = new EventEmitter<BoundingBox>();
  @Output() onBoundingBoxClicked = new EventEmitter<String>();
  @Output() onAnnClicked = new EventEmitter<AnnotationElement>();
  @Output() onAnnMouseEnter = new EventEmitter<AnnotationElement>();
  @Output() onAnnMouseLeave = new EventEmitter<AnnotationElement>();

  @ViewChild('docToolbar') docToolbar: DocToolbarComponent;
  @ViewChild('pdfViewer', { static: false })
  public pdfViewer: PdfJsViewerComponent;

  pdfUrl: string;
  fullPdfUrl: string;
  docLoading: boolean = true;
  displayedPage: number = 1;
  analyzedPage: number = 1;
  zoomLevel: string = 'page-fit';
  displayAnnotations: boolean = false;
  scale: number = 100;
  cursor: string = 'hand';
  drawMode: boolean = false;
  boxElement: HTMLDivElement;

  canvasx: number;
  canvasy: number;
  last_mousex: number = 0;
  last_mousey: number = 0;
  mousex: number = 0;
  mousey: number = 0;
  mousedown = false;

  annotations: AnnotationElement[] = [];
  boundingBoxes: BoundingBox[] = [];

  get currentScale(): number {
    return this.pdfViewer.PDFViewerApplication.pdfViewer._currentScale;
  }

  get currentPdfPage() {
    return this.pdfViewer.PDFViewerApplication.pdfViewer._pages[
      this.singlePage ? 0 : this.analyzedPage - 1
    ];
  }

  get currentPageDiv(): HTMLElement {
    return this.pdfViewer.PDFViewerApplication.pdfViewer._pages[
      this.singlePage ? 0 : this.analyzedPage - 1
    ].div as HTMLElement;
  }

  get displayToggleAnnotationsCalc(): boolean {
    return (
      this.displayToggleAnnotations && this.analyzedPage == this.displayedPage
    );
  }

  constructor(private catService: CatService) {
    // this.pdfUrl ='https://s3.eu-west-1.amazonaws.com/scai.website.com/public/Brochure+1+(1.0).pdf';
  }

  ngOnInit(): void {
    this.catService
      .getDocFile(this.doc.docId, this.doc.docObjectType, null)
      .subscribe(
        (url) => {
          this.fullPdfUrl = url;
        },
        (err) => {}
      );

    this.catService
      .getDocFile(
        this.doc.docId,
        this.doc.docObjectType,
        this.singlePage ? this.initialPage - 1 : null
      )
      .subscribe(
        (url) => {
          this.pdfUrl = encodeURIComponent(url);
          this.docLoading = false;
          setTimeout(() => {
            this.docToolbar.toggleBoundingBoxesSw = false;
            this.docToolbar.toggleImageAnnotationsSw = false;
            this.docToolbar.toggleAnnotationsSw = false;
          }, 200);

          console.log(this.pdfUrl);
        },
        (err) => {
          this.docLoading = false;
        }
      );
  }

  pdfLoaded() {
    const appConfig = this.pdfViewer.PDFViewerApplication.appConfig;

    // console.log(this.pdfViewer);
    // console.log(appConfig);
    // console.log(this.pdfViewer.PDFViewerApplication.pdfViewer);
    // console.log(this.pdfViewer.PDFViewerApplicationOptions.getAll());

    appConfig.toolbar.next.style.display = 'none';
    appConfig.toolbar.previous.style.display = 'none';
    appConfig.toolbar.numPages.style.display = 'none';
    appConfig.toolbar.pageNumber.style.display = 'none';
    appConfig.secondaryToolbar.toggleButton.style.display = 'none';
    appConfig.toolbar.download.style.display = 'inherit !important';

    this.pdfViewer.PDFViewerApplicationOptions.textLayer = false;
    this.pdfViewer.PDFViewerApplicationOptions.annotationLayer = false;
    this.pdfViewer.PDFViewerApplication.pdfViewer.textLayerMode = 0;

    this.pdfViewer.page = this.singlePage ? 1 : this.initialPage;

    this.onIframeLoaded.emit();

    this.pdfViewer.PDFViewerApplication.pdfViewer.eventBus.on(
      'pagerendered',
      (e) => {
        console.log('pagerendered');
        this.annotations.forEach((e) => {
          this.showAnnotation(e, null, true);
        });
        this.boundingBoxes.forEach((e) => {
          this.displayBoundingBox(e);
        });
      }
    );

    this.pdfViewer.PDFViewerApplication.pdfViewer.eventBus.on(
      'scalechange',
      (e) => {}
    );

    this.pdfViewer.PDFViewerApplication.pdfViewer.eventBus._listeners['download'] = [];
    this.pdfViewer.PDFViewerApplication.pdfViewer.eventBus.on(
      'download',
      async (e) => {
        const filename = new URL(this.fullPdfUrl).pathname.split('/').pop();
        let blob = await fetch(this.fullPdfUrl).then(r => r.blob());
        const a = document.createElement("a");
        a.download = filename;
        a.href = URL.createObjectURL(blob);
        a.click();
        setTimeout(() => {
          URL.revokeObjectURL(a.href);
          a.remove();
        }, 200)
      }
    );

    this.turnOffTextLayer();

    this.subscribeToPageEvents();
  }

  unsubscribeFromPageEvents() {
    this.currentPageDiv.onmousedown = null;
    this.currentPageDiv.onmouseup = null;
    this.currentPageDiv.onmousemove = null;
  }

  subscribeToPageEvents() {
    /************* MOUSE DOWN *************/

    this.currentPageDiv.onmousedown = (e: MouseEvent) => {
      if (this.ignoreMouseEvents) return;

      this.mousedown = true;
      if (this.drawMode) {
        var boxToRemove = this.currentPageDiv.getElementsByClassName('tmp-bb');
        if (boxToRemove) {
          try {
            this.currentPageDiv.removeChild(boxToRemove[0]);
          } catch (e) {
            console.log(e);
          }
        }

        this.last_mousex = e.offsetX;
        this.last_mousey = e.offsetY;
        this.boxElement = document.createElement('div');
        this.boxElement.className = 'tmp-bb';
        this.boxElement.style.position = 'absolute';
        this.boxElement.style.border = '3px solid #0978A2';
        this.boxElement.style.opacity = '60%';
        this.boxElement.style.boxSizing = 'border-box';
        this.boxElement.style.pointerEvents = 'none';
        this.boxElement.id = 'tmp-bb';
      }
    };

    /************* MOUSE UP *************/

    this.currentPageDiv.onmouseup = (e) => {
      if (this.ignoreMouseEvents) return;

      this.mousedown = false;

      if (this.drawMode) {
        console.log(this.boxElement);
        console.log(this.currentPageDiv);

        if (this.boxElement) {
          let box = new BoundingBox();

          // div coords
          let x1 = Number.parseInt(
            this.boxElement.style.left.replace('px', '')
          );
          let y1 = Number.parseInt(this.boxElement.style.top.replace('px', ''));
          let x2 =
            Number.parseInt(this.boxElement.style.width.replace('px', '')) + x1;
          let y2 =
            Number.parseInt(this.boxElement.style.height.replace('px', '')) +
            y1;
          let pageHeightPx = Number.parseInt(
            this.currentPageDiv.style.height.replace('px', '')
          );
          let pageWidthPx = Number.parseInt(
            this.currentPageDiv.style.width.replace('px', '')
          );

          let ratioX =
            this.currentPdfPage.pdfPage._pageInfo.view[2] / pageWidthPx;
          let ratioY =
            this.currentPdfPage.pdfPage._pageInfo.view[3] / pageHeightPx;

          // to pdf coords
          box.x1 = Math.round(x1 * ratioX);
          box.y1 = Math.round(y1 * ratioY);
          box.x2 = Math.round(x2 * ratioX);
          box.y2 = Math.round(y2 * ratioY);
          box.pageHeight = this.currentPdfPage.pdfPage._pageInfo.view[3];
          box.pageWidth = this.currentPdfPage.pdfPage._pageInfo.view[2];

          box.elements = [this.boxElement];
          this.boundingBoxes.push(box);
          this.onBoundingBoxDraw.emit(box);
          this.onToggleDrawMode(false);
        }

        this.last_mousex = 0;
        this.last_mousey = 0;
        this.mousex = 0;
        this.mousey = 0;
      }
    };

    /************* MOUSE MOVE *************/

    this.currentPageDiv.onmousemove = (e) => {
      if (this.ignoreMouseEvents) return;

      if (this.drawMode) {
        this.mousex = e.offsetX;
        this.mousey = e.offsetY;

        if (this.mousedown) {
          var width = Math.abs(this.mousex - this.last_mousex);
          var height = Math.abs(this.mousey - this.last_mousey);
          var x1 = Math.min(this.mousex, this.last_mousex);
          var y1 = Math.min(this.mousey, this.last_mousey);

          if (this.boxElement != null) {
            this.boxElement.style.left = x1 + 'px';
            this.boxElement.style.top = y1 + 'px';
            this.boxElement.style.width = width + 'px';
            this.boxElement.style.height = height + 'px';

            if (
              width > 0 &&
              height > 0 &&
              !this.currentPageDiv.contains(this.boxElement)
            ) {
              console.log(this.boxElement);
              this.currentPageDiv.appendChild(this.boxElement);
            }
          }
        }
      }
    };
  }

  turnOffTextLayer() {
    var textLayer = this.currentPageDiv.getElementsByClassName('textLayer');
    if (textLayer) {
      (textLayer[0] as HTMLDivElement).style.pointerEvents = 'none';
    }
  }

  hideAnnotation(data: AnnotationElement, remove: boolean = false) {
    data.visible = false;
    let idx = this.annotations.indexOf(data);
    if (idx != -1) {
      if (remove) {
        this.annotations[idx].annBadge.remove();
        this.annotations[idx].annSvg.remove();
        this.annotations[idx].annPolygon.remove();
      } else {
        this.annotations[idx].annBadge.style.opacity = '0%';
        this.annotations[idx].annPolygon.setAttribute('stroke-width', '0');
      }

      this.annotations[idx].elements.forEach((e) => {
        if (remove) {
          e.remove();
        } else {
          e.style.opacity = '0%';
        }
      });

      if (remove) this.annotations.splice(idx, 1);
    }
  }

  async showAnnotation(
    data: AnnotationElement,
    visible: boolean = true,
    keepPrevVisibility: boolean = false
  ) {
    if (!keepPrevVisibility) {
      data.visible = visible;
    }

    let badge: HTMLElement;
    let annSvg: SVGSVGElement;
    let annPolygon: SVGPolygonElement;

    let exists = false;

    let idx = this.annotations.indexOf(data);
    if (idx != -1) {
      exists = true;
      annSvg = this.annotations[idx].annSvg;
      annPolygon = this.annotations[idx].annPolygon;
      badge = this.annotations[idx].annBadge;
    } else {
      badge = document.createElement('div');
      annSvg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
      annPolygon = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'polygon'
      );
      annSvg.appendChild(annPolygon);
    }

    let x1 = Math.max(0, data.x1 - 3);
    let y1 = Math.max(0, data.y1 - 3);
    let x2 = data.x2 + 3;
    let y2 = data.y2 + 3;

    let pdfWidth = this.currentPdfPage.pdfPage._pageInfo.view[2] - this.currentPdfPage.pdfPage._pageInfo.view[0];
    let pdfHeight = this.currentPdfPage.pdfPage._pageInfo.view[3] - this.currentPdfPage.pdfPage._pageInfo.view[1];

    let ratioX =
      pdfWidth /
      Number.parseInt(this.currentPageDiv.style.width.replace('px', ''));
    let ratioY =
      pdfHeight /
      Number.parseInt(this.currentPageDiv.style.height.replace('px', ''));
    let strokeWidth = 3;
    annSvg.setAttribute('x', x1 / ratioX + 'px');
    annSvg.setAttribute('y', y1 / ratioX + 'px');

    annSvg.style.position = 'absolute';
    annSvg.style.left = x1 / ratioX + 'px';
    annSvg.style.top = y1 / ratioY + 'px';

    let polyPoints = '';
    data.polygon.forEach((p, i) => {
      if (i != 0) {
        polyPoints += ',  ';
      }
      polyPoints += (p.x - x1) / ratioX + ' ' + (p.y - y1) / ratioY + ' ';
    });

    annPolygon.setAttribute('points', polyPoints);
    annPolygon.setAttribute('fill', 'transparent');
    annPolygon.setAttribute('stroke', data.annColor);
    annPolygon.setAttribute('stroke-width', strokeWidth.toString());
    annPolygon.style.strokeOpacity = data.visible ? '60%' : '0%';

    this.currentPageDiv.appendChild(annSvg);

    var bbox = annSvg.getBBox();
    annSvg.setAttribute('width', bbox.x + bbox.width + +strokeWidth + 'px');
    annSvg.setAttribute('height', bbox.height + bbox.y + +strokeWidth + 'px');

    // if image area is 70% or more of page area, image will have lower z-index
    let zIndex: string;
    if (data.annIdPrefix == 'img') {
      let pdfArea = pdfWidth * pdfHeight;
      let imgArea = Math.abs(data.x2 - data.x1) * Math.abs(data.y2 - data.y1)
      let areaRatio = (imgArea / pdfArea) * 100

      if (areaRatio >= 70) {
        zIndex = '1';
      } else {
        zIndex = '2'
      }
    } else if (data.annIdPrefix == 'bb') {
      zIndex = '3';
    } else {
      zIndex = '4';
    }

    annSvg.style.zIndex = zIndex;

    annPolygon.onmouseenter = (e: MouseEvent) => {
      if (!this.drawMode) {
        this.onAnnMouseEnter.emit(data);
        console.log('mouse enter');
      }
    };
    annPolygon.onmouseleave = (e: MouseEvent) => {
      if (!this.drawMode) {
        this.onAnnMouseLeave.emit(data);
      }
    };
    annPolygon.onmouseup = (e: MouseEvent) => {
      if (!this.drawMode) {
        console.log('mouse up');
        this.onAnnClicked.emit(data);
        setTimeout(() => {
          this.onAnnMouseLeave.emit(data);
        }, 100);
      }
    };

    if (!exists) {
      data.annSvg = annSvg;
      data.annPolygon = annPolygon;
    }

    badge.className = 'badge';
    badge.style.position = 'absolute';
    badge.style.left = (data.polygon[0].x - 1) / ratioX + 'px';
    badge.style.top = data.polygon[0].y / ratioY - 17 + 'px';
    badge.innerText = (data.annIdPrefix ?? '') + data.annId.toString();
    badge.id = 'badge-' + data.annIdPrefix + data.annId;
    badge.style.zIndex = zIndex;
    badge.style.pointerEvents = 'none';
    badge.style.color = 'white';
    badge.style.fontWeight = 'bold';
    badge.style.padding = '.25em .5em';
    badge.style.fontFamily = 'Verdana';
    badge.style.borderTopLeftRadius = '5px';
    badge.style.borderTopRightRadius = '5px';
    badge.style.opacity = data.visible ? '80%' : '0%';
    badge.style.fontSize = '1rem';
    badge.style.backgroundColor = data.annColor;

    if (!exists) {
      data.elements.push(badge);
      data.annBadge = badge;
    }
    this.currentPageDiv.appendChild(badge);

    if (!exists) {
      this.annotations.push(data);
    }
  }

  onViewerPageChanged(event) {
    this.displayedPage = this.pdfViewer.page;
  }

  goToCurPage(page: number) {
    this.pdfViewer.page = this.singlePage ? 1 : page;
  }

  async goToPage(page: number, emitPageChangedEvent = true) {
    if (!this.pdfViewer) {
      console.error('GoToPage invoked with no pdfViewer');
      return;
    }

    this.clearAnnotations();

    this.displayedPage = page;
    this.docToolbar.curPageNum = page;
    this.analyzedPage = page;

    this.unsubscribeFromPageEvents();

    this.docToolbar.toggleBoundingBoxesSw = false;
    this.docToolbar.toggleImageAnnotationsSw = false;
    this.docToolbar.toggleAnnotationsSw = false;

    if (this.singlePage) {
      if (emitPageChangedEvent) {
        setTimeout(() => this.onPageChanged.emit(page), 200);
      }

      this.docLoading = true;
      this.pdfViewer.pdfSrc = null;
      this.pdfViewer.refresh();
      let url = await this.catService.getDocFile(this.doc.docId, this.doc.docObjectType, page - 1).toPromise();
      this.docLoading = false;
      this.pdfUrl = encodeURIComponent(url);

      this.pdfViewer.pdfSrc = this.pdfUrl;
      this.pdfViewerRefresh(this.pdfViewer);
    } else {
      if (emitPageChangedEvent) {
        this.onPageChanged.emit(page);
      }
    }

    this.pdfViewer.page = this.singlePage ? 1 : page;
    console.log('page:' + this.pdfViewer.page);
    
    this.subscribeToPageEvents();

    //this.fitToPage();
    this.onIframeLoaded.emit();
  }

  clearAnnotations() {
    let nodesToRemove = [];
    for (let i = 0; i < this.currentPageDiv.childNodes.length; i++) {
      let el = this.currentPageDiv.childNodes[i] as HTMLElement;
      if (
        el.id.includes('ann-') ||
        el.id.includes('badge-') ||
        el.id.includes('bb-')
      ) {
        nodesToRemove.push(el);
      }
    }
    nodesToRemove.forEach((e) => {
      this.currentPageDiv.removeChild(e);
    });
    this.annotations = [];
    this.boundingBoxes = [];
  }

  zoomOut() {
    this.scale = this.currentScale - 0.05;
    this.pdfViewer.PDFViewerApplication.pdfViewer.currentScaleValue =
      this.scale.toString();
  }

  zoomIn() {
    this.scale = this.currentScale + 0.05;
    this.pdfViewer.PDFViewerApplication.pdfViewer.currentScaleValue =
      this.scale.toString();
  }

  fitToWidth() {
    this.pdfViewer.PDFViewerApplication.pdfViewer.currentScaleValue =
      'page-width';
  }

  fitToPage() {
    this.pdfViewer.PDFViewerApplication.pdfViewer.currentScaleValue =
      'page-fit';
  }

  onToggleDrawMode(removeBB: boolean = true) {
    this.drawMode = !this.drawMode;

    this.docToolbar.toggleDrawModeSw = this.drawMode;

    this.annotations.forEach((a) => {
      a.annBadge.style.pointerEvents = this.drawMode ? 'none' : 'initial';
      a.annSvg.style.pointerEvents = this.drawMode ? 'none' : 'initial';
      a.annPolygon.style.pointerEvents = this.drawMode ? 'none' : 'initial';
      a.elements.forEach((e) => {
        e.style.pointerEvents = this.drawMode ? 'none' : 'initial';
      });
      if (this.drawMode) this.hideAnnotation(a);
    });

    if (removeBB) {
      // this.boundingBoxes.forEach((a) => {
      //   this.removeBoundingBox(a);
      // });
      this.removeBoundingBoxes();
      this.docToolbar.toggleBoundingBoxesSw = false;
    }

    this.cursor = this.drawMode ? 'select' : 'hand';
    this.pdfViewer.cursor = this.cursor;
    this.pdfViewer.PDFViewerApplication.pdfCursorTools.switchTool(
      this.drawMode ? 0 : 1
    );

    this.turnOffTextLayer();
  }

  toggleAnnotations(displayAnnotations: boolean) {
    this.onToggleAnnotations.emit(displayAnnotations);
  }

  toggleImageAnnotations(displayAnnotations: boolean) {
    this.onToggleImageAnnotations.emit(displayAnnotations);
  }

  removeBoundingBox(box: BoundingBox) {
    let idx = this.boundingBoxes.indexOf(box);
    if (idx != -1) {
      this.boundingBoxes[idx].elements.forEach((e) => {
        e.remove();
      });
      this.boundingBoxes.splice(idx, 1);
    }
  }

  removeBoundingBoxes() {
    this.boundingBoxes.forEach((b) => {
      b.elements.forEach((e) => {
        e.remove();
      });
    });
    this.boundingBoxes = [];
  }

  toggleBoundingBoxes(viewSw: boolean) {
    this.onToggleBoundingBoxes.emit(viewSw);
  }

  displayBoundingBox(box: BoundingBox) {
    // let tooltip = '<strong>' + box.boxType.text + '</strong>';
    // if (box.descr) {
    //   tooltip += '<br>' + (box.descr || '');
    // }

    let ratioX =
      this.currentPdfPage.pdfPage._pageInfo.view[2] /
      Number.parseInt(this.currentPageDiv.style.width.replace('px', ''));
    let ratioY =
      this.currentPdfPage.pdfPage._pageInfo.view[3] /
      Number.parseInt(this.currentPageDiv.style.height.replace('px', ''));


    let ann = document.createElement('div');
    ann.className = 'bb';
    ann.style.position = 'absolute';
    ann.style.border = '3px solid #0978A2';
    ann.style.opacity = '60%';
    ann.style.boxSizing = 'border-box';
    //ann.style.pointerEvents = 'none';
    ann.id = 'bb-' + box.boxId;
    ann.style.zIndex = '2';
    ann.style.left = box.x1 / ratioX + 'px';
    ann.style.top = box.y1 / ratioY + 'px';
    ann.style.width = (box.x2 - box.x1) / ratioX + 'px';
    ann.style.height = (box.y2 - box.y1) / ratioY + 'px';

    ann.onmouseup = (e: MouseEvent) => {
      if (!this.drawMode) {
        this.onBoundingBoxClicked.emit(ann.id);
        // setTimeout(() => {
        //   this.onAnnMouseLeave.emit(data);
        // }, 100);
      }
    };

    box.elements = [ann];
    this.boundingBoxes.push(box);
    this.currentPageDiv.appendChild(ann);
  }

  // https://github.com/intbot/ng2-pdfjs-viewer/issues/44
  // https://github.com/MaxEtMoritz/ng2-pdfjs-viewer/commit/d905af302ba1b76431f167cafc88a5564d5fd345
  pdfViewerRefresh(pdfViewer: PdfJsViewerComponent) {
    if (!pdfViewer['_src']) {
      return;
    }

    // console.log(`Tab is - ${pdfViewer.viewerTab}`);
    // if (pdfViewer.viewerTab) {
    //   console.log(`Status of window - ${pdfViewer.viewerTab.closed}`);
    // }

    if (pdfViewer.externalWindow && (typeof pdfViewer.viewerTab === 'undefined' || pdfViewer.viewerTab.closed)) {
      pdfViewer.viewerTab = window.open('', pdfViewer.target, pdfViewer.externalWindowOptions || '');
      if (pdfViewer.viewerTab == null) {
        if(pdfViewer.diagnosticLogs) console.error("ng2-pdfjs-viewer: For 'externalWindow = true'. i.e opening in new tab to work, pop-ups should be enabled.");
        return;
      }

      if (pdfViewer.showSpinner) {
        pdfViewer.viewerTab.document.write(`
          <style>
          .loader {
            position: fixed;
            left: 40%;
            top: 40%;
            border: 16px solid #f3f3f3;
            border-radius: 50%;
            border-top: 16px solid #3498db;
            width: 120px;
            height: 120px;
            animation: spin 2s linear infinite;
          }
          @keyframes spin {
            0% {
              transform: rotate(0deg);
            }
            100% {
              transform: rotate(360deg);
            }
          }
          </style>
          <div class="loader"></div>
        `);
      }
    }

    if (pdfViewer['relaseUrl']) {
      pdfViewer['relaseUrl']();
    }
    let fileUrl;
    //if (typeof pdfViewer.src === "string") {
    //  fileUrl = pdfViewer.src;
    //}
    if (pdfViewer['_src'] instanceof Blob) {
      const url = URL.createObjectURL(pdfViewer['_src']);
      fileUrl = encodeURIComponent(url);
      pdfViewer['relaseUrl'] = () => URL.revokeObjectURL(url);
    } else if (pdfViewer['_src'] instanceof Uint8Array) {
      let blob = new Blob([pdfViewer['_src']], { type: "application/pdf" });
      const url = URL.createObjectURL(blob);
      pdfViewer['relaseUrl'] = () => URL.revokeObjectURL(url);
      fileUrl = encodeURIComponent(url);
    } else {
      fileUrl = pdfViewer['_src'];
    }

    let viewerUrl;
    if (pdfViewer.viewerFolder) {
      viewerUrl = `${pdfViewer.viewerFolder}/web/viewer.html`;
    } else {
      viewerUrl = `assets/pdfjs/web/viewer.html`;
    }

    viewerUrl += `?file=${fileUrl}`;

    if (typeof pdfViewer.viewerId !== 'undefined') {
      viewerUrl += `&viewerId=${pdfViewer.viewerId}`;
    }
    if (typeof pdfViewer.onBeforePrint !== 'undefined') {
      viewerUrl += `&beforePrint=true`;
    }
    if (typeof pdfViewer.onAfterPrint !== 'undefined') {
      viewerUrl += `&afterPrint=true`;
    }
    if (typeof pdfViewer.onDocumentLoad !== 'undefined') {
      viewerUrl += `&pagesLoaded=true`;
    }
    if (typeof pdfViewer.onPageChange !== 'undefined') {
      viewerUrl += `&pageChange=true`;
    }

    if (pdfViewer.downloadFileName) {
      if(!pdfViewer.downloadFileName.endsWith(".pdf")) {
        pdfViewer.downloadFileName += ".pdf";
      }
      viewerUrl += `&fileName=${pdfViewer.downloadFileName}`;
    }
    if (typeof pdfViewer.openFile !== 'undefined') {
      viewerUrl += `&openFile=${pdfViewer.openFile}`;
    }
    if (typeof pdfViewer.download !== 'undefined') {
      viewerUrl += `&download=${pdfViewer.download}`;
    }
    if (pdfViewer.startDownload) {
      viewerUrl += `&startDownload=${pdfViewer.startDownload}`;
    }
    if (typeof pdfViewer.viewBookmark !== 'undefined') {
      viewerUrl += `&viewBookmark=${pdfViewer.viewBookmark}`;
    }
    if (typeof pdfViewer.print !== 'undefined') {
      viewerUrl += `&print=${pdfViewer.print}`;
    }
    if (pdfViewer.startPrint) {
      viewerUrl += `&startPrint=${pdfViewer.startPrint}`;
    }
    if (typeof pdfViewer.fullScreen !== 'undefined') {
      viewerUrl += `&fullScreen=${pdfViewer.fullScreen}`;
    }
    // if (pdfViewer.showFullScreen) {
    //   viewerUrl += `&showFullScreen=${pdfViewer.showFullScreen}`;
    // }
    if (typeof pdfViewer.find !== 'undefined') {
      viewerUrl += `&find=${pdfViewer.find}`;
    }
    if (pdfViewer.lastPage) {
      viewerUrl += `&lastpage=${pdfViewer.lastPage}`;
    }
    if (pdfViewer.rotatecw) {
      viewerUrl += `&rotatecw=${pdfViewer.rotatecw}`;
    }
    if (pdfViewer.rotateccw) {
      viewerUrl += `&rotateccw=${pdfViewer.rotateccw}`;
    }
    if (pdfViewer.cursor) {
      viewerUrl += `&cursor=${pdfViewer.cursor}`;
    }
    if (pdfViewer.scroll) {
      viewerUrl += `&scroll=${pdfViewer.scroll}`;
    }
    if (pdfViewer.spread) {
      viewerUrl += `&spread=${pdfViewer.spread}`;
    }
    if (pdfViewer.locale) {
      viewerUrl += `&locale=${pdfViewer.locale}`;
    }
    if (pdfViewer.useOnlyCssZoom) {
      viewerUrl += `&useOnlyCssZoom=${pdfViewer.useOnlyCssZoom}`;
    }

    if (pdfViewer['_page'] || pdfViewer.zoom || pdfViewer.nameddest || pdfViewer.pagemode) viewerUrl += "#"
    if (pdfViewer['_page']) {
      viewerUrl += `&page=${pdfViewer['_page']}`;
    }
    if (pdfViewer.zoom) {
      viewerUrl += `&zoom=${pdfViewer.zoom}`;
    }
    if (pdfViewer.nameddest) {
      viewerUrl += `&nameddest=${pdfViewer.nameddest}`;
    }
    if (pdfViewer.pagemode) {
      viewerUrl += `&pagemode=${pdfViewer.pagemode}`;
    }
    if (pdfViewer.errorOverride || pdfViewer.errorAppend) {
      viewerUrl += `&errorMessage=${pdfViewer.errorMessage}`;

      if (pdfViewer.errorOverride) {
        viewerUrl += `&errorOverride=${pdfViewer.errorOverride}`;
      }
      if (pdfViewer.errorAppend) {
        viewerUrl += `&errorAppend=${pdfViewer.errorAppend}`;
      }
    }

    if (pdfViewer.externalWindow) {
      pdfViewer.viewerTab.location.href = viewerUrl;
    } else {
      pdfViewer.iframe.nativeElement.contentWindow.location.replace(viewerUrl);
    }
  }
}
