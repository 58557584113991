import { NgRedux } from '@redux/redux-compatibility.service';
import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { LabelChange } from 'src/app/shared/models/lc.model';
import { AppState } from 'src/app/shared/redux/store';
import { CatService } from 'src/app/shared/services/cat.service';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'app-select-cat',
  templateUrl: './select-cat.component.html',
  styleUrls: ['./select-cat.component.scss'],
})
export class SelectLCCatComponent implements OnInit {
  type: 'ac' | 'bc';
  loading: boolean;

  get lc(): LabelChange {
    return this.ngRedux.getState().curLabelChange;
  }

  constructor(
    private ngRedux: NgRedux<AppState>,
    private router: Router,
    private sharedService: SharedService,
    private catService: CatService,
    private route: ActivatedRoute,
    private location: Location
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      this.type = params['type'];
      console.log('type: ' + this.type);
    });
  }

  onCatSelected() {
    this.router.navigate([
      this.sharedService.routeEnum.URL_LC_SELECT_DOCS,
      this.type,
    ]);
  }
}
