import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  ViewChild,
  ElementRef,
  ViewChildren,
  QueryList,
  TemplateRef,
} from '@angular/core';
import { trigger, transition, animate, style } from '@angular/animations';
import { SharedService } from '../../services/shared.service';
import { KeyCodes, focusableSelector } from '../../models/key-codes.model';
import * as Draggabilly from 'draggabilly/draggabilly';

@Component({
  selector: 'modal-popup',
  animations: [
    trigger('opacity', [
      transition(':enter', [
        style({ opacity: '0' }),
        animate('100ms ease-out', style({ opacity: '1' })),
      ]),
      transition(':leave', [
        style({ opacity: '1' }),
        animate('200ms ease-out', style({ opacity: '0' })),
      ]),
    ]),
  ],
  template: `
    <ng-container *ngIf="!isOpen; else modalPopupTemplate"></ng-container>

    <ng-template #modalPopupTemplate>
      <div
        [@opacity]
        class="popup-overlay"
        [ngClass]="{
          'is-flexible': flexible,
          'is-popupDisplay': popupDisplay,
          'is-fullWidth': fullWidth,
          'is-alignLeft': alignLeft
        }"
        role="alert"
      >
        <div class="l-spread popup-overlayClose"></div>

        <section
          id="{{ uniqueId }}"
          class="l-layer-up modal-popup-body"
          [class.wide]="wide"
        >
          <header
            *ngIf="!hideHeader"
            class="l-align-y popup-top popup-top--modal handle"
          >
            <div class="l-cell-spread-x">
              <h2 *ngIf="title" class="popup-title">
                {{ title | translate }}
              </h2>
            </div>

            <ng-container *ngIf="afterTitle">
              <div class="t-space"></div>
              <ng-container *ngTemplateOutlet="afterTitle"></ng-container>
              <div class="t-space"></div>
            </ng-container>

            <button #closeBtn [attr.aria-label]="'close'" (click)="onCancel()">
              <fa-icon [icon]="['fas', 'times']"></fa-icon>
            </button>
          </header>

          <div class="l-cell-spread-y h-full l-pos-relative popup-main-hold">
            <main #modalContent class="popup-main" [class.pad]="padding">
              <ng-content></ng-content>
            </main>
          </div>
        </section>
      </div>
    </ng-template>
  `,
})
export class ModalPopupComponent implements OnInit {
  constructor(private sharedService: SharedService) {}

  @Input() title: string;
  @Input() show: string;
  @Input() flexible: boolean = false;
  @Input() popupDisplay: boolean = false;
  @Input() isOpen: boolean = false;
  @Input() fullWidth: boolean = false;
  @Input() hideHeader: boolean = false;
  @Input() focusOnOpen: boolean = true;
  @Input() padding: boolean = true;
  @Input() wide: boolean = false;
  @Input() afterTitle: TemplateRef<any>;
  @Input() alignLeft: boolean = false;
  @Output() onOpen = new EventEmitter<any>();
  @Output() onClose = new EventEmitter<any>();

  showPopup: boolean = false;
  keyCodes: KeyCodes = new KeyCodes();
  focusableSelector: string = focusableSelector;
  _modalContent: ElementRef;
  freeKeyboard: boolean;
  keyboardTrapCounter: number = -1;
  uniqueId = Math.random().toString();

  @ViewChild('modalContent', { static: false }) set modalContent(
    modalContent: ElementRef
  ) {
    requestAnimationFrame(() => {
      if (modalContent) {
        this._modalContent = modalContent;
        this.freeKeyboard = false;
        document.removeEventListener('keyup', this.keyboardTrap);
        document.addEventListener('keyup', this.keyboardTrap.bind(this));
        document.addEventListener('keyup', this.onCancelByEscKey.bind(this));
        if (this.focusOnOpen) this.focusOnFirstInput();

        var elem = document.getElementById(this.uniqueId);
        var draggie = new Draggabilly(elem, {
          handle: '.handle',
        });
      } else {
        this.freeKeyboard = true;
        this._modalContent = null;
      }
    });
  }

  @ViewChild('closeBtn', { static: false }) closeBtn: ElementRef;

  @ViewChild(ModalPopupComponent, { static: true }) test;

  ngOnInit() {}
  ngOnDestroy() {}
  ngOnChanges() {
    // this.sharedService.modal = this;
  }

  onCancel() {
    this.onClose.emit();
  }

  onCancelByEscKey(event: KeyboardEvent) {
    if (event.keyCode === this.keyCodes.escape) {
      let htmlCloseBtns = document.querySelectorAll('.popup-close');
      let lastBtn: Element = htmlCloseBtns[htmlCloseBtns.length - 1];
      if (lastBtn) lastBtn['click']();
    }
  }

  keyboardTrap(event: KeyboardEvent) {
    if (
      this.freeKeyboard ||
      !this._modalContent ||
      event.keyCode !== this.keyCodes.tab
    )
      return;

    const el = this._modalContent.nativeElement;
    const focusables: NodeList = el.querySelectorAll(this.focusableSelector);
    const focusablesLength = focusables.length;
    let currentFocusableIndex = [].findIndex.call(
      focusables,
      (i) => i === document.activeElement
    );
    if (!event.shiftKey) {
      this.keyboardTrapCounter++;
    } else {
      this.keyboardTrapCounter--;
    }
    if (currentFocusableIndex !== -1)
      this.keyboardTrapCounter = currentFocusableIndex;

    // if larger than last, focus on first
    if (this.keyboardTrapCounter > focusablesLength) {
      this.keyboardTrapCounter = 0;
      focusables[this.keyboardTrapCounter] &&
        focusables[this.keyboardTrapCounter]['focus']();
    }
    // if smaller than first, focus on last
    if (this.keyboardTrapCounter < 0) {
      this.keyboardTrapCounter = focusablesLength;
      focusables[this.keyboardTrapCounter - 1] &&
        focusables[this.keyboardTrapCounter - 1]['focus']();
    }
  }

  focusOnFirstInput() {
    const el = this._modalContent.nativeElement;
    const focusable = el.querySelector(this.focusableSelector);
    console.log(focusable);
    focusable && focusable.focus();
  }
}
