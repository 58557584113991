<modal-popup
  [flexible]="true"
  [focusOnOpen]="true"
  [isOpen]="true"
  (onClose)="close()"
  [title]="modalTitle"
>
  <div class="container" #container>
    <div class="l-align-spaceBetween">
      <!--username-->
      <input-wrap
        label="{{ 'translate.general.username' | translate }}"
        [required]="true"
        [customValidation]="validateUsername()"
        [customValidationWarn]="usernameValidationStr | translate"
        cssClass="l-cell-spread w-half"
      >
        <input
          type="email"
          [(ngModel)]="user.username"
          class="t-input"
          [disabled]="!isNew"
          (keyup)="autoCompleteUsername($event)"
        />
      </input-wrap>

      <!-- password-->
      <input-wrap
        label="{{ 'translate.mng.password' | translate }}"
        [required]="true"
        [customValidation]="passwordValid"
        [customValidationWarn]="passwordValidationMsg"
        cssClass="l-cell-spread w-half"
      >
        <input
          #editUserInputPassword
          type="password"
          [(ngModel)]="user.password"
          class="t-input"
          [disabled]="!usernameFilled"
          (ngModelChange)="validatePassword()"
        />

          <a
            tabIndex="0"
            class="l-align-center h-full c-grey-med edit-user-btn-showPassword"
            (click)="showPassword($event)"
          >
            <fa-icon [icon]="['fas', 'eye']"></fa-icon>
          </a>
      </input-wrap>
    </div>

    <div class="l-align-spaceBetween">
      <!--descr-->
      <input-wrap
        label="{{ 'translate.general.userDescr' | translate }}"
        cssClass="l-cell-spread w-half"
      >
        <input
          type="text"
          [(ngModel)]="user.userDescr"
          class="t-input"
          [disabled]="!usernameFilled"
        />
      </input-wrap>

      <!--timezone-->
      <input-wrap
        label="{{ 'translate.general.timezone' | translate }}"
        [loading]="timezoneList && timezoneList.loading"
        [required]="true"
        [customValidation]="user.timezoneName"
        cssClass="l-cell-spread w-half"
      >
        <p-dropdown
          [options]="timezoneList"
          optionLabel="label"
          optionValue="id"
          [(ngModel)]="user.timezoneName"
          class="l-cell-spread-x form"
          [autoDisplayFirst]="false"
          [filter]="true"
          [resetFilterOnHide]="true"
          appendTo="body"
          baseZIndex="100"
        >
        </p-dropdown>
      </input-wrap>
    </div>

    <div class="env-container">
      <div class="l-align-spaceBetween env-container-title">
        <p>
          {{ "translate.mng.userEnv" | translate }}
        </p>
        <a (click)="addUserEnv()">{{ "translate.general.add" | translate }}</a>
      </div>

      <ui-table
        class="env-tbl"
        [isWrapped]="false"
        [data]="user.userEnvList"
        [templates]="[
          {
            title: 'translate.general.envDescr',
            template: userEnv
          },
          {
            title: 'translate.mng.role',
            template: role
          },
          {
            template: envActions
          }
        ]"
      ></ui-table>
    </div>

    <section
      class="l-mar-top"
      [class.l-align-spaceBetween]="!user.activeSw"
      [class.l-align-end]="user.activeSw"
    >
      <div *ngIf="!user.activeSw" role="alert" class="c-alert t-alert-warn">
        {{ "translate.mng.userInactive" | translate }}
      </div>

      <div class="t-panel-cell t-buttonSet l-align-spaceBetween">
        <button
          *ngIf="user.activeSw && !isNew"
          [disabled]="saving"
          (click)="disableUserClicked()"
          class="t-button-outline t-button-warn"
        >
          {{ "translate.mng.disableUser" | translate }}
        </button>
        &nbsp;
        <button
          *ngIf="user.activeSw && !isNew"
          [disabled]="saving"
          (click)="resetUser()"
          class="t-button-outline t-button-warn"
        >
          {{ "translate.mng.resetUser" | translate }}
        </button>
        &nbsp;
        <button
          [disabled]="!allowSubmit() || saving"
          (click)="updateUser()"
          class="t-button-outline"
        >
          {{ "translate.mng.saveUser" | translate }}
        </button>
      </div>
    </section>

    <loader *ngIf="saving"></loader>
  </div>
</modal-popup>

<ng-template #userEnv let-row="row">
  <p-inplace [closable]="false" [active]="!row.env">
    <ng-template pTemplate="display">{{ row.env.descr }}</ng-template>
    <ng-template pTemplate="content">
      <!--select env -->
      <input-wrap [loading]="envList && envList.loading">
        <p-dropdown
          [options]="envList"
          optionLabel="descr"
          [(ngModel)]="row.env"
          [placeholder]="'translate.general.select' | translate"
          appendTo="body"
          baseZIndex="100"
        >
        </p-dropdown>
      </input-wrap>
    </ng-template>
  </p-inplace>
</ng-template>

<ng-template #role let-row="row">
  <p-inplace [closable]="false" [active]="!row.role">
    <ng-template pTemplate="display">{{ row.role.text }}</ng-template>
    <ng-template pTemplate="content">
      <!--select role -->
      <input-wrap [loading]="roleList && roleList.loading">
        <p-dropdown
          [options]="roleList"
          optionLabel="label"
          [(ngModel)]="row.role"
          [placeholder]="'translate.general.select' | translate"
          appendTo="body"
          baseZIndex="100"
        >
        </p-dropdown>
      </input-wrap>
    </ng-template>
  </p-inplace>
</ng-template>

<ng-template #envActions let-row="row">
  <a (click)="deleteUserEnv(row)">{{
    "translate.general.delete" | translate
  }}</a>
</ng-template>
