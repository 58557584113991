import { Component, OnInit, Output, EventEmitter, Input, ChangeDetectorRef } from '@angular/core';
import { NgRedux } from '@redux/redux-compatibility.service';

import { AppState } from 'src/app/shared/redux/store';
import { DataElement } from 'src/app/shared/models/common';
import { Env } from 'src/app/shared/models/user.model';
import { Catalog } from 'src/app/shared/models/cat.model';
import { IIntegrationTypeSchema, IIntegrationType, ConfigScope } from 'src/app/shared/models/integration-type.model';

import { IntegrationService } from 'src/app/shared/services/integration.service';

@Component({
  selector: 'scai-configure-integration',
  templateUrl: './configure-integration.component.html',
  styleUrls: ['./configure-integration.component.scss'],
})
export class ConfigureIntegrationComponent implements OnInit {
  @Input() configScope: ConfigScope;
  @Input() environmentId: number;
  @Input() integrationTypes: IIntegrationType[];
  @Input() isIntegrationTypesDropdownDisabled: boolean = false;
  @Input() integrationConfig: IIntegrationTypeSchema[];
  @Output() closeDialog = new EventEmitter();
  @Output() configuredIntegration = new EventEmitter<Catalog>();

  data: any = {};
  integrationTypeLoading: boolean;
  formLoading: boolean = false;

  integrationTypeOptions: DataElement[];
  selectedIntegrationTypeOption: DataElement = null;
  saving: boolean;

  get enableSubmit(): boolean {
    const areRequiredIntegrationConfigFieldsPopulated = this.integrationConfig != null &&
      // If there are any required fields with no value
      !this.integrationConfig.some((fieldConfig: IIntegrationTypeSchema) => {
        return fieldConfig.isRequired && (this.data[fieldConfig.key] == null || this.data[fieldConfig.key] === '' );
      });

    return this.selectedIntegrationTypeOption == null || areRequiredIntegrationConfigFieldsPopulated;
  }

  get currentEnvironment(): Env {
    return this.ngRedux.getState().curEnv;
  }

  get currentCatalog(): Catalog {
    return this.ngRedux.getState().curCat;
  }

  get curEnvConfiguredIntegrationType(): string {
    return this.ngRedux.getState().curEnvConfiguredIntegrationType;
  }

  constructor(
    private ngRedux: NgRedux<AppState>,
    private integrationService: IntegrationService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
      if (!this.integrationTypes) {
        this.integrationService.getIntegrationTypes(this.resolveEnvironmentId(), true)
          .subscribe((integrationTypes) => this.setUpIntegrationTypeControlOptions(integrationTypes));
      } else {
        this.setUpIntegrationTypeControlOptions(this.integrationTypes);
      }
  }

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  setUpIntegrationTypeControlOptions(integrationTypes: IIntegrationType[]): void {
    this.integrationTypeOptions = integrationTypes.map(item => new DataElement(item.type, item.type));
    this.changeDetectorRef.detectChanges();
    this.selectedIntegrationTypeOption = this.integrationTypeOptions.find(item => item.id === this.curEnvConfiguredIntegrationType);
    this.onIntegrationTypeChange({ originalEvent: null, value: this.selectedIntegrationTypeOption });
  }

  onIntegrationTypeChange(event: { originalEvent: Event, value: DataElement }): void {
    if (!this.integrationConfig) {
      const integrationType = event.value ? event.value.id : null;

      if (integrationType) {
        const environmentId = this.resolveEnvironmentId();
        const catalogId = this.currentCatalog && this.currentCatalog.catId || null;
        const entityId = this.configScope == ConfigScope.Environment ? environmentId : catalogId;
        this.formLoading = true;

        this.integrationService.getIntegrationConfig(integrationType, this.configScope, environmentId, entityId).subscribe(
          (data: IIntegrationTypeSchema[]) => {
            this.integrationConfig = data;
          }
        ).add(() => this.formLoading = false);
      }
    }
  }

  onSave(): void {
    const integrationType = this.selectedIntegrationTypeOption && this.selectedIntegrationTypeOption.id || null;
    const environmentId = this.resolveEnvironmentId();

    if (integrationType) {
      this.saveIntegrationConfig(environmentId, integrationType);
    } else {
      this.deleteCatalogIntegrationType(environmentId);
    }
  }

  saveIntegrationConfig(environmentId: number, integrationType: string): void {
    const catalogId = this.currentCatalog && this.currentCatalog.catId || null;
    const entityId = this.configScope == ConfigScope.Environment ? environmentId : catalogId;
    this.saving = true;

    this.integrationService.saveIntegrationConf(this.integrationConfig, this.data, entityId).subscribe(
      (successSave: boolean) => {
        if (successSave) {
          switch (this.configScope) {
            case ConfigScope.Environment:
              this.integrationService.addCatalogIntegrationType(environmentId, integrationType).subscribe(
                (success: boolean) => {
                  this.saving = !success;
                }
              );

              break;
            case ConfigScope.Library:
              this.saving = !successSave;

              break;
          }

          this.closeDialog.emit();
        }
      }
    );
  }

  deleteCatalogIntegrationType(environmentId: number): void {
    this.saving = true;

    this.integrationService.deleteCatalogIntegrationType(environmentId).subscribe(
      (success: boolean) => {
        this.saving = !success;
        this.closeDialog.emit();
      }
    );
  }

  onClose(): void {
    this.closeDialog.emit();
  }

  private resolveEnvironmentId(): number {
    return this.environmentId || this.currentEnvironment && this.currentEnvironment.id || null;
  }

}
