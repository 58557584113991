import { NgRedux } from '@redux/redux-compatibility.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Catalog, CatPhraseType } from 'src/app/shared/models/cat.model';
import { AppState } from 'src/app/shared/redux/store';
import { CatService } from 'src/app/shared/services/cat.service';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'edit-phrase-type',
  templateUrl: './edit-phrase-type.component.html',
  styleUrls: ['./edit-phrase-type.component.scss'],
})
export class EditPhraseTypeComponent implements OnInit {
  @Input() type: CatPhraseType;
  @Output() onClose = new EventEmitter();
  @Output() onSave = new EventEmitter();
  loading: boolean;
  isNew: boolean;

  get modalTitle(): string {
    if (this.isNew) return 'translate.cat.mngPhraseTypeAddType';
    else return 'translate.cat.mngPhraseTypeEditType';
  }

  constructor(
    private ngRedux: NgRedux<AppState>,
    private catService: CatService,
    private sharedService: SharedService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.isNew = this.type.typeCode == null;
    this.type.saveOriginal();
  }

  isDirty(): boolean {
    return this.type.isDirty();
  }

  close() {
    if (this.isDirty()) {
      let validationStr = this.translateService.instant(
        'translate.cat.discardEditChanges'
      );

      this.sharedService.emitPopupchReceivedEvent({
        title: 'translate.validation.confirm',
        icon: 'exclamation-triangle',
        subTitle: validationStr,
        keep: false,
        approve: () => {
          this.discardChanges();
          this.onClose.emit();
        },
      });
    } else {
      this.onClose.emit();
    }
  }

  discardChanges() {
    this.type.restoreOriginal();
  }

  validateBeforeSave(): boolean {
    if (!this.type.typeCode || !this.type.typeDescr) {
      return false;
    } else {
      return true;
    }
  }

  saveChanges() {
    this.loading = true;
    this.catService
      .updCatPhraseType(this.type, this.isNew ? 'ADD' : 'UPD')
      .subscribe(
        (data) => {
          this.loading = false;
          this.onSave.emit();
        },
        (err) => (this.loading = false)
      );
  }

  onDeleteClicked() {
    let validationStr = this.translateService.instant(
      'translate.cat.deletePhraseType'
    );

    this.sharedService.emitPopupchReceivedEvent({
      title: 'translate.validation.confirm',
      icon: 'exclamation-triangle',
      subTitle: validationStr,
      keep: false,
      approve: () => {
        this.loading = true;
        this.catService.updCatPhraseType(this.type, 'DEL').subscribe(
          (data) => {
            this.loading = false;
            this.onSave.emit();
          },
          (err) => (this.loading = false)
        );
      },
    });
  }
}
