import { NgRedux } from '@redux/redux-compatibility.service';
import { Component, OnInit } from '@angular/core';
import { Product } from 'src/app/shared/models/common';
import { Env } from 'src/app/shared/models/user.model';
import { AppState } from 'src/app/shared/redux/store';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-mng-products',
  templateUrl: './mng-products.component.html',
  styleUrls: ['./mng-products.component.scss']
})
export class MngProductsComponent implements OnInit {
  loading: boolean;
  editProductOpenSw: boolean;
  curProduct: Product;

  get productList(): Product[] {
    return this.ngRedux.getState().allProducts;
  }

  constructor(
    private ngRedux: NgRedux<AppState>,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.getProductList();
  }

  getProductList() {
    this.loading = true;
    this.authService.getProductList(true).subscribe(
      (data) => {
        this.loading = false;
      },
      (err) => {
        this.loading = false;
      }
    );
  }

  addProduct() {
    this.curProduct = new Product();
    this.editProductOpenSw = true;
  }

  editProduct(product: Product) {
    this.curProduct = product;
    this.editProductOpenSw = true;
  }

}
