<section class="page-header">
  <div class="title-container">
    <h1>{{ "translate.mng.mngEnv" | translate }}</h1>
  </div>
</section>

<div class="header-actions">
  <button class="t-button-outline" (click)="addEnv()">
    {{ "translate.mng.addEnv" | translate }}
  </button>
</div>

<loader *ngIf="!envList || envList.loading"></loader>

<ui-table
  [isWrapped]="false"
  [data]="envList"
  [numberOfRows]="10"
  [displayFilter]="['id', 'descr']"
  [templates]="[
    {
      title: 'translate.general.actions',
      template: actions
    }
  ]"
></ui-table>

<ng-template #actions let-row="row">
  <a (click)="editEnv(row)">{{ "translate.general.edit" | translate }}</a>
  <span class="action-divider">|</span>
  <a (click)="onConfigureIntegration(row)">{{ "translate.cat.configureIntegration" | translate }}</a>
</ng-template>

<edit-env
  *ngIf="editEnvOpenSw"
  [env]="curEnv"
  (onClose)="editEnvOpenSw = false"
  (onSave)="editEnvOpenSw = false; refreshEnvList()"
>
</edit-env>

<scai-configure-integration
  *ngIf="displayConfigureIntegration"
  (closeDialog)="displayConfigureIntegration = false"
  [configScope]="integrationConfigurationScope"
  [environmentId]="curEnv.id"
  (configuredIntegration)="onIntegrationConfigured($event)"
></scai-configure-integration>
