<modal-popup
  [flexible]="true"
  [focusOnOpen]="true"
  [isOpen]="true"
  (onClose)="close()"
  [title]="modalTitle"
>
  <div class="container">
    <div>
      <!--descr-->
      <input-wrap
        label="{{ 'translate.general.descr' | translate }}"
        cssClass="l-cell-spread"
      >
        <input type="text" [(ngModel)]="env.descr" class="t-input" />
      </input-wrap>

      <input-wrap
      [loading]="ruleConfigTemplateList && ruleConfigTemplateList.loading"
      label="{{ 'translate.general.ruleConfigTemplate' | translate }}"
      [required]="true"
      cssClass="l-cell-spread"
      >
        <p-dropdown
          [options]="ruleConfigTemplateList"
          optionLabel="label"
          [(ngModel)]="env.ruleConfigTemplate"
          optionValue="id"
          class="l-cell-spread-x"
          [resetFilterOnHide]="true"
          [filterPlaceholder]="'translate.general.search' | translate"
          [placeholder]="'translate.general.select' | translate"
          [filter]="true"
          appendTo="body"
          baseZIndex="100"
        >
        </p-dropdown>
      </input-wrap>

      <input-wrap
      [loading]="genaiConfigTemplateList && genaiConfigTemplateList.loading"
      label="{{ 'translate.general.genaiConfigTemplate' | translate }}"
      [required]="true"
      cssClass="l-cell-spread"
      >
        <p-dropdown
          [options]="genaiConfigTemplateList"
          optionLabel="label"
          [(ngModel)]="env.genaiConfigTemplate"
          optionValue="id"
          class="l-cell-spread-x"
          [resetFilterOnHide]="true"
          [filterPlaceholder]="'translate.general.search' | translate"
          [placeholder]="'translate.general.select' | translate"
          [filter]="true"
          appendTo="body"
          baseZIndex="100"
        >
        </p-dropdown>
      </input-wrap>

      <input-wrap
      [loading]="embeddingConfigTemplateList && embeddingConfigTemplateList.loading"
      label="{{ 'translate.general.embeddingConfigTemplate' | translate }}"
      [required]="true"
      cssClass="l-cell-spread"
      >
        <p-dropdown
          [options]="embeddingConfigTemplateList"
          optionLabel="label"
          [(ngModel)]="env.embeddingConfigTemplate"
          optionValue="id"
          class="l-cell-spread-x"
          [resetFilterOnHide]="true"
          [filterPlaceholder]="'translate.general.search' | translate"
          [placeholder]="'translate.general.select' | translate"
          [filter]="true"
          appendTo="body"
          baseZIndex="100"
        >
        </p-dropdown>
      </input-wrap>

      <div class="t-panel-cell t-buttonSet l-align-end l-mar-top">
        <button
          *ngIf="!isNew"
          [disabled]="saving"
          (click)="deleteClicked()"
          class="t-button-outline t-button-warn"
        >
          {{ "translate.general.delete" | translate }}
        </button>
        &nbsp;
        <button
          [disabled]="!allowSubmit() || saving"
          (click)="updateEnv()"
          class="t-button-outline"
        >
          {{ "translate.general.save" | translate }}
        </button>
      </div>

      <loader *ngIf="saving"></loader>
    </div>
  </div>
</modal-popup>
