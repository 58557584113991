<div *ngIf="card">
  <section class="page-header">
    <div class="title-container">
      <h1>
        <a class="icon" (click)="goBack()"
          ><fa-icon [icon]="['fas', 'arrow-left']"></fa-icon
        ></a>
        &nbsp;
        <span *ngIf="isNew"> {{ "translate.cat.newCard" | translate }}</span>
        <span *ngIf="!isNew"> {{ card.title }} </span>
      </h1>

      <div *ngIf="isNew">
        <button
          (click)="save()"
          class="t-button"
          [disabled]="loading || !validateBeforeSave()"
        >
          {{ "translate.general.save" | translate }}
        </button>
      </div>

      <div *ngIf="!isNew" class="l-align-center">
        <div *ngIf="isDirty() || editHeaderMode">
          <button
            class="t-button t-button-warn"
            [disabled]="loading || !validateBeforeSave()"
            (click)="save()"
          >
            {{ "translate.general.saveChanges" | translate }}
          </button>
          &nbsp;
          <button
            class="t-button-outline t-button-warn"
            [disabled]="loading"
            (click)="discardChanges()"
          >
            {{ "translate.general.discardChanges" | translate }}
          </button>
        </div>

        &nbsp; &nbsp;

        <button
          *ngIf="allowEdit"
          (click)="deleteClicked()"
          class="t-button-outline t-button-warn"
          [disabled]="loading"
        >
          {{ "translate.general.delete" | translate }}
        </button>
      </div>
    </div>
  </section>

  <div class="t-row--med"></div>

  <section *ngIf="isNew || editHeaderMode" class="l-align-spaceBetween">
    <div class="w-full">
      <!--title-->
      <input-wrap
        label="{{ 'translate.general.title' | translate }}"
        [required]="true"
        cssClass="l-cell-spread"
      >
        <input type="text" [(ngModel)]="card.title" class="t-input" />
      </input-wrap>

      <!--type-->
      <input-wrap
        [loading]="cardTypes && cardTypes.loading"
        label="{{ 'translate.general.typeDescr' | translate }}"
        [required]="true"
        cssClass="l-cell-spread"
      >
        <p-dropdown
          [options]="cardTypes"
          optionLabel="label"
          [(ngModel)]="card.type"
          class="l-cell-spread-x"
          [placeholder]="'translate.general.select' | translate"
          (onChange)="onCardTypeChanged($event)"
        >
        </p-dropdown>
      </input-wrap>

      <!--mc type-->
      <input-wrap
        *ngIf="mcSw"
        [loading]="mcTypes && mcTypes.loading"
        label="{{ 'translate.general.mcType' | translate }}"
        [required]="true"
        cssClass="l-cell-spread"
      >
        <p-dropdown
          [options]="mcTypes"
          optionLabel="label"
          [(ngModel)]="card.mcType"
          class="l-cell-spread-x"
          [placeholder]="'translate.general.select' | translate"
          (onChange)="onMcTypeChanged($event)"
        >
        </p-dropdown>
      </input-wrap>

      <!--mc sub type-->
      <input-wrap
        *ngIf="mcSw"
        [loading]="mcTypes && mcTypes.loading"
        label="{{ 'translate.general.mcSubType' | translate }}"
        cssClass="l-cell-spread"
        [required]="displayMcSubTypes.length > 0"
      >
        <p-dropdown
          [options]="displayMcSubTypes"
          optionLabel="label"
          [(ngModel)]="card.mcSubType"
          class="l-cell-spread-x"
          [placeholder]="'translate.general.select' | translate"
          [readonly]="displayMcSubTypes.length == 0"
        >
        </p-dropdown>
      </input-wrap>
    </div>

    <div class="w-full">
      <data-box
        *ngIf="!isNew"
        [data]="card"
        [displayFilter]="['cardId', 'createDate']"
        [columns]="1"
      ></data-box>
    </div>
  </section>

  <section *ngIf="!isNew && !editHeaderMode">
    <data-box
      class="w-full"
      [data]="card"
      [displayFilter]="['cardId', 'createDate', 'type', 'mcType', 'mcSubType']"
      [columns]="2"
      [templateRefs]="{
        type: type,
        mcType: mcType,
        mcSubType: mcSubType
      }"
      [belowData]="editCardHeaderLink"
    ></data-box>

    <ng-template #type>
      {{ card.type.text }}
    </ng-template>

    <ng-template #mcType>
      <span *ngIf="card.mcType">{{ card.mcType.text }}</span>
    </ng-template>

    <ng-template #mcSubType>
      <span *ngIf="card.mcSubType"> {{ card.mcSubType.text }}</span>
    </ng-template>

    <ng-template #editCardHeaderLink>
      <ng-container *ngIf="allowEdit">
        <div class="t-row--small"></div>
        <a (click)="editHeaderMode = true" class="l-align-end">{{
          "translate.general.edit" | translate
        }}</a>
      </ng-container>
    </ng-template>
  </section>

  <div class="t-row--med"></div>

  <section class="tabs">
    <tabs #cardTabs>
      <tab-single
        *ngFor="let tab of tabs"
        [tabTitle]="tab.tabTitle"
        [tabId]="tab.tabId"
        [link]="tab.link"
        [icon]="tab.icon"
      >
      </tab-single>
    </tabs>
  </section>
</div>
