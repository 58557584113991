<section *ngIf="!dlMode" class="page-header">
  <div class="title-container">
    <h1>{{ "translate.rev.documents" | translate }}</h1>
    <button
      class="t-button"
      (click)="createVersionParentDoc = null; displayAddRevDoc = true"
    >
      {{ "translate.rev.addDoc" | translate }}
    </button>
  </div>
</section>

<loader *ngIf="loading || dlMode"></loader>

<ui-table
  *ngIf="docs && !dlMode"
  [isWrapped]="false"
  [data]="docs"
  [numberOfRows]="10"
  [totalRecords]="docsMetaData.totalRecords"
  (requestUpdate)="onPagination($event)"
  [selectedRow]="selectedDoc"
  [displayFilter]="[
    'docId',
    'productDescr',
    'createDateFormatted',
    'uploadUserDescr'
  ]"
  [templates]="[
    {
      title: 'translate.general.docName',
      template: docName,
      afterColumn: 1,
      permission: true
    },
    {
      title: 'translate.general.statusDescr',
      template: status
    },
    {
      title: 'translate.general.catalog',
      template: catalog
    },
    {
      title: 'translate.general.actions',
      template: actions,
      colClass: 'nowrap'
    }
  ]"
  [more]="docVersions"
  [topEnd]="filterArea"
></ui-table>

<ng-template #docName let-row="row">
  <div *ngIf="!docsBeingEdited[row.docId]" class="l-align-spaceBetween">
    <span style="margin-right: 1rem; word-break: break-all;">{{ row.docName }}</span>
    <a class="icon" (click)="onClickEditDocName($event, row)">
      <i class="fa-solid fa-pen-to-square" [tooltip]="'translate.general.renameDoc' | translate"></i>
    </a>
  </div>

  <div *ngIf="docsBeingEdited[row.docId]" class="l-align-spaceBetween">
    <input 
      type="text"
      [(ngModel)]="docsBeingEdited[row.docId].docName" 
      class="t-input" style="margin-right: 1rem;" 
      (click)="$event.stopPropagation()" 
      [disabled]="docsBeingUpdated[row.docId]" />
    <a *ngIf="!docsBeingUpdated[row.docId]" class="icon" style="margin-right: 1rem;" (click)="onClickUpdateDocName($event, row.docId)">
      <i class="fa-solid fa-check" [tooltip]="'translate.general.saveChanges' | translate"></i>
    </a>

    <a *ngIf="!docsBeingUpdated[row.docId]" class="icon" (click)="onClickCancelEditDocName($event, row.docId)">
      <i class="fa-solid fa-xmark" [tooltip]="'translate.general.cancel' | translate"></i>
    </a>

    <a *ngIf="docsBeingUpdated[row.docId]" class="icon">
      <i class="fa-solid fa-spinner"></i>
    </a>
  </div>
</ng-template>

<ng-template #assetType let-row="row">
  <span *ngIf="row.assetType">{{ row.assetType.text }}</span>
</ng-template>

<ng-template #status let-row="row">
  <p
    *ngIf="!row.isProcessRunning"
    class="fw-bold"
    [class.c-success]="row.statusCode == 'FINAL'"
    [class.c-warn]="row.statusCode == 'UNDERWAY'"
  >
    {{ row.statusDescr }}
  </p>

  <p *ngIf="row.runStatusCode == 'FAILED'" class="processing-status failed">
    {{ "translate.general.processingFailed" | translate }}
    &nbsp;
    <i
      *ngIf="row.runStatusMessage"
      class="fa fa-exclamation-circle icon"
      [tooltip]="row.runStatusMessage"
    >
    </i>
  </p>

  <div *ngIf="row.isProcessRunning">
    <div *ngIf="row.processStatus" (click)="openProcessStatus(row)">
      <circle-progress
        [percent]="row.processStatus.completedPct"
      ></circle-progress>
    </div>

    <spinner *ngIf="!row.processStatus"></spinner>

    <p class="processing-status">
      <processing-label></processing-label>
    </p>
  </div>
</ng-template>

<ng-template #catalog let-row="row">
  <p>{{ row.catDescr }}</p>
  <!--
  <span class="t-footnote">
    ({{ "translate.general.version" | translate }} {{ row.catVersionId }})
  </span>
  -->
  <span [tooltip]="'translate.rev.newCatVersionAvailable' | translate">
    <fa-icon
      *ngIf="row.newCatVersionSw"
      class="c1 t-small"
      [icon]="['fas', 'exclamation-circle']"
    ></fa-icon>
  </span>
</ng-template>

<ng-template #actions let-row="row">
  <a
    *ngIf="row.runStatusCode == 'FINISHED'"
    (click)="$event.stopPropagation(); viewDoc(row)"
    >{{ "translate.general.view" | translate }}</a
  >
  <span *ngIf="row.runStatusCode == 'FINISHED'" class="action-divider">|</span>
  <a
    (click)="
      $event.stopPropagation();
      createVersionParentDoc = row;
      displayAddRevDoc = true
    "
    >{{ "translate.general.addVersion" | translate }}</a
  >
</ng-template>

<ng-template #docVersions let-row="row">
  <ui-table
    *ngIf="row.docVersions && row.docVersions.length"
    class="t-small"
    [rowHover]="false"
    [isWrapped]="false"
    [data]="row.docVersions"
    [templates]="[
      {
        title: 'translate.general.id',
        template: versionId
      },
      {
        title: 'translate.general.fileName',
        template: fileName
      },
      {
        title: 'translate.general.createDateFormatted',
        template: createDateFormatted
      },
      {
        title: 'translate.general.statusDescr',
        template: status
      },
      {
        title: 'translate.general.statusDate',
        template: statusDate
      },
      {
        title: 'translate.general.catalog',
        template: catalog
      },
      {
        title: 'translate.general.actions',
        template: versionActions
      }
    ]"
  ></ui-table>
</ng-template>

<ng-template #versionId let-row="row">
  {{ row.docId }} ({{ "translate.general.versionShort" | translate
  }}{{ row.versionId }})
</ng-template>

<ng-template #fileName let-row="row">
  {{ row.fileName }}
</ng-template>

<ng-template #createDateFormatted let-row="row">
  {{ row.createDateFormatted }}
</ng-template>

<ng-template #versionActions let-row="row">
  <a *ngIf="row.runStatusCode == 'FINISHED'" (click)="viewDoc(row)">{{
    "translate.general.view" | translate
  }}</a>
</ng-template>

<ng-template #statusDate let-row="row">
  {{ row.statusDate }}
</ng-template>

<ng-template #filterArea let-row="row">
  <div class="filter-container">
    <!--docIdName-->
    <input-wrap class="t-input-search-wrap">
      <input
        class="t-input"
        type="text"
        [(ngModel)]="filterDocIdName"
        placeholder="{{ 'translate.general.filterDocIdName' | translate }}"
        (keypress)="onFilterKeyPress($event)"
      />
    </input-wrap>

    <!--status-->
    <input-wrap [loading]="statusList && statusList.loading">
      <p-dropdown
        [options]="statusList"
        optionLabel="label"
        [(ngModel)]="filterStatus"
        [resetFilterOnHide]="true"
        [filterPlaceholder]="'translate.general.search' | translate"
        [placeholder]="'translate.general.statusDescr' | translate"
        [filter]="true"
        (onChange)="onFieldValueChanged($event)"
      >
      </p-dropdown>
    </input-wrap>

    <!--product-->
    <input-wrap [loading]="productList && productList.loading">
      <p-dropdown
        [options]="productList"
        optionLabel="label"
        [(ngModel)]="filterProduct"
        [resetFilterOnHide]="true"
        [filterPlaceholder]="'translate.general.search' | translate"
        [placeholder]="'translate.general.productDescr' | translate"
        [filter]="true"
        (onChange)="onFieldValueChanged($event)"
      >
      </p-dropdown>
    </input-wrap>

    <!--inactive-->
    <checkbox-wrap
      class="checkbox"
      [label]="'translate.general.includeInactive' | translate"
    >
      <input
        type="checkbox"
        [(ngModel)]="filterInactive"
        (ngModelChange)="onFieldValueChanged()"
      />
    </checkbox-wrap>

    <a *ngIf="displayClearFilter" (click)="clearFilter()">{{
      "translate.general.clear" | translate
    }}</a>
  </div>
</ng-template>

<add-rev-doc
  *ngIf="displayAddRevDoc"
  (onClose)="displayAddRevDoc = false"
  [parentDoc]="createVersionParentDoc"
  (onDocCreated)="onDocCreated($event)"
></add-rev-doc>

<process-run-status
  *ngIf="displayProcessStatus"
  (onClose)="displayProcessStatus = false"
  [doc]="curDoc"
  [status]="curDoc.processStatus"
>
</process-run-status>
