<loader *ngIf="loading"></loader>
<ng-container *ngIf="!loading">
  <ng-container *ngFor="let formField of formFieldConfig" [ngSwitch]="formField?.type?.toLowerCase()">
    <!-- TODO: Labels can't be localized atm -->

    <div [class.is-hidden]="!formField.isVisible">

      <input-wrap *ngSwitchCase="'boolean'"
        [label]="formField.label"
        [required]="false"
        cssClass="l-cell-spread"
        singleLine="true"
      >
        <input
          type="checkbox"
          [(ngModel)]="formData[formField.key]"
          class="t-checkboxInputWrap"
          [disabled]="formField.disabled"
        />
      </input-wrap>

      <input-wrap *ngSwitchDefault
        [label]="formField.label"
        [required]="formField.isRequired"
        cssClass="l-cell-spread"
      >
        <input
          [type]="formField.isSecret ? 'password' : 'text'"
          [(ngModel)]="formData[formField.key]"
          class="t-input"
          [disabled]="formField.disabled"
        />
      </input-wrap>
    </div>  

  </ng-container>
</ng-container>
