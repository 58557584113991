import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  CatEntriesSearchParams,
  IndPhrase,
  Catalog,
  CatalogStatusEnum,
  Card,
  IndElement,
} from 'src/app/shared/models/cat.model';
import { ServiceMetaData } from 'src/app/shared/models/service-meta.model';
import { NgRedux } from '@redux/redux-compatibility.service';
import { AppState, actionsList } from 'src/app/shared/redux/store';
import { CatService } from 'src/app/shared/services/cat.service';
import { Router } from '@angular/router';
import { SharedService } from 'src/app/shared/services/shared.service';
import {
  DataElement,
  PaginationParams,
  SearchRunStatusEnum,
} from 'src/app/shared/models/common';
import { keyCodes } from 'src/app/shared/models/key-codes.model';
import { TabIdList } from 'src/app/shared/models/tab.model';
import { TranslateService } from '@ngx-translate/core';
import { TreeNode } from 'primeng/api';
import { TableComponent } from 'src/app/shared/components/ui/table/table.component';
import { Unsubscribe } from 'redux';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';
import { CatPhraseBulkEditComponent } from 'src/app/cat-doc/cat-phrase-bulk-edit/cat-phrase-bulk-edit.component';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/shared/services/auth.service';
import { faTurkishLiraSign } from '@fortawesome/free-solid-svg-icons';
import { Location } from '@angular/common';

@Component({
  selector: 'cat-entries',
  templateUrl: './cat-entries.component.html',
  styleUrls: ['./cat-entries.component.scss'],
})
export class CatEntriesComponent implements OnInit {
  @ViewChild('phraseBulkEdit') phraseBulkEditCmp: CatPhraseBulkEditComponent;
  @ViewChild('tblEntries') tbl: TableComponent;

  @Input() cardMode: boolean = false;
  @Input() lcMode: boolean;
  @Input() selectMode: boolean;
  @Input() displayEntries: IndPhrase[];

  @Output() onPhrasesSelected = new EventEmitter<IndPhrase[]>();

  loading: boolean;
  rangeFrom: number;
  rangeTo: number;
  searchParams: CatEntriesSearchParams = {};
  filterText: string;
  filterDocIdName: string;
  filterCatEntryStatus: DataElement;
  filterAttachedDocs: DataElement[] = [];
  selectedEntry: IndPhrase;
  tabIdList: TabIdList;
  phraseDetailsOpenSw: boolean;
  selectedTypeNodes: TreeNode[];
  filterSubTypeCodes: string[];
  selectedRows: IndPhrase[] = [];
  reduxSubscribe: Unsubscribe;
  rowsPerPage = 50;
  toggleAllSw: boolean;
  editPhraseTypeOpenSw: boolean;
  refSw: boolean = false;
  displayAddToAltRef: boolean = false;
  searchStatusTimer: any;
  searchStatusIntervalSec: number = 2;
  sub: Subscription;
  getDataSub: Subscription;

  get entries(): IndPhrase[] {
    return this.ngRedux.getState().catEntries;
  }

  get curCat(): Catalog {
    return this.ngRedux.getState().curCat;
  }

  get catPhraseTypes(): TreeNode[] {
    if (!this.ngRedux.getState().catPhraseTypeModel) return [];
    return this.ngRedux.getState().catPhraseTypeModel.treeTypes;
  }

  get allowEdit(): boolean {
    if (!this.curCat) return false;
    return this.curCat.statusCode == CatalogStatusEnum.DRAFT;
  }

  get entriesMetaData(): ServiceMetaData {
    const metaData: ServiceMetaData =
      this.ngRedux.getState().catEntriesMetadata;
    return metaData || {};
  }

  get displayClearFilter(): boolean {
    return (
      this.filterDocIdName != null ||
      this.filterText != null ||
      this.filterSubTypeCodes != null ||
      this.filterAttachedDocs.length > 0 ||
      this.filterCatEntryStatus != null
    );
  }

  get curCard(): Card {
    return this.ngRedux.getState().curCard;
  }

  get attachedDocs(): DataElement[] {
    return this.curCat.attachedDocsList;
  }

  get selectedPhrases(): IndPhrase[] {
    if (!this.displayEntries) return [];
    return this.displayEntries.filter((r) => r.checkedSw);
  }

  get numberOfRows(): number {
    return this.lcMode ? this.displayEntries.length : this.rowsPerPage;
  }

  get totalRecords(): number {
    return this.lcMode ? null : this.entriesMetaData.totalRecords;
  }

  get catEntryStatusList(): DataElement[] {
    return this.ngRedux.getState().catEntryStatusList;
  }

  get allowAddToAltRef(): boolean {
    return (
      this.selectedPhrases.length > 0 &&
      this.selectedPhrases.every((p) => p.isRefSw)
    );
  }

  constructor(
    private ngRedux: NgRedux<AppState>,
    private catService: CatService,
    private router: Router,
    private sharedService: SharedService,
    private translateService: TranslateService,
    private authService: AuthService,
    private location: Location
  ) {
    this.tabIdList = this.ngRedux.getState().ui_tabIdList;
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    if (!this.lcMode) this.loadEntries(true);
  }

  ngOnDestroy(): void {
    if (this.reduxSubscribe) this.reduxSubscribe();
    this.sub?.unsubscribe();
    this.getDataSub?.unsubscribe();
  }

  loadEntries(newSearch: boolean) {
    this.sub?.unsubscribe();
    this.getDataSub?.unsubscribe();
    this.loading = true;

    if (newSearch) {
      this.displayEntries = null;
      this.searchParams.searchId = null;
      this.searchParams.rangeFrom = 0;
      this.searchParams.rangeTo = this.rowsPerPage;
    }

    this.searchParams.text = this.filterText;
    this.searchParams.subTypeCodes = this.filterSubTypeCodes
      ? this.filterSubTypeCodes
      : [];
    this.searchParams.docs = this.filterAttachedDocs;
    this.searchParams.catEntryStatus = this.filterCatEntryStatus;
    this.searchParams.refStarterSw = this.refSw;

    this.getDataSub = this.catService
      .getCatEntries(this.searchParams)
      .subscribe(
        (searchId) => {
          console.log(searchId);
          this.pullSearchStatus(
            searchId,
            () => {
              this.catService.getCatEntriesSearch(searchId).subscribe(
                (data) => {
                  this.updateDisplayedEntries();
                  this.loading = false;
                },
                (err) => {
                  this.loading = false;
                }
              );
            },
            () => {
              this.loading = false;
              this.sharedService.alertError(
                this.translateService.instant('translate.alert.searchError'),
                null,
                null,
                'get_cat_entries_func'
              );
            }
          );
        },
        (err) => (this.loading = false)
      );
  }

  pullSearchStatus(searchId: number, onFinished, onError) {
    this.sub?.unsubscribe();
    this.sub = this.authService.getSearchStatus(searchId).subscribe(
      (status) => {
        if (status == SearchRunStatusEnum.FINISHED) {
          onFinished();
        } else if (status == SearchRunStatusEnum.ERROR) {
          onError();
        } else {
          setTimeout(() => {
            this.pullSearchStatus(searchId, onFinished, onError);
          }, this.searchStatusIntervalSec * 1000);
        }
      },
      (err) => {
        onError();
      }
    );
  }

  updateDisplayedEntries() {
    this.displayEntries = this.entries.slice(
      this.searchParams.rangeFrom - 1,
      this.searchParams.rangeTo
    );
  }

  onPhraseEdited() {
    this.updateDisplayedEntries();
  }

  onPagination(event: PaginationParams) {
    if (
      this.lcMode ||
      !this.entries ||
      (event.rangeFrom == this.searchParams.rangeFrom &&
        event.rangeTo == this.searchParams.rangeTo)
    )
      return;
    this.searchParams.rangeFrom = event.rangeFrom; // + 1;
    this.searchParams.rangeTo = event.rangeTo;
    this.searchParams.searchId =
      this.ngRedux.getState().catEntriesMetadata.searchId;
    this.updateDisplayedEntries();
  }

  onFilterKeyPress(e: any) {
    if (e.keyCode == keyCodes.enter) {
      this.loadEntries(true);
    }
  }

  onFieldValueChanged(e: any) {
    if (e) (e.originalEvent as MouseEvent).stopPropagation();
    this.loadEntries(true);
  }

  clearFilter() {
    this.filterDocIdName = null;
    this.filterText = null;
    this.filterSubTypeCodes = null;
    this.filterAttachedDocs = [];
    this.filterCatEntryStatus = null;
    this.refSw = false;
    this.loadEntries(true);
  }

  edit(event: any, entry: IndPhrase) {
    if (event) event.stopPropagation();

    this.ngRedux.dispatch({
      type: actionsList.SELECT_CAT_ELEMENT,
      data: entry,
    });
    this.phraseDetailsOpenSw = true;
  }

  delete(event: any, entry: IndElement) {
    if (event) event.stopPropagation();

    let validationStr = this.translateService.instant(
      'translate.cat.deleteEntry'
    );

    this.sharedService.emitPopupchReceivedEvent({
      title: 'translate.validation.confirm',
      icon: 'exclamation-triangle',
      subTitle: validationStr,
      keep: false,
      approve: () => {
        this.phraseDetailsOpenSw = false;
        this.loading = true;
        this.catService.updCatEntry(entry, true).subscribe(
          (data) => {
            this.onEntryDeleted(entry);
            this.loading = false;
          },
          (err) => (this.loading = false)
        );
      },
    });
  }

  newEntry() {
    let newPhrase = new IndPhrase();
    newPhrase.catId = this.curCat.catId;
    newPhrase.manualSrcSw = true;
    newPhrase.catEntryStatus = this.ngRedux
      .getState()
      .catEntryStatusList.find((r) => r.id == 'VALID');
    this.edit(false, newPhrase);
  }

  onEntryDeleteClicked() {
    this.delete(null, this.ngRedux.getState().curCatElement);
  }

  onTypesPanelHide() {
    if (!this.selectedTypeNodes || this.selectedTypeNodes.length == 0) {
      this.filterSubTypeCodes = null;
    } else {
      this.filterSubTypeCodes = this.selectedTypeNodes.map((n) => n.key);
    }
    this.loadEntries(true);
  }

  onPhraseSaved() {
    this.phraseDetailsOpenSw = false;
    this.loadEntries(false);
  }

  onEntryDeleted(entry: IndElement = null) {
    if (!entry) {
      entry = this.ngRedux.getState().curCatElement;
    }
    this.phraseDetailsOpenSw = false;
    let idx = this.entries.findIndex((e) => e.phraseId == entry.elementId);
    if (idx > -1) {
      this.entries.splice(idx, 1);

      this.entriesMetaData.totalRecords--;
      this.updateDisplayedEntries();
    }
  }

  isRowInCard(row: IndPhrase): boolean {
    if (!this.ngRedux.getState().curCard) return false;
    return (
      this.ngRedux
        .getState()
        .curCard.cardPhrases.find((c) => c.phraseId == row.phraseId) != null
    );
  }

  getCardAppearancesCnt(row: IndPhrase): number {
    if (!this.ngRedux.getState().curCard) return 0;
    return this.ngRedux
      .getState()
      .curCard.cardPhrases.filter((c) => c.phraseId == row.phraseId).length;
  }

  addEntryToCard(event, entry: IndPhrase) {
    event.stopPropagation();
    this.curCard.cardPhrases.push(entry);
  }

  removeEntryFromCard(event, entry: IndPhrase) {
    event.stopPropagation();
    let idx = this.curCard.cardPhrases.findIndex(
      (e) => e.phraseId == entry.phraseId
    );
    if (idx != -1) {
      this.curCard.cardPhrases.splice(idx, 1);
    }
  }

  onToggleSelectAll() {
    this.displayEntries.forEach((e) => (e.checkedSw = this.toggleAllSw));
  }

  editSelected() {
    this.editPhraseTypeOpenSw = true;
  }

  onBulkEditSave(phraseType: TreeNode) {
    this.catService
      .updBulkCatEntry(
        this.selectedPhrases,
        phraseType.parent.data.typeCode,
        phraseType.data.typeCode
      )
      .subscribe(
        (data) => {
          this.selectedPhrases.forEach((p) => {
            p.typeCode = phraseType.parent.data.typeCode;
            p.typeDescr = phraseType.parent.data.typeDescr;
            p.subTypeCode = phraseType.data.typeCode;
            p.subTypeDescr = phraseType.data.typeDescr;
          });
          this.selectedPhrases.forEach((p) => {
            p.checkedSw = false;
          });
          this.phraseBulkEditCmp.loading = false;
          this.editPhraseTypeOpenSw = false;
        },
        (err) => {
          this.phraseBulkEditCmp.loading = false;
        }
      );
  }

  onAddPhrases() {
    this.onPhrasesSelected.emit(this.selectedPhrases);
  }

  goBack() {
    this.location.back();
  }

  addToAltRef() {
    this.displayAddToAltRef = true;
  }

  afterAddToAltRef() {
    this.displayAddToAltRef = false;
    this.tbl?.resetSelection();
    this.displayEntries.map((r) => (r.checkedSw = false));
  }
}
