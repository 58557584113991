import { NgRedux } from '@redux/redux-compatibility.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TreeNode } from 'primeng/api';
import {
  BoundingBox,
  Card,
  CardSearchParams,
  McBoxType,
  McSubType,
} from 'src/app/shared/models/cat.model';
import { DataElement } from 'src/app/shared/models/common';
import { LabelChange, LCCardTypeEnum } from 'src/app/shared/models/lc.model';
import { AppState, actionsList } from 'src/app/shared/redux/store';
import { AuthService } from 'src/app/shared/services/auth.service';
import { CatService } from 'src/app/shared/services/cat.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { IntegrationService } from 'src/app/shared/services/integration.service';
import { threadId } from 'worker_threads';

@Component({
  selector: 'cat-bounding-box',
  templateUrl: './cat-bounding-box.component.html',
  styleUrls: ['./cat-bounding-box.component.scss'],
})
export class CatBoundingBoxComponent implements OnInit {
  @Input() box: BoundingBox;
  @Input() lcMode: string;

  @Output() onClose = new EventEmitter();
  @Output() onSave = new EventEmitter<BoundingBox>();
  @Output() onImageSave = new EventEmitter<number>();
  @Output() onDiscard = new EventEmitter();
  @Output() onDelete = new EventEmitter();

  isNew: boolean;
  saving: boolean;
  cards: Card[] = [];
  displayedCards: Card[] = [];
  bbElementTypes: DataElement[] = [];
  selectedBBType: DataElement;
  selectedFigureType: DataElement;
  selectedFigureSubType: DataElement;
  selectedPhraseType: TreeNode;
  catPhraseTypesTree: TreeNode[];
  addCardSw: boolean;
  newCard: Card = new Card();
  mcSw: boolean;
  displayMcSubTypes: DataElement[] = [];
  displayMcBoxTypes: DataElement[] = [];
  cardTypes: DataElement[] = [];
  loading: boolean;

  get lcSw(): boolean {
    if (!this.lcMode) return false;
    return this.lcMode.startsWith('lc');
  }

  get curLC(): LabelChange {
    return this.ngRedux.getState().curLabelChange;
  }

  get modalTitle(): string {
    if (this.isNew) return 'translate.cat.createBoundingBox';
    else return 'translate.cat.viewBoundingBox';
  }

  get figureTypes(): DataElement[] {
    return this.ngRedux.getState().figureTypeList;
  }

  get boxTypes(): DataElement[] {
    return this.ngRedux.getState().boundingBoxTypes;
  }

  get mcTypes(): DataElement[] {
    return this.ngRedux.getState().mcTypes;
  }

  get allMcSubTypes(): McSubType[] {
    return this.ngRedux.getState().mcSubTypes;
  }

  get allMcBoxTypes(): McBoxType[] {
    return this.ngRedux.getState().mcBoxTypes;
  }

  constructor(
    private ngRedux: NgRedux<AppState>,
    private catService: CatService,
    private sharedService: SharedService,
    private translateService: TranslateService,
    private integrationService: IntegrationService
  ) {}

  ngOnInit(): void {
    this.isNew = this.box.boxId == null;
    this.loadCardList();
    this.bbElementTypes.push(new DataElement('CARD_BOX', 'Card element'));
    if (!this.lcMode) {
      this.bbElementTypes.push(new DataElement('IND_IMG', 'Document figure'));
    }
    this.selectedBBType = this.bbElementTypes[0];
    this.initCatTypesTree();
    this.newCard.type = this.cardTypes.find((t) => t.id == 'MODULAR_CONTENT');
    this.mcSw = true;
    if (this.lcMode) {
      this.cardTypes = this.ngRedux
        .getState()
        .cardTypes?.filter((c) => c.id == 'LC');
    } else {
      this.cardTypes = this.ngRedux
        .getState()
        .cardTypes?.filter((c) => c.id != 'LC');
    }
  }

  initCatTypesTree() {
    if (!this.ngRedux.getState().catPhraseTypeModel) {
      this.catPhraseTypesTree = [];
    } else {
      this.catPhraseTypesTree =
        this.ngRedux.getState().catPhraseTypeModel.treeTypesForInput;
    }
  }

  loadCardList() {
    this.loading = true;
    let sp = new CardSearchParams();
    sp.shortModeSw = true;
    sp.docId=this.ngRedux.getState().curCatDocStatePrime.curCatDoc.docId;
    this.catService.getCards(sp).subscribe(
      (data) => {
        if (this.lcMode == 'lc-ac') {
          this.cards = data.filter(
            (c) =>
              c.lcCardType &&
              c.lcCardType.id == LCCardTypeEnum.LC_DONE_TAG &&
              c.lcId == this.curLC.lcId
          );
        } else if (this.lcMode == 'lc-bc') {
          this.cards = data.filter(
            (c) =>
              c.lcCardType &&
              c.lcCardType.id == LCCardTypeEnum.LC_NEED_TAG &&
              c.lcId == this.curLC.lcId
          );
        } else {
          this.cards = data.filter(
            (c) =>
              c.type.id != 'LC' &&
              !c.cardDocs.find(
                (d) =>
                  d.docId !=
                  this.ngRedux.getState().curCatDocStatePrime.curCatDoc.docId
              )
          );
        }

        this.displayedCards = this.cards;

        if (this.lcSw && this.displayedCards.length > 0) {
          this.box.card = this.displayedCards[0];
        }
        this.loading = false;
      },
      () => (this.loading = false)
    );
  }

  close() {
    if (this.isNew) {
      this.onDiscard.emit();
    }
    this.onClose.emit();
  }

  save() {
    this.saving = true;

    if (this.selectedBBType.id == 'IND_IMG') {
      this.catService
        .updIndImg(
          this.ngRedux.getState().curCatDocStatePrime.curCatDocPageNum,
          this.ngRedux.getState().curCatDocStatePrime.curCatDoc,
          this.box,
          this.selectedPhraseType.parent.key,
          this.selectedPhraseType.key,
          this.selectedFigureType.id,
          this.selectedFigureSubType.id
        )
        .subscribe(
          (imageId) => {
            this.saving = false;
            this.onImageSave.emit(imageId);
          },
          () => (this.saving = false)
        );
    } else {
      this.catService
        .updBoundingBox(
          this.ngRedux.getState().curCatDocStatePrime.curCatDocPageNum,
          this.ngRedux.getState().curCatDocStatePrime.curCatDoc,
          this.box,
          false,
          this.addCardSw ? this.newCard : null
        )
        .subscribe(
          (box) => {
            this.saving = false;
            this.onSave.emit(box);
            this.integrationService.setClaimBlockPhraseContentType(box.card.cardId, false).subscribe(
              (data) => {
                this.ngRedux.dispatch({
                  type: actionsList.RELOAD_CAT_DOC_PAGE_REQ,
                  data: false,
                });
              },
              (err) => (this.loading = false)
            );
          },
          () => (this.saving = false)
        );
    }
  }

  deleteClicked() {
    let validationStr = this.translateService.instant(
      'translate.cat.deleteBoxValidation'
    );

    this.sharedService.emitPopupchReceivedEvent({
      title: 'translate.validation.confirm',
      icon: 'exclamation-triangle',
      subTitle: validationStr,
      keep: false,
      approve: () => {
        this.delete();
      },
    });
  }

  delete() {
    this.saving = true;
    this.integrationService.setClaimBlockPhraseContentType(this.box.card.cardId, true).subscribe(
      (data) => {
        this.ngRedux.dispatch({
          type: actionsList.RELOAD_CAT_DOC_PAGE_REQ,
          data: false,
        });
        this.catService
        .updBoundingBox(
          this.ngRedux.getState().curCatDocStatePrime.curCatDocPageNum,
          this.ngRedux.getState().curCatDocStatePrime.curCatDoc,
          this.box,
          true
        )
        .subscribe(
          (box) => {
            this.saving = false;
            this.onDelete.emit(box);
          },
          () => (this.saving = false)
        );
      },
      (err) => (this.loading = false)
    );
  }

  public allowSubmit(): boolean {
    if (this.selectedBBType.id == 'IND_IMG') {
      return (
        this.selectedPhraseType != null &&
        this.selectedFigureType != null &&
        this.selectedFigureSubType != null
      );
    } else
      return (
        this.box.card != null ||
        (this.newCard.title != null &&
          this.newCard.type != null &&
          (this.newCard.type.id != 'MODULAR_CONTENT' ||
            (this.newCard.mcType != null &&
              (this.displayMcSubTypes.length == 0 ||
                this.newCard.mcSubType != null))))
      );
  }

  discard() {
    this.onDiscard.emit();
  }

  onBBTypeChanged(event: any) {
    if (this.selectedBBType.id == 'MODULAR_CONTENT') {
      this.displayedCards = this.cards.filter(
        (c) => c.type.id == 'MODULAR_CONTENT'
      );
    } else {
      this.displayedCards = this.cards;
    }
  }

  onCardTypeChanged() {
    this.newCard.title = null;
    if (this.newCard.type.id == 'MODULAR_CONTENT') {
      this.mcSw = true;
    } else if (this.newCard.type.id == 'CLAIM') {
      this.newCard.title = this.translateService.instant(
        'translate.cat.defaultClaimTitle'
      );
    }

    if (this.newCard.type.id != 'MODULAR_CONTENT') {
      this.mcSw = false;
      this.newCard.mcSubType = null;
      this.newCard.mcType = null;
    }
    this.filterMcBoxTypes();
  }

  onMcTypeChanged() {
    this.displayMcSubTypes = this.allMcSubTypes
      .filter((r) => r.mcTypeCode == this.newCard.mcType.id)
      .map((e) => e.subType);
    this.newCard.mcSubType = null;
    this.filterMcBoxTypes();
  }

  filterMcBoxTypes() {
    if (this.addCardSw) {
      if (!this.newCard.type) {
        this.displayMcBoxTypes = [];
      } else {
        this.selectMcBoxTypesByCard(this.newCard);
      }
    } else {
      if (!this.box.card) {
        this.displayMcBoxTypes = [];
      } else {
        this.selectMcBoxTypesByCard(this.box.card);
      }
    }
  }

  selectMcBoxTypesByCard(card: Card) {
    if (!card.mcType || !card.mcSubType) {
      this.displayMcBoxTypes = [];
    } else
      this.displayMcBoxTypes = this.allMcBoxTypes
        .filter(
          (t) =>
            t.mcTypeCode == card.mcType.id &&
            t.mcSubTypeCode == card.mcSubType.id
        )
        .map((b) => b.mcBoxType);
  }
}
