import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { DocViewerComponent } from 'src/app/shared/components/doc-viewer/doc-viewer.component';
import { BoundingBox, Card } from 'src/app/shared/models/cat.model';
import { Doc, DocPage } from 'src/app/shared/models/common';

@Component({
  selector: 'card-display',
  templateUrl: './card-display.component.html',
  styleUrls: ['./card-display.component.scss'],
})
export class CardDisplayComponent implements OnInit {
  @Input() card: Card;
  @Input() cardType: 'IND' | 'REV';
  @Input() selectedBoxId: number;
  @Input() selectedPhraseId: number;
  @Input() displayMatchStatus: boolean;
  @Input() displayPhraseLink: boolean;

  constructor() {}

  ngOnInit(): void {}
}
