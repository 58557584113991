import { Input, Component, OnInit } from '@angular/core';

@Component({
  selector: 'checkbox-wrap',
  template: `
  <label class="t-checkbox">
    <span class="l-align-y">
      <ng-content></ng-content>
      <i class="fa t-checkbox-appearance"></i>
      <span class="t-chackbox-labeText">
        <i *ngIf="icon" class="fas" [ngClass]="'fa-' + icon"></i>
        {{label}}
      </span>
      <ng-content select="[customLabel]"></ng-content>
    </span>
  </label>
`
} )
export class CheckboxWrapComponent implements OnInit {

  @Input() label: string;
  @Input() icon: string;

  constructor() {}

  ngOnInit() {
  }

}
