<modal-popup
  [flexible]="true"
  [isOpen]="true"
  (onClose)="close()"
  [title]="'translate.general.processRunStatus'"
>
  <div class="container">
    <data-box
      class="w-full t-row--med"
      [isWrapped]="false"
      [data]="doc"
      [displayFilter]="['docId', 'docName', 'createDateFormatted']"
      [columns]="1"
    ></data-box>

    <p-progressBar [value]="status.completedPct"></p-progressBar>

    <div class="steps">
      <p *ngFor="let step of status.completedSteps; let first = first">
        <i class="fas fa-check c-success"></i>&nbsp; {{ step.stepDescr }}
        <processing-label *ngIf="first" [withText]="false"></processing-label>
      </p>
    </div>
  </div>
</modal-popup>
