import { NgRedux } from '@redux/redux-compatibility.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ValidationPipe } from 'src/app/shared/components/ui/validation.pipe';
import { Product } from 'src/app/shared/models/common';
import { Env } from 'src/app/shared/models/user.model';
import { AppState } from 'src/app/shared/redux/store';
import { AuthService } from 'src/app/shared/services/auth.service';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'edit-product',
  templateUrl: './edit-product.component.html',
  styleUrls: ['./edit-product.component.scss'],
})
export class EditProductComponent implements OnInit {
  @Input() product: Product;
  @Output() onClose = new EventEmitter();
  @Output() onSave = new EventEmitter();
  isNew: boolean;
  saving: boolean;

  get modalTitle(): string {
    if (this.isNew) return 'translate.mng.addProduct';
    else return 'translate.mng.editProduct';
  }

  isDirty(): boolean {
    return this.product.isDirty();
  }

  get envList(): Env[] {
    return this.ngRedux.getState().envList;
  }

  constructor(
    private ngRedux: NgRedux<AppState>,
    private authService: AuthService,
    private sharedService: SharedService,
    private translateService: TranslateService,
    private validationPipe: ValidationPipe
  ) {}

  ngOnInit(): void {
    this.isNew = this.product.id == null;
    this.product.saveOriginal();
  }

  close() {
    if (this.isDirty()) {
      let validationStr = this.translateService.instant(
        'translate.cat.discardEditChanges'
      );

      this.sharedService.emitPopupchReceivedEvent({
        title: 'translate.validation.confirm',
        icon: 'exclamation-triangle',
        subTitle: validationStr,
        keep: false,
        approve: () => {
          this.discardChanges();
          this.onClose.emit();
        },
      });
    } else {
      this.onClose.emit();
    }
  }

  discardChanges() {
    this.product.restoreOriginal();
  }

  deleteClicked() {
    let validationStr = this.translateService.instant(
      'translate.mng.deleteProductValidation'
    );

    this.sharedService.emitPopupchReceivedEvent({
      title: 'translate.validation.confirm',
      icon: 'exclamation-triangle',
      subTitle: validationStr,
      keep: false,
      approve: () => {
        this.updateProduct(true);
      },
    });
  }

  public allowSubmit(): boolean {
    return this.product.productDescr != null;
  }

  public updateProduct(deleteSw: boolean = false) {
    this.saving = true;
    this.authService.updateProduct(this.product, deleteSw).subscribe(
      () => {
        this.saving = false;
        this.onSave.emit();
      },
      () => (this.saving = false)
    );
  }
}
