import { NgRedux } from '@redux/redux-compatibility.service';
import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { CatDoc } from 'src/app/shared/models/cat.model';
import { LabelChange, LCDoc } from 'src/app/shared/models/lc.model';
import { actionsList, AppState } from 'src/app/shared/redux/store';
import { CatService } from 'src/app/shared/services/cat.service';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'app-select-docs',
  templateUrl: './select-docs.component.html',
  styleUrls: ['./select-docs.component.scss'],
})
export class SelectLCDocsComponent implements OnInit {
  type: 'ac' | 'bc';
  saving: boolean;

  get lc(): LabelChange {
    return this.ngRedux.getState().curLabelChange;
  }

  get navMode(): string {
    return 'lc-' + this.type;
  }

  constructor(
    private ngRedux: NgRedux<AppState>,
    private router: Router,
    private sharedService: SharedService,
    private catService: CatService,
    private route: ActivatedRoute,
    private location: Location
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      this.type = params['type'];
      console.log('type: ' + this.type);
    });
  }

  onDocsSelected(docs: CatDoc[]) {
    let lc = Object.assign(new LabelChange(), this.lc);
    docs.forEach((d) => {
      if (
        this.type == 'ac' &&
        lc.acDocs.findIndex((x) => x.origDocId == d.origDocId) == -1
      ) {
        lc.acDocs.push(Object.assign(new LCDoc(), d));
      } else if (
        this.type == 'bc' &&
        lc.bcDocs.findIndex((x) => x.origDocId == d.origDocId) == -1
      ) {
        lc.bcDocs.push(Object.assign(new LCDoc(), d));
      }
    });
    this.saving = true;
    this.catService.updateLabelChange(lc).subscribe(
      (data) => {
        this.ngRedux.dispatch({
          type: actionsList.RELOAD_CUR_LC_REQ,
        });
        this.saving = false;
        if (this.type == 'ac') {
          this.router.navigate(['main/lc/lc-docs/ac']);
        } else {
          this.router.navigate(['main/lc/lc-docs/bc']);
        }
      },
      (err) => (this.saving = false)
    );
  }

  goBack() {
    this.location.back();
  }
}
