import { NgRedux } from '@redux/redux-compatibility.service';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { IndPhrase } from 'src/app/shared/models/cat.model';
import { AppState } from 'src/app/shared/redux/store';
import { CatService } from 'src/app/shared/services/cat.service';

@Component({
  selector: 'edit-phrase-order',
  templateUrl: './edit-phrase-order.component.html',
  styleUrls: ['./edit-phrase-order.component.scss'],
})
export class EditPhraseOrderComponent implements OnInit {
  @Output() onClose = new EventEmitter();
  @Output() onSave = new EventEmitter();
  loading: boolean;
  firstItemOrder: number;
  origData: Map<number, number>;

  get data(): IndPhrase[] {
    return this.ngRedux.getState().curCatDocStatePrime.indDocPhrases;
  }

  constructor(
    private ngRedux: NgRedux<AppState>,
    private catService: CatService
  ) {}

  ngOnInit(): void {
    this.firstItemOrder = this.data[0].phraseOrder;
    this.origData = new Map();
    this.data.forEach((r) => {
      this.origData.set(r.phraseId, r.phraseOrder);
    });
  }

  close() {
    this.onClose.emit();
    this.data.forEach((r) => {
      r.phraseOrder = this.origData.get(r.phraseId);
    });
    this.data.srp_sortByKey('phraseOrder');
  }

  save() {
    this.loading = true;
    this.catService
      .updPhraseOrder(
        this.ngRedux.getState().curCatDocStatePrime.curCatDoc.docId,
        this.data
      )
      .subscribe(
        (data) => {
          this.onSave.emit();
        },
        (err) => {}
      );
  }

  onReorder(event: any) {
    this.data.map((e) => {
      e.phraseOrder = this.data.indexOf(e) + this.firstItemOrder;
    });
    console.log(this.data);
  }
}
