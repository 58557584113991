import { NgRedux } from '@redux/redux-compatibility.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CardAssetTemplate } from 'src/app/shared/models/cat.model';
import { AppState } from 'src/app/shared/redux/store';
import { CatService } from 'src/app/shared/services/cat.service';

@Component({
  selector: 'edit-card-order',
  templateUrl: './edit-card-order.component.html',
  styleUrls: ['./edit-card-order.component.scss'],
})
export class EditCardOrderComponent implements OnInit {
  @Input() data: CardAssetTemplate;

  @Output() onClose = new EventEmitter();
  @Output() onSave = new EventEmitter();
  loading: boolean;
  origData: Map<number, number>;

  constructor(
    private ngRedux: NgRedux<AppState>,
    private catService: CatService
  ) {}

  ngOnInit(): void {
    this.origData = new Map();
    this.data.cards.forEach((r, idx) => {
      r.sortOrder = idx;
      this.origData.set(r.cardId, r.sortOrder);
    });
  }

  close() {
    this.onClose.emit();
    this.data.cards.forEach((r) => {
      r.sortOrder = this.origData.get(r.cardId);
    });
    this.data.cards.srp_sortByKey('sortOrder');
  }

  save() {
    this.loading = true;
    this.catService.updateCardAssetTemplate(this.data).subscribe(
      (data) => {
        this.onClose.emit();
      },
      (err) => (this.loading = false)
    );
  }

  onReorder(event: any) {
    this.data.cards.map((e) => {
      e.sortOrder = this.data.cards.indexOf(e);
    });
    console.log(this.data);
  }
}
