import { NgRedux } from '@redux/redux-compatibility.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BooleanColumnStatisticsData } from 'aws-sdk/clients/glue';
import {
  Catalog,
  CatDoc,
  CatDocsSearchParams,
  CatDocStateTagEnum,
  IndPhrase,
} from 'src/app/shared/models/cat.model';
import { actionsList, AppState } from 'src/app/shared/redux/store';
import { CatService } from 'src/app/shared/services/cat.service';

@Component({
  selector: 'add-ext-ref',
  templateUrl: './add-ext-ref.component.html',
  styleUrls: ['./add-ext-ref.component.scss'],
})
export class AddExtRefComponent implements OnInit {
  @Input() srcPhraseId: number;
  @Output() onClose = new EventEmitter<boolean>();

  mode: 'cat' | 'doc' | 'doc-view' = 'cat';
  selectedCat: Catalog;
  loading: boolean;
  docs: CatDoc[] = [];
  selectedDoc: CatDoc;

  get curCat(): Catalog {
    return this.ngRedux.getState().curCat;
  }

  constructor(
    private ngRedux: NgRedux<AppState>,
    private catService: CatService
  ) {}

  ngOnInit(): void {}

  close() {
    this.onClose.emit(false);
  }

  selectCat(cat: Catalog) {
    this.selectedCat = cat;
    this.loading = true;
    this.mode = 'doc';

    let searchParams = new CatDocsSearchParams();
    this.catService
      .getCatDocs(searchParams, this.selectedCat.catVersionId, false)
      .subscribe(
        (data) => {
          this.docs = data;
          this.loading = false;
        },
        (err) => (this.loading = false)
      );
  }

  selectDoc(doc: CatDoc) {
    this.ngRedux.dispatch({
      type: actionsList.SELECT_CAT_DOC,
      data: doc,
      catDocSTateTag: CatDocStateTagEnum.SEC,
    });

    this.selectedDoc = doc;
    this.mode = 'doc-view';
  }

  onAddExtRef() {
    this.onClose.emit(true);
  }

  closeDoc() {
    this.selectedDoc = null;
    this.mode = 'doc';
  }
}
