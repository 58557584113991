import { Component } from '@angular/core';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'secure-chk-ng';
  sub: Subscription;

  constructor(private router: Router, private route: ActivatedRoute) {}

  ngOnInit() {
    console.log(this.route);

    this.sub = this.router.events.subscribe((event) => {
      console.log(event); // If you see the log here, You can get lot of events. So based on your requirement you can able to filter
      if (event instanceof NavigationStart) {
        let url = (event as NavigationStart).url;
        console.log(url);
        this.sub.unsubscribe();
        if (url.startsWith('/dl?')) {
          console.log(this.route);
          let qpObject = {};
          let qp = url.replace('/dl?', '').split('&');
          qp.forEach((p) => {
            var key = p.substring(0, p.indexOf('='));
            var value = p.substring(p.indexOf('=') + 1, p.length);
            qpObject[key] = value;
          });
          this.router.navigate(['/login'], { queryParams: qpObject });
        } else {
          this.router.navigate(['/login']);
        }
      }
    });

    // this.route.queryParams.subscribe((params) => {
    //   if (params && params['dl']) {
    //     this.router.navigate(['/login', { queryParamsHandling: 'preserve' }]);
    //   } else {
    //     this.router.navigate(['/']);
    //   }
    // });
  }
}
