import { NgRedux } from '@redux/redux-compatibility.service';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Unsubscribe } from 'redux';
import {
  Catalog,
  CatDoc,
  CatDocStateTagEnum,
} from 'src/app/shared/models/cat.model';
import {
  ProcessRunStatusEnum,
  ProcessRunStatusResult,
} from 'src/app/shared/models/common';
import {
  LabelChange,
  LCCardTypeEnum,
  LCDoc,
  LCSearchParams,
} from 'src/app/shared/models/lc.model';
import { actionsList, AppState } from 'src/app/shared/redux/store';
import { AuthService } from 'src/app/shared/services/auth.service';
import { CatService } from 'src/app/shared/services/cat.service';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'lc-doc-list',
  templateUrl: './lc-doc-list.component.html',
  styleUrls: ['./lc-doc-list.component.scss'],
})
export class LCDocListComponent implements OnInit {
  @Input() type: 'ac' | 'bc';

  running: boolean;
  processStatusTimers: NodeJS.Timeout[] = [];
  getDocProcessStatusIntervalSeconds: number = 5;
  displayProcessStatus: boolean;
  curDoc: CatDoc;
  docSummaryOpenSw: boolean;
  reduxSubscribe: Unsubscribe;

  get lc(): LabelChange {
    return this.ngRedux.getState().curLabelChange;
  }

  get docs(): LCDoc[] {
    return this.type == 'ac' ? this.lc.acDocs : this.lc.bcDocs;
  }

  get docsProcessing(): boolean {
    return this.docs.some((d) => d.isProcessRunning);
  }

  constructor(
    private ngRedux: NgRedux<AppState>,
    private catService: CatService,
    private router: Router,
    private sharedService: SharedService,
    private authService: AuthService,
    private translateService: TranslateService
  ) {
    this.reduxSubscribe = this.ngRedux.subscribe(() => {
      const appState = this.ngRedux.getState();
      switch (appState._currentAction) {
        case actionsList.SELECT_LC:
          this.handleProcessingDocs();
          break;
      }
    });
  }

  ngOnInit(): void {
    this.handleProcessingDocs();
  }

  ngOnDestroy(): void {
    this.processStatusTimers.forEach((t) => clearInterval(t));
    this.reduxSubscribe();
  }

  viewDoc(row: CatDoc) {
    let cat = new Catalog();
    cat.catDescr = row.catDescr;
    cat.catId = row.catId;
    cat.statusCode = row.catStatusCode;
    cat.statusDescr = row.catStatusDescr;
    cat.catVersionId = row.catVersionId;
    this.ngRedux.dispatch({
      type: actionsList.SELECT_CATALOG,
      data: cat,
    });

    let doc;
    if (row.histDocs.length > 0) {
      doc = row.histDocs.find((d) => d.versionId == row.lastVersionId);
    } else {
      doc = row;
    }

    this.ngRedux.dispatch({
      type: actionsList.SELECT_CAT_DOC,
      data: doc,
      catDocSTateTag: CatDocStateTagEnum.PRIME,
    });
    this.router.navigate(['main/lc/cat-doc', 'lc-' + this.type]);
  }

  removeDoc(row: LCDoc) {
    this.docs.splice(this.docs.indexOf(row), 1);
    this.catService.updateLabelChange(this.lc).subscribe((data) => {});
  }

  // onTrainingSwChanged(doc: LCDoc) {
  //   doc.dirtySw = true;
  //   this.catService.updateLabelChange(this.lc).subscribe((data) => {
  //     doc.dirtySw = false;
  //   });
  // }

  reloadCurLC() {
    this.ngRedux.dispatch({
      type: actionsList.RELOAD_CUR_LC_REQ,
    });
    this.handleProcessingDocs();
  }

  handleProcessingDocs() {
    this.processStatusTimers = [];
    let processingDocs = this.docs.filter(
      (d) => d.isProcessRunning && d.runNum
    );
    if (processingDocs.length > 0) {
      processingDocs.forEach((d) => {
        let timer = setInterval(() => {
          this.authService.getProcessStatus(d.runNum).subscribe((data) => {
            d.processStatus = data;
            if (
              data.status == ProcessRunStatusEnum.FINISHED ||
              data.status == ProcessRunStatusEnum.FAILED
            ) {
              clearInterval(timer);
              this.reloadCurLC();
            }
          });
        }, this.getDocProcessStatusIntervalSeconds * 1000);

        this.processStatusTimers.push(timer);
      });
    }
  }

  openProcessStatus(doc: CatDoc) {
    this.displayProcessStatus = true;
    this.curDoc = doc;
  }

  hasBlocksTagged(doc: LCDoc) {
    let cards =
      this.type == 'bc'
        ? this.lc.cards.filter(
            (c) => c.lcCardType.id == LCCardTypeEnum.LC_NEED_TAG
          )
        : this.lc.cards.filter(
            (c) => c.lcCardType.id == LCCardTypeEnum.LC_DONE_TAG
          );

    return (
      cards.filter((c) => c.cardDocs.find((d) => d.docId == doc.docId)).length >
      0
    );
  }

  viewDocDetails(doc: LCDoc) {
    this.curDoc = doc;
    this.docSummaryOpenSw = true;
  }
}
