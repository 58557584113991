import { Injectable } from '@angular/core';
import { NgRedux } from '@redux/redux-compatibility.service';
import { AppState, actionsList } from '../redux/store';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from './auth.service';
import { DatePipe } from '@angular/common';
import { SharedService } from './shared.service';
import { Config } from 'src/app/config';
import {
  CatalogSearchParams,
  Catalog,
  CatDoc,
  CatDocsSearchParams,
  CatPhraseTypeModel,
  CatPhraseType,
  CatPhraseSubType,
  IndPhraseSearchParams,
  IndPhrase,
  CatPhraseStatusEnum,
  MergeLine,
  CatEntriesSearchParams,
  Card,
  BoundingBox,
  IndElement,
  IndImage,
  CatImagesSearchParams,
  McSubType,
  McBoxType,
  CardSearchParams,
  CardAssetTemplate,
  CatalogStatusEnum,
  ExtRef,
  CatExtRefSearchParams,
  CatDocStateTagEnum,
  CatAltRefSearchParams,
  AltRef,
} from '../models/cat.model';
import { Observable, of } from 'rxjs';
import { map, catchError, take } from 'rxjs/operators';
import { CardMatch, RevDoc, RevRule } from '../models/rev.model';
import { ServiceOptions } from '../models/service-options';
import { ServiceMetaData } from '../models/service-meta.model';
import {
  DataElement,
  ReferencePhrase,
  AddInstr,
  PhraseWord,
  CatMatch,
  MatchDiff,
  DocPage,
  RefMatch,
  WordChar,
  ServiceCallInfo,
  AnnotationElement,
  PhraseBorderLine,
  PolygonCorner,
  ProcessRunStatusResult,
  TblRow,
  TblCell,
  TblRowDiff,
  GrammarSpellingIssue,
  ReferenceIssue,
} from '../models/common';
import { bindActionCreators } from 'redux';
import { isNgContainer } from '@angular/compiler';
import { dispatch } from 'rxjs/internal/observable/pairs';
import { LabelChange, LCDoc, LCSearchParams } from '../models/lc.model';
import {
  TaggedDocsSearchParams,
  TaggedIndDoc,
} from '../models/tagged_doc.model';
import { Env } from '../models/user.model';
import { NumberAttributeValue } from 'aws-sdk/clients/clouddirectory';
import { TranslateService } from '@ngx-translate/core';
import { publicDecrypt } from 'crypto';
import { worker } from 'cluster';

@Injectable({
  providedIn: 'root',
})
export class CatService {
  private mockupDataUrl = 'assets/mock/';
  private scaiServiceUrl: string;
  private presignedUrls = new Map<string, string>();
  private objectsWaitingPresignedUrls = new Map<string, IndImage | BoundingBox | AddInstr | RevRule>();

  constructor(
    private ngRedux: NgRedux<AppState>,
    private http: HttpClient,
    private authService: AuthService,
    private datePipe: DatePipe,
    private sharedService: SharedService,
    private translateService: TranslateService
  ) {
    this.scaiServiceUrl = Config.assetConfigData['scaiServiceUrl'];
  }

  getCatList(
    searchParams: CatalogSearchParams,
    dispatch: boolean = true
  ): Observable<Catalog[]> {
    /*if (this.mockupSw) {
      return this.http
        .get(this.mockupDataUrl + 'get_cat_versions.json')
        .pipe(map((res) => this.extractGetCatList(res)));
    }*/

    let response = new Observable<Catalog[]>();
    let serviceName = 'get_cat_list_func';
    let params = new ServiceOptions();
    params.serviceName = serviceName;

    params['envId'] = searchParams.envId;
    if (searchParams.catIdName) params['catIdName'] = searchParams.catIdName;
    if (searchParams.products && searchParams.products.length > 0)
      params['productIdList'] = searchParams.products
        .map((r) => r.id)
        .join(',');
    if (searchParams.typeCode) params['catTypeCode'] = searchParams.typeCode;
    params['extCatSw'] = searchParams.extSw;

    if (searchParams.searchId) params['searchId'] = searchParams.searchId;
    if (searchParams.rangeFrom) params['fromRec'] = searchParams.rangeFrom;
    if (searchParams.rangeTo) params['toRec'] = searchParams.rangeTo;

    let url = this.authService.getRequestUrl(serviceName);
    let body = this.authService.getRequestBody(params);
    let info = new ServiceCallInfo(serviceName, url, body);
    response = this.http
      .post(url, body, {
        headers: this.authService.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json',
      })
      .pipe(
        map((data) =>
          this.extractGetCatList(data, info, dispatch, searchParams.extSw)
        ),
        catchError((error) => {
          return this.authService.handleError(error, info);
        })
      );

    return response;
  }

  private extractGetCatList(
    res: any,
    info: ServiceCallInfo,
    dispatch: boolean,
    extSw: boolean
  ): Catalog[] {
    console.log(res);
    this.authService.extractRequestStatus(info, res);
    let result: Catalog[] = [];
    let data = res.reply.requestResult.data;
    let metaData = new ServiceMetaData();

    if (data.metadata) {
      let metaTag = data.metadata;
      metaData.totalRecords = metaTag.totRec;
      metaData.fromRecord = metaTag.fromRec;
      metaData.toRecord = metaTag.toRec;
      metaData.searchId = metaTag.searchId;
    }

    if (data) {
      let dataTag = data.cat;
      if (dataTag) {
        let arr = new Array().concat(dataTag);
        arr.forEach((i) => {
          result.push(this._extractCatTag(i));
        });

        result.srp_sortByKey('catId', -1);
      }

      if (!metaData.searchId && result) {
        metaData.fromRecord = 1;
        metaData.toRecord = result.length;
        metaData.totalRecords = result.length;
      }
    }

    if (dispatch)
      this.ngRedux.dispatch({
        type: extSw
          ? actionsList.GET_EXT_CATALOG_LIST
          : actionsList.GET_CATALOG_LIST,
        data: result,
        reply: res,
        metaData: metaData,
      });

    return result;
  }

  _extractCatTag(i: any): Catalog {
    let c = new Catalog();
    c.catId = i.catId;
    c.catDescr = i.descr;
    c.activeVersionId = i.lastVersionId;
    c.productId = i.productId;
    c.productDescr = i.productDescr;
    c.typeCode = i.catTypeCode;
    c.typeDescr = i.catTypeDescr;
    c.extSw = i.extCatSw;
    c.langCode = i.catLangCode;
    c.langDescr = i.catLangDescr;

    if (i.catAssetTypes) {
      let assetTypeArr = new Array().concat(i.catAssetTypes);
      assetTypeArr.forEach((t) => {
        c.catAssetTypeList.push(new DataElement(t.assetType, t.assetDescr));
      });
    }

    let vArr = new Array().concat(i.catVersions);
    vArr.forEach((v) => {
      let version = new Catalog();
      version.catId = c.catId;
      version.catDescr = c.catDescr;
      version.productId = c.productId;
      version.productDescr = c.productDescr;
      version.typeCode = c.typeCode;
      version.typeDescr = c.typeDescr;
      version.langCode = c.langCode;
      version.langDescr = c.langDescr;
      version.versionId = v.versionId;
      version.catVersionId = v.catVersionId;
      version.extSw = c.extSw;
      version.versionName = v.descr;
      if (!version.versionName)
        version.versionName = '(' + version.versionId.toString() + ')';
      version.createDate = this.authService.formatDBDateTime(v.crDate);
      version.statusCode = v.catStatusCode;
      version.statusDate = this.authService.formatDBDateTime(v.catStatusDate);
      version.statusDescr = v.catStatusDescr;
      version.totDocCnt = v.totDocCnt;
      version.catAssetTypeList = c.catAssetTypeList;

      if (v.extCat) {
        let extCatArr = new Array().concat(v.extCat);
        extCatArr.forEach((e) => {
          let extCat = new Catalog();
          extCat.catId = e.catId;
          extCat.catDescr = e.catDescr;
          extCat.catVersionId = e.extCatVersionId;
          extCat.productDescr = e.productDescr;
          extCat.productId = e.productId;
          version.extCat.push(extCat);
        });
      }

      c.catVersions.push(version);
    });

    c.catVersions.srp_sortByKey('catVersionId');

    return c;
  }

  getCatTypeList(): Observable<DataElement[]> {
    let serviceName = 'get_cat_type_list_func';
    let response = new Observable<DataElement[]>();
    let params = new ServiceOptions();
    params.serviceName = serviceName;

    let url = this.authService.getRequestUrl(serviceName);
    let body = this.authService.getRequestBody(params);
    let info = new ServiceCallInfo(serviceName, url, body);

    response = this.http
      .post(url, body, {
        headers: this.authService.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json',
      })
      .pipe(
        take(1),
        map((data) => {
          return this.extractCatTypes(data, info);
        }),
        catchError((err) => {
          return this.authService.handleError(err, info);
        })
      );
    return response;
  }

  private extractCatTypes(res, info: ServiceCallInfo): DataElement[] {
    const results: DataElement[] = this.authService.mapToDataElement(
      res,
      'catTypeCode',
      'descr',
      'catType',
      info
    );

    this.ngRedux.dispatch({
      type: actionsList.GET_CAT_TYPES,
      data: results,
      reply: res,
    });

    return results;
  }

  getCatReferenceParsingMatchTypeList(): Observable<DataElement[]> {
    let serviceName = 'get_cat_ref_match_type_list_func';
    let response = new Observable<DataElement[]>();
    let params = new ServiceOptions();
    params.serviceName = serviceName;

    let url = this.authService.getRequestUrl(serviceName);
    let body = this.authService.getRequestBody(params);
    let info = new ServiceCallInfo(serviceName, url, body);

    response = this.http
      .post(url, body, {
        headers: this.authService.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json',
      })
      .pipe(
        take(1),
        map((data) => {
          return this.extractCatReferenceParsingMatchType(data, info);
        }),
        catchError((err) => {
          return this.authService.handleError(err, info);
        })
      );
    return response;
  }

  private extractCatReferenceParsingMatchType(res, info: ServiceCallInfo): DataElement[] {
    this.authService.extractRequestStatus(info, res);

    if (!res || !res.reply.requestResult.data) return [];

    let dataList = new Array<DataElement>();
    let data = res.reply.requestResult.data["mlCatRefMatchType"];

    if (data && data.length) {
      dataList = data.map((item: any) => {
        let row = new DataElement(item["catRefMatchTypeCode"], item["catRefMatchTypeDesc"]);
        return row;
      });
    } else {
      dataList = [];
    }

    const results: DataElement[] = dataList;

    this.ngRedux.dispatch({
      type: actionsList.GET_REF_MATCH_TYPES,
      data: results,
      reply: res,
    });

    return results;
  }

  getCatLangList(): Observable<DataElement[]> {
    let serviceName = 'get_cat_lang_list_func';
    let response = new Observable<DataElement[]>();
    let params = new ServiceOptions();
    params.serviceName = serviceName;

    let url = this.authService.getRequestUrl(serviceName);
    let body = this.authService.getRequestBody(params);
    let info = new ServiceCallInfo(serviceName, url, body);

    response = this.http
      .post(url, body, {
        headers: this.authService.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json',
      })
      .pipe(
        take(1),
        map((data) => {
          return this.extractCatLangList(data, info);
        }),
        catchError((err) => {
          return this.authService.handleError(err, info);
        })
      );
    return response;
  }

  private extractCatLangList(res, info: ServiceCallInfo): DataElement[] {
    this.authService.extractRequestStatus(info, res);

    if (!res || !res.reply.requestResult.data) return [];

    let dataList = new Array<DataElement>();
    let data = res.reply.requestResult.data["mlCatLang"];

    if (data && data.length) {
      dataList = data.map((item: any) => {
        let row = new DataElement(item["langCode"], item["langDesc"]);
        return row;
      });
    } else {
      dataList = [];
    }

    const results: DataElement[] = dataList;

    this.ngRedux.dispatch({
      type: actionsList.GET_CAT_LANGUAGES,
      data: results,
      reply: res,
    });

    return results;
  }

  getCatEntries(searchParams: CatEntriesSearchParams): Observable<number> {
    let serviceName = 'get_cat_entries_func';
    let response = new Observable<number>();
    let params = new ServiceOptions();
    params.serviceName = serviceName;

    params['envId'] = this.ngRedux.getState().curEnv.id;

    params['catVersionId'] = Number.parseInt(
      this.ngRedux.getState().curCat.catVersionId as any
    );

    if (searchParams.text) params['phraseTxt'] = searchParams.text;
    if (searchParams.subTypeCodes)
      params['subTypeCodes'] = searchParams.subTypeCodes;
    if (searchParams.docs && searchParams.docs.length > 0)
      params['docIdList'] = searchParams.docs.map((d) => d.id).join(',');
    if (searchParams.catEntryStatus)
      params['catEntryStatus'] = searchParams.catEntryStatus.id;

    if (searchParams.refStarterSw)
      params['refStarterSw'] = searchParams.refStarterSw;

    if (searchParams.searchId) params['searchId'] = searchParams.searchId;
    if (searchParams.rangeFrom) params['fromRec'] = searchParams.rangeFrom;
    if (searchParams.rangeTo) params['toRec'] = searchParams.rangeTo;

    let url = this.authService.getRequestUrl(serviceName);
    let body = this.authService.getRequestBody(params);

    return this.authService.runGeneralSearch(body);
    // let info = new ServiceCallInfo(serviceName, url, body);
    // response = this.http
    //   .post(url, body, {
    //     headers: this.authService.getRequestHeaders(),
    //     withCredentials: false,
    //     responseType: 'json',
    //   })
    //   .pipe(
    //     map((data) => this.extractGetCatEntries(data, info)),
    //     catchError((error) => {
    //       return this.authService.handleError(error, info);
    //     })
    //   );

    // return response;
  }

  getCatEntriesSearch(searchId: number): Observable<IndPhrase[]> {
    let serviceName = 'get_search_results_func';
    let response = new Observable<IndPhrase[]>();
    let params = new ServiceOptions();
    params.serviceName = serviceName;

    params['searchId'] = searchId;

    let url = this.authService.getRequestUrl(serviceName);
    let body = this.authService.getRequestBody(params);
    let info = new ServiceCallInfo(serviceName, url, body);
    response = this.http
      .post(url, body, {
        headers: this.authService.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json',
      })
      .pipe(
        map((data) => this.extractGetCatEntries(data, info)),
        catchError((error) => {
          return this.authService.handleError(error, info);
        })
      );

    return response;
  }

  private extractGetCatEntries(res: any, info: ServiceCallInfo): IndPhrase[] {
    this.authService.extractRequestStatus(info, res);
    let result: IndPhrase[] = [];
    let data = res.reply.requestResult.data;
    let metaData = new ServiceMetaData();

    if (data.metadata) {
      let metaTag = data.metadata;
      metaData.totalRecords = metaTag.totRec;
      metaData.fromRecord = metaTag.fromRec;
      metaData.toRecord = metaTag.toRec;
      metaData.searchId = metaTag.searchId;
    }

    if (data) {
      let dataTag = data.catEntry;
      if (dataTag) {
        let arr = new Array().concat(dataTag);
        arr.forEach((i) => {
          result.push(this._extractCatEntryTag(i));
        });
      }

      if (!metaData.searchId && result) {
        metaData.fromRecord = 1;
        metaData.toRecord = result.length;
        metaData.totalRecords = result.length;
      }
    }

    this.ngRedux.dispatch({
      type: actionsList.GET_CAT_ENTRIES,
      data: result,
      reply: res,
      metaData: metaData,
    });

    return result;
  }

  _extractCatEntryTag(i: any): IndPhrase {
    let e = new IndPhrase();
    e.phraseId = e.elementId = i.catPhraseId;
    e.phraseText = i.txt;
    e.typeCode = i.phraseTypeCode;
    e.typeDescr = i.phraseTypeDescr;
    e.subTypeCode = i.phraseSubtypeCode;
    e.subTypeDescr = i.phraseSubtypeDescr;
    e.errorLevel = i.errorLevel;
    e.sortOrder = i.sortOrder;
    e.manualSrcSw = this.authService.fromXMLBoolean(i.manualSrcSw);
    e.isRefSw = i.isRefSw;
    if (i.catEntryStatus) {
      e.catEntryStatus = new DataElement(
        i.catEntryStatus,
        i.catEntryStatusDescr
      );
    }

    if (i.phraseDispWords) {
      let arrWords = new Array().concat(i.phraseDispWords);
      e.phraseWords = [];
      arrWords.forEach((w) => {
        e.phraseWords.push(this._extractPhraseWordTag(w));
      });
    }

    let refTag = i.refernces;
    if (refTag) {
      e.references = [];
      let refArr = new Array().concat(refTag);
      refArr.forEach((r) => {
        let ref = new ReferencePhrase();
        ref.refPhraseId = r.refPhraseId;
        ref.refPhraseText = r.txt;
        ref.refType = r.refTypeCode;
        ref.pointerWordId = r.refPointerWordId;
        ref.origPointerWordId = ref.pointerWordId;

        e.references.push(ref);
      });
    }

    let instrTag = i.addInstr;
    if (instrTag) {
      e.addInstr = [];
      let instrArr = new Array().concat(instrTag);
      instrArr.forEach((n) => {
        let instr = new AddInstr();
        instr.id = n.id;
        instr.text = n.txt;
        instr.imgS3Key = n.imgS3Key;
        this.assignOrSubscribeObjectForPresignedImgUrl(instr, instr.imgS3Key);
        instr.type = instr.text ? { value: 'TXT' } : { value: 'IMG' };
        if (instr.imgS3Key != null || instr.text != null) e.addInstr.push(instr);
      });
      e.addInstr.srp_sortByKey('id');
    }

    let docTag = i.doc;
    if (docTag) {
      e.docs = [];
      let docArr = new Array().concat(docTag);
      docArr.forEach((d) => {
        let doc = new CatDoc();
        doc.docId = d.docId;
        doc.docName = d.docName;
        doc.versionId = d.versionId;
        doc.fileName = d.fileName;
        doc.docS3Key = d.docS3Key;
        doc.inddS3Key = d.inddS3Key;
        doc.pagePdfS3Key = d.pagePdfS3Key;
        doc.createDate = this.authService.formatDBDateTime(d.crDate);
        doc.singlePhrasePageUrl = d.phrasePageUrl;
        doc.singlePhraseEl = d.phraseEl;
        doc.singlePhrasePageNum = d.pageNum + 1;
        doc.pagesNum = d.pagesCnt;
        let ann = new AnnotationElement();
        ann.x1 = d.x1;
        ann.x2 = d.x2;
        ann.y1 = d.y1;
        ann.y2 = d.y2;
        this._updPhraseAnnElementPolygon(ann, d.borderLines);
        ann.annColor = '#ff2229';
        ann.annId = e.elementId;
        doc.annotation = ann;
        e.docs.push(doc);
      });
    }

    return e;
  }

  getCatDocs(
    searchParams: CatDocsSearchParams,
    catVersionId: number,
    dispatchDocList: boolean = true
  ): Observable<CatDoc[]> {
    let serviceName = 'get_ind_doc_func';

    let response = new Observable<CatDoc[]>();
    let params = new ServiceOptions();
    params.serviceName = serviceName;

    params['envId'] = this.ngRedux.getState().curEnv.id;
    // params['catVersionId'] = Number.parseInt(
    //   this.ngRedux.getState().curCat.catVersionId as any
    // );

    params['catVersionId'] = Number.parseInt(catVersionId as any);

    if (searchParams.docId) params['docId'] = searchParams.docId;
    if (searchParams.docIdName) params['docIdName'] = searchParams.docIdName;
    if (searchParams.status) params['statusCode'] = searchParams.status.id;
    params['includeInactiveSw'] = searchParams.includeInactiveSw || false;

    if (searchParams.searchId) params['searchId'] = searchParams.searchId;
    if (searchParams.rangeFrom) params['fromRec'] = searchParams.rangeFrom;
    if (searchParams.rangeTo) params['toRec'] = searchParams.rangeTo;

    let url = this.authService.getRequestUrl(serviceName);
    let body = this.authService.getRequestBody(params);
    let info = new ServiceCallInfo(serviceName, url, body);
    response = this.http
      .post(url, body, {
        headers: this.authService.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json',
      })
      .pipe(
        map((data) => this.extractGetCatDocs(data, dispatchDocList, info)),
        catchError((error) => {
          return this.authService.handleError(error, info);
        })
      );

    return response;
  }

  private extractGetCatDocs(
    res: any,
    dispatchDocList: boolean,
    info: ServiceCallInfo
  ): CatDoc[] {
    this.authService.extractRequestStatus(info, res);
    let result: CatDoc[] = [];
    let data = res.reply.requestResult.data;
    let metaData = new ServiceMetaData();

    if (data.metadata) {
      let metaTag = data.metadata;
      metaData.totalRecords = metaTag.totRec;
      metaData.fromRecord = metaTag.fromRec;
      metaData.toRecord = metaTag.toRec;
      metaData.searchId = metaTag.searchId;
    }

    if (data) {
      let dataTag = data.indDocs;
      if (dataTag) {
        let arr = new Array().concat(dataTag);
        arr.forEach((i) => {
          result.push(this._extractCatDocTag(i));
        });
      }

      if (!metaData.searchId && result) {
        metaData.fromRecord = 1;
        metaData.toRecord = result.length;
        metaData.totalRecords = result.length;
      }
    }

    result.srp_sortByKey('docId', -1);

    if (dispatchDocList)
      this.ngRedux.dispatch({
        type: actionsList.GET_CAT_DOCS,
        data: result,
        reply: res,
        metaData: metaData,
      });

    return result;
  }

  _extractCatDocTag(i: any): CatDoc {
    let d = new CatDoc();
    d.docId = i.parentDocId; //i.docId;
    d.origDocId = i.docId;
    if (!d.docId) d.docId = i.docId;
    d.docName = i.docName;
    d.lastVersionId = i.versionId;
    d.catVersionId = i.catVersionId;
    // d.createDate = this.datePipe.transform(
    //   i.crDate,
    //   SharedService.appDateFormat,
    //   this.authService.currentUser.timezone
    // );
    d.createDate = this.authService.formatDBDateTime(i.crDate);
    d.statusCode = i.indDocStatusCode;
    d.statusDescr = i.indDocStatusDescr;
    d.progressPct = i.progressPct;
    d.runNum = i.runNum;
    d.runStatusCode = i.runStatusCode;
    d.runStatusMessage = i.runStatusMessage;
    d.attachedCardSw = this.authService.fromXMLBoolean(i.attachedCardSw);
    if (i.assetType) {
      d.assetType = new DataElement(i.assetType, i.assetDescr);
    }
    d.taggedSw = this.authService.fromXMLBoolean(i.taggedSw);
    d.productId = i.productId;
    d.productDescr = i.productDescr;
    d.catDescr = i.catDescr;
    d.catStatusCode = i.catStatusCode;
    d.catStatusDescr = i.catStatusDescr;
    d.pagesNum = i.pageNums;
    d.inddDocS3Key = i.inddDocUrl;
    d.inddCardS3Key = i.inddCardUrl;

    let catalog = new Catalog();
    catalog.catId = d.catId;
    catalog.catDescr = d.catDescr;
    catalog.catVersionId = d.catVersionId;
    catalog.statusCode = d.catStatusCode;
    catalog.statusDescr = d.catStatusDescr;
    d.catalog = catalog;

    let histTag = i.versions;
    if (histTag) {
      let histArr = new Array().concat(histTag);
      histArr.forEach((h) => {
        let histDoc = new CatDoc();
        histDoc.taggedSw = this.authService.fromXMLBoolean(h.taggedSw);
        histDoc.docId = h.docId;
        histDoc.origDocId = histDoc.docId;
        histDoc.docName = d.docName;
        histDoc.versionId = h.versionId;
        histDoc.fileName = h.fileName;
        histDoc.docType = h.docType;
        histDoc.docS3Key = h.docS3Key;
        histDoc.inddS3Key = h.inddS3Key;
        histDoc.createDate = this.authService.formatDBDateTime(h.crDate);
        histDoc.statusCode = h.indDocStatusCode;
        histDoc.statusDescr = h.indDocStatusDescr;
        histDoc.progressPct = h.progressPct;
        histDoc.pagesNum = h.pageNums;
        histDoc.runStatusCode = h.runStatusCode;
        histDoc.runNum = h.runNum;
        histDoc.catVersionId = h.catVersionId;
        if (h.assetType) {
          histDoc.assetType = new DataElement(h.assetType, h.assetDescr);
        }
        histDoc.attachedCardSw = this.authService.fromXMLBoolean(
          h.attachedCardSw
        );

        if (h.pages) {
          histDoc.pages = [];
          let pArr = new Array().concat(h.pages);
          pArr.forEach((p) => {
            let page = new DocPage();
            page.bucket = p.bucket;
            page.pageNum = p.dispPageNum;
            page.s3Key = p.s3Key;
            page.url = p.url;

            histDoc.pages.push(page);
          });
        }

        d.histDocs.push(histDoc);
      });
    }

    let boxTag = i.boundingBox;
    if (boxTag) {
      let boxesArr = new Array().concat(boxTag);
      boxesArr.forEach((b) => {
        let box = this._extractBoundingBoxTag(b);
        d.boundingBoxes.push(box);
      });
      d.boundingBoxes.srp_sortByKey('boxId');
    }

    return d;
  }

  getCatPhraseTypes(
    catId: number,
    dispatch: boolean = true
  ): Observable<CatPhraseTypeModel> {
    let serviceName = 'get_cat_phrase_types_func';

    /*if (this.mockupSw) {
      return this.http
        .get(this.mockupDataUrl + 'get_cat_phrase_types.json')
        .pipe(map((res) => this.extractGetCatPhraseTypes(res)));
    }*/

    let response = new Observable<CatPhraseTypeModel>();
    let params = new ServiceOptions();
    params.serviceName = serviceName;
    params['catId'] = Number.parseInt(catId as any);

    let url = this.authService.getRequestUrl(serviceName);
    let body = this.authService.getRequestBody(params);
    let info = new ServiceCallInfo(serviceName, url, body);
    response = this.http
      .post(url, body, {
        headers: this.authService.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json',
      })
      .pipe(
        map((data) => this.extractGetCatPhraseTypes(data, dispatch, info)),
        catchError((error) => {
          return this.authService.handleError(error, info);
        })
      );

    return response;
  }

  private extractGetCatPhraseTypes(
    res: any,
    dispatch: boolean,
    info: ServiceCallInfo
  ): CatPhraseTypeModel {
    this.authService.extractRequestStatus(info, res);
    let result: CatPhraseTypeModel = new CatPhraseTypeModel();
    let data = res.reply.requestResult.data;

    if (data) {
      let dataTag = data.phrasetype;
      if (dataTag) {
        let arr = new Array().concat(dataTag);
        arr.forEach((i) => {
          let type = new CatPhraseType();
          type.typeCode = i.phraseTypeCode;
          type.typeDescr = i.descr;

          let subTypesTag = i.phraseSubType;
          if (subTypesTag) {
            let subArr = new Array().concat(subTypesTag);
            subArr.forEach((s) => {
              let subType = new CatPhraseSubType();
              subType.typeCode = type.typeCode;
              subType.typeDescr = type.typeDescr;
              subType.subTypeCode = s.phraseSubTypeCode;
              subType.subTypeDescr = s.descr;
              subType.errorLevel = s.errorLevel;
              subType.level0 = s.level0;
              subType.level1 = s.level1;
              subType.algKeyWords = s.algKeyWords;
              type.subTypes.push(subType);
            });

            type.subTypes.srp_sortByKey('subTypeDescr');
          }

          result.types.push(type);
        });
      }
    }

    result.buildTree();

    if (dispatch)
      this.ngRedux.dispatch({
        type: actionsList.GET_CAT_PHRASE_TYPES,
        data: result,
        reply: res,
      });

    return result;
  }

  getIndPhrases(
    docId: number,
    pageNum: number,
    searchParams: IndPhraseSearchParams
  ): Observable<number> {
    let serviceName = 'get_ind_phrases_func';
    let params = new ServiceOptions();
    params.serviceName = serviceName;

    params['docId'] = docId;
    if (pageNum) params['dispPageNum'] = pageNum;

    if (searchParams) {
      if (searchParams.phrase) params['phraseText'] = searchParams.phrase;
      if (searchParams.matchStatus)
        params['matchStatusCode'] = searchParams.matchStatus.id;
      if (searchParams.phraseStatus)
        params['indPhraseStatusCode'] = searchParams.phraseStatus.id;
      if (searchParams.contentType)
        params['indPhraseContentType'] = searchParams.contentType.id;
      if (searchParams.matchType)
        params['indPhraseMatchType'] = searchParams.matchType.id;
      if (searchParams.subTypeList && searchParams.subTypeList.length > 0)
        params['subTypeList'] = searchParams.subTypeList;
    }

    let body = this.authService.getRequestBody(params);

    return this.authService.runGeneralSearch(body);
  }

  getIndPhrasesSearch(
    searchId: number,
    catDocSTateTag: CatDocStateTagEnum
  ): Observable<void> {
    let serviceName = 'get_search_results_func';
    let response = new Observable<void>();
    let params = new ServiceOptions();
    params.serviceName = serviceName;

    params['searchId'] = searchId;

    let url = this.authService.getRequestUrl(serviceName);
    let body = this.authService.getRequestBody(params);
    let info = new ServiceCallInfo(serviceName, url, body);
    response = this.http
      .post(url, body, {
        headers: this.authService.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json',
      })
      .pipe(
        map((data) => this.extractGetIndPhrases(data, info, catDocSTateTag)),
        catchError((error) => {
          return this.authService.handleError(error, info);
        })
      );

    return response;
  }

  _exctractPageLines(linesTag: any): MergeLine[] {
    let pageLines: MergeLine[] = [];
    let arr = new Array().concat(linesTag);
    arr.forEach((b) => {
      let opt = new MergeLine();
      opt.id = b.lineId;
      opt.text = b.lineTxt;
      opt.phraseId = b.phraseId;
      opt.uniqueId = opt.id + '-' + opt.phraseId;
      if (b.phraseDispWords) {
        let arrWords = new Array().concat(b.phraseDispWords);
        arrWords.forEach((i) => {
          opt.phraseWords.push(this._extractPhraseWordTag(i));
        });
      }
      pageLines.push(opt);
    });
    return pageLines;
  }

  private extractGetIndPhrases(
    res: any,
    info: ServiceCallInfo,
    catDocSTateTag: CatDocStateTagEnum
  ): void {
    this.authService.extractRequestStatus(info, res);
    let phrases: IndPhrase[] = [];
    let images: IndImage[] = [];
    let data = res.reply.requestResult.data;

    if (data) {
      let pageLines: MergeLine[] = [];
      let linesTag = data.pageLines;
      if (linesTag) {
        pageLines = this._exctractPageLines(linesTag);
      }
      this.ngRedux.dispatch({
        type: actionsList.GET_CAT_DOC_PAGE_LINES,
        data: pageLines,
        reply: res,
        catDocSTateTag: catDocSTateTag,
      });

      let dataTag = data.indPhrase;
      if (dataTag) {
        let arr = new Array().concat(dataTag);
        arr.forEach((i) => {
          phrases.push(this._extractIndPhraseTag(i, pageLines));
        });
      }

      let imgTag = data.indImage;
      if (imgTag) {
        let arr = new Array().concat(imgTag);
        arr.forEach((i) => {
          images.push(this._extractIndImageTag(i));
        });
      }

      images.srp_sortByKey('imageId');

      this.ngRedux.dispatch({
        type: actionsList.GET_IND_DOC_IMAGES,
        data: images,
        reply: res,
        catDocSTateTag: catDocSTateTag,
      });

      let statsTag = data.phraseStats;
      if (statsTag) {
        this.updCatDocStats(statsTag);
      }

      let boundingBoxes: BoundingBox[] = [];
      let boxesTag = data.boundingbox;
      if (boxesTag) {
        let arr = new Array().concat(boxesTag);
        arr.forEach((b) => {
          boundingBoxes.push(this._extractBoundingBoxTag(b));
        });
      }

      this.ngRedux.dispatch({
        type: actionsList.GET_BOUNDING_BOXES,
        data: boundingBoxes,
        reply: res,
        catDocSTateTag: catDocSTateTag,
      });
    }

    // phrases.srp_sortByKey('phraseId');

    this.ngRedux.dispatch({
      type: actionsList.GET_IND_DOC_PHRASES,
      data: phrases,
      reply: res,
      catDocSTateTag: catDocSTateTag,
    });
  }

  _extractIndImageTag(i: any): IndImage {
    let img = new IndImage();
    img.imageId = img.elementId = i.imageId;
    img.imgS3Key = i.imgS3Key;
    this.assignOrSubscribeObjectForPresignedImgUrl(img, img.imgS3Key);
    img.statusCode = i.indImageStatusCode;
    img.statusDescr = i.indImageStatusDescr;
    img.similarityCode = i.matchSimilarityCode;
    img.similarityDescr = i.matchSimilarityDescr;
    img.similarityPct = i.similarityPct;
    img.pageNum = i.pageNum;
    img.x1 = i.x1;
    img.x2 = i.x2;
    img.y1 = i.y1;
    img.y2 = i.y2;
    this._updPhraseAnnElementPolygon(img, i.phraseBorder);
    img.typeCode = i.imageTypeCode;
    img.subTypeCode = i.imageSubTypeCode;
    img.typeDescr = i.imageTypeDescr;
    img.subTypeDescr = i.imageSubTypeDescr;
    img.bgImgSw = this.authService.fromXMLBoolean(i.bgSw);
    img.docId = i.docId;
    img.figureType = i.figureType;
    img.figureTypeDescr = i.figureTypeDescr;
    if (i.figureSubType) {
      img.figureSubType = new DataElement(
        i.figureSubType,
        i.figureSubTypeDescr
      );
    }

    img.userStatus = this.sharedService
      .getCatPhraseUpdStatusOptions()
      .find((e) => e.id == img.statusCode);

    let instrTag = i.addInstr;
    if (instrTag) {
      img.addInstr = [];
      let instrArr = new Array().concat(instrTag);
      instrArr.forEach((n) => {
        let instr = new AddInstr();
        instr.id = n.id;
        instr.text = n.txt;
        instr.imgS3Key = n.imgS3Key;
        this.assignOrSubscribeObjectForPresignedImgUrl(instr, instr.imgS3Key);
        instr.type = instr.text ? { value: 'TXT' } : { value: 'IMG' };
        if (instr.imgS3Key != null || instr.text != null)
          img.addInstr.push(instr);
      });
      img.addInstr.srp_sortByKey('id');
    }

    if (i.catMatch) {
      let matchArr = new Array().concat(i.catMatch);
      img.catMatches = [];
      matchArr.forEach((j) => {
        let m = new CatMatch();
        m.catMatchSeq = j.catMatchSeq;
        m.similarityPct = j.similarityPct;

        if (j.catImage) {
          m.catImage = this._extractCatImageTag(j.catImage);
        }

        if (j.tblRowDiff) {
          m.tblRowDiff = [];
          let diffArr = new Array().concat(j.tblRowDiff);
          diffArr.forEach((d) => {
            let rowDiff = new TblRowDiff();

            let cellsArr = new Array().concat(d.catRow.cells);
            cellsArr.forEach((c) => {
              let cell = new TblCell();
              cell.cellId = c.cellId;
              cell.txt = c.txt;
              cell.diffType = c.diffType;
              rowDiff.catRow.cells.push(cell);
            });

            cellsArr = new Array().concat(d.docRow.cells);
            cellsArr.forEach((c) => {
              let cell = new TblCell();
              cell.cellId = c.cellId;
              cell.txt = c.txt;
              cell.diffType = c.diffType;
              rowDiff.docRow.cells.push(cell);
            });

            m.tblRowDiff.push(rowDiff);
          });
        }

        img.catMatches.push(m);
      });

      img.catMatches.srp_sortByKey('similarityPct', -1);
    }

    // rule engine
    img.imageSimilarityEvent = i.imageSimilarityEvent;
    img.imageSimilarityEventDesc = i.imageSimilarityEventDesc;
    if (i.imageFindingEvents) {
      img.imageFindingEvents = i.imageFindingEvents.split(',')
    }
    if (i.imageActions) {
      img.imageActions = i.imageActions.split(',')
    }

    return img;
  }

  _extractIndPhraseTag(i: any, pageLines: MergeLine[]): IndPhrase {
    let p = new IndPhrase();
    p.phraseId = p.elementId = i.phraseId;
    p.phraseText = i.phraseTxt;
    p.pageNum = i.dispPageNum;
    p.docId = i.docId;
    p.statusCode = i.indPhraseStatusCode;
    p.statusDescr = i.indPhraseStatusDescr;
    p.typeCode = i.phraseTypeCode;
    p.typeDescr = i.phraseTypeDescr;
    p.subTypeCode = i.phraseSubTypeCode;
    p.subTypeDescr = i.phraseSubTypeDescr;
    if (i.phraseSubType) p.errorLevel = i.phraseSubType.errorLevel;
    p.isRefSw = this.authService.fromXMLBoolean(i.isRefSw);
    p.similarityCode = i.matchSimilarityCode;
    p.similarityDescr = i.matchSimilarityDescr;
    p.similarityPct = i.similarityPct;
    p.docId = i.docId;
    p.transTxt = i.transTxt;
    p.phraseOrder = i.phraseOrder;

    p.userStatus = this.sharedService
      .getCatPhraseUpdStatusOptions()
      .find((e) => e.id == p.statusCode);

    p.x1 = i.x1;
    p.y1 = i.y1;
    p.x2 = i.x2;
    p.y2 = i.y2;
    p.bgImgSw = this.authService.fromXMLBoolean(i.bgSw);
    this._updPhraseAnnElementPolygon(p, i.phraseBorder);

    p.pageHeight = i.pageYsize;
    p.pageWidth = i.pageXsize;

    if (i.refPhrases) {
      let arrRef = new Array().concat(i.refPhrases);
      p.references = [];
      arrRef.forEach((e) => {
        let ref = new ReferencePhrase();
        ref.refPhraseId = e.refPhraseId;
        ref.refPhraseText = e.refTxt;
        ref.refType = e.refTypeCode;
        ref.phraseId = e.phraseId;
        ref.pointerWordId = e.pointerWordId;
        ref.origPointerWordId = ref.pointerWordId;
        if (e.phraseDispWords) {
          let arrWords = new Array().concat(e.phraseDispWords);
          arrWords.forEach((w) => {
            ref.phraseDispWords.push(this._extractPhraseWordTag(w));
          });
        }
        if(!p.references.find(obj => obj.refPhraseId == ref.refPhraseId)) {
          p.references.push(ref);
        }
      });
    }

    if (i.refByPhrases) {
      let arrRefBy = new Array().concat(i.refByPhrases);
      p.referencedBy = [];
      arrRefBy.forEach((b) => {
        let ref = new ReferencePhrase();
        ref.refPhraseId = b.refPhraseId;
        ref.refPhraseText = b.txt;
        ref.refType = b.refTypeCode;
        ref.phraseId = b.phraseId;
        if (b.phraseDispWords) {
          let arrWords = new Array().concat(b.phraseDispWords);
          arrWords.forEach((w) => {
            ref.phraseDispWords.push(this._extractPhraseWordTag(w));
          });
        }
        if(!p.referencedBy.find(obj => obj.phraseId == ref.phraseId)) {
          p.referencedBy.push(ref);
        }
      });
    }

    if (i.addInstr) {
      let arrInstr = new Array().concat(i.addInstr);
      p.addInstr = [];
      arrInstr.forEach((i) => {
        let instr = new AddInstr();
        instr.id = i.instId;
        instr.imgS3Key = i.imgS3Key;
        this.assignOrSubscribeObjectForPresignedImgUrl(instr, instr.imgS3Key);
        instr.text = i.instTxt;
        instr.type = instr.text ? { value: 'TXT' } : { value: 'IMG' };
        if (instr.imgS3Key != null || instr.text != null) p.addInstr.push(instr);
      });

      p.addInstr.srp_sortByKey('id');
    }

    if (i.phraseDispWords) {
      let arrWords = new Array().concat(i.phraseDispWords);
      p.phraseWords = [];
      arrWords.forEach((i) => {
        p.phraseWords.push(this._extractPhraseWordTag(i));
      });
    }

    if (pageLines) {
      p.mergeOptions = JSON.parse(JSON.stringify(pageLines));
      p.mergeOptions.forEach((l) => {
        l.uniqueId = l.id + '-' + l.phraseId;
        // l.inUseSw = l.phraseId == p.phraseId;
        l.phraseWords.forEach((w) => {
          w.checked =
            p.phraseWords.findIndex(
              (x) => x.id == w.id && l.phraseId == p.phraseId
            ) != -1;
        });
        l.inUseSw = l.phraseWords.some((w) => w.checked);
      });
    }

    if (i.catMatch) {
      let matchArr = new Array().concat(i.catMatch);
      p.catMatches = [];
      matchArr.forEach((i) => {
        let m = new CatMatch();
        m.catMatchSeq = i.catMatchSeq;
        m.similarityPct = i.similarityPct;

        if (i.refCatMatch) {
          let mRefMatchArr = new Array().concat(i.refCatMatch);
          mRefMatchArr.forEach((r) => {
            let refMatch = new RefMatch();
            refMatch.catRefPhraseId = r.catRefPhraseId;
            refMatch.refPhraseId = r.refPhraseId;
            refMatch.similarityPct = r.similarity;
            refMatch.matchSimilarityCode = r.matchSimilarityCode;
            m.refMatch.push(refMatch);
          });
        }

        if (i.catEntry) {
          m.catPhrase = this._extractCatEntryTag(i.catEntry);
          m.catPhrase.references.forEach((r) => {
            let idx = m.refMatch.findIndex(
              (m) => r.refPhraseId == m.catRefPhraseId
            );

            if (idx != -1) {
              let matchingRef = m.refMatch[idx];
              r.similarityPct = matchingRef.similarityPct;
              r.matchSimilarityCode = matchingRef.matchSimilarityCode;
            } else {
              r.matchSimilarityCode = 'MISSING';
            }
          });

          // extra ref
          m.refMatch.forEach((rf) => {
            if (rf.catRefPhraseId == -1) {
              let idx = m.catPhrase.references.findIndex(
                (r) => rf.refPhraseId == r.refPhraseId
              );
              if (idx != -1) {
                let workingPhraseRef = m.catPhrase.references[idx];
                let extraRef = new ReferencePhrase();
                extraRef.extraRefSw = true;
                extraRef.refPhraseText = workingPhraseRef.refPhraseText;
                m.extraReferences.push(extraRef);
              }
            }
          });
        }

        if (i.matchDiffWords) {
          m.diffParts = [];
          let mDiffArr = new Array().concat(i.matchDiffWords);
          mDiffArr.forEach((d) => {
            let diffPart = new MatchDiff();
            diffPart.id = d.id;
            diffPart.text = d.txt;
            diffPart.diffType = d.difftype;
            m.diffParts.push(diffPart);
          });

          m.diffParts.srp_sortByKey('id');
        }

        p.catMatches.push(m);
      });

      p.catMatches.srp_sortByKey('similarityPct', -1);
    }

    if (i.extRef) {
      let extRef = new Array().concat(i.extRef);
      p.extRefs = [];
      extRef.forEach((r) => {
        let ref = this._extrectExtRefTag(r);
        p.extRefs.push(ref);
      });

      p.addInstr.srp_sortByKey('id');
    }

    // grammar and spelling issues
    if (i.grammarSpellingIssues) {
      let arrGrammarSpellingIssues = new Array().concat(i.grammarSpellingIssues);
      p.grammarSpellingIssues = [];
      arrGrammarSpellingIssues.forEach(issue => {
        p.grammarSpellingIssues.push(this._extractGrammarSpellingIssueTag(issue));
      });
    }

    if (i.referenceIssues) {
      let arrReferenceIssues = new Array().concat(i.referenceIssues);
      p.referenceIssues = [];
      arrReferenceIssues.forEach(issue => {
        p.referenceIssues.push(this._extractReferenceIssueTag(issue));
      });
    }

    // rule engine
    p.phraseContentType = i.phraseContentType;
    p.phraseContentTypeDesc = i.phraseContentTypeDesc;
    p.phraseSimilarityEvent = i.phraseSimilarityEvent;
    p.phraseSimilarityEventDesc = i.phraseSimilarityEventDesc;
    if (i.phraseFindingEvents) {
      p.phraseFindingEvents = i.phraseFindingEvents.split(',')
    }
    if (i.phraseActions) {
      p.phraseActions = i.phraseActions.split(',')
    }
    if (i.phraseFindingCategoryDesc) {
      p.phraseFindingCategoryDescs = i.phraseFindingCategoryDesc.split(',').map(
        keyValue => keyValue.split('=')
      );
    }

    return p;
  }

  private _extrectExtRefTag(r: any) {
    let ref = new ExtRef();
    ref.extRefId = r.extRefId;
    ref.extDocId = r.extDocId;
    ref.extType = r.extType;
    ref.extPhraseId = r.extPhraseId;
    ref.extDocName = r.extDocName;
    ref.extCatDescr = r.extCatDescr;
    ref.extCatVersionId = r.extCatVersionId;

    ref.srcDocId = r.docId;
    ref.docName = r.docName;
    ref.srcPhraseId = r.phraseId;
    ref.type = r.type;
    ref.pageNum = r.pageNum + 1;
    ref.extPageNum = r.extPageNum + 1;

    if (r.phraseDispWords) {
      let arrWords = new Array().concat(r.phraseDispWords);
      arrWords.forEach((w) => {
        ref.phraseDispWords.push(this._extractPhraseWordTag(w));
      });
    }

    if (r.extPhraseDispWords) {
      let arrWords = new Array().concat(r.extPhraseDispWords);
      arrWords.forEach((w) => {
        ref.extPhraseDispWords.push(this._extractPhraseWordTag(w));
      });
    }

    if (r.srcPos) {
      let ann = new AnnotationElement();
      ann.x1 = r.srcPos.x1;
      ann.x2 = r.srcPos.x2;
      ann.y1 = r.srcPos.y1;
      ann.y2 = r.srcPos.y2;
      this._updPhraseAnnElementPolygon(ann, r.srcPos.phraseBorder);
      ann.annColor = '#ff2229';
      ann.annId = ref.srcPhraseId;
      ref.ann = ann;
    }

    if (r.extPos) {
      let ann = new AnnotationElement();
      ann.x1 = r.extPos.x1;
      ann.x2 = r.extPos.x2;
      ann.y1 = r.extPos.y1;
      ann.y2 = r.extPos.y2;
      this._updPhraseAnnElementPolygon(ann, r.extPos.phraseBorder);
      ann.annColor = '#ff2229';
      ann.annId = ref.extPhraseId;
      ref.extAnn = ann;
    }

    return ref;
  }

  _updPhraseAnnElementPolygon(p: AnnotationElement, borderTag: any) {
    if (borderTag) {
      let arrBorder = new Array().concat(borderTag);
      arrBorder.forEach((b) => {
        let line = new PhraseBorderLine();
        line.badgeSw = b.badgeSw;
        line.borderLineId = b.borderLineId;
        line.startX = b.startX;
        line.startY = b.startY;
        line.endX = b.endX;
        line.endY = b.endY;
        p.borderLines.push(line);
      });

      p.borderLines.forEach((l) => {
        let c = new PolygonCorner();
        c.id = l.borderLineId;
        c.x = l.startX;
        c.y = l.startY;
        p.polygon.push(c);
      });
    } else {
      let w = p.x2 - p.x1;
      let h = p.y2 - p.y1;
      let c1 = new PolygonCorner();
      c1.id = 1;
      c1.badgeSw = true;
      c1.x = p.x1;
      c1.y = p.y1;
      p.polygon.push(c1);
      let c2 = new PolygonCorner();
      c2.id = 2;
      c2.badgeSw = false;
      c2.x = p.x1 + w;
      c2.y = p.y1;
      p.polygon.push(c2);
      let c3 = new PolygonCorner();
      c3.id = 3;
      c3.badgeSw = false;
      c3.x = p.x1 + w;
      c3.y = p.y1 + h;
      p.polygon.push(c3);
      let c4 = new PolygonCorner();
      c4.id = 4;
      c4.badgeSw = false;
      c4.x = p.x1;
      c4.y = p.y1 + h;
      p.polygon.push(c4);
    }
  }

  updCatDocStats(statsTag: any) {
    let approvedCnt;
    let approvedVariantCnt;
    let rejectedCnt;
    let pendingCnt;
    let progressPct;

    let statsArr = new Array().concat(statsTag.indStatusStats);
    statsArr.forEach((s) => {
      switch (s.revRuleStatusCode) {
        case CatPhraseStatusEnum.APPROVE:
          approvedCnt = s.cnt;
          break;
        case CatPhraseStatusEnum.APPROVE_VAR:
          approvedVariantCnt = s.cnt;
          break;
        case CatPhraseStatusEnum.REJECT:
          rejectedCnt = s.cnt;
          break;
        case CatPhraseStatusEnum.PENDING:
          pendingCnt = s.cnt;
          break;
      }
    });

    progressPct = statsTag.progressPct;

    this.ngRedux.dispatch({
      type: actionsList.UPD_CAT_DOC_STATS,
      data: {
        approvedCnt: approvedCnt || 0,
        approvedVariantCnt: approvedVariantCnt || 0,
        rejectedCnt: rejectedCnt || 0,
        pendingCnt: pendingCnt || 0,
        progressPct: progressPct,
      },
    });
  }

  createCat(cat: Catalog): Observable<boolean> {
    let serviceName = 'create_cat_func';

    /*if (this.mockupSw) {
      return this.http.get(this.mockupDataUrl + 'update_result.json').pipe(
        map((res) => this.authService.extractRequestStatus(res, serviceName)),
        catchError((error) => {
          return this.authService.handleError(err, info);
        })
      );
    }*/

    let response = new Observable<boolean>();
    let params = new ServiceOptions();
    params.serviceName = serviceName;
    params['envId'] = this.ngRedux.getState().curEnv.id;
    params['catDescr'] = cat.catDescr;
    params['productId'] = Number.parseInt(cat.productId as any);
    params['catType'] = cat.typeCode;
    params['extCatSw'] = cat.extSw;
    params['refMatchTypeCode'] = cat.refMatchTypeCode;
    params['langCode'] = cat.langCode;

    let url = this.authService.getRequestUrl(serviceName);
    let body = this.authService.getRequestBody(params);
    let info = new ServiceCallInfo(serviceName, url, body);
    response = this.http
      .post(url, body, {
        headers: this.authService.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json',
      })
      .pipe(
        map((data) => {
          return this.authService.extractRequestStatus(info, data);
        }),
        catchError((err) => {
          return this.authService.handleError(err, info);
        })
      );
    return response;
  }

  getCatDocStatusList(): Observable<DataElement[]> {
    let serviceName = 'get_ind_doc_status_list_func';

    let response = new Observable<DataElement[]>();
    let params = new ServiceOptions();
    params.serviceName = serviceName;

    let url = this.authService.getRequestUrl(serviceName);
    let body = this.authService.getRequestBody(params);
    let info = new ServiceCallInfo(serviceName, url, body);

    response = this.http
      .post(url, body, {
        headers: this.authService.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json',
      })
      .pipe(
        take(1),
        map((data) => {
          return this.extractCatDocStatusList(data, info);
        }),
        catchError((err) => {
          return this.authService.handleError(err, info);
        })
      );
    return response;
  }

  private extractCatDocStatusList(res, info: ServiceCallInfo): DataElement[] {
    const results: DataElement[] = this.authService.mapToDataElement(
      res,
      'indDocStatusCode',
      'descr',
      'indDocStatus',
      info
    );

    this.ngRedux.dispatch({
      type: actionsList.GET_CAT_DOC_STATUS_LIST,
      data: results,
      reply: res,
    });

    return results;
  }

  getCatMatchSimilarityCodes(): Observable<DataElement[]> {
    let serviceName = 'get_match_similarity_codes_func';

    let response = new Observable<DataElement[]>();
    let params = new ServiceOptions();
    params.serviceName = serviceName;

    let url = this.authService.getRequestUrl(serviceName);
    let body = this.authService.getRequestBody(params);
    let info = new ServiceCallInfo(serviceName, url, body);

    response = this.http
      .post(url, body, {
        headers: this.authService.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json',
      })
      .pipe(
        take(1),
        map((data) => {
          return this.extractCatMatchSimilarityCodes(data, info);
        }),
        catchError((err) => {
          return this.authService.handleError(err, info);
        })
      );
    return response;
  }

  private extractCatMatchSimilarityCodes(
    res,
    info: ServiceCallInfo
  ): DataElement[] {
    const results: DataElement[] = this.authService.mapToDataElement(
      res,
      'matchSimilarityCode',
      'descr',
      'matchSimilarity',
      info
    );

    this.ngRedux.dispatch({
      type: actionsList.GET_CAT_MATCH_SIMILARITY_CODES,
      data: results,
      reply: res,
    });

    return results;
  }

  createIndDoc(catDoc: CatDoc): Observable<CatDoc> {
    let serviceName = 'create_ind_doc_func';

    let response = new Observable<CatDoc>();
    let params = new ServiceOptions();
    params.serviceName = serviceName;
    params['envId'] = this.ngRedux.getState().curEnv.id;
    params['docName'] = catDoc.docName;
    params['parentDocId'] = catDoc.parentDocId;
    params['productId'] = catDoc.productId;
    params['catId'] = catDoc.catId;
    params['catVersionId'] = catDoc.catVersionId;
    params['docS3Key'] = catDoc.docS3Key;
    params['fileName'] = catDoc.fileName;
    params['bulkUploadSeq'] = catDoc.bulkSeq;
    if (catDoc.assetType)
      params['assetType'] = Number.parseInt(catDoc.assetType.id);
    if (catDoc.createDocType) {
      params['docType'] = catDoc.createDocType;
    }
    if (catDoc.taggedDocId) {
      params['taggedDocId'] = catDoc.taggedDocId;
    }

    let url = this.authService.getRequestUrl(serviceName);
    let body = this.authService.getRequestBody(params);
    let info = new ServiceCallInfo(serviceName, url, body);
    response = this.http
      .post(url, body, {
        headers: this.authService.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json',
      })
      .pipe(
        map((data) => {
          return this.extractCreateCatDoc(data, info);
        }),
        catchError((err) => {
          return this.authService.handleError(err, info);
        })
      );
    return response;
  }

  extractCreateCatDoc(res: any, info: ServiceCallInfo): CatDoc {
    this.authService.extractRequestStatus(info, res);
    let data = res.reply.requestResult.data;
    let result: CatDoc;

    if (data) {
      result = this._extractCatDocTag(data);
    }

    return result;
  }

  updIndDoc(catDoc: CatDoc, statusCode: string): Observable<CatDoc> {
    let serviceName = 'upd_ind_doc_func';

    let response = new Observable<CatDoc>();
    let params = new ServiceOptions();
    params.serviceName = serviceName;
    params['docId'] = catDoc.docId;
    params['statusCode'] = statusCode;

    let url = this.authService.getRequestUrl(serviceName);
    let body = this.authService.getRequestBody(params);
    let info = new ServiceCallInfo(serviceName, url, body);
    response = this.http
      .post(url, body, {
        headers: this.authService.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json',
      })
      .pipe(
        map((data) => {
          return this.extractUpdIndDoc(data, info);
        }),
        catchError((err) => {
          return this.authService.handleError(err, info);
        })
      );
    return response;
  }

  extractUpdIndDoc(res: any, info: ServiceCallInfo): CatDoc {
    this.authService.extractRequestStatus(info, res);
    let data = res.reply.requestResult.data;
    let result: CatDoc;

    if (data && data.inddoc) {
      result = this._extractCatDocTag(data.inddoc);
    }

    this.ngRedux.dispatch({
      type: actionsList.UPD_CAT_DOC,
      data: result,
      reply: res,
    });

    return result;
  }

  createIndDocPhrase(
    phrase: IndPhrase,
    doc: CatDoc,
    pageNum: number
  ): Observable<boolean> {
    let serviceName = 'create_ind_doc_phrase_func';

    let response = new Observable<boolean>();
    let params = new ServiceOptions();
    params.serviceName = serviceName;
    params['envId'] = this.ngRedux.getState().curEnv.id;
    params['docId'] = doc.docId;
    params['pageNum'] = pageNum;
    params['type'] = phrase.typeCode;
    params['subType'] = phrase.subTypeCode;
    params['phraseText'] = phrase.phraseText;
    params['txtEditMode'] = 'LINES';
    params['lines'] = phrase.mergeOptions
      .filter((l) => l.inUseSw)
      .map((e) => {
        return {
          lineId: e.id,
          inUseSw: e.inUseSw,
        };
      });

    const ref = [];
    phrase.references.forEach((i) => {
      ref.push({
        refText: i.refPhraseText,
        refType: i.refType,
      });
    });
    params['ref'] = ref;

    const instr = [];
    phrase.addInstr.forEach((i) => {
      instr.push({
        text: i.text,
        imgUrl: i.imgS3Key,
      });
    });
    params['addInstr'] = instr;
    params.urldecodeSw = true;

    let url = this.authService.getRequestUrl(serviceName);
    let body = this.authService.getRequestBody(params);
    let info = new ServiceCallInfo(serviceName, url, body);
    response = this.http
      .post(url, body, {
        headers: this.authService.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json',
      })
      .pipe(
        map((data) => {
          return this.authService.extractRequestStatus(info, data);
        }),
        catchError((err) => {
          return this.authService.handleError(err, info);
        })
      );
    return response;
  }

  createCatEntry(phrase: IndPhrase): Observable<boolean> {
    let serviceName = 'create_cat_entry_func';

    let response = new Observable<boolean>();
    let params = new ServiceOptions();
    params.serviceName = serviceName;
    params['envId'] = this.ngRedux.getState().curEnv.id;
    params['catId'] = this.ngRedux.getState().curCat.catId;
    params['catVersionId'] = this.ngRedux.getState().curCat.catVersionId;
    params['type'] = phrase.typeCode;
    params['subType'] = phrase.subTypeCode;
    params['phraseText'] = phrase.phraseText;
    if (phrase.catEntryStatus)
      params['catEntryStatus'] = phrase.catEntryStatus.id;

    const ref = [];
    phrase.references.forEach((i) => {
      ref.push({
        refText: i.refPhraseText,
        refType: i.refType,
      });
    });
    params['ref'] = ref;

    const instr = [];
    phrase.addInstr.forEach((i) => {
      instr.push({
        text: i.text,
        imgUrl: i.imgS3Key,
      });
    });
    params['addInstr'] = instr;
    params.urldecodeSw = true;

    let url = this.authService.getRequestUrl(serviceName);
    let body = this.authService.getRequestBody(params);
    let info = new ServiceCallInfo(serviceName, url, body);
    response = this.http
      .post(url, body, {
        headers: this.authService.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json',
      })
      .pipe(
        map((data) => {
          return this.authService.extractRequestStatus(info, data);
        }),
        catchError((err) => {
          return this.authService.handleError(err, info);
        })
      );
    return response;
  }

  updIndDocPhrase(
    element: IndElement,
    deleteSw: boolean = false
  ): Observable<boolean> {
    let serviceName = 'upd_ind_doc_phrase_func';

    let phrase: IndPhrase;
    let image: IndImage;
    if (element instanceof IndPhrase) {
      phrase = element as IndPhrase;
    } else {
      image = element as IndImage;
    }

    let response = new Observable<boolean>();
    let params = new ServiceOptions();
    params.serviceName = serviceName;
    params['envId'] = this.ngRedux.getState().curEnv.id;
    params['docId'] = element.docId;
    params['pageNum'] = element.pageNum;
    params['deleteSw'] = deleteSw;

    if (phrase) {
      params['phraseId'] = phrase.phraseId;
      params['phraseText'] = phrase.phraseText;
      params['txtEditMode'] = phrase.hasId ? 'LINES' : 'FREE_TEXT';
      // params['lines'] = phrase.mergeOptions.map((e) => {
      //   return {
      //     lineId: e.id,
      //     inUseSw: e.inUseSw,
      //   };
      // });

      params['words'] = [];
      phrase.mergeOptions
        .filter((e) => e.inUseSw)
        .forEach((e) => {
          e.phraseWords
            .filter((w) => w.checked)
            .forEach((w) => {
              params['words'].push({
                wordId: w.id,
                phraseId: e.phraseId,
                txt: w.text,
              });
            });
        });

      const ref = [];
      let refToSend = new Array().concat(
        phrase.references,
        phrase.deletedReferences
      );
      refToSend.forEach((i) => {
        ref.push({
          refText: i.refPhraseText,
          refType: i.refType,
          refPhraseId: i.refPhraseId,
          pointerWordId: i.pointerWordId,
          origPointerWordId: i.origPointerWordId,
          newSw: i.newSw,
          deleteSw: i.deleteSw,
        });
      });
      params['ref'] = ref;
    } else {
      params['imageId'] = image.imageId;
    }

    params['type'] = element.typeCode;
    params['subType'] = element.subTypeCode;

    if (element.figureSubType)
      params['figureSubType'] = element.figureSubType.id;

    const instr = [];
    element.addInstr.forEach((i) => {
      instr.push({
        text: i.text,
        imgS3Key: i.imgS3Key,
      });
    });
    params['addInstr'] = instr;
    params.urldecodeSw = true;

    let url = this.authService.getRequestUrl(serviceName);
    let body = this.authService.getRequestBody(params);
    let info = new ServiceCallInfo(serviceName, url, body);
    response = this.http
      .post(url, body, {
        headers: this.authService.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json',
      })
      .pipe(
        map((data) => {
          return this.extractUpdIndDocPhrase(data, info);
        }),
        catchError((err) => {
          return this.authService.handleError(err, info);
        })
      );
    return response;
  }

  private extractUpdIndDocPhrase(res, info: ServiceCallInfo) {
    this.authService.extractRequestStatus(info, res);
    let data = res.reply.requestResult.data;
    let linesUpdSw = false;

    if (data) {
      let pageLines: MergeLine[] = [];
      let linesTag = data.pageLines;
      if (linesTag) {
        pageLines = this._exctractPageLines(linesTag);
      }
      this.ngRedux.dispatch({
        type: actionsList.GET_CAT_DOC_PAGE_LINES,
        data: pageLines,
        reply: res,
        catDocSTateTag: CatDocStateTagEnum.PRIME,
      });
    }

    if (data && data.indPhrase) {
      let result = this._extractIndPhraseTag(
        data.indPhrase[0],
        this.ngRedux.getState().curCatDocStatePrime.catDocPageLines
      );
      this.ngRedux.dispatch({
        type: actionsList.UPD_IND_PHRASE,
        data: result,
        reply: res,
      });
    }

    if (data && data.phraseStats) {
      let statsTag = data.phraseStats;
      if (statsTag) {
        this.updCatDocStats(statsTag);
      }
    }

    if (data && data.linesUpdSw) {
      linesUpdSw = true;
    }

    return linesUpdSw;
  }

  updIndPhraseStatus(
    catDoc: CatDoc,
    elements: IndElement[],
    status: CatPhraseStatusEnum,
    allDoc: boolean = false,
    curPage: number = null,
    variantOrigPhraseId: number = null
  ): Observable<IndElement[]> {
    let serviceName = 'upd_ind_phrase_status_func';

    let response = new Observable<IndElement[]>();
    let params = new ServiceOptions();
    params.serviceName = serviceName;

    params['docId'] = catDoc.docId;
    params['phraseIdList'] = allDoc
      ? 'ALL'
      : elements.filter((e) => e instanceof IndPhrase).map((r) => r.elementId);
    params['imageIdList'] = allDoc
      ? 'ALL'
      : elements.filter((e) => e instanceof IndImage).map((r) => r.elementId);
    params['statusCode'] = status;
    if (variantOrigPhraseId) {
      params['variantOrigPhraseId'] = variantOrigPhraseId;
    }
    if (curPage != null) params['dispPageNum'] = curPage;

    let url = this.authService.getRequestUrl(serviceName);
    let body = this.authService.getRequestBody(params);
    let info = new ServiceCallInfo(serviceName, url, body);
    response = this.http
      .post(url, body, {
        headers: this.authService.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json',
      })
      .pipe(
        map((data) => {
          return this.extractUpdIndPhraseStatus(data, info);
        }),
        catchError((err) => {
          return this.authService.handleError(err, info);
        })
      );
    return response;
  }

  private extractUpdIndPhraseStatus(res, info: ServiceCallInfo): IndElement[] {
    this.authService.extractRequestStatus(info, res);
    let data = res.reply.requestResult.data;
    let results: IndElement[] = [];

    if (data) {
      let phrasesTag = data.indPhrase;
      if (phrasesTag) {
        let arr = new Array().concat(phrasesTag);
        arr.forEach((i) => {
          results.push(
            this._extractIndPhraseTag(
              i,
              this.ngRedux.getState().curCatDocStatePrime.catDocPageLines
            )
          );
        });
      }

      let imagesTag = data.indImage;
      if (imagesTag) {
        let arr = new Array().concat(imagesTag);
        arr.forEach((i) => {
          results.push(this._extractIndImageTag(i));
        });
      }

      let statsTag = data.phraseStats;
      if (statsTag) {
        this.updCatDocStats(statsTag);
      }
    }

    return results;
  }

  updCatEntry(
    element: IndElement,
    deleteSw: boolean = false
  ): Observable<IndPhrase> {
    let serviceName = 'upd_cat_entry_func';

    let phrase: IndPhrase;
    let image: IndImage;

    if (element instanceof IndPhrase) {
      phrase = element as IndPhrase;
    } else {
      image = element as IndImage;
    }

    let response = new Observable<IndPhrase>();
    let params = new ServiceOptions();
    params.serviceName = serviceName;
    params['envId'] = this.ngRedux.getState().curEnv.id;
    params['catId'] = this.ngRedux.getState().curCat.catId;
    params['catVersionId'] = this.ngRedux.getState().curCat.catVersionId;
    params['deleteSw'] = deleteSw;

    if (phrase) {
      params['phraseId'] = phrase.phraseId;
      params['phraseText'] = phrase.phraseText;
      params['manualSrcSw'] = phrase.manualSrcSw;
      const ref = [];
      phrase.references.forEach((i) => {
        ref.push({
          refText: i.refPhraseText,
          refType: i.refType,
        });
      });
      params['ref'] = ref;
    } else {
      params['imageId'] = image.imageId;
    }

    params['type'] = element.typeCode;
    params['subType'] = element.subTypeCode;
    if (element.figureSubType)
      params['figureSubType'] = element.figureSubType.id;

    if (element.catEntryStatus)
      params['catEntryStatus'] = element.catEntryStatus.id;

    const instr = [];
    element.addInstr.forEach((i) => {
      instr.push({
        text: i.text,
        imgS3Key: i.imgS3Key,
      });
    });
    params['addInstr'] = instr;

    params.urldecodeSw = true;

    let url = this.authService.getRequestUrl(serviceName);
    let body = this.authService.getRequestBody(params);
    let info = new ServiceCallInfo(serviceName, url, body);
    response = this.http
      .post(url, body, {
        headers: this.authService.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json',
      })
      .pipe(
        map((data) => {
          return this.extractUpdCatEntry(data, info);
        }),
        catchError((err) => {
          return this.authService.handleError(err, info);
        })
      );
    return response;
  }

  private extractUpdCatEntry(res, info: ServiceCallInfo): IndPhrase {
    this.authService.extractRequestStatus(info, res);
    let data = res.reply.requestResult.data;

    if (data && data.catEntry) {
      let result = this._extractCatEntryTag(data.catEntry[0]);
      this.ngRedux.dispatch({
        type: actionsList.UPD_CAT_ENTRY,
        data: result,
        reply: res,
      });

      return result;
    }
  }

  publishCatVersion(
    cat: Catalog,
    unpublishSw: boolean = false
  ): Observable<void> {
    let serviceName = unpublishSw
      ? 'unpublish_cat_version_func'
      : 'publish_cat_version_func';

    let response = new Observable<void>();
    let params = new ServiceOptions();
    params.serviceName = serviceName;

    params['envId'] = this.ngRedux.getState().curEnv.id;
    params['catVersionId'] = cat.catVersionId;

    let url = this.authService.getRequestUrl(serviceName);
    let body = this.authService.getRequestBody(params);
    let info = new ServiceCallInfo(serviceName, url, body);
    response = this.http
      .post(url, body, {
        headers: this.authService.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json',
      })
      .pipe(
        map((data) => {
          return this.extractPublishCatVersion(data, info);
        }),
        catchError((err) => {
          return this.authService.handleError(err, info);
        })
      );
    return response;
  }

  private extractPublishCatVersion(res, info: ServiceCallInfo): void {
    this.authService.extractRequestStatus(info, res);
    let data = res.reply.requestResult.data;
    let result = data && data.cat ? this._extractCatTag(data.cat[0]) : null;

    this.ngRedux.dispatch({
      type: actionsList.SAVE_PUBLISHED_CATALOG,
      data: result,
      reply: res,
    });

    this.ngRedux.dispatch({
      type: actionsList.UPD_CAT_VERSION,
      reply: res,
    });

    // if (data && data.cat) {
    //   let result = this._extractCatTag(data.cat[0]);
    //   this.ngRedux.dispatch({
    //     type: actionsList.UPD_CAT_VERSION,
    //     data: result,
    //     reply: res,
    //   });

    //   return result;
    // }
  }

  addIndDocToCat(doc: CatDoc): Observable<CatDoc> {
    let serviceName = 'add_ind_phrases_to_cat_func';

    let response = new Observable<CatDoc>();
    let params = new ServiceOptions();
    params.serviceName = serviceName;

    params['docId'] = doc.docId;

    let url = this.authService.getRequestUrl(serviceName);
    let body = this.authService.getRequestBody(params);
    let info = new ServiceCallInfo(serviceName, url, body);
    response = this.http
      .post(url, body, {
        headers: this.authService.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json',
      })
      .pipe(
        map((data) => {
          return this.extractUpdIndDoc(data, info);
          //return this.authService.extractRequestStatus(info, data);
        }),
        catchError((err) => {
          return this.authService.handleError(err, info);
        })
      );
    return response;
  }

  updCatPhraseType(type: CatPhraseType, action: string): Observable<boolean> {
    let serviceName = 'upd_cat_phrase_type_func';

    let response = new Observable<boolean>();
    let params = new ServiceOptions();
    params.serviceName = serviceName;

    params['catId'] = this.ngRedux.getState().curCat.catId;
    params['typeCode'] = type.typeCode.toUpperCase();
    params['typeDescr'] = type.typeDescr;
    params['action'] = action;
    params.urldecodeSw = true;

    let url = this.authService.getRequestUrl(serviceName);
    let body = this.authService.getRequestBody(params);
    let info = new ServiceCallInfo(serviceName, url, body);
    response = this.http
      .post(url, body, {
        headers: this.authService.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json',
      })
      .pipe(
        map((data) => {
          return this.authService.extractRequestStatus(info, data);
        }),
        catchError((err) => {
          return this.authService.handleError(err, info);
        })
      );
    return response;
  }

  updCatPhraseSubType(
    subType: CatPhraseSubType,
    action: string
  ): Observable<boolean> {
    let serviceName = 'upd_cat_phrase_sub_type_func';

    let response = new Observable<boolean>();
    let params = new ServiceOptions();
    params.serviceName = serviceName;

    params['catId'] = this.ngRedux.getState().curCat.catId;
    params['typeCode'] = subType.typeCode.toUpperCase();
    params['typeDescr'] = subType.typeDescr;
    params['action'] = action;
    params['subTypeCode'] = subType.subTypeCode.toUpperCase();
    params['subTypeDescr'] = subType.subTypeDescr;
    params['errorLevel'] = subType.errorLevel;
    params['level0'] = subType.level0;
    params['level1'] = subType.level1;
    params['algKeyWords'] = subType.algKeyWords;
    params.urldecodeSw = true;

    let url = this.authService.getRequestUrl(serviceName);
    let body = this.authService.getRequestBody(params);
    let info = new ServiceCallInfo(serviceName, url, body);
    response = this.http
      .post(url, body, {
        headers: this.authService.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json',
      })
      .pipe(
        map((data) => {
          return this.authService.extractRequestStatus(info, data);
        }),
        catchError((err) => {
          return this.authService.handleError(err, info);
        })
      );
    return response;
  }

  addCatPhraseTypeTree(subTypes: CatPhraseSubType[]): Observable<boolean> {
    let serviceName = 'add_cat_phrase_type_tree_func';

    let response = new Observable<boolean>();
    let params = new ServiceOptions();
    params.serviceName = serviceName;

    params['catId'] = this.ngRedux.getState().curCat.catId;
    params['subType'] = subTypes.map((s) => {
      return {
        typeCode: s.typeCode,
        typeDescr: s.typeDescr,
        subTypeCode: s.subTypeCode,
        subTypeDescr: s.subTypeDescr,
        errorLevel: s.errorLevel,
        level0: s.level0,
        level1: s.level1,
        algKeyWords: s.algKeyWords,
      };
    });

    let url = this.authService.getRequestUrl(serviceName);
    let body = this.authService.getRequestBody(params);
    let info = new ServiceCallInfo(serviceName, url, body);
    response = this.http
      .post(url, body, {
        headers: this.authService.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json',
      })
      .pipe(
        map((data) => {
          return this.authService.extractRequestStatus(info, data);
        }),
        catchError((err) => {
          return this.authService.handleError(err, info);
        })
      );
    return response;
  }

  backUpCatVersion(
    catVersionId: number,
    versionName: string
  ): Observable<Catalog> {
    let serviceName = 'backup_cat_version_func';

    let response = new Observable<Catalog>();
    let params = new ServiceOptions();
    params.serviceName = serviceName;

    params['envId'] = this.ngRedux.getState().curEnv.id;
    params['catVersionId'] = catVersionId;
    params['versionName'] = versionName;

    let url = this.authService.getRequestUrl(serviceName);
    let body = this.authService.getRequestBody(params);
    let info = new ServiceCallInfo(serviceName, url, body);
    response = this.http
      .post(url, body, {
        headers: this.authService.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json',
      })
      .pipe(
        map((data) => {
          return this.extractBackupCatVersion(data, info);
        }),
        catchError((err) => {
          return this.authService.handleError(err, info);
        })
      );
    return response;
  }

  private extractBackupCatVersion(res, info: ServiceCallInfo): Catalog {
    this.authService.extractRequestStatus(info, res);
    let data = res.reply.requestResult.data;

    if (data && data.cat) {
      let result = this._extractCatTag(data.cat[0]);
      this.ngRedux.dispatch({
        type: actionsList.UPD_CAT_VERSION,
        data: result,
        reply: res,
      });

      return result;
    }
  }

  restoreCatVersion(catVersionId: number): Observable<Catalog> {
    let serviceName = 'restore_cat_version_func';

    let response = new Observable<Catalog>();
    let params = new ServiceOptions();
    params.serviceName = serviceName;

    params['envId'] = this.ngRedux.getState().curEnv.id;
    params['catVersionId'] = catVersionId;

    let url = this.authService.getRequestUrl(serviceName);
    let body = this.authService.getRequestBody(params);
    let info = new ServiceCallInfo(serviceName, url, body);
    response = this.http
      .post(url, body, {
        headers: this.authService.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json',
      })
      .pipe(
        map((data) => {
          return this.extractBackupCatVersion(data, info);
        }),
        catchError((err) => {
          return this.authService.handleError(err, info);
        })
      );
    return response;
  }

  getCardTypes(): Observable<DataElement[]> {
    let serviceName = 'get_cat_card_types_func';

    let response = new Observable<DataElement[]>();
    let params = new ServiceOptions();
    params.serviceName = serviceName;

    let url = this.authService.getRequestUrl(serviceName);
    let body = this.authService.getRequestBody(params);
    let info = new ServiceCallInfo(serviceName, url, body);

    response = this.http
      .post(url, body, {
        headers: this.authService.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json',
      })
      .pipe(
        take(1),
        map((data) => {
          return this.extractCardTypes(data, info);
        }),
        catchError((err) => {
          return this.authService.handleError(err, info);
        })
      );
    return response;
  }

  private extractCardTypes(res, info: ServiceCallInfo): DataElement[] {
    const results: DataElement[] = this.authService.mapToDataElement(
      res,
      'cardTypeCode',
      'descr',
      'cardtype',
      info
    );

    this.ngRedux.dispatch({
      type: actionsList.GET_CARD_TYPES,
      data: results,
      reply: res,
    });

    return results;
  }

  updateCard(card: Card, deleteSw: boolean = false): Observable<Card> {
    let response = new Observable<Card>();
    let serviceName = 'upd_cat_card_func';
    let params = new ServiceOptions();
    params.serviceName = serviceName;

    params['catVersionId'] = this.ngRedux.getState().curCat.catVersionId;
    params['cardId'] = card.cardId;
    params['title'] = card.title;
    params['cardType'] = card.type.id;
    params['deleteSw'] = deleteSw;
    params['cardPhrases'] = [];
    card.cardPhrases.forEach((e, idx) => {
      params['cardPhrases'].push({ catPhraseId: e.phraseId, sortOrder: idx });
    });

    if (card.mcType) params['mcType'] = card.mcType.id;
    if (card.mcSubType) params['mcSubType'] = card.mcSubType.id;

    let url = this.authService.getRequestUrl(serviceName);
    let body = this.authService.getRequestBody(params);
    let info = new ServiceCallInfo(serviceName, url, body);
    response = this.http
      .post(url, body, {
        headers: this.authService.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json',
      })
      .pipe(
        map((data) => this.extractUpdCard(data, info)),
        catchError((error) => {
          return this.authService.handleError(error, info);
        })
      );

    return response;
  }

  private extractUpdCard(res, info: ServiceCallInfo): Card {
    this.authService.extractRequestStatus(info, res);
    let data = res.reply.requestResult.data;

    if (data && data.card) {
      let result = this._extractCardTag(data.card);
      this.ngRedux.dispatch({
        type: actionsList.UPD_CARD,
        data: result,
        reply: res,
      });

      return result;
    }
  }

  _extractCardTag(i: any): Card {
    let c = new Card();
    c.cardId = i.cardId;
    c.type = new DataElement(i.cardType, i.cardTypeDescr);
    c.title = i.title;
    c.createDate = this.authService.formatDBDateTime(i.createDate);
    c.lcId = i.lcId;
    c.matchedCardId = i.catCardId;
    c.matchStatusCode = i.matchStatusCode;
    c.matchStatusDescr = i.matchStatusDescr;
    c.srcType = i.srcType;

    if (i.mcType) {
      c.mcType = new DataElement(i.mcType, i.mcTypeDescr);
    }
    if (i.mcSubType) {
      c.mcSubType = new DataElement(i.mcSubType, i.mcSubTypeDescr);
    }
    if (i.lcCardType) {
      c.lcCardType = new DataElement(i.lcCardType, i.lcCardTypeDescr);
    }

    let docsTag = i.boundingBoxDoc;
    if (docsTag) {
      let arr = new Array().concat(docsTag);
      arr.forEach((d) => {
        c.cardDocs.push(this._extractCatDocTag(d));
      });

      c.cardDocs.srp_sortByKey('docId', -1);
    }

    let transTag = i.transLang;
    if (transTag) {
      let arr = new Array().concat(transTag);
      arr.forEach((d) => {
        c.transLang.push(
          new DataElement(
            d.transLang,
            this.translateService.instant('transLang.' + d.transLang)
          )
        );
      });

      c.transLang.srp_sortByKey('text');
    }

    return c;
  }

  getCards(searchParams: CardSearchParams): Observable<Card[]> {
    let serviceName = 'get_cat_cards_func';
    let response = new Observable<Card[]>();
    let params = this._buildGetCardsReq(searchParams);
    params.serviceName = serviceName;

    let url = this.authService.getRequestUrl(serviceName);
    let body = this.authService.getRequestBody(params);
    let info = new ServiceCallInfo(serviceName, url, body);
    response = this.http
      .post(url, body, {
        headers: this.authService.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json',
      })
      .pipe(
        map((data) => this.extractGetCards(data, searchParams.dispatch, info)),
        catchError((error) => {
          return this.authService.handleError(error, info);
        })
      );

    return response;
  }

  getCardsAsync(searchParams: CardSearchParams): Observable<number> {
    let serviceName = 'get_cat_cards_func';
    let params = this._buildGetCardsReq(searchParams);
    params.serviceName = serviceName;
    let body = this.authService.getRequestBody(params);
    return this.authService.runGeneralSearch(body);
  }

  _buildGetCardsReq(searchParams: CardSearchParams): ServiceOptions {
    let params = new ServiceOptions();
    params['envId'] = this.ngRedux.getState().curEnv.id;

    let catVersionId;
    catVersionId = searchParams.catVersionId;
    if (!catVersionId && this.ngRedux.getState().curCat) {
      catVersionId = Number.parseInt(
        this.ngRedux.getState().curCat.catVersionId as any
      );
    }

    if (catVersionId) params['catVersionId'] = catVersionId;

    if (searchParams.lcId) params['lcId'] = searchParams.lcId;
    if (searchParams.docId) params['docId'] = searchParams.docId;
    if (searchParams.cardId) params['cardId'] = searchParams.cardId;
    params['title'] = searchParams.title;
    params['shortModeSw'] = searchParams.shortModeSw ? 1 : 0;

    return params;
  }

  getCardsSearch(searchId: number, dispatch: boolean): Observable<Card[]> {
    let serviceName = 'get_search_results_func';
    let response = new Observable<Card[]>();
    let params = new ServiceOptions();
    params.serviceName = serviceName;

    params['searchId'] = searchId;

    let url = this.authService.getRequestUrl(serviceName);
    let body = this.authService.getRequestBody(params);
    let info = new ServiceCallInfo(serviceName, url, body);
    response = this.http
      .post(url, body, {
        headers: this.authService.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json',
      })
      .pipe(
        map((data) => this.extractGetCards(data, dispatch, info)),
        catchError((error) => {
          return this.authService.handleError(error, info);
        })
      );

    return response;
  }

  private extractGetCards(
    res: any,
    dispatch: boolean,
    info: ServiceCallInfo
  ): Card[] {
    this.authService.extractRequestStatus(info, res);
    let result: Card[] = [];
    let data = res.reply.requestResult.data;

    if (data) {
      let dataTag = data.card;
      if (dataTag) {
        let arr = new Array().concat(dataTag);
        arr.forEach((i) => {
          result.push(this._extractCardTag(i));
        });
      }
    }

    if (dispatch)
      this.ngRedux.dispatch({
        type: actionsList.GET_CARDS,
        data: result,
        reply: res,
      });

    return result;
  }

  getBoundingBoxTypes(): Observable<DataElement[]> {
    let serviceName = 'get_cat_bounding_box_types_func';

    let response = new Observable<DataElement[]>();
    let params = new ServiceOptions();
    params.serviceName = serviceName;

    let url = this.authService.getRequestUrl(serviceName);
    let body = this.authService.getRequestBody(params);
    let info = new ServiceCallInfo(serviceName, url, body);

    response = this.http
      .post(url, body, {
        headers: this.authService.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json',
      })
      .pipe(
        take(1),
        map((data) => {
          return this.extractBoundingBoxTypes(data, info);
        }),
        catchError((err) => {
          return this.authService.handleError(err, info);
        })
      );
    return response;
  }

  private extractBoundingBoxTypes(res, info: ServiceCallInfo): DataElement[] {
    const results: DataElement[] = this.authService.mapToDataElement(
      res,
      'boxTypeCode',
      'descr',
      'boxtype',
      info
    );

    this.ngRedux.dispatch({
      type: actionsList.GET_BOUNDING_BOX_TYPES,
      data: results,
      reply: res,
    });

    return results;
  }

  updBoundingBox(
    pageNum: number,
    doc: CatDoc,
    box: BoundingBox,
    deleteSw: boolean = false,
    newCard: Card = null
  ): Observable<BoundingBox> {
    let response = new Observable<BoundingBox>();
    let serviceName = 'upd_ind_doc_bounding_box_func';
    let params = new ServiceOptions();
    params.serviceName = serviceName;

    if (!deleteSw)
      params['catVersionId'] = this.ngRedux.getState().curCat.catVersionId;
    if (doc) params['docId'] = doc.docId;
    if (pageNum) params['pageNum'] = pageNum - 1;
    params['boxId'] = box.boxId;
    if (box.boxType) {
      params['boxType'] = box.boxType.id;
    } else {
      params['boxType'] = 'MODULAR_CONTENT';
    }
    if (box.card) params['cardId'] = box.card.cardId;
    if (box.mcBoxType) params['mcBoxType'] = box.mcBoxType.id;

    params['descr'] = box.descr;
    params['deleteSw'] = deleteSw;

    params['docX1'] = Math.round(box.x1);
    params['docY1'] = Math.round(box.y1);
    params['docX2'] = Math.round(box.x2);
    params['docY2'] = Math.round(box.y2);
    params['docPageWidth'] = Math.round(box.pageWidth);
    params['docPageHeight'] = Math.round(box.pageHeight);
    params['useForPrecheckSw'] = box.useForPrecheckSw;

    if (newCard) {
      params['newCardSw'] = true;
      params['newCardTitle'] = newCard.title;
      params['newCardType'] = newCard.type.id;
      if (newCard.mcType) params['newCardMcType'] = newCard.mcType.id;
      if (newCard.mcSubType) params['newCardMcSubType'] = newCard.mcSubType.id;
    }

    let url = this.authService.getRequestUrl(serviceName);
    let body = this.authService.getRequestBody(params);
    let info = new ServiceCallInfo(serviceName, url, body);
    response = this.http
      .post(url, body, {
        headers: this.authService.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json',
      })
      .pipe(
        map((data) =>
          this.extractUpdBoundingBox(data, deleteSw, box.boxId, info)
        ),
        catchError((error) => {
          return this.authService.handleError(error, info);
        })
      );

    return response;
  }

  private extractUpdBoundingBox(
    res,
    deleteSw: boolean,
    boxId: number,
    info: ServiceCallInfo
  ): BoundingBox {
    this.authService.extractRequestStatus(info, res);
    let data = res.reply.requestResult.data;
    let result;

    if (data && data.boundingbox) {
      result = this._extractBoundingBoxTag(data.boundingbox);
    }

    if (!deleteSw) {
      this.ngRedux.dispatch({
        type: actionsList.CREATE_CAT_BOUNDING_BOX,
        data: result,
        reply: res,
      });
    } else {
      this.ngRedux.dispatch({
        type: actionsList.DELETE_CAT_BOUNDING_BOX,
        data: boxId,
        reply: res,
      });
    }

    return result;
  }

  _extractBoundingBoxTag(i: any): BoundingBox {
    let b = new BoundingBox();
    b.boxId = i.boxId;
    b.elementId = 'bb-' + b.boxId;
    b.boxType = new DataElement(i.boxType, i.boxTypeDescr);
    b.blockRules = i.blockRules;
    if (i.mcBoxType) {
      b.mcBoxType = new DataElement(i.mcBoxType, i.mcBoxTypeDescr);
    }

    b.x1 = i.x1;
    b.y1 = i.y1;
    b.x2 = i.x2;
    b.y2 = i.y2;
    b.pageHeight = i.pageHeight;
    b.pageWidth = i.pageWidth;

    b.descr = i.descr;
    b.pageNum = i.pageNum;
    b.displayPageNum = b.pageNum + 1;
    if (i.cardId) {
      let card = new Card();
      card.cardId = i.cardId;
      card.title = i.cardTitle;
      b.card = card;
    }
    b.pageUrl = i.pageUrl;
    b.s3Key = i.imgS3Key;
    this.assignOrSubscribeObjectForPresignedImgUrl(b, b.s3Key);
    b.useForPrecheckSw = i.useForPrecheckSw;

    let arr = [];
    if (i.indPhrases) {
      b.indPhrases = [];
      arr = new Array().concat(i.indPhrases);
      arr.forEach((e) => {
        b.indPhrases.push(this._extractIndPhraseTag(e, []));
      });
    } else if (i.revPhrases) {
      b.revPhrases = [];
      arr = new Array().concat(i.revPhrases);
      arr.forEach((e) => {
        b.revPhrases.push(this._extractRevRuleTag(e));
      });
    }

    return b;
  }

  createBoundingBoxImage(box: BoundingBox): Observable<boolean> {
    let response = new Observable<boolean>();
    let serviceName = 'ScaiPyAdmin.CreateBoundingBoxImage';
    let params = new ServiceOptions();
    params.serviceName = serviceName;

    params['boxId'] = box.boxId;
    params['pageURL'] = box.pageUrl;
    params['userId'] = this.ngRedux.getState().user.userId;
    params['token'] = this.ngRedux.getState().user.token;

    let url = this.authService.getRequestUrl(serviceName, true);
    let body = this.authService.getRequestBody(params);
    let info = new ServiceCallInfo(serviceName, url, body, true);

    response = this.http
      .post(url, body, {
        headers: this.authService.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json',
      })
      .pipe(
        map((data) => this.authService.extractRequestStatus(info, data)),
        catchError((error) => {
          return of(true);
          //return this.authService.handleError(err, info);
        })
      );

    return response;
  }

  getIndDocRefOptions(doc: CatDoc): Observable<IndPhrase[]> {
    let serviceName = 'get_ind_doc_ref_options_func';

    let response = new Observable<IndPhrase[]>();
    let params = new ServiceOptions();
    params.serviceName = serviceName;
    params['docId'] = doc.docId;

    let url = this.authService.getRequestUrl(serviceName);
    let body = this.authService.getRequestBody(params);
    let info = new ServiceCallInfo(serviceName, url, body);
    response = this.http
      .post(url, body, {
        headers: this.authService.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json',
      })
      .pipe(
        take(1),
        map((data) => {
          return this.extractIndDocRefOptions(data, info);
        }),
        catchError((err) => {
          return this.authService.handleError(err, info);
        })
      );
    return response;
  }

  private extractIndDocRefOptions(res, info: ServiceCallInfo): IndPhrase[] {
    this.authService.extractRequestStatus(info, res);
    let result: IndPhrase[] = [];
    let data = res.reply.requestResult.data;

    if (data) {
      let dataTag = data.phrase;
      if (dataTag) {
        let arr = new Array().concat(dataTag);
        arr.forEach((i) => {
          let phrase = new IndPhrase();
          phrase.phraseId = phrase.elementId = i.phraseId;
          phrase.phraseText = i.phraseText;
          phrase.pageNum = i.pageNum;
          if (i.phraseDispWords) {
            let arrWords = new Array().concat(i.phraseDispWords);
            arrWords.forEach((w) => {
              phrase.phraseWords.push(this._extractPhraseWordTag(w));
            });
          }
          if (phrase.phraseText) result.push(phrase);
        });
      }
    }

    this.ngRedux.dispatch({
      type: actionsList.GET_CAT_DOC_REF_OPTIONS,
      data: result,
      reply: res,
    });

    return result;
  }

  _extractPhraseWordTag(tag: any): PhraseWord {
    let word = new PhraseWord();
    word.id = tag.id;
    word.dispFormat = tag.dispFormatCode;
    if (word.dispFormat) word.dispFormatList = word.dispFormat.split('_');
    word.text = tag.txt;
    word.concatSw = this.authService.fromXMLBoolean(tag.concateSw);

    if (tag.charDisp) {
      word.chars = [];
      let arrChars = new Array().concat(tag.charDisp);
      arrChars.forEach((c) => {
        let char = new WordChar();
        char.id = c.charId;
        char.dispFormat = c.dispFormatCode;
        if (char.dispFormat) char.dispFormatList = char.dispFormat.split('_');
        char.text = c.txt;
        word.chars.push(char);
      });
    }
    return word;
  }

  updCat(catalog: Catalog): Observable<boolean> {
    let serviceName = 'upd_cat_func';

    let response = new Observable<boolean>();
    let params = new ServiceOptions();
    params.serviceName = serviceName;
    params['envId'] = this.ngRedux.getState().curEnv.id;
    params['catId'] = catalog.catId;
    params['catAssetTypes'] = catalog.catAssetTypeList.map((e) => {
      return {
        assetType: Number.parseInt(e.id),
        assetDescr: e.text,
      };
    });
    params.urldecodeSw = true;

    let url = this.authService.getRequestUrl(serviceName);
    let body = this.authService.getRequestBody(params);
    let info = new ServiceCallInfo(serviceName, url, body);
    response = this.http
      .post(url, body, {
        headers: this.authService.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json',
      })
      .pipe(
        map((data) => {
          return this.authService.extractRequestStatus(info, data);
        }),
        catchError((err) => {
          return this.authService.handleError(err, info);
        })
      );
    return response;
  }

  getAssetTypes(): Observable<DataElement[]> {
    let serviceName = 'get_asset_types_func';
    let response = new Observable<DataElement[]>();
    let params = new ServiceOptions();
    params.serviceName = serviceName;

    let url = this.authService.getRequestUrl(serviceName);
    let body = this.authService.getRequestBody(params);
    let info = new ServiceCallInfo(serviceName, url, body);

    response = this.http
      .post(url, body, {
        headers: this.authService.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json',
      })
      .pipe(
        take(1),
        map((data) => {
          return this.extractAssetTypes(data, info);
        }),
        catchError((err) => {
          return this.authService.handleError(err, info);
        })
      );
    return response;
  }

  private extractAssetTypes(res, info: ServiceCallInfo): DataElement[] {
    const results: DataElement[] = this.authService.mapToDataElement(
      res,
      'assetType',
      'assetDescr',
      'catAssetTypes',
      info
    );

    this.ngRedux.dispatch({
      type: actionsList.GET_ASSET_TYPES,
      data: results,
      reply: res,
    });

    return results;
  }

  updAssetType(
    row: DataElement,
    deleteSw: boolean = false
  ): Observable<boolean> {
    let response = new Observable<boolean>();
    let serviceName = 'upd_asset_type_func';
    let params = new ServiceOptions();
    params.serviceName = serviceName;

    params['assetType'] = Number.parseInt(row.id);
    params['assetDescr'] = row.text;
    params['deleteSw'] = deleteSw;

    let url = this.authService.getRequestUrl(serviceName);
    let body = this.authService.getRequestBody(params);
    let info = new ServiceCallInfo(serviceName, url, body);
    response = this.http
      .post(url, body, {
        headers: this.authService.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json',
      })
      .pipe(
        map((data) => this.authService.extractRequestStatus(info, data)),
        catchError((error) => {
          return this.authService.handleError(error, info);
        })
      );

    return response;
  }

  getDocFile(
    docId: number,
    docType: string,
    pageNum: number = null
  ): Observable<string> {
    let response = new Observable<string>();
    let serviceName = 'ScaiPyLong.GetDocFile';
    let params = new ServiceOptions();
    params.serviceName = serviceName;

    params['docId'] = docId;
    params['docType'] = docType;
    params['username'] = this.ngRedux.getState().user.username;
    params['userId'] = this.ngRedux.getState().user.userId;
    params['token'] = this.ngRedux.getState().user.token;
    if (pageNum != null) {
      params['pageNum'] = pageNum;
    }

    let url = this.authService.getRequestUrl(serviceName, true);
    let body = this.authService.getRequestBody(params);
    let info = new ServiceCallInfo(serviceName, url, body);

    response = this.http
      .post(url, body, {
        headers: this.authService.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json',
      })
      .pipe(
        map((data) => {
          this.authService.extractRequestStatus(info, data);
          return data['reply']['requestResult']['data']['fileUrl'];
        }),
        catchError((error) => {
          return this.authService.handleError(error, info);
        })
      );

    return response;
  }

  updBulkCatEntry(
    phrases: IndPhrase[],
    phraseTypeCode: string,
    phraseSubTypeCode: string
  ): Observable<boolean> {
    let serviceName = 'upd_bulk_cat_entry_func';

    let response = new Observable<boolean>();
    let params = new ServiceOptions();
    params.serviceName = serviceName;
    params['envId'] = this.ngRedux.getState().curEnv.id;
    params['catId'] = this.ngRedux.getState().curCat.catId;
    params['catVersionId'] = this.ngRedux.getState().curCat.catVersionId;
    params['phraseIdList'] = phrases.map((p) => p.phraseId).join(',');
    params['phraseTypeCode'] = phraseTypeCode;
    params['phraseSubTypeCode'] = phraseSubTypeCode;

    let url = this.authService.getRequestUrl(serviceName);
    let body = this.authService.getRequestBody(params);
    let info = new ServiceCallInfo(serviceName, url, body);
    response = this.http
      .post(url, body, {
        headers: this.authService.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json',
      })
      .pipe(
        map((data) => {
          return this.authService.extractRequestStatus(info, data);
        }),
        catchError((err) => {
          return this.authService.handleError(err, info);
        })
      );
    return response;
  }

  updBulkIndDocPhrase(
    phrases: IndPhrase[],
    phraseTypeCode: string,
    phraseSubTypeCode: string
  ): Observable<boolean> {
    let serviceName = 'upd_bulk_ind_doc_phrase_func';

    let response = new Observable<boolean>();
    let params = new ServiceOptions();
    params.serviceName = serviceName;
    params['envId'] = this.ngRedux.getState().curEnv.id;
    params['docId'] =
      this.ngRedux.getState().curCatDocStatePrime.curCatDoc.docId;
    params['phraseIdList'] = phrases.map((p) => p.phraseId).join(',');
    params['phraseTypeCode'] = phraseTypeCode;
    params['phraseSubTypeCode'] = phraseSubTypeCode;

    let url = this.authService.getRequestUrl(serviceName);
    let body = this.authService.getRequestBody(params);
    let info = new ServiceCallInfo(serviceName, url, body);
    response = this.http
      .post(url, body, {
        headers: this.authService.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json',
      })
      .pipe(
        map((data) => {
          return this.authService.extractRequestStatus(info, data);
        }),
        catchError((err) => {
          return this.authService.handleError(err, info);
        })
      );
    return response;
  }

  getCatImages(searchParams: CatImagesSearchParams): Observable<IndImage[]> {
    if (false) {
      return this.http
        .get(this.mockupDataUrl + 'get_cat_images.json')
        .pipe(map((res) => this.extractGetCatImages(res, null)));
    }

    let serviceName = 'get_cat_images_func';
    let response = new Observable<IndImage[]>();
    let params = new ServiceOptions();
    params.serviceName = serviceName;

    params['envId'] = this.ngRedux.getState().curEnv.id;
    params['catId'] = Number.parseInt(
      this.ngRedux.getState().curCat.catId as any
    );
    params['catVersionId'] = Number.parseInt(
      this.ngRedux.getState().curCat.catVersionId as any
    );

    let url = this.authService.getRequestUrl(serviceName);
    let body = this.authService.getRequestBody(params);
    let info = new ServiceCallInfo(serviceName, url, body);
    response = this.http
      .post(url, body, {
        headers: this.authService.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json',
      })
      .pipe(
        map((data) => this.extractGetCatImages(data, info)),
        catchError((error) => {
          return this.authService.handleError(error, info);
        })
      );

    return response;
  }

  private extractGetCatImages(res: any, info: ServiceCallInfo): IndImage[] {
    this.authService.extractRequestStatus(info, res);
    let result: IndImage[] = [];
    let data = res.reply.requestResult.data;
    let metaData = new ServiceMetaData();

    if (data.metadata) {
      let metaTag = data.metadata;
      metaData.totalRecords = metaTag.totRec;
      metaData.fromRecord = metaTag.fromRec;
      metaData.toRecord = metaTag.toRec;
      metaData.searchId = metaTag.searchId;
    }

    if (data) {
      let dataTag = data.catImage;
      if (dataTag) {
        let arr = new Array().concat(dataTag);
        arr.forEach((i) => {
          result.push(this._extractCatImageTag(i));
        });
      }

      if (!metaData.searchId && result) {
        metaData.fromRecord = 1;
        metaData.toRecord = result.length;
        metaData.totalRecords = result.length;
      }
    }

    this.ngRedux.dispatch({
      type: actionsList.GET_CAT_IMAGES,
      data: result,
      reply: res,
      metaData: metaData,
    });

    return result;
  }

  _extractCatImageTag(i: any): IndImage {
    let e = new IndImage();
    e.imageId = e.elementId = i.imageId;
    e.imgS3Key = i.imgS3Key;
    this.assignOrSubscribeObjectForPresignedImgUrl(e, e.imgS3Key);
    e.typeCode = i.imageTypeCode;
    e.subTypeCode = i.imageSubTypeCode;
    e.typeDescr = i.imageTypeDescr;
    e.subTypeDescr = i.imageSubTypeDescr;
    e.figureType = i.figureType;
    e.figureTypeDescr = i.figureTypeDescr;
    if (i.figureSubType) {
      e.figureSubType = new DataElement(i.figureSubType, i.figureSubTypeDescr);
    }

    let instrTag = i.addInstr;
    if (instrTag) {
      e.addInstr = [];
      let instrArr = new Array().concat(instrTag);
      instrArr.forEach((n) => {
        let instr = new AddInstr();
        instr.id = n.id;
        instr.text = n.txt;
        instr.imgS3Key = n.imgS3Key;
        this.assignOrSubscribeObjectForPresignedImgUrl(instr, instr.imgS3Key);
        instr.type = instr.text ? { value: 'TXT' } : { value: 'IMG' };
        if (instr.imgS3Key != null || instr.text != null) e.addInstr.push(instr);
      });
      e.addInstr.srp_sortByKey('id');
    }

    let docTag = i.doc;
    if (docTag) {
      e.docs = [];
      let docArr = new Array().concat(docTag);
      docArr.forEach((d) => {
        let doc = new CatDoc();
        doc.docId = d.docId;
        doc.docName = d.docName;
        doc.versionId = d.versionId;
        doc.fileName = d.fileName;
        doc.docS3Key = d.docS3Key;
        doc.createDate = this.authService.formatDBDateTime(d.crDate);
        doc.singlePhrasePageUrl = d.imagePageUrl;
        doc.singlePhraseEl = d.phraseEl;
        doc.singlePhrasePageNum = d.pageNum + 1;
        doc.pagesNum = d.pagesCnt;
        let ann = new AnnotationElement();
        ann.x1 = d.x1;
        ann.x2 = d.x2;
        ann.y1 = d.y1;
        ann.y2 = d.y2;
        this._updPhraseAnnElementPolygon(ann, d.borderLines);
        ann.annColor = '#ff2229';
        ann.annId = e.elementId;
        doc.annotation = ann;
        e.docs.push(doc);
      });
    }

    return e;
  }

  getBulkUploadSeq(): Observable<number> {
    let serviceName = 'get_ind_bulk_upload_seq_func';

    let response = new Observable<number>();
    let params = new ServiceOptions();
    params.serviceName = serviceName;
    params['envId'] = this.ngRedux.getState().curEnv.id;
    let url = this.authService.getRequestUrl(serviceName);
    let body = this.authService.getRequestBody(params);
    let info = new ServiceCallInfo(serviceName, url, body);
    response = this.http
      .post(url, body, {
        headers: this.authService.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json',
      })
      .pipe(
        map((data) => {
          return this.extractGetBulkUploadSeq(data, info);
        }),
        catchError((err) => {
          return this.authService.handleError(err, info);
        })
      );
    return response;
  }

  extractGetBulkUploadSeq(res: any, info: ServiceCallInfo): number {
    this.authService.extractRequestStatus(info, res);
    let data = res.reply.requestResult.data;
    let seq: number;

    if (data) {
      seq = data.bulkUploadSeq;
    }

    return seq;
  }

  processIndDocAlg(runNum: number): Observable<boolean> {
    let response = new Observable<boolean>();
    let serviceName = 'ScaiPyAdmin.StartDocProcess';
    let params = new ServiceOptions();
    params.serviceName = serviceName;

    params['runNum'] = runNum;
    //params['username'] = this.ngRedux.getState().user.username;
    params['userId'] = this.ngRedux.getState().user.userId;
    params['token'] = this.ngRedux.getState().user.token;

    let url = this.authService.getRequestUrl(serviceName, true);
    let body = this.authService.getRequestBody(params);
    let info = new ServiceCallInfo(serviceName, url, body);
    response = this.http
      .post(url, body, {
        headers: this.authService.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json',
      })
      .pipe(
        map((data) => this.authService.extractRequestStatus(info, data)),
        catchError((error) => {
          return this.authService.handleError(error, info);
        })
      );

    return response;
  }

  getMCTypes(): Observable<DataElement[]> {
    let serviceName = 'get_mc_types_func';

    let response = new Observable<DataElement[]>();
    let params = new ServiceOptions();
    params.serviceName = serviceName;

    let url = this.authService.getRequestUrl(serviceName);
    let body = this.authService.getRequestBody(params);
    let info = new ServiceCallInfo(serviceName, url, body);

    response = this.http
      .post(url, body, {
        headers: this.authService.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json',
      })
      .pipe(
        take(1),
        map((data) => {
          return this.extractMCTypes(data, info);
        }),
        catchError((err) => {
          return this.authService.handleError(err, info);
        })
      );
    return response;
  }

  private extractMCTypes(res, info: ServiceCallInfo): DataElement[] {
    const results: DataElement[] = this.authService.mapToDataElement(
      res,
      'mcTypeCode',
      'descr',
      'type',
      info
    );

    this.ngRedux.dispatch({
      type: actionsList.GET_MC_TYPES,
      data: results,
      reply: res,
    });

    return results;
  }

  getMCSubTypes(): Observable<McSubType[]> {
    let serviceName = 'get_mc_sub_types_func';

    let response = new Observable<McSubType[]>();
    let params = new ServiceOptions();
    params.serviceName = serviceName;

    let url = this.authService.getRequestUrl(serviceName);
    let body = this.authService.getRequestBody(params);
    let info = new ServiceCallInfo(serviceName, url, body);

    response = this.http
      .post(url, body, {
        headers: this.authService.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json',
      })
      .pipe(
        take(1),
        map((data) => {
          return this.extractMCSubTypes(data, info);
        }),
        catchError((err) => {
          return this.authService.handleError(err, info);
        })
      );
    return response;
  }

  private extractMCSubTypes(res, info: ServiceCallInfo): McSubType[] {
    this.authService.extractRequestStatus(info, res);
    let result: McSubType[] = [];
    let data = res.reply.requestResult.data;

    if (data) {
      let arr = new Array().concat(data.user);
      arr.forEach((i) => {
        let c = new McSubType();
        c.subType = new DataElement(i.mcSubTypeCode, i.descr);
        c.mcTypeCode = i.mcTypeCode;
        result.push(c);
      });
    }

    this.ngRedux.dispatch({
      type: actionsList.GET_MC_SUB_TYPES,
      data: result,
      reply: res,
    });

    return result;
  }

  getMCBoxTypes(): Observable<McBoxType[]> {
    let serviceName = 'get_mc_box_types_func';

    let response = new Observable<McBoxType[]>();
    let params = new ServiceOptions();
    params.serviceName = serviceName;

    let url = this.authService.getRequestUrl(serviceName);
    let body = this.authService.getRequestBody(params);
    let info = new ServiceCallInfo(serviceName, url, body);

    response = this.http
      .post(url, body, {
        headers: this.authService.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json',
      })
      .pipe(
        take(1),
        map((data) => {
          return this.extractMCBoxTypes(data, info);
        }),
        catchError((err) => {
          return this.authService.handleError(err, info);
        })
      );
    return response;
  }

  private extractMCBoxTypes(res, info: ServiceCallInfo): McBoxType[] {
    this.authService.extractRequestStatus(info, res);
    let result: McBoxType[] = [];
    let data = res.reply.requestResult.data;

    if (data) {
      let arr = new Array().concat(data.mcBoxType);
      arr.forEach((i) => {
        let c = new McBoxType();
        c.mcBoxType = new DataElement(i.mcBoxType, i.descr);
        c.mcTypeCode = i.mcTypeCode;
        c.mcSubTypeCode = i.mcSubTypeCode;
        c.blockRules = i.blockRules;
        result.push(c);
      });
    }

    this.ngRedux.dispatch({
      type: actionsList.GET_MC_BOX_TYPES,
      data: result,
      reply: res,
    });

    return result;
  }

  createMcReportReq(card: Card): Observable<number> {
    let serviceName = 'create_mc_report_req_func';

    let response = new Observable<number>();
    let params = new ServiceOptions();
    params.serviceName = serviceName;

    params['envId'] = this.ngRedux.getState().curEnv.id;
    params['catVersionId'] = this.ngRedux.getState().curCat.catVersionId;
    params['cardId'] = card.cardId;

    let url = this.authService.getRequestUrl(serviceName);
    let body = this.authService.getRequestBody(params);
    let info = new ServiceCallInfo(serviceName, url, body);
    response = this.http
      .post(url, body, {
        headers: this.authService.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json',
      })
      .pipe(
        map((data) => {
          return this.extractCreateMcReportReq(data, info);
        }),
        catchError((err) => {
          return this.authService.handleError(err, info);
        })
      );
    return response;
  }

  extractCreateMcReportReq(res: any, info: ServiceCallInfo): number {
    this.authService.extractRequestStatus(info, res);
    let data = res.reply.requestResult.data;
    if (data) {
      return data.runNum;
    }

    return 0;
  }

  startProcessRun(runNum: number): Observable<boolean> {
    let response = new Observable<boolean>();
    let serviceName = 'ScaiPyAdmin.StartDocProcess';
    let params = new ServiceOptions();
    params.serviceName = serviceName;

    params['runNum'] = runNum;
    params['userId'] = this.ngRedux.getState().user.userId;
    params['token'] = this.ngRedux.getState().user.token;

    let url = this.authService.getRequestUrl(serviceName, true);
    let body = this.authService.getRequestBody(params);
    let info = new ServiceCallInfo(serviceName, url, body);
    response = this.http
      .post(url, body, {
        headers: this.authService.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json',
      })
      .pipe(
        map((data) => this.authService.extractRequestStatus(info, data)),
        catchError((error) => {
          return this.authService.handleError(error, info);
        })
      );

    return response;
  }

  updIndImg(
    pageNum: number,
    doc: CatDoc,
    box: BoundingBox,
    typeCode: string,
    subTypeCode: string,
    figureType: string,
    figureSubType: string
  ): Observable<number> {
    let response = new Observable<number>();
    let serviceName = 'upd_ind_img_func';
    let params = new ServiceOptions();
    params.serviceName = serviceName;

    params['catVersionId'] = this.ngRedux.getState().curCat.catVersionId;
    if (doc) params['docId'] = doc.docId;
    if (pageNum) params['pageNum'] = pageNum - 1;

    params['imageTypeCode'] = typeCode;
    params['imageSubTypeCode'] = subTypeCode;
    params['figureType'] = figureType;
    params['figureSubType'] = figureSubType;

    params['x1'] = Math.round(box.x1);
    params['y1'] = Math.round(box.y1);
    params['x2'] = Math.round(box.x2);
    params['y2'] = Math.round(box.y2);
    params['docPageWidth'] = Math.round(box.pageWidth);
    params['docPageHeight'] = Math.round(box.pageHeight);

    let url = this.authService.getRequestUrl(serviceName);
    let body = this.authService.getRequestBody(params);
    let info = new ServiceCallInfo(serviceName, url, body);
    response = this.http
      .post(url, body, {
        headers: this.authService.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json',
      })
      .pipe(
        map((rep) => {
          this.authService.extractRequestStatus(info, rep);
          let data = (rep as any).reply.requestResult.data;
          return data.imageId;
        }),
        catchError((error) => {
          return this.authService.handleError(error, info);
        })
      );

    return response;
  }

  createIndImage(docId: number, imageId: number): Observable<string> {
    let response = new Observable<string>();
    let serviceName = 'ScaiPyAdmin.CreateIndImage';
    let params = new ServiceOptions();
    params.serviceName = serviceName;

    params['docId'] = docId;
    params['imageId'] = imageId;
    params['userId'] = this.ngRedux.getState().user.userId;
    params['token'] = this.ngRedux.getState().user.token;

    let url = this.authService.getRequestUrl(serviceName, true);
    let body = this.authService.getRequestBody(params);
    let info = new ServiceCallInfo(serviceName, url, body, false);

    response = this.http
      .post(url, body, {
        headers: this.authService.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json',
      })
      .pipe(
        map((rep) => {
          this.authService.extractRequestStatus(info, rep);
          let data = (rep as any).reply.requestResult.data;
          return data.url;
        }),
        catchError((error) => {
          return this.authService.handleError(error, info);
        })
      );

    return response;
  }

  getDocObjects(docId: number, docType: 'IND' | 'REV'): Observable<Map<string, string>> {
    let response = new Observable<Map<string, string>>();
    let serviceName = 'ScaiPyLong.GetDocObjects';
    let params = new ServiceOptions();
    params.serviceName = serviceName;

    params['docId'] = docId;
    params['docType'] = docType;
    params['userId'] = this.ngRedux.getState().user.userId;
    params['token'] = this.ngRedux.getState().user.token;

    let url = this.authService.getRequestUrl(serviceName, true);
    let body = this.authService.getRequestBody(params);
    let info = new ServiceCallInfo(serviceName, url, body);
    response = this.http
      .post(url, body, {
        headers: this.authService.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json',
      })
      .pipe(
        map((data) => this.extractPresignedUrls(data, info)),
        catchError((error) => {
          return this.authService.handleError(error, info);
        })
      );

    return response;
  }

  getS3Objects(s3Key: string[], syncSw: boolean = false): Observable<Map<string, string>> {
    let response = new Observable<Map<string, string>>();
    let serviceName = 'ScaiPyLong.GetS3Objects';
    let params = new ServiceOptions();
    params.serviceName = serviceName;
    params['s3Key'] = s3Key;
    params['userId'] = this.ngRedux.getState().user.userId;
    params['token'] = this.ngRedux.getState().user.token;
    params['syncSw'] = syncSw;

    let url = this.authService.getRequestUrl(serviceName, true);
    let body = this.authService.getRequestBody(params);
    let info = new ServiceCallInfo(serviceName, url, body);
    response = this.http
      .post(url, body, {
        headers: this.authService.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json',
      })
      .pipe(
        map((data) => this.extractPresignedUrls(data, info)),
        catchError((error) => {
          return this.authService.handleError(error, info);
        })
      );

    return response;
  }

  private extractPresignedUrls(
    res: any,
    info: ServiceCallInfo
  ): Map<string, string> {
    this.authService.extractRequestStatus(info, res);
    let result: Map<string, string> = new Map<string, string>();
    let data = res.reply.requestResult.data;
    if(data.presigned_urls) {
      result = new Map(data.presigned_urls.map(obj => [obj.obj_name, obj.url]));
      // add all the newly generated URLs (results) to the internal presignedUrls map
      this.presignedUrls = new Map([...this.presignedUrls.entries(), ...result.entries()]);
      this.notifyObjectsWaitingPresignedUrls();
    }
    return result;
  }

  private notifyObjectsWaitingPresignedUrls(): void {
    const waitedObjectsKeys = Array.from(this.objectsWaitingPresignedUrls.keys());
    waitedObjectsKeys.forEach((s3Key) => {
      if(this.presignedUrls.has(s3Key)) {
        let waitingObject = this.objectsWaitingPresignedUrls.get(s3Key);
        if(waitingObject instanceof IndImage || waitingObject instanceof BoundingBox || waitingObject instanceof AddInstr || waitingObject instanceof RevRule) {
          waitingObject.imgUrl = this.presignedUrls.get(s3Key);
          this.objectsWaitingPresignedUrls.delete(s3Key);
        }
      }
    })
  }

  assignOrSubscribeObjectForPresignedImgUrl(obj: IndImage | BoundingBox | AddInstr | RevRule, imgS3Key: string): void {
    if(this.presignedUrls.has(imgS3Key)) {
      obj.imgUrl = this.presignedUrls.get(imgS3Key);
    } else {
      obj.imgUrl = '';
      this.objectsWaitingPresignedUrls.set(imgS3Key, obj);
    }
  }

  resolveAnyRemainingPresignedUrl(): void {
    this.getS3Objects(Array.from(this.objectsWaitingPresignedUrls.keys())).subscribe();
  }

  getS3TempObjectPresignedUrl(s3Key: string): string {
    return this.presignedUrls.get(s3Key);
  }

  updateLabelChange(lc: LabelChange): Observable<void> {
    let response = new Observable<void>();
    let serviceName = 'update_label_change_func';
    let params = new ServiceOptions();
    params.serviceName = serviceName;

    params['envId'] = this.ngRedux.getState().curEnv.id;
    params['lcId'] = lc.lcId;
    params['title'] = lc.title;
    params['descr'] = lc.descr;
    params['catVersionId'] = lc.catalog.catVersionId;

    params['products'] = [
      {
        productId: lc.product.id as number,
      },
    ];

    params['bcDocs'] = [];
    lc.bcDocs.forEach((r) => {
      params['bcDocs'].push({
        docId: r.origDocId,
        trainingSw: r.trainingSw ?? false,
      });
    });

    params['acDocs'] = [];
    lc.acDocs.forEach((r) => {
      params['acDocs'].push({
        docId: r.origDocId,
        trainingSw: r.trainingSw ?? false,
      });
    });

    params['acPhrases'] = [];
    lc.acPhrases.forEach((r) => {
      params['acPhrases'].push({
        catPhraseId: r.phraseId,
      });
    });

    params['bcPhrases'] = [];
    lc.bcPhrases.forEach((r) => {
      params['bcPhrases'].push({
        catPhraseId: r.phraseId,
      });
    });

    let url = this.authService.getRequestUrl(serviceName);
    let body = this.authService.getRequestBody(params);
    let info = new ServiceCallInfo(serviceName, url, body);
    response = this.http
      .post(url, body, {
        headers: this.authService.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json',
      })
      .pipe(
        map((rep) => {
          this.extractUpdLC(rep, info, lc);
        }),
        catchError((error) => {
          return this.authService.handleError(error, info);
        })
      );

    return response;
  }

  private extractUpdLC(res, info: ServiceCallInfo, lc: LabelChange): void {
    this.authService.extractRequestStatus(info, res);
    let data = res.reply.requestResult.data;

    if (data && data.lc) {
      lc = this._extractLCTag(data.lc);
    }
  }

  addTaggedDocs(docIds: number[], docType: 'IND' | 'REV'): Observable<boolean> {
    let response = new Observable<boolean>();
    let serviceName = 'upd_tagged_doc_func';
    let params = new ServiceOptions();
    params.serviceName = serviceName;

    params['docs'] = [];
    docIds.forEach((d) => {
      params['docs'].push({
        docId: d,
        docType: docType,
        deleteSw: false,
        refTagSw: false,
        matchTagSw: false,
      });
    });

    let url = this.authService.getRequestUrl(serviceName);
    let body = this.authService.getRequestBody(params);
    let info = new ServiceCallInfo(serviceName, url, body);
    response = this.http
      .post(url, body, {
        headers: this.authService.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json',
      })
      .pipe(
        map((rep) => {
          return this.authService.extractRequestStatus(info, rep);
        }),
        catchError((error) => {
          return this.authService.handleError(error, info);
        })
      );

    return response;
  }

  updTaggedDoc(
    doc: TaggedIndDoc,
    deleteSw: boolean = false
  ): Observable<boolean> {
    let response = new Observable<boolean>();
    let serviceName = 'upd_tagged_doc_func';
    let params = new ServiceOptions();
    params.serviceName = serviceName;

    params['docs'] = [
      {
        docId: doc.docId,
        deleteSw: deleteSw,
        refTagSw: doc.refTagSw,
        matchTagSw: doc.matchTagSw,
      },
    ];

    let url = this.authService.getRequestUrl(serviceName);
    let body = this.authService.getRequestBody(params);
    let info = new ServiceCallInfo(serviceName, url, body);
    response = this.http
      .post(url, body, {
        headers: this.authService.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json',
      })
      .pipe(
        map((rep) => {
          return this.authService.extractRequestStatus(info, rep);
        }),
        catchError((error) => {
          return this.authService.handleError(error, info);
        })
      );

    return response;
  }

  getLabelChanges(
    searchParams: LCSearchParams,
    dispatch: boolean = true
  ): Observable<LabelChange[]> {
    let serviceName = 'get_lc_list_func';

    let response = new Observable<LabelChange[]>();
    let params = new ServiceOptions();
    params.serviceName = serviceName;

    params['envId'] = this.ngRedux.getState().curEnv.id;

    if (searchParams.lcId) params['lcId'] = searchParams.lcId;
    if (searchParams.product) params['productIdList'] = searchParams.product.id;
    if (searchParams.searchId) params['searchId'] = searchParams.searchId;
    if (searchParams.rangeFrom) params['fromRec'] = searchParams.rangeFrom;
    if (searchParams.rangeTo) params['toRec'] = searchParams.rangeTo;

    let url = this.authService.getRequestUrl(serviceName);
    let body = this.authService.getRequestBody(params);
    let info = new ServiceCallInfo(serviceName, url, body);
    response = this.http
      .post(url, body, {
        headers: this.authService.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json',
      })
      .pipe(
        map((data) => this.extractGetLabelChanges(data, info, dispatch)),
        catchError((error) => {
          return this.authService.handleError(error, info);
        })
      );

    return response;
  }

  private extractGetLabelChanges(
    res: any,
    info: ServiceCallInfo,
    dispatch: boolean
  ): LabelChange[] {
    this.authService.extractRequestStatus(info, res);
    let result: LabelChange[] = [];
    let data = res.reply.requestResult.data;
    let metaData = new ServiceMetaData();

    if (data.metadata) {
      let metaTag = data.metadata;
      metaData.totalRecords = metaTag.totRec;
      metaData.fromRecord = metaTag.fromRec;
      metaData.toRecord = metaTag.toRec;
      metaData.searchId = metaTag.searchId;
    }

    if (data) {
      let dataTag = data.lc;
      if (dataTag) {
        let arr = new Array().concat(dataTag);
        arr.forEach((i) => {
          let lc = this._extractLCTag(i);
          result.push(lc);
        });
      }

      if (!metaData.searchId && result) {
        metaData.fromRecord = 1;
        metaData.toRecord = result.length;
        metaData.totalRecords = result.length;
      }
    }

    if (dispatch)
      this.ngRedux.dispatch({
        type: actionsList.GET_LC,
        data: result,
        reply: res,
        metaData: metaData,
      });

    return result;
  }

  getLCDetails(lcId: number): Observable<LabelChange> {
    let serviceName = 'get_lc_details_func';

    let response = new Observable<LabelChange>();
    let params = new ServiceOptions();
    params.serviceName = serviceName;

    params['envId'] = this.ngRedux.getState().curEnv.id;
    params['lcId'] = lcId;

    let url = this.authService.getRequestUrl(serviceName);
    let body = this.authService.getRequestBody(params);
    let info = new ServiceCallInfo(serviceName, url, body);
    response = this.http
      .post(url, body, {
        headers: this.authService.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json',
      })
      .pipe(
        map((data) => this.extractLCDetails(data, info)),
        catchError((error) => {
          return this.authService.handleError(error, info);
        })
      );

    return response;
  }

  private extractLCDetails(res: any, info: ServiceCallInfo): LabelChange {
    this.authService.extractRequestStatus(info, res);
    let data = res.reply.requestResult.data;
    if (data) {
      return this._extractLCTag(data.lc);
    }
  }

  _extractLCTag(i: any): LabelChange {
    let lc = new LabelChange();
    lc.lcId = i.lcId;
    lc.title = i.title;
    lc.descr = i.descr;
    // lc.crDate = this.datePipe.transform(
    //   i.crDate,
    //   SharedService.appDateFormat,
    //   this.authService.currentUser.timezone
    // );
    lc.crDate = this.authService.formatDBDateTime(i.crDate);
    lc.catalog = new Catalog();
    lc.catalog.catVersionId = i.catVersionId;
    lc.catalog.catDescr = i.catDescr;
    lc.catalog.productId = i.productId;
    lc.acDocsCnt = i.acDocsCnt;
    lc.bcDocsCnt = i.bcDocsCnt;

    let pArr = new Array().concat(i.products);
    pArr.forEach((p) => {
      lc.product = new DataElement(p.productId, p.productDescr);
    });

    if (i.acPhrases) {
      let acPhraseArr = new Array().concat(i.acPhrases);
      acPhraseArr.forEach((a) => {
        let phrase = this._extractCatEntryTag(a);
        lc.acPhrases.push(phrase);
      });
    }

    if (i.bcPhrases) {
      let bcPhraseArr = new Array().concat(i.bcPhrases);
      bcPhraseArr.forEach((a) => {
        let phrase = this._extractCatEntryTag(a);
        lc.bcPhrases.push(phrase);
      });
    }

    if (i.acDocs) {
      let acDocArr = new Array().concat(i.acDocs);
      acDocArr.forEach((a) => {
        let doc = this._extractLCDocTag(a);
        lc.acDocs.push(doc);
      });
    }

    if (i.bcDocs) {
      let ccDocArr = new Array().concat(i.bcDocs);
      ccDocArr.forEach((a) => {
        let doc = this._extractLCDocTag(a);
        lc.bcDocs.push(doc);
      });
    }

    if (i.acStats) {
      let statTag = i.acStats;
      lc.acStats.lcInvalidationFoundCnt = statTag.lcInvalidationFoundCnt;
      lc.acStats.lcValidatedCnt = statTag.lcValidatedCnt;
      lc.acStats.lcMissingCnt = statTag.lcMissingCnt;
    }

    if (i.bcStats) {
      let statTag = i.bcStats;
      lc.bcStats.lcInvalidationFoundCnt = statTag.lcInvalidationFoundCnt;
      lc.bcStats.lcValidatedCnt = statTag.lcValidatedCnt;
      lc.bcStats.lcMissingCnt = statTag.lcMissingCnt;
    }

    if (i.bcProcess) {
      let pTag = i.bcProcess;
      lc.bcProcess = new ProcessRunStatusResult();
      lc.bcProcess.runNum = pTag.runNum;
      // lc.bcProcess.createDate = this.datePipe.transform(
      //   pTag.createDate,
      //   SharedService.appDateFormat,
      //   this.authService.currentUser.timezone
      // );
      lc.bcProcess.createDate = this.authService.formatDBDateTime(
        pTag.createDate
      );
      lc.bcProcess.status = pTag.status;
    }

    if (i.acProcess) {
      let pTag = i.acProcess;
      lc.acProcess = new ProcessRunStatusResult();
      lc.acProcess.runNum = pTag.runNum;
      // lc.acProcess.createDate = this.datePipe.transform(
      //   pTag.createDate,
      //   SharedService.appDateFormat,
      //   this.authService.currentUser.timezone
      // );
      lc.acProcess.createDate = this.authService.formatDBDateTime(
        pTag.createDate
      );
      lc.acProcess.status = pTag.status;
    }

    if (i.cards) {
      let dataTag = i.cards;
      let arr = new Array().concat(dataTag);
      arr.forEach((i) => {
        lc.cards.push(this._extractCardTag(i));
      });
    }

    if (i.acDetectedPhrases) {
      let acPhraseArr = new Array().concat(i.acDetectedPhrases);
      acPhraseArr.forEach((a) => {
        let phrase = this._extractIndPhraseTag(a, null);
        lc.acDetectedPhrases.push(phrase);
      });
    }

    if (i.bcDetectedPhrases) {
      let bcPhraseArr = new Array().concat(i.bcDetectedPhrases);
      bcPhraseArr.forEach((a) => {
        let phrase = this._extractIndPhraseTag(a, null);
        lc.bcDetectedPhrases.push(phrase);
      });
    }

    return lc;
  }

  _extractLCDocTag(tag: any): LCDoc {
    let doc = this._extractCatDocTag(tag);
    let lcDoc = Object.assign(new LCDoc(), doc);
    lcDoc.trainingSw = this.authService.fromXMLBoolean(tag.trainingSw);
    lcDoc.lcMatchStatusDescr = tag.matchStatusCodeDescr;
    lcDoc.lcMatchStatusCode = tag.matchStatusCode;
    return lcDoc;
  }

  getIndTaggedDocs(
    searchParams: TaggedDocsSearchParams
  ): Observable<TaggedIndDoc[]> {
    let serviceName = 'get_ind_tagged_docs_func';

    let response = new Observable<TaggedIndDoc[]>();
    let params = new ServiceOptions();
    params.serviceName = serviceName;

    if (searchParams.envId) params['envId'] = searchParams.envId;
    if (searchParams.catId) params['catId'] = searchParams.catId;
    if (searchParams.docIdName) params['docIdName'] = searchParams.docIdName;

    if (searchParams.searchId) params['searchId'] = searchParams.searchId;
    if (searchParams.rangeFrom) params['fromRec'] = searchParams.rangeFrom;
    if (searchParams.rangeTo) params['toRec'] = searchParams.rangeTo;

    let url = this.authService.getRequestUrl(serviceName);
    let body = this.authService.getRequestBody(params);
    let info = new ServiceCallInfo(serviceName, url, body);
    response = this.http
      .post(url, body, {
        headers: this.authService.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json',
      })
      .pipe(
        map((data) => this.extractIndTaggedDocs(data, info)),
        catchError((error) => {
          return this.authService.handleError(error, info);
        })
      );

    return response;
  }

  private extractIndTaggedDocs(
    res: any,
    info: ServiceCallInfo
  ): TaggedIndDoc[] {
    this.authService.extractRequestStatus(info, res);
    let result: TaggedIndDoc[] = [];
    let data = res.reply.requestResult.data;
    let metaData = new ServiceMetaData();

    if (data.metadata) {
      let metaTag = data.metadata;
      metaData.totalRecords = metaTag.totRec;
      metaData.fromRecord = metaTag.fromRec;
      metaData.toRecord = metaTag.toRec;
      metaData.searchId = metaTag.searchId;
    }

    if (data) {
      let dataTag = data.docs;
      if (dataTag) {
        let arr = new Array().concat(dataTag);
        arr.forEach((i) => {
          let doc = new TaggedIndDoc();
          doc = this._extractCatDocTag(i);
          doc.refTagSw = i.refTagSw;
          doc.matchTagSw = i.matchTagSw;
          if (i.replicatedDoc) {
            doc.replicatedDoc = this._extractCatDocTag(i.replicatedDoc);
          }
          let envArr = new Array().concat(i.env);
          doc.env = [];
          envArr.forEach((a) => {
            let env = new Env();
            env.id = a.envId;
            env.descr = a.envDescr;
            doc.env.push(env);
          });
          result.push(doc);
        });
      }

      if (!metaData.searchId && result) {
        metaData.fromRecord = 1;
        metaData.toRecord = result.length;
        metaData.totalRecords = result.length;
      }
    }

    this.ngRedux.dispatch({
      type: actionsList.GET_IND_TAGGED_DOCS,
      data: result,
      reply: res,
      metaData: metaData,
    });

    return result;
  }

  createLCRun(
    lcId: number,
    runType: 'LABEL_CHANGE_BC' | 'LABEL_CHANGE_AC'
  ): Observable<number> {
    let serviceName = 'create_lc_run_func';

    let response = new Observable<number>();
    let params = new ServiceOptions();
    params.serviceName = serviceName;

    params['lcId'] = lcId;
    params['runType'] = runType;

    let url = this.authService.getRequestUrl(serviceName);
    let body = this.authService.getRequestBody(params);
    let info = new ServiceCallInfo(serviceName, url, body);
    response = this.http
      .post(url, body, {
        headers: this.authService.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json',
      })
      .pipe(
        map((data) => {
          return this.extractCreateLCRun(data, info);
        }),
        catchError((err) => {
          return this.authService.handleError(err, info);
        })
      );
    return response;
  }

  extractCreateLCRun(res: any, info: ServiceCallInfo): number {
    this.authService.extractRequestStatus(info, res);
    let data = res.reply.requestResult.data;
    if (data) {
      return data.runNum;
    }

    return 0;
  }

  replicateDoc(docId: number, docType: 'IND' | 'REV'): Observable<string> {
    let response = new Observable<string>();
    let serviceName = 'ScaiPyLong.ReplicateDoc';
    let params = new ServiceOptions();
    params.serviceName = serviceName;

    params['docId'] = docId;
    params['docType'] = docType;
    params['userId'] = this.ngRedux.getState().user.userId;
    params['token'] = this.ngRedux.getState().user.token;

    let url = this.authService.getRequestUrl(serviceName, true);
    let body = this.authService.getRequestBody(params);
    let info = new ServiceCallInfo(serviceName, url, body);
    response = this.http
      .post(url, body, {
        headers: this.authService.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json',
      })
      .pipe(
        map((data) => this.extractReplicateDoc(data, info)),
        catchError((error) => {
          return this.authService.handleError(error, info);
        })
      );

    return response;
  }

  extractReplicateDoc(res: any, info: ServiceCallInfo): string {
    this.authService.extractRequestStatus(info, res);
    let data = res.reply.requestResult.data;
    if (data) {
      return data.docS3Key;
    }
    return null;
  }

  getCatEntryStatusList(): Observable<DataElement[]> {
    let serviceName = 'get_cat_entry_status_list_func';
    let response = new Observable<DataElement[]>();
    let params = new ServiceOptions();
    params.serviceName = serviceName;

    let url = this.authService.getRequestUrl(serviceName);
    let body = this.authService.getRequestBody(params);
    let info = new ServiceCallInfo(serviceName, url, body);

    response = this.http
      .post(url, body, {
        headers: this.authService.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json',
      })
      .pipe(
        take(1),
        map((data) => {
          return this.extractCatEntryStatusList(data, info);
        }),
        catchError((err) => {
          return this.authService.handleError(err, info);
        })
      );
    return response;
  }

  private extractCatEntryStatusList(res, info: ServiceCallInfo): DataElement[] {
    const results: DataElement[] = this.authService.mapToDataElement(
      res,
      'catEntryStatus',
      'catEntryStatusDescr',
      'status',
      info
    );

    this.ngRedux.dispatch({
      type: actionsList.GET_CAT_ENTRY_STATUS_LIST,
      data: results,
      reply: res,
    });

    return results;
  }

  publishLC(lc: LabelChange): Observable<boolean> {
    let serviceName = 'publish_lc_func';

    let response = new Observable<boolean>();
    let params = new ServiceOptions();
    params.serviceName = serviceName;
    params['lcId'] = lc.lcId;

    let url = this.authService.getRequestUrl(serviceName);
    let body = this.authService.getRequestBody(params);
    let info = new ServiceCallInfo(serviceName, url, body);
    response = this.http
      .post(url, body, {
        headers: this.authService.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json',
      })
      .pipe(
        map((data) => {
          return this.authService.extractRequestStatus(info, data);
        }),
        catchError((err) => {
          return this.authService.handleError(err, info);
        })
      );
    return response;
  }

  _extractRevRuleTag(i: any): RevRule {
    let r = new RevRule();
    r.revRuleId = i.revRuleId;
    r.phraseId = i.phraseId;
    r.revRuleType = i.revRuleTypeCode;
    r.revRuleTypeDescr = i.revRuleTypeDescr;
    r.ruleDescr = i.ruleDescr;
    r.pageNum = i.dispPageNum;
    r.revStatusCode = i.revRuleStatusCode;
    r.revStatusDescr = i.revRuleStatusDescr;
    r.imageId = i.imageId;
    r.figureType = i.figureType;
    r.figureTypeDescr = i.figureTypeDescr;
    if (i.figureSubType) {
      r.figureSubType = new DataElement(i.figureSubType, i.figureSubTypeDescr);
    }
    //r.imgUrl = i.imageUrl;
    if (i.imgS3Key) {
      this.assignOrSubscribeObjectForPresignedImgUrl(r, i.imgS3Key);
    }

    if (
      this.sharedService
        .getRevRuleUpdStatusOptions()
        .find((e) => e.id == r.revStatusCode)
    ) {
      r.ruleUserStatus = this.sharedService
        .getRevRuleUpdStatusOptions()
        .find((e) => e.id == r.revStatusCode);
    }

    r.similarityCode = i.matchSimilarityCode;
    r.similarityDescr = i.matchSimilarityDescr;
    r.similarityPct = i.similarityPct;
    r.isRefSw = this.authService.fromXMLBoolean(i.isRefSw);
    r.comment = i.revRuleComment;

    r.x1 = i.x1;
    r.y1 = i.y1;
    r.x2 = i.x2;
    r.y2 = i.y2;
    this._updPhraseAnnElementPolygon(r, i.phraseBorder);
    r.pageHeight = i.pageYsize;
    r.pageWidth = i.pageXsize;

    let annTag = i.annElm;
    if (annTag) {
      r.annElFailed = annTag.annElNotPassed;
      r.annElDismissed = annTag.annElDismissed;
      r.annElMarkedForChanges = annTag.annElMarkedForChanges;
      r.annElPassed = annTag.annElPassed;
      r.annElStet = annTag.annElStet;
      r.annElNotPassedVar = annTag.annElNotPassedVar;
    }

    if (i.formatMatch) {
      console.log(i.formatMatch);
      let arrF = new Array().concat(i.formatMatch);
      arrF.forEach((w) => {
        let word = new PhraseWord();
        word.id = w.wordId;
        word.text = w.txt;
        word.isBold = w.boldSw;
        word.isItalic = w.italicSw;
        word.markDiffSw = w.diffSw;
        word.mCardId = w.mcCardId;
        if (word.markDiffSw) word.formatDescr = w.formatDescr;
        r.formatMatchPhrase.push(word);

        let entry = new PhraseWord();
        entry.id = w.wordId;
        entry.text = w.catTxt;
        entry.isBold = w.catBoldSw;
        entry.isItalic = w.catItalicSw;
        entry.mCardId = w.mcCardId;
        if (word.markDiffSw) entry.formatDescr = w.catFormatDescr;
        r.formatMatchEntry.push(entry);
      });
    }

    if (i.refPhrases) {
      let arrRef = new Array().concat(i.refPhrases);
      r.references = [];
      arrRef.forEach((e) => {
        let ref = new ReferencePhrase();
        ref.refPhraseId = e.refPhraseId;
        ref.refPhraseText = e.refTxt;
        ref.refType = e.refTypeCode;
        ref.phraseId = e.phraseId;
        if (e.phraseDispWords) {
          let arrWords = new Array().concat(e.phraseDispWords);
          arrWords.forEach((w) => {
            ref.phraseDispWords.push(this._extractPhraseWordTag(w));
          });
        }
        r.references.push(ref);
      });
    }

    if (i.refByPhrases) {
      let arrRefBy = new Array().concat(i.refByPhrases);
      r.referencedBy = [];
      arrRefBy.forEach((b) => {
        let ref = new ReferencePhrase();
        ref.refPhraseId = b.refPhraseId;
        ref.refPhraseText = b.txt;
        ref.refType = b.refTypeCode;
        ref.phraseId = b.phraseId;
        if (b.phraseDispWords) {
          let arrWords = new Array().concat(b.phraseDispWords);
          arrWords.forEach((w) => {
            ref.phraseDispWords.push(this._extractPhraseWordTag(w));
          });
        }
        r.referencedBy.push(ref);
      });
    }

    if (i.addInstr) {
      let arrInstr = new Array().concat(i.addInstr);
      r.addInstr = [];
      arrInstr.forEach((i) => {
        let instr = new AddInstr();
        instr.id = i.id;
        instr.text = i.text;
        instr.imgS3Key = i.imgS3Key;
        if (i.imgS3Key) {
          this.assignOrSubscribeObjectForPresignedImgUrl(instr, i.imgS3Key);
        }
        instr.type = instr.text ? { value: 'TXT' } : { value: 'IMG' };
        if (instr.imgS3Key != null || instr.text != null) r.addInstr.push(instr);
      });
    }

    if (i.ruleDispWords) {
      let arrWords = new Array().concat(i.ruleDispWords);
      r.ruleDispWords = [];
      arrWords.forEach((w) => {
        r.ruleDispWords.push(this._extractPhraseWordTag(w));
      });
    }

    // CAT MATCH

    if (i.catMatch) {
      let matchArr = new Array().concat(i.catMatch);
      r.ruleCatMatches = [];
      matchArr.forEach((i) => {
        let m = new CatMatch();
        m.catMatchSeq = i.catMatchSeq;
        m.similarityPct = i.similarityPct;
        m.reportDisplaySw = this.authService.fromXMLBoolean(i.reportDisplaySw);

        if (i.matchDiffWords) {
          m.diffParts = [];
          let mDiffArr = new Array().concat(i.matchDiffWords);
          mDiffArr.forEach((d) => {
            let diffPart = new MatchDiff();
            diffPart.id = d.id;
            diffPart.text = d.txt;
            diffPart.diffType = d.difftype;
            m.diffParts.push(diffPart);
          });

          m.diffParts.srp_sortByKey('id');
        }

        if (i.refCatMatch) {
          let mRefMatchArr = new Array().concat(i.refCatMatch);
          mRefMatchArr.forEach((r) => {
            let refMatch = new RefMatch();
            refMatch.catRefPhraseId = r.catRefPhraseId;
            refMatch.refPhraseId = r.refPhraseId;
            refMatch.similarityPct = r.similarity;
            refMatch.matchSimilarityCode = r.matchSimilarityCode;
            refMatch.altCatRefPhraseId = r.altCatRefPhraseId;
            m.refMatch.push(refMatch);
          });
        }

        if (i.catEntry) {
          m.catPhrase = this._extractCatEntryTag(i.catEntry);
          m.catPhrase.references.forEach((r) => {
            let idx = m.refMatch.findIndex(
              (m) => r.refPhraseId == m.catRefPhraseId
            );

            if (idx != -1) {
              let matchingRef = m.refMatch[idx];
              r.similarityPct = matchingRef.similarityPct;
              r.matchSimilarityCode = matchingRef.matchSimilarityCode;
              r.altCatRefPhraseId = matchingRef.altCatRefPhraseId;
            } else {
              r.matchSimilarityCode = 'MISSING';
            }
          });

          // extra ref
          m.refMatch.forEach((rf) => {
            if (rf.catRefPhraseId == -1) {
              let idx = m.catPhrase.references.findIndex(
                (r) => rf.refPhraseId == r.refPhraseId
              );
              if (idx != -1) {
                let workingPhraseRef = m.catPhrase.references[idx];
                let extraRef = new ReferencePhrase();
                extraRef.extraRefSw = true;
                extraRef.refPhraseText = workingPhraseRef.refPhraseText;
                m.extraReferences.push(extraRef);
              }
            }
          });
        }

        if (i.catImage) {
          m.catImage = this._extractCatImageTag(i.catImage);
        }

        if (i.tblRowDiff) {
          m.tblRowDiff = [];
          let diffArr = new Array().concat(i.tblRowDiff);
          diffArr.forEach((d) => {
            let rowDiff = new TblRowDiff();

            let cellsArr = new Array().concat(d.catRow.cells);
            cellsArr.forEach((c) => {
              let cell = new TblCell();
              cell.cellId = c.cellId;
              cell.txt = c.txt;
              cell.diffType = c.diffType;
              rowDiff.catRow.cells.push(cell);
            });

            cellsArr = new Array().concat(d.docRow.cells);
            cellsArr.forEach((c) => {
              let cell = new TblCell();
              cell.cellId = c.cellId;
              cell.txt = c.txt;
              cell.diffType = c.diffType;
              rowDiff.docRow.cells.push(cell);
            });

            m.tblRowDiff.push(rowDiff);
          });
        }

        r.ruleCatMatches.push(m);
      });

      r.ruleCatMatches.srp_sortByKey('similarityPct', -1);
    }

    // CARD  MATCH
    if (i.cardMatchId && i.cardMatchId != -1) {
      r.cardMatchId = i.cardMatchId;
      if (this.ngRedux.getState().revCardMatches) {
        this._setRuleCardMatch(r);
      }
    }

    // grammar and spelling issues
    if (i.grammarSpellingIssues) {
      let arrGrammarSpellingIssues = new Array().concat(i.grammarSpellingIssues);
      r.grammarSpellingIssues = [];
      arrGrammarSpellingIssues.forEach(issue => {
        r.grammarSpellingIssues.push(this._extractGrammarSpellingIssueTag(issue));
      });
    }

    if (i.referenceIssues) {
      let arrReferenceIssues = new Array().concat(i.referenceIssues);
      r.referenceIssues = [];
      arrReferenceIssues.forEach(issue => {
        r.referenceIssues.push(this._extractReferenceIssueTag(issue));
      });
    }

    // rule engine
    r.revRuleContentType = i.revRuleContentType;
    r.revRuleContentTypeDesc = i.revRuleContentTypeDesc;
    r.revRuleSimilarityEvent = i.revRuleSimilarityEvent;
    r.revRuleSimilarityEventDesc = i.revRuleSimilarityEventDesc;
    if (i.revRuleFindingEvents) {
      r.revRuleFindingEvents = i.revRuleFindingEvents.split(',');
    }
    if (i.revRuleActions) {
      r.revRuleActions = i.revRuleActions.split(',');
    }
    if (i.revRuleFindingCategoryDesc) {
      r.revRuleFindingCategoryDescs = i.revRuleFindingCategoryDesc.split(',').map(
        keyValue => keyValue.split('=')
      );
    }

    return r;
  }

  _extractGrammarSpellingIssueTag(issue: any): GrammarSpellingIssue {
    let result = new GrammarSpellingIssue();
    result.id = issue.issueId;
    result.type = issue.issueType;
    result.description = issue.ruleDescription;
    result.offset = issue.offset;
    result.length = issue.length;
    result.replacements = [];
    if (issue.replacements) {
      result.replacements = new Array().concat(issue.replacements);
    }
    return result;
  }

  _extractReferenceIssueTag(data: any): ReferenceIssue {
    let result = new ReferenceIssue();
    result.phraseId = data.phraseId;
    result.refPhraseId = data.refPhraseId;
    result.docId = data.docId;
    result.catPhraseId = data.catPhraseId;
    result.catRefPhraseId = data.catRefPhraseId;
    result.refPointerWordId = data.refPointerWordId ?? null;
    result.catRefPointerWordId = data.catRefPointerWordId ?? null;
    result.refFinding = data.refFinding;
    result.isPrimary = data.isPrimary;
    result.refChar = data.refChar;
    result.similarity = data.similarity;
    result.refDiffWords = data.refDiffWords;
    result.refDispWords = data.refDispWords;
    result.refText = data.refText;
    return result;
  }

  _setRuleCardMatch(rule: RevRule) {
    let match = this.ngRedux
      .getState()
      .revCardMatches.find((m) => m.cardMatchId == rule.cardMatchId);

    if (match.cardId) {
      let idx = this.ngRedux
        .getState()
        .revDocCards.findIndex((c) => c.cardId == match.cardId);
      if (idx != -1) match.card = this.ngRedux.getState().revDocCards[idx];
    }

    if (match.mCardId) {
      let idx = this.ngRedux
        .getState()
        .revDocMatchedCards.findIndex((c) => c.cardId == match.mCardId);
      if (idx != -1)
        match.mCard = this.ngRedux.getState().revDocMatchedCards[idx];
    }

    rule.cardMatch = match;
  }

  _extractRevCardMatchTag(tag: any): CardMatch {
    let match = new CardMatch();
    match.cardMatchId = tag.cardMatchId;
    match.mDocId = tag.catDocId;
    match.mCardId = tag.catCardId;
    match.cardId = tag.revCardId;
    match.mBoxId = tag.catBoxId;
    match.mPhraseId = tag.catPhraseId;
    match.matchStatusCode = tag.matchStatusCode;
    match.matchStatusDescr = tag.matchStatusDescr;
    match.boxId = tag.revBoxId;
    match.phraseId = tag.phraseId;
    return match;
  }

  updateCardAssetTemplate(data: CardAssetTemplate): Observable<boolean> {
    let response = new Observable<boolean>();
    let serviceName = 'update_card_asset_template_func';
    let params = new ServiceOptions();
    params.serviceName = serviceName;

    params['catVersionId'] = this.ngRedux.getState().curCat.catVersionId;
    if (data.id) params['id'] = data.id;
    params['name'] = data.name;
    params['descr'] = data.descr;
    params['assetType'] = Number.parseInt(data.assetType.id);
    params['cards'] = [];
    data.cards.forEach((e, idx) => {
      params['cards'].push({ cardId: e.cardId, sortOrder: idx });
    });

    let url = this.authService.getRequestUrl(serviceName);
    let body = this.authService.getRequestBody(params);
    let info = new ServiceCallInfo(serviceName, url, body);
    response = this.http
      .post(url, body, {
        headers: this.authService.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json',
      })
      .pipe(
        map((data) => this.authService.extractRequestStatus(info, data)),
        catchError((error) => {
          return this.authService.handleError(error, info);
        })
      );

    return response;
  }

  getCardAssetTemplates(
    shortMode: boolean,
    id: number = null
  ): Observable<CardAssetTemplate[]> {
    let serviceName = 'get_card_asset_templates_func';

    let response = new Observable<CardAssetTemplate[]>();
    let params = new ServiceOptions();
    params.serviceName = serviceName;

    params['envId'] = this.ngRedux.getState().curEnv.id;
    params['catVersionId'] = this.ngRedux.getState().curCat.catVersionId;
    params['shortMode'] = shortMode;
    if (id) params['id'] = id;

    let url = this.authService.getRequestUrl(serviceName);
    let body = this.authService.getRequestBody(params);
    let info = new ServiceCallInfo(serviceName, url, body);
    response = this.http
      .post(url, body, {
        headers: this.authService.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json',
      })
      .pipe(
        map((data) => this.extractCardAssetTemplates(data, info, id == null)),
        catchError((error) => {
          return this.authService.handleError(error, info);
        })
      );

    return response;
  }

  private extractCardAssetTemplates(
    res: any,
    info: ServiceCallInfo,
    dispatch: boolean
  ): CardAssetTemplate[] {
    this.authService.extractRequestStatus(info, res);
    let result: CardAssetTemplate[] = [];
    let data = res.reply.requestResult.data;

    if (data) {
      let dataTag = data.row;
      if (dataTag) {
        let arr = new Array().concat(dataTag);
        arr.forEach((i) => {
          let c = new CardAssetTemplate();
          c.id = i.id;
          c.name = i.name;
          c.descr = i.descr;
          c.assetType = new DataElement(i.assetType, i.assetTypeDescr);
          c.url = i.url;
          c.previewUrl = i.previewUrl;

          if (i.cards) {
            let arrCards = new Array().concat(i.cards);
            arrCards.forEach((card) => {
              c.cards.push(this._extractCardTag(card));
            });
          }

          result.push(c);
        });
      }
    }

    if (dispatch)
      this.ngRedux.dispatch({
        type: actionsList.GET_CARD_ASSET_TEMPLATES,
        data: result,
        reply: res,
      });

    return result;
  }

  createBlockTransRun(
    cardId: number,
    origLang: string,
    transLang: string
  ): Observable<number> {
    let serviceName = 'create_block_trans_run_func';

    let response = new Observable<number>();
    let params = new ServiceOptions();
    params.serviceName = serviceName;

    params['cardId'] = cardId;
    params['origLang'] = origLang;
    params['transLang'] = transLang;

    let url = this.authService.getRequestUrl(serviceName);
    let body = this.authService.getRequestBody(params);
    let info = new ServiceCallInfo(serviceName, url, body);
    response = this.http
      .post(url, body, {
        headers: this.authService.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json',
      })
      .pipe(
        map((res: any) => {
          this.authService.extractRequestStatus(info, res);
          let data = res.reply.requestResult.data;
          if (data) {
            return data.runNum;
          }
        }),
        catchError((err) => {
          return this.authService.handleError(err, info);
        })
      );
    return response;
  }

  getCardTrans(cardId: number, transLang: string): Observable<Card> {
    let serviceName = 'get_cat_cards_func';
    let response = new Observable<Card>();
    let params = new ServiceOptions();
    params.serviceName = serviceName;

    params['cardId'] = cardId;
    params['transLang'] = transLang;
    params['catVersionId'] = this.ngRedux.getState().curCat.catVersionId;

    let url = this.authService.getRequestUrl(serviceName);
    let body = this.authService.getRequestBody(params);
    let info = new ServiceCallInfo(serviceName, url, body);
    response = this.http
      .post(url, body, {
        headers: this.authService.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json',
      })
      .pipe(
        map((data) => this.extractCardTrans(data, info)),
        catchError((error) => {
          return this.authService.handleError(error, info);
        })
      );

    return response;
  }

  private extractCardTrans(res: any, info: ServiceCallInfo): Card {
    var arr = this.extractGetCards(res, false, info);
    return arr[0];
  }

  updCardTrans(card: Card, transLang: string): Observable<boolean> {
    let response = new Observable<boolean>();
    let serviceName = 'upd_card_trans_func';
    let params = new ServiceOptions();
    params.serviceName = serviceName;
    params.urldecodeSw = true;

    params['catVersionId'] = this.ngRedux.getState().curCat.catVersionId;
    params['cardId'] = card.cardId;
    params['transLang'] = transLang;

    params['doc'] = [];
    card.cardDocs.forEach((d) => {
      params['doc'].push({
        docId: d.docId,
        bb: d.boundingBoxes.map((b) => {
          return {
            boxId: b.boxId,
            phrases: b.indPhrases
              .filter((p) => p.dirtySw)
              .map((p) => {
                return {
                  phraseId: p.phraseId,
                  transTxt: p.transTxt,
                };
              }),
          };
        }),
      });
    });

    let url = this.authService.getRequestUrl(serviceName);
    let body = this.authService.getRequestBody(params);
    let info = new ServiceCallInfo(serviceName, url, body);
    response = this.http
      .post(url, body, {
        headers: this.authService.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json',
      })
      .pipe(
        map((data) => this.authService.extractRequestStatus(info, data)),
        catchError((error) => {
          return this.authService.handleError(error, info);
        })
      );

    return response;
  }

  updCatExtLib(
    catVersionId: number,
    extCatVersionId: number,
    deleteSw: boolean = false
  ): Observable<boolean> {
    let response = new Observable<boolean>();
    let serviceName = 'upd_cat_ext_lib_func';
    let params = new ServiceOptions();
    params.serviceName = serviceName;

    params['catVersionId'] = catVersionId;
    params['extCatVersionId'] = extCatVersionId;
    params['deleteSw'] = deleteSw;

    let url = this.authService.getRequestUrl(serviceName);
    let body = this.authService.getRequestBody(params);
    let info = new ServiceCallInfo(serviceName, url, body);
    response = this.http
      .post(url, body, {
        headers: this.authService.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json',
      })
      .pipe(
        map((data) => this.authService.extractRequestStatus(info, data)),
        catchError((error) => {
          return this.authService.handleError(error, info);
        })
      );

    return response;
  }

  getCatExtRefs(searchParams: CatExtRefSearchParams): Observable<ExtRef[]> {
    let serviceName = 'get_cat_ext_ref_func';

    let response = new Observable<ExtRef[]>();
    let params = new ServiceOptions();
    params.serviceName = serviceName;

    params['catVersionId'] = this.ngRedux.getState().curCat.catVersionId;
    if (searchParams.docId)
      params['docId'] = Number.parseInt(searchParams.docId as any);
    if (searchParams.extDocId)
      params['extDocId'] = Number.parseInt(searchParams.extDocId as any);
    if (searchParams.phraseText) params['phraseText'] = searchParams.phraseText;
    if (searchParams.extPhraseText)
      params['extPhraseText'] = searchParams.extPhraseText;

    let url = this.authService.getRequestUrl(serviceName);
    let body = this.authService.getRequestBody(params);
    let info = new ServiceCallInfo(serviceName, url, body);
    response = this.http
      .post(url, body, {
        headers: this.authService.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json',
      })
      .pipe(
        map((data) => this.extractCatExtRefs(data, info)),
        catchError((error) => {
          return this.authService.handleError(error, info);
        })
      );

    return response;
  }

  private extractCatExtRefs(res: any, info: ServiceCallInfo): ExtRef[] {
    this.authService.extractRequestStatus(info, res);
    let result: ExtRef[] = [];
    let data = res.reply.requestResult.data;

    if (data) {
      let dataTag = data.extRef;
      if (dataTag) {
        let arr = new Array().concat(dataTag);
        arr.forEach((i) => {
          let ref = this._extrectExtRefTag(i);
          result.push(ref);
        });
      }
    }

    this.ngRedux.dispatch({
      type: actionsList.GET_CAT_EXT_REFS,
      data: result,
      reply: res,
    });

    return result;
  }

  updExtRefPointer(
    extRef: ExtRef,
    deleteSw: boolean = false
  ): Observable<boolean> {
    let response = new Observable<boolean>();
    let serviceName = 'upd_ext_ref_pointer_func';
    let params = new ServiceOptions();
    params.serviceName = serviceName;

    params['extRefId'] = extRef.extRefId;
    params['docId'] = extRef.srcDocId;
    params['type'] = extRef.type;
    params['phraseId'] = extRef.srcPhraseId;
    params['extDocId'] = extRef.extDocId;
    params['extCatVersionId'] = extRef.extCatVersionId;
    params['extType'] = extRef.extType;
    params['extPhraseId'] = extRef.extPhraseId;
    params['deleteSw'] = deleteSw;

    let url = this.authService.getRequestUrl(serviceName);
    let body = this.authService.getRequestBody(params);
    let info = new ServiceCallInfo(serviceName, url, body);
    response = this.http
      .post(url, body, {
        headers: this.authService.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json',
      })
      .pipe(
        map((data) => this.authService.extractRequestStatus(info, data)),
        catchError((error) => {
          return this.authService.handleError(error, info);
        })
      );

    return response;
  }

  updPhraseOrder(docId: number, phrases: IndPhrase[]): Observable<boolean> {
    let response = new Observable<boolean>();
    let serviceName = 'upd_doc_phrase_order_func';
    let params = new ServiceOptions();
    params.serviceName = serviceName;
    params.urldecodeSw = true;

    params['docId'] = docId;

    params['order'] = [];
    phrases.forEach((d) => {
      params['order'].push({
        phraseId: d.phraseId,
        phraseOrder: d.phraseOrder,
      });
    });

    let url = this.authService.getRequestUrl(serviceName);
    let body = this.authService.getRequestBody(params);
    let info = new ServiceCallInfo(serviceName, url, body);
    response = this.http
      .post(url, body, {
        headers: this.authService.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json',
      })
      .pipe(
        map((data) => this.authService.extractRequestStatus(info, data)),
        catchError((error) => {
          return this.authService.handleError(error, info);
        })
      );

    return response;
  }

  getFigureTypeList(): Observable<DataElement[]> {
    let serviceName = 'get_figure_type_list_func';
    let response = new Observable<DataElement[]>();
    let params = new ServiceOptions();
    params.serviceName = serviceName;

    let url = this.authService.getRequestUrl(serviceName);
    let body = this.authService.getRequestBody(params);
    let info = new ServiceCallInfo(serviceName, url, body);

    response = this.http
      .post(url, body, {
        headers: this.authService.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json',
      })
      .pipe(
        take(1),
        map((data) => {
          return this.extractFigureTypes(data, info);
        }),
        catchError((err) => {
          return this.authService.handleError(err, info);
        })
      );
    return response;
  }

  private extractFigureTypes(res, info: ServiceCallInfo): DataElement[] {
    const results: DataElement[] = this.authService.mapToDataElement(
      res,
      'code',
      'descr',
      'types',
      info,
      'subTypes'
    );

    this.ngRedux.dispatch({
      type: actionsList.GET_FIGURE_TYPES,
      data: results,
      reply: res,
    });

    return results;
  }

  exportIndPhrasesExcel(catVersionId: number): Observable<{ runNum; s3Key }> {
    let response = new Observable<{ runNum; s3Key }>();
    let serviceName = 'ScaiPyLong.ExportExcel';
    let params = new ServiceOptions();
    params.serviceName = serviceName;

    params['catVersionId'] = catVersionId;
    params['userId'] = this.ngRedux.getState().user.userId;
    params['token'] = this.ngRedux.getState().user.token;

    let url = this.authService.getRequestUrl(serviceName, true);
    let body = this.authService.getRequestBody(params);
    let info = new ServiceCallInfo(serviceName, url, body);
    response = this.http
      .post(url, body, {
        headers: this.authService.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json',
      })
      .pipe(
        map((res: any) => {
          this.authService.extractRequestStatus(info, res);
          let data = res.reply.requestResult.data;
          if (data) {
            return { runNum: data.runNum, s3Key: data.s3Key };
          }
        }),
        catchError((error) => {
          return this.authService.handleError(error, info);
        })
      );

    return response;
  }

  importClaimsExcel(catVersionId: number, xlReportS3Key: string): Observable<boolean> {
    // DEV NOTE: any slash characters in xlReportS3Key must be reconstructed on server side
    const url = this.scaiServiceUrl + `catalogs/${catVersionId}/import_claims_excel/${xlReportS3Key.split('/').join('_SLSH_')}`;
    const submitData = {};

    return this.http
      .post(url, submitData, {
        headers: this.authService.getRequestHeaders(),
        withCredentials: false,
        observe: 'response',
        responseType: 'json'
      })
      .pipe(
        map((response) => {
          return response.status == 204;
        }),
        catchError((error) => {
          error.message = error.error.description || error.statusText;
          return this.authService.handleError(error, new ServiceCallInfo(url, url, null))
        })
      );
  }

  createCardExtractRun(
    cardId: number,
    catVersionId: number
  ): Observable<number> {
    let serviceName = 'create_indd_extract_card_run_func';

    let response = new Observable<number>();
    let params = new ServiceOptions();
    params.serviceName = serviceName;

    params['cardId'] = cardId;
    params['catVersionId'] = catVersionId;

    let url = this.authService.getRequestUrl(serviceName);
    let body = this.authService.getRequestBody(params);
    let info = new ServiceCallInfo(serviceName, url, body);
    response = this.http
      .post(url, body, {
        headers: this.authService.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json',
      })
      .pipe(
        map((res: any) => {
          this.authService.extractRequestStatus(info, res);
          let data = res.reply.requestResult.data;
          if (data) {
            return data.runNum;
          }
        }),
        catchError((err) => {
          return this.authService.handleError(err, info);
        })
      );
    return response;
  }

  createCardTemplateExtractRun(
    templateId: number,
    catVersionId: number
  ): Observable<number> {
    let serviceName = 'create_indd_extract_template_run_func';

    let response = new Observable<number>();
    let params = new ServiceOptions();
    params.serviceName = serviceName;

    params['cardAssetTemplateId'] = templateId;
    params['catVersionId'] = catVersionId;

    let url = this.authService.getRequestUrl(serviceName);
    let body = this.authService.getRequestBody(params);
    let info = new ServiceCallInfo(serviceName, url, body);
    response = this.http
      .post(url, body, {
        headers: this.authService.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json',
      })
      .pipe(
        map((res: any) => {
          this.authService.extractRequestStatus(info, res);
          let data = res.reply.requestResult.data;
          if (data) {
            return data.runNum;
          }
        }),
        catchError((err) => {
          return this.authService.handleError(err, info);
        })
      );
    return response;
  }

  checkFileExists(fileUrl: string): Observable<boolean> {
    let response = new Observable<boolean>();
    console.log('checkFileExists');
    response = this.http.head(fileUrl).pipe(
      map((res: any) => {
        return true;
      }),
      catchError((err) => {
        return of(false);
      })
    );
    return response;
  }

  getCatAltRef(searchParams: CatAltRefSearchParams): Observable<AltRef[]> {
    let serviceName = 'get_cat_alt_ref_func';

    let response = new Observable<AltRef[]>();
    let params = new ServiceOptions();
    params.serviceName = serviceName;

    params['catVersionId'] = this.ngRedux.getState().curCat.catVersionId;
    if (searchParams.searchId) params['searchId'] = searchParams.searchId;
    if (searchParams.rangeFrom) params['fromRec'] = searchParams.rangeFrom;
    if (searchParams.rangeTo) params['toRec'] = searchParams.rangeTo;

    let url = this.authService.getRequestUrl(serviceName);
    let body = this.authService.getRequestBody(params);
    let info = new ServiceCallInfo(serviceName, url, body);
    response = this.http
      .post(url, body, {
        headers: this.authService.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json',
      })
      .pipe(
        map((data) => this.extractCatAltRefs(data, info)),
        catchError((error) => {
          return this.authService.handleError(error, info);
        })
      );

    return response;
  }

  private extractCatAltRefs(res: any, info: ServiceCallInfo): AltRef[] {
    this.authService.extractRequestStatus(info, res);
    let result: AltRef[] = [];
    let data = res.reply.requestResult.data;
    let metaData = new ServiceMetaData();

    if (data.metadata) {
      let metaTag = data.metadata;
      metaData.totalRecords = metaTag.totRec;
      metaData.fromRecord = metaTag.fromRec;
      metaData.toRecord = metaTag.toRec;
      metaData.searchId = metaTag.searchId;
    }

    if (data) {
      let dataTag = data.ref;
      if (dataTag) {
        let arr = new Array().concat(dataTag);
        arr.forEach((i) => {
          let ref = new AltRef();
          ref.catRefAltId = i.catRefAltId;
          ref.txt = i.txt;
          if (i.phrases) {
            let arrPhrases = new Array().concat(i.phrases);
            arrPhrases.forEach((p) => {
              let phrase = new ReferencePhrase();
              phrase.refPhraseId = p.catPhraseId;
              phrase.refPhraseText = p.txt;
              if (p.dispwords) {
                let arrWords = new Array().concat(p.dispwords);
                arrWords.forEach((w) => {
                  phrase.phraseDispWords.push(this._extractPhraseWordTag(w));
                });
              }

              ref.phrases.push(phrase);
            });
          }
          result.push(ref);
        });
      }

      if (!metaData.searchId && result) {
        metaData.fromRecord = 1;
        metaData.toRecord = result.length;
        metaData.totalRecords = result.length;
      }
    }

    this.ngRedux.dispatch({
      type: actionsList.GET_CAT_ALT_REFS,
      data: result,
      reply: res,
      metaData: metaData,
    });

    return result;
  }

  updCatAltRef(data: AltRef, phrases: number[]): Observable<boolean> {
    let response = new Observable<boolean>();
    let serviceName = 'upd_cat_alt_ref_func';
    let params = new ServiceOptions();
    params.serviceName = serviceName;

    params['catVersionId'] = this.ngRedux.getState().curCat.catVersionId;

    if (data.catRefAltId) params['catRefAltId'] = data.catRefAltId;
    params['txt'] = data.txt;
    params['phrases'] = [];
    phrases.forEach((e) => {
      params['phrases'].push({ catPhraseId: e });
    });

    let url = this.authService.getRequestUrl(serviceName);
    let body = this.authService.getRequestBody(params);
    let info = new ServiceCallInfo(serviceName, url, body);
    response = this.http
      .post(url, body, {
        headers: this.authService.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json',
      })
      .pipe(
        map((data) => this.authService.extractRequestStatus(info, data)),
        catchError((error) => {
          return this.authService.handleError(error, info);
        })
      );

    return response;
  }

  renameCatDoc(docId: number, docName: string): Observable<boolean> {
    let url = `${this.scaiServiceUrl}cat-docs/${docId}`;
    return this.http
      .patch(url, { docName }, {
        headers: this.authService.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json',
      })
      .pipe(
        take(1),
        map((data: any) => {
          return true;
        }),
        catchError((err) => {
          return this.handleError(err, url);
        })
      );
  }

  private handleError(error: any, url: string): Observable<never> {
    const info = new ServiceCallInfo(url, url, null);
    error.message = error.error.description || error.statusText;

    return this.authService.handleError(error, info);
  }

  changeCatTitle(catId: number, catDescr: string): Observable<boolean> {
    let url = `${this.scaiServiceUrl}catalogs/${catId}`;
    return this.http
      .patch(url, { catDescr }, {
        headers: this.authService.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json',
      })
      .pipe(
        take(1),
        map((data: any) => {
          return true;
        }),
        catchError((err) => {
          return this.handleError(err, url);
        })
      );
  }
}
