<div class="container">
  <div *ngIf="displayPaging" class="l-align-center">
    <p class="cur-page" (click)="goToCurPage()">Current analyzed page:</p>
    <i
      class="fas fa-caret-left toolbar-icon"
      [class.disabled]="!enablePrev"
      (click)="prev()"
      [tooltip]="'translate.doc.prev' | translate"
    ></i>

    <select
      class="page-select"
      name="my-dropdown"
      [(ngModel)]="curPageNum"
      (ngModelChange)="onPageSelected()"
    >
      <option *ngFor="let page of pagesList" [ngValue]="page">
        {{ page }}
      </option>
    </select>

    <span>/ {{ totPages }}</span>

    <i
      class="fas fa-caret-right toolbar-icon"
      [class.disabled]="!enableNext"
      (click)="next()"
      [tooltip]="'translate.doc.next' | translate"
    ></i>

    <span class="separator"></span>
  </div>

  <!-- <i
    class="fas fa-search-minus toolbar-icon"
    (click)="zoomOut()"
    [tooltip]="'translate.doc.zoomOut' | translate"
  ></i>

  <i
    class="fas fa-search-plus toolbar-icon"
    (click)="zoomIn()"
    [tooltip]="'translate.doc.zoomIn' | translate"
  ></i>

  <i
    class="fas fa-arrows-alt-h toolbar-icon"
    (click)="fitToWidth()"
    [tooltip]="'translate.doc.fitWidth' | translate"
  ></i>

  <i
    class="fas fa-expand-arrows-alt toolbar-icon"
    (click)="fitPage()"
    [tooltip]="'translate.doc.fitPage' | translate"
  ></i>

  <span class="separator"></span> -->

  <i
    *ngIf="displayToggleAnnotations"
    class="fas toolbar-icon"
    [class.fa-eye]="!toggleAnnotationsSw"
    [class.fa-eye-slash]="toggleAnnotationsSw"
    (click)="toggleAnnotations()"
    [tooltip]="'translate.doc.toggleAnnotations' | translate"
  ></i>

  <span
    *ngIf="displayToggleAnnotations"
    class="fa-stack"
    (click)="toggleImageAnnotations()"
    [tooltip]="'translate.doc.toggleImageAnnotations' | translate"
  >
    <i class="far fa-image fa-stack-1x toolbar-icon"></i>
    <i
      *ngIf="toggleImageAnnotationsSw"
      class="fas fa-slash fa-stack-1x toolbar-icon"
    ></i>
  </span>

  <!-- <i
    class="fa-image toolbar-icon"
    [class.fas]="!toggleImageAnnotationsSw"
    [class.far]="toggleImageAnnotationsSw"
    (click)="toggleImageAnnotations()"
    [tooltip]="'translate.doc.toggleImageAnnotations' | translate"
  ></i> -->

  <span *ngIf="displayDrawMode" class="separator"></span>

  <i
    *ngIf="displayToggleBoundingBoxes"
    class="fas fa-vector-square toolbar-icon"
    [class.selected]="toggleBoundingBoxesSw"
    (click)="toggleBoundingBoxes()"
    [tooltip]="'translate.doc.toggleBoundingBoxes' | translate"
  ></i>

  <i
    *ngIf="displayDrawMode"
    class="fas fa-pencil-ruler toolbar-icon"
    [class.selected]="toggleDrawModeSw"
    (click)="toggleDrawMode()"
    [tooltip]="'translate.doc.toggleDrawMode' | translate"
  ></i>

  <p class="disp-page">Displayed page: {{ displayedPage }}</p>
</div>
