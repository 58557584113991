import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';  // Assuming this is used for authorization headers and error handling
import { Config } from 'src/app/config';  // Assuming configuration file for URLs
import { Observable } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { ServiceCallInfo } from '../models/common';  // Assuming a model for service call info
import { DataElement } from '../models/common';  // Assuming DataElement model for structured data

@Injectable({
  providedIn: 'root'
})
export class ReferenceMappingService {

  private scaiServiceUrl: string;

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) {
    
    this.scaiServiceUrl = Config.assetConfigData['scaiServiceUrl'];
  }


  getReferenceMapping(catId: number): Observable<DataElement[]> {
    const url = `${this.scaiServiceUrl}reference-mapping?catId=${catId}`;  

    return this.http
      .get(url, {
        headers: this.authService.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json'
      })
      .pipe(
        take(1),
        map((data: Array<any>) => {
          if (data && data.length) {
            return data
          }
          return [];
        }),
        catchError((err) => {
          return this.handleError(err, url);
        })
      );
  }


  private handleError(error: any, url: string): Observable<never> {
    const info = new ServiceCallInfo(url, url, null);
    const errorMessage = error.error?.description || error.statusText || 'An unknown error occurred';

    return this.authService.handleError({ ...error, message: errorMessage }, info);
  }
}
