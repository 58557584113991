import { Component, Input, ViewChild ,ElementRef} from '@angular/core';
import { trigger, transition, animate, style } from '@angular/animations'
import { SharedService } from '../../services/shared.service';

@Component({
  selector: 'slide-in-out',
  template : `
  
  <div #slideInOut class="slide-in-out">
    <div *ngIf="isOpen" [@slideInOut] class="slide-in-out-content">
      <ng-content></ng-content>
    </div>
  </div>
  `,
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({transform: 'translateY(0%)'} ),
        animate('0ms ease-in', style({transform: 'translateY(0%)'} ))
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({transform: 'translateY(0%)'} ))
      ])
    ])
  ]  
} )
export class SlideInOutComponent {

  constructor(
    private sharedService: SharedService
  ) {}
  @Input() isOpen = true;
  @ViewChild( 'slideInOut', { static: true } ) el : ElementRef;
  

  ngOnInit() {
  }

  ngOnChanges() {
    this.toggle();
  }

  toggle() {
    const el = this.el.nativeElement;
    if (this.isOpen) {
      this.open(el);
    } else {
      this.close(el);
    }
  }
  getHeight(el:HTMLElement):number{
    let height = 0;
    [].forEach.call(el.children,(child) => {
        height += child.offsetHeight + 
                  this.sharedService.computeStyle(child,'margin-top',true) +
                  this.sharedService.computeStyle(child,'margin-bottom',true) +
                  this.sharedService.computeStyle(child.lastElementChild,'margin-bottom',true);
    } );
    return height;
  }
  open(el:HTMLElement) {
    el.style.transition = 'height 200ms ease-out';
    el.style.height = '0px';
    el.style.overflow = 'hidden';
    setTimeout( () => {
      const height = this.getHeight(el);
      el.style.height = height + 'px';
      setTimeout( () => {
        el.style.height = '';
        el.style.overflow = '';
      },200)
    },50)

  }
  close(el:HTMLElement) {
    const height = this.getHeight(el);
    el.style.overflow = 'hidden';
    el.style.height = height + 'px';
    setTimeout( () => {
      el.style.height = '0px';
    },50);

  }

}
