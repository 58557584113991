import { Component, OnInit } from '@angular/core';
import { AppState, actionsList } from '../shared/redux/store';
import { NgRedux } from '@redux/redux-compatibility.service';
import {
  DataElement,
  PaginationParams,
  ProcessRunStatusEnum,
  Product,
} from '../shared/models/common';
import { RevService } from '../shared/services/rev.service';
import {
  DirectLinkDoc,
  RevDoc,
  RevDocsSearchParams,
} from '../shared/models/rev.model';
import { ServiceMetaData } from '../shared/models/service-meta.model';
import { keyCodes } from '../shared/models/key-codes.model';
import { ActivatedRoute, ActivationEnd, Router } from '@angular/router';
import { SharedService } from '../shared/services/shared.service';
import { Unsubscribable } from 'rxjs';
import { Unsubscribe } from 'redux';
import { AuthService } from '../shared/services/auth.service';

@Component({
  selector: 'app-rev-doc-list',
  templateUrl: './rev-doc-list.component.html',
  styleUrls: ['./rev-doc-list.component.scss'],
})
export class RevDocListComponent implements OnInit {
  loading: boolean;
  selectedDoc: RevDoc;
  rangeFrom: number;
  rangeTo: number;
  totalRecords: number;
  filterDocIdName: string;
  filterStatus: DataElement;
  filterProduct: DataElement;
  filterInactive: boolean;
  reduxSubscribe: Unsubscribe;
  displayAddRevDoc: boolean;
  createVersionParentDoc: RevDoc;
  searchParams: RevDocsSearchParams = {};
  getProcStatusFailedCnt: number = 0;
  processStatusTimers: NodeJS.Timeout[] = [];
  getProcessStatusIntervalSeconds: number = 5;
  displayProcessStatus: boolean;
  curDoc: RevDoc;
  docsBeingEdited: object = {};
  docsBeingUpdated: object = {};

  get docs(): RevDoc[] {
    return this.ngRedux.getState().revDocs;
  }

  get statusList(): DataElement[] {
    return this.ngRedux.getState().revDocStatusList;
  }

  get productList(): Product[] {
    return this.ngRedux.getState().productList;
  }

  get docsMetaData(): ServiceMetaData {
    const metaData: ServiceMetaData = this.ngRedux.getState().revDocsMetadata;
    return metaData || {};
  }

  get displayClearFilter(): boolean {
    return (
      this.filterDocIdName != null ||
      this.filterProduct != null ||
      this.filterStatus != null ||
      this.filterInactive
    );
  }

  get dlMode(): boolean {
    return (
      this.ngRedux.getState().dlRevDoc &&
      !this.ngRedux.getState().dlRevDoc.dlDocHandled
    );
  }

  constructor(
    private ngRedux: NgRedux<AppState>,
    private revService: RevService,
    private router: Router,
    private sharedService: SharedService,
    private authService: AuthService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    // reuse
    // this.router.events.subscribe((ev) => {
    //   if (
    //     this.ngRedux.getState().dlRevDoc &&
    //     this.ngRedux.getState().dlRevDoc.dlDocHandled &&
    //     ev instanceof ActivationEnd &&
    //     Object.is(ev?.snapshot?.component, RevDocListComponent)
    //   ) {
    //     this.getDocsHeader(true);
    //   }
    // });

    this.sharedService.putCookie('menu', 'rev', false);

    this.getDocsHeader(true);

    this.reduxSubscribe = this.ngRedux.subscribe(() => {
      const appState = this.ngRedux.getState();
      switch (appState._currentAction) {
        case actionsList.SELECT_ENV:
          this.getDocsHeader(true);
          break;
        case actionsList.USER_SIGN_OUT:
          this.processStatusTimers.forEach((t) => {
            clearInterval(t);
          });
          break;
      }
    });
  }

  ngOnDestroy(): void {
    this.processStatusTimers?.forEach((t) => {
      clearInterval(t);
    });
  }

  getDocsHeader(newSearch: boolean) {
    this.loading = true;

    if (newSearch) {
      this.searchParams.searchId = null;
      this.searchParams.rangeFrom = 1;
      this.searchParams.rangeTo = 10;
    }
    this.searchParams.docId = null;

    if (this.dlMode) {
      this.searchParams.docId = this.ngRedux.getState().dlRevDoc.docId;
      this.searchParams.rangeTo = 2;
    }
    this.searchParams.docIdName = this.filterDocIdName;
    this.searchParams.product = this.filterProduct;
    this.searchParams.includeInactiveSw = this.filterInactive;
    this.searchParams.statusCode = this.filterStatus
      ? this.filterStatus.id
      : null;

    this.processStatusTimers.forEach((t) => {
      clearInterval(t);
    });

    this.processStatusTimers = [];
    this.getProcStatusFailedCnt = 0;

    this.revService.getRevDocs(this.searchParams).subscribe(
      (data) => {
        this.loading = false;

        let processingDocs = this.docs.filter(
          (d) => d.isProcessRunning && d.runNum
        );
        if (processingDocs.length > 0) {
          this.handleProcessingDocs(processingDocs);
        }

        if (this.dlMode && data.length > 0 && !data[0].isProcessRunning) {
          this.viewDoc(data[0]);
        } else {
          this.ngRedux.dispatch({
            type: actionsList.SET_DL_REV_DOC,
            data: null,
          });
        }
      },
      (err) => (this.loading = false)
    );
  }

  reloadSingleDoc(docId: number) {
    let searchParams = new RevDocsSearchParams();
    searchParams.docId = docId;

    this.revService.getRevDocs(searchParams, false).subscribe((data) => {
      let curIdx = this.docs.findIndex((d) => d.docId == docId);
      if (curIdx !== -1) {
        this.docs[curIdx] = data[0];
      }
    });
  }

  handleProcessingDocs(docs: RevDoc[]) {
    docs.forEach((d) => {
      let timer = setInterval(() => {
        this.authService.getProcessStatus(d.runNum).subscribe(
          (data) => {
            d.processStatus = data;
            if (
              data.status == ProcessRunStatusEnum.FINISHED ||
              data.status == ProcessRunStatusEnum.FAILED
            ) {
              clearInterval(timer);
              this.reloadSingleDoc(d.docId);
              if (this.curDoc && this.curDoc?.docId == d.docId) {
                this.displayProcessStatus = false;
              }
            }
          },
          (err) => {
            this.getProcStatusFailedCnt++;
            if (this.getProcStatusFailedCnt > 20) {
              clearInterval(timer);
            }
          }
        );
      }, this.getProcessStatusIntervalSeconds * 1000);

      this.processStatusTimers.push(timer);
    });
  }

  onPagination(event: PaginationParams) {
    if (
      !this.docs ||
      (event.rangeFrom == this.searchParams.rangeFrom &&
        event.rangeTo == this.searchParams.rangeTo)
    )
      return;

    this.searchParams.rangeFrom = event.rangeFrom;
    this.searchParams.rangeTo = event.rangeTo;
    this.searchParams.searchId =
      this.ngRedux.getState().revDocsMetadata.searchId;

    this.getDocsHeader(false);
  }

  onFilterKeyPress(e: any) {
    if (e.keyCode == keyCodes.enter) {
      this.getDocsHeader(true);
    }
  }

  onFieldValueChanged(e: any) {
    if (e) (e.originalEvent as MouseEvent).stopPropagation();
    this.getDocsHeader(true);
  }

  clearFilter() {
    this.filterDocIdName = null;
    this.filterProduct = null;
    this.filterStatus = null;
    this.filterInactive = false;
    this.getDocsHeader(true);
  }

  viewDoc(doc: RevDoc) {
    let viewDoc;

    if (doc.docVersions.length > 0) {
      viewDoc = doc.docVersions.find((d) => d.versionId == doc.lastVersionId); //doc.docVersions[doc.docVersions.length - 1];
      viewDoc.newCatVersionSw = doc.newCatVersionSw;
    } else {
      viewDoc = doc;
    }

    this.ngRedux.dispatch({
      type: actionsList.SELECT_REV_DOC,
      data: viewDoc,
    });
    this.router.navigate([this.sharedService.routeEnum.URL_REV_DOC]);
  }

  onDocCreated(doc: RevDoc) {
    this.displayAddRevDoc = false;
    this.getDocsHeader(true);
  }

  openProcessStatus(doc: RevDoc) {
    this.displayProcessStatus = true;
    this.curDoc = doc;
  }

  onClickEditDocName(event: Event, doc: RevDoc) {
    event.stopPropagation();
    this.docsBeingEdited[doc.docId] = { docName: doc.docName }
  }

  onClickCancelEditDocName(event: Event, docId: number) {
    event.stopPropagation();
    delete this.docsBeingEdited[docId];
  }

  onClickUpdateDocName(event: Event, docId: number) {
    event.stopPropagation();

    this.docsBeingUpdated[docId] = this.docsBeingEdited[docId];

    const docName = this.docsBeingUpdated[docId].docName;

    this.revService.renameRevDoc(docId, docName).subscribe(
      _ => {
        delete this.docsBeingUpdated[docId];
        delete this.docsBeingEdited[docId];

        let doc = this.docs.find(d => d.docId === docId);
        doc.docName = docName;
        doc.docVersions.forEach(dv => dv.docName = docName);
      },
      err => {
        delete this.docsBeingUpdated[docId];
        delete this.docsBeingEdited[docId];

        this.sharedService.alertError(err);
      }
    );
  }
}
