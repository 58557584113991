<div class="ref-match-container">

  <!-- MATCH -->
  <ng-template [ngIf]="!referenceIssue?.refFinding">
    <div class="ref-match-data-container">
      <div class="text-container">
        <p class="text">
          {{ ref?.refPhraseText }}
        </p>
      </div>
    </div>
    <div class="ref-similarity-code">
      <p class="match">
        {{ "translate.rev.refMatch" | translate }}
      </p>
    </div>
  </ng-template>

  <!-- MISSING_REFERENCE -->
  <ng-template [ngIf]="referenceIssue?.refFinding === 'MISSING_REFERENCE'">
    <div class="ref-match-data-container">
      <div class="text-container ref-similarity">
        <p class="text missing">
          {{ ref?.refPhraseText }}
        </p>
      </div>
      <div class="ref-info">
        <span class="footnote id">
          <!-- {{ "translate.cat.phraseId" | translate }}: {{ ref.refPhraseId }} -->
        </span>
      </div>
    </div>
    <div class="ref-similarity-code">
      <p class="missing">
        {{ "translate.rev.refMissing" | translate }}
      </p>
    </div>
  </ng-template>

  <!-- TEXT_MISMATCH -->
  <ng-template [ngIf]="referenceIssue?.refFinding === 'TEXT_MISMATCH'">
    <div class="ref-diff-container" style="position: relative;">
      <!-- Diff container -->
      <p class="text">
        <span *ngIf="!showOriginal">
          <span *ngFor="let word of referenceIssue?.refDiffWords" class="match-diff"
            [class.missing]="word.diffType == 'MISSING'" [class.extra]="word.diffType == 'EXTRA'">
            {{ word.txt }}
          </span>
        </span>
        <span *ngIf="showOriginal">
          <div class="text-container">
            <p class="text">
              {{ ref?.refPhraseText }}
            </p>
          </div>
          <div class="ref-info">
            <span class="footnote id">
              {{ "translate.cat.phraseId" | translate }}: {{ ref.refPhraseId }}
            </span>
          </div>
        </span>
      </p>
    </div>
    <div *ngIf="!showOriginal">
      <button (click)="showOriginal = !showOriginal" class="eye-toggle" tooltip="{{ 'translate.rev.showOriginal' | translate }}">
        <i class="fas fa-eye"></i>
      </button>
    </div>
    <div *ngIf="showOriginal">
      <button (click)="showOriginal = !showOriginal" class="eye-toggle" tooltip="{{ 'translate.rev.showDiff' | translate }}">
        <i class="fas fa-eye-slash"></i>
      </button>
    </div>
  </ng-template>

  <!-- ADDED_REFERENCE -->
  <ng-template [ngIf]="referenceIssue?.refFinding === 'ADDED_REFERENCE'">
    <div class="ref-match-data-container">
      <div class="text-container ref-similarity">
        <p class="text extra">
          {{ referenceIssue?.refText }}
        </p>
      </div>
    </div>
    <div class="ref-similarity-code">
      <p class="extra">
        {{ "translate.rev.refAdded" | translate }}
      </p>
    </div>
  </ng-template>

</div>