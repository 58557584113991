<modal-popup
  [flexible]="true"
  [focusOnOpen]="false"
  [isOpen]="true"
  (onClose)="close()"
  [title]="'translate.cat.addToAltRef'"
>
  <div class="container">
    <!--descr-->
    <input-wrap
      label="{{ 'translate.general.descr' | translate }}"
      [required]="true"
      cssClass="l-cell-spread"
      class="t-row"
    >
      <input type="text" [(ngModel)]="data.txt" class="t-input" />
    </input-wrap>

    <ui-table
      *ngIf="data.phrases.length"
      class="t-small"
      [isWrapped]="false"
      [data]="data.phrases"
      [displayFilter]="['refPhraseId']"
      [templates]="[
        {
          title: 'translate.general.phraseText',
          template: refPhraseText
        },
        {
          title: 'translate.general.actions',
          template: actions
        }
      ]"
    ></ui-table>

    <ng-template #refPhraseText let-row="row">
      <div class="l-align-spaceBetween">
        <formatted-phrase
          *ngIf="row.phraseDispWords.length > 0"
          [phraseWords]="row.phraseDispWords"
        ></formatted-phrase>
      </div>
    </ng-template>

    <div class="actions">
      <button class="t-button-outline" (click)="close()">
        {{ "translate.general.cancel" | translate }}
      </button>
      &nbsp;
      <button
        class="t-button"
        [disabled]="!enableSubmit || saving"
        (click)="save()"
      >
        {{ "translate.general.ok" | translate }}
      </button>
    </div>
  </div>

  <loader *ngIf="saving"></loader>
</modal-popup>

<ng-template #actions let-row="row">
  <a (click)="deleteRefPhrase(row)">{{
    "translate.general.delete" | translate
  }}</a>
</ng-template>
