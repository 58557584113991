import { NgRedux } from '@redux/redux-compatibility.service';
import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { CatDoc, IndPhrase } from 'src/app/shared/models/cat.model';
import { LabelChange, LCDoc } from 'src/app/shared/models/lc.model';
import { AppState } from 'src/app/shared/redux/store';
import { CatService } from 'src/app/shared/services/cat.service';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'app-select-phrases',
  templateUrl: './select-phrases.component.html',
  styleUrls: ['./select-phrases.component.scss'],
})
export class SelectPhrasesComponent implements OnInit {
  type: 'ac' | 'bc';
  saving: boolean;

  get lc(): LabelChange {
    return this.ngRedux.getState().curLabelChange;
  }

  constructor(
    private ngRedux: NgRedux<AppState>,
    private router: Router,
    private sharedService: SharedService,
    private catService: CatService,
    private route: ActivatedRoute,
    private location: Location
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      this.type = params['type'];
      console.log('type: ' + this.type);
    });
  }

  onPhrasesSelected(phrases: IndPhrase[]) {
    let lc = Object.assign(new LabelChange(), this.lc);
    phrases.forEach((p) => {
      if (
        this.type == 'ac' &&
        lc.acPhrases.findIndex((x) => x.phraseId == p.phraseId) == -1
      ) {
        lc.acPhrases.push(p);
      } else if (
        this.type == 'bc' &&
        lc.bcPhrases.findIndex((x) => x.phraseId == p.phraseId) == -1
      ) {
        lc.bcPhrases.push(p);
      }
    });
    this.saving = true;
    this.catService.updateLabelChange(lc).subscribe(
      (data) => {
        this.saving = false;
        this.router.navigate(['main/lc/lc-phrases/' + this.type]);
      },
      (err) => (this.saving = false)
    );
  }

  goBack() {
    this.location.back();
  }
}
