import { NgRedux } from '@redux/redux-compatibility.service';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { TreeNode } from 'primeng/api';
import { AppState } from 'src/app/shared/redux/store';
import { CatService } from 'src/app/shared/services/cat.service';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'cat-phrase-bulk-edit',
  templateUrl: './cat-phrase-bulk-edit.component.html',
  styleUrls: ['./cat-phrase-bulk-edit.component.scss'],
})
export class CatPhraseBulkEditComponent implements OnInit {
  @Output() onClose = new EventEmitter();
  @Output() onSave = new EventEmitter<TreeNode>();

  selectedPhraseType: TreeNode;
  catPhraseTypesTree: TreeNode[];
  loading: boolean;

  constructor(
    private ngRedux: NgRedux<AppState>,
    private catService: CatService,
    private sharedService: SharedService
  ) {}

  ngOnInit(): void {
    this.catPhraseTypesTree =
      this.ngRedux.getState().catPhraseTypeModel.treeTypesForInput;
  }

  close() {
    this.onClose.emit();
  }

  save() {
    this.loading = true;
    this.onSave.emit(this.selectedPhraseType);
  }
}
