import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NgRedux } from '@redux/redux-compatibility.service';
import { AppState, actionsList } from '../shared/redux/store';
import { SharedService } from '../shared/services/shared.service';
import { AuthService } from '../shared/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UiUser } from '../shared/models/ui-user.model';
import { Env } from '../shared/models/user.model';
import { TranslateService } from '@ngx-translate/core';
import { DirectLinkDoc } from '../shared/models/rev.model';

const COGNITO_TEMP_PASSWORD_EXPIRED_MESSAGE = 'Temporary password has expired and must be reset by an administrator.';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  @ViewChild('loginIputPassword', { static: false })
  loginIputPassword: ElementRef;
  @ViewChild('userNameInput', { static: false }) userNameInput: ElementRef;
  rememberMe: boolean = false;
  typedUserName: string;
  username: string;
  password: string;
  showLogin: boolean = true;
  allowShowPassBtn: boolean = true;
  showSpinner: boolean = true;
  generalLoader: boolean = false;
  displaySelectEnv: boolean = false;
  dl: boolean = false;
  dlEnv: number;
  dlRevAnnSw: boolean;
  isShowRememberMe: boolean = false;

  constructor(
    private ngRedux: NgRedux<AppState>,
    private sharedService: SharedService,
    private authService: AuthService,
    private router: Router,
    private translateService: TranslateService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    console.log('>>>>>Route:', this.route);

    this.isShowRememberMe = this.sharedService.isShowRememberMeInLogin();

    this.route.queryParams.subscribe((params) => {
      if (params && params['dl'] && params['env'] && params['revdoc']) {
        this.dl = true;
        this.dlEnv = params['env'];
        this.dlRevAnnSw = params['dl'] == 'rev-ann';
        let dlRevdoc = new DirectLinkDoc();
        dlRevdoc.docId = Number.parseInt(params['revdoc']);
        dlRevdoc.page = Number.parseInt(params['page']) ?? 1;
        dlRevdoc.ruleId = Number.parseInt(params['rule']);
        dlRevdoc.cardId = Number.parseInt(params['card']);

        this.ngRedux.dispatch({
          type: actionsList.SET_DL_REV_DOC,
          data: dlRevdoc,
        });
      }

      this.handleLogin();
    });
  }

  handleLogin() {
    this.typedUserName = this.sharedService.getCookie('username');

    this.sharedService.generalLoader$.subscribe((data) => {
      this.generalLoader = data;
    });

    this.showSpinner = true;
    if (this.typedUserName || (!this.rememberMe && !this.authService.isAutoLogoutPeriodExpired())) {
      this.checkExistingSession();
    } else {
      this.showSpinner = false;
    }
  }

  removeInitialLoader() {
    const initialLoadingDiv = document.getElementById('initial_loading');
    initialLoadingDiv &&
      initialLoadingDiv.parentNode.removeChild(initialLoadingDiv);
    this.showSpinner = false;
    this.showLogin = true;
  }

  checkExistingSession() {
    this.showSpinner = true;
    this.showLogin = false;

    this.authService
      .retrieveUserSession(this.typedUserName)
      .then(
        (data) => {
          //valid session
          this.appLogin();
        },
        (reject) => {
          // no session
          this.removeInitialLoader();
        }
      )
      .catch((err) => {
        console.log(err);
        this.removeInitialLoader();
      });
  }

  handleLoginCookies() {
    if (this.rememberMe) {
      this.sharedService.putCookie('typedUserName', this.typedUserName);
      this.sharedService.putCookie('username', this.typedUserName);
    } else {
      this.sharedService.removeCookie('typedUserName');
      this.sharedService.removeCookie('username');
    }
  }

  login(e: any, updateAppUserPassword: boolean = false) {
    if (e) e.stopPropagation();

    this.sharedService.emitGeneralLoaderEvent(true);
    this.username = this.typedUserName.toUpperCase().trim();
    this.handleLoginCookies();

    if (!this.typedUserName || !this.password) {
      this.sharedService.alertWarning('translate.login.inputerror');
      return;
    }

    this.authService
      .loginUser(this.username.toUpperCase(), this.password)
      .then(
        (data) => {
          if (data.value === 'EMPTY_TOKEN') {
            this.showLogin = true;
          } else if (data.value === 'OK') {
            if(!this.rememberMe) {
              this.authService.resetUserInactivityMonitor();
            }
            if (updateAppUserPassword) {
              this.appLogin();
            } else {
              this.appLogin();
            }
          }
        },
        (reject) => {
          this.showLogin = true;

          if (reject.error === COGNITO_TEMP_PASSWORD_EXPIRED_MESSAGE) {
            this.sharedService.emitPopupchReceivedEvent({
              title: 'translate.login.loginFailedMsg',
              subTitle: 'translate.alert.wrongSubTitle',
              icon: 'exclamation-triangle',
              htmlMessage: this.translateService.instant('translate.login.tempPasswordExpired')
            });
          } else {
            this.sharedService.alertError(
              reject.error,
              'translate.login.loginFailedMsg'
            );
          }
        }
      )
      .catch((err) => {
        this.showLogin = true;
        console.log(err);
        this.sharedService.alertError(err);
      });
  }

  appLogin() {
    this.authService.appSignIn(this.password).subscribe(
      () => {
        this.getUserDetails();
      },
      (err) => {
        this.handleLoginFailed();
      }
    );
  }

  getUserDetails() {
    this.authService.getUserDetails().subscribe(
      (res) => {
        if (res) {
          this.authService.getEnvList().subscribe(
            (data) => {
              let curEnv: Env;
              let savedEnvId;
              let cookieEnv = this.sharedService.getCookie('env', false);

              if (this.dlEnv) {
                savedEnvId = this.dlEnv;
              } else if (cookieEnv != '') {
                savedEnvId = cookieEnv;
              } else {
                savedEnvId = this.authService.currentUser.curEnvId;
              }

              if (savedEnvId) {
                curEnv = data.find((e) => e.id == savedEnvId);
                if (!curEnv && data.length == 1) {
                  curEnv = data[0];
                  this.dl = false;
                }
              }

              if (curEnv) {
                this.ngRedux.dispatch({
                  type: actionsList.SELECT_ENV,
                  data: curEnv,
                });

                this.sharedService.putCookie(
                  'env',
                  curEnv.id.toString(),
                  false
                );

                this.handleInitialNav();
              } else {
                this.displaySelectEnv = true;
              }
            },
            (err) => {
              this.showSpinner = false;
              this.showLogin = true;
            }
          );
        } else {
          this.handleLoginFailed();
        }
      },
      (err) => {
        this.handleLoginFailed();
      }
    );
  }

  private handleInitialNav() {
    if (this.dlRevAnnSw) {
      this.router.navigate(['main/dl-rev-ann']);
    } else if (this.ngRedux.getState().dlRevDoc) {
      this._navigateToRev();
    } else {
      let cookieMenu = this.sharedService.getCookie('menu', false);
      console.log('cookie menu: ' + cookieMenu);
      if (cookieMenu == '' || cookieMenu == 'rev') {
        this._navigateToRev();
      } else if (cookieMenu == 'cat') {
        let cookieCat = this.sharedService.getCookie('cat_id', false);
        console.log('cookie cat: ' + cookieCat);

        this.ngRedux.dispatch({
          type: actionsList.UI__ROUTE_NAVIGATION,
          data: this.sharedService.routeEnum.URL_CATALOG_LIST,
        });

        this.ngRedux.dispatch({
          type: actionsList.UI__SIDEBAR_ROUTE_NAVIGATION,
          data: this.translateService.instant('translate.sidebar.catalog'),
        });

        if (cookieCat != '') {
          this.router.navigate([
            this.sharedService.routeEnum.URL_CATALOG_LIST,
            cookieCat,
          ]);
        } else {
          this.router.navigate([this.sharedService.routeEnum.URL_CATALOG_LIST]);
        }
      } else {
        if (cookieMenu.toString().startsWith('main/mng/')) {
          this.ngRedux.dispatch({
            type: actionsList.UI__SIDEBAR_ROUTE_NAVIGATION,
            data: this.translateService.instant('translate.sidebar.manage'),
          });
        }
        this.router.navigate([cookieMenu]);
      }
    }
  }

  private _navigateToRev() {
    this.ngRedux.dispatch({
      type: actionsList.UI__ROUTE_NAVIGATION,
      data: this.sharedService.routeEnum.URL_REV_DOC_LIST,
    });

    this.ngRedux.dispatch({
      type: actionsList.UI__SIDEBAR_ROUTE_NAVIGATION,
      data: this.translateService.instant('translate.sidebar.review'),
    });

    this.router.navigate([this.sharedService.routeEnum.URL_REV_DOC_LIST]);
  }

  handleLoginFailed() {
    this.sharedService.alertError('translate.login.loginFailedMsg');
    this.showSpinner = false;
    this.showLogin = true;
  }

  forgotPassword() {
    this.username = this.typedUserName.toUpperCase();
    if (!this.username) {
      this.sharedService.alertWarning('translate.alert.fillUserName');
      return;
    }

    this.authService
      .forgotPassword(this.username)
      .then(
        (data) => {
          this.sharedService.emitPopupchReceivedEvent({
            subTitle: 'translate.alert.passwordChangeSuccessfully',
            icon: 'check',
          });

          this.password = this.sharedService.capturedUserPassword;
          this.login(null, true);
        },
        (reject) => {
          this.sharedService.alertError(reject.error);
        }
      )
      .catch((err) => {
        console.log(err);
      });
  }

  passwordInputType: 'text' | 'password';
  showPassword($event: MouseEvent) {
    $event.preventDefault();
    const passwordInput = this.loginIputPassword.nativeElement;
    passwordInput.type =
      passwordInput.type === 'password' ? 'text' : 'password';
    this.passwordInputType = passwordInput.type;
  }

  onSelectEnv() {
    this.displaySelectEnv = false;
    this.router.navigate([this.sharedService.routeEnum.URL_REV_DOC_LIST]);
  }
}
