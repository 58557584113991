import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  TemplateRef,
  ViewChildren,
  QueryList,
} from '@angular/core';
import { DataElement } from '../../models/common';
import { UiService } from '../../services/ui.service';
import { InputWrapComponent } from './input-wrap.component';

@Component({
  selector: 'data-box',
  template: `
    <section *ngIf="data" class="t-dataBox" [ngClass]="classSet">
      <h2 *ngIf="title" class="t-widgetTitle">
        {{ title | translate }}
      </h2>
      <h2 *ngIf="!title && showEmptyTitle" class="t-widgetTitle">&nbsp;</h2>

      <div class="l-flex t-row--small">
        <ng-container *ngIf="dataImage">
          <figure
            class="t-dataBox-figure l-align-center l-align-y"
            [class.t-pointer]="onImgClicked"
            (click)="imgClickedWrapper($event)"
          >
            <img
              *ngIf="isImage()"
              #dataImageHTML
              [attr.src]="dataImage"
              alt="{{ imageAlt }}"
            />
            <div *ngIf="!isImage()">
              <a
                ><i class="far c-grey fa-file-pdf" style="font-size: 3em"></i
              ></a>
            </div>
          </figure>

          <div class="t-space--med"></div>
        </ng-container>

        <ng-container *ngIf="beforeData">
          <div class="t-dataBox-figure">
            <ng-container *ngTemplateOutlet="beforeData"></ng-container>
          </div>
          <div class="t-space--med"></div>
        </ng-container>

        <dl
          [ngStyle]="{ 'column-count': columns }"
          class="t-columns t-panel-small l-cell-spread-x"
        >
          <ng-container *ngFor="let key of getKeys(key)">
            <ng-container
              *ngTemplateOutlet="keyVal; context: { key: key }"
            ></ng-container>
          </ng-container>
        </dl>

        <ng-container *ngIf="afterData">
          <div class="t-space--med"></div>
          <div class="t-dataBox-figure">
            <ng-container *ngTemplateOutlet="afterData"></ng-container>
          </div>
        </ng-container>
      </div>

      <ng-container *ngTemplateOutlet="belowData"></ng-container>
    </section>

    <ng-template #keyVal let-key="key">
      <div class="t-panel-cell-small t-key-value-pair {{ key }}">
        <div class="t-key-value-pair-box">
          <div class="l-align-spaceBetween">
            <dt *ngIf="!titles[key]">
              {{ 'translate.general.' + key | translate }}
            </dt>

            <dt *ngIf="titles[key]">{{ titles[key] | translate }}</dt>

            <dd *ngIf="!templateRefs[key]">
              {{ data[key] }}
            </dd>

            <ng-container *ngIf="templateRefs[key]">
              <ng-container
                *ngTemplateOutlet="templateRefs[key]"
              ></ng-container>
            </ng-container>
          </div>
        </div>
      </div>
    </ng-template>
  `,
})
export class DataBoxComponent implements OnInit {
  constructor(private uiService: UiService) {}

  data: Object;
  dataImage: string;
  imageLoading: boolean = false;
  @Output() onActionBtn = new EventEmitter<DataElement>();
  @Output() onImgClicked = new EventEmitter<MouseEvent>();
  @Input() image: string;
  @Input() imageFull: string;
  @ViewChildren('dataImageHTML ') dataImageHTML: QueryList<any>;
  @Input('data') set dt(data: Object) {
    if (!data) return;
    this.data = data;
    if (!this.imageFull) {
      this.dataImage = data[this.image];
    }
  }
  @Input() columns: number = 1;
  @Input() imageAlt: string = '';
  @Input() title: string;
  @Input() cssClass: string;
  @Input() displayFilter: string[];
  @Input() excludeFilter: string[];
  @Input() templateRefs: Object = {};
  @Input() titles: Object = {};
  @Input() belowData: TemplateRef<any>;
  @Input() afterData: TemplateRef<any>;
  @Input() beforeData: TemplateRef<any>;
  @Input() isWrapped: boolean = true;
  @Input() styleClass: string;
  @Input() showEmptyTitle: boolean;
  classSet: Object;

  ngOnInit() {
    if (this.imageFull) this.dataImage = this.imageFull;
    this.classSet = {
      'h-full t-widget': this.isWrapped,
      [this.styleClass]: this.styleClass,
    };
  }

  getKeys(index: number = 0): string[] {
    if (!this.displayFilter && !this.excludeFilter) {
      return [];
    }

    let keysArray = Object.keys(this.data).filter((key) => {
      let validKey: boolean = true; //
      //  'string,number,date'.indexOf(typeof this.data[key]) !== -1;
      let filterBy: boolean = true;
      if (this.displayFilter) {
        filterBy = this.displayFilter.indexOf(key) !== -1;
      } else if (this.excludeFilter) {
        filterBy = this.excludeFilter.indexOf(key) === -1;
      }
      return validKey && filterBy;
    });

    keysArray.sort(this.sortByDisplayFilter.bind(this));

    return keysArray;
  }

  sortByDisplayFilter(a: string, b: string) {
    if (!this.displayFilter) return 0;
    let aI: number = this.displayFilter.findIndex((i) => i === a);
    let bI: number = this.displayFilter.findIndex((i) => i === b);

    if (aI < bI) {
      return -1;
    } else if (aI > bI) {
      return 1;
    } else {
      return 0;
    }
  }

  imgClickedWrapper(event) {
    if (this.onImgClicked) {
      this.onImgClicked.emit(event);
    }
  }

  isImage(): boolean {
    if (this.dataImage) return this.uiService.isImage(this.dataImage);
    else return false;
  }
}
