import { NgRedux } from '@redux/redux-compatibility.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
  ProcessRunStatusEnum,
  ProcessRunStatusResult,
} from 'src/app/shared/models/common';
import {
  CombinedCard,
  LabelChange,
  LCCardTypeEnum,
  LCSearchParams,
  LCStats,
} from 'src/app/shared/models/lc.model';
import { TabIdList } from 'src/app/shared/models/tab.model';
import { actionsList, AppState } from 'src/app/shared/redux/store';
import { AuthService } from 'src/app/shared/services/auth.service';
import { CatService } from 'src/app/shared/services/cat.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import * as Chart from 'chart.js';
import { Unsubscribe } from 'redux';

@Component({
  selector: 'app-lc-docs',
  templateUrl: './lc-docs.component.html',
  styleUrls: ['./lc-docs.component.scss'],
})
export class LcDocsComponent implements OnInit {
  type: 'ac' | 'bc';
  running: boolean;
  processStatusTimer: NodeJS.Timeout;
  getProcessStatusIntervalSeconds: number = 3;
  displayProcessStatus: boolean;
  tabIdList: TabIdList;
  loading: boolean;
  canvasStatus: any;
  ctxStatus: any;

  get lc(): LabelChange {
    return this.ngRedux.getState().curLabelChange;
  }

  get lcStats(): LCStats {
    return this.type == 'ac' ? this.lc.acStats : this.lc.bcStats;
  }

  get processRunStatus(): ProcessRunStatusResult {
    return this.type == 'ac' ? this.lc.acProcess : this.lc.bcProcess;
  }

  get docsProcessing(): boolean {
    let docs = this.type == 'ac' ? this.lc.acDocs : this.lc.bcDocs;
    return docs.some((d) => d.isProcessRunning);
  }

  constructor(
    private ngRedux: NgRedux<AppState>,
    private catService: CatService,
    private router: Router,
    private sharedService: SharedService,
    private authService: AuthService,
    private translateService: TranslateService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      this.type = params['type'];
      console.log('type: ' + this.type);
      this.handleProcesses();
    });
  }

  ngAfterViewInit(): void {
    this.route.params.subscribe((params: Params) => {
      this.type = params['type'];
      this.drawStatusChart();
    });
  }

  drawStatusChart() {
    this.canvasStatus = document.getElementById(
      'chart-status-cnv-' + this.type
    );
    if (!this.canvasStatus) return;

    this.ctxStatus = this.canvasStatus.getContext('2d');

    let myChart = new Chart(this.ctxStatus, {
      type: 'doughnut',
      data: {
        datasets: [
          {
            data: [
              this.lcStats.lcInvalidationFoundCnt,
              this.lcStats.lcValidatedCnt,
              this.lcStats.lcMissingCnt,
            ],
            backgroundColor: ['#f35f3e', '#62bd60', '#dbab23'],
          },
        ],
        labels: [
          this.translateService.instant(
            'translate.general.lcInvalidationFoundCnt'
          ),
          this.translateService.instant('translate.general.lcValidatedCnt'),
          this.translateService.instant('translate.general.lcMissingCnt'),
        ],
      },
      options: {
        tooltips: {
          enabled: true,
          callbacks: {
            label: (tooltipItems, data) => {
              return (
                data.labels[tooltipItems.index] +
                ' ' +
                data.datasets[0].data[tooltipItems.index]
              );
            },
          },
        },
        cutoutPercentage: 60,
        legend: {
          display: false,
        },
        // elements: {
        //   center: {
        //     text: Math.round(this.doc.progressPct * 100) + '%',
        //     color: '#0978A2',
        //     sidePadding: 40,
        //   },
        // },
      },
    });
  }

  handleProcesses() {
    if (this.processRunStatus && this.processRunStatus.isProcessRunning) {
      this.running = true;
      this.pullProcessStatus(this.processRunStatus.runNum);
    }
  }

  ngOnDestroy(): void {
    if (this.processStatusTimer) clearInterval(this.processStatusTimer);
  }

  addDocuments() {
    this.ngRedux.dispatch({
      type: actionsList.SELECT_CATALOG,
      data: this.lc.catalog,
    });
    this.router.navigate([
      this.sharedService.routeEnum.URL_LC_SELECT_DOCS,
      this.type,
    ]);
  }

  pullProcessStatus(runNum: number) {
    this.processStatusTimer = setInterval(() => {
      this.authService.getProcessStatus(runNum).subscribe(
        (data) => {
          if (data.status == ProcessRunStatusEnum.FINISHED) {
            clearInterval(this.processStatusTimer);
            this.running = false;
            this.reloadCurLC();
          } else if (data.status == ProcessRunStatusEnum.FAILED) {
            this.running = false;
            this.processError(
              this.translateService.instant('translate.alert.processFailed')
            );
          }
        },
        (err) => {
          this.processError(err);
        }
      );
    }, this.getProcessStatusIntervalSeconds * 1000);
  }

  reloadCurLC() {
    this.ngRedux.dispatch({
      type: actionsList.RELOAD_CUR_LC_REQ,
    });
    this.handleProcesses();
  }

  processError(err) {
    clearInterval(this.processStatusTimer);
    this.running = false;
    this.sharedService.alertError(err);
  }

  process() {
    this.running = true;
    this.catService
      .createLCRun(
        this.lc.lcId,
        this.type == 'ac' ? 'LABEL_CHANGE_AC' : 'LABEL_CHANGE_BC'
      )
      .subscribe(
        (runNum) => {
          this.catService.startProcessRun(runNum).subscribe(
            (data) => {
              this.reloadCurLC();
              this.pullProcessStatus(runNum);
            },
            (err) => {
              this.processError(err);
            }
          );
        },
        (err) => {
          this.processError(err);
        }
      );
  }
}
