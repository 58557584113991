import { Card } from './cat.model';
import {
  PaginationParams,
  DataElement,
  ReferencePhrase,
  AddInstr,
  Doc,
  PhraseWord,
  CatMatch,
  ProcessRunStatusEnum,
  CardEntryMatch,
  BoundingBoxMatch,
  AnnotationElement,
  GrammarSpellingIssue,
  ReferenceIssue,
} from './common';

export class RevDoc extends Doc {
  lastVersionId: number;
  createDate: Date;
  createDateFormatted: string;
  productId: any;
  productDescr: string;
  catId: number;
  catDescr: string;
  catVersionId: number;
  newCatVersionSw: boolean;
  runStatusCode: string;
  runStatusMessage: string;
  passedCnt: number;
  failedCnt: number;
  dismissedCnt: number;
  newClaimCnt: number;
  claimVariantCnt: number;
  markedCnt: number;
  stetCnt: number;
  notPassedVarCnt: number;
  newContentCnt: number;
  versionId: number;
  progressPct: number;
  totCnt: number;
  statusCode: string;
  statusDescr: string;
  statusDate: string;
  fileName: string;
  docType: string;
  parentDocId: number;
  actCatVersionId: number;
  uploadUserDescr: string;
  assetType: DataElement;
  origDocId: number;

  docVersions: RevDoc[] = [];

  get isProcessRunning(): boolean {
    return (
      this.runStatusCode == ProcessRunStatusEnum.RUNNING ||
      this.runStatusCode == ProcessRunStatusEnum.PENDING
    );
  }

  constructor() {
    super();
    this.docObjectType = 'REV';
  }
}

export class RevDocsSearchParams extends PaginationParams {
  envId?: number;
  docId?: number;
  docName?: string;
  docIdName?: string;
  statusCode?: string;
  product?: DataElement;
  includeInactiveSw?: boolean;

  constructor() {
    super();
  }
}

export class RevRule extends AnnotationElement {
  revRuleId: number;
  phraseId: number;
  revRuleType: string;
  revRuleTypeDescr: string;
  ruleDescr: string;
  pageNum: number;
  revStatusCode: string;
  revStatusDescr: string;
  ruleUserStatus: DataElement;
  similarityCode: string;
  similarityDescr: string;
  similarityPct: number;
  isRefSw: boolean;
  references: ReferencePhrase[];
  referencedBy: ReferencePhrase[];
  addInstr: AddInstr[];
  ruleDispWords: PhraseWord[] = [];
  grammarSpellingIssues: GrammarSpellingIssue[];
  referenceIssues: ReferenceIssue[];
  formatMatchEntry: PhraseWord[] = [];
  formatMatchPhrase: PhraseWord[] = [];
  checkedSw: boolean;
  viewSw: boolean;
  ruleDiffParts: PhraseWord[] = [];
  ruleCatMatches: CatMatch[] = [];
  annElPassed: string;
  annElFailed: string;
  annElDismissed: string;
  annElMarkedForChanges: string;
  annElStet: string;
  annElNotPassedVar: string;
  comment: string;
  cardEntryMatch: CardEntryMatch;
  boundingBoxMatch: BoundingBoxMatch;
  imageId: number;
  imgUrl: string;
  cardMatch: CardMatch;
  cardMatchId: number;
  figureType: string;
  figureTypeDescr: string;
  figureSubType?: DataElement;
  revRuleContentType: string;
  revRuleContentTypeDesc: string;
  revRuleSimilarityEvent: string;
  revRuleSimilarityEventDesc: string;
  revRuleFindingEvents: string[] = [];
  revRuleActions: string[] = [];
  revRuleFindingCategoryDescs: [string, string][] = [];
  variantOrigPhraseId?: number;

  original: string;

  get annIdPrefix() {
    return this.imageId !== null ? "img" : null;
  }

  saveOriginal() {
    this.original = JSON.stringify(this);
  }

  restoreOriginal() {
    let original = JSON.parse(this.original) as RevRule;
    this.comment = original.comment;
  }

  get isImage(): boolean {
    return this.imageId !== null;
  }
}

export class CardMatch {
  cardMatchId: number;
  mCardId: number;
  mDocId: number;
  mBoxId: number;
  mPhraseId: number;
  mCard: Card;
  matchStatusCode: string;
  matchStatusDescr: string;
  cardId: number;
  boxId: number;
  card: Card;
  phraseId: number;
}

export class RevRulesSearchParams {
  phrase: string;
  ruleType: DataElement;
  ruleStatus: DataElement;
  contentType: DataElement;
  matchType: DataElement;
  subTypeList: string[] = [];
}

export class RevCatalog {
  catId: number;
  catDescr: string;
  lastVersionId: number;
  catVersionId: number;
  catAssetTypeList: DataElement[] = [];
  catType: string;
  catTypeDescr: string;
}

export class DirectLinkDoc {
  docId: number;
  page: number;
  ruleId: number;
  cardId: number;
  dlDocHandled: boolean;
  dlPageHandled: boolean;
  dlRuleHandled: boolean;
  dlCardHandled: boolean;
}

/** ENUMS **/

export class RevDocStatusEnum {
  public static UNDERWAY: string = 'UNDERWAY';
  public static FINAL: string = 'FINAL';
  public static COMPLETED: string = 'COMPLETED';
  public static INACTIVE: string = 'INACTIVE';
}

export class RevStatus {
  public static MARKED_FOR_CHANGES: string = 'MARKED_FOR_CHANGES';
  public static DISMISSED: string = 'DISMISSED';
  public static NEW_CLAIM: string = 'NEW_CLAIM';
  public static CLAIM_VARIANT: string = 'CLAIM_VARIANT';
  public static PASSED: string = 'PASSED';
  public static NOT_PASSED: string = 'NOT_PASSED';
  public static STET: string = 'STET';
  public static NOT_PASSED_VAR: string = 'NOT_PASSED_VAR';
  public static NEW_CONTENT: string = 'NOT_PASSED_NEW_CONTENT';
}
