<div class="box-container">
  <div class="header-container">
    <div class="thumbnail">
      <async-img
        *ngIf="box.imgUrl"
        [url]="box.imgUrl"
        (click)="viewBox(true)"
      ></async-img>
    </div>
  </div>

  <div class="box-phrases" [class.has-content]="box.indPhrases">
    <div *ngFor="let phrase of box.indPhrases" class="phrase">
      <p>{{ phrase.phraseText }}</p>
    </div>
  </div>

  <div class="page footnote">
    <span
      >{{ "translate.general.page" | translate }}:
      {{ box.displayPageNum }}</span
    >
    /
    <span>{{ "translate.general.bbId" | translate }}: {{ box.boxId }}</span>
  </div>
</div>

<modal-popup
  *ngIf="displayDocViewer"
  [flexible]="false"
  [hideHeader]="false"
  [title]="doc.docName"
  [isOpen]="displayDocViewer"
  (onClose)="displayDocViewer = false"
>
  <div *ngIf="!docLoading" class="pdf-container">
    <pdf-viewer
      #docSinglePhraseViewer
      [doc]="doc"
      [initialPage]="box.displayPageNum"
      [displayDrawMode]="false"
      [displayToggleBoundingBoxes]="false"
      [displayToggleAnnotations]="false"
      [displayPaging]="false"
      [singlePage]="singlePageSw"
      (onIframeLoaded)="onDocIframeLoaded()"
    ></pdf-viewer>
  </div>
</modal-popup>
