<div class="filter-bar l-align-end">
  <button class="t-button-outline" (click)="addCards()">
    {{ "translate.lc.addBlocks" | translate }}
  </button>
</div>

<div *ngFor="let cc of combinedCards">
  <div *ngFor="let card of cc.cards">
    <card-summary [curCard]="card" [lcSw]="true"></card-summary>
  </div>
</div>
