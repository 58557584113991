import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { bool } from 'aws-sdk/clients/signer';
import { Doc } from 'src/app/shared/models/common';

@Component({
  selector: 'doc-toolbar',
  templateUrl: './doc-toolbar.component.html',
  styleUrls: ['./doc-toolbar.component.scss'],
})
export class DocToolbarComponent implements OnInit {
  @Input() doc: Doc;
  @Input() initialPage: number;
  @Input() displayPaging: boolean = true;
  @Input() displayDrawMode: boolean;
  @Input() displayToggleBoundingBoxes: boolean;
  @Input() displayRefreshingIcon: boolean;
  @Input() displayToggleAnnotations: boolean = true;
  @Input() displayedPage: number;

  @Output() onPageChanged = new EventEmitter<number>();
  @Output() onZoomIn = new EventEmitter();
  @Output() onZoomOut = new EventEmitter();
  @Output() onFitToWidth = new EventEmitter();
  @Output() onFitToPage = new EventEmitter();
  @Output() onToggleAnnotations = new EventEmitter<boolean>();
  @Output() onToggleDrawMode = new EventEmitter<boolean>();
  @Output() onToggleBoundingBoxes = new EventEmitter<boolean>();
  @Output() onToggleImageAnnotations = new EventEmitter<boolean>();
  @Output() onGoToCurPage = new EventEmitter<number>();

  pagesList: number[] = [];
  totPages: number;
  curPageNum: number;
  toggleAnnotationsSw: boolean;
  toggleImageAnnotationsSw: boolean;
  toggleDrawModeSw: boolean;
  toggleBoundingBoxesSw: boolean;

  get enablePrev(): boolean {
    return this.curPageNum > 1;
  }

  get enableNext(): boolean {
    return this.curPageNum < this.totPages;
  }

  constructor() {}

  ngOnInit(): void {
    this.curPageNum = this.initialPage;
    this.totPages = this.doc.pagesNum;
    this.pagesList = [];
    for (let i = 1; i <= this.totPages; i++) {
      this.pagesList.push(i);
    }
  }

  prev() {
    if (this.enablePrev) {
      this.curPageNum--;
      this.onPageSelected();
    }
  }

  next() {
    if (this.enableNext) {
      this.curPageNum++;
      this.onPageSelected();
    }
  }

  onPageSelected() {
    this.onPageChanged.emit(this.curPageNum);
    this.toggleAnnotationsSw = false;
  }

  zoomOut() {
    this.onZoomOut.emit();
  }

  zoomIn() {
    this.onZoomIn.emit();
  }

  fitToWidth() {
    this.onFitToWidth.emit();
  }

  fitPage() {
    this.onFitToPage.emit();
  }

  toggleAnnotations() {
    this.toggleAnnotationsSw = !this.toggleAnnotationsSw;
    this.onToggleAnnotations.emit(this.toggleAnnotationsSw);
  }

  toggleDrawMode() {
    this.toggleDrawModeSw = !this.toggleDrawModeSw;
    this.onToggleDrawMode.emit(this.toggleDrawModeSw);
  }

  toggleBoundingBoxes() {
    this.toggleBoundingBoxesSw = !this.toggleBoundingBoxesSw;
    this.onToggleBoundingBoxes.emit(this.toggleBoundingBoxesSw);
  }

  toggleImageAnnotations() {
    this.toggleImageAnnotationsSw = !this.toggleImageAnnotationsSw;
    this.onToggleImageAnnotations.emit(this.toggleImageAnnotationsSw);
  }

  goToCurPage() {
    this.onGoToCurPage.emit(this.curPageNum);
  }
}
