import { NgRedux } from '@redux/redux-compatibility.service';
import { Component, OnInit } from '@angular/core';
import { DataElement } from 'src/app/shared/models/common';
import { keyCodes } from 'src/app/shared/models/key-codes.model';
import { TabIdList } from 'src/app/shared/models/tab.model';
import { TaggedDocsSearchParams } from 'src/app/shared/models/tagged_doc.model';
import { Env } from 'src/app/shared/models/user.model';
import { actionsList, AppState } from 'src/app/shared/redux/store';
import { CatService } from 'src/app/shared/services/cat.service';

@Component({
  selector: 'app-mng-tagged-docs',
  templateUrl: './mng-tagged-docs.component.html',
  styleUrls: ['./mng-tagged-docs.component.scss'],
})
export class MngTaggedDocsComponent implements OnInit {
  tabIdList: TabIdList;
  filterDocIdName: string;
  filterEnv: Env;

  get envList(): Env[] {
    return this.ngRedux.getState().envList;
  }

  get displayClearFilter(): boolean {
    return this.filterDocIdName != null || this.filterEnv != null;
  }

  constructor(
    private ngRedux: NgRedux<AppState>,
    private catService: CatService
  ) {
    this.tabIdList = this.ngRedux.getState().ui_tabIdList;
  }

  ngOnInit(): void {
    this.getData();
  }

  getData() {
    let searchParams = new TaggedDocsSearchParams();
    searchParams.docIdName = this.filterDocIdName;
    searchParams.envId = this.filterEnv ? this.filterEnv.id : null;

    this.ngRedux.dispatch({
      type: actionsList.RUN_TAG_DOCS_SEARCH,
      data: searchParams,
    });
  }

  onFilterKeyPress(e: any) {
    if (e.keyCode == keyCodes.enter) {
      this.getData();
    }
  }

  onFieldValueChanged(e: any) {
    (e.originalEvent as MouseEvent).stopPropagation();
    this.getData();
  }

  clearFilter() {
    this.filterDocIdName = null;
    this.filterEnv = null;
    this.getData();
  }
}
