import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { stat } from 'fs';
import {
  Doc,
  ProcessRunStatusEnum,
  ProcessRunStatusResult,
} from '../../models/common';

@Component({
  selector: 'process-run-status',
  templateUrl: './process-run-status.component.html',
  styleUrls: ['./process-run-status.component.scss'],
})
export class ProcessRunStatusComponent implements OnInit {
  @Output() onClose = new EventEmitter();
  @Input() doc: Doc;
  @Input() status: ProcessRunStatusResult;

  constructor() {}

  ngOnInit(): void {}

  close() {
    this.onClose.emit();
  }
}
