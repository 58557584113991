<section class="page-header">
  <div class="l-align-end">
    <button class="t-button" (click)="create()">
      {{ "translate.cat.createCardTemplate" | translate }}
    </button>
  </div>
</section>

<loader *ngIf="loading"></loader>

<div *ngIf="!data && !loading" role="alert" class="c-alert t-alert-info">
  {{ "translate.alert.noResults" | translate }}
</div>

<ui-table
  [isWrapped]="false"
  [data]="data"
  [displayFilter]="['id', 'name']"
  [templates]="[
    {
      title: 'translate.general.assetType',
      template: assetType
    },
    {
      title: 'translate.general.actions',
      template: actions
    }
  ]"
></ui-table>

<ng-template #assetType let-row="row">
  {{ row.assetType.text }}
</ng-template>

<ng-template #actions let-row="row">
  <a (click)="view(row)">{{ "translate.general.view" | translate }}</a>
  |
  <a (click)="edit(row)">{{ "translate.general.edit" | translate }}</a>
</ng-template>

<create-card-asset-template
  *ngIf="displayCreate"
  [data]="editData"
  (onClose)="displayCreate = false"
  (onCreate)="onCreated()"
></create-card-asset-template>
