import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { Config } from './app/config';

if (environment.production) {
  enableProdMode();
}

$.getJSON('assets/config/config.json', (mainConf) => {
  let env = mainConf['env'];
  $.getJSON('assets/config/config_' + env + '.json', (data) => {
    Config.assetConfigData = data;
    console.log(Config.assetConfigData);

    platformBrowserDynamic()
      .bootstrapModule(AppModule)
      .catch((err) => console.error(err));
  });
});

// $.getJSON('assets/config/config.json', (data) => {
//   Config.assetConfigData = data;
//   console.log(Config.assetConfigData);

//   platformBrowserDynamic().bootstrapModule(AppModule)
//   .catch(err => console.error(err));
// })
