import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DataElement } from 'src/app/shared/models/common';
import {
  RevStatus,
  RevRule,
  RevDocStatusEnum,
} from 'src/app/shared/models/rev.model';
import { TranslateService } from '@ngx-translate/core';
import { NgRedux } from '@redux/redux-compatibility.service';
import { AppState, actionsList } from 'src/app/shared/redux/store';
import { RevService } from 'src/app/shared/services/rev.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { Unsubscribe } from 'redux';

@Component({
  selector: 'rev-doc-rule-tile',
  templateUrl: './rev-doc-rule-tile.component.html',
  styleUrls: ['./rev-doc-rule-tile.component.scss'],
})
export class RevDocRuleTileComponent implements OnInit {
  @Input() rule: RevRule;
  @Input() displayPageNum: boolean;
  @Output() onToggleRuleView = new EventEmitter();
  @Output() onGoToRulePage = new EventEmitter();
  @Output() onUpdateSpellingIssues = new EventEmitter();

  ruleStatusOptions: DataElement[] = [];
  ruleDetailsOpenSw: boolean = false;
  focusRuleDetailsOnOpen: boolean;
  un: Unsubscribe;

  get editable(): boolean {
    return (
      this.ngRedux.getState().curRevDoc.statusCode == RevDocStatusEnum.UNDERWAY
    );
  }

  get curPage(): number {
    return this.ngRedux.getState().curRevDocPageNum;
  }

  get isPhrase(): boolean {
    return !this.rule.isImage;
  }

  get isShowIssuesOnFullMatchEnabled(): boolean {
    return this.sharedService.isShowIssuesOnFullMatchEnabled();
  }

  get libraryMismatch() {
    if (this.rule.revRuleSimilarityEvent == 'POTENTIAL_NEW') {
      return false;
    }
    return this.getFindingEventCategory('TEXT_MISMATCH');
  }

  get referenceIssues() {
    if (!this.isShowIssuesOnFullMatchEnabled && this.rule.revRuleSimilarityEvent == 'FULL_MATCH') {
      return false;
    }
    return this.getFindingEventCategory('REFERENCE_ISSUE');
  }

  get contentPartMismatch() {
    if (!this.isShowIssuesOnFullMatchEnabled && this.rule.revRuleSimilarityEvent == 'FULL_MATCH') {
      return false;
    }
    return this.getFindingEventCategory('MANDATORY_ASSOC_CONTENT_ISSUE');
  }

  get spellingGrammarIssues() {
    if (!this.isShowIssuesOnFullMatchEnabled && this.rule.revRuleSimilarityEvent == 'FULL_MATCH') {
      return false;
    }
    return this.getFindingEventCategory('SPELLING_GRAMMAR_ISSUE');
  }

  get formatMismatch() {
    if (!this.isShowIssuesOnFullMatchEnabled && this.rule.revRuleSimilarityEvent == 'FULL_MATCH') {
      return false;
    }
    return this.getFindingEventCategory('FORMAT_MISMATCH');
  }

  getFindingEventCategory(findingEventCategory: string) {
    let matchFindingCategoryDescs = this.rule.revRuleFindingCategoryDescs.filter(
      revRuleFindingCategoryDesc => findingEventCategory == revRuleFindingCategoryDesc[0]
    );
    return matchFindingCategoryDescs.length > 0 ? matchFindingCategoryDescs[0] : null;
  }

  constructor(
    private translateService: TranslateService,
    private ngRedux: NgRedux<AppState>,
    private sharedService: SharedService,
    private revService: RevService
  ) {
    this.un = this.ngRedux.subscribe(() => {
      const appState = this.ngRedux.getState();
      switch (appState._currentAction) {
        case actionsList.REV_RULE_ANN_CLICKED:
          if (
            (appState._currentActionData as RevRule).revRuleId ==
            this.rule.revRuleId
          ) {
            this.onRuleDetailsClicked(null);
          }
          break;
      }
    });
  }

  ngOnInit(): void {
    let actions = [];
    if (this.rule) {
      actions = this.rule.revRuleActions.map(
        a => (a == 'APPLY_CHANGE') ? 'MARKED_FOR_CHANGES' : a
      );
    }
    // show all actions on old docs
    let isNewRuleDoc = !!this.rule.revRuleSimilarityEvent;
    if (isNewRuleDoc) {
      this.ruleStatusOptions =
      this.sharedService.getRevRuleUpdStatusOptions().filter(
        s => {
          return actions.indexOf(s.id) >= 0;
        }
      );
    } else {
      this.ruleStatusOptions = this.sharedService.getRevRuleUpdStatusOptions();
    }

    if (
      this.ngRedux.getState().dlRevDoc &&
      this.ngRedux.getState().dlRevDoc.ruleId &&
      this.ngRedux.getState().dlRevDoc.ruleId == this.rule.revRuleId &&
      !this.ngRedux.getState().dlRevDoc.dlRuleHandled
    ) {
      this.onRuleDetailsClicked(null);
      this.ngRedux.getState().dlRevDoc.dlRuleHandled = true;
    }
  }

  ngOnDestroy(): void {
    if (this.un) this.un();
  }

  onRuleDetailsClicked(event: any) {
    if (event) event.stopPropagation();
    this.focusRuleDetailsOnOpen = false;
    this.openRuleDetails();
  }

  openRuleDetails() {
    this.ngRedux.dispatch({
      type: actionsList.SELECT_REV_RULE,
      data: this.rule,
    });
    this.ruleDetailsOpenSw = true;
  }

  onRevStatusChange() {
    if (this.rule.revStatusCode == this.rule.ruleUserStatus.id) {
      this.rule.revStatusCode = null;
      this.rule.ruleUserStatus = null;
      this.rule.variantOrigPhraseId = null;
    } else {
      this.rule.revStatusCode = this.rule.ruleUserStatus.id;
      this.rule.revStatusDescr = this.ngRedux
        .getState()
        .revRuleStatusList.find(
          (s) => s.id == this.rule.ruleUserStatus.id
        ).text;
    }

    this.revService
      .updRevRule(
        this.ngRedux.getState().curRevDoc,
        [this.rule],
        this.rule.revStatusCode,
        this.rule.variantOrigPhraseId
      )
      .subscribe(
        (data) => {
          this.rule.revStatusCode = data[0].revStatusCode;
          this.rule.revStatusDescr = data[0].revStatusDescr;
          this.onToggleRuleView.emit();

          if (this.rule.revStatusCode == RevStatus.DISMISSED) {
            this.focusRuleDetailsOnOpen = true;
            this.openRuleDetails();
          }
        },
        (err) => {}
      );
  }

  toggleRuleView(event: any) {
    this.rule.viewSw = !this.rule.viewSw;
    this.onToggleRuleView.emit();
  }

  goToPage() {
    this.onGoToRulePage.emit();
  }

  notifySpellingIssuesUpdate() {
    this.onUpdateSpellingIssues.emit();
  }

}
