import { NgRedux } from '@redux/redux-compatibility.service';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Card, CardSearchParams } from 'src/app/shared/models/cat.model';
import {
  CombinedCard,
  LabelChange,
  LCCardTypeEnum,
  LCDoc,
  LCSearchParams,
  LCStats,
} from 'src/app/shared/models/lc.model';
import { actionsList, AppState } from 'src/app/shared/redux/store';
import { AuthService } from 'src/app/shared/services/auth.service';
import { CatService } from 'src/app/shared/services/cat.service';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'lc-cards',
  templateUrl: './lc-cards.component.html',
  styleUrls: ['./lc-cards.component.scss'],
})
export class LcCardsComponent implements OnInit {
  @Input() type: 'ac' | 'bc';
  cards: Card[] = [];
  combinedCards: CombinedCard[] = [];

  get lc(): LabelChange {
    return this.ngRedux.getState().curLabelChange;
  }

  constructor(
    private ngRedux: NgRedux<AppState>,
    private catService: CatService,
    private router: Router,
    private sharedService: SharedService,
    private authService: AuthService,
    private translateService: TranslateService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      this.type = params['type'];
      console.log('type: ' + this.type);
      this.getCards();
    });
  }

  getCards() {
    if (this.type == 'bc') {
      this.cards = this.lc.cards.filter(
        (c) => c.lcCardType.id == LCCardTypeEnum.LC_NEED_TAG
      );
    } else {
      this.cards = this.lc.cards.filter(
        (c) => c.lcCardType.id == LCCardTypeEnum.LC_DONE_TAG
      );
    }

    this.cards.forEach((c) => {
      let idx = this.combinedCards.findIndex(
        (cc) => cc.cardType == c.lcCardType.id
      );
      if (idx == -1) {
        let cc = new CombinedCard();
        cc.cardType = c.lcCardType.id;
        cc.cardTypeDescr = c.lcCardType.text;
        cc.cards = [c];
        this.combinedCards.push(cc);
      } else {
        this.combinedCards[idx].cards.push(c);
      }
    });

    this.combinedCards.sort((a, b) => {
      return a.cardType.includes('TAG') ? -1 : 1;
    });

    let bbKeys = [];
    this.cards.forEach((c) =>
      c.cardDocs.forEach((d) =>
        d.boundingBoxes.forEach((bb) => bbKeys.push(bb.s3Key))
      )
    );
    this.catService.getS3Objects(bbKeys).subscribe();
  }

  addCards() {
    this.ngRedux.dispatch({
      type: actionsList.SELECT_CATALOG,
      data: this.lc.catalog,
    });
    this.router.navigate([
      this.sharedService.routeEnum.URL_LC_SELECT_CARDS,
      this.type,
    ]);
  }
}
