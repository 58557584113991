import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PhraseWord, ReferencePhrase } from 'src/app/shared/models/common';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from 'src/app/shared/services/shared.service';
import { IndPhrase } from 'src/app/shared/models/cat.model';
import { WrappedNodeExpr } from '@angular/compiler';

@Component({
  selector: 'cat-phrase-ref',
  templateUrl: './cat-phrase-ref.component.html',
  styleUrls: ['./cat-phrase-ref.component.scss'],
})
export class CatPhraseRefComponent implements OnInit {
  @Input() ref: ReferencePhrase;
  @Input() editable: boolean;
  @Input() allowEdit: boolean;
  @Input() mainPhrase: IndPhrase;

  @Output() onRefChanged = new EventEmitter();
  @Output() onDeleteRef = new EventEmitter();

  editMode: boolean;
  displayEditBtn: boolean;
  mainPhraseWords: PhraseWord[] = [];
  pointerWord: PhraseWord;
  displayPointerWordList: boolean;

  constructor(
    private translateService: TranslateService,
    private sharedService: SharedService
  ) {}

  ngOnInit(): void {
    this.ref.saveOriginal();
    if (this.editable && !this.ref.refPhraseId) {
      this.editMode = true;
    }
    if (this.ref.pointerWordId) {
      this.pointerWord = this.mainPhrase.phraseWords.find(
        (w) => w.id == this.ref.pointerWordId
      );
    }
    Object.assign(this.mainPhraseWords, this.mainPhrase.phraseWords);
  }

  markChanges() {
    this.onRefChanged.emit();
  }

  discardChanges() {
    this.ref.restoreOriginal();
    this.editMode = false;
    this.ref.saveOriginal();
  }

  onDeleteClicked() {
    if (!this.ref.refPhraseId) {
      this.onDeleteRef.emit();
      return;
    }

    let validationStr = this.translateService.instant(
      'translate.cat.deleteReference'
    );

    this.sharedService.emitPopupchReceivedEvent({
      title: 'translate.validation.confirm',
      icon: 'exclamation-triangle',
      subTitle: validationStr,
      keep: false,
      approve: () => {
        this.onDeleteRef.emit();
      },
    });
  }

  onWordChanged() {
    this.markChanges();
    this.ref.pointerWordId = this.pointerWord.id;
    this.displayPointerWordList = false;
  }
}
