<modal-popup
  [flexible]="mode != 'doc-view'"
  [isOpen]="true"
  (onClose)="close()"
  [title]="'translate.cat.addExtRef' | translate"
>
  <div *ngIf="mode != 'doc-view'" class="container">
    <div class="title-container">
      <a *ngIf="mode == 'doc'" class="icon" (click)="mode = 'cat'"
        ><fa-icon [icon]="['fas', 'arrow-left']"></fa-icon>&nbsp;</a
      >
    </div>
    <loader *ngIf="loading"></loader>

    <ui-table
      *ngIf="!loading && mode == 'cat'"
      [isWrapped]="false"
      [data]="curCat.extCat"
      [displayFilter]="['catId', 'catDescr', 'productDescr']"
      [templates]="[
        {
          title: 'translate.general.actions',
          template: catActions
        }
      ]"
    ></ui-table>

    <ng-template #catActions let-row="row">
      <a (click)="selectCat(row)">
        <span>{{ "translate.general.view" | translate }}</span>
      </a>
    </ng-template>

    <ui-table
      *ngIf="!loading && mode == 'doc'"
      [isWrapped]="false"
      [data]="docs"
      [displayFilter]="['docId', 'docName', 'lastVersionId', 'createDate']"
      [templates]="[
        {
          title: 'translate.general.assetType',
          template: assetType
        },
        {
          title: 'translate.general.actions',
          template: docActions
        }
      ]"
    ></ui-table>

    <ng-template #assetType let-row="row">
      <span *ngIf="row.assetType">{{ row.assetType.text }}</span>
    </ng-template>

    <ng-template #docActions let-row="row">
      <a (click)="selectDoc(row)">
        <span>{{ "translate.general.view" | translate }}</span>
      </a>
    </ng-template>
  </div>

  <div *ngIf="mode == 'doc-view'" class="doc-view-container">
    <cat-doc
      [stateTag]="'SEC'"
      (onAddExtRef)="onAddExtRef()"
      (onGoBack)="closeDoc()"
      [srcPhraseId]="srcPhraseId"
      [extCatVersionId]="selectedCat.catVersionId"
    ></cat-doc>
  </div>
</modal-popup>
