import { Directive, ElementRef, HostBinding } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";

//services
import { SharedService } from "../../services/shared.service";
import { NgRedux } from '@redux/redux-compatibility.service';
import { AppState, actionsList } from "../../redux/store";

@Directive({
  selector: "[translate]",
})
export class TranslateDirective {
  lang: string = "";
  defaultLang: string = "en";
  @HostBinding("class.is-rtl") get valid() {
    return this.isRtl();
  }
  @HostBinding("class.is-ltr") get invalid() {
    return !this.isRtl();
  }

  constructor(
    private sharedService: SharedService,
    private translate: TranslateService,
    private el: ElementRef,
    private activatedRoute: ActivatedRoute,
    private ngRedux: NgRedux<AppState>
  ) {}

  ngOnInit() {
    // set lang by parameter
    this.activatedRoute.data.subscribe((data) => {
      if (data.lang) {
        this.lang = data.lang;
        this.setLang();
      }
    });

    // get lang overwrite form ui
    this.ngRedux.subscribe(() => {
      const appState = this.ngRedux.getState();
      switch (appState._currentAction) {
        case actionsList.UI__REQUEST_SET_LANG:
          this.lang = appState.ui_requestSetLang;
          this.setLang(true);
          break;
      }
    });

    this.setLang();
  }

  setLang(saveCookie: boolean = false) {
    let _lang: string =
      this.lang ||
      this.sharedService.getCookie("lang") ||
      this.defaultLang ||
      this.sharedService.lang;
     
    let _direction: string;

    if (this.sharedService.overwriteUserLang)
      _lang = this.sharedService.overwriteUserLang;

    _lang = _lang.toLowerCase();

    if (!_lang) return;

    if (saveCookie) this.sharedService.putCookie("lang", _lang);

    this.translate.use(_lang);

    if (this.isRtl(_lang)) {
      document.body.classList.remove("is-ltr");
      document.body.classList.add("is-rtl");
      _direction = "rtl";
    } else {
      document.body.classList.remove("is-rtl");
      document.body.classList.add("is-ltr");
      _direction = "ltr";
    }

    this.el.nativeElement.setAttribute("lang", _lang);
    document.querySelector("html").setAttribute("lang", _lang);

    this.ngRedux.dispatch({
      type: actionsList.UI__SET_LANG,
      data: {
        direction: _direction,
        lang: _lang,
      },
    });

  }

  isRtl(lang?): boolean {
    const _lang = lang || this.ngRedux.getState().ui_lang;
    if (!_lang) return false;
    // Arabic and Hebrew have ltr direction.
    return _lang.toLowerCase() === "ar" || _lang.toLowerCase() === "he";
  }
}
