import { NgRedux } from '@redux/redux-compatibility.service';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Unsubscribe } from 'redux';
import { DataElement } from 'src/app/shared/models/common';
import { keyCodes } from 'src/app/shared/models/key-codes.model';
import { CognitoSyncStatusEnum, Env } from 'src/app/shared/models/user.model';
import { User, UserSearchParams } from 'src/app/shared/models/user.model';
import { actionsList, AppState } from 'src/app/shared/redux/store';
import { AuthService } from 'src/app/shared/services/auth.service';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'app-mng-users',
  templateUrl: './mng-users.component.html',
  styleUrls: ['./mng-users.component.scss'],
})
export class MngUsersComponent implements OnInit {
  loading: boolean;
  rangeFrom: number;
  rangeTo: number;
  totalRecords: number;
  filterUsername: string;
  filterEnv: Env;
  reduxSubscribe: Unsubscribe;
  searchParams: UserSearchParams = {};
  curUser: User;
  editUserOpenSw: boolean;
  getProcStatusFailedCnt = 0;
  processStatusTimers: NodeJS.Timeout[] = [];
  getProcessStatusIntervalSeconds: number = 5;

  get usersList(): User[] {
    return this.ngRedux.getState().usersList;
  }

  get envList(): Env[] {
    return this.ngRedux.getState().envList;
  }

  get displayClearFilter(): boolean {
    return this.filterEnv != null || this.filterUsername != null;
  }

  constructor(
    private ngRedux: NgRedux<AppState>,
    private sharedService: SharedService,
    private authService: AuthService,
    private translateService: TranslateService
  ) {
    this.reduxSubscribe = this.ngRedux.subscribe(() => {
      const appState = this.ngRedux.getState();
      switch (appState._currentAction) {
        case actionsList.USER_SIGN_OUT:
          this.processStatusTimers.forEach((t) => {
            clearInterval(t);
          });
          break;
      }
    });
  }

  ngOnInit(): void {
    this.getUsers(true);
    this.getUserRoles();
  }

  ngOnDestroy(): void {
    this.processStatusTimers?.forEach((t) => {
      clearInterval(t);
    });
  }

  ngAfterViewInit(): void {
    // this.ngRedux.dispatch({
    //   type: actionsList.UI__UPD_BREADCRUMBS_LEV,
    //   data: {
    //     lev: 1,
    //     title: this.translateService.instant('translate.sidebar.mngUsers'),
    //   },
    // });
  }

  getUserRoles() {
    if (
      !this.ngRedux.getState().userRoles ||
      this.ngRedux.getState().userRoles.length == 0
    ) {
      this.ngRedux.getState().userRoles.loading = true;
      this.authService.getUserRoles().subscribe();
    }
  }

  getUsers(newSearch: boolean) {
    this.loading = true;

    if (newSearch) {
      this.searchParams.searchId = null;
      this.searchParams.rangeFrom = 1;
      this.searchParams.rangeTo = 10;
    }

    this.searchParams.username = this.filterUsername;
    this.searchParams.env = this.filterEnv;

    this.processStatusTimers.forEach((t) => {
      clearInterval(t);
    });
    this.processStatusTimers = [];
    this.getProcStatusFailedCnt = 0;

    this.authService.getUsers(this.searchParams).subscribe(
      (data) => {
        this.loading = false;
        let processingUsers = this.usersList.filter(
          (d) =>
            d.cognitoSyncStatus == CognitoSyncStatusEnum.PROCESSING ||
            d.cognitoSyncStatus == CognitoSyncStatusEnum.PENDING
        );
        if (processingUsers.length > 0) {
          this.handleProcessingUsers(processingUsers);
        }
      },
      (err) => (this.loading = false)
    );
  }

  handleProcessingUsers(users: User[]) {
    users.forEach((u) => {
      let timer = setInterval(() => {
        let params = new UserSearchParams();
        params.exactUsername = u.username;
        params.rangeFrom = 1;
        params.rangeTo = 2;
        this.authService.getUsers(params, false).subscribe(
          (data) => {
            if (data.length > 0) {
              let user = data[0];
              if (
                !(
                  user.cognitoSyncStatus == CognitoSyncStatusEnum.PROCESSING ||
                  user.cognitoSyncStatus == CognitoSyncStatusEnum.PENDING
                )
              ) {
                clearInterval(timer);
                let idx = this.usersList.findIndex(
                  (u) => u.userId == user.userId
                );
                if (idx != -1) {
                  this.usersList[idx].cognitoActiveSw = user.cognitoActiveSw;
                  this.usersList[idx].cognitoSyncStatus =
                    user.cognitoSyncStatus;
                }
              }
            }
          },
          (err) => {
            this.getProcStatusFailedCnt++;
            if (this.getProcStatusFailedCnt > 20) {
              clearInterval(timer);
            }
          }
        );
      }, this.getProcessStatusIntervalSeconds * 1000);

      this.processStatusTimers.push(timer);
    });
  }

  addUser() {
    this.curUser = new User();
    this.editUserOpenSw = true;
  }

  editUser(user: User) {
    this.curUser = user;
    this.editUserOpenSw = true;
  }

  onFilterKeyPress(e: any) {
    if (e.keyCode == keyCodes.enter) {
      this.getUsers(true);
    }
  }

  onFieldValueChanged(e: any) {
    (e.originalEvent as MouseEvent).stopPropagation();
    this.getUsers(true);
  }

  clearFilter() {
    this.filterEnv = null;
    this.filterUsername = null;
    this.getUsers(true);
  }
}
