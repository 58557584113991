<section class="page-header">
  <div *ngIf="!addModeSw" class="l-align-end">
    <button class="t-button" (click)="onAdd()">
      {{ "translate.general.add" | translate }}
    </button>
  </div>

  <div *ngIf="addModeSw" class="title-container">
    <h1>
      <a class="icon" (click)="addModeSw = false"
        ><fa-icon [icon]="['fas', 'arrow-left']"></fa-icon
      ></a>
      &nbsp; {{ "translate.cat.selectExtLib" | translate }}
    </h1>
  </div>
</section>

<loader *ngIf="loading"></loader>

<ui-table
  *ngIf="!loading"
  [isWrapped]="false"
  [data]="addModeSw ? addCatalogs : curCat.extCat"
  [displayFilter]="['catId', 'catDescr', 'productDescr']"
  [templates]="[
    {
      title: 'translate.general.actions',
      template: headerActions
    }
  ]"
></ui-table>

<ng-template #headerActions let-row="row">
  <a *ngIf="!addModeSw" (click)="remove(row)">
    <span>{{ "translate.general.remove" | translate }}</span>
  </a>
  <a *ngIf="addModeSw && getPublishedVersion(row)" (click)="addCat(row)">
    <span>{{ "translate.general.add" | translate }}</span>
  </a>
</ng-template>
