<div class="tile-container" [class.selected]="rule.viewSw">
  <checkbox-wrap class="checkbox">
    <input type="checkbox" [(ngModel)]="rule.checkedSw" />
  </checkbox-wrap>

  <div class="id footnote">
    {{ "translate.rev.Id" | translate }}: {{ rule.revRuleId }}

    <span *ngIf="displayPageNum"> | </span>
    <span *ngIf="displayPageNum && rule.pageNum == curPage">
      {{ "translate.general.page" | translate }}: {{ rule.pageNum }}</span>

    <a *ngIf="displayPageNum && rule.pageNum != curPage" (click)="goToPage()">
      {{ "translate.general.page" | translate }}: {{ rule.pageNum }}</a>
  </div>

  <div class="phrase">
    <div *ngIf="isPhrase">
      <p class="t-footnote">
        {{ rule.revRuleContentTypeDesc }}
      </p>
      <div *ngIf="rule.revRuleType != 'BX_MATCH'">
        <formatted-phrase [phraseWords]="rule.ruleDispWords"></formatted-phrase>
      </div>

      <div *ngIf="rule.revRuleType == 'BX_MATCH'">
        <p class="fw-semibold" [class.missing]="rule.similarityCode == 'MISSING'">
          {{ "translate.general.boundingBox" | translate }} &middot;
          {{ rule.boundingBoxMatch.boundingBox.boxType.text }}
        </p>
        <span [class.missing]="rule.similarityCode == 'MISSING'">
          {{ rule.boundingBoxMatch.boundingBox.descr }}</span>
      </div>
    </div>

    <div *ngIf="!isPhrase">
      <p class="t-footnote figure-type">
        {{ rule.figureTypeDescr }}
        <span *ngIf="rule.figureSubType">&middot; {{ rule.figureSubType.text }}</span>
      </p>
      <async-img class="t-pointer" [className]="'ind-img'" [url]="rule.imgUrl"
        (click)="onRuleDetailsClicked()"></async-img>
    </div>
  </div>

  <div class="type">
    <button class="rev-rule-type-desc-tile"
      [ngClass]="['rev-rule-type-descr', rule.revRuleSimilarityEvent || rule.revRuleType]"
      (click)="onRuleDetailsClicked($event)">
      {{ rule.revRuleSimilarityEventDesc || rule.revRuleTypeDescr }}
    </button>
    <div>
      <i *ngIf="!!libraryMismatch" class="task" tooltip="{{ libraryMismatch[1] }}"
        tooltipPosition="bottom"></i>
      <i *ngIf="!!referenceIssues" class="rule" tooltip="{{ referenceIssues[1] }}" tooltipPosition="bottom"></i>
      <i *ngIf="!!contentPartMismatch" class="warning" tooltip="{{ contentPartMismatch[1] }}"
        tooltipPosition="bottom"></i>
      <i *ngIf="!!spellingGrammarIssues" class="spellcheck" tooltip="{{ spellingGrammarIssues[1] }}"
        tooltipPosition="bottom"></i>
      <i *ngIf="!!formatMismatch" class="format" tooltip="{{ formatMismatch[1] }}" tooltipPosition="bottom"></i>
    </div>
  </div>
  <p *ngIf="rule.revStatusCode" [ngClass]="['rev-rule-status', rule.revStatusCode]">
    {{ rule.revStatusDescr }}
  </p>
  <spinner *ngIf="!rule.revStatusCode" class="rev-rule-status-spinner"></spinner>

  <div *ngIf="isPhrase && rule.associatedText" class="associatedText">
    <p class="title">{{ "translate.general.associatedText" | translate }}:</p>
    <div *ngFor="let assocPhrase of rule.associatedText" class="ref-container">
      <p *ngIf="assocPhrase.phraseDispWords.length == 0" class="text">
        {{ assocPhrase.assocPhraseText }}
      </p>
      <formatted-phrase *ngIf="assocPhrase.phraseDispWords.length > 0" class="text"
        [phraseWords]="assocPhrase.phraseDispWords"></formatted-phrase>
    </div>
  </div>

  <div *ngIf="isPhrase && rule.references" class="reference">
    <p class="title">{{ "translate.rev.reference" | translate }}:</p>
    <div *ngFor="let ref of rule.references" class="ref-container">
      <p *ngIf="ref.phraseDispWords.length == 0" class="text">
        {{ ref.refPhraseText }}
      </p>
      <formatted-phrase *ngIf="ref.phraseDispWords.length > 0" class="text"
        [phraseWords]="ref.phraseDispWords"></formatted-phrase>
    </div>
  </div>

  <div *ngIf="isPhrase && rule.associatedBy" class="associatedBy">
    <p class="title">{{ "translate.general.associatedBy" | translate }}:</p>
    <div *ngFor="let assocPhrase of rule.associatedBy" class="ref-container">
      <p *ngIf="assocPhrase.phraseDispWords.length == 0" class="text">
        {{ assocPhrase.assocPhraseText }}
      </p>
      <formatted-phrase *ngIf="assocPhrase.phraseDispWords.length > 0" class="text"
        [phraseWords]="assocPhrase.phraseDispWords"></formatted-phrase>
    </div>
  </div>

  <div *ngIf="isPhrase && rule.referencedBy" class="referencedBy">
    <p class="title">{{ "translate.rev.referencedBy" | translate }}:</p>
    <div *ngFor="let ref of rule.referencedBy" class="ref-container">
      <p *ngIf="ref.phraseDispWords.length == 0" class="text">
        {{ ref.refPhraseText }}
      </p>
      <formatted-phrase *ngIf="ref.phraseDispWords.length > 0" class="text"
        [phraseWords]="ref.phraseDispWords"></formatted-phrase>
    </div>
  </div>

  <div *ngIf="isPhrase && rule.associatedImages" class="associatedImages">
    <p class="title">{{ "translate.general.associatatedImages" | translate }}:</p>
    <div *ngFor="let img of rule.associatedImages" style="margin-bottom: 0.5em;">
      <p class="t-footnote figure-type">
        ID: {{ img.assocImageId }}
      </p>
      <async-img [className]="'ind-img'" [url]="img.imgUrl"></async-img>
    </div>
  </div>

  <div *ngIf="rule.addInstr && rule.addInstr.length > 0" class="instructions">
    <p class="title">{{ "translate.rev.instructions" | translate }}:</p>
    <div *ngFor="let instr of rule.addInstr">
      <p class="text">{{ instr.text }}</p>
    </div>
  </div>

  <div *ngIf="editable" class="btn">
    <p-selectButton class="rev-status" [options]="ruleStatusOptions" [(ngModel)]="rule.ruleUserStatus"
      (onChange)="onRevStatusChange()"></p-selectButton>

    <i *ngIf="rule.comment" class="far fa-comment-alt icon" (click)="onRuleDetailsClicked($event)"
      tooltip="{{ rule.comment }}"></i>
  </div>

  <div class="icons">
    <i *ngIf="rule.pageNum == curPage && rule.similarityCode !== 'MISSING'" class="icon fas fa-eye icon-view"
      [class.selected]="rule.viewSw" (click)="toggleRuleView($event)"
      [tooltip]="'translate.general.viewAnnotation' | translate"></i>
  </div>

</div>
<div *ngIf="isPhrase && rule.revRuleSimilarityEvent">
  <phrase-rev-rule-details *ngIf="ruleDetailsOpenSw" (onClose)="ruleDetailsOpenSw = false"
    (onStatusChange)="onRevStatusChange()" [focusOnOpen]="focusRuleDetailsOnOpen" (onUpdateSpellingIssues)="notifySpellingIssuesUpdate()"></phrase-rev-rule-details>
</div>
<div *ngIf="!isPhrase || !rule.revRuleSimilarityEvent">
  <rev-rule-details *ngIf="ruleDetailsOpenSw" (onClose)="ruleDetailsOpenSw = false"
    (onStatusChange)="onRevStatusChange()" [focusOnOpen]="focusRuleDetailsOnOpen"></rev-rule-details>
</div>