import { NgRedux } from '@redux/redux-compatibility.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  LabelChange,
  LCCardTypeEnum,
  LCDoc,
} from 'src/app/shared/models/lc.model';
import { TabIdList } from 'src/app/shared/models/tab.model';
import { AppState } from 'src/app/shared/redux/store';

@Component({
  selector: 'lc-doc-summary',
  templateUrl: './lc-doc-summary.component.html',
  styleUrls: ['./lc-doc-summary.component.scss'],
})
export class LcDocSummaryComponent implements OnInit {
  @Input() type: 'ac' | 'bc';
  @Input() doc: LCDoc;

  @Output() onClose = new EventEmitter();

  tabIdList: TabIdList;

  get lc(): LabelChange {
    return this.ngRedux.getState().curLabelChange;
  }

  get taggedCardType(): LCCardTypeEnum {
    return this.type == 'bc'
      ? LCCardTypeEnum.LC_NEED_TAG
      : LCCardTypeEnum.LC_DONE_TAG;
  }

  get detectedCardType(): LCCardTypeEnum {
    return this.type == 'bc'
      ? LCCardTypeEnum.LC_NEED_ALG
      : LCCardTypeEnum.LC_DONE_ALG;
  }

  constructor(private ngRedux: NgRedux<AppState>) {
    this.tabIdList = this.ngRedux.getState().ui_tabIdList;
  }

  ngOnInit(): void {}

  close() {
    this.onClose.emit();
  }
}
