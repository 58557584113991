<div class="l-align-spaceBetween w-full">
  <div class="l-align-y w-full">
    <data-box
      class="w-full"
      [data]="lcStats"
      [displayFilter]="[
        'lcValidatedCnt',
        'lcInvalidationFoundCnt',
        'lcMissingCnt'
      ]"
      [columns]="1"
    ></data-box>

    <div class="canvas-status">
      <canvas
        id="chart-status-cnv-{{ type }}"
        width="120px"
        height="100px"
      ></canvas>
    </div>

    <data-box
      class="w-full"
      [data]="processRunStatus"
      [displayFilter]="['createDate']"
      [columns]="1"
      [titles]="{
        createDate: 'translate.lc.processStatus'
      }"
    ></data-box>
  </div>

  <div class="filter-bar l-align-end">
    <button
      class="t-button-outline"
      (click)="addDocuments()"
      [disabled]="running"
    >
      {{ "translate.lc.addDocs" | translate }}
    </button>
    &nbsp;&nbsp;
    <button
      *ngIf="!(processRunStatus && processRunStatus.isProcessRunning)"
      class="t-button"
      (click)="process()"
      [disabled]="running || docsProcessing"
    >
      {{ "translate.lc.process" | translate }}
    </button>

    <div
      *ngIf="processRunStatus && processRunStatus.isProcessRunning"
      class="l-align-center"
    >
      <spinner></spinner>
      &nbsp;
      <span class="processing-status">
        <processing-label></processing-label>
      </span>
    </div>
  </div>
</div>

<lc-doc-list [type]="type"></lc-doc-list>
