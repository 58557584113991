import { Component, OnInit } from '@angular/core';
import { Doc, ProcessRunStatusEnum } from '../../models/common';
import { TaggedIndDoc } from '../../models/tagged_doc.model';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-pull-doc-process',
  templateUrl: './pull-doc-process.component.html',
  styleUrls: ['./pull-doc-process.component.scss'],
})
export class PullDocProcessComponent implements OnInit {
  processStatusTimers: NodeJS.Timeout[] = [];
  getDocProcessStatusIntervalSeconds: number = 5;

  constructor(protected authService: AuthService) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.processStatusTimers?.forEach((t) => clearInterval(t));
  }

  handleProcessingDocs(
    processingDocs: Doc[],
    onFinished: any,
    replicatedDocSw: boolean = false
  ) {
    if (processingDocs.length > 0) {
      processingDocs.forEach((d) => {
        let timer = setInterval(() => {
          this.authService
            .getProcessStatus(
              replicatedDocSw
                ? (d as TaggedIndDoc).replicatedDoc.runNum
                : d.runNum
            )
            .subscribe((data) => {
              if (replicatedDocSw) {
                (d as TaggedIndDoc).replicatedDoc.processStatus = data;
              } else {
                d.processStatus = data;
              }

              if (
                data.status == ProcessRunStatusEnum.FINISHED ||
                data.status == ProcessRunStatusEnum.FAILED
              ) {
                clearInterval(timer);
                onFinished();
              }
            });
        }, this.getDocProcessStatusIntervalSeconds * 1000);

        this.processStatusTimers.push(timer);
      });
    }
  }
}
