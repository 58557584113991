export class ActionsEnum {
  ERROR__FROM_SERVER: string = 'ERROR__FROM_SERVER';
  UI__SET_LANG: string = 'UI__SET_LANG';
  UI__REQUEST_SET_LANG: string = 'UI__REQUEST_SET_LANG';
  UI__ROUTE_NAVIGATION: string = 'UI__ROUTE_NAVIGATION';
  UI__SIDEBAR_ROUTE_NAVIGATION: string = 'UI__SIDEBAR_ROUTE_NAVIGATION';
  UI__SET_ACTIVE_TABS: string = 'UI__SET_ACTIVE_TABS';
  UI__HIGHLIGHT_TABS: string = 'UI__HIGHLIGHT_TABS';
  UI__TAB_NAVIGATION: string = 'UI__TAB_NAVIGATION';
  UI__UPDATE_TAB_STORE: string = 'UI__UPDATE_TAB_STORE';
  UI__UPD_BREADCRUMBS_LEV: string = 'UI__UPD_BREADCRUMBS_LEV';
  UI__NAVIGATE_TO_TAB: string = 'UI__NAVIGATE_TO_TAB';

  SET_DL_REV_DOC: string = 'SET_DL_REV_DOC';

  APP_SIGN_IN: string = 'APP_SIGN_IN';
  GET_USER_DETAILS: string = 'GET_USER_DETAILS';
  USER_SIGN_OUT: string = 'USER_SIGN_OUT';
  GET_ENV_LIST: string = 'GET_ENV_LIST';
  SELECT_ENV: string = 'SELECT_ENV';
  GET_PRODUCT_LIST: string = 'GET_PRODUCT_LIST';
  GET_TIMEZONE_LIST: string = 'GET_TIMEZONE_LIST';

  GET_REV_DOCS: string = 'GET_REV_DOCS';
  GET_REV_DOC_STATUS_LIST: string = 'GET_REV_DOC_STATUS_LIST';
  GET_REV_RULE_TYPES: string = 'GET_REV_RULE_TYPES';
  GET_REV_RULE_STATUS_LIST: string = 'GET_REV_RULE_STATUS_LIST';
  GET_CONTENT_TYPE_LIST: string = 'GET_CONTENT_TYPE_LIST';
  GET_REV_RULE_SIMILARITY_EVENT_LIST: string = 'GET_REV_RULE_SIMILARITY_EVENT_LIST';
  GET_IND_RULE_SIMILARITY_EVENT_LIST: string = 'GET_IND_RULE_SIMILARITY_EVENT_LIST';
  SELECT_REV_DOC: string = 'SELECT_REV_DOC';
  GET_REV_DOC_RULES: string = 'GET_REV_DOC_RULES';
  SELECT_REV_RULE: string = 'SELECT_REV_RULE';
  FILTER_REV_RULES: string = 'FILTER_REV_RULES';
  UPD_REV_DOC_STATS: string = 'UPD_REV_DOC_STATS';
  UPD_REV_DOC: string = 'UPD_REV_DOC';
  SELECT_REV_DOC_PAGE_NUM: string = 'SELECT_REV_DOC_PAGE_NUM';
  SELECT_REV_DOC_PAGE_FROM_SEARCH: string = 'SELECT_REV_DOC_PAGE_FROM_SEARCH';
  GET_REV_DOC_CARDS: string = 'GET_REV_DOC_CARDS';
  GET_REV_DOC_MATCHED_CARDS: string = 'GET_REV_DOC_MATCHED_CARDS';
  GET_REV_CARD_MATCHES: string = 'GET_REV_CARD_MATCHES';
  GET_REV_IMAGE_MATCHES: string = 'GET_REV_IMAGE_MATCHES';
  GET_REV_DOC_CARDS_END: string = 'GET_REV_DOC_CARDS_END';

  GET_CATALOG_LIST: string = 'GET_CATALOG_LIST';
  GET_EXT_CATALOG_LIST: string = 'GET_EXT_CATALOG_LIST';
  GET_CAT_TYPES: string = 'GET_CAT_TYPES';
  GET_REF_MATCH_TYPES: string = 'GET_REF_MATCH_TYPES';
  GET_CAT_LANGUAGES: string = 'GET_CAT_LANGUAGES';
  GET_FIGURE_TYPES: string = 'GET_FIGURE_TYPES';
  GET_CAT_ENTRY_STATUS_LIST: string = 'GET_CAT_ENTRY_STATUS_LIST';
  SELECT_CATALOG: string = 'SELECT_CATALOG';
  GET_CAT_ENTRIES: string = 'GET_CAT_ENTRIES';
  GET_CAT_IMAGES: string = 'GET_CAT_IMAGES';
  GET_CAT_DOCS: string = 'GET_CAT_DOCS';
  SELECT_CAT_DOC: string = 'SELECT_CAT_DOC';
  CLOSE_CAT_DOC: string = 'CLOSE_CAT_DOC';
  UPD_CAT_DOC_STATS: string = 'UPD_CAT_DOC_STATS';
  GET_CAT_PHRASE_TYPES: string = 'GET_CAT_PHRASE_TYPES';
  GET_IND_DOC_PHRASES: string = 'GET_IND_DOC_PHRASES';
  GET_IND_DOC_IMAGES: string = 'GET_IND_DOC_IMAGES';
  SELECT_CAT_DOC_PAGE_NUM: string = 'SELECT_CAT_DOC_PAGE_NUM';
  SELECT_CAT_DOC_PAGE_FROM_SEARCH: string = 'SELECT_CAT_DOC_PAGE_FROM_SEARCH';
  FILTER_CAT_DOC_PHRASES: string = 'FILTER_CAT_DOC_PHRASES';
  GET_CAT_PHRASE_STATUS_LIST: string = 'GET_CAT_PHRASE_STATUS_LIST';
  GET_CAT_DOC_STATUS_LIST: string = 'GET_CAT_DOC_STATUS_LIST';
  SELECT_CAT_ELEMENT: string = 'SELECT_CAT_ELEMENT';
  GET_CAT_MATCH_SIMILARITY_CODES: string = 'GET_CAT_MATCH_SIMILARITY_CODES';
  UPD_CAT_DOC: string = 'UPD_CAT_DOC';
  UPD_IND_PHRASE: string = 'UPD_IND_PHRASE';
  RELOAD_CAT_DOC_PAGE_REQ: string = 'RELOAD_CAT_DOC_PAGE_REQ';
  REANNOTATE_CAT_DOC_REQ: string = 'REANNOTATE_CAT_DOC_REQ';
  UPD_CAT_ENTRY: string = 'UPD_CAT_ENTRY';
  DELETE_CAT_DOC_PHRASE: string = 'DELETE_CAT_DOC_PHRASE';
  UPD_CAT_VERSION: string = 'UPD_CAT_VERSION';
  SELECT_CAT_CARD: string = 'SELECT_CAT_CARD';
  GET_CARD_TYPES: string = 'GET_CARD_TYPES';
  UPD_CARD: string = 'UPD_CARD';
  GET_CARDS: string = 'GET_CARDS';
  GET_BOUNDING_BOX_TYPES: string = 'GET_BOUNDING_BOX_TYPES';
  CREATE_CAT_BOUNDING_BOX: string = 'CREATE_CAT_BOUNDING_BOX';
  DELETE_CAT_BOUNDING_BOX: string = 'DELETE_CAT_BOUNDING_BOX';
  GET_BOUNDING_BOXES: string = 'GET_BOUNDING_BOXES';
  REV_RULE_ANN_CLICKED: string = 'REV_RULE_ANN_CLICKED';
  CAT_PHRASE_ANN_CLICKED: string = 'CAT_PHRASE_ANN_CLICKED';
  GET_CAT_DOC_REF_OPTIONS: string = 'GET_CAT_DOC_REF_OPTIONS';
  GET_CAT_DOC_PAGE_LINES: string = 'GET_CAT_DOC_PAGE_LINES';
  GET_CAT_DOC_CARDS: string = 'GET_CAT_DOC_CARDS';
  RUN_IND_PHRASES_SEARCH: string = 'RUN_IND_PHRASES_SEARCH';
  GET_ASSET_TYPES: string = 'GET_ASSET_TYPES';
  GET_MC_TYPES: string = 'GET_MC_TYPES';
  GET_MC_SUB_TYPES: string = 'GET_MC_SUB_TYPES';
  GET_MC_BOX_TYPES: string = 'GET_MC_BOX_TYPES';
  RELOAD_CUR_LC_REQ: string = 'RELOAD_CUR_LC_REQ';
  GET_CARD_ASSET_TEMPLATES: string = 'GET_CARD_ASSET_TEMPLATES';
  SELECT_CARD_ASSET_TEMPLATE: string = 'CARD_ASSET_TEMPLATE';
  CARD_TRANSLATE_FINISHED: string = 'CARD_TRANSLATE_FINISHED';
  GET_CAT_EXT_REFS: string = 'GET_CAT_EXT_REFS';
  GET_CAT_ALT_REFS: string = 'GET_CAT_ALT_REFS';

  GET_RULE_CONFIG_TEMPLATES: string = 'GET_RULE_CONFIG_TEMPLATES';
  GET_GENAI_CONFIG_TEMPLATE_LIST: string = 'GET_GENAI_CONFIG_TEMPLATE_LIST';
  GET_EMBEDDING_CONFIG_TEMPLATE_LIST: string = 'GET_EMBEDDING_CONFIG_TEMPLATE_LIST';

  GET_USERS: string = 'GET_USERS';
  GET_USER_ROLES: string = 'GET_USER_ROLES';
  GET_CROSS_ENV_PRODUCTS: string = 'GET_CROSS_ENV_PRODUCTS';

  GET_LC: string = 'GET_LC';
  SELECT_LC: string = 'SELECT_LC';

  GET_IND_TAGGED_DOCS: string = 'GET_IND_TAGGED_DOCS';
  GET_REV_TAGGED_DOCS: string = 'GET_REV_TAGGED_DOCS';
  RUN_TAG_DOCS_SEARCH: string = 'RUN_TAG_DOCS_SEARCH';

  UPD_DOC_PAGE_LOADING: string = 'UPD_DOC_PAGE_LOADING';

  GET_INTEGRATION_TYPES: string = 'GET_INTEGRATION_TYPES';
  CONFIGURED_INTEGRATION_TYPE: string = 'CONFIGURED_INTEGRATION_TYPE';
  GET_CATALOG_INTEGRATION_CONFIG: string = 'GET_CATALOG_INTEGRATION_CONFIG';
  SAVE_PUBLISHED_CATALOG: string = 'SAVE_PUBLISHED_CATALOG';
}
