/**
 * IMPORTANT:
 * For clarity, do not format this file with an automatic code-formatter.
 * Otherwise you may lose all the indentations.
 */
export class RouteEnum {
  private LOGIN = 'login';
  private MAIN = 'main';
  private REV_DOC_LIST = 'rev-doc-list';
  private REV_DOC = 'rev-doc';
  private CATALOG_LIST = 'catalog-list';
  private CATALOG = 'catalog';
  private CAT_ENTRIES = 'entries';
  private CAT_IMAGES = 'images';
  private CAT_DOCS = 'docs';
  private MNG_PHRASE_TYPES = 'mng-phrase-types';
  private MNG_CAT_ASSET_TYPES = 'mng-cat-asset-types';
  private CARDS = 'cards';
  private CARD_ASSET_TEMPLATES = 'card-asset-templates';
  private CARD = 'card';
  private CARD_ENTRIES = 'entries';
  private ADD_CARD_ENTRIES = 'add_entries';
  private CARD_TRANS = 'trans';
  private CARD_SUMMARY = 'summary';
  private CAT_DOC = 'cat-doc';
  private MNG = 'mng';
  private MNG_ENV = 'env';
  private MNG_USERS = 'users';
  private MNG_PRODUCTS = 'products';
  private MNG_ASSET_TYPES = 'asset-types';
  private MNG_TAGGED_DOCS = 'tagged-docs';
  private LC = 'lc-list';
  private LC_DETAILS = 'lc';
  private LC_DETAILS_AC = 'lc-details/ac';
  private LC_DETAILS_BC = 'lc-details/bc';
  private LC_SELECT_CAT = 'select-cat';
  private LC_SELECT_DOCS = 'select-docs';
  private LC_SELECT_PHRASES = 'select-phrases';
  private LC_SELECT_CARDS = 'select-cards';
  private CARD_ASSET_TEMPLATE = 'card-asset-template-det';
  private EXT_CAT_LIST = 'ext-cat-list';
  private EXT_REFS = 'ext-refs';
  private EXT_LIBS = 'ext-libs';
  private ALT_REF = 'alt-ref';
  private SPELLING_CHECK_IGNORED = 'spelling-check-ignored';

  private urlMain = this.MAIN + '/';
  private urlRevDocList = this.REV_DOC_LIST;
  private urlRevDoc = this.REV_DOC;
  private urlCatalogList = this.CATALOG_LIST;
  private urlCatalog = this.CATALOG;
  private urlCatDoc = this.CAT_DOC;
  private urlMng = this.MNG;
  private urlLC = this.LC;
  private urlLCDetails = this.LC_DETAILS;
  private urlExtCatList = this.EXT_CAT_LIST;

  URL_EXT_CATALOG_LIST = this.urlMain + this.urlExtCatList;

  URL_LC = this.urlMain + this.urlLC;
  URL_LC_DETAILS = this.urlMain + this.urlLCDetails;
  URL_LC_BC = this.URL_LC_DETAILS + '/' + this.LC_DETAILS_BC;
  URL_LC_AC = this.URL_LC_DETAILS + '/' + this.LC_DETAILS_AC;
  URL_LC_SELECT_CAT = this.URL_LC_DETAILS + '/' + this.LC_SELECT_CAT;
  URL_LC_SELECT_DOCS = this.URL_LC_DETAILS + '/' + this.LC_SELECT_DOCS;
  URL_LC_SELECT_PHRASES = this.URL_LC_DETAILS + '/' + this.LC_SELECT_PHRASES;
  URL_LC_SELECT_CARDS = this.URL_LC_DETAILS + '/' + this.LC_SELECT_CARDS;

  URL_REV_DOC_LIST = this.urlMain + this.urlRevDocList;
  URL_REV_DOC = this.urlMain + this.urlRevDoc;
  URL_CATALOG_LIST = this.urlMain + this.urlCatalogList;
  URL_CATALOG = this.urlMain + this.urlCatalog;
  URL_CATALOG_ENTRIES = this.URL_CATALOG + '/' + this.CAT_ENTRIES;
  URL_CATALOG_IMAGES = this.URL_CATALOG + '/' + this.CAT_IMAGES;
  URL_CATALOG_CARDS = this.URL_CATALOG + '/' + this.CARDS;
  URL_CATALOG_CARD_ASSET_TEMPLATES =
    this.URL_CATALOG + '/' + this.CARD_ASSET_TEMPLATES;
  URL_CARD_ASSET_TEMPLATE_DET =
    this.URL_CATALOG + '/' + this.CARD_ASSET_TEMPLATE;
  URL_CATALOG_CARD = this.URL_CATALOG + '/' + this.CARD;
  URL_CATALOG_CARD_ENTRIES =
    this.URL_CATALOG + '/' + this.CARD + '/' + this.CARD_ENTRIES;
  URL_CATALOG_CARD_ADD_ENTRIES =
    this.URL_CATALOG + '/' + this.CARD + '/' + this.ADD_CARD_ENTRIES;
  URL_CATALOG_CARD_TRANS =
    this.URL_CATALOG + '/' + this.CARD + '/' + this.CARD_TRANS;
  URL_CATALOG_CARD_SUMMARY =
    this.URL_CATALOG + '/' + this.CARD + '/' + this.CARD_SUMMARY;

  URL_CAT_EXT_REF = this.URL_CATALOG + '/' + this.EXT_REFS;
  URL_CAT_EXT_LIBS = this.URL_CATALOG + '/' + this.EXT_LIBS;
  URL_CAT_ALT_REF = this.URL_CATALOG + '/' + this.ALT_REF;

  URL_CATALOG_DOCS = this.URL_CATALOG + '/' + this.CAT_DOCS;
  URL_CATALOG_MNG_PHRASE_TYPES = this.URL_CATALOG + '/' + this.MNG_PHRASE_TYPES;
  URL_CATALOG_MNG_ASSET_TYPES =
    this.URL_CATALOG + '/' + this.MNG_CAT_ASSET_TYPES;
  URL_CAT_DOC = this.urlMain + this.urlCatDoc;
  URL_MNG_ENV = this.urlMain + this.urlMng + '/' + this.MNG_ENV;
  URL_MNG_USERS = this.urlMain + this.urlMng + '/' + this.MNG_USERS;
  URL_MNG_PRODUCTS = this.urlMain + this.urlMng + '/' + this.MNG_PRODUCTS;
  URL_MNG_ASSET_TYPES = this.urlMain + this.urlMng + '/' + this.MNG_ASSET_TYPES;
  URL_MNG_TAGGED_DOCS = this.urlMain + this.urlMng + '/' + this.MNG_TAGGED_DOCS;

  URL_CATALOG_SPELLING_CHECK_IGNORED =
    this.URL_CATALOG + '/' + this.SPELLING_CHECK_IGNORED
}

export const routeEnum = Object.freeze(new RouteEnum());
