import { NgRedux } from '@redux/redux-compatibility.service';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DocViewerComponent } from 'src/app/shared/components/doc-viewer/doc-viewer.component';
import { PdfViewerComponent } from 'src/app/shared/components/pdf-viewer/pdf-viewer.component';
import { InputWrapComponent } from 'src/app/shared/components/ui/input-wrap.component';
import {
  BoundingBox,
  Card,
  CardImage,
  CatDoc,
  IndPhrase,
} from 'src/app/shared/models/cat.model';
import { Doc, DocPage } from 'src/app/shared/models/common';
import { CardMatch, ImageMatch } from 'src/app/shared/models/rev.model';
import { actionsList, AppState } from 'src/app/shared/redux/store';
import { CatService } from 'src/app/shared/services/cat.service';
import { RevService } from 'src/app/shared/services/rev.service';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'bb-tile-new',
  templateUrl: './bb-tile-new.component.html',
  styleUrls: ['./bb-tile-new.component.scss'],
})
export class BbTileNewComponent implements OnInit {
  @ViewChild('docSinglePhraseViewer') docViewer: PdfViewerComponent;

  @Input() box: BoundingBox;
  @Input() doc: Doc;
  @Input() docType: 'IND' | 'REV' = 'IND';
  @Input() allowEdit: boolean;
  @Input() selected: boolean;
  @Input() displayIndPhrases: boolean = false;
  @Input() forceDeleteAction: boolean;
  @Input() selectedPhraseId: number;
  @Input() card: Card;
  @Input() displayMatchStatus: boolean;
  @Input() displayPhraseLink: boolean;
  @Input() transSw: boolean;
  @Input() transLang: string;

  @Output() onViewBoxPage = new EventEmitter();
  @Output() onDelete = new EventEmitter();

  displayDocViewer: boolean;
  curDoc: Doc;
  docLoading: boolean;
  boxAdded: boolean;
  singlePageSw: boolean;
  phraseDetailsLoading: boolean;
  ruleDetailsOpenSw: boolean;
  ruleImageDetailsOpenSw: boolean;

  constructor(
    private sharedService: SharedService,
    private translateService: TranslateService,
    private catService: CatService,
    private ngRedux: NgRedux<AppState>,
    private revService: RevService
  ) {}

  ngOnInit(): void {}

  viewBox(singlePage: boolean = false) {
    this.docLoading = true;
    this.singlePageSw = singlePage;
    this.curDoc = new Doc();
    this.curDoc.docObjectType = this.docType;
    this.curDoc.docName = this.doc.docName;
    this.curDoc.docId = this.doc.docId;
    this.docLoading = false;
    this.boxAdded = false;
    this.displayDocViewer = true;
  }

  onDocIframeLoaded(doc: CatDoc) {
    if (!this.boxAdded) {
      if (
        this.docViewer.displayedPage == this.box.displayPageNum ||
        this.singlePageSw
      ) {
        this.docViewer.displayBoundingBox(this.box);
        this.boxAdded = true;
      } else {
        this.docViewer.goToPage(this.box.displayPageNum);
      }
    }
  }

  deleteBoxClicked() {
    let validationStr = this.translateService.instant(
      'translate.cat.deleteBoxValidation'
    );

    this.sharedService.emitPopupchReceivedEvent({
      title: 'translate.validation.confirm',
      icon: 'exclamation-triangle',
      subTitle: validationStr,
      keep: false,
      approve: () => {
        this.onDelete.emit();
      },
    });
  }

  onUseForPrecheckChanged(box: BoundingBox) {
    box.dirtySw = true;
    this.catService.updBoundingBox(null, null, box).subscribe((data) => {
      box.dirtySw = false;
    });
  }

  getPhraseMatchStatus(box: BoundingBox, phrase: IndPhrase) {
    let cardMatch = this.getCardMatch(box, phrase);
    if (cardMatch) {
      return cardMatch.matchStatusDescr;
    }
  }

  getImageMatchStatus(box: BoundingBox, image: CardImage) {
    let imageMatch = this.getImageCardMatch(box, image);
    if (imageMatch) {
      return imageMatch.matchStatusDescr;
    }
  }

  getPhraseMatchStatusCode(box: BoundingBox, phrase: IndPhrase) {
    let cardMatch = this.getCardMatch(box, phrase);
    if (cardMatch) {
      return cardMatch.matchStatusCode;
    } else {
      return 'OTHER';
    }
  }

  getImageMatchStatusCode(box: BoundingBox, image: CardImage) {
    let imageMatch = this.getImageCardMatch(box, image);
    if (imageMatch) {
      return imageMatch.matchStatusCode;
    } else {
      return 'OTHER';
    }
  }

  private getCardMatch(box: BoundingBox, phrase: IndPhrase): CardMatch {
    return this.ngRedux
      .getState()
      .revCardMatches.find(
        (m) =>
          m.mCardId == this.card.cardId &&
          m.mBoxId == box.boxId &&
          m.mPhraseId == phrase.phraseId
      );
  }

  private getImageCardMatch(box: BoundingBox, image: CardImage): ImageMatch {
    return this.ngRedux
      .getState()
      .revCardImageMatches.find(
        (m) =>
          m.catBoxId == box.boxId &&
          m.catImageId == image.catCardRefImageId
      );
  }

  viewPhraseDetails(box: BoundingBox, phrase: IndPhrase) {
    let cardMatch = this.getCardMatch(box, phrase);

    this.phraseDetailsLoading = true;
    this.revService
      .getRevRuleSingle(
        this.ngRedux.getState().curRevDoc.docId,
        cardMatch.phraseId
      )
      .subscribe(
        (data) => {
          this.ngRedux.dispatch({
            type: actionsList.SELECT_REV_RULE,
            data: data,
          });
          this.ruleDetailsOpenSw = true;
          this.phraseDetailsLoading = false;
        },
        (err) => {
          this.phraseDetailsLoading = false;
        }
      );
  }

  viewImageDetails(box: BoundingBox, image: CardImage) {
    let imageMatch = this.getImageCardMatch(box, image);
    this.phraseDetailsLoading = true;
    this.revService
      .getRevRuleSingleImage(
        this.ngRedux.getState().curRevDoc.docId,
        imageMatch.imageId
      )
      .subscribe(
        (data) => {
          this.ngRedux.dispatch({
            type: actionsList.SELECT_REV_RULE,
            data: data,
          });
          this.ruleImageDetailsOpenSw = true;
          this.phraseDetailsLoading = false;
        },
        (err) => {
          this.phraseDetailsLoading = false;
        }
      );
  }
}
