import { NgRedux } from '@redux/redux-compatibility.service';
import { Location } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { TableComponent } from 'src/app/shared/components/ui/table/table.component';
import {
  Card,
  CardSearchParams,
  Catalog,
  CatalogStatusEnum,
} from 'src/app/shared/models/cat.model';
import { SearchRunStatusEnum } from 'src/app/shared/models/common';
import { keyCodes } from 'src/app/shared/models/key-codes.model';
import { actionsList, AppState } from 'src/app/shared/redux/store';
import { AuthService } from 'src/app/shared/services/auth.service';
import { CatService } from 'src/app/shared/services/cat.service';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'cat-cards',
  templateUrl: './cards.component.html',
  styleUrls: ['./cards.component.scss'],
})
export class CardsComponent implements OnInit {
  @Input() selectMode: boolean;
  @Output() onCardsSelected = new EventEmitter<Card[]>();
  @ViewChild('tbl') tblCards: TableComponent;

  loading: boolean;
  displayAddToTemplate: boolean;
  getDataSub: Subscription;
  searchStatusTimer: any;
  searchStatusIntervalSec: number = 1;
  sub: Subscription;
  selectedCards: Card[] = [];
  filterTitle: string;

  get cards(): Card[] {
    return this.ngRedux.getState().cards;
  }

  get curCat(): Catalog {
    return this.ngRedux.getState().curCat;
  }

  get allowEdit(): boolean {
    if (!this.curCat) return false;
    return this.curCat.statusCode == CatalogStatusEnum.DRAFT;
  }

  get allowSelection(): boolean {
    return this.selectMode || this.allowEdit;
  }

  get displayClearFilter(): boolean {
    return this.filterTitle != null;
  }

  constructor(
    private ngRedux: NgRedux<AppState>,
    private catService: CatService,
    private router: Router,
    private sharedService: SharedService,
    private authService: AuthService,
    private location: Location,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.getCards();
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
    this.getDataSub?.unsubscribe();
  }

  getCards() {
    this.getDataSub?.unsubscribe();
    this.loading = true;
    let sp = new CardSearchParams();
    sp.title = this.filterTitle;
    sp.dispatch = true;
    this.getDataSub = this.catService.getCardsAsync(sp).subscribe(
      (searchId) => {
        console.log(searchId);
        this.pullSearchStatus(
          searchId,
          () => {
            this.catService.getCardsSearch(searchId, sp.dispatch).subscribe(
              (data) => {
                this.loading = false;
              },
              (err) => {
                this.loading = false;
              }
            );
          },
          () => {
            this.loading = false;
            this.sharedService.alertError(
              this.translateService.instant('translate.alert.searchError'),
              null,
              null,
              'get_cat_cards_func'
            );
          }
        );
      },
      (err) => {
        this.loading = false;
      }
    );
  }

  pullSearchStatus(searchId: number, onFinished, onError) {
    this.sub?.unsubscribe();
    this.sub = this.authService.getSearchStatus(searchId).subscribe(
      (status) => {
        if (status == SearchRunStatusEnum.FINISHED) {
          onFinished();
        } else if (status == SearchRunStatusEnum.ERROR) {
          onError();
        } else {
          setTimeout(() => {
            this.pullSearchStatus(searchId, onFinished, onError);
          }, this.searchStatusIntervalSec * 1000);
        }
      },
      (err) => {
        onError();
      }
    );
  }

  clearFilter() {
    this.filterTitle = null;
    this.getCards();
  }

  view(card: Card) {
    this.openCard(card);
  }

  createCard() {
    let card = new Card();
    this.openCard(card);
  }

  openCard(card: Card) {
    this.ngRedux.dispatch({
      type: actionsList.SELECT_CAT_CARD,
      data: card,
    });

    this.router.navigate([this.sharedService.routeEnum.URL_CATALOG_CARD]);
  }

  goBack() {
    this.location.back();
  }

  onSelectionChanged(rows: Card[]) {
    this.selectedCards = rows;
  }

  cardsSelected() {
    this.onCardsSelected.emit(this.selectedCards);
  }

  addToTemplate() {
    this.displayAddToTemplate = true;
  }

  afterAddToTemplate() {
    this.displayAddToTemplate = false;
    this.selectedCards = [];
    this.tblCards?.resetSelection();
  }

  onFilterKeyPress(e: any) {
    if (e.keyCode == keyCodes.enter) {
      this.getCards();
    }
  }
}
