import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { NgRedux } from '@redux/redux-compatibility.service';

import { AppState, actionsList } from '../redux/store';
import { AuthService } from './auth.service';
import { toCamelCaseProperties, toSnakeCaseProperties } from '../utils';

import { Config } from 'src/app/config';
import { ConfigScope, IIntegrationTypeSchema, IIntegrationType, IntegrationType, ICatalogPublishResult } from '../models/integration-type.model';
import { ServiceCallInfo } from '../models/common';

import { SharedService } from 'src/app/shared/services/shared.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class IntegrationService {
  mockupSw = true;
  scaiServiceUrl: string;
  private mockupDataUrl = 'assets/mock/';

  constructor(
    private ngRedux: NgRedux<AppState>,
    private http: HttpClient,
    private authService: AuthService,
    private sharedService: SharedService,
    private translateService: TranslateService
  ) {
    this.scaiServiceUrl = Config.assetConfigData['scaiServiceUrl'];
  }

  get curCatIntegrationConfig(): any {
    return this.ngRedux.getState().curCatIntegrationConfig;
  }

  getIntegrationTypes(envId?: number, shouldUpdateStore: boolean = true): Observable<IIntegrationType[]> {
    const url = this.scaiServiceUrl + `catalogs/integration/types?env_id=${envId}`;

    return this.http
      .get(url, {
        headers: this.authService.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json'
      })
      .pipe(
        map((data) => {
          data = toCamelCaseProperties(data);

          return this.extractIntegrationTypes(data, shouldUpdateStore);
        }),
        catchError((err) => {
          return this.handleError(err, url)
        })
      );
  }

  private extractIntegrationTypes(res, shouldUpdateStore: boolean = true): IIntegrationType[] {
    const integrationTypes: IIntegrationType[] = res.integrationTypes;
    const configuredIntegrationType: IIntegrationType = this.extractConfiguredIntegrationType(integrationTypes);

    if (shouldUpdateStore) {
      this.ngRedux.dispatch({
        type: actionsList.GET_INTEGRATION_TYPES,
        data: integrationTypes,
        reply: res
      });

      this.ngRedux.dispatch({
        type: actionsList.CONFIGURED_INTEGRATION_TYPE,
        data: configuredIntegrationType ? configuredIntegrationType.type : null,
        reply: integrationTypes
      });
    }

    return integrationTypes;
  }

  private extractConfiguredIntegrationType(integrationTypes: IIntegrationType[]): IIntegrationType {
    return integrationTypes ? integrationTypes.find((integrationType: IIntegrationType) => {
      return integrationType.isConfigured;
    }) : null;
  }

  getIntegrationConfig(
    integrationType: string,
    configScope = ConfigScope.Library,
    environmentId?: number,
    entityId?: number
  ): Observable<IIntegrationTypeSchema[]> {
    const url = this.scaiServiceUrl + `catalogs/integrations/${integrationType}/${configScope}/schema?env_id=${environmentId}&entity_id=${entityId}`;

    return this.http
      .get(url, {
        headers: this.authService.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json'
      })
      .pipe(
        map((data) => {
          data = toCamelCaseProperties(data);

          return this.extractIntegrationConfig(data, configScope);
        }),
        catchError((err) => {
          return this.handleError(err, url)
        })
      );
  }

  private extractIntegrationConfig(res, configScope: ConfigScope): IIntegrationTypeSchema[] {
    const result: IIntegrationTypeSchema[] = res.fields;
    let action: string;

    result.forEach(item => {
      // Since configuration contains data, map data as default values
      item.defaultValue = item.configuredValue ?? item.defaultValue;
      // Convert to boolean
      item.isRequired = typeof item.isRequired == "boolean" ? item.isRequired : (item.isRequired as any) === 'Y';
      item.isSecret = typeof item.isSecret == "boolean" ? item.isSecret : (item.isSecret as any) === 'Y';
      item.isVisible = typeof item.isVisible == "boolean" ? item.isVisible : (item.isVisible as any) === 'Y';
    });

    switch (configScope) {
      case ConfigScope.Library:
        this.ngRedux.dispatch({
          type: actionsList.GET_CATALOG_INTEGRATION_CONFIG,
          data: result,
          reply: res,
        });

        break;
    }

    return result;
  }

  saveIntegrationConf(config: IIntegrationTypeSchema[], formData: any, entityId: number): Observable<boolean> {
    const url = this.scaiServiceUrl + 'catalogs/integration/config';
    const data = {
      field_values: config.map((fieldConfig: IIntegrationTypeSchema) => {
        return {
          id: fieldConfig.id,
          entityId: entityId,
          value: formData[fieldConfig.key],
          isSecret: fieldConfig.isSecret ? 'Y' : 'N'
        };
      })
    };
    const submitData = toSnakeCaseProperties(data);

    return this.http
      .post(url, submitData, {
        headers: this.authService.getRequestHeaders(),
        withCredentials: false,
        observe: 'response',
        responseType: 'json'
      })
      .pipe(
        map((response) => {
          if (this.curCatIntegrationConfig) {
            this.curCatIntegrationConfig.forEach((item) => {
              const fieldConfig = config.find((fieldConfig: IIntegrationTypeSchema) => {
                return fieldConfig.id === item.id;
              });

              if (fieldConfig) {
                item.configuredValue = formData[fieldConfig.key];
              }
            })

            this.extractIntegrationConfig({fields: this.curCatIntegrationConfig}, ConfigScope.Library);
          }

          return response.status == 201;
        }),
        catchError((err) => {
          return this.handleError(err, url)
        })
      );
  }

  private extractSaveIntegrationConf(submitData, response): void {
    this.ngRedux.dispatch({
      type: actionsList.GET_CATALOG_INTEGRATION_CONFIG,
      data: submitData,
      reply: response,
    });
  }

  addCatalogIntegrationType(environmentId: number, integrationType: string): Observable<boolean> {
    const url = this.scaiServiceUrl + `environments/${environmentId}/catalogs/integration/type`;
    const submitData = {
      integration_type: integrationType
    };

    return this.http
      .post(url, submitData, {
        headers: this.authService.getRequestHeaders(),
        withCredentials: false,
        observe: 'response',
        responseType: 'json'
      })
      .pipe(
        map((response) => {
          return response.status == 201;
        }),
        catchError((err) => {
          return this.handleError(err, url)
        })
      );
  }

  deleteCatalogIntegrationType(environmentId: number): Observable<boolean> {
    const url = this.scaiServiceUrl + `environments/${environmentId}/catalogs/integration/type`;

    return this.http
      .delete(url, {
        headers: this.authService.getRequestHeaders(),
        withCredentials: false,
        observe: 'response'
      })
      .pipe(
        map((response) => {
          return response.status == 200;
        }),
        catchError((err) => {
          return this.handleError(err, url)
        })
      );
  }

  publishCatalogVersion(catalogVersionId: number): Observable<ICatalogPublishResult> {
    const url = this.scaiServiceUrl + `catalogs/${catalogVersionId}/initiate-publish-external`;

    return this.http
      .post(url, {}, {
        headers: this.authService.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json'
      })
      .pipe(
        map((response) => {
          return toCamelCaseProperties(response);
        }),
        catchError((err) => {
          return this.handleError(err, url)
        })
      );
  }

  pullExternal(catalogVersionId: number, entityId?: number): Observable<ICatalogPublishResult> {
    const url = this.scaiServiceUrl + `catalogs/${catalogVersionId}/init-pull-claims`;

    return this.http
      .post(url, {}, {
        headers: this.authService.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json'
      })
      .pipe(
        map((response) => {
          let infoMsg = this.translateService.instant(
            'translate.alert.externalPullStarted'
          );
          this.sharedService.alertInfo(infoMsg);
          return toCamelCaseProperties(response);
        }),
        catchError((err) => {
          return this.handleError(err, url)
        })
      );
  }

  finalizePrecheck(documentId: number): Observable<ICatalogPublishResult> {
    const url = this.scaiServiceUrl + `/precheck/initiate-finalize/${documentId}`;

    return this.http
      .post(url, {}, {
        headers: this.authService.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json'
      })
      .pipe(
        map((response) => {
          return toCamelCaseProperties(response);
        }),
        catchError((err) => {
          return this.handleError(err, url)
        })
      );
  }

  addVariantsToLibrary(catalogVersionId: number, documentId: number): Observable<ICatalogPublishResult> {
    const url = this.scaiServiceUrl + `catalogs/${catalogVersionId}/add_to_library/${documentId}`;

    return this.http
      .post(url, {}, {
        headers: this.authService.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json'
      })
      .pipe(
        map((response) => {
          return toCamelCaseProperties(response);
        }),
        catchError((err) => {
          return this.handleError(err, url)
        })
      );
  }

  private handleError(error: any, url: string): Observable<never> {
    const info = new ServiceCallInfo(url, url, null);
    error.message = error.error.description || error.statusText;

    return this.authService.handleError(error, info);
  }

}

