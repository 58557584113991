import { NgRedux } from '@redux/redux-compatibility.service';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import {
  Card,
  Catalog,
  CatalogStatusEnum,
  IndPhrase,
} from 'src/app/shared/models/cat.model';
import { RouteEnum } from 'src/app/shared/models/routes.model';
import { actionsList, AppState } from 'src/app/shared/redux/store';
import { AuthService } from 'src/app/shared/services/auth.service';
import { CatService } from 'src/app/shared/services/cat.service';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'app-card-entries',
  templateUrl: './card-entries.component.html',
  styleUrls: ['./card-entries.component.scss'],
})
export class CardEntriesComponent implements OnInit {
  routeEnum: RouteEnum = new RouteEnum();
  phraseDetailsOpenSw: boolean;

  get card(): Card {
    return this.ngRedux.getState().curCard;
  }

  get entries(): IndPhrase[] {
    return this.card.cardPhrases;
  }

  get curCat(): Catalog {
    return this.ngRedux.getState().curCat;
  }

  get allowEdit(): boolean {
    if (!this.curCat) return false;
    return this.curCat.statusCode == CatalogStatusEnum.DRAFT;
  }

  constructor(
    private ngRedux: NgRedux<AppState>,
    private catService: CatService,
    private sharedService: SharedService,
    private authService: AuthService,
    private router: Router
  ) {}

  ngOnInit(): void {}

  goToAdd() {
    this.ngRedux.dispatch({
      type: actionsList.UI__SET_ACTIVE_TABS,
      data: {
        link: this.routeEnum.URL_CATALOG_CARD_ADD_ENTRIES,
      },
    });
  }

  view(entry: IndPhrase) {
    this.ngRedux.dispatch({
      type: actionsList.SELECT_CAT_ELEMENT,
      data: entry,
    });
    this.phraseDetailsOpenSw = true;
  }

  remove(entry: IndPhrase) {
    let idx = this.card.cardPhrases.indexOf(entry);
    if (idx != -1) {
      this.card.cardPhrases.splice(idx, 1);
    }
  }

  onPhraseSaved() {
    this.phraseDetailsOpenSw = false;
  }

  onEntryDeleted(entry: IndPhrase) {
    this.phraseDetailsOpenSw = false;
    if (!entry) {
      entry = this.ngRedux.getState().curCatElement as IndPhrase;
    }
    this.remove(entry);
  }

  getEntryIndex(entry: IndPhrase): number {
    return this.entries.indexOf(entry);
  }

  onReorder(event: any) {
    this.entries.map((e) => {
      e.sortOrder = this.getEntryIndex(e);
    });
  }
}
