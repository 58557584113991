import { NgRedux } from '@redux/redux-compatibility.service';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { ProcessRunStatusEnum } from 'src/app/shared/models/common';
import { RevDocsSearchParams } from 'src/app/shared/models/rev.model';
import { AppState } from 'src/app/shared/redux/store';
import { AuthService } from 'src/app/shared/services/auth.service';
import { CatService } from 'src/app/shared/services/cat.service';
import { RevService } from 'src/app/shared/services/rev.service';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'app-dl-rev-ann',
  templateUrl: './dl-rev-ann.component.html',
  styleUrls: ['./dl-rev-ann.component.scss'],
})
export class DlRevAnnComponent implements OnInit {
  downloadingFile: boolean = false;
  processStatusTimer: NodeJS.Timeout;
  getProcessStatusIntervalSeconds: number = 2;
  docPdfUrl: string =
    'https://s3.eu-central-1.amazonaws.com/scai.qa.tmpdocs.com/db4ab5f0-7f19-4940-900d-4db9f3c13838/annotate_precheck/5054ab3a-90ff-11ed-9641-f9f98cb65d8b.pdf';
  iframeUrl: SafeResourceUrl;

  constructor(
    private ngRedux: NgRedux<AppState>,
    private revService: RevService,
    private authService: AuthService,
    private catService: CatService,
    private translateService: TranslateService,
    private sharedService: SharedService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.downloadAnnotatedDoc();

    // this.iframeUrl =
    //   this.sanitizer.bypassSecurityTrustResourceUrl(this.docPdfUrl) +
    //   '&embedded=true';
  }

  downloadAnnotatedDoc() {
    this.downloadingFile = true;

    let sp = new RevDocsSearchParams();
    sp.docId = this.ngRedux.getState().dlRevDoc.docId;
    sp.rangeFrom = 1;
    sp.rangeTo = 2;

    this.revService.getRevDocs(sp).subscribe(
      (data) => {
        if (data.length > 0) {
          let doc = data[0];
          this.revService.createAnnotateFileReq(doc).subscribe(
            (runNum) => {
              this.revService.processAnnotatePrecheck(runNum).subscribe(
                (data) => {},
                (err) => {
                  this.downloadFileError(err);
                }
              );

              this.processStatusTimer = setInterval(() => {
                this.authService.getProcessStatus(runNum).subscribe(
                  (data) => {
                    if (data.status == ProcessRunStatusEnum.FINISHED) {
                      clearInterval(this.processStatusTimer);
                      this.catService
                        .getS3Objects([data.outDocS3Key], true)
                        .subscribe(
                          (data2) => {
                            this.downloadingFile = false;
                            let url = this.catService.getS3TempObjectPresignedUrl(data.outDocS3Key);
                            console.log(url);
                            var element = document.createElement('a');
                            element.href = url;
                            element.setAttribute('target', '_self');
                            document.body.appendChild(element);
                            element.click();
                            document.body.removeChild(element);
                            this.docPdfUrl = url;
                          },
                          (err) => {
                            this.downloadFileError(err);
                          }
                        );
                    } else if (data.status == ProcessRunStatusEnum.FAILED) {
                      this.downloadFileError(
                        this.translateService.instant(
                          'translate.cat.annotateProcessFailed'
                        )
                      );
                    }
                  },
                  (err) => {
                    this.downloadFileError(err);
                  }
                );
              }, this.getProcessStatusIntervalSeconds * 1000);
            },
            (err) => {
              this.downloadFileError(err);
            }
          );
        } else {
          this.downloadFileError('Document not found');
        }
      },
      (err) => {
        this.downloadFileError('Document not found');
      }
    );
  }

  downloadFileError(err) {
    clearInterval(this.processStatusTimer);
    this.downloadingFile = false;
    this.sharedService.alertError(err);
  }
}
