import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgRedux } from '@redux/redux-compatibility.service';
import { AppState, actionsList } from 'src/app/shared/redux/store';
import { Tab } from 'src/app/shared/models/tab.model';
import { SharedService } from 'src/app/shared/services/shared.service';
import { MenuItem, PrimeIcons } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  items: MenuItem[];

  constructor(
    private router: Router,
    private ngRedux: NgRedux<AppState>,
    private sharedService: SharedService,
    private translateService: TranslateService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.ngRedux.subscribe(() => {
      const appState = this.ngRedux.getState();
      switch (appState._currentAction) {
        case actionsList.GET_USER_DETAILS:
          this.initNavItems();
          break;
      }
    });

    this.initNavItems();
  }

  initNavItems() {
    this.items = [
      {
        label: this.translateService.instant('translate.sidebar.catalog'),
        icon: PrimeIcons.BOOK,
        routerLinkActiveOptions: { exact: true },
        command: (event) => {
          this.sharedService.putCookie('menu', 'cat', false);
          this.onMenuLinkClicked(
            this.sharedService.routeEnum.URL_CATALOG_LIST,
            this.translateService.instant('translate.sidebar.catalog')
          );
        },
        visible: this.authService.currentUser.authDetails.catScreenAllowed(),
      },
      {
        label: this.translateService.instant('translate.sidebar.review'),
        icon: 'pi pi-fw pi-check-circle',
        routerLinkActiveOptions: { exact: true },
        command: (event) => {
          this.sharedService.putCookie('menu', 'rev', false);
          this.onMenuLinkClicked(
            this.sharedService.routeEnum.URL_REV_DOC_LIST,
            this.translateService.instant('translate.sidebar.review')
          );
        },
        visible: this.authService.currentUser.authDetails.revScreenAllowed(),
      },
      {
        label: this.translateService.instant('translate.lc.labelChange'),
        icon: 'pi pi-fw pi-file',
        routerLinkActiveOptions: { exact: true },
        command: (event) => {
          this.sharedService.putCookie(
            'menu',
            this.sharedService.routeEnum.URL_LC,
            false
          );
          this.onMenuLinkClicked(
            this.sharedService.routeEnum.URL_LC,
            this.translateService.instant('translate.lc.labelChange')
          );
        },
        visible: this.authService.currentUser.authDetails.lcScreenAllowed(),
      },
      {
        label: this.translateService.instant('translate.cat.extLibList'),
        icon: 'pi pi-fw pi-bookmark-fill',
        routerLinkActiveOptions: { exact: true },
        command: (event) => {
          this.sharedService.putCookie(
            'menu',
            this.sharedService.routeEnum.URL_EXT_CATALOG_LIST,
            false
          );
          this.onMenuLinkClicked(
            this.sharedService.routeEnum.URL_EXT_CATALOG_LIST,
            this.translateService.instant('translate.cat.extLibList')
          );
        },
        visible: this.authService.currentUser.authDetails.catScreenAllowed(),
      },
      {
        label: this.translateService.instant('translate.sidebar.manage'),
        visible: this.authService.currentUser.isAdmin,
        items: [
          {
            label: this.translateService.instant('translate.sidebar.mngUsers'),
            command: (event) => {
              this.sharedService.putCookie(
                'menu',
                this.sharedService.routeEnum.URL_MNG_USERS,
                false
              );
              this.onMenuLinkClicked(
                this.sharedService.routeEnum.URL_MNG_USERS,
                this.translateService.instant('translate.sidebar.manage')
              );
            },
            routerLinkActiveOptions: { exact: true },
          },
          {
            label: this.translateService.instant('translate.sidebar.mngEnv'),
            command: (event) => {
              this.sharedService.putCookie(
                'menu',
                this.sharedService.routeEnum.URL_MNG_ENV,
                false
              );
              this.onMenuLinkClicked(
                this.sharedService.routeEnum.URL_MNG_ENV,
                this.translateService.instant('translate.sidebar.manage')
              );
            },
            routerLinkActiveOptions: { exact: true },
          },
          {
            label: this.translateService.instant(
              'translate.sidebar.mngProducts'
            ),
            command: (event) => {
              this.sharedService.putCookie(
                'menu',
                this.sharedService.routeEnum.URL_MNG_PRODUCTS,
                false
              );
              this.onMenuLinkClicked(
                this.sharedService.routeEnum.URL_MNG_PRODUCTS,
                this.translateService.instant('translate.sidebar.manage')
              );
            },
            routerLinkActiveOptions: { exact: true },
          },
          {
            label: this.translateService.instant(
              'translate.general.assetTypes'
            ),
            command: (event) => {
              this.sharedService.putCookie(
                'menu',
                this.sharedService.routeEnum.URL_MNG_ASSET_TYPES,
                false
              );
              this.onMenuLinkClicked(
                this.sharedService.routeEnum.URL_MNG_ASSET_TYPES,
                this.translateService.instant('translate.sidebar.manage')
              );
            },
            routerLinkActiveOptions: { exact: true },
          },
          {
            label: this.translateService.instant(
              'translate.general.taggedDocs'
            ),
            command: (event) => {
              this.sharedService.putCookie(
                'menu',
                this.sharedService.routeEnum.URL_MNG_TAGGED_DOCS,
                false
              );
              this.onMenuLinkClicked(
                this.sharedService.routeEnum.URL_MNG_TAGGED_DOCS,
                this.translateService.instant('translate.sidebar.manage')
              );
            },
            routerLinkActiveOptions: { exact: true },
          },
        ],
      },
    ];
  }

  isEnabled(tab: Tab): boolean {
    return true;
  }

  onMenuLinkClicked(url: string, title: string) {
    this.sharedService.clearLastCatCookies();
    this.router.navigate([url]);
    this.ngRedux.dispatch({
      type: actionsList.UI__SIDEBAR_ROUTE_NAVIGATION,
      data: title,
    });
    this.ngRedux.dispatch({
      type: actionsList.UI__ROUTE_NAVIGATION,
      data: url,
    });
  }
}
