<modal-popup
  [flexible]="true"
  [focusOnOpen]="false"
  [isOpen]="true"
  (onClose)="onClose()"
  [title]="'translate.cat.configureIntegration'"
>
  <div class="container">

    <!--Integration Type-->
    <input-wrap
      label="{{ 'translate.cat.configureIntegration.integrationType' | translate }}"
      cssClass="l-cell-spread"
      [loading]="integrationTypeLoading"
    >
      <p-dropdown
        [options]="integrationTypeOptions"
        [(ngModel)]="selectedIntegrationTypeOption"
        [disabled]="isIntegrationTypesDropdownDisabled"
        [placeholder]="(integrationTypeLoading ? 'translate.general.loading' : 'translate.general.select') | translate"
        [filter]="true"
        [showClear]="true"
        filterBy="id"
        name="selectedIntegrationTypeOption"
        optionLabel="text"
        appendTo="body"
        class="l-cell-spread-x"
        baseZIndex="100"
        (onChange)="onIntegrationTypeChange($event)"
      >
      </p-dropdown>
    </input-wrap>

    <scai-dynamic-form *ngIf="selectedIntegrationTypeOption"
      [loading]="formLoading"
      [formFieldConfig]="integrationConfig"
      [formData]="data">
    </scai-dynamic-form>

    <div class="actions">
      <button class="t-button-outline" (click)="onClose()">
        {{ "translate.general.cancel" | translate }}
      </button>
      &nbsp;
      <button
        class="t-button"
        [disabled]="!enableSubmit || saving"
        (click)="onSave()"
      >
        {{ "translate.general.ok" | translate }}
      </button>
    </div>
  </div>

  <loader *ngIf="saving"></loader>
</modal-popup>
