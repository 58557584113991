<modal-popup
  [flexible]="true"
  [focusOnOpen]="false"
  [isOpen]="true"
  (onClose)="close()"
  [title]="'translate.cat.addBulkDocuments'"
>
  <div class="container">
    <!--asset type-->
    <input-wrap
      label="{{ 'translate.general.assetType' | translate }}"
      cssClass="l-cell-spread"
    >
      <p-dropdown
        [options]="assetTypes"
        optionLabel="text"
        [(ngModel)]="assetType"
        class="l-cell-spread-x"
        [placeholder]="'translate.general.select' | translate"
        appendTo="body"
        baseZIndex="100"
      >
      </p-dropdown>
    </input-wrap>

    <!--file upload-->
    <div class="upload-container">
      <p-fileUpload
        multiple="true"
        accept=".pdf"
        customUpload="true"
        [showUploadButton]="false"
        [showCancelButton]="false"
        chooseLabel="{{ 'translate.general.selectFiles' | translate }}"
        (onSelect)="onFilesSelect($event)"
      >
        <ng-template let-file pTemplate="file"> </ng-template>
        <ng-template pTemplate="content" let-files></ng-template>
      </p-fileUpload>

      <div class="files-container">
        <ui-table
          *ngIf="files && files.length"
          class="dense"
          [isWrapped]="false"
          [data]="files"
          [templates]="[
            {
              title: 'translate.general.docName',
              template: docName
            },
            {
              title: 'translate.general.parentDoc',
              template: parentDoc
            },
            {
              template: status,
              colClass: 'l-ta-center'
            }
          ]"
        ></ui-table>

        <ng-template #docName let-row="row">
          <input-wrap [required]="true" cssClass="l-cell-spread">
            <input
              type="text"
              [(ngModel)]="row.docName"
              class="t-input"
              [disabled]="row.uploading || row.finished"
            />
          </input-wrap>
        </ng-template>

        <ng-template #parentDoc let-row="row">
          <input-wrap cssClass="l-cell-spread">
            <p-dropdown
              [options]="parentDocList"
              optionLabel="docName"
              [(ngModel)]="row.parentDoc"
              class="l-cell-spread-x"
              [resetFilterOnHide]="true"
              [filterPlaceholder]="'translate.general.search' | translate"
              [placeholder]="'translate.general.select' | translate"
              [filter]="true"
              appendTo="body"
              baseZIndex="100"
              [showClear]="true"
              [disabled]="row.uploading || row.finished"
            >
              <ng-template let-el pTemplate="item">
                <div class="parent-doc-li">
                  <div class="first-row l-align-spaceBetween">
                    <p>{{ el.docName }}</p>
                  </div>
                  <div class="t-footnote l-align-spaceBetween">
                    <span class="t-footnote"> {{ el.docId }}</span>
                    {{ el.createDate }}
                  </div>
                </div>
              </ng-template>
            </p-dropdown>
          </input-wrap>
        </ng-template>

        <ng-template #status let-row="row">
          <div class="l-ta-center">
            <i
              *ngIf="!row.uploading && !row.finished"
              class="fas fa-times c-warn"
              (click)="removeFile(row)"
            ></i>

            <i *ngIf="row.finished" class="fas fa-check c-success"></i>

            <spinner *ngIf="row.uploading"></spinner>
          </div>
        </ng-template>
      </div>
    </div>

    <div class="actions">
      <button class="t-button-outline" (click)="close()">
        {{ "translate.general.cancel" | translate }}
      </button>
      &nbsp;
      <button
        class="t-button"
        [disabled]="!enableSubmit || saving"
        (click)="createDocs()"
      >
        {{ "translate.general.ok" | translate }}
      </button>
    </div>
  </div>
</modal-popup>
