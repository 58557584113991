<div class="phrase-cat-match-container">
  <div class="phrase-cat-diff-container" style="position: relative;">
    <!-- Diff container -->
    <div class="text">
      <div *ngIf="catMatch.diffParts?.length > 0; else originalText">
        <span *ngIf="!showOriginal">
          <span *ngFor="let word of catMatch.diffParts" class="match-diff" [class.missing]="word.diffType == 'MISSING'"
            [class.extra]="word.diffType == 'EXTRA'">
            {{ word.text }}
          </span>
        </span>
        <span *ngIf="showOriginal">{{ catMatch.catPhrase.phraseText }}</span>
      </div>
      <ng-template #originalText>
        <span>{{ catMatch.catPhrase.phraseText }}</span>
      </ng-template>
    </div>
    <div class="l-align-spaceBetween">
      <!--type-->
      <div *ngIf="catMatch.catPhrase" class="cat-phrase-type">
        <span>
          {{ catMatch.catPhrase.typeDescr }}
          <span class="sub-type" *ngIf="catMatch.catPhrase.subTypeDescr">&middot; {{ catMatch.catPhrase.subTypeDescr
            }}</span>
        </span>
      </div>

      <p class="match-id">
        <span *ngIf="catMatch.catPhrase">
          {{ "translate.cat.phraseId" | translate }}:
          {{ catMatch.catPhrase.phraseId }}</span>
        &nbsp;
        {{ "translate.cat.matchSeq" | translate }}: {{ catMatch.catMatchSeq }}
      </p>
    </div>
  </div>
  <div *ngIf="catMatch.diffParts?.length > 0">
    <div *ngIf="!showOriginal">
      <button (click)="showOriginal = !showOriginal" class="eye-toggle"
        tooltip="{{ 'translate.rev.showOriginal' | translate }}">
        <i class="fas fa-eye"></i>
      </button>
    </div>
    <div *ngIf="showOriginal">
      <button (click)="showOriginal = !showOriginal" class="eye-toggle"
        tooltip="{{ 'translate.rev.showDiff' | translate }}">
        <i class="fas fa-eye-slash"></i>
      </button>
    </div>
  </div>
</div>

<!--docs-->
<div *ngIf="
    (catMatch.catPhrase &&
    catMatch.catPhrase.docs &&
    catMatch.catPhrase.docs.length > 0)
  " class="docs-container t-small">
  <a (click)="displayDocsSw = !displayDocsSw">
    {{ "translate.cat.matchSourceDocs" | translate }}
  </a>
  <slide-in-out [isOpen]="displayDocsSw">
    <div class="docs-tbl">
      <cat-entry-docs *ngIf="catMatch.catPhrase" [docs]="catMatch.catPhrase.docs"></cat-entry-docs>
    </div>
  </slide-in-out>
</div>