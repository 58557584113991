<section class="page-header">
  <div class="title-container">
    <h1>{{ "translate.tagDocs.taggedDocs" | translate }}</h1>
  </div>
</section>

<div class="filter-bar l-align-spaceBetween">
  <div class="filters">
    <input-wrap [loading]="envList && envList.loading">
      <p-dropdown
        [options]="envList"
        optionLabel="descr"
        [(ngModel)]="filterEnv"
        [resetFilterOnHide]="true"
        [filterPlaceholder]="'translate.general.search' | translate"
        [placeholder]="'translate.general.env' | translate"
        [filter]="true"
        (onChange)="onFieldValueChanged($event)"
      >
      </p-dropdown>
    </input-wrap>

    <input-wrap class="t-input-search-wrap">
      <input
        class="t-input"
        type="text"
        [(ngModel)]="filterDocIdName"
        placeholder="{{ 'translate.general.filterDocIdName' | translate }}"
        (keypress)="onFilterKeyPress($event)"
      />
    </input-wrap>

    <a *ngIf="displayClearFilter" (click)="clearFilter()">{{
      "translate.general.clear" | translate
    }}</a>
  </div>
</div>

<tabs>
  <tab-single
    tabTitle="{{ 'translate.tagDocs.catDocs' | translate }}"
    [tabId]="tabIdList.TAG_DOCS_IND"
  >
    <cat-tag-docs [type]="'IND'"></cat-tag-docs>
  </tab-single>

  <tab-single
    tabTitle="{{ 'translate.tagDocs.revDocs' | translate }}"
    [tabId]="tabIdList.TAG_DOCS_REV"
  >
    <cat-tag-docs [type]="'REV'"></cat-tag-docs>
  </tab-single>
</tabs>
