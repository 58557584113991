<div class="tile-container" [class.selected]="element.checkedSw">
  <checkbox-wrap class="checkbox">
    <input type="checkbox" [(ngModel)]="element.checkedSw" />
  </checkbox-wrap>

  <div class="id">
    {{ "translate.general.id" | translate }}: {{ element.annIdPrefix
    }}{{ element.elementId }}

    <span *ngIf="displayPageNum"> | </span>
    <span *ngIf="displayPageNum && element.pageNum == curPage">
      {{ "translate.general.page" | translate }}: {{ element.pageNum }}</span
    >

    <a
      *ngIf="displayPageNum && element.pageNum != curPage"
      (click)="goToPage()"
    >
      {{ "translate.general.page" | translate }}: {{ element.pageNum }}</a
    >
  </div>

  <div *ngIf="phrase" class="phrase">
    <p class="t-footnote">
      {{ phrase.phraseContentTypeDesc }}
    </p>
    <formatted-phrase [phraseWords]="phrase.phraseWords"></formatted-phrase>
  </div>

  <div *ngIf="indImage" class="phrase">
    <p class="t-footnote figure-type">
      {{ indImage.figureTypeDescr }}
      <span *ngIf="indImage.figureSubType"
        >&middot; {{ indImage.figureSubType.text }}</span
      >
    </p>
    <async-img [className]="'ind-img'" [url]="indImage.imgUrl"></async-img>
  </div>

  <div *ngIf="showMatchSw" class="match-type">
    <button *ngIf="phrase" [ngClass]="['match-type-descr', phrase.phraseSimilarityEvent || element.similarityCode || '']" (click)="openPhraseDetails($event)">
      {{ phrase.phraseSimilarityEventDesc || element.similarityDescr }}
    </button>
    <button *ngIf="indImage" [ngClass]="['match-type-descr', indImage.imageSimilarityEvent || element.similarityCode]" (click)="openPhraseDetails($event)">
      {{ indImage.imageSimilarityEventDesc || element.similarityDescr }}
    </button>
    <div>
      <i *ngIf="!!libraryMismatch" class="task"  tooltip="{{ libraryMismatch[1] | translate }}" tooltipPosition="bottom"></i>
      <i *ngIf="!!referenceIssues" class="rule" tooltip="{{ referenceIssues[1] }}" tooltipPosition="bottom"></i>
      <i *ngIf="!!contentPartMismatch" class="warning" tooltip="{{ contentPartMismatch[1] }}" tooltipPosition="bottom"></i>
      <i *ngIf="!!spellingGrammarIssues" class="spellcheck" tooltip="{{ spellingGrammarIssues[1] }}" tooltipPosition="bottom"></i>  
      <i *ngIf="!!formatMismatch" class="format" tooltip="{{ formatMismatch[1] }}" tooltipPosition="bottom"></i>  
    </div>
  </div>

  <p
    *ngIf="element.statusCode && showMatchSw"
    [ngClass]="['cat-phrase-status', element.statusCode]"
  >
    {{ element.statusDescr }}
  </p>
  <spinner
    *ngIf="!element.statusCode"
    class="cat-phrase-status-spinner"
  ></spinner>

  <div
    *ngIf="phrase && phrase.associatedText.length > 0"
    class="associatedText"
  >
    <p class="title">{{ "translate.general.associatedText" | translate }}:</p>
    <div *ngFor="let assocPhrase of phrase.associatedText" class="ref-container">
      <p *ngIf="assocPhrase.phraseDispWords.length == 0" class="text">
        {{ assocPhrase.assocPhraseText }}
      </p>
      <formatted-phrase
        *ngIf="assocPhrase.phraseDispWords.length > 0"
        class="text"
        [phraseWords]="assocPhrase.phraseDispWords"
      ></formatted-phrase>
    </div>
  </div>

  <div
    *ngIf="phrase && phrase.references.length > 0"
    class="reference"
  >
    <p class="title">{{ "translate.rev.reference" | translate }}:</p>
    <div *ngFor="let ref of phrase.references" class="ref-container">
      <p *ngIf="ref.phraseDispWords.length == 0" class="text">
        {{ ref.refPhraseText }}
      </p>
      <formatted-phrase
        *ngIf="ref.phraseDispWords.length > 0"
        class="text"
        [phraseWords]="ref.phraseDispWords"
      ></formatted-phrase>
    </div>
  </div>

  <div
    *ngIf="phrase && phrase.associatedBy.length > 0"
    class="associatedBy"
  >
    <p class="title">{{ "translate.general.associatedBy" | translate }}:</p>
    <div *ngFor="let assocPhrase of phrase.associatedBy" class="ref-container">
      <p *ngIf="assocPhrase.phraseDispWords.length == 0" class="text">
        {{ assocPhrase.assocPhraseText }}
      </p>
      <formatted-phrase
        *ngIf="assocPhrase.phraseDispWords.length > 0"
        class="text"
        [phraseWords]="assocPhrase.phraseDispWords"
      ></formatted-phrase>
    </div>
  </div>

  <div
    *ngIf="phrase && phrase.referencedBy.length > 0"
    class="referencedBy"
  >
    <p class="title">{{ "translate.rev.referencedBy" | translate }}:</p>
    <div *ngFor="let ref of phrase.referencedBy" class="ref-container">
      <p *ngIf="ref.phraseDispWords.length == 0" class="text">
        {{ ref.refPhraseText }}
      </p>
      <formatted-phrase
        *ngIf="ref.phraseDispWords.length > 0"
        class="text"
        [phraseWords]="ref.phraseDispWords"
      ></formatted-phrase>
    </div>
  </div>

  <div *ngIf="phrase && phrase.associatedImages.length > 0" class="associatedImages">
    <p class="title">{{ "translate.general.associatatedImages" | translate }}:</p>
    <div *ngFor="let img of phrase.associatedImages" style="margin-bottom: 0.5em;">
      <p class="t-footnote figure-type">
        ID: {{ img.assocImageId }}
      </p>
      <async-img [className]="'ind-img'" [url]="img.imgUrl"></async-img>
    </div>
  </div>

  <!--ext ref-->
  <div *ngIf="phrase && phrase.extRefs.length > 0" class="ext-ref">
    <p class="title">{{ "translate.cat.extRefs" | translate }}:</p>
    <div *ngFor="let ref of phrase.extRefs">
      <div class="ref-container">
        <formatted-phrase
          class="text"
          [phraseWords]="ref.extPhraseDispWords"
        ></formatted-phrase>
      </div>

      <p class="footnote l-ta-end">
        {{ ref.extDocName }} ({{ ref.extCatDescr }})
      </p>
    </div>
  </div>

  <!-- instructions -->
  <div
    *ngIf="element.addInstr && element.addInstr.length > 0"
    class="instructions"
  >
    <p class="title">{{ "translate.rev.instructions" | translate }}:</p>

    <cat-add-instr
      *ngFor="let instr of element.addInstr"
      [instr]="instr"
      [editable]="false"
    ></cat-add-instr>
  </div>

  <div *ngIf="editable" class="btn">
    <p-selectButton
      class="cat-phrase-status"
      [options]="phraseStatusOptions"
      [(ngModel)]="element.userStatus"
      (onChange)="onPhraseStatusChange()"
    ></p-selectButton>
  </div>

  <div class="icons">
    <i
      *ngIf="editable"
      class="fas fa-edit"
      (click)="editPhrase()"
      tooltip="{{ 'translate.general.edit' | translate }}"
    ></i>
    &nbsp; &nbsp;
    <i
      *ngIf="editable"
      class="far fa-bookmark"
      (click)="onAddExtRef()"
      tooltip="{{ 'translate.cat.addExtRef' | translate }}"
    ></i>
    &nbsp; &nbsp;
    <i
      *ngIf="element.pageNum == curPage && !element.bgImgSw"
      class="fas fa-eye icon-view"
      [class.selected]="element.viewSw"
      (click)="togglePhraseView($event)"
      tooltip="{{ 'translate.general.toggle' | translate }}"
    ></i>
  </div>

  <div class="cat-phrase-type">
    {{ element.typeDescr }}
    <span class="sub-type">&middot; {{ element.subTypeDescr }}</span>
  </div>
</div>

<cat-doc-phrase-details
  *ngIf="phraseDetailsOpenSw"
  (onClose)="phraseDetailsOpenSw = false"
  (onStatusChange)="onPhraseStatusChange()"
  [editModeSw]="phraseEditModeSw"
  [editable]="editable"
  [mode]="'ind'"
  (onDelete)="onPhraseDelete()"
  [stateTag]="stateTag"
  (onUpdateSpellingIssues)="notifySpellingIssuesUpdate()"
></cat-doc-phrase-details>

<add-ext-ref
  *ngIf="addExtRefOpenSw"
  (onClose)="onAddExtRefClose($event)"
  [srcPhraseId]="phrase ? phrase.phraseId : null"
>
</add-ext-ref>
