import { NgRedux } from '@redux/redux-compatibility.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ValidationPipe } from 'src/app/shared/components/ui/validation.pipe';
import { Env } from 'src/app/shared/models/user.model';
import { AppState } from 'src/app/shared/redux/store';
import { AuthService } from 'src/app/shared/services/auth.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { DataElement } from '@shared/models/common';

@Component({
  selector: 'edit-env',
  templateUrl: './edit-env.component.html',
  styleUrls: ['./edit-env.component.scss'],
})
export class EditEnvComponent implements OnInit {
  @Input() env: Env;
  @Output() onClose = new EventEmitter();
  @Output() onSave = new EventEmitter();
  isNew: boolean;
  saving: boolean;

  get modalTitle(): string {
    if (this.isNew) return 'translate.mng.addEnv';
    else return 'translate.mng.editEnv';
  }

  isDirty(): boolean {
    return this.env.isDirty();
  }

  get ruleConfigTemplateList(): DataElement[] {
    return this.ngRedux.getState().ruleConfigTemplateList;
  }

  get genaiConfigTemplateList(): DataElement[] {
    return this.ngRedux.getState().genaiConfigTemplateList;
  }

  get embeddingConfigTemplateList(): DataElement[] {
    return this.ngRedux.getState().embeddingConfigTemplateList;
  }

  constructor(
    private ngRedux: NgRedux<AppState>,
    private authService: AuthService,
    private sharedService: SharedService,
    private translateService: TranslateService,
    private validationPipe: ValidationPipe
  ) {}

  ngOnInit(): void {
    this.isNew = this.env.id == null;
    this.env.saveOriginal();
  }

  close() {
    if (this.isDirty()) {
      let validationStr = this.translateService.instant(
        'translate.cat.discardEditChanges'
      );

      this.sharedService.emitPopupchReceivedEvent({
        title: 'translate.validation.confirm',
        icon: 'exclamation-triangle',
        subTitle: validationStr,
        keep: false,
        approve: () => {
          this.discardChanges();
          this.onClose.emit();
        },
      });
    } else {
      this.onClose.emit();
    }
  }

  discardChanges() {
    this.env.restoreOriginal();
  }

  deleteClicked() {
    let validationStr = this.translateService.instant(
      'translate.mng.deleteEnvValidation'
    );

    this.sharedService.emitPopupchReceivedEvent({
      title: 'translate.validation.confirm',
      icon: 'exclamation-triangle',
      subTitle: validationStr,
      keep: false,
      approve: () => {
        this.updateEnv(true);
      },
    });
  }

  public allowSubmit(): boolean {
    return this.env.descr != null && this.env.ruleConfigTemplate != null
      && this.env.genaiConfigTemplate != null && this.env.embeddingConfigTemplate != null;
  }

  public updateEnv(deleteSw: boolean = false) {
    this.saving = true;
    this.authService.updateEnv(this.env, deleteSw).subscribe(
      () => {
        this.saving = false;
        this.onSave.emit();
      },
      () => (this.saving = false)
    );
  }
}
