import { NgRedux } from '@redux/redux-compatibility.service';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CatDoc, IndPhrase } from 'src/app/shared/models/cat.model';
import { AppState } from 'src/app/shared/redux/store';
import { CatService } from 'src/app/shared/services/cat.service';

@Component({
  selector: 'cat-doc-ref-options',
  templateUrl: './cat-doc-ref-options.component.html',
  styleUrls: ['./cat-doc-ref-options.component.scss'],
})
export class CatDocRefOptionsComponent implements OnInit {
  @Output() onRefAdd = new EventEmitter<IndPhrase>();
  @Output() onRefRemove = new EventEmitter<IndPhrase>();

  loading: boolean;

  get curPhrase(): IndPhrase {
    return this.ngRedux.getState().curCatElement as IndPhrase;
  }

  get curDoc(): CatDoc {
    return this.ngRedux.getState().curCatDocStatePrime.curCatDoc;
  }

  get refOptions(): IndPhrase[] {
    return this.ngRedux.getState().curCatDocStatePrime.catDocRefOptions;
  }

  constructor(
    private ngRedux: NgRedux<AppState>,
    private catService: CatService
  ) {}

  ngOnInit(): void {
    if (
      !this.ngRedux.getState().curCatDocStatePrime.catDocRefOptions ||
      this.ngRedux.getState().curCatDocStatePrime.catDocRefOptions.length == 0
    ) {
      this.loading = true;
      this.catService.getIndDocRefOptions(this.curDoc).subscribe(
        (data) => {
          this.loading = false;
        },
        () => (this.loading = false)
      );
    }
  }

  add(phrase: IndPhrase) {
    this.onRefAdd.emit(phrase);
  }

  remove(phrase: IndPhrase) {
    this.onRefRemove.emit(phrase);
  }

  isSelected(phrase: IndPhrase): boolean {
    return (
      this.curPhrase.references.filter((r) => r.refPhraseId == phrase.phraseId)
        .length > 0
    );
  }
}
