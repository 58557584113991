<div class="ref-match-container">

    <!-- MATCH -->
    <ng-template [ngIf]="!associatedImageFinding?.assocImageFinding || associatedImageFinding?.assocImageFinding === 'FULL_MATCH'">
      <div class="ref-match-data-container">
        <div class="text-container">
            <async-img *ngIf="associatedImage.imgS3Key" [className]="'inner-img'" [url]="associatedImage.imgUrl"></async-img>
        </div>
      </div>
      <div class="ref-similarity-code">
        <p class="match">
          {{ "translate.rev.refMatch" | translate }}
        </p>
      </div>
    </ng-template>
  
    <!-- MISSING_REFERENCE -->
    <ng-template [ngIf]="associatedImageFinding?.assocImageFinding === 'MISSING_ASSOC_IMAGE'">
      <div class="ref-match-data-container">
        <div class="text-container ref-similarity">
            <async-img *ngIf="associatedImage.imgS3Key" [className]="'inner-img'" [url]="associatedImage.imgUrl"></async-img>
        </div>
        <div class="ref-info">
          <span class="footnote id">
            <!-- {{ "translate.cat.phraseId" | translate }}: {{ ref.refPhraseId }} -->
          </span>
        </div>
      </div>
      <div class="ref-similarity-code">
        <p class="missing">
          {{ "translate.rev.refMissing" | translate }}
        </p>
      </div>
    </ng-template>
  
    <!-- TEXT_MISMATCH -->
    <ng-template [ngIf]="associatedImageFinding?.assocImageFinding === 'IMAGE_MISMATCH'">
        <div class="ref-match-data-container">
            <div class="text-container">
                <async-img *ngIf="associatedImage.imgS3Key" [className]="'inner-img'" [url]="associatedImage.imgUrl"></async-img>
                <div class="ref-info">
                    <span class="footnote id">
                        {{ "translate.general.id" | translate }}: {{ associatedImageFinding.catAssocImageId }}
                    </span>
                </div>
            </div>
        </div>
        <div class="ref-similarity-code">
            <p class="missing">
                {{ "translate.general.imageMismatch" | translate}}
            </p>
        </div>
    </ng-template>
  
    <!-- ADDED_REFERENCE -->
    <ng-template [ngIf]="associatedImageFinding?.assocImageFinding === 'ADDED_ASSOC_IMAGE'">
      <div class="ref-match-data-container">
        <div class="text-container ref-similarity">
          <async-img *ngIf="associatedImage.imgS3Key" [className]="'inner-img'" [url]="associatedImage.imgUrl"></async-img>
        </div>
      </div>
      <div class="ref-similarity-code">
        <p class="extra">
          {{ "translate.rev.refAdded" | translate }}
        </p>
      </div>
    </ng-template>
  
  </div>