<loader *ngIf="loading"></loader>

<div *ngIf="!loading" class="rules-container">
  <div *ngIf="imageList.length == 0 && phraseList == 0">
    <h2 *ngIf="!isSearchActive" class="images">
      <span>{{ "translate.general.noPageElements" | translate }}</span>
    </h2>
    <h2 *ngIf="isSearchActive" class="images">
      <span>{{ "translate.general.noResults" | translate }}</span>
    </h2>
  </div>
  <cat-doc-phrase-tile
    *ngFor="let phrase of phraseList"
    [phrase]="phrase"
    (onTogglePhraseView)="onTogglePhraseViewClicked(phrase)"
    [displayPageNum]="displayPageNum"
    (onGoToPhrasePage)="onGoToPhrasePage(phrase)"
    (mouseenter)="onMouseEnter(phrase)"
    (mouseleave)="onMouseLeave(phrase)"
    [editable]="editable"
    [stateTag]="stateTag"
    (onUpdateSpellingIssues)="reloadData()"
  ></cat-doc-phrase-tile>

  <div *ngIf="imageList.length">
    <h2 class="images">
      <span>{{ "translate.general.images" | translate }}</span>
    </h2>
    <cat-doc-phrase-tile
      *ngFor="let img of imageList"
      [phrase]="img"
      (onTogglePhraseView)="onTogglePhraseViewClicked(img)"
      [displayPageNum]="displayPageNum"
      (onGoToPhrasePage)="onGoToPhrasePage(img)"
      (mouseenter)="onMouseEnter(img)"
      (mouseleave)="onMouseLeave(img)"
      [editable]="editable"
      [stateTag]="stateTag"
      (onUpdateSpellingIssues)="reloadData()"
    ></cat-doc-phrase-tile>
  </div>
</div>
