import { Component, OnInit, Compiler } from '@angular/core';
import { UiUser, UserRoleEnum } from 'src/app/shared/models/ui-user.model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NgRedux } from '@redux/redux-compatibility.service';
import { AppState, actionsList } from 'src/app/shared/redux/store';
import { RouteEnum } from 'src/app/shared/models/routes.model';
import { DataElement } from 'src/app/shared/models/common';
import { Env } from 'src/app/shared/models/user.model';

@Component({
  selector: 'navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  displaySelectEnv: boolean;
  displaySideBar: boolean;

  get version(): String {
    return SharedService.versionCode;
  }

  get currentUser(): UiUser {
    return this.ngRedux.getState().user;
  }

  get env(): Env {
    return this.ngRedux.getState().curEnv;
  }

  get displaySidebarBtn(): boolean {
    if (!this.currentUser) return false;

    return (
      (this.currentUser.authDetails.catScreenAllowed() &&
        this.currentUser.authDetails.revScreenAllowed()) ||
      this.currentUser.isAdmin
    );
  }

  // get breadcrumbsList(): string[]{
  //   let list = [];

  //   if (this.ngRedux.getState().ui_navigation.startsWith(""))

  //   return [];
  // }

  constructor(
    private authService: AuthService,
    private sharedService: SharedService,
    private router: Router,
    private ngRedux: NgRedux<AppState>
  ) {}

  routeEnum: RouteEnum = new RouteEnum();

  ngOnInit() {
    this.ngRedux.subscribe(() => {
      const appState = this.ngRedux.getState();
      switch (appState._currentAction) {
        case actionsList.UI__SIDEBAR_ROUTE_NAVIGATION:
          this.displaySideBar = false;
          break;
      }
    });
  }

  signout() {
    this.authService.signOutUser().then(() => {
      // this.goToLogin();
    });
  }

  // goToLogin() {
  //   this.ngRedux.dispatch({
  //     type: actionsList.USER_SIGN_OUT,
  //   });
  //   this.router.navigate(['']);
  // }

  onSelectEnv() {
    this.displaySelectEnv = false;
  }
}
