<header class="l-align-y navbar-table">
  <div class="l-align-center">
    <button
      *ngIf="displaySidebarBtn"
      class="sidebar-btn"
      (click)="displaySideBar = true"
    >
      <i class="fas fa-bars"></i>
    </button>
    <img class="logo" src="assets\img\logo.png" />
    <a (click)="displaySelectEnv = true">{{ env.descr }}</a>

    <app-breadcrumbs></app-breadcrumbs>

    <!-- <ng-container *ngTemplateOutlet="breadcrumbs"></ng-container> -->
  </div>

  <div *ngIf="currentUser" class="hello-user fs-small">
    <i
      class="fas fa-info-circle c1"
      tooltip="{{ 'translate.general.appVersion' | translate }}: {{ version }}"
    ></i>
    &nbsp;
    <strong>{{ currentUser.userDescr }}</strong>
    &nbsp;
    <span class="bar"></span>
    <a (click)="signout()">({{ "translate.login.signout" | translate }})</a>
  </div>
</header>

<select-env
  *ngIf="displaySelectEnv"
  (onClose)="displaySelectEnv = false"
  (onSelectEnv)="onSelectEnv()"
></select-env>

<p-sidebar [(visible)]="displaySideBar" position="left">
  <app-sidebar> </app-sidebar>
</p-sidebar>

<!-- <ng-template #breadcrumbs>
  <div class="breadcrumbs">
    <span *ngFor="let loc of breadcrumbsList">
      {{ loc }}
    </span>
  </div>
</ng-template> -->
