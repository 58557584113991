import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DocViewerComponent } from 'src/app/shared/components/doc-viewer/doc-viewer.component';
import { PdfViewerComponent } from 'src/app/shared/components/pdf-viewer/pdf-viewer.component';
import { CatDoc } from 'src/app/shared/models/cat.model';
import { Doc, DocPage } from 'src/app/shared/models/common';

@Component({
  selector: 'cat-entry-docs',
  templateUrl: './cat-entry-docs.component.html',
  styleUrls: ['./cat-entry-docs.component.scss'],
})
export class CatEntryDocsComponent implements OnInit {
  @ViewChild('docSinglePhraseViewer') docViewer: PdfViewerComponent;
  @Input() docs: CatDoc[] = [];
  curPhraseDoc: Doc;
  curPhraseEl: string;
  displayDocViewer: boolean;
  curDoc: CatDoc;
  curPage: number;
  singlePageSw: boolean;
  initialDocViewerDisplay: boolean;

  constructor() {}

  ngOnInit(): void {}

  viewPhraseDoc(doc: CatDoc, singlePage: boolean = false) {
    this.singlePageSw = singlePage;
    this.curDoc = doc;
    this.curPage = this.curDoc.singlePhrasePageNum;
    this.displayDocViewer = true;
    this.initialDocViewerDisplay = true;
  }

  onDocIframeLoaded() {
    if (this.initialDocViewerDisplay) {
      this.initialDocViewerDisplay = false;
      if (this.docViewer.analyzedPage !== this.curPage && !this.singlePageSw) {
        this.docViewer.goToPage(this.curPage);
      }
    }
    if (this.singlePageSw || this.docViewer.analyzedPage === this.curPage) {
      this.docViewer.showAnnotation(this.curDoc.annotation, true);
    }
  }

  onToggleAnnotations(viewSw: boolean) {
    if (viewSw) {
      this.docViewer.showAnnotation(this.curDoc.annotation, true);
    } else {
      this.docViewer.hideAnnotation(this.curDoc.annotation, false);
    }
  }
}
