<div class="ref-match-container">

    <!-- MATCH -->
    <ng-template [ngIf]="!associatedTextFinding?.assocTextFinding || associatedTextFinding?.assocTextFinding === 'FULL_MATCH'">
      <div class="ref-match-data-container">
        <div class="text-container">
          <p class="text">
            {{ associatedText?.assocPhraseText }}
          </p>
        </div>
      </div>
      <div class="ref-similarity-code">
        <p class="match">
          {{ "translate.rev.refMatch" | translate }}
        </p>
      </div>
    </ng-template>
  
    <!-- MISSING_REFERENCE -->
    <ng-template [ngIf]="associatedTextFinding?.assocTextFinding === 'MISSING_ASSOC_TEXT'">
      <div class="ref-match-data-container">
        <div class="text-container ref-similarity">
          <p class="text missing">
            {{ associatedText?.assocPhraseText }}
          </p>
        </div>
        <div class="ref-info">
          <span class="footnote id">
            <!-- {{ "translate.cat.phraseId" | translate }}: {{ ref.refPhraseId }} -->
          </span>
        </div>
      </div>
      <div class="ref-similarity-code">
        <p class="missing">
          {{ "translate.rev.refMissing" | translate }}
        </p>
      </div>
    </ng-template>
  
    <!-- TEXT_MISMATCH -->
    <ng-template [ngIf]="associatedTextFinding?.assocTextFinding === 'TEXT_MISMATCH'">
      <div class="ref-diff-container" style="position: relative;">
        <!-- Diff container -->
        <p class="text">
          <span *ngIf="!showOriginal">
            <span *ngFor="let word of associatedTextFinding?.assocDiffWords" class="match-diff"
              [class.missing]="word.diffType == 'MISSING'" [class.extra]="word.diffType == 'EXTRA'">
              {{ word.txt }}
            </span>
          </span>
          <span *ngIf="showOriginal">
            <div class="text-container">
              <p class="text">
                {{ catPhraseAssocText(associatedTextFinding.catAssocPhraseId).assocPhraseText }}
              </p>
            </div>
            <div class="ref-info">
              <span class="footnote id">
                {{ "translate.cat.phraseId" | translate }}: {{ associatedTextFinding.catAssocPhraseId }}
              </span>
            </div>
          </span>
        </p>
      </div>
      <div *ngIf="!showOriginal">
        <button (click)="showOriginal = !showOriginal" class="eye-toggle" tooltip="{{ 'translate.rev.showOriginal' | translate }}">
          <i class="fas fa-eye"></i>
        </button>
      </div>
      <div *ngIf="showOriginal">
        <button (click)="showOriginal = !showOriginal" class="eye-toggle" tooltip="{{ 'translate.rev.showDiff' | translate }}">
          <i class="fas fa-eye-slash"></i>
        </button>
      </div>
    </ng-template>
  
    <!-- ADDED_REFERENCE -->
    <ng-template [ngIf]="associatedTextFinding?.assocTextFinding === 'ADDED_ASSOC_TEXT'">
      <div class="ref-match-data-container">
        <div class="text-container ref-similarity">
          <p class="text extra">
            {{ associatedTextFinding?.assocText }}
          </p>
        </div>
      </div>
      <div class="ref-similarity-code">
        <p class="extra">
          {{ "translate.rev.refAdded" | translate }}
        </p>
      </div>
    </ng-template>
  
  </div>