<loader *ngIf="loading"></loader>

<div *ngIf="!loading" class="rules-container">
  <div *ngIf="imagesList.length == 0 && rulesList.length == 0">
    <h2 *ngIf="!isSearchActive" class="images">
      <span>{{ "translate.general.noPageElements" | translate }}</span>
    </h2>
    <h2 *ngIf="isSearchActive" class="images">
      <span>{{ "translate.general.noResults" | translate }}</span>
    </h2>
  </div>

  <rev-doc-rule-tile
    *ngFor="let rule of rulesList"
    [rule]="rule"
    (onToggleRuleView)="onToggleRuleViewClicked(rule)"
    [displayPageNum]="displayPageNum"
    (onGoToRulePage)="onGoToRulePage(rule)"
    (mouseenter)="onMouseEnter(rule)"
    (mouseleave)="onMouseLeave(rule)"
    (onUpdateSpellingIssues)="reloadData()"
  ></rev-doc-rule-tile>

  <div *ngIf="imagesList.length">
    <h2 class="images">
      <span>{{ "translate.general.images" | translate }}</span>
    </h2>
    <rev-doc-rule-tile
      *ngFor="let rule of imagesList"
      [rule]="rule"
      (onToggleRuleView)="onToggleRuleViewClicked(rule)"
      [displayPageNum]="displayPageNum"
      (onGoToRulePage)="onGoToRulePage(rule)"
      (mouseenter)="onMouseEnter(rule)"
      (mouseleave)="onMouseLeave(rule)"
      (onUpdateSpellingIssues)="reloadData()"
    ></rev-doc-rule-tile>
  </div>
</div>
