import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  Input,
  ViewChildren,
  QueryList,
} from '@angular/core';
import {
  IndPhrase,
  CatDocStatusEnum,
  MergeLine,
  CatalogStatusEnum,
  IndElement,
  IndImage,
  CatDocStateTagEnum,
  CatDoc,
  ExtRef,
} from 'src/app/shared/models/cat.model';
import { SharedService } from 'src/app/shared/services/shared.service';
import {
  DataElement,
  ReferencePhrase,
  AddInstr,
  PhraseWord,
  CatMatch,
  ProcessRunStatusEnum,
} from 'src/app/shared/models/common';
import { NgRedux } from '@redux/redux-compatibility.service';
import { actionsList, AppState } from 'src/app/shared/redux/store';
import { CatService } from 'src/app/shared/services/cat.service';
import { TreeNode } from 'primeng/api';
import { OverlayPanel } from 'primeng/overlaypanel';
import { TranslateService } from '@ngx-translate/core';
import { CatPhraseRefComponent } from '../cat-phrase-ref/cat-phrase-ref.component';
import { CatAddInstrComponent } from '../cat-add-instr/cat-add-instr.component';
import { merge, Observable } from 'rxjs';
import { GrammarSpellingCheckService } from '@shared/services/grammar-spelling-check.service';
import { AuthService } from '@shared/services/auth.service';
import { CommonService } from '@shared/services/common.service';

@Component({
  selector: 'cat-doc-phrase-details',
  templateUrl: './cat-doc-phrase-details.component.html',
  styleUrls: ['./cat-doc-phrase-details.component.scss'],
})
export class CatDocPhraseDetailsComponent implements OnInit {
  @Input() editable: boolean = true;
  @Input() stateTag: CatDocStateTagEnum = CatDocStateTagEnum.PRIME;

  @Input('mode') set data(mode: 'cat' | 'ind') {
    if (mode == 'cat') {
      this.catModeSw = true;
    } else {
      this.indModeSw = true;
    }

    this.element = this.ngRedux.getState().curCatElement;
    if (this.ngRedux.getState().curCatElement instanceof IndPhrase) {
      this.phrase = this.ngRedux.getState().curCatElement as IndPhrase;
    } else if (this.ngRedux.getState().curCatElement instanceof IndImage) {
      this.image = this.ngRedux.getState().curCatElement as IndImage;
    }
  }
  element: IndElement;
  phrase: IndPhrase;
  image: IndImage;
  indModeSw: boolean;
  catModeSw: boolean;

  @Output() onClose = new EventEmitter();
  @Output() onStatusChange = new EventEmitter();
  @Output() onSave = new EventEmitter();
  @Output() onEdit = new EventEmitter();
  @Output() onDelete = new EventEmitter();
  @Input() editModeSw: boolean;
  @Input() displayEntryDocs: boolean = false;
  @Output() onUpdateSpellingIssues = new EventEmitter();

  @ViewChild('opTypes') typesOverlay: OverlayPanel;

  @ViewChildren(CatPhraseRefComponent)
  refComponents: QueryList<CatPhraseRefComponent>;

  @ViewChildren(CatAddInstrComponent)
  instrComponents: QueryList<CatAddInstrComponent>;

  loading: boolean;
  phraseStatusOptions: DataElement[] = [];
  displayTypeEditBtn: boolean;
  displayPhraseEditBtn: boolean;
  displayDocsSw: boolean;
  selectedPhraseType: TreeNode;
  catPhraseTypesTree: TreeNode[];
  editPhraseText: string;
  selectedLines: MergeLine[];
  displayRefOptions: boolean;
  editPhraseTextWords: PhraseWord[];
  showIssuesOnFullMatchEnabled: boolean;
  wordsToWhitelist: string[] = [];
  processStatusCheckIntervalMillis = 1000;

  get extSw(): boolean {
    return (
      this.ngRedux.getState().curCat && this.ngRedux.getState().curCat.extSw
    );
  }

  get inCatEditable(): boolean {
    if (this.indModeSw) {
      return true;
    } else {
      return this.phrase && this.phrase.manualSrcSw;
    }
  }

  get curCatDoc(): CatDoc {
    return this.stateTag == CatDocStateTagEnum.PRIME
      ? this.ngRedux.getState().curCatDocStatePrime.curCatDoc
      : this.ngRedux.getState().curCatDocStateSec.curCatDoc;
  }

  get curCatDocPageNum(): number {
    return this.stateTag == CatDocStateTagEnum.PRIME
      ? this.ngRedux.getState().curCatDocStatePrime.curCatDocPageNum
      : this.ngRedux.getState().curCatDocStateSec.curCatDocPageNum;
  }

  get catEntryStatusList(): DataElement[] {
    return this.ngRedux.getState().catEntryStatusList;
  }

  get figureSubTypeList(): DataElement[] {
    if (!this.element.figureType) return [];
    return this.ngRedux
      .getState()
      .figureTypeList.find((e) => e.id == this.element.figureType).children;
  }

  get notPotentialNew(): boolean {
    let similarityEvent = this.getSimilarityEvent();
    return similarityEvent && similarityEvent != 'POTENTIAL_NEW';
  }

  getSimilarityEvent() {
    let similarityEvent;
    if (this.phrase) {
      similarityEvent = this.phrase.phraseSimilarityEvent;
    } else if (this.image) {
      similarityEvent = this.image.imageSimilarityEvent;
    }
    return similarityEvent;
  }

  get grammarSpellingIssues() {
    let similarityEvent = this.getSimilarityEvent();
    if (!this.showIssuesOnFullMatchEnabled && similarityEvent && similarityEvent == 'FULL_MATCH') {
      return [];
    }
    return this.phrase.grammarSpellingIssues;
  }

  get referenceIssues() {
    let similarityEvent = this.getSimilarityEvent();
    if (!this.showIssuesOnFullMatchEnabled && similarityEvent && similarityEvent == 'FULL_MATCH') {
      return [];
    }
    return this.phrase.referenceIssues;
  }

  constructor(
    private ngRedux: NgRedux<AppState>,
    private catService: CatService,
    private sharedService: SharedService,
    private translateService: TranslateService,
    private grammarSpellingService: GrammarSpellingCheckService,
    private authService: AuthService,
    private commonService: CommonService
  ) {
    this.showIssuesOnFullMatchEnabled = this.sharedService.isShowIssuesOnFullMatchEnabled();
  }

  ngOnInit(): void {
    if (!this.element.hasId) {
      this.editModeSw = true;
    }
    this.element.saveOriginal();
    if (this.phrase) {
      this.editPhraseText = this.phrase.phraseText;
      this.editPhraseTextWords = this.phrase.phraseWords;
      this.initMergeOptions();
    }
    this.initCatTypesTree();

    let actions = [];
    if (this.phrase) {
      actions = this.phrase.phraseActions;
    } else if (this.image) {
      actions = this.image.imageActions;
    }
    // show all actions on old docs
    let isNewRuleDoc = !!this.getSimilarityEvent();
    if (isNewRuleDoc) {
      this.phraseStatusOptions =
      this.sharedService.getCatPhraseUpdStatusOptions().filter(
        s => {
          return actions.indexOf(s.id) >= 0;
        }
      );
    } else {
      this.phraseStatusOptions = this.sharedService.getCatPhraseUpdStatusOptions();
    }
  }

  isDirty(): boolean {
    return (
      this.element.isDirty() ||
      (this.phrase && this.editPhraseText != this.phrase.phraseText)
    );
  }

  hasWhitelistedWords() {
    return this.wordsToWhitelist.length > 0;
  }

  initMergeOptions() {
    if (this.phrase.mergeOptions) {
      this.selectedLines = this.phrase.mergeOptions.filter((m) => m.inUseSw);
      this.phrase.mergeOptions.forEach((p) => {
        p.displayWordsSw = p.inUseSw && p.phraseWords.some((w) => !w.checked);
      });
    }
  }

  initCatTypesTree() {
    if (!this.ngRedux.getState().catPhraseTypeModel) {
      this.catPhraseTypesTree = [];
    } else if (this.element.typeCode && this.element.subTypeCode) {
      this.catPhraseTypesTree = this.ngRedux
        .getState()
        .catPhraseTypeModel.getTreeBySelectedType(
          this.element.typeCode,
          this.element.subTypeCode
        );
      this.selectedPhraseType = this.ngRedux
        .getState()
        .catPhraseTypeModel.getSubTypeNode(
          this.catPhraseTypesTree,
          this.element.typeCode,
          this.element.subTypeCode
        );
    } else {
      this.catPhraseTypesTree =
        this.ngRedux.getState().catPhraseTypeModel.treeTypesForInput;
    }
  }

  close() {
    if (this.isDirty() || this.hasWhitelistedWords()) {
      let validationStr = this.translateService.instant(
        'translate.cat.discardEditChanges'
      );

      this.sharedService.emitPopupchReceivedEvent({
        title: 'translate.validation.confirm',
        icon: 'exclamation-triangle',
        subTitle: validationStr,
        keep: false,
        approve: () => {
          this.discardChanges();
          this.onClose.emit();
        },
      });
    } else {
      this.onClose.emit();
    }
  }

  onElementStatusChange() {
    if (this.phrase && this.catMatches.length > 0 && this.getSimilarityEvent()) {
      if (this.element.userStatus.value && this.element.userStatus.value.id === 'APPROVE_VARIANT') {
        this.phrase.variantOrigPhraseId = this.catMatches[this.currentIndex].catPhrase.phraseId;
      } else {
        this.phrase.variantOrigPhraseId = null;
      }
    }
    this.onStatusChange.emit();
  }

  addReference() {
    if (this.catModeSw) {
      this.phrase.references.push(new ReferencePhrase());
    } else {
      this.displayRefOptions = true;
    }
  }

  addInstr() {
    this.element.addInstr.push(new AddInstr());
  }

  onTypeSelected(event: any) {
    this.element.typeCode = this.selectedPhraseType.parent.key;
    this.element.typeDescr = this.selectedPhraseType.parent.label;
    this.element.subTypeCode = this.selectedPhraseType.key;
    this.element.subTypeDescr = this.selectedPhraseType.label;
    this.initCatTypesTree();
    if (this.typesOverlay) {
      this.typesOverlay.hide();
    }
  }

  onSaveClicked() {
    this.loading = true;
    //if (this.phrase) this.phrase.phraseText = this.editPhraseText;

    if (this.hasWhitelistedWords()) {
      this.processWhitelistedWords().subscribe(
        _ => {
          this.commonService.addAlgRunHead({doc_id: this.phrase.docId, run_type: 'IND_SPELLING_LIGHT'}).subscribe(
            runNum => {
              this.grammarSpellingService.updateSpellingLight(runNum, this.wordsToWhitelist).subscribe(
                _ => {
                  let timer = setInterval(() => {
                    this.authService.getProcessStatus(runNum).subscribe(
                      (data) => {
                        if (data.status == ProcessRunStatusEnum.FINISHED || data.status == ProcessRunStatusEnum.FAILED) {
                          clearInterval(timer);

                          this.onUpdateSpellingIssues.emit();
                          
                          if (this.isDirty()) {
                            this.savePhraseChanges();
                          } else {
                            this.loading = false;
                            this.onClose.emit();
                          }
                        }
                      },
                      (err) => {
                        clearInterval(timer);
                      }
                    );
                  }, this.processStatusCheckIntervalMillis);
                }
              )
            },
            err => {
              this.loading = false;
            }
          );
        },
        err => {
          this.loading = false;
        }
      );
    } else {
      if (this.isDirty()) {
        this.savePhraseChanges();
      } else {
        this.loading = false;
        this.onClose.emit();
      }
    }
  }

  savePhraseChanges() {
    if (this.indModeSw) {
      this.saveChangesIndMode();
    } else {
      this.saveChangesCatMode();
    }
  }

  saveChangesIndMode() {
    // create phrase
    if (!this.element.hasId) {
      this.catService
        .createIndDocPhrase(this.phrase, this.curCatDoc, this.curCatDocPageNum)
        .subscribe(
          (data) => {
            this.loading = false;
            this.onSave.emit();
          },
          (err) => (this.loading = false)
        );
    } else {
      // update phrase/image

      let txtUpdated: boolean;
      let refUpdated: boolean;
      if (this.phrase) {
        txtUpdated = this.phrase.phraseText != this.editPhraseText;
        refUpdated =
          JSON.stringify(this.phrase.references) !=
          JSON.stringify(
            (JSON.parse(this.phrase.original) as IndPhrase).references
          );
      }

      this.catService.updIndDocPhrase(this.element).subscribe(
        (linesUpdSw) => {
          this.element.saveOriginal();
          this.setInitialEditMode();
          this.instrComponents.forEach((c) => (c.editMode = false));
          this.refComponents.forEach((c) => (c.editMode = false));
          this.loading = false;

          if (txtUpdated || refUpdated) {
            this.ngRedux.dispatch({
              type: actionsList.RELOAD_CAT_DOC_PAGE_REQ,
              data: linesUpdSw,
            });
          }
          if (txtUpdated) {
            this.ngRedux.dispatch({
              type: actionsList.REANNOTATE_CAT_DOC_REQ,
              data: linesUpdSw,
            });
          }
        },
        (err) => (this.loading = false)
      );
    }
  }

  saveChangesCatMode() {
    // create cat phrase
    if (!this.element.hasId) {
      this.phrase.phraseText = this.editPhraseText;
      this.catService.createCatEntry(this.phrase).subscribe(
        (data) => {
          this.loading = false;
          this.onSave.emit();
        },
        (err) => (this.loading = false)
      );
    } else {
      // update cat phrase/image
      this.catService.updCatEntry(this.element).subscribe(
        (data) => {
          this.element.saveOriginal();
          this.setInitialEditMode();
          this.instrComponents.forEach((c) => (c.editMode = false));
          this.refComponents.forEach((c) => (c.editMode = false));
          this.loading = false;
          this.onEdit.emit();
        },
        (err) => (this.loading = false)
      );
    }
  }

  processWhitelistedWords(): Observable<any> {
    const observables: Observable<any>[] = [];

    for (let word of this.wordsToWhitelist) {
      const catId = this.ngRedux.getState().curCat.catId;
      const observable = this.grammarSpellingService.addCatSpellingCheckIgnoredWord(catId, word);
      observables.push(observable);
    }

    return merge(...observables);
  }

  setInitialEditMode() {
    if (this.phrase) {
      this.editPhraseText = this.phrase.phraseText;
      this.editPhraseTextWords = this.phrase.phraseWords;
      this.initMergeOptions();
    }

    this.editModeSw = false;
  }

  discardChanges() {
    this.element.restoreOriginal();
    this.setInitialEditMode();
    this.wordsToWhitelist = [];
    this.phrase.grammarSpellingIssues?.forEach(i => i.isWhitelisted = false);
  }

  onMergeOptionsClicked(event: any) {
    let line = event.option as MergeLine;
    console.log(line);
    line.phraseWords.forEach(
      (w) => (w.checked = this.selectedLines.includes(line))
    );
    this.updateEditPhraseTxt();
  }

  onMergeWordChange(line: MergeLine) {
    if (
      line.phraseWords.some((w) => w.checked) &&
      !this.selectedLines.includes(line)
    ) {
      this.selectedLines.push(line);
    }
    if (
      line.phraseWords.every((w) => !w.checked) &&
      this.selectedLines.includes(line)
    ) {
      this.selectedLines.splice(this.selectedLines.indexOf(line), 1);
    }
    this.updateEditPhraseTxt();
  }

  updateEditPhraseTxt() {
    this.editPhraseText = this.selectedLines
      .srp_sortByKey('id')
      .map((m: MergeLine) =>
        m.phraseWords
          .filter((w) => w.checked)
          .map((w) => w.text)
          .join(' ')
      )
      .join(' ');

    this.phrase.mergeOptions.forEach((l) => {
      l.inUseSw = this.selectedLines.indexOf(l) !== -1;
    });

    this.editPhraseTextWords = [];
    this.selectedLines.forEach((o) => {
      this.editPhraseTextWords.push(...o.phraseWords.filter((w) => w.checked));
    });
  }

  onDeleteRef(ref: ReferencePhrase) {
    ref.deleteSw = true;
    this.phrase.deletedReferences.push(ref);
    this.phrase.references.splice(this.phrase.references.indexOf(ref), 1);
  }

  undoPhraseEdit() {
    this.editPhraseText = this.phrase.phraseText;
    this.editPhraseTextWords = this.phrase.phraseWords;
    this.editModeSw = false;
  }

  onDeleteInstr(instr: AddInstr) {
    this.element.addInstr.splice(this.element.addInstr.indexOf(instr), 1);
  }

  onDeleteClicked() {
    if (this.indModeSw) {
      this.loading = true;
      this.catService.updIndDocPhrase(this.element, true).subscribe(
        (data) => {
          this.loading = false;
          this.onDelete.emit();
        },
        (err) => (this.loading = false)
      );
    } else {
      let validationStr = this.translateService.instant(
        'translate.cat.deleteEntry'
      );

      this.sharedService.emitPopupchReceivedEvent({
        title: 'translate.validation.confirm',
        icon: 'exclamation-triangle',
        subTitle: validationStr,
        keep: false,
        approve: () => {
          this.loading = true;
          this.catService.updCatEntry(this.element, true).subscribe(
            (data) => {
              this.loading = false;
              this.onDelete.emit();
            },
            (err) => (this.loading = false)
          );
        },
      });
    }
  }

  validateBeforeSave(): boolean {
    if (this.phrase && !this.editPhraseText) {
      return false;
    } else {
      return true;
    }
  }

  onPhraseRefAdd(phrase: IndPhrase) {
    let ref = new ReferencePhrase();
    ref.refPhraseId = phrase.phraseId;
    ref.refPhraseText = phrase.phraseText;
    ref.newSw = true;
    ref.pointerWordId =
      this.phrase.phraseWords[this.phrase.phraseWords.length - 1].id;
    this.phrase.references.push(ref);
    this.displayRefOptions = false;
  }

  onPhraseRefRemove(phrase: IndPhrase) {
    let idx = this.phrase.references.findIndex(
      (r) => r.refPhraseId == phrase.phraseId
    );
    if (idx != -1) {
      this.phrase.references.splice(idx, 1);
    }
  }

  removeExtRef(extRef: ExtRef) {
    this.catService.updExtRefPointer(extRef, true).subscribe(
      (data) => {
        this.phrase.extRefs.splice(this.phrase.extRefs.indexOf(extRef), 1);
        this.ngRedux.dispatch({
          type: actionsList.RELOAD_CAT_DOC_PAGE_REQ,
          data: false,
        });
      },
      (err) => {}
    );
  }

  currentIndex: number = 0;

  getCurrentMatch(): CatMatch {
    return this.catMatches[this.currentIndex];
  }

  prevMatch() {
    if (this.currentIndex > 0) {
      this.currentIndex--;
    }
  }

  nextMatch() {
    if (this.currentIndex < this.catMatches.length - 1) {
      this.currentIndex++;
    }
  }

  get catMatches(): any[] {
    return this.element?.catMatches || [];
  }

  getCurrentSimilarity(): number {
    return this.catMatches[this.currentIndex]?.similarityPct || 0;
  }

  getPrevSimilarity(): number {
    return this.catMatches[this.currentIndex - 1]?.similarityPct || 0;
  }

  getNextSimilarity(): number {
    return this.catMatches[this.currentIndex + 1]?.similarityPct || 0;
  }

  getReferenceIssue(phraseId: number, refPhraseId: number): any {
    return this.phrase.referenceIssues?.find(
      (issue => issue.catPhraseId === phraseId && issue.refPhraseId === refPhraseId) ||
      (issue => issue.catPhraseId === phraseId && issue.refFinding === 'ADDED_REFERENCE')
    );
  }

  getAddedReferenceIssue(phraseId: number): any[] {
    return this.phrase.referenceIssues?.filter(
      issue => issue.catPhraseId === phraseId && issue.refFinding === 'ADDED_REFERENCE'
    ) || [];
  }

  getMissingReferenceIssue(phraseId: number): any[] {
    return this.phrase.referenceIssues?.filter(
      issue => issue.catPhraseId === phraseId && issue.refFinding === 'MISSING_REFERENCE'
    ) || [];
  }

  getCatPhraseReference(catRefPhraseId: number): any {
    return this.element.catMatches[this.currentIndex]?.catPhrase?.references?.find(
      ref => ref.refPhraseId === catRefPhraseId
    );
  }

  onAddToWhitelist(word: string) {
    this.wordsToWhitelist.push(word);
  }
}
