<modal-popup
  [flexible]="true"
  [focusOnOpen]="false"
  [isOpen]="true"
  (onClose)="close()"
  [title]="'translate.cat.importClaimsExcel' | translate"
>
  <div class="container">
    <!--file upload-->
    <div class="upload-container">
      <input-wrap
        *ngIf="!isUploading && !fileName"
        [customValidation]="isValidFile"
        [customValidationWarn]="'translate.validation.importClaimsFileTypes' | translate"
        [cssClass]="'l-ta-center input-wrap-hasFileSpread'"
      >
        <input
          id="uploadFileInput"
          (change)="uploadFile($event)"
          class="t-fileInput-spread"
          type="file"
          accept=".xlsx"
        />

        <button class="t-button">
          {{ "translate.general.selectFile" | translate }}
        </button>
      </input-wrap>
      <spinner *ngIf="isUploading" class="l-ta-center"></spinner>
      <p *ngIf="fileName">
        <span>{{ fileName }}</span>
        <a (click)="removeFile()">
          ({{ "translate.general.remove" | translate }})</a
        >
      </p>
    </div>

    <div class="actions">
      <button class="t-button-outline" (click)="close()">
        {{ "translate.general.cancel" | translate }}
      </button>
      &nbsp;
      <button
        class="t-button"
        [disabled]="!enableSubmit || importing"
        (click)="importClaims()"
      >
        {{ "translate.general.ok" | translate }}
      </button>
    </div>
  </div>

  <loader *ngIf="importing"></loader>
</modal-popup>
