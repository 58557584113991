<div *ngIf="lc">
  <section class="page-header">
    <div class="title-container">
      <h1>
        <a class="icon" (click)="goBack()"
          ><fa-icon [icon]="['fas', 'arrow-left']"></fa-icon
        ></a>
        &nbsp;
        {{ lc.title }}
      </h1>

      <button (click)="publish()" class="t-button" [disabled]="loading">
        {{ "translate.lc.publish" | translate }}
      </button>
    </div>
  </section>

  <section>
    <data-box
      [title]="'translate.lc.lcDetails'"
      class="w-full"
      [data]="lc"
      [displayFilter]="['lcId', 'crDate', 'products', 'catalog', 'descr']"
      [columns]="2"
      [templateRefs]="{
        products: products,
        catalog: catalog
      }"
    ></data-box>
  </section>

  <ng-template #products>
    {{ lc.product.text }}
  </ng-template>

  <ng-template #catalog>
    {{ lc.catalog.catDescr }}
  </ng-template>

  <div class="t-row"></div>

  <loader *ngIf="loading"></loader>
  <section *ngIf="!loading" class="tabs">
    <tabs #lcTabs>
      <tab-single
        tabTitle="{{ 'translate.lc.bcDocs' | translate }} {{ bcDocsCnt }}"
        [tabId]="tabIdList.LC_BC_DOCS"
        [link]="'main/lc/lc-docs/bc'"
      >
      </tab-single>

      <tab-single
        tabTitle="{{ 'translate.lc.acDocs' | translate }} {{ acDocsCnt }}"
        [tabId]="tabIdList.LC_AC_DOCS"
        [link]="'main/lc/lc-docs/ac'"
      >
      </tab-single>

      <tab-single
        tabTitle="{{ 'translate.lc.bcCards' | translate }} {{ bcCardsCnt }}"
        [tabId]="tabIdList.LC_BC_CARDS"
        [link]="'main/lc/lc-cards/bc'"
      >
      </tab-single>

      <tab-single
        tabTitle="{{ 'translate.lc.bcPhrases' | translate }} {{ bcPhrasesCnt }}"
        [tabId]="tabIdList.LC_BC_PHRASES"
        [link]="'main/lc/lc-phrases/bc'"
      >
      </tab-single>

      <tab-single
        tabTitle="{{ 'translate.lc.acCards' | translate }} {{ acCardsCnt }}"
        [tabId]="tabIdList.LC_AC_CARDS"
        [link]="'main/lc/lc-cards/ac'"
      >
      </tab-single>

      <tab-single
        tabTitle="{{ 'translate.lc.acPhrases' | translate }} {{ acPhrasesCnt }}"
        [tabId]="tabIdList.LC_AC_PHRASES"
        [link]="'main/lc/lc-phrases/ac'"
      >
      </tab-single>
    </tabs>
  </section>
</div>
