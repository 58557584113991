<section class="page-header">
  <div class="title-container">
    <h1>{{ "translate.mng.mngProducts" | translate }}</h1>
  </div>
</section>

<div class="header-actions">
  <button class="t-button-outline" (click)="addProduct()">
    {{ "translate.mng.addProduct" | translate }}
  </button>
</div>

<loader *ngIf="!productList || productList.loading"></loader>

<ui-table
  [isWrapped]="false"
  [data]="productList"
  [numberOfRows]="10"
  [displayFilter]="['id', 'productDescr']"
  [templates]="[
    {
      title: 'translate.general.environments',
      template: environments
    },
    {
      title: 'translate.general.actions',
      template: actions
    }
  ]"
></ui-table>

<ng-template #environments let-row="row">
  <p>{{ row.envListStr }}</p>
</ng-template>

<ng-template #actions let-row="row">
  <a (click)="editProduct(row)">{{ "translate.general.edit" | translate }}</a>
</ng-template>

<edit-product
  *ngIf="editProductOpenSw"
  [product]="curProduct"
  (onClose)="editProductOpenSw = false"
  (onSave)="editProductOpenSw = false; getProductList()"
>
</edit-product>
