import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import {
  IndPhrase,
  CatDoc,
  IndImage,
  IndElement,
  CatDocStateTagEnum,
  IndPhraseSearchParams,
} from 'src/app/shared/models/cat.model';
import { NgRedux } from '@redux/redux-compatibility.service';
import { AppState, actionsList } from 'src/app/shared/redux/store';
import { CatService } from 'src/app/shared/services/cat.service';
import { Unsubscribe } from 'redux';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/shared/services/auth.service';
import { SearchRunStatusEnum } from 'src/app/shared/models/common';
import { SharedService } from 'src/app/shared/services/shared.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'cat-doc-phrase-list',
  templateUrl: './cat-doc-phrase-list.component.html',
  styleUrls: ['./cat-doc-phrase-list.component.scss'],
})
export class CatDocPhraseListComponent implements OnInit {
  @Input() editable: boolean = true;
  @Input() main: boolean = true;
  @Input() stateTag: CatDocStateTagEnum = CatDocStateTagEnum.PRIME;

  loading: boolean;
  @Output() onTogglePhraseView = new EventEmitter<IndPhrase>();
  @Output() onHidePhrase = new EventEmitter<IndElement>();
  @Output() onShowPhrase = new EventEmitter<IndElement>();

  displayPageNum: boolean;
  un: Unsubscribe;
  getDataSub: Subscription;
  searchStatusTimer: any;
  searchStatusIntervalSec: number = 2;
  sub: Subscription;

  get phraseList(): IndPhrase[] {
    // return this.ngRedux.getState().indDocPhrases;
    return this.stateTag == CatDocStateTagEnum.PRIME
      ? this.ngRedux.getState().curCatDocStatePrime.indDocPhrases
      : this.ngRedux.getState().curCatDocStateSec.indDocPhrases;
  }

  get imageList(): IndImage[] {
    //return this.ngRedux.getState().indDocImages;
    return this.stateTag == CatDocStateTagEnum.PRIME
      ? this.ngRedux.getState().curCatDocStatePrime.indDocImages
      : this.ngRedux.getState().curCatDocStateSec.indDocImages;
  }

  get curCatDocPageNum(): number {
    return this.stateTag == CatDocStateTagEnum.PRIME
      ? this.ngRedux.getState().curCatDocStatePrime.curCatDocPageNum
      : this.ngRedux.getState().curCatDocStateSec.curCatDocPageNum;
  }

  get curCatDoc(): CatDoc {
    return this.stateTag == CatDocStateTagEnum.PRIME
      ? this.ngRedux.getState().curCatDocStatePrime.curCatDoc
      : this.ngRedux.getState().curCatDocStateSec.curCatDoc;
  }

  get phraseSearchParams(): IndPhraseSearchParams {
    return this.stateTag == CatDocStateTagEnum.PRIME
      ? this.ngRedux.getState().curCatDocStatePrime.catPhrasesSearchParams
      : this.ngRedux.getState().curCatDocStateSec.catPhrasesSearchParams;
  }

  constructor(
    private ngRedux: NgRedux<AppState>,
    private catService: CatService,
    private authService: AuthService,
    private sharedService: SharedService,
    private translateService: TranslateService
  ) {
    this.un = this.ngRedux.subscribe(() => {
      const appState = this.ngRedux.getState();
      switch (appState._currentAction) {
        case actionsList.FILTER_CAT_DOC_PHRASES:
          if (appState._currentActionCatDocStateTag != this.stateTag) return;
          this.displayPageNum = true;
          this.getData(null);
          break;
        case actionsList.SELECT_CAT_DOC_PAGE_NUM:
          if (appState._currentActionCatDocStateTag != this.stateTag) return;

          this.displayPageNum = false;
          this.getData(this.curCatDocPageNum);
          break;
        case actionsList.RELOAD_CAT_DOC_PAGE_REQ:
          this.getData(this.curCatDocPageNum);
          break;
      }
    });
  }

  isMine(currentActionData: any) {
    return (
      (currentActionData.main && this.main) ||
      (!currentActionData.main && !this.main)
    );
  }

  ngOnInit(): void {
    this.getData(this.curCatDocPageNum);
  }

  ngOnDestroy(): void {
    if (this.un) {
      this.un();
    }
    this.sub?.unsubscribe();
  }

  getData(pageNum: number) {
    this.getDataSub?.unsubscribe();
    this.sub?.unsubscribe();

    this.loading = true;

    this.ngRedux.dispatch({
      type: actionsList.UPD_DOC_PAGE_LOADING,
      data: true,
    });

    this.ngRedux.dispatch({
      type: actionsList.RUN_IND_PHRASES_SEARCH,
      catDocSTateTag: this.stateTag,
    });

    this.getDataSub = this.catService
      .getIndPhrases(this.curCatDoc.docId, pageNum, this.phraseSearchParams)
      .subscribe(
        (searchId) => {
          console.log(searchId);
          this.pullSearchStatus(
            searchId,
            () => {
              this.catService
                .getIndPhrasesSearch(searchId, this.stateTag)
                .subscribe(
                  (data) => {
                    this.loading = false;
                    this.ngRedux.dispatch({
                      type: actionsList.UPD_DOC_PAGE_LOADING,
                      data: false,
                    });
                  },
                  (err) => {
                    this.loading = false;
                    this.ngRedux.dispatch({
                      type: actionsList.UPD_DOC_PAGE_LOADING,
                      data: false,
                    });
                  }
                );
            },
            () => {
              this.loading = false;
              this.ngRedux.dispatch({
                type: actionsList.UPD_DOC_PAGE_LOADING,
                data: false,
              });
              this.sharedService.alertError(
                this.translateService.instant('translate.alert.searchError'),
                null,
                null,
                'get_ind_phrases_func'
              );
            }
          );
        },
        (err) => {
          this.loading = false;
          this.ngRedux.dispatch({
            type: actionsList.UPD_DOC_PAGE_LOADING,
            data: false,
          });
        }
      );
  }

  pullSearchStatus(searchId: number, onFinished, onError) {
    this.sub?.unsubscribe();
    this.sub = this.authService.getSearchStatus(searchId).subscribe(
      (status) => {
        if (status == SearchRunStatusEnum.FINISHED) {
          onFinished();
        } else if (status == SearchRunStatusEnum.ERROR) {
          onError();
        } else {
          setTimeout(() => {
            this.pullSearchStatus(searchId, onFinished, onError);
          }, this.searchStatusIntervalSec * 1000);
        }
      },
      (err) => {
        onError();
      }
    );
  }

  onTogglePhraseViewClicked(phrase: IndPhrase) {
    this.onTogglePhraseView.emit(phrase);
  }

  onGoToPhrasePage(phrase: IndPhrase) {
    this.onTogglePhraseView.emit(phrase);
  }

  onMouseEnter(element: IndElement) {
    this.onShowPhrase.emit(element);
  }

  onMouseLeave(element: IndElement) {
    if (!element.viewSw) this.onHidePhrase.emit(element);
  }

  get isSearchActive() {
    let searchParams = this.phraseSearchParams;
    return searchParams && 
      (searchParams.phrase 
        || searchParams.phraseStatus 
        || searchParams.matchStatus 
        || searchParams.contentType 
        || searchParams.matchType 
        || (searchParams.subTypeList && searchParams.subTypeList.length > 0));
  }

  reloadData() {
    this.getData(this.curCatDocPageNum);
  }
}
