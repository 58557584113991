<modal-popup
  [flexible]="true"
  [focusOnOpen]="focusOnOpen"
  [isOpen]="true"
  (onClose)="close()"
  [title]="'translate.general.revRuleDetails'"
  [afterTitle]="dirtyBtns"
>
  <div *ngIf="curRule" class="container">
    <div class="l-align-spaceBetween">
      <h3 *ngIf="curRule.similarityCode !== 'MISSING'">
        <span *ngIf="isPhrase">
          {{ "translate.general.workingPhrase" | translate }}</span
        >
        <span *ngIf="!isPhrase">
          {{ "translate.general.workingImage" | translate }}</span
        >
      </h3>
      <h3 *ngIf="curRule.similarityCode == 'MISSING'">
        {{ "translate.rev.missing" | translate }}:
      </h3>
      <p
        *ngIf="getMatchStatusCode()"
        [ngClass]="['rev-rule-status', getMatchStatusCode()]"
      >
        {{ getMatchStatusDescr() }}
      </p>
      <spinner
        *ngIf="!curRule.revStatusCode"
        class="rev-rule-status-spinner"
      ></spinner>
    </div>

    <div *ngIf="curRule.revRuleType != 'BX_MATCH'">
      <p class="phrase-txt">
        <formatted-phrase
          *ngIf="isPhrase"
          [phraseWords]="curRule.ruleDispWords"
          [grammarSpellingIssues]="grammarSpellingIssues"
          [referenceIssues]="referenceIssues"
        ></formatted-phrase>

        <async-img
          *ngIf="isImage"
          [className]="'inner-img'"
          [url]="curRule.imgUrl"
        ></async-img>

        <!-- <img *ngIf="isImage" class="ind-img" [src]="curRule.imgUrl" /> -->
      </p>
    </div>

    <div *ngIf="curRule.revRuleType == 'BX_MATCH'">
      <p class="fw-semibold">
        {{ "translate.general.boundingBox" | translate }} &middot;
        {{ curRule.boundingBoxMatch.boundingBox.boxType.text }}
      </p>
      <span> {{ curRule.boundingBoxMatch.boundingBox.descr }}</span>
    </div>

    <div
      *ngIf="isPhrase && curRule.references && !curRule.isRefSw"
      class="reference"
    >
      <p class="title">{{ "translate.rev.reference" | translate }}:</p>
      <div *ngFor="let ref of curRule.references" class="ref-container">
        <p *ngIf="ref.phraseDispWords.length == 0" class="text">
          {{ ref.refPhraseText }}
        </p>
        <formatted-phrase
          *ngIf="ref.phraseDispWords.length > 0"
          class="text"
          [phraseWords]="ref.phraseDispWords"
        ></formatted-phrase>

        <p class="ref-id">
          {{ "translate.cat.phraseId" | translate }}: {{ ref.refPhraseId }}
        </p>
      </div>
    </div>

    <div
      *ngIf="isPhrase && curRule.referencedBy && curRule.isRefSw"
      class="reference"
    >
      <p class="title">{{ "translate.rev.referencedBy" | translate }}:</p>
      <div *ngFor="let ref of curRule.referencedBy" class="ref-container">
        <p *ngIf="ref.phraseDispWords.length == 0" class="text">
          {{ ref.refPhraseText }}
        </p>
        <formatted-phrase
          *ngIf="ref.phraseDispWords.length > 0"
          [phraseWords]="ref.phraseDispWords"
          class="text"
        ></formatted-phrase>
      </div>
    </div>

    <div *ngIf="curRule.figureType" class="cat-phrase-type">
      <span>
        {{ curRule.figureTypeDescr }}
        <span *ngIf="curRule.figureSubType" class="sub-type"
          >&middot; {{ curRule.figureSubType.text }}</span
        >
      </span>
    </div>

    <div class="l-align-spaceBetween">
      <div class="l-align-spaceBetween">
        <p [ngClass]="['rev-rule-type-descr', curRule.revRuleType]">
          {{ curRule.revRuleSimilarityEventDesc || curRule.revRuleTypeDescr }}
        </p>
        <p class="cat-phrase-type">
          &nbsp; &middot; {{ curRule.revRuleContentTypeDesc || curRule.similarityDescr }}
        </p>
      </div>

      <p-selectButton
        *ngIf="editable"
        class="rev-status"
        [options]="ruleStatusOptions"
        [(ngModel)]="curRule.ruleUserStatus"
        (onChange)="onRevStatusChange()"
      ></p-selectButton>
    </div>

    <div class="comment-container">
      <p class="t-footnote t-row--small">
        {{ "translate.general.comment" | translate }}
      </p>
      <textarea
        class="comment"
        tabindex="0"
        [disabled]="!editable"
        pInputTextarea
        [(ngModel)]="curRule.comment"
        autoResize="true"
        (ngModelChange)="dirtySw = true"
      ></textarea>
    </div>

    <div>
      <ng-container *ngTemplateOutlet="formatMatchTemplate"></ng-container>

      <ng-container *ngTemplateOutlet="catTemplate"></ng-container>

      <div *ngIf="curRule.revRuleType == 'SPELLING'">
        <ng-container *ngTemplateOutlet="spellingTemplate"></ng-container>
      </div>

      <div *ngIf="curRule.cardMatch">
        <ng-container *ngTemplateOutlet="cardMatchTemplate"></ng-container>
      </div>
    </div>

    <p class="footnote id">
      {{ "translate.rev.ruleId" | translate }}: {{ curRule.revRuleId }}
    </p>
    <loader *ngIf="loading"></loader>
  </div>
</modal-popup>

<ng-template #catTemplate>
  <ng-container *ngIf="!isPotentialNew">
    <div class="template-container" *ngFor="let match of curRule.ruleCatMatches">
      <cat-match
        [match]="match" [referenceIssues]="curRule.referenceIssues"
        [displayReportMatch]="curRule.revStatusCode == 'MARKED_FOR_CHANGES'"
        (onReportDisplaySwChange)="onReportDisplaySwChange(match)"
      >
      </cat-match>
    </div>
  </ng-container>

  <div *ngIf="isPotentialNew || curRule.ruleCatMatches.length == 0" class="template-container">
    <h3>
      {{ "translate.rev.noMatchesFound" | translate }}
    </h3>
  </div>
</ng-template>

<ng-template #spellingTemplate>
  <div class="template-container">
    <h3>{{ "translate.rev.suggestedFix" | translate }}</h3>
    <p class="template-phrase-txt">
      <span *ngFor="let word of curRule.ruleDiffParts">{{ word.text }}</span>
    </p>
  </div>
</ng-template>

<ng-template #cardMatchTemplate>
  <card-match [cardMatch]="curRule.cardMatch"></card-match>
</ng-template>

<ng-template #formatMatchTemplate>
  <div *ngIf="formatMatchEntry.length" class="template-container">
    <div class="l-align-spaceBetween">
      <h3>
        {{ "translate.general.formatMatch" | translate }}
      </h3>
    </div>

    <div class="template-phrase-txt">
      <formatted-phrase
        [phraseWords]="formatMatchEntry"
        [formatType]="'formatMatchEntry'"
        [formatMatchDifferences]="formatMatchDifferences"
        [highlightedIds]="highlightedIds"
        (highlightedIdsChange)="highlightDiff($event)"
      ></formatted-phrase>
    </div>

    <div class="diff-container">
      <div class="diff-title">
        <i class="fas fa-not-equal"></i>
        &nbsp; &nbsp;
        <p>{{ "translate.general.diff" | translate }}</p>
      </div>
      <p class="template-phrase-txt">
        <formatted-phrase
          [phraseWords]="formatMatchPhrase"
          [formatType]="'formatMatchPhrase'"
          [formatMatchDifferences]="formatMatchDifferences"
          [highlightedIds]="highlightedIds"
          (highlightedIdsChange)="highlightDiff($event)"
        ></formatted-phrase>
      </p>
    </div>
    <table class="diffTable">
      <thead>
        <tr>
          <th>Phrase</th>
          <th>
            <i class="fas fa-not-equal"></i>&nbsp; &nbsp;{{ "translate.general.diff" | translate }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr [class.diffRowHighlight]="isHighlighted(difference.ids)" *ngFor="let difference of formatMatchDifferences" (mouseenter)="highlightDiff(difference.ids)" (mouseleave)="clearHighlight()">
          <td><span [tooltip]="difference.text">{{ difference.text | truncate:6:'...' }}</span></td>
          <td>
            <ng-container *ngFor="let attr of difference | keyvalue">
              <div class="diffItem" *ngIf="attr.key !== 'text' && attr.key !== 'ids'">
                <span class="diffTag">{{ getFriendlyDifferenceName(attr.key) }}</span>
                <span class="diffContent" [ngSwitch]="attr.key">
                  <ng-container *ngSwitchCase="'font_size'">
                    {{ attr.value[0] }} -> {{ attr.value[1] }}
                  </ng-container>
                  <ng-container *ngSwitchCase="'font_name'" style="font-size: 0.6em">
                    {{ getFriendlyFontName(attr.value[0]) }} -> {{ getFriendlyFontName(attr.value[1]) }}
                  </ng-container>
                  <ng-container *ngSwitchCase="'color_hex'">
                    <span class="color-box" [tooltip]="'Hex Color Code: ' + attr.value[0]" [style.background-color]="attr.value[0]"></span> &nbsp; -> &nbsp;
                    <span class="color-box" [tooltip]="'Hex Color Code: ' + attr.value[1]" [style.background-color]="attr.value[1]"></span>
                  </ng-container>
                  <ng-container *ngSwitchDefault style="font-family: 'Arial', sans-serif; font-weight: bolder;">
                    {{ attr.value[0].toLowerCase() === 'true' ? '✓' : '✗' }} -> {{ attr.value[1].toLowerCase() === 'true' ? '✓' : '✗' }}
                  </ng-container>
                </span>
              </div>
            </ng-container>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

</ng-template>

<ng-template #dirtyBtns>
  <div *ngIf="dirtySw" class="t-small">
    <button
      class="t-button t-button-warn"
      [disabled]="loading"
      (click)="saveChanges()"
    >
      {{ "translate.general.saveChanges" | translate }}
    </button>
    &nbsp;
    <button
      class="t-button-outline t-button-warn"
      [disabled]="loading"
      (click)="discardChanges()"
    >
      {{ "translate.general.discardChanges" | translate }}
    </button>
  </div>
</ng-template>
