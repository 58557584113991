<modal-popup
  [flexible]="true"
  [focusOnOpen]="false"
  [isOpen]="true"
  (onClose)="close()"
  [title]="'translate.cat.createCardTemplate'"
>
  <div class="container">
    <!--name-->
    <input-wrap
      label="{{ 'translate.general.name' | translate }}"
      [required]="true"
      cssClass="l-cell-spread"
    >
      <input type="text" [(ngModel)]="data.name" class="t-input" />
    </input-wrap>

    <!--asset type-->
    <input-wrap
      label="{{ 'translate.general.assetType' | translate }}"
      cssClass="l-cell-spread"
      [required]="true"
      [customValidation]="data.assetType"
    >
      <p-dropdown
        [options]="assetTypes"
        optionLabel="text"
        [(ngModel)]="data.assetType"
        class="l-cell-spread-x"
        [placeholder]="'translate.general.select' | translate"
        appendTo="body"
        baseZIndex="100"
      >
      </p-dropdown>
    </input-wrap>

    <!--descr-->
    <input-wrap
      label="{{ 'translate.general.descr' | translate }}"
      cssClass="l-cell-spread"
    >
      <textarea
        pInputTextarea
        [(ngModel)]="data.descr"
        autoResize="false"
      ></textarea>
    </input-wrap>

    <div class="actions">
      <button class="t-button-outline" (click)="close()">
        {{ "translate.general.cancel" | translate }}
      </button>
      &nbsp;
      <button
        class="t-button"
        [disabled]="!enableSubmit || saving"
        (click)="save()"
      >
        {{ "translate.general.ok" | translate }}
      </button>
    </div>
  </div>

  <loader *ngIf="saving"></loader>
</modal-popup>
