import { NgRedux } from '@redux/redux-compatibility.service';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Unsubscribe } from 'redux';
import { PullDocProcessComponent } from 'src/app/shared/components/pull-doc-process/pull-doc-process.component';
import { CatDoc, CatDocStateTagEnum } from 'src/app/shared/models/cat.model';
import {
  PaginationParams,
  ProcessRunStatusEnum,
} from 'src/app/shared/models/common';
import {
  TaggedDocsSearchParams,
  TaggedIndDoc,
} from 'src/app/shared/models/tagged_doc.model';
import { actionsList, AppState } from 'src/app/shared/redux/store';
import { AuthService } from 'src/app/shared/services/auth.service';
import { CatService } from 'src/app/shared/services/cat.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { UploadService } from 'src/app/shared/services/upload.service';
import { threadId } from 'worker_threads';

@Component({
  selector: 'cat-tag-docs',
  templateUrl: './cat-tag-docs.component.html',
  styleUrls: ['./cat-tag-docs.component.scss'],
})
export class CatTagDocsComponent extends PullDocProcessComponent {
  @Input() type: 'IND' | 'REV';

  un: Unsubscribe;
  loading: boolean;
  rangeFrom: number;
  rangeTo: number;
  totalRecords: number;
  searchParams: TaggedDocsSearchParams = new TaggedDocsSearchParams();
  displayProcessStatus: boolean;
  curDoc: CatDoc;

  get data(): TaggedIndDoc[] {
    return this.ngRedux.getState().tagIndDocs;
  }

  constructor(
    private ngRedux: NgRedux<AppState>,
    private catService: CatService,
    private uploadService: UploadService,
    private router: Router,
    private sharedService: SharedService,
    authService: AuthService
  ) {
    super(authService);

    this.un = this.ngRedux.subscribe(() => {
      const appState = this.ngRedux.getState();
      switch (appState._currentAction) {
        case actionsList.RUN_TAG_DOCS_SEARCH:
          this.getData(true);
          break;
      }
    });
  }

  ngOnDestroy(): void {
    this?.un();
    this.processStatusTimers.forEach((t) => clearInterval(t));
  }

  getData(newSearch: boolean) {
    this.loading = true;

    if (newSearch) {
      this.searchParams.searchId = null;
      this.searchParams.rangeFrom = 1;
      this.searchParams.rangeTo = 10;
    }

    this.searchParams.envId = this.ngRedux.getState().tagDocSearchParams.envId;
    this.searchParams.docIdName =
      this.ngRedux.getState().tagDocSearchParams.docIdName;

    this.catService.getIndTaggedDocs(this.searchParams).subscribe(
      (data) => {
        this.loading = false;
        let processingDocs = this.data.filter(
          (d) =>
            d.replicatedDoc &&
            d.replicatedDoc.isProcessRunning &&
            d.replicatedDoc.runNum
        );
        this.handleProcessingDocs(
          processingDocs,
          () => {
            this.getData(false);
          },
          true
        );
      },
      (err) => (this.loading = false)
    );
  }

  onPagination(event: PaginationParams) {
    if (
      !this.data ||
      (event.rangeFrom == this.searchParams.rangeFrom &&
        event.rangeTo == this.searchParams.rangeTo)
    )
      return;

    this.searchParams.rangeFrom = event.rangeFrom;
    this.searchParams.rangeTo = event.rangeTo;
    this.searchParams.searchId = this.ngRedux.getState().lcMetadata.searchId;

    this.getData(false);
  }

  view(doc: TaggedIndDoc) {
    this.viewCatDoc(doc);
  }

  replicate(doc: TaggedIndDoc) {
    doc.processing = true;
    this.catService.replicateDoc(doc.docId, this.type).subscribe(
      (docS3Key) => {
        let newDoc = new CatDoc();
        newDoc.docName = doc.docName;
        newDoc.parentDocId = doc.parentDocId;
        newDoc.productId = doc.productId;
        newDoc.catId = doc.catId;
        newDoc.catVersionId = doc.catVersionId;
        newDoc.docS3Key = docS3Key;
        newDoc.fileName = doc.fileName;
        newDoc.assetType = doc.assetType;
        newDoc.createDocType = 'TAG';
        newDoc.taggedDocId = doc.docId;
        this.catService.createIndDoc(newDoc).subscribe(
          (savedDoc) => {
            savedDoc.docS3Key = newDoc.docS3Key;
            let env = this.ngRedux
              .getState()
              .envList.find((e) => e.id == doc.env[0].id);

            this.uploadService
              .encryptFile(
                savedDoc.origDocId,
                this.type,
                env.tmpS3Bucket,
                env.docS3Bucket,
                savedDoc.docS3Key
              )
              .subscribe(
                (res) => {
                  this.catService.processIndDocAlg(savedDoc.runNum).subscribe();
                  doc.processing = false;
                  this.getData(false);
                },
                (err) => {
                  doc.processing = false;
                }
              );
          },
          (err) => (doc.processing = false)
        );
      },
      (err) => (doc.processing = false)
    );
  }

  viewReplicated(doc: TaggedIndDoc) {
    this.viewCatDoc(doc.replicatedDoc);
  }

  viewCatDoc(doc: CatDoc) {
    this.ngRedux.dispatch({
      type: actionsList.SELECT_CATALOG,
      data: doc.catalog,
    });

    this.ngRedux.dispatch({
      type: actionsList.SELECT_CAT_DOC,
      data: doc,
      catDocSTateTag: CatDocStateTagEnum.PRIME,
    });

    this.router.navigate([this.sharedService.routeEnum.URL_CAT_DOC]);
  }

  openProcessStatus(doc: CatDoc) {
    this.displayProcessStatus = true;
    this.curDoc = doc;
  }

  remove(doc: TaggedIndDoc) {
    this.catService.updTaggedDoc(doc, true).subscribe(() => {
      this.getData(false);
    });
  }
}
