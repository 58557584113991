import { PaginationParams, DataElement } from './common';
import { SelectItem } from 'primeng/api';

export class UiUser {
  username: string;
  userId: number;
  userDescr: string;
  curEnvId: number;
  phone: string;
  password: string;
  lang: string;
  authDetails: AuthDetails = new AuthDetails();
  token: number;
  areaCode: string;
  globalRole: string;
  timezone: string;
  timezoneName: string;

  // cognito params
  firstName: string;
  lastName: string;
  accessJwtToken: string;
  idJwtToken: string;
  refreshToken: string;
  refreshTokenObject: any;
  isLoggedIn: boolean = false;
  cognitoUserObject: any;

  get isAdmin(): boolean {
    return this.globalRole == 'ADMIN';
  }

  constructor() {
    this.isLoggedIn = true;
  }
}

export class AuthDetails {
  constructor() {}

  getStringValue(key: string): string {
    let value = this[key];
    return value ? value : '';
  }

  getBooleanValue(key: string): boolean {
    let value = this[key] as String;
    if (value == '0') return false;
    return !!value;
  }

  setValue(key: string, value: string) {
    this[key] = value;
  }

  revScreenAllowed(): boolean {
    return this.getBooleanValue('REV_SCR.ALLOWED_SW');
  }

  catScreenAllowed(): boolean {
    return this.getBooleanValue('CAT_SCR.ALLOWED_SW');
  }

  lcScreenAllowed(): boolean {
    return this.getBooleanValue('LC_SCR.ALLOWED_SW');
  }
}

export class UsersSearchParams extends PaginationParams {
  username?: string;
  clinic?: DataElement;
  plant?: DataElement;
  role?: DataElement;
  org?: string;
  constructor() {
    super();
  }
}

export class UserRoleEnum {
  static ADMIN: string = 'ADMIN';
  static AGENCY: string = 'AGENCY';
}
