<ui-table
  [isWrapped]="false"
  [data]="docs"
  [displayFilter]="[
    'origDocId',
    'docName',
    'productDescr',
    'catDescr',
    'createDate'
  ]"
  [templates]="[
    {
      title: 'translate.lc.blocksTaggedSw',
      template: blocksTaggedSw
    },
    {
      title: 'translate.general.status',
      template: matchStatus
    },
    {
      title: 'translate.general.actions',
      template: actions
    }
  ]"
></ui-table>

<ng-template #blocksTaggedSw let-row="row">
  <i *ngIf="hasBlocksTagged(row)" class="fas fa-check"></i>
</ng-template>

<ng-template #matchStatus let-row="row">
  <p
    *ngIf="row.lcMatchStatusCode"
    [ngClass]="['match-status', row.lcMatchStatusCode]"
  >
    {{ row.lcMatchStatusDescr }}
  </p>
</ng-template>

<!-- <ng-template #trainingSw let-row="row">
  <checkbox-wrap class="checkbox" [class.dirty]="row.dirtySw">
    <input
      type="checkbox"
      [(ngModel)]="row.trainingSw"
      (ngModelChange)="onTrainingSwChanged(row)"
    />
  </checkbox-wrap>
</ng-template> -->

<ng-template #actions let-row="row">
  <!--actions-->
  <div
    *ngIf="!row.isProcessRunning && row.runStatusCode != 'FAILED'"
    class="actions-container"
  >
    <a (click)="$event.stopPropagation(); viewDoc(row)">{{
      "translate.lc.viewDoc" | translate
    }}</a>

    <span class="action-divider">|</span>
    <a (click)="$event.stopPropagation(); viewDocDetails(row)">{{
      "translate.lc.viewDocDetails" | translate
    }}</a>

    <span class="action-divider">|</span>
    <a (click)="$event.stopPropagation(); removeDoc(row)">{{
      "translate.general.remove" | translate
    }}</a>
  </div>

  <!--failed-->
  <p *ngIf="row.runStatusCode == 'FAILED'" class="processing-status failed">
    ({{ "translate.general.processingFailed" | translate }})
  </p>

  <!--running-->
  <div *ngIf="row.isProcessRunning">
    <div *ngIf="row.processStatus" (click)="openProcessStatus(row)">
      <circle-progress
        [percent]="row.processStatus.completedPct"
      ></circle-progress>
    </div>
    <spinner *ngIf="!row.processStatus"></spinner>
    <p class="processing-status">
      <processing-label></processing-label>
    </p>
  </div>
</ng-template>

<process-run-status
  *ngIf="displayProcessStatus"
  (onClose)="displayProcessStatus = false"
  [doc]="curDoc"
  [status]="curDoc.processStatus"
>
</process-run-status>

<lc-doc-summary
  *ngIf="docSummaryOpenSw"
  (onClose)="docSummaryOpenSw = false; curDoc = null"
  [type]="type"
  [doc]="curDoc"
></lc-doc-summary>
