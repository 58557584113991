import { NgRedux } from '@redux/redux-compatibility.service';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { faGlasses } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import {
  BoundingBox,
  Card,
  CardSearchParams,
  Catalog,
  CatalogStatusEnum,
  CatDoc,
  CatDocStatusEnum,
} from 'src/app/shared/models/cat.model';
import {
  DataElement,
  ProcessRunStatusEnum,
} from 'src/app/shared/models/common';
import { actionsList, AppState } from 'src/app/shared/redux/store';
import { AuthService } from 'src/app/shared/services/auth.service';
import { CatService } from 'src/app/shared/services/cat.service';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'card-summary',
  templateUrl: './card-summary.component.html',
  styleUrls: ['./card-summary.component.scss'],
})
export class CardSummaryComponent {
  @Input() curCard: Card;
  @Input() lcSw: boolean;
  @Input() tileSw: boolean;

  downloadingFile: boolean;
  processStatusTimer: NodeJS.Timeout;
  exportProcessStatusTimer: NodeJS.Timeout;
  getProcessStatusIntervalSeconds: number = 3;
  transRunning: boolean;
  displayTransPopup: boolean;
  loading: boolean;
  langs: DataElement[] = [];
  selectedLang: DataElement;
  running: boolean;
  sub: Subscription;

  get card(): Card {
    return this.curCard ?? this.ngRedux.getState().curCard;
  }

  get curCat(): Catalog {
    return this.ngRedux.getState().curCat;
  }

  get allowEdit(): boolean {
    if (this.lcSw) return true;
    if (!this.curCat) return false;
    return this.curCat.statusCode == CatalogStatusEnum.DRAFT;
  }

  constructor(
    private ngRedux: NgRedux<AppState>,
    private catService: CatService,
    private authService: AuthService,
    private translateService: TranslateService,
    private sharedService: SharedService
  ) {}

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }

  ngOnInit(): void {
    let bbKeys = [];
    this.card.cardDocs.forEach((d) =>
      d.boundingBoxes.forEach((bb) => bbKeys.push(bb.s3Key))
    );
    this.catService.getS3Objects(bbKeys).subscribe();

    this.langs = SharedService.transLang.map(
      (v) => new DataElement(v, this.translateService.instant('transLang.' + v))
    );
    this.langs.srp_sortByKey('text');
  }

  deleteBox(doc: CatDoc, box: BoundingBox) {
    box.deleting = true;
    this.catService.updBoundingBox(null, null, box, true).subscribe(
      (data) => {
        let idx = doc.boundingBoxes.findIndex((b) => b.boxId == box.boxId);
        if (idx != -1) doc.boundingBoxes.splice(idx, 1);
        if (doc.boundingBoxes.length == 0) {
          idx = this.card.cardDocs.findIndex((d) => d.docId == doc.docId);
          this.card.cardDocs.splice(idx, 1);
        }
      },
      () => (box.deleting = false)
    );
  }

  // exportCardReport() {
  //   this.downloadingFile = true;
  //   this.catService.createMcReportReq(this.card).subscribe(
  //     (runNum) => {
  //       this.catService.startProcessRun(runNum).subscribe(
  //         (data) => {},
  //         (err) => {
  //           this.downloadFileError(err);
  //         }
  //       );

  //       this.processStatusTimer = setInterval(() => {
  //         this.authService.getProcessStatus(runNum).subscribe(
  //           (data) => {
  //             if (data.status == ProcessRunStatusEnum.FINISHED) {
  //               clearInterval(this.processStatusTimer);
  //               this.downloadingFile = false;

  //               let url = data.outDocUrl;
  //               console.log(url);

  //               var element = document.createElement('a');
  //               element.href = url;
  //               element.setAttribute(
  //                 'download',
  //                 'card_' + this.card.cardId.toString()
  //               );
  //               document.body.appendChild(element);
  //               element.click();
  //               document.body.removeChild(element);
  //             } else if (data.status == ProcessRunStatusEnum.FAILED) {
  //               this.downloadFileError(
  //                 this.translateService.instant('translate.cat.processFailed')
  //               );
  //             }
  //           },
  //           (err) => {
  //             this.downloadFileError(err);
  //           }
  //         );
  //       }, this.getProcessStatusIntervalSeconds * 1000);
  //     },
  //     (err) => {
  //       this.downloadFileError(err);
  //     }
  //   );
  // }

  onTranslateClicked() {
    this.displayTransPopup = true;
  }

  runTranslation() {
    this.transRunning = true;
    this.catService
      .createBlockTransRun(this.card.cardId, 'en', this.selectedLang.id)
      .subscribe(
        (runNum) => {
          this.displayTransPopup = false;
          this.catService.startProcessRun(runNum).subscribe(
            (data) => {
              this.processStatusTimer = setInterval(() => {
                this.authService.getProcessStatus(runNum).subscribe(
                  (data) => {
                    if (data.status == ProcessRunStatusEnum.FINISHED) {
                      clearInterval(this.processStatusTimer);
                      if (
                        !this.card.transLang.find(
                          (el) => el.id == this.selectedLang.id
                        )
                      ) {
                        this.card.transLang.push(this.selectedLang);
                        this.ngRedux.dispatch({
                          type: actionsList.CARD_TRANSLATE_FINISHED,
                        });
                      }
                      this.transRunning = false;
                    } else if (data.status == ProcessRunStatusEnum.FAILED) {
                      this.processError(
                        this.translateService.instant(
                          'translate.cat.processFailed'
                        )
                      );
                    }
                  },
                  (err) => {
                    this.processError(err);
                  }
                );
              }, this.getProcessStatusIntervalSeconds * 1000);
            },
            (err) => {
              this.processError(err);
            }
          );
        },
        (err) => {
          this.processError(err);
        }
      );
  }

  processError(err) {
    clearInterval(this.processStatusTimer);
    this.transRunning = false;
    this.sharedService.alertError(err);
  }

  runError(err) {
    clearInterval(this.exportProcessStatusTimer);
    this.running = false;
    this.sharedService.alertError(err);
  }

  extract() {
    this.running = true;
    this.catService
      .createCardExtractRun(
        this.card.cardId,
        this.ngRedux.getState().curCat.catVersionId
      )
      .subscribe(
        (runNum) => {
          this.catService.startProcessRun(runNum).subscribe(
            (data) => {},
            (err) => {
              this.runError(err);
            }
          );

          this.exportProcessStatusTimer = setInterval(() => {
            this.authService.getProcessStatus(runNum).subscribe(
              (data) => {
                if (data.status == ProcessRunStatusEnum.FINISHED) {
                  clearInterval(this.exportProcessStatusTimer);
                  this.running = false;
                  clearInterval(this.exportProcessStatusTimer);
                  this.reloadCurCard();
                } else if (data.status == ProcessRunStatusEnum.FAILED) {
                  this.runError(
                    this.translateService.instant('translate.cat.processFailed')
                  );
                }
              },
              (err) => {
                this.runError(err);
              }
            );
          }, this.getProcessStatusIntervalSeconds * 1000);
        },
        (err) => {
          this.runError(err);
        }
      );
  }

  reloadCurCard() {
    this.loading = true;
    let sp = new CardSearchParams();
    sp.dispatch = false;
    sp.cardId = this.card.cardId;
    this.catService.getCards(sp).subscribe(
      (data) => {
        this.ngRedux.dispatch({
          type: actionsList.SELECT_CAT_CARD,
          data: data[0],
        });
        this.loading = false;
      },
      (err) => (this.loading = false)
    );
  }

  export(doc: CatDoc) {
    this.running = true;
    this.catService.getS3Objects([doc.inddCardS3Key]).subscribe(
      () => {
        let url = this.catService.getS3TempObjectPresignedUrl(doc.inddCardS3Key);
        this.tryDownloadFile(url);
      },
      (err) => {
        this.running = false;
      }
    );

    // var element = document.createElement('a');
    // element.href = doc.inddCardUrl;
    // element.setAttribute('download', 'card_' + this.card.cardId.toString());
    // document.body.appendChild(element);
    // element.click();
    // document.body.removeChild(element);
  }

  tryDownloadFile(url: string) {
    this.sub?.unsubscribe();
    this.sub = this.catService.checkFileExists(url).subscribe(
      (exists) => {
        if (exists) {
          console.log(url);
          var element = document.createElement('a');
          element.href = url;
          element.setAttribute(
            'download',
            'card_' + this.card.cardId.toString()
          );
          document.body.appendChild(element);
          element.click();
          document.body.removeChild(element);
          this.running = false;
        } else {
          setTimeout(() => {
            this.tryDownloadFile(url);
          }, 100);
        }
      },
      (err) => {
        this.running = false;
      }
    );
  }
}
