<div class="template-container" [ngClass]="[cardMatch.matchStatusCode]">
  <div class="l-align-spaceBetween">
    <h3>
      {{ "translate.rev.detectedCard" | translate }}
    </h3>
    <p class="similarity" [ngClass]="[cardMatch.matchStatusCode]">
      {{ cardMatch.matchStatusDescr }}
    </p>
  </div>

  <card-display
    [card]="cardMatch.card"
    [cardType]="'REV'"
    [selectedBoxId]="cardMatch.boxId"
    [selectedPhraseId]="cardMatch.phraseId"
  ></card-display>
</div>

<div class="template-container">
  <h3>
    {{ "translate.rev.matchedCard" | translate }}
  </h3>
  <card-display
    [card]="cardMatch.mCard"
    [cardType]="'IND'"
    [selectedBoxId]="cardMatch.mBoxId"
    [selectedPhraseId]="cardMatch.mPhraseId"
    [displayMatchStatus]="true"
  ></card-display>
</div>
