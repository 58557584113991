<p class="card-title">
  <strong>{{ card.type.text }}</strong> &middot; {{ card.title }}
</p>

<div *ngFor="let doc of card.cardDocs">
  <div *ngFor="let box of doc.boundingBoxes" class="box-tile">
    <bb-tile-new
      id="{{ box.boxId }}"
      [box]="box"
      [doc]="doc"
      [allowEdit]="false"
      [displayIndPhrases]="true"
      [docType]="cardType"
      [selected]="box.boxId == selectedBoxId"
      [selectedPhraseId]="selectedPhraseId"
      [card]="card"
      [displayMatchStatus]="displayMatchStatus"
      [displayPhraseLink]="displayPhraseLink"
    >
    </bb-tile-new>
  </div>
</div>

<!-- [selected]="curBox != null && curBox.boxId == box.boxId" -->
