import { NgRedux } from '@redux/redux-compatibility.service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CatDoc, IndImage } from 'src/app/shared/models/cat.model';
import { CatMatch, Doc, DocPage, ReferenceIssue } from 'src/app/shared/models/common';
import { RevRule } from 'src/app/shared/models/rev.model';
import { AppState } from 'src/app/shared/redux/store';

@Component({
  selector: 'cat-match',
  templateUrl: './cat-match.component.html',
  styleUrls: ['./cat-match.component.scss'],
})
export class CatMatchComponent implements OnInit {
  @Input() match: CatMatch;
  @Input() referenceIssues: ReferenceIssue[] = [];
  @Input() displayReportMatch: boolean = false;

  @Output() onReportDisplaySwChange = new EventEmitter();

  displayDocsSw: boolean;

  get isImage() {
    //return this.curRule?.revRuleType == 'IMAGE_MATCH';
    return this.match.catImage;
  }

  get isTable() {
    //return this.curRule?.revRuleType == 'TABLE_MATCH';
    return this.match.catImage && this.match.catImage.figureType == 'TABLE';
  }

  get isPhrase() {
    return !this.isImage && !this.isTable;
  }

  get title(): string {
    if (this.isTable) {
      return 'translate.rev.matchedTable';
    } else if (this.isImage) {
      return 'translate.rev.matchedImg';
    } else {
      return 'translate.rev.matchedCatEntry';
    }
  }

  getReferenceIssue(phraseId: number, refPhraseId: number): any {
    return this.referenceIssues?.find(
      issue => issue.catPhraseId === phraseId && issue.catRefPhraseId === refPhraseId
    );
  }

  getAddedReferenceIssue(phraseId: number): any[] {
    return this.referenceIssues?.filter(
      issue => issue.catPhraseId === phraseId && issue.refFinding === 'ADDED_REFERENCE'
    ) || [];
  }

  constructor(private ngRedux: NgRedux<AppState>) {}

  ngOnInit(): void {
    this.referenceIssues =  this.referenceIssues?.filter(issue => issue.catPhraseId === this.match.catPhrase.phraseId);
  }

  onReportDisplaySwChanged() {
    this.match.reportDisplaySw = true;
    this.onReportDisplaySwChange.emit();
  }
}
