import { Injectable } from '@angular/core';
import {
  HttpHeaders,
  HttpClient,
  HttpErrorResponse,
} from '@angular/common/http';
import { ServiceOptions } from '../models/service-options';
import { Config } from 'src/app/config';
import { NgRedux } from '@redux/redux-compatibility.service';
import { AppState, actionsList } from '../redux/store';
import { SharedService } from './shared.service';
import { throwError, Observable, timer, Subscription } from 'rxjs';
import { AwsService } from './aws.service';
import { UiUser, AuthDetails } from '../models/ui-user.model';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { catchError, map, take } from 'rxjs/operators';
import {
  DataElement,
  ProcessRunStatusResult,
  ProcessStep,
  Product,
  SearchRunStatusEnum,
  ServerError,
  ServiceCallInfo,
} from '../models/common';
import { ConnectionService } from 'ng-connection-service';
import { TranslateService } from '@ngx-translate/core';
import { Env, User, UserEnv, UserSearchParams } from '../models/user.model';
import { ResourceLoader } from '@angular/compiler';
import { DatePipe } from '@angular/common';
import { isWindow } from 'jquery';
import { TokenFileWebIdentityCredentials } from 'aws-sdk';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private static HTTP_HEADERS_DEFAULT: HttpHeaders = new HttpHeaders({'Content-Type': 'application/json; charset=UTF-8',});
  private static LAST_USER_ACTIVITY_TIME_KEY: string = "lastUserActivityTime";

  servicesUrl: string;
  algServicesUrl: string;
  getPresignedUrl: string;
  pckName: string = 'scai';
  refreshTokenTimer: any;
  currentUser: UiUser = new UiUser();
  loggerSw: boolean = true; // TODO
  isOnline = true;
  private mockupDataUrl = 'assets/mock/';
  userInactivityCheckIntervalId: number;
  timeToAutoLogout!: number;
  autoLogoutCheckIntervalMillis: number;

  constructor(
    private ngRedux: NgRedux<AppState>,
    private sharedService: SharedService,
    private awsService: AwsService,
    private http: HttpClient,
    private router: Router,
    private connectionService: ConnectionService,
    private translateService: TranslateService,
    private datePipe: DatePipe
  ) {
    this.servicesUrl = Config.assetConfigData['servicesUrl'];
    this.algServicesUrl = Config.assetConfigData['algServicesUrl'];
    this.getPresignedUrl = this.algServicesUrl + 'ScaiPyAdmin.GetPresignedUrl';

    if (this.loggerSw) console.log(this.servicesUrl);

    this.connectionService.monitor().subscribe((isConnected) => {
      this.isOnline = isConnected.hasNetworkConnection;
    });

    this.timeToAutoLogout = +Config.assetConfigData['autoLogoutTimeInMinutes'] * 60 * 1000;
    this.autoLogoutCheckIntervalMillis = +Config.assetConfigData['autoLogoutCheckIntervalMillis'];
    this.userInactivityCheckIntervalId = null;
  }

  getRequestUrl(serviceName: string, algServiceSw: boolean = false): string {
    if (algServiceSw) {
      return this.algServicesUrl + serviceName;
    } else
      return (
        this.servicesUrl + this.pckName + '.' + serviceName + '?useXml=false'
      );
  }

  getRequestHeaders(): HttpHeaders {
    return AuthService.HTTP_HEADERS_DEFAULT;
  }

  getRequestBody(options: ServiceOptions, msg?: string): string {
    if (this.currentUser) {
      if (!options.userId) options.userId = this.currentUser.userId;
      if (!options.reqEnv) options.reqEnv = this.ngRedux.getState().curEnv?.id;
      if (!options.lang) options.lang = 'en'; //  this.ngRedux.getState().ui_lang; //this.currentUser.lang;
      options.token = this.currentUser.token;
    }
    options.appVersion = SharedService.versionCode;
    console.log(options);

    let req = new Object();
    if (options.urldecodeSw) {
      req['request'] = {};
      req['request']['serviceName'] = options.serviceName;
      req['request']['data'] = encodeURIComponent(JSON.stringify(options));
      req['request']['urldecodeSw'] = 1;
    } else {
      req['request'] = {};
      req['request']['serviceName'] = options.serviceName;
      req['request']['data'] = options;
    }

    let body = JSON.stringify(req);
    let _msg: string = '';
    let _serviceName: string = options.serviceName
      ? options.serviceName
      : 'request body';
    if (msg) {
      _msg = ' (' + msg + ')';
    }
    if (this.loggerSw) {
      console.groupCollapsed(
        '%c' + _serviceName + ' | ' + _msg,
        'color: LightSlateGray'
      );
      console.log(this.servicesUrl);
      console.log(body);
      console.table(options);
      console.groupEnd();
    }

    return body;
  }

  getTimezonesList(): Observable<DataElement[]> {
    return this.http
      .get(
        'assets/i18n/timezones_' + this.translateService.currentLang + '.json'
      )
      .pipe(map((res) => this.mapPlainJSonToDataElement(res)));
  }

  private mapPlainJSonToDataElement(content: any): DataElement[] {
    let dataList = new Array<DataElement>();

    Object.keys(content).forEach((k) => {
      let el = new DataElement(k, content[k]);
      dataList.push(el);
    });

    return dataList;
  }

  extractRequestStatus(info: ServiceCallInfo, rep: any): boolean {
    if (this.loggerSw) {
      console.groupCollapsed(
        '%c' + info.serviceName + ' | REPLY',
        'color: LightSlateGray'
      );
      console.log(this.servicesUrl);
      console.log(rep);
      console.groupEnd();
    }

    let errorSw: boolean;
    let statusCode: string;
    let statusMsg: string;

    if (info.algServicesSw) {
      if (rep.reqStatus.statusCode !== 0) {
        errorSw = true;
        statusCode = rep.reqStatus.status;
        if (rep.req != null) {
          statusMsg = rep.req.statusMessage;
        }
      }
    } else if (rep.reply.requestStatus.statusCode !== 0) {
      if (rep.reply.requestStatus.status == 'AUTH_ERR') {
        this.signOutUser().then(() => {
          this.ngRedux.dispatch({
            type: actionsList.USER_SIGN_OUT,
          });
          this.router.navigate(['']);
        });
      } else {
        errorSw = true;
        statusCode = rep.reply.requestStatus.statusCode;
        statusMsg = rep.reply.requestStatus.statusMessage;
      }
    }

    if (errorSw) {
      let serverError = new ServerError();
      serverError.message = statusMsg;
      serverError.errorCode = statusCode;
      serverError.url = info.url;
      serverError.serviceName = info.serviceName;
      serverError.req = info.req;
      serverError.rep = JSON.stringify(rep);
      throw serverError;
    }

    return true;
  }

  fromXMLBoolean(inputBool: string): boolean {
    if (!inputBool) return false;
    return (
      String(inputBool).toUpperCase() === '1' ||
      String(inputBool).toUpperCase() === 'TRUE'
    );
  }

  formatDBDateTime(date: string) {
    if (!date) return '';
    return Intl.DateTimeFormat([], {
      timeZone: this.currentUser.timezoneName,
      hour: 'numeric',
      minute: 'numeric',
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    }).format(Date.parse(date + SharedService.dbTZ));
  }

  fromXMLDate(inputDate: string): Date {
    if (inputDate === '' || !inputDate) return undefined;
    return new Date(inputDate.replace(' ', 'T'));
  }

  handleError(error: any, info: ServiceCallInfo) {
    this.ngRedux.dispatch({
      type: actionsList.ERROR__FROM_SERVER,
      data: {
        serviceName: info.serviceName,
        error: error.message ? error.message : error,
      },
    });

    if (this.isOnline) {
      this.sharedService.alertError(
        this.ngRedux.getState()._errorFromServer.error,
        null,
        null,
        info.serviceName
      );
    } else {
      this.sharedService.alertError(
        null,
        this.translateService.instant('translate.alert.networkErrorTitle'),
        this.translateService.instant('translate.alert.networkError'),
        info.serviceName
      );
    }

    let serverError = new ServerError();
    if (error.isServerError) {
      serverError = error;
    } else {
      serverError.message = error.message || error.toString();
      serverError.errorCode = error.name;
      serverError.url = info.url;
      serverError.serviceName = info.serviceName;
      serverError.req = info.req;
    }

    //this.processError(serverError);

    return throwError(error);
  }

  processError(e: any) {
    let encodedBody = JSON.stringify({
      serviceName: e.serviceName ?? '',
      url: e.url ?? '',
      errorCode: e.errorCode ?? '',
      message: e.message ?? '',
      networkError: e.networkError ?? '',
      req: e.req ?? '',
      rep: e.rep ?? '',
      userId: this.ngRedux.getState().user
        ? this.ngRedux.getState().user?.username
        : '',
      timestamp: new Date(),
      project: 'scai',
    });

    let body = {
      data: encodeURI(encodedBody),
    };

    try {
      this.http
        .post(SharedService.errorLogLamdaUrl, JSON.stringify(body), {
          headers: this.getRequestHeaders(),
          withCredentials: false,
          responseType: 'json',
        })
        .pipe(map((data: any) => console.log(data)))
        .subscribe();
    } catch (e) {
      console.log(e);
    }
  }

  loginUser(username: string, password: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.awsService.authenticateUserPool(
        username,
        password,
        (message: any, result: any, user: any) => {
          if (message === 'EMPTY_TOKEN') {
            resolve({ value: 'EMPTY_TOKEN', error: undefined });
          } else {
            if (message != null) {
              reject({ value: 'ERROR', error: message.message });
            } else {
              this.setCurrentUser(false, result, user);
              resolve({ value: 'OK', error: undefined });
            }
          }
        }
      );
    });
  }

  forgotPassword(username: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.awsService.forgotPassword(
        username,
        (message: any, result: any, user: any) => {
          if (message === 'SUCCESS') {
            resolve({ value: 'OK', error: undefined });
          } else {
            reject({ value: 'ERROR', error: result.message });
          }
        }
      );
    });
  }

  setCurrentUser(
    rertievedSessionSw: boolean,
    cognitoAuthResult: any,
    cognitoUser: any
  ) {
    this.resetUserInactivityMonitor();
    if (rertievedSessionSw) {
      this.currentUser.accessJwtToken =
        cognitoUser.signInUserSession.accessToken.jwtToken;
      this.currentUser.idJwtToken =
        cognitoUser.signInUserSession.idToken.jwtToken;
      this.currentUser.refreshToken =
        cognitoUser.signInUserSession.refreshToken.token;
      this.currentUser.refreshTokenObject =
        cognitoUser.signInUserSession.refreshToken;
    } else {
      this.currentUser.accessJwtToken = cognitoAuthResult.accessToken.jwtToken;
      this.currentUser.idJwtToken = cognitoAuthResult.idToken.jwtToken;
      this.currentUser.refreshToken = cognitoAuthResult.refreshToken.token;
      this.currentUser.refreshTokenObject = cognitoAuthResult.refreshToken;
    }

    this.currentUser.username = cognitoUser.username;
    this.currentUser.isLoggedIn = true;
    this.currentUser.cognitoUserObject = cognitoUser;

    cognitoUser.getUserAttributes((err, result) => {
      if (!err) {
        this.currentUser.phone = result.find((i) => {
          return i['Name'] === 'phone_number';
        })['Value'];
      }
    });
  }

  forgetUser() {
    if (this.currentUser) {
      this.currentUser.isLoggedIn = false;      
    }
    this.ngRedux.dispatch({
      type: actionsList.USER_SIGN_OUT,
    });
    setTimeout(() => this.router.navigate(['']), 1000);

    this.currentUser = new UiUser();
  }

  refreshUser(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.awsService.refreshTokens(
        this.currentUser.cognitoUserObject,
        this.currentUser.refreshTokenObject,
        (err, session) => {
          if (err) {
            this.forgetUser();
            reject();
          } else {
            this.currentUser.accessJwtToken = session.accessToken.jwtToken;
            this.currentUser.idJwtToken = session.idToken.jwtToken;
            resolve(true);
          }
        }
      );
    });
  }

  signOutUser(): Promise<any> {
    if (this.userInactivityCheckIntervalId) {
      window.clearInterval(this.userInactivityCheckIntervalId);
      this.userInactivityCheckIntervalId = null;
      localStorage.removeItem(this.getLocalStorageKeyUserLastActivityTime());
    }
    return new Promise((resolve, reject) => {
      this.awsService.signOutUser(
        this.currentUser.cognitoUserObject,
        (result: any) => {
          if (result === 'SUCCESS') {
            this.forgetUser();
            resolve(true);
          } else {
            this.refreshUser().then(
              success => {
                this.awsService.signOutUser(this.currentUser.cognitoUserObject, res => {
                  this.forgetUser();
                  resolve(true);
                });
              }
            )
          }
        }
      );
    });
  }

  retrieveUserSession(username: string): Promise<any> {
    return new Promise((resolve, reject) => {
      if(this.isAutoLogoutFeatureEnabled() && this.isAutoLogoutPeriodExpired()) {
        setTimeout(() => this.signOutUser(), 1000);
        reject();
      }
      this.awsService.retrieveUserSession(
        (sessionValid: boolean, cognitoUser: any) => {
          if (sessionValid) {
            if (cognitoUser) {
              this.setCurrentUser(true, null, cognitoUser);
              this.refreshUser().then(
                (success) => resolve(true),
                (error) => reject()
              );
            } else {
              reject();
            }
          } else {
            reject();
          }
        }
      );
    });
  }

  getToken(): Promise<string> {
    return this.awsService.getCognitoToken();
  }

  resetUserInactivityMonitor(): void {
    if(this.isAutoLogoutFeatureEnabled()) {
      localStorage.setItem(this.getLocalStorageKeyUserLastActivityTime(), JSON.stringify(new Date().getTime()));

      if (!this.userInactivityCheckIntervalId) {
        this.userInactivityCheckIntervalId = window.setInterval(() => {
          const lastActivityTimeValue = localStorage.getItem(this.getLocalStorageKeyUserLastActivityTime());

          if (lastActivityTimeValue) {
            const lastActivityTime = parseInt(JSON.parse(lastActivityTimeValue));
            const currentTime = new Date().getTime();

            if ((lastActivityTime + this.timeToAutoLogout) < currentTime) {
              this.signOutUser().then(() => { console.log('Signed user out due to inactivity.'); })
            }
          }
        }, this.autoLogoutCheckIntervalMillis);
      }
    }
  }

  simulateUserActivity(): void {
    if (this.userInactivityCheckIntervalId && this.currentUser?.isLoggedIn) {
      localStorage.setItem(this.getLocalStorageKeyUserLastActivityTime(), JSON.stringify(new Date().getTime()));
    }
  }

  isAutoLogoutFeatureEnabled(): boolean {
    // DEV NOTE: auto-logout's considered disabled when the "username" cookie exists ("remembered" user)
    return !this.sharedService.getCookie('username') && this.timeToAutoLogout > 0;
  }

  isAutoLogoutPeriodExpired(): boolean {
    let ret: boolean = false;
    if(this.isAutoLogoutFeatureEnabled()) {
      let lastUserActivityTimeStr: string = localStorage.getItem(this.getLocalStorageKeyUserLastActivityTime());
      if(lastUserActivityTimeStr) {
        let lastUserActivityTime: number = new Date(+lastUserActivityTimeStr).getTime();
        ret = (this.timeToAutoLogout + lastUserActivityTime) < new Date().getTime();
      }
    }    
    return ret;
  }

  getLocalStorageKeyUserLastActivityTime(): string { // TODO support multi-users case!
    return AuthService.LAST_USER_ACTIVITY_TIME_KEY;
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    let user = this.currentUser;

    if (user.isLoggedIn) {
      return true;
    } else return false;
  }

  getUserDetails(): Observable<boolean> {
    let response = new Observable<boolean>();
    let serviceName = 'get_user_details_func';

    /*if (true) {
      return this.http
        .get(this.mockupDataUrl + 'get_user_details.json')
        .pipe(map((res) => this.extractUserDetails(res)));
    }*/

    let params = new ServiceOptions();
    params.serviceName = serviceName;
    params['username'] = this.currentUser.username;

    let url = this.getRequestUrl(serviceName);
    let body = this.getRequestBody(params);
    let info = new ServiceCallInfo(serviceName, url, body);

    response = this.http
      .post(url, body, {
        headers: this.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json',
      })
      .pipe(
        map((data) => {
          return this.extractUserDetails(data, info);
        }),
        catchError((error) => {
          return this.handleError(error, info);
        })
      );

    return response;
  }

  private extractUserDetails(res: any, info: ServiceCallInfo): boolean {
    this.extractRequestStatus(info, res);

    let data = res.reply.requestResult.data;
    if (!data || !data.user) return false;
    data = data.user;

    this.currentUser.userDescr = data.usrDescr;
    this.currentUser.lang = data.lang;
    this.currentUser.authDetails = new AuthDetails();
    this.currentUser.curEnvId = data.curEnvId;
    this.currentUser.globalRole = data.globalRole;
    this.currentUser.timezone = data.timezone;
    this.currentUser.timezoneName = data.timezoneName;

    if (data.roleSetting) {
      let arr = new Array().concat(data.roleSetting);
      arr.forEach((i) => {
        this.currentUser.authDetails.setValue(i.key, i.value);
      });
    }

    this.ngRedux.dispatch({
      type: actionsList.GET_USER_DETAILS,
      data: this.currentUser,
      reply: res,
    });

    return true;
  }

  appSignIn(password: string): Observable<void> {
    let response = new Observable<void>();
    let serviceName = 'sign_in_func';
    let url = this.getRequestUrl(serviceName);

    let params = new ServiceOptions();
    params.serviceName = serviceName;

    params['userName'] = this.currentUser.username.toLowerCase();
    params['password'] = password;

    let body = this.getRequestBody(params);
    let info = new ServiceCallInfo(serviceName, url, body);

    response = this.http
      .post(url, body, {
        headers: this.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json',
      })
      .pipe(
        map((data) => {
          this.extractAppSignIn(data, info);
        }),
        catchError((error) => {
          return this.handleError(error, info);
        })
      );

    return response;
  }

  private extractAppSignIn(res: any, info: ServiceCallInfo) {
    this.extractRequestStatus(info, res);
    let data = res.reply.requestResult.data;

    if (data && data.user) {
      this.currentUser.userId = data.user.userId;
      this.currentUser.token = data.user.lastToken;
    }

    this.ngRedux.dispatch({
      type: actionsList.GET_USER_DETAILS,
      data: this.currentUser,
      reply: res,
    });
  }

  updateUserDetails(env: Env): Observable<boolean> {
    let response = new Observable<boolean>();
    let serviceName = 'update_user_details_func';

    let params = new ServiceOptions();
    params.serviceName = serviceName;
    params['curEnvId'] = env.id;

    let url = this.getRequestUrl(serviceName);
    let body = this.getRequestBody(params);
    let info = new ServiceCallInfo(serviceName, url, body);

    response = this.http
      .post(url, body, {
        headers: this.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json',
      })
      .pipe(
        map((data) => {
          let info = new ServiceCallInfo(serviceName, url, body);
          return this.extractUserDetails(data, info);
        }),
        catchError((error) => {
          return this.handleError(error, info);
        })
      );

    return response;
  }

  mapToDataElement(
    res,
    idProp: string,
    textProp: string,
    rowEl: string = 'row',
    info: ServiceCallInfo,
    childrenProp?: string
  ): DataElement[] {
    this.extractRequestStatus(info, res);

    if (!res || !res.reply.requestResult.data) return [];

    let dataList = new Array<DataElement>();
    let data = res.reply.requestResult.data[rowEl];

    if (data && data.length) {
      dataList = data.map((item: any) => {
        let row = new DataElement(item[idProp], item[textProp]);
        if (childrenProp && item[childrenProp]) {
          row.children = item[childrenProp].map((c: any) => {
            return new DataElement(c[idProp], c[textProp]);
          });
        }
        return row;
      });
    } else {
      dataList = [];
    }

    return dataList;
  }

  toTmpImg(s3key: string): string {
    return this.ngRedux.getState().curEnv.tmpS3BucketUrl + s3key;
  }

  getEnvList(): Observable<Env[]> {
    let serviceName = 'get_env_list_func';
    let response = new Observable<Env[]>();
    let params = new ServiceOptions();
    params.serviceName = serviceName;

    let url = this.getRequestUrl(serviceName);
    let body = this.getRequestBody(params);
    let info = new ServiceCallInfo(serviceName, url, body);

    response = this.http
      .post(url, body, {
        headers: this.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json',
      })
      .pipe(
        map((data) => {
          let info = new ServiceCallInfo(serviceName, url, body);
          return this.extractEnvList(data, info);
        }),
        catchError((err) => {
          return this.handleError(err, info);
        })
      );
    return response;
  }

  private extractEnvList(res, info: ServiceCallInfo): Env[] {
    this.extractRequestStatus(info, res);
    let result: Env[] = [];
    let data = res.reply.requestResult.data;

    if (data) {
      let arr = new Array().concat(data.env);
      arr.forEach((i) => {
        let c = new Env();
        c.id = i.envId;
        c.descr = i.descr;
        c.defaultSw = this.fromXMLBoolean(i.defaultSw);
        c.tmpS3BucketUrl = i.tmpS3BucketUrl;
        c.docS3Bucket = i.docS3Bucket;
        c.tmpS3Bucket = i.tmpS3Bucket;
        c.ruleConfigTemplate = i.ruleConfigTemplate;
        result.push(c);
      });

      result.srp_sortByKey('descr');
    }

    this.ngRedux.dispatch({
      type: actionsList.GET_ENV_LIST,
      data: result,
      reply: res,
    });

    return result;
  }

  getProcessStatus(runNum: number): Observable<ProcessRunStatusResult> {
    let serviceName = 'get_process_status_func';

    let response = new Observable<ProcessRunStatusResult>();
    let params = new ServiceOptions();
    params.serviceName = serviceName;
    params['runNum'] = runNum;

    let url = this.getRequestUrl(serviceName);
    let body = this.getRequestBody(params);
    let info = new ServiceCallInfo(serviceName, url, body);

    response = this.http
      .post(url, body, {
        headers: this.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json',
      })
      .pipe(
        map((data) => {
          let info = new ServiceCallInfo(serviceName, url, body);
          return this.extractGetProcessStatus(data, info);
        }),
        catchError((err) => {
          return this.handleError(err, info);
        })
      );
    return response;
  }

  extractGetProcessStatus(
    res: any,
    info: ServiceCallInfo
  ): ProcessRunStatusResult {
    this.extractRequestStatus(info, res);
    let data = res.reply.requestResult.data;
    let result = new ProcessRunStatusResult();

    if (data && data.algRun) {
      let tag = data.algRun;
      result.status = tag.runStatusCode;
      result.errorMsg = tag.runStatusMessage;
      result.outDocUrl = tag.outDocUrl;
      result.outDocS3Key = tag.outDocS3Key;
      result.completedPct = tag.completedPct || 0;
      result.completedPct *= 100;
      if (tag.steps) {
        let arrSteps = new Array().concat(tag.steps);
        arrSteps.forEach((s) => {
          let step = new ProcessStep();
          step.stepCode = s.stepCode;
          step.stepDescr = s.stepDescr;
          step.stepId = s.stepId;
          result.completedSteps.push(step);
        });

        result.completedSteps.reverse();
      }
    }

    return result;
  }

  getUsers(
    searchParams: UserSearchParams,
    dispatch: boolean = true
  ): Observable<User[]> {
    let serviceName = 'get_users_func';
    let response = new Observable<User[]>();
    let params = new ServiceOptions();
    params.serviceName = serviceName;

    if (searchParams.username) params['username'] = searchParams.username;
    if (searchParams.env) params['envId'] = searchParams.env.id;
    if (searchParams.exactUsername)
      params['exactUsername'] = searchParams.exactUsername;
    if (searchParams.activeSw) params['activeSw'] = true;

    let url = this.getRequestUrl(serviceName);
    let body = this.getRequestBody(params);
    let info = new ServiceCallInfo(serviceName, url, body);

    response = this.http
      .post(url, body, {
        headers: this.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json',
      })
      .pipe(
        map((data) => {
          return this.extractGetUsers(data, dispatch, info);
        }),
        catchError((err) => {
          return this.handleError(err, info);
        })
      );
    return response;
  }

  private extractGetUsers(
    res,
    dispatch: boolean,
    info: ServiceCallInfo
  ): User[] {
    this.extractRequestStatus(info, res);
    let result: User[] = [];
    let data = res.reply.requestResult.data;

    if (data && data.user) {
      let arr = new Array().concat(data.user);
      arr.forEach((i) => {
        let u = new User();
        u.userId = i.userId;
        u.username = i.userName;
        u.userDescr = i.userDescr;
        u.password = i.pass;
        u.timezoneName = i.timezoneName;
        u.activeSw = this.fromXMLBoolean(i.activeSw);
        // u.lastSeen = this.datePipe.transform(
        //   i.lastSeen,
        //   SharedService.appDateFormat,
        //   this.currentUser.timezone
        // );
        u.lastSeen = this.formatDBDateTime(i.lastSeen);
        u.cognitoActiveSw = this.fromXMLBoolean(i.cognitoActiveSw);
        u.cognitoSyncStatus = i.cognitoSyncStatus;
        u.cognitoSyncError = i.cognitoSyncError;

        if (i.env) {
          let envArr = new Array().concat(i.env);
          envArr.forEach((e) => {
            let userEnv = new UserEnv();
            let env = new Env();
            env.id = e.envid;
            env.descr = e.envdescr;
            userEnv.env = env;
            userEnv.role = new DataElement(e.role, e.roledescr);
            u.userEnvList.push(userEnv);
          });
        }

        result.push(u);
      });

      result.srp_sortByKey('userId', -1);
    }

    if (dispatch)
      this.ngRedux.dispatch({
        type: actionsList.GET_USERS,
        data: result,
        reply: res,
      });

    return result;
  }

  updateUser(user: User, resetSw: boolean = false): Observable<number> {
    let serviceName = 'update_user_func';
    let params = new ServiceOptions();
    params.serviceName = serviceName;

    params['updUserId'] = user.userId;
    params['username'] = user.username.toLowerCase();
    params['password'] = user.password;
    params['userDescr'] = user.userDescr;
    if (user.activeSw != null) {
      params['activeSw'] = user.activeSw ? 1 : 0;
    }

    params['cognitoResetSw'] = resetSw;
    params['timezoneName'] = user.timezoneName;

    params['env'] = user.userEnvList.map((e) => {
      return {
        envId: e.env.id,
        roleCode: e.role.id,
      };
    });

    let url = this.getRequestUrl(serviceName);
    let body = this.getRequestBody(params);
    let info = new ServiceCallInfo(serviceName, url, body);

    return this.http
      .post(url, body, {
        headers: this.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json',
      })
      .pipe(
        map((data: any) => {
          let userId: number = data.reply.requestResult.data.user[0].userId;
          return userId;
        }),
        catchError((error) => {
          return this.handleError(error, info);
        })
      );
  }

  public syncCongitoUsers(user: User): Observable<void> {
    let response = new Observable<void>();
    let serviceName = 'ScaiPyAdmin.SyncCognitoUsers';
    let params = new ServiceOptions();
    params.serviceName = serviceName;
    params['username'] = user.username;
    params['password'] = user.password;
    params['updUserId'] = user.userId;
    if (user.activeSw != null) {
      params['activeSw'] = user.activeSw ? 1 : 0;
    }

    let url = this.getRequestUrl(serviceName, true);
    let body = this.getRequestBody(params);
    let info = new ServiceCallInfo(serviceName, url, body);

    response = this.http
      .post(url, body, {
        headers: this.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json',
      })
      .pipe(
        map((data) => {
          this.extractRequestStatus(info, data);
        }),
        catchError((error) => {
          return this.handleError(error, info);
        })
      );

    return response;
  }

  getUserRoles(): Observable<DataElement[]> {
    let serviceName = 'get_user_roles_func';
    let response = new Observable<DataElement[]>();
    let params = new ServiceOptions();
    params.serviceName = serviceName;

    let url = this.getRequestUrl(serviceName);
    let body = this.getRequestBody(params);
    let info = new ServiceCallInfo(serviceName, url, body);

    response = this.http
      .post(url, body, {
        headers: this.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json',
      })
      .pipe(
        map((data) => {
          return this.extractUserRoles(data, info);
        }),
        catchError((err) => {
          return this.handleError(err, info);
        })
      );
    return response;
  }

  private extractUserRoles(res, info: ServiceCallInfo): DataElement[] {
    const results: DataElement[] = this.mapToDataElement(
      res,
      'roleCode',
      'roleDescr',
      'role',
      info
    );

    this.ngRedux.dispatch({
      type: actionsList.GET_USER_ROLES,
      data: results,
      reply: res,
    });

    return results;
  }

  updateUserPassword(password: string): Observable<boolean> {
    let response = new Observable<boolean>();
    let serviceName = 'update_user_password';
    let params = new ServiceOptions();
    params.serviceName = serviceName;

    params['username'] = this.currentUser.username.toUpperCase();
    params['newPassword'] = password;

    let url = this.getRequestUrl(serviceName);
    let body = this.getRequestBody(params);
    let info = new ServiceCallInfo(serviceName, url, body);

    response = this.http
      .post(url, body, {
        headers: this.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json',
      })
      .pipe(
        map((data) => this.extractRequestStatus(info, data)),
        catchError((error) => {
          return this.handleError(error, info);
        })
      );

    return response;
  }

  updateEnv(env: Env, deleteSw: boolean = false): Observable<boolean> {
    let response = new Observable<boolean>();
    let serviceName = 'upd_env_func';
    let params = new ServiceOptions();
    params.serviceName = serviceName;

    params['envId'] = env.id;
    params['envDescr'] = env.descr;
    params['deleteSw'] = deleteSw;
    params['ruleConfigTemplate'] = env.ruleConfigTemplate

    let url = this.getRequestUrl(serviceName);
    let body = this.getRequestBody(params);
    let info = new ServiceCallInfo(serviceName, url, body);

    response = this.http
      .post(url, body, {
        headers: this.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json',
      })
      .pipe(
        map((data) => this.extractRequestStatus(info, data)),
        catchError((error) => {
          return this.handleError(error, info);
        })
      );

    return response;
  }

  getProductList(allEnv: boolean = false): Observable<Product[]> {
    let serviceName = 'get_product_list_func';

    let response = new Observable<Product[]>();
    let params = new ServiceOptions();
    params.serviceName = serviceName;
    if (!allEnv) params['envId'] = this.ngRedux.getState().curEnv.id;

    let url = this.getRequestUrl(serviceName);
    let body = this.getRequestBody(params);
    let info = new ServiceCallInfo(serviceName, url, body);

    response = this.http
      .post(url, body, {
        headers: this.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json',
      })
      .pipe(
        map((data) => {
          return this.extractProductList(data, allEnv, info);
        }),
        catchError((err) => {
          return this.handleError(err, info);
        })
      );
    return response;
  }

  private extractProductList(
    res,
    allEnv: boolean,
    info: ServiceCallInfo
  ): Product[] {
    this.extractRequestStatus(info, res);
    let result: Product[] = [];
    let data = res.reply.requestResult.data;

    if (data && data.product) {
      let arr = new Array().concat(data.product);
      arr.forEach((i) => {
        let p = new Product();
        p.id = i.productId;
        p.productDescr = i.descr;

        if (i.envList) {
          let arrEnv = new Array().concat(i.envList);
          arrEnv.forEach((e) => {
            let env = new Env();
            env.id = e.envId;
            env.descr = e.envDescr;
            p.envList.push(env);
          });
        }

        result.push(p);
      });

      result.srp_sortByKey('productDescr');
    }

    if (allEnv) {
      this.ngRedux.dispatch({
        type: actionsList.GET_CROSS_ENV_PRODUCTS,
        data: result,
        reply: res,
      });
    } else
      this.ngRedux.dispatch({
        type: actionsList.GET_PRODUCT_LIST,
        data: result,
        reply: res,
      });

    return result;
  }

  updateProduct(
    product: Product,
    deleteSw: boolean = false
  ): Observable<boolean> {
    let response = new Observable<boolean>();
    let serviceName = 'upd_product_func';
    let params = new ServiceOptions();
    params.serviceName = serviceName;

    params['productId'] = product.id;
    params['productDescr'] = product.productDescr;
    params['deleteSw'] = deleteSw;
    params['envList'] = [];
    product.envList.forEach((e) => {
      params['envList'].push({ envId: e.id });
    });

    let url = this.getRequestUrl(serviceName);
    let body = this.getRequestBody(params);
    let info = new ServiceCallInfo(serviceName, url, body);

    response = this.http
      .post(url, body, {
        headers: this.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json',
      })
      .pipe(
        map((data) => this.extractRequestStatus(info, data)),
        catchError((error) => {
          return this.handleError(error, info);
        })
      );

    return response;
  }

  getSearchStatus(searchId: number): Observable<SearchRunStatusEnum> {
    let serviceName = 'get_search_status_func';

    let response = new Observable<SearchRunStatusEnum>();
    let params = new ServiceOptions();
    params.serviceName = serviceName;
    params['searchId'] = searchId;

    let url = this.getRequestUrl(serviceName);
    let body = this.getRequestBody(params);
    let info = new ServiceCallInfo(serviceName, url, body);

    response = this.http
      .post(url, body, {
        headers: this.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json',
      })
      .pipe(
        map((data) => {
          let info = new ServiceCallInfo(serviceName, url, body);
          return this.extractGetSearchStatus(data, info);
        }),
        catchError((err) => {
          return this.handleError(err, info);
        })
      );
    return response;
  }

  extractGetSearchStatus(res: any, info: ServiceCallInfo): SearchRunStatusEnum {
    this.extractRequestStatus(info, res);
    let data = res.reply.requestResult.data;
    return data['searchStatus'];
  }

  runGeneralSearch(reqBody: string): Observable<number> {
    let response = new Observable<number>();
    let serviceName = 'ScaiPyAdmin.runSearch';
    let url = this.getRequestUrl(serviceName, true);
    let info = new ServiceCallInfo(serviceName, url, reqBody);
    response = this.http
      .post(url, reqBody, {
        headers: this.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json',
      })
      .pipe(
        map((data) => this.extractRunGeneralSearch(info, data)),
        catchError((error) => {
          return this.handleError(error, info);
        })
      );

    return response;
  }

  extractRunGeneralSearch(info: ServiceCallInfo, res: any): number {
    this.extractRequestStatus(info, res);
    let data = res.reply.requestResult.data;
    let seq: number;

    if (data) {
      seq = data.searchId;
    }

    return seq;
  }

  getRuleConfigTemplateList(): Observable<DataElement[]> {
    let serviceName = 'get_rule_config_templates_func';
    let response = new Observable<DataElement[]>();
    let params = new ServiceOptions();
    params.serviceName = serviceName;

    let url = this.getRequestUrl(serviceName);
    let body = this.getRequestBody(params);
    let info = new ServiceCallInfo(serviceName, url, body);

    response = this.http
      .post(url, body, {
        headers: this.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json',
      })
      .pipe(
        take(1),
        map((data) => {
          return this.extractRuleConfigTemplateList(data, info);
        }),
        catchError((err) => {
          return this.handleError(err, info);
        })
      );
    return response;
  }

  private extractRuleConfigTemplateList(res, info: ServiceCallInfo): DataElement[] {
    this.extractRequestStatus(info, res);

    if (!res || !res.reply.requestResult.data) return [];

    let dataList = new Array<DataElement>();
    let data = res.reply.requestResult.data["mlRuleConfigTemplate"];

    if (data && data.length) {
      dataList = data.map((item: any) => {
        let row = new DataElement(item["ruleConfigTemplateCode"], item["ruleConfigTemplateDesc"]);
        return row;
      });
    } else {
      dataList = [];
    }

    const results: DataElement[] = dataList;

    this.ngRedux.dispatch({
      type: actionsList.GET_RULE_CONFIG_TEMPLATES,
      data: results,
      reply: res,
    });

    return results;
  }

}
