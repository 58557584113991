<div *ngIf="entries.length == 0" class="empty-container">
  <a class="icon" (click)="goToAdd()"><i class="far fa-plus-square"></i></a>
</div>

<section *ngIf="entries.length > 0">
  <p-orderList
    [value]="entries"
    dragdrop="true"
    controlsPosition="right"
    (onReorder)="onReorder($event)"
  >
    <ng-template let-row pTemplate="item">
      <div class="tile-container">
        <div class="id">
          {{ "translate.cat.phraseId" | translate }}: {{ row.phraseId }}
          <span *ngIf="row.lastBBId">
            / {{ "translate.cat.fromBB" | translate }} {{ row.lastBBId }}</span
          >
        </div>

        <div class="phrase">
          {{ row.phraseText }}
        </div>

        <div class="cat-phrase-type">
          {{ getEntryIndex(row) + 1 }}
        </div>

        <div class="actions">
          <a (click)="view(row)">{{ "translate.general.view" | translate }}</a>
          <span *ngIf="allowEdit">
            <span class="action-divider">|</span>
            <a (click)="remove(row)">{{
              "translate.cat.removeEntryFromCard" | translate
            }}</a>
          </span>
        </div>
      </div>
    </ng-template>
  </p-orderList>
</section>

<cat-doc-phrase-details
  *ngIf="phraseDetailsOpenSw"
  (onClose)="phraseDetailsOpenSw = false"
  (onDelete)="onEntryDeleted()"
  (onSave)="onPhraseSaved()"
  [mode]="'cat'"
  [displayEntryDocs]="true"
  [editable]="false"
  [stateTag]="'PRIME'"
></cat-doc-phrase-details>
