<!-- <div class="container">
  <div *ngFor="let card of revCards" class="w-full">
    <div class="card-title">
      <strong>{{ card.type.text }}</strong>
      &nbsp; &middot;
      {{ card.title }}
    </div>
    <div class="template-container">
      <div *ngFor="let doc of card.cardDocs">
        <div class="template-container" [ngClass]="[card.matchStatusCode]">
          <div class="l-align-spaceBetween">
            <h3>
              {{ "translate.rev.detectedCard" | translate }}
            </h3>
            <p class="card-match-similarity" [ngClass]="[card.matchStatusCode]">
              {{ card.matchStatusDescr }}
            </p>
          </div>
          <card-display [card]="card" [cardType]="'REV'"></card-display>
        </div>

        <div class="template-container">
          <h3>
            {{ "translate.rev.matchedCard" | translate }}
          </h3>
          <card-display
            [card]="card.matchedCard"
            [cardType]="'IND'"
            [displayMatchStatus]="true"
            [displayPhraseLink]="true"
          ></card-display>
        </div>
      </div>
    </div>
  </div>
</div> -->

<div class="container">
  <p-tabMenu [model]="tabItems" [activeItem]="activeItem" [scrollable]="true">
    <ng-template pTemplate="item" let-item let-i="index">
      <strong>{{ revCards[i].type.text }}</strong
      >&nbsp; &middot;
      {{ revCards[i].title }}
    </ng-template>
  </p-tabMenu>

  <div *ngIf="curCard">
    <div class="template-container" [ngClass]="[curCard.matchStatusCode ? curCard.matchStatusCode : 'NOT_FOUND']">
      <div class="l-align-spaceBetween">
        <h3>
          {{ "translate.rev.detectedCard" | translate }}
        </h3>
        <p class="card-match-similarity" [ngClass]="[curCard.matchStatusCode ? curCard.matchStatusCode : 'NOT_FOUND']">
          {{ curCard.matchStatusDescr ? curCard.matchStatusDescr : "" }}
        </p>
      </div>
      <card-display [card]="curCard" [cardType]="'REV'"></card-display>
    </div>

    <div class="template-container">
      <h3>
        {{ "translate.rev.matchedCard" | translate }}
      </h3>
      <card-display
        [card]="curCard.matchedCard"
        [cardType]="'IND'"
        [displayMatchStatus]="true"
        [displayPhraseLink]="true"
      ></card-display>
    </div>
  </div>
</div>
