import { NgRedux } from '@redux/redux-compatibility.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  AltRef,
  CatAltRefSearchParams,
  Catalog,
  CatalogStatusEnum,
} from 'src/app/shared/models/cat.model';
import { PaginationParams } from 'src/app/shared/models/common';
import { ServiceMetaData } from 'src/app/shared/models/service-meta.model';
import { TabIdList } from 'src/app/shared/models/tab.model';
import { AppState } from 'src/app/shared/redux/store';
import { CatService } from 'src/app/shared/services/cat.service';

@Component({
  selector: 'cat-alt-ref',
  templateUrl: './cat-alt-ref.component.html',
  styleUrls: ['./cat-alt-ref.component.scss'],
})
export class CatAltRefComponent {
  @Input() selectMode: boolean = false;
  @Output() onRowSelected = new EventEmitter<AltRef>();

  loading: boolean;
  rangeFrom: number;
  rangeTo: number;
  searchParams: CatAltRefSearchParams = {};
  rowsPerPage = 50;
  tabIdList: TabIdList;
  selectedRow: AltRef;
  displayEdit: boolean;
  editData: AltRef;

  get data(): AltRef[] {
    return this.ngRedux.getState().catAltRefs;
  }

  get curCat(): Catalog {
    return this.ngRedux.getState().curCat;
  }

  get allowEdit(): boolean {
    if (!this.curCat) return false;
    return (
      this.curCat.statusCode == CatalogStatusEnum.DRAFT && !this.selectMode
    );
  }

  get metaData(): ServiceMetaData {
    const metaData: ServiceMetaData =
      this.ngRedux.getState().catAltRefsMetadata;
    return metaData || {};
  }

  get numberOfRows(): number {
    return this.rowsPerPage;
  }

  get totalRecords(): number {
    return this.metaData.totalRecords;
  }

  constructor(
    private ngRedux: NgRedux<AppState>,
    private catService: CatService
  ) {
    this.tabIdList = this.ngRedux.getState().ui_tabIdList;
  }

  ngAfterViewInit(): void {
    this.loadData(true);
  }

  loadData(newSearch: boolean) {
    this.loading = true;

    if (newSearch) {
      this.searchParams.searchId = null;
      this.searchParams.rangeFrom = 0;
      this.searchParams.rangeTo = this.rowsPerPage;
    }

    this.catService.getCatAltRef(this.searchParams).subscribe(
      (data) => {
        this.loading = false;
      },
      (err) => (this.loading = false)
    );
  }

  onPagination(event: PaginationParams) {
    if (
      !this.data ||
      (event.rangeFrom == this.searchParams.rangeFrom &&
        event.rangeTo == this.searchParams.rangeTo)
    )
      return;

    this.searchParams.rangeFrom = event.rangeFrom;
    this.searchParams.rangeTo = event.rangeTo;
    this.searchParams.searchId =
      this.ngRedux.getState().catDocsMetadata.searchId;

    this.loadData(false);
  }

  onRowSelect(row: AltRef) {
    this.onRowSelected.emit(row);
  }

  edit(row: AltRef) {
    this.editData = Object.assign(new AltRef(), row);
    this.displayEdit = true;
  }

  delete(row: AltRef) {}

  newEntry() {
    this.editData = new AltRef();
    this.displayEdit = true;
  }

  onCreated() {
    this.displayEdit = false;
    this.loadData(true);
  }
}
