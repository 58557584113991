<div *ngIf="curCat">
  <section *ngIf="selectMode" class="page-header">
    <div class="title-container">
      <h1>
        <a class="icon" (click)="goBack()"
          ><fa-icon [icon]="['fas', 'arrow-left']"></fa-icon
        ></a>
        &nbsp;
        {{ curCat.catDescr }}
      </h1>
    </div>
  </section>

  <ng-container *ngTemplateOutlet="filterArea"></ng-container>

  <loader *ngIf="loading"></loader>

  <ui-table
    #tblEntries
    *ngIf="displayEntries"
    [isWrapped]="false"
    [data]="displayEntries"
    [numberOfRows]="numberOfRows"
    [totalRecords]="totalRecords"
    [selectedRow]="selectedEntry"
    [showPaginator]="!lcMode"
    (requestUpdate)="onPagination($event)"
    [templates]="[
      {
        template: checkbox,
        permission: !cardMode
      },
      {
        title: 'translate.general.phraseId',
        template: phraseId
      },
      {
        title: 'translate.general.phraseText',
        template: phraseText
      },
      {
        title: 'translate.general.typeDescr',
        template: phraseType
      },
      {
        title: 'translate.cat.references',
        template: references,
        colClass: 'l-ta-center'
      },
      {
        title: 'translate.cat.catEntryStatus',
        template: catEntryStatus,
        colClass: 'l-ta-center'
      },
      {
        title: 'translate.general.actions',
        template: actions,
        permission: allowEdit
      },
      {
        title: 'translate.cat.cardActions',
        template: addToCard,
        permission: cardMode,
        colClass: 'l-ta-center'
      }
    ]"
    [more]="expandRow"
  ></ui-table>

  <ng-template #checkbox let-row="row">
    <checkbox-wrap class="checkbox">
      <input type="checkbox" [(ngModel)]="row.checkedSw" />
    </checkbox-wrap>
  </ng-template>

  <ng-template #phraseText let-row="row">
    <div class="l-align-spaceBetween">
      <span *ngIf="row.phraseWords.length == 0"> {{ row.phraseText }}</span>
      <formatted-phrase
        *ngIf="row.phraseWords.length > 0"
        [phraseWords]="row.phraseWords"
      ></formatted-phrase>

      <span *ngIf="row.manualSrcSw"
        ><i
          class="icon-manual c3 fas fa-user-edit"
          [tooltip]="'translate.general.manuallyAdded' | translate"
        ></i
      ></span>
    </div>
  </ng-template>

  <ng-template #phraseId let-row="row">
    {{ row.phraseId }}
  </ng-template>

  <ng-template #phraseType let-row="row">
    <div class="cat-phrase-type">
      {{ row.typeDescr }}
      <span class="sub-type">&middot; {{ row.subTypeDescr }}</span>
    </div>
  </ng-template>

  <ng-template #references let-row="row">
    <i *ngIf="row.references.length > 0" class="fas fa-check"></i>
  </ng-template>

  <!-- <ng-template #addInstr let-row="row">
    <i *ngIf="row.addInstr.length > 0" class="fas fa-check"></i>
  </ng-template> -->

  <ng-template #catEntryStatus let-row="row">
    <span *ngIf="row.catEntryStatus"> {{ row.catEntryStatus.text }}</span>
  </ng-template>

  <ng-template #actions let-row="row">
    <div *ngIf="allowEdit">
      <a (click)="edit($event, row)">{{
        "translate.general.edit" | translate
      }}</a>
      <span class="action-divider">|</span>
      <a (click)="delete($event, row)">{{
        "translate.general.delete" | translate
      }}</a>
    </div>
  </ng-template>

  <ng-template #addToCard let-row="row">
    <button
      class="t-button"
      [class.t-button-outline]="getCardAppearancesCnt(row) > 0"
      (click)="addEntryToCard($event, row)"
    >
      {{ "translate.cat.addEntryToCard" | translate }}
    </button>
    <p
      *ngIf="getCardAppearancesCnt(row) > 0"
      class="t-footnote l-ta-center add-to-card-link"
    >
      {{
        "translate.cat.appearancesInCard"
          | translate : { cnt: getCardAppearancesCnt(row) }
      }}
    </p>
  </ng-template>

  <ng-template #expandRow let-row="row">
    <tabs>
      <!--documents-->
      <tab-single
        tabTitle="{{ 'translate.cat.catDocs' | translate }}"
        [tabId]="tabIdList.CAT__ENTRIES__DOC"
      >
        <cat-entry-docs
          class="t-small"
          *ngIf="row.docs.length > 0"
          [docs]="row.docs"
        ></cat-entry-docs>
      </tab-single>

      <!--references-->
      <tab-single
        *ngIf="row.references.length > 0"
        tabTitle="{{ 'translate.cat.references' | translate }}"
        [tabId]="tabIdList.CAT__ENTRIES__REF"
      >
        <ui-table
          class="t-small"
          [isWrapped]="false"
          [data]="row.references"
          [displayFilter]="['refPhraseId', 'refPhraseText']"
        ></ui-table>
      </tab-single>

      <!--instructions-->
      <tab-single
        *ngIf="row.addInstr.length > 0"
        tabTitle="{{ 'translate.cat.addInstr' | translate }}"
        [tabId]="tabIdList.CAT__ENTRIES__INSTR"
      >
        <cat-add-instr
          class="t-small"
          *ngFor="let instr of row.addInstr"
          [instr]="instr"
          [editable]="false"
        ></cat-add-instr>
      </tab-single>
    </tabs>
  </ng-template>

  <ng-template #filterArea>
    <div *ngIf="!lcMode" class="filter-bar l-align-spaceBetween">
      <div class="filters">
        <!--filterText-->
        <input-wrap class="t-input-search-wrap">
          <input
            class="t-input"
            type="text"
            [(ngModel)]="filterText"
            placeholder="{{ 'translate.general.phraseText' | translate }}"
            (keypress)="onFilterKeyPress($event)"
          />
        </input-wrap>

        <!--attached docs-->
        <input-wrap>
          <p-multiSelect
            [options]="attachedDocs"
            [(ngModel)]="filterAttachedDocs"
            optionLabel="label"
            [filter]="true"
            [resetFilterOnHide]="true"
            [autofocusFilter]="true"
            filterBy="label,value.id"
            [showToggleAll]="true"
            [maxSelectedLabels]="3"
            [filterPlaceholder]="'translate.general.search' | translate"
            [placeholder]="'translate.general.docs' | translate"
            [selectedItemsLabel]="
              'translate.general.multiselectItemsSelected' | translate
            "
            (onChange)="onFieldValueChanged($event)"
          ></p-multiSelect>
        </input-wrap>

        <!--type-->
        <input-wrap>
          <button class="t-button t-button-search" (click)="op.toggle($event)">
            {{ "translate.general.typeDescr" | translate }}
            <span *ngIf="filterSubTypes"
              >({{ filterSubTypes.length }}
              {{ "translate.general.selected" | translate }})</span
            >
          </button>
        </input-wrap>

        <p-overlayPanel #op (onHide)="onTypesPanelHide()">
          <ng-template pTemplate>
            <p-tree
              [value]="catPhraseTypes"
              selectionMode="checkbox"
              [(selection)]="selectedTypeNodes"
              scrollHeight="250px"
              filter="true"
              propagateSelectionUp="false"
            ></p-tree>
          </ng-template>
        </p-overlayPanel>

        <!--cat entry status-->
        <input-wrap
          [loading]="catEntryStatusList && catEntryStatusList.loading"
        >
          <p-dropdown
            [options]="catEntryStatusList"
            optionLabel="label"
            [(ngModel)]="filterCatEntryStatus"
            (onChange)="onFieldValueChanged($event)"
            [autoDisplayFirst]="false"
            [placeholder]="'translate.cat.catEntryStatus' | translate"
          >
          </p-dropdown>
        </input-wrap>

        <!-- reference -->
        <checkbox-wrap
          class="checkbox"
          [label]="'translate.cat.refSw' | translate"
        >
          <input
            type="checkbox"
            [(ngModel)]="refSw"
            (ngModelChange)="onFieldValueChanged()"
          />
        </checkbox-wrap>

        <a *ngIf="displayClearFilter" (click)="clearFilter()">{{
          "translate.general.clear" | translate
        }}</a>
      </div>

      <div class="l-align-center">
        <div *ngIf="!cardMode && allowEdit">
          <checkbox-wrap
            class="checkbox"
            [label]="'translate.general.selectAllOnPage' | translate"
          >
            <input
              type="checkbox"
              [(ngModel)]="toggleAllSw"
              (ngModelChange)="onToggleSelectAll()"
            />
          </checkbox-wrap>
          &nbsp;
          <button
            class="t-button-outline"
            [disabled]="selectedPhrases.length == 0 || loading"
            (click)="editSelected()"
          >
            {{ "translate.cat.editSelected" | translate }}
            ({{ selectedPhrases.length }})
          </button>
          &nbsp;
          <button
            class="t-button-outline"
            [disabled]="loading || !allowAddToAltRef"
            (click)="addToAltRef()"
          >
            {{ "translate.cat.addToAltRef" | translate }}
            ({{ selectedPhrases.length }})
          </button>
          &nbsp;
        </div>

        <button
          *ngIf="allowEdit"
          class="t-button"
          [disabled]="loading"
          (click)="newEntry()"
        >
          {{ "translate.cat.newEntry" | translate }}
        </button>

        <button
          *ngIf="selectMode"
          class="t-button"
          (click)="onAddPhrases()"
          [disabled]="selectedPhrases.length == 0"
        >
          {{ "translate.lc.addPhrases" | translate }}
          ({{ selectedPhrases.length }})
        </button>
      </div>
    </div>
  </ng-template>
</div>

<cat-doc-phrase-details
  *ngIf="phraseDetailsOpenSw"
  (onClose)="phraseDetailsOpenSw = false"
  (onDelete)="onEntryDeleted()"
  (onSave)="onPhraseSaved()"
  (onEdit)="onPhraseEdited()"
  [editable]="allowEdit"
  [mode]="'cat'"
  [displayEntryDocs]="true"
  [stateTag]="'PRIME'"
></cat-doc-phrase-details>

<cat-phrase-bulk-edit
  #phraseBulkEdit
  *ngIf="editPhraseTypeOpenSw"
  (onClose)="editPhraseTypeOpenSw = false"
  (onSave)="onBulkEditSave($event)"
>
</cat-phrase-bulk-edit>

<add-to-alt-ref
  *ngIf="displayAddToAltRef"
  [phrases]="selectedPhrases"
  (onClose)="afterAddToAltRef()"
></add-to-alt-ref>
