import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
} from '@angular/core';
import { Tab } from 'src/app/shared/models/tab.model';

@Component({
  selector: 'tab-button',
  templateUrl: './tab-button.component.html',
})
export class TabButtonComponent implements OnInit {
  @Input() tab: Tab;
  @Input() tabId: string;
  @Input() tabTitle: string;
  @Input() tabClass: string;
  @Input() icon: string;
  @Input() disabled: boolean;
  @Input() link: string;
  @Input() isActive: boolean;

  @Output() onTabClick = new EventEmitter<Tab>();

  constructor() {}

  ngOnInit() {
    if (!this.tab) {
      this.tab = new Tab();
      Object.keys(this.tab).forEach((key: string) => {
        this.tab[key] = this[key];
      });
    }
  }

  setActiveTabOnClick(tab) {
    if (!this.tab.disabled) this.onTabClick.emit(tab);
  }
}
