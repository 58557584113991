import { NgRedux } from '@redux/redux-compatibility.service';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { DataElement } from 'src/app/shared/models/common';
import { AppState } from 'src/app/shared/redux/store';

@Component({
  selector: 'add-asset-type',
  templateUrl: './add-asset-type.component.html',
  styleUrls: ['./add-asset-type.component.scss'],
})
export class AddAssetTypeComponent implements OnInit {
  @Output() onAdd = new EventEmitter<DataElement>();
  @Output() onClose = new EventEmitter();
  assetType: DataElement;

  get assetTypes(): DataElement[] {
    return this.ngRedux.getState().assetTypes;
  }

  constructor(private ngRedux: NgRedux<AppState>) {}

  ngOnInit(): void {}

  add() {
    this.onAdd.emit(this.assetType);
  }

  close() {
    this.onClose.emit();
  }
}
