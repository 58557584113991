import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { PhraseWordWrapper } from '../formatted-phrase/formatted-phrase.component';
import { createPopper, VirtualElement } from '@popperjs/core';
import { GrammarSpellingIssue } from '@shared/models/common';

@Component({
  selector: 'grammar-spelling-formatted-word',
  templateUrl: './grammar-spelling-formatted-word.component.html',
  styleUrls: ['./grammar-spelling-formatted-word.component.scss']
})
export class GrammarSpellingFormattedWordComponent implements AfterViewInit {

  @Input() wrappedWord: PhraseWordWrapper;
  @Input() first: boolean;
  @ViewChild("grammarSpellingSpan", { static: false }) spanRef!: ElementRef;
  @ViewChild("grammarSpellingTooltip", { static: false }) tooltipRef!: ElementRef;
  @ViewChild("grammarSpellingConcatSpan", { static: false }) concatSpan!: ElementRef;

  @Output() onAddToWhiteList = new EventEmitter<GrammarSpellingIssue>();

  static popperInstance: any = null;
  static activeTooltip: HTMLElement = null;

  ngAfterViewInit() {
    if (this.wrappedWord.grammarSpellingIssue || this.wrappedWord.referenceIssues?.length > 0) {
      const spanElement: HTMLElement = this.spanRef.nativeElement;
      const tooltip: HTMLElement = this.tooltipRef.nativeElement;

      function create(element: HTMLElement, tooltip: HTMLElement) {
        GrammarSpellingFormattedWordComponent.popperInstance = createPopper(element, tooltip, {
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 8],
              },
            },
            {
              name: 'preventOverflow',
              options: {
                //mainAxis: false, // true by default
                //boundary: spanElement
              },
            },
          ],
        });
        GrammarSpellingFormattedWordComponent.popperInstance.update();
      }

      function destroy() {
        if (GrammarSpellingFormattedWordComponent.popperInstance) {
          GrammarSpellingFormattedWordComponent.popperInstance.destroy();
          GrammarSpellingFormattedWordComponent.popperInstance = null;
        }
      }

      const thisComponent = this;

      function show(element: HTMLElement, tooltip: HTMLElement) {
        if ((thisComponent.wrappedWord.grammarSpellingIssue && !thisComponent.wrappedWord.grammarSpellingIssue.isWhitelisted)
        || thisComponent.wrappedWord.referenceIssues?.length > 0) {
          if (tooltip.getAttribute('data-show') === '') {
            hide(tooltip);
            GrammarSpellingFormattedWordComponent.activeTooltip = null;
          } else {
            if (GrammarSpellingFormattedWordComponent.popperInstance) {
              hide(GrammarSpellingFormattedWordComponent.activeTooltip);
            }

            tooltip.setAttribute('data-show', '');
            GrammarSpellingFormattedWordComponent.activeTooltip = tooltip;
            create(element, tooltip);
          }
        }
      }

      function hide(tooltip: HTMLElement) {
        tooltip.removeAttribute('data-show');
        destroy();
      }

      const showEvents = ['click'];
      showEvents.forEach(event => {
        spanElement.addEventListener(event, () => show(spanElement, tooltip));
        if (this.concatSpan) {
          const concatSpanElement = this.concatSpan.nativeElement;
          concatSpanElement.addEventListener(event, () => show(concatSpanElement, tooltip));
        }
      });

      document.addEventListener('click', (e) => {
        const target = e.target as HTMLElement;
        const targetParent = target?.closest('.word-reference-issue');
        if (!spanElement.isEqualNode(target) && !spanElement.isEqualNode(targetParent) && tooltip.getAttribute('data-show') === '') {
          hide(tooltip);
          GrammarSpellingFormattedWordComponent.activeTooltip = null;
        } 
      })
    }
  }

  addToWhiteList(grammarSpellingIssue: GrammarSpellingIssue) {
    this.spanRef.nativeElement.click();
    grammarSpellingIssue.isWhitelisted = true;
    this.onAddToWhiteList.emit(grammarSpellingIssue);
  }

  hasGrammarIssue(wrappedWord: PhraseWordWrapper) {
    return wrappedWord.grammarSpellingIssue && wrappedWord.grammarSpellingIssue.type === 'GRAMMAR'
  }

  hasSpellingIssue(wrappedWord: PhraseWordWrapper): boolean {
    return wrappedWord.grammarSpellingIssue && wrappedWord.grammarSpellingIssue.type === 'SPELLING'
  }

}
