<modal-popup
  [flexible]="true"
  [focusOnOpen]="true"
  [isOpen]="true"
  (onClose)="close()"
  [title]="'translate.cat.editPhraseOrder'"
>
  <div class="container">
    <div class="list">
      <p-orderList
        [value]="data.cards"
        dragdrop="true"
        controlsPosition="right"
        (onReorder)="onReorder($event)"
      >
        <ng-template let-row pTemplate="item">
          <div class="tile-container">
            <div class="phrase">
              {{ row.title }}
            </div>
            <div class="id">
              {{ row.cardId }}
            </div>
          </div>
        </ng-template>
      </p-orderList>
    </div>

    <div class="l-align-end l-mar-top">
      <button class="t-button" [disabled]="loading" (click)="save()">
        {{ "translate.general.save" | translate }}
      </button>
    </div>

    <loader *ngIf="loading"></loader>
  </div>
</modal-popup>
