import { Component, Input, OnInit } from '@angular/core';
import { IndPhrase } from '@shared/models/cat.model';
import { AssociatedTextFinding, AssociatedTextPhrase } from '@shared/models/common';

@Component({
  selector: 'cat-match-phrase-assoc-text',
  templateUrl: './cat-match-phrase-assoc-text.component.html',
  styleUrl: './cat-match-phrase-assoc-text.component.scss'
})
export class CatMatchPhraseAssocTextComponent implements OnInit {
  @Input() associatedText?: AssociatedTextPhrase;
  @Input() mainPhrase: IndPhrase;
  @Input() associatedTextFinding?: AssociatedTextFinding;
  showOriginal: boolean = false;

  ngOnInit(): void {
    console.log("associated text: ", this.associatedText)
    console.log("main phrase: ", this.mainPhrase)
    console.log("assoc text finding: ", this.associatedTextFinding)
  }

  catPhraseAssocText(catAssocPhraseId): AssociatedTextPhrase {
    return this.mainPhrase.associatedText.find(a => a.assocPhraseId === catAssocPhraseId);
  }

}
