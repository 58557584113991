import { NgRedux } from '@redux/redux-compatibility.service';
import { Component } from '@angular/core';
import { ReferenceMappingService } from 'src/app/shared/services/reference-mapping.service';
import { AppState } from 'src/app/shared/redux/store';
import { Catalog } from 'src/app/shared/models/cat.model';
@Component({
  selector: 'app-reference-mapping',
  templateUrl: './reference-mapping.component.html',
  styleUrl: './reference-mapping.component.scss'
})
export class ReferenceMappingComponent {
  data: any[] = [];
  loading: boolean = true;

  constructor(private referenceMappingService: ReferenceMappingService, private ngRedux: NgRedux<AppState>,) {}

  get curCat(): Catalog {
      return this.ngRedux.getState().curCat;
    }
  ngOnInit(): void {
    this.getData();
  }

  getData(): void {
    const catId = this.curCat.catId
    this.referenceMappingService.getReferenceMapping(catId).subscribe(
      (data) => {
        console.log('Data from API:', data);
        this.data = data;
        this.loading = false;
      },
      (error) => {
        console.error('Error loading table data:', error);
        this.loading = false;
      }
    );
  }
}
