import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { HttpClient } from '@angular/common/http';
import { Config } from 'src/app/config';
import { Observable } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { DataElement, ServiceCallInfo } from '../models/common';
import { ServiceOptions } from '@shared/models/service-options';

@Injectable({
  providedIn: 'root'
})
export class GrammarSpellingCheckService {

  scaiServiceUrl: string;

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) {
    this.scaiServiceUrl = Config.assetConfigData['scaiServiceUrl'];
  }

  getCatSpellingCheckIgnoredWords(catId: number): Observable<DataElement[]> {
    let url = `${this.scaiServiceUrl}grammar-spelling-check-config/${catId}/spelling-check-ignored-words`;
    return this.http
      .get(url, {
        headers: this.authService.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json',
      })
      .pipe(
        take(1),
        map((data: Array<any>) => {
          if (data && data.length) {
            return data.map(item => new DataElement(item.word_id, item.word));
          }
          return [];
        }),
        catchError((err) => {
          return this.handleError(err, url);
        })
      );
  }

  addCatSpellingCheckIgnoredWord(catId: number, word: string): Observable<DataElement> {
    let url = `${this.scaiServiceUrl}grammar-spelling-check-config/${catId}/spelling-check-ignored-words`;
    return this.http
      .post(url, { word }, {
        headers: this.authService.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json',
      })
      .pipe(
        take(1),
        map((data: any) => {
          if (data) {
            return new DataElement(data.word_id, data.word);
          }
        }),
        catchError((err) => {
          return this.handleError(err, url);
        })
      );
  }

  deleteCatSpellingCheckIgnoredWord(catId: number, wordId: number): Observable<boolean> {
    let url = `${this.scaiServiceUrl}grammar-spelling-check-config/${catId}/spelling-check-ignored-words/${wordId}`;
    return this.http
      .delete(url, {
        headers: this.authService.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json',
      })
      .pipe(
        take(1),
        map((data) => {
          return true;
        }),
        catchError((err) => {
          return this.handleError(err, url)
        })
      );
  }

  private handleError(error: any, url: string): Observable<never> {
    const info = new ServiceCallInfo(url, url, null);
    error.message = error.error.description || error.statusText;

    return this.authService.handleError(error, info);
  }

  updateSpellingLight(runNum: number, whitelistedWords: string[]): Observable<boolean> {
    let response = new Observable<boolean>();
    let serviceName = 'ScaiPyAdmin.UpdateSpellingLight';
    let params = new ServiceOptions();
    params.serviceName = serviceName;
    params['runNum'] = runNum;
    params['whitelistedWords'] = whitelistedWords;

    let url = this.authService.getRequestUrl(serviceName, true);
    let body = this.authService.getRequestBody(params);
    let info = new ServiceCallInfo(serviceName, url, body);
    response = this.http
      .post(url, body, {
        headers: this.authService.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json',
      })
      .pipe(
        map((data) => this.authService.extractRequestStatus(info, data)),
        catchError((error) => {
          return this.authService.handleError(error, info);
        })
      );

    return response;
  }
}
