import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'truncate' })
export class TruncatePipe implements PipeTransform {
  transform(value: string, wordCount: number, symbol: string): any {
    const words = value.split(' ');

    if (words.length <= wordCount) {
      return value;
    } else {
      const truncatedText = words.slice(0, wordCount).join(' ');
      return truncatedText + symbol;
    }
  }
}
