<div
  class="cat-phrase-ref-container"
  (mouseenter)="displayEditBtn = !editMode"
  (mouseleave)="displayEditBtn = false"
>
  <div *ngIf="!editMode">
    <div *ngIf="pointerWord" class="pointer-container">
      <div class="ref-pointer">
        <formatted-phrase [phraseWords]="[pointerWord]"></formatted-phrase>
        &nbsp;
        <button
          *ngIf="editable"
          class="t-small t-button t-button--pill t-btn-more bg-white"
          (click)="displayPointerWordList = !displayPointerWordList"
        >
          <i class="fas fa-angle-down t-small"></i>
        </button>
      </div>

      <slide-in-out [isOpen]="displayPointerWordList">
        <div class="l-flex l-wrap t-row--med">
          <div *ngFor="let word of mainPhraseWords">
            <checkbox-wrap class="checkbox" [label]="word.text">
              <input
                type="radio"
                [value]="word"
                name="pointerWord"
                [(ngModel)]="pointerWord"
                (ngModelChange)="onWordChanged()"
              />
            </checkbox-wrap>
            &nbsp;&nbsp;
          </div>
        </div>
      </slide-in-out>
    </div>

    <p *ngIf="ref.phraseDispWords.length == 0" class="text">
      {{ ref.refPhraseText }}
    </p>
    <formatted-phrase
      *ngIf="ref.phraseDispWords.length > 0"
      class="text"
      [phraseWords]="ref.phraseDispWords"
    ></formatted-phrase>
  </div>

  <div *ngIf="ref.matchSimilarityCode" class="ref-similarity">
    <p *ngIf="ref.matchSimilarityCode != 'MISSING'">
      {{ ref.similarityPct | percent }}
      {{ "translate.general.similarity" | translate }}
    </p>
    <p *ngIf="ref.matchSimilarityCode == 'MISSING'" class="missing">
      {{ "translate.rev.missing" | translate }}
    </p>
  </div>

  <div *ngIf="ref.altCatRefPhraseId > 0" class="ref-similarity">
    <p class="missing">
      {{ "translate.rev.altRefUsed" | translate }}
    </p>
  </div>

  <p *ngIf="ref.extraRefSw" class="extra">Extra context in working phrase</p>

  <textarea
    *ngIf="editMode"
    class="edit-area"
    pInputTextarea
    [(ngModel)]="ref.refPhraseText"
    autoResize="true"
    (ngModelChange)="markChanges()"
  ></textarea>

  <div class="l-align-spaceBetween l-mar-top">
    <span class="footnote id"
      >{{ "translate.cat.phraseId" | translate }}: {{ ref.refPhraseId }}</span
    >
    <div class="icons" *ngIf="editable">
      <!--edit-->
      <i
        *ngIf="allowEdit"
        (click)="editMode = true"
        class="fas fa-edit icon-edit"
      ></i>

      <!--cancel edit-->
      <i
        *ngIf="editMode && ref.refPhraseId"
        (click)="discardChanges()"
        class="fas fa-undo icon-edit"
      ></i>

      <!--delete-->
      <a (click)="onDeleteClicked()" class="t-small">Remove</a>
    </div>
  </div>
</div>
