import { NgRedux } from '@redux/redux-compatibility.service';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { IndPhrase } from 'src/app/shared/models/cat.model';
import { LabelChange, LCDoc } from 'src/app/shared/models/lc.model';
import { actionsList, AppState } from 'src/app/shared/redux/store';
import { AuthService } from 'src/app/shared/services/auth.service';
import { CatService } from 'src/app/shared/services/cat.service';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'lc-doc-phrases',
  templateUrl: './lc-doc-phrases.component.html',
  styleUrls: ['./lc-doc-phrases.component.scss'],
})
export class LcDocPhrasesComponent implements OnInit {
  @Input() type: 'ac' | 'bc';
  @Input() taggedSw: boolean;
  @Input() doc: LCDoc;
  @Input() title: string;

  get lc(): LabelChange {
    return this.ngRedux.getState().curLabelChange;
  }

  get phrases(): IndPhrase[] {
    if (this.taggedSw) {
      if (this.type == 'ac') {
        return this.lc.acPhrases;
      } else {
        return this.lc.bcPhrases;
      }
    } else {
      if (this.type == 'ac') {
        return this.lc.acDetectedPhrases;
      } else {
        return this.lc.bcDetectedPhrases;
      }
    }
  }

  constructor(
    private ngRedux: NgRedux<AppState>,
    private catService: CatService,
    private router: Router,
    private sharedService: SharedService,
    private authService: AuthService,
    private translateService: TranslateService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {}
}
