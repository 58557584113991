<div class="header">
  <div class="header-actions">
    <button class="t-button-outline" (click)="addTypeOpenSw = true">
      {{ "translate.general.add" | translate }}
    </button>
  </div>
</div>
<loader *ngIf="loading"></loader>
<ui-table
  [isWrapped]="false"
  [data]="cat.catAssetTypeList"
  [numberOfRows]="10"
  [displayFilter]="['id', 'text']"
  [templates]="[
    {
      title: 'translate.general.actions',
      template: actions
    }
  ]"
></ui-table>

<ng-template #actions let-row="row">
  <a (click)="delete(row)">{{ "translate.general.delete" | translate }}</a>
</ng-template>

<add-asset-type
  *ngIf="addTypeOpenSw"
  (onClose)="addTypeOpenSw = false"
  (onAdd)="add($event)"
>
</add-asset-type>
