import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  CatDocsSearchParams,
  Catalog,
  CatalogStatusEnum,
  CatDoc,
  CatDocStatusEnum,
  CatDocFileWrapper,
  CatDocStateTagEnum,
} from 'src/app/shared/models/cat.model';
import {
  DataElement,
  PaginationParams,
  ProcessRunStatusEnum,
} from 'src/app/shared/models/common';
import { TabIdList } from 'src/app/shared/models/tab.model';
import { ServiceMetaData } from 'src/app/shared/models/service-meta.model';
import { NgRedux } from '@redux/redux-compatibility.service';
import { AppState, actionsList } from 'src/app/shared/redux/store';
import { CatService } from 'src/app/shared/services/cat.service';
import { Router } from '@angular/router';
import { SharedService } from 'src/app/shared/services/shared.service';
import { keyCodes } from 'src/app/shared/models/key-codes.model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';
import { TableComponent } from 'src/app/shared/components/ui/table/table.component';

@Component({
  selector: 'cat-docs',
  templateUrl: './cat-docs.component.html',
  styleUrls: ['./cat-docs.component.scss'],
})
export class CatDocsComponent implements OnInit {
  @Input() selectMode: boolean;
  @Input() viewDocNavUrl: string;
  @Input() navMode: string;
  @Input() createDocType: string;
  @Output() onDocsSelected = new EventEmitter<CatDoc[]>();

  @ViewChild('tblDocs') tblDocs: TableComponent;
  @ViewChild('tblVersions') tblVersions: TableComponent;

  loading: boolean;
  rangeFrom: number;
  rangeTo: number;
  totalRecords: number;
  searchParams: CatDocsSearchParams = {};
  filterStatus: DataElement;
  filterDocIdName: string;
  filterInactive: boolean;
  selectedDoc: CatDoc;
  displayAddCatDoc: boolean;
  displayAddBulkDoc: boolean;
  createVersionParentDoc: CatDoc;
  getProcStatusFailedCnt: number = 0;
  processStatusTimers: NodeJS.Timeout[] = [];
  getProcessStatusIntervalSeconds: number = 5;
  displayProcessStatus: boolean;
  curDoc: CatDoc;
  selectedDocs: CatDoc[] = [];
  selectedVersions: CatDoc[] = [];
  docsBeingEdited: object = {};
  docsBeingUpdated: object = {};

  get docs(): CatDoc[] {
    return this.ngRedux.getState().catDocs;
  }

  get curCat(): Catalog {
    return this.ngRedux.getState().curCat;
  }

  get allowEdit(): boolean {
    if (!this.curCat || this.selectMode) return false;
    return this.curCat.statusCode == CatalogStatusEnum.DRAFT;
  }

  get catDocStatusList(): DataElement[] {
    return this.ngRedux.getState().catDocStatusList;
  }

  get docsMetaData(): ServiceMetaData {
    const metaData: ServiceMetaData = this.ngRedux.getState().catDocsMetadata;
    return metaData || {};
  }

  get displayClearFilter(): boolean {
    return (
      this.filterDocIdName != null ||
      this.filterStatus != null ||
      this.filterInactive
    );
  }

  get allowTagDocs() {
    return this.authService.currentUser.isAdmin && !this.curCat.extSw;
  }

  get allowParentSelect() {
    return this.selectMode;
  }

  get allowVersionSelect() {
    return this.allowTagDocs;
  }

  constructor(
    private ngRedux: NgRedux<AppState>,
    private catService: CatService,
    private router: Router,
    private sharedService: SharedService,
    private authService: AuthService,
    private translateService: TranslateService,
    private location: Location
  ) {
    this.ngRedux.subscribe(() => {
      const appState = this.ngRedux.getState();
      switch (appState._currentAction) {
        case actionsList.USER_SIGN_OUT:
          this.processStatusTimers.forEach((t) => {
            clearInterval(t);
          });
          break;
      }
    });
  }

  ngOnInit(): void {
    this.loadDocs(true);
  }

  ngOnDestroy(): void {
    this.processStatusTimers?.forEach((t) => {
      clearInterval(t);
    });
  }

  loadDocs(newSearch: boolean) {
    this.loading = true;

    if (newSearch) {
      this.searchParams.searchId = null;
      this.searchParams.rangeFrom = 1;
      this.searchParams.rangeTo = 10;
    }

    this.searchParams.docIdName = this.filterDocIdName;
    this.searchParams.status = this.filterStatus;
    this.searchParams.includeInactiveSw = this.filterInactive;

    this.processStatusTimers.forEach((t) => {
      clearInterval(t);
    });

    this.processStatusTimers = [];
    this.getProcStatusFailedCnt = 0;

    this.catService
      .getCatDocs(this.searchParams, this.curCat.catVersionId)
      .subscribe(
        (data) => {
          this.loading = false;

          let processingDocs = this.docs.filter(
            (d) => d.isProcessRunning && d.runNum
          );
          if (processingDocs.length > 0) {
            this.handleProcessingDocs(processingDocs);
          }
        },
        (err) => (this.loading = false)
      );
  }

  reloadSingleDoc(docId: number) {
    let searchParams = new CatDocsSearchParams();
    searchParams.docId = docId;

    this.catService
      .getCatDocs(searchParams, this.curCat.catVersionId, false)
      .subscribe((data) => {
        let curIdx = this.docs.findIndex((d) => d.docId == docId);
        if (curIdx != -1) {
          this.docs[curIdx] = data[0];
        }
      });
  }

  handleProcessingDocs(docs: CatDoc[]) {
    docs.forEach((d) => {
      let timer = setInterval(() => {
        this.authService.getProcessStatus(d.runNum).subscribe(
          (data) => {
            d.processStatus = data;
            if (
              data.status == ProcessRunStatusEnum.FINISHED ||
              data.status == ProcessRunStatusEnum.FAILED
            ) {
              clearInterval(timer);
              this.reloadSingleDoc(d.docId);
              if (this.curDoc && this.curDoc?.docId == d.docId) {
                this.displayProcessStatus = false;
              }
            }
          },
          (err) => {
            this.getProcStatusFailedCnt++;
            if (this.getProcStatusFailedCnt > 20) {
              clearInterval(timer);
            }
          }
        );
      }, this.getProcessStatusIntervalSeconds * 1000);

      this.processStatusTimers.push(timer);
    });
  }

  onPagination(event: PaginationParams) {
    if (
      !this.docs ||
      (event.rangeFrom == this.searchParams.rangeFrom &&
        event.rangeTo == this.searchParams.rangeTo)
    )
      return;

    this.searchParams.rangeFrom = event.rangeFrom;
    this.searchParams.rangeTo = event.rangeTo;
    this.searchParams.searchId =
      this.ngRedux.getState().catDocsMetadata.searchId;

    this.loadDocs(false);
  }

  onFilterKeyPress(e: any) {
    if (e.keyCode == keyCodes.enter) {
      this.loadDocs(true);
    }
  }

  onFieldValueChanged(e: any) {
    if (e != null) (e.originalEvent as MouseEvent).stopPropagation();
    this.loadDocs(true);
  }

  clearFilter() {
    this.filterDocIdName = null;
    this.filterStatus = null;
    this.filterInactive = false;
    this.loadDocs(true);
  }

  view(doc: CatDoc) {
    let viewDoc;
    if (doc.histDocs.length > 0) {
      viewDoc = doc.histDocs.find((d) => d.versionId == doc.lastVersionId);
    } else {
      viewDoc = doc;
    }

    this.ngRedux.dispatch({
      type: actionsList.SELECT_CAT_DOC,
      data: viewDoc,
      catDocSTateTag: CatDocStateTagEnum.PRIME,
    });

    if (this.selectMode) {
      this.router.navigate([this.viewDocNavUrl, this.navMode]);
    } else this.router.navigate([this.sharedService.routeEnum.URL_CAT_DOC]);
  }

  detach(doc: CatDoc) {
    let detachDoc;
    if (doc.histDocs.length > 0) {
      detachDoc = doc.histDocs.find((d) => d.versionId == doc.lastVersionId);
    } else {
      detachDoc = doc;
    }

    if (detachDoc.attachedCardSw) {
      let validationStr = this.translateService.instant(
        'translate.cat.detachWithCardWarning'
      );

      this.sharedService.emitPopupchReceivedEvent({
        title: 'translate.validation.confirm',
        icon: 'exclamation-triangle',
        subTitle: validationStr,
        keep: false,
        approve: () => {
          this.updateDocStatus(detachDoc, CatDocStatusEnum.DETACHED);
        },
      });
    } else this.updateDocStatus(detachDoc, CatDocStatusEnum.DETACHED);
  }

  reopen(doc: CatDoc) {
    let reopenDoc;
    if (doc.histDocs.length > 0) {
      reopenDoc = doc.histDocs.find((d) => d.versionId == doc.lastVersionId);
    } else {
      reopenDoc = doc;
    }

    this.updateDocStatus(reopenDoc, CatDocStatusEnum.DRAFT);
  }

  updateDocStatus(doc: CatDoc, statusCode: string) {
    this.loading = true;
    this.catService.updIndDoc(doc, statusCode).subscribe(
      (data) => {
        this.loading = false;
      },
      (err) => (this.loading = false)
    );
  }

  copyToOtherCat(doc: CatDoc) {}

  allowDetach(doc: CatDoc): boolean {
    return doc.statusCode == CatDocStatusEnum.ADDED_TO_CATALOG;
  }

  allowReopen(doc: CatDoc): boolean {
    return doc.statusCode != CatDocStatusEnum.DRAFT;
  }

  allowAddVersion(doc: CatDoc): boolean {
    return doc.statusCode == CatDocStatusEnum.DRAFT;
  }

  onDocCreated(doc: CatDoc) {
    this.displayAddCatDoc = false;
    if (!this.selectMode) this.loadDocs(true);
  }

  onAllDocsCreated(docs: CatDocFileWrapper[]) {
    if (this.selectMode) {
      this.onDocsSelected.emit(docs.map((d) => d.doc));
    }
  }

  openProcessStatus(doc: CatDoc) {
    this.displayProcessStatus = true;
    this.curDoc = doc;
  }

  onDocSelectionChanged(rows: CatDoc[]) {
    this.selectedDocs = rows;
  }

  onVersionSelectionChanged(rows: CatDoc[]) {
    this.selectedVersions = rows;
  }

  tagDocs() {
    let docIds = [
      ...new Set(
        [...this.selectedDocs, ...this.selectedVersions].map(
          (item) => item.origDocId
        )
      ),
    ];

    this.loading = true;
    this.catService.addTaggedDocs(docIds, 'IND').subscribe(
      (data) => {
        this.tblDocs?.resetSelection();
        this.tblVersions?.resetSelection();
        this.selectedDocs.forEach((d) => (d.taggedSw = true));
        this.selectedVersions.forEach((d) => (d.taggedSw = true));
        this.selectedDocs = [];
        this.selectedVersions = [];
        this.loading = false;
      },
      (err) => (this.loading = false)
    );
  }

  docsSelected() {
    this.onDocsSelected.emit(this.selectedDocs);
  }

  goBack() {
    this.location.back();
  }

  onClickEditDocName(event: Event, doc: CatDoc) {
    event.stopPropagation();
    this.docsBeingEdited[doc.docId] = { docName: doc.docName }
  }

  onClickCancelEditDocName(event: Event, docId: number) {
    event.stopPropagation();
    delete this.docsBeingEdited[docId];
  }

  onClickUpdateDocName(event: Event, docId: number) {
    event.stopPropagation();

    this.docsBeingUpdated[docId] = this.docsBeingEdited[docId];

    const docName = this.docsBeingUpdated[docId].docName;

    this.catService.renameCatDoc(docId, docName).subscribe(
      _ => {
        delete this.docsBeingUpdated[docId];
        delete this.docsBeingEdited[docId];

        let doc = this.docs.find(d => d.docId === docId);
        doc.docName = docName;
        doc.histDocs.forEach(hd => hd.docName = docName);
      },
      err => {
        delete this.docsBeingUpdated[docId];
        delete this.docsBeingEdited[docId];

        this.sharedService.alertError(err);
      }
    );
  }
}
