export enum ConfigScope {
  Library = 'library',
  Environment = 'environment',
  Product = 'product',
}

export interface IIntegrationType {
  type?: string;
  isConfigured?: boolean;
}

export interface IIntegrationTypeConfig {
  id?: number;
  catIntegrationSchemaId?: number;
  entityId?: number;
  value?: string;
}

export interface IIntegrationTypeSchema {
  id?: number;
  integrationType?: string;
  configScope?: ConfigScope;
  key?: string;
  label?: string;
  type?: string;
  isRequired?: boolean;
  isSecret?: boolean;
  isVisible?: boolean;
  defaultValue?: null;
  configuredValue?: null;
  minLength?: number;
  maxLength?: number;
  pattern?: string;
}

export interface IClaimPublishResult {
  id?: number;
  externalId?: string;
  error?: string;
  errorMessage?: string
}

export interface ICatalogPublishResult {
  catVersionId?: number;
  publishedClaims?: IClaimPublishResult[];
}

export class IntegrationType implements IIntegrationType {
  constructor(
    public type?: string,
    public isConfigured?: boolean
    // public id?: number,
    // public envId?: number,
    // public integrationType?: string
  ) {}
}

export class IntegrationTypeConfig implements IIntegrationTypeConfig {
  constructor(
    public id?: number,
    public catIntegrationSchemaId?: number,
    public entityId?: number,
    public value?: string
  ) {}
}

export class IntegrationTypeSchema implements IIntegrationTypeSchema {
  constructor(
    public id?: number,
    public value?: string,
    public integrationType?: string,
    public configScope?: ConfigScope,
    public key?: string,
    public label?: string,
    public type?: string,
    public isRequired?: boolean,
    public isSecret?: boolean,
    public defaultValue?: null,
    public configuredValue?: null,
    public minLength?: number,
    public maxLength?: number,
    public pattern?: string
  ) {}
}

export class ClaimPublishResult implements IClaimPublishResult {
  constructor(
    public id?: number,
    public externalId?: string,
    public error?: string,
    public errorMessage?: string
  ) {}

  public toString = () : string => {
    return `ClaimPublishResult (id: ${this.id}, externalId: ${this.externalId}, error: ${this.error}, errorMessage: ${this.errorMessage})`;
  }
}

export class CatalogPublishResult implements ICatalogPublishResult {
  constructor(
    public catVersionId?: number,
    public publishedClaims?: ClaimPublishResult[],
  ) {}
}
