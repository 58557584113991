<modal-popup
  [flexible]="true"
  [focusOnOpen]="false"
  [isOpen]="true"
  (onClose)="close()"
  [title]="'translate.lc.create'"
>
  <div class="container">
    <!--product-->
    <input-wrap
      [loading]="productList && productList.loading"
      label="{{ 'translate.general.productDescr' | translate }}"
      [required]="true"
      cssClass="l-cell-spread"
      [customValidation]="lc.product"
    >
      <p-dropdown
        [options]="productList"
        optionLabel="label"
        [(ngModel)]="lc.product"
        class="l-cell-spread-x"
        [resetFilterOnHide]="true"
        [filterPlaceholder]="'translate.general.search' | translate"
        [placeholder]="'translate.general.select' | translate"
        [filter]="true"
        appendTo="body"
        baseZIndex="100"
        (onChange)="onProductChange()"
        [disabled]="lc.lcId"
      >
      </p-dropdown>
    </input-wrap>

    <!--catalog-->
    <input-wrap
      [loading]="catalogList && catalogList.loading"
      label="{{ 'translate.general.catalog' | translate }}"
      [required]="true"
      cssClass="l-cell-spread"
      [customValidation]="lc.catalog"
    >
      <p-dropdown
        [options]="catalogList"
        optionLabel="catDescr"
        [disabled]="!lc.product"
        [(ngModel)]="lc.catalog"
        class="l-cell-spread-x"
        [resetFilterOnHide]="true"
        [filterPlaceholder]="'translate.general.search' | translate"
        [placeholder]="'translate.general.select' | translate"
        [filter]="true"
        appendTo="body"
        baseZIndex="100"
        [disabled]="lc.lcId"
      >
        <ng-template let-el pTemplate="item">
          <div class="l-align-spaceBetween">
            <p>{{ el.catDescr }}</p>
          </div>
        </ng-template>
      </p-dropdown>
    </input-wrap>

    <input-wrap
      label="{{ 'translate.lc.title' | translate }}"
      [required]="true"
      cssClass="l-cell-spread"
    >
      <input type="text" [(ngModel)]="lc.title" class="t-input" />
    </input-wrap>

    <input-wrap
      label="{{ 'translate.lc.descr' | translate }}"
      cssClass="l-cell-spread"
    >
      <textarea
        pInputTextarea
        [(ngModel)]="lc.descr"
        autoResize="false"
      ></textarea>
    </input-wrap>

    <div class="actions">
      <button class="t-button-outline" (click)="close()">
        {{ "translate.general.cancel" | translate }}
      </button>
      &nbsp;
      <button
        class="t-button"
        [disabled]="!enableSubmit || saving"
        (click)="save()"
      >
        {{ "translate.general.ok" | translate }}
      </button>
    </div>
  </div>

  <loader *ngIf="saving"></loader>
</modal-popup>
