class Pelette {
    UI__ = "darkred; font-style: italic; font-size: 70%";
    ERROR__ =
      "red; font-weigth: bold; font-size: 140%; text-shadow: 0px 0px 4px darkred; ";
    /*AGENT__ = "Green";
    AGENT_USER__ = "Gold";
    AGENT_NOTIFICATIONS__ = "Magenta";
    AGENT_KPI__ = "IndianRed";
    CAMPAIGNS__ = "BlueViolet";
    USERS__ = "CadetBlue";
    CALENDAR__ = "Chocolate";
    CATALOGUE__ = "DarkGoldenRod";
    MONITOR__ = "Pink";*/
    get(key) {
      const index = Object.keys(this).find(e => key.indexOf(e) !== -1);
      return this[index] || "DodgerBlue";
    }
  }
  const pelette = Object.freeze(new Pelette());
  export const actionLogger = (stateUpdate, action, update) => {
    const time = new Date().to_HH_MM_SS();
    console.groupCollapsed(
      "%c" + time + ": state update: " + "%c" + action.type,
      "color: darkgrey",
      "color: " + pelette.get(action.type)
    );
    action.component &&
      console.log("%c Component name: " + action.component, "color: tomato");
  
    console.groupCollapsed("State");
    console.log(stateUpdate);
    console.groupEnd();
  
    console.group("Update");
    Object.keys(update).forEach(key => {
      console.log("%c" + key, "color: tomato; text-decoration: underline");
      const updateValue = update[key];
      if (!updateValue) return;
      if (updateValue.authDetails) {
        // LOG USER DETAILS
        console.table(updateValue.authDetails);
        const updateValueClone = { ...updateValue };
        delete updateValueClone.authDetails;
        console.table(updateValueClone);
      } else if (typeof updateValue === "object" && !updateValue.length) {
        console.table(updateValue);
      } else {
        console.log(updateValue);
      }
      if (updateValue instanceof Map) {
        console.log(updateValue);
      }
    });
  
    console.groupEnd();
  
    if (action.metaData) {
      console.group("MetaData");
      console.table(action.metaData);
      console.groupEnd();
    }
    if (action.reply) {
      console.group("Raw data from server");
      console.log(action.reply);
      console.groupEnd();
    }
  
    console.groupEnd();
  };
  