import { NgRedux } from '@redux/redux-compatibility.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppState, actionsList } from 'src/app/shared/redux/store';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
})
export class BreadcrumbsComponent {
  constructor(
    private ngRedux: NgRedux<AppState>,
    private router: Router,
    private translateService: TranslateService
  ) {}

  crumbList: Array<string | number> = [];

  ngOnInit() {
    this.updateCrumbList(0, this.ngRedux.getState().ui_sidebarNavTitle);
    this._updByRoute(this.router.url);

    this.ngRedux.subscribe(() => {
      const appState = this.ngRedux.getState();
      switch (appState._currentAction) {
        case actionsList.UI__UPD_BREADCRUMBS_LEV:
          this.updateCrumbList(
            appState._currentActionData.lev,
            appState._currentActionData.title
          );
          break;
        case actionsList.UI__SIDEBAR_ROUTE_NAVIGATION:
          this.updateCrumbList(0, this.ngRedux.getState().ui_sidebarNavTitle);
          break;
        case actionsList.UI__ROUTE_NAVIGATION:
          this.updateCrumbList(0, this.ngRedux.getState().ui_sidebarNavTitle);
          this._updByRoute('/' + appState._currentActionData);
          break;
        case actionsList.SELECT_CATALOG:
          if (
            this.router.url != '/main/mng/tagged-docs' &&
            !this.router.url.startsWith('/main/lc')
          ) {
            if (this.ngRedux.getState().curCat) {
              this.updateCrumbList(1, this.ngRedux.getState().curCat.catDescr);
            } else {
              this.crumbList.splice(1, this.crumbList.length);
            }
          }

          break;
      }
    });
  }

  private _updByRoute(url: string) {
    if (url == '/main/mng/users') {
      this.updateCrumbList(
        1,
        this.translateService.instant('translate.sidebar.mngUsers')
      );
    } else if (url == '/main/mng/env') {
      this.updateCrumbList(
        1,
        this.translateService.instant('translate.sidebar.mngEnv')
      );
    } else if (url == '/main/mng/products') {
      this.updateCrumbList(
        1,
        this.translateService.instant('translate.sidebar.mngProducts')
      );
    } else if (url == '/main/mng/asset-types') {
      this.updateCrumbList(
        1,
        this.translateService.instant('translate.general.assetTypes')
      );
    } else if (url == '/main/mng/tagged-docs') {
      this.updateCrumbList(
        1,
        this.translateService.instant('translate.general.taggedDocs')
      );
    }
  }

  updateCrumbList(location: number, crumbLabel: string | number) {
    this.crumbList.splice(location, this.crumbList.length);
    this.crumbList.push(crumbLabel.toString());
  }
}
