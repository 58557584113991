<loader *ngIf="docLoading"></loader>

<div
  id="container"
  class="container"
  [class.ignore-pointer]="ignoreMouseEvents"
  *ngIf="pdfUrl && !docLoading"
>
  <doc-toolbar
    #docToolbar
    [doc]="doc"
    [initialPage]="initialPage"
    [displayedPage]="displayedPage"
    [displayPaging]="displayPaging"
    [displayDrawMode]="displayDrawMode"
    [displayToggleBoundingBoxes]="displayToggleBoundingBoxes"
    [displayToggleAnnotations]="displayToggleAnnotationsCalc"
    (onPageChanged)="goToPage($event)"
    (onToggleDrawMode)="onToggleDrawMode()"
    (onToggleAnnotations)="toggleAnnotations($event)"
    (onToggleImageAnnotations)="toggleImageAnnotations($event)"
    (onToggleBoundingBoxes)="toggleBoundingBoxes($event)"
    (onGoToCurPage)="goToCurPage($event)"
  ></doc-toolbar>

  <ng2-pdfjs-viewer
    viewerId="pdfViewer"
    #pdfViewer
    [pdfSrc]="pdfUrl"
    [zoom]="zoomLevel"
    [cursor]="cursor"
    openFile="false"
    viewBookmark="false"
    (onDocumentLoad)="pdfLoaded()"
    (onPageChange)="onViewerPageChanged($event)"
  ></ng2-pdfjs-viewer>
</div>
