import { NgRedux } from '@redux/redux-compatibility.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Unsubscribe } from 'redux';
import {
  DataElement,
  PaginationParams,
  Product,
} from 'src/app/shared/models/common';
import { LabelChange, LCSearchParams } from 'src/app/shared/models/lc.model';
import { ServiceMetaData } from 'src/app/shared/models/service-meta.model';
import { actionsList, AppState } from 'src/app/shared/redux/store';
import { CatService } from 'src/app/shared/services/cat.service';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'app-label-change',
  templateUrl: './label-change.component.html',
  styleUrls: ['./label-change.component.scss'],
})
export class LabelChangeComponent implements OnInit {
  displayCreateLC: boolean;
  loading: boolean;

  rangeFrom: number;
  rangeTo: number;
  totalRecords: number;
  filterProduct: DataElement;
  reduxSubscribe: Unsubscribe;
  searchParams: LCSearchParams = {};

  editLC: LabelChange;

  get data(): LabelChange[] {
    return this.ngRedux.getState().labelChanges;
  }

  get productList(): Product[] {
    return this.ngRedux.getState().productList;
  }

  get metadata(): ServiceMetaData {
    const metaData: ServiceMetaData = this.ngRedux.getState().lcMetadata;
    return metaData || {};
  }

  get displayClearFilter(): boolean {
    return this.filterProduct != null;
  }

  constructor(
    private ngRedux: NgRedux<AppState>,
    private router: Router,
    private sharedService: SharedService,
    private catService: CatService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.getData(true);
  }

  getData(newSearch: boolean) {
    this.loading = true;

    if (newSearch) {
      this.searchParams.searchId = null;
      this.searchParams.rangeFrom = 1;
      this.searchParams.rangeTo = 20;
    }

    this.searchParams.product = this.filterProduct;

    this.catService.getLabelChanges(this.searchParams).subscribe(
      (data) => {
        this.loading = false;
      },
      (err) => (this.loading = false)
    );
  }

  view(row: LabelChange) {
    this.ngRedux.dispatch({
      type: actionsList.SELECT_LC,
      data: row,
    });

    this.router.navigate([this.sharedService.routeEnum.URL_LC_DETAILS]);
  }

  edit(row: LabelChange) {
    this.editLC = Object.assign(new LabelChange(), row);
    this.displayCreateLC = true;
  }

  clearFilter() {
    this.filterProduct = null;
    this.getData(true);
  }

  onLCCreated() {
    this.displayCreateLC = false;
    this.editLC = null;
    this.getData(true);
  }

  add() {
    this.editLC = new LabelChange();
    this.displayCreateLC = true;
  }

  onPagination(event: PaginationParams) {
    if (
      !this.data ||
      (event.rangeFrom == this.searchParams.rangeFrom &&
        event.rangeTo == this.searchParams.rangeTo)
    ) {
      return;
    }

    this.searchParams.rangeFrom = event.rangeFrom;
    this.searchParams.rangeTo = event.rangeTo;
    this.searchParams.searchId = this.ngRedux.getState().lcMetadata.searchId;

    this.getData(false);
  }

  onFieldValueChanged(e: any) {
    (e.originalEvent as MouseEvent).stopPropagation();
    this.getData(true);
  }
}
