import { NgRedux } from '@redux/redux-compatibility.service';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { Card } from 'src/app/shared/models/cat.model';
import { actionsList, AppState } from 'src/app/shared/redux/store';
import { RevService } from 'src/app/shared/services/rev.service';

@Component({
  selector: 'rev-doc-cards',
  templateUrl: './rev-doc-cards.component.html',
  styleUrls: ['./rev-doc-cards.component.scss'],
})
export class RevDocCardsComponent implements OnInit {
  @Output() onClose = new EventEmitter();

  ruleDetailsOpenSw: boolean;
  tabItems: MenuItem[] = [];
  activeItem: MenuItem;
  curCard: Card;

  get revCards(): Card[] {
    return this.ngRedux.getState().revDocCards;
  }

  constructor(
    private ngRedux: NgRedux<AppState>,
    private revService: RevService
  ) {}

  ngOnInit(): void {
    this.ngRedux.subscribe(() => {
      const appState = this.ngRedux.getState();
      switch (appState._currentAction) {
        case actionsList.GET_REV_DOC_CARDS_END:
          this.revCards.forEach((c) =>
            this.tabItems.push({
              label: c.title,
              id: c.cardId.toString(),
              command: () => {
                this.curCard = c;
              },
            })
          );

          if (this.revCards && this.revCards.length > 0) {
            this.activeItem = this.tabItems[0];
            this.curCard = this.revCards[0];

            if (
              this.ngRedux.getState().dlRevDoc &&
              this.ngRedux.getState().dlRevDoc.cardId
            ) {
              let dlCard = this.revCards.find(
                (c) => c.cardId == this.ngRedux.getState().dlRevDoc.cardId
              );
              if (dlCard) {
                this.activeItem = this.tabItems[this.revCards.indexOf(dlCard)];
                this.curCard = dlCard;
              }
            }
          }
          break;
      }
    });
  }
}
