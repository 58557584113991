import { Component, Input, OnInit } from '@angular/core';

import { IFormFieldConfig } from '../models/dynamic-form.model';

@Component({
  selector: 'scai-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss']
})
export class DynamicFormComponent implements OnInit {
  @Input() formData: any;
  @Input() loading: boolean;
  @Input() set formFieldConfig(value: IFormFieldConfig[]) {
    setTimeout(() => {
      this._formFieldConfig = value;
      // Update formData with default values only if data wasn't sent
      if (!this.isFormUpdate && value) {
        for (const field of value) {
          if (!this.formData[field.key]) {
            this.formData[field.key] = field.configuredValue || field.defaultValue;
          }
        }
      }
    });
  }
  get formFieldConfig(): IFormFieldConfig[] | null {
    return this._formFieldConfig;
  }
  private _formFieldConfig: IFormFieldConfig[];
  isFormUpdate = false; // Flag indicating if form is being updated

  constructor() { }

  ngOnInit(): void {
    // Check if form data initially exists and set flag accordingly
    this.isFormUpdate = this.formData !== undefined && this.formData !== null && Object.keys(this.formData).length !== 0 ;

    if (!this.formData) {
      this.formData = {};
    }
  }
}
