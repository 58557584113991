import { Injectable } from '@angular/core';
import {
  RevDoc,
  RevRule,
  RevCatalog,
  RevDocsSearchParams,
  RevRulesSearchParams,
  RevStatus,
  CardMatch,
} from '../models/rev.model';
import { Observable } from 'rxjs';
import { ServiceOptions } from '../models/service-options';
import { map, catchError, take } from 'rxjs/operators';
import { ServiceMetaData } from '../models/service-meta.model';
import { actionsList, AppState } from '../redux/store';
import { NgRedux } from '@redux/redux-compatibility.service';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';
import { SharedService } from './shared.service';
import { DatePipe } from '@angular/common';
import {
  DataElement,
  DocPage,
  ReferencePhrase,
  AddInstr,
  PhraseWord,
  CatMatch,
  MatchDiff,
  Product,
  CardEntryMatch,
  BoundingBoxMatch,
  RefMatch,
  WordChar,
  ServiceCallInfo,
} from '../models/common';
import { TranslateService } from '@ngx-translate/core';
import { CatService } from './cat.service';
import { BoundingBox, Card } from '../models/cat.model';
import { Config } from 'src/app/config';

@Injectable({
  providedIn: 'root',
})
export class RevService {
  mockupSw: boolean = true;
  private mockupDataUrl = 'assets/mock/';
  private scaiServiceUrl: string;

  constructor(
    private ngRedux: NgRedux<AppState>,
    private http: HttpClient,
    private authService: AuthService,
    private datePipe: DatePipe,
    private sharedService: SharedService,
    private translateService: TranslateService,
    private catService: CatService
  ) {
    this.scaiServiceUrl = Config.assetConfigData['scaiServiceUrl'];
  }

  getRevDocs(
    searchParams: RevDocsSearchParams,
    dispatchDocList: boolean = true
  ): Observable<RevDoc[]> {
    /*if (this.mockupSw) {
      return this.http
        .get(this.mockupDataUrl + 'get_rev_docs.json')
        .pipe(map((res) => this.extractGetRevDocs(res)));
    }*/

    let response = new Observable<RevDoc[]>();
    let serviceName = 'get_rev_docs_func';
    let params = new ServiceOptions();
    params.serviceName = serviceName;
    params.urldecodeSw = false;

    params['envId'] = this.ngRedux.getState().curEnv.id;
    if (searchParams.docId) params['docId'] = searchParams.docId;
    if (searchParams.docName) params['docName'] = searchParams.docName;
    if (searchParams.docIdName) params['docIdName'] = searchParams.docIdName;
    if (searchParams.statusCode) params['statusCode'] = searchParams.statusCode;

    if (searchParams.product) params['productId'] = searchParams.product.id;

    params['includeInactiveSw'] = searchParams.includeInactiveSw || false;

    if (searchParams.searchId) params['searchId'] = searchParams.searchId;
    if (searchParams.rangeFrom) params['fromRec'] = searchParams.rangeFrom;
    if (searchParams.rangeTo) params['toRec'] = searchParams.rangeTo;

    let url = this.authService.getRequestUrl(serviceName);
    let body = this.authService.getRequestBody(params);
    let info = new ServiceCallInfo(serviceName, url, body);
    response = this.http
      .post(url, body, {
        headers: this.authService.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json',
      })
      .pipe(
        map((data) => this.extractGetRevDocs(data, dispatchDocList, info)),
        catchError((error) => {
          return this.authService.handleError(error, info);
        })
      );

    return response;
  }

  private extractGetRevDocs(
    res: any,
    dispatchDocList,
    info: ServiceCallInfo
  ): RevDoc[] {
    console.log(res);
    this.authService.extractRequestStatus(info, res);
    let result: RevDoc[] = [];
    let data = res.reply.requestResult.data;
    let metaData = new ServiceMetaData();

    if (data.metadata) {
      let metaTag = data.metadata;
      metaData.totalRecords = metaTag.totRec;
      metaData.fromRecord = metaTag.fromRec;
      metaData.toRecord = metaTag.toRec;
      metaData.searchId = metaTag.searchId;
    }

    if (data) {
      let dataTag = data.revDocs;
      if (dataTag) {
        let arr = new Array().concat(dataTag);
        arr.forEach((i) => {
          result.push(this._extractRevDocTag(i));
        });
      }

      if (!metaData.searchId && result) {
        metaData.fromRecord = 1;
        metaData.toRecord = result.length;
        metaData.totalRecords = result.length;
      }
    }

    result.srp_sortByKey('docId', -1);

    if (dispatchDocList)
      this.ngRedux.dispatch({
        type: actionsList.GET_REV_DOCS,
        data: result,
        reply: res,
        metaData: metaData,
      });

    return result;
  }

  _extractRevDocTag(i: any): RevDoc {
    let d = new RevDoc();
    d.docId = i.docId;
    d.docName = i.docName;
    d.lastVersionId = i.versionId;
    // d.createDateFormatted = this.datePipe.transform(
    //   i.crDate,
    //   SharedService.appDateFormat,
    //   this.authService.currentUser.timezone
    // );

    d.createDateFormatted = this.authService.formatDBDateTime(i.crDate);

    d.productId = i.productId;
    d.productDescr = i.productDescr;
    d.catId = i.catId;
    d.catDescr = i.catDescr;
    if (i.catVersion) {
      d.catVersionId = i.catVersion.catVersionId;
      d.actCatVersionId = i.catVersion.catVersionId;
    }

    d.newCatVersionSw = this.authService.fromXMLBoolean(i.newCatVersionSw);
    d.fileName = i.fileName;
    d.docType = i.docType;
    d.docS3Key = i.docS3Key;
    d.inddS3Key = i.inddS3Key;
    d.pagesNum = i.pageNums;
    d.statusCode = i.revDocStatusCode;
    d.statusDescr = i.revDocStatusDescr;
    d.statusDate = this.authService.formatDBDateTime(i.revDocStatusDate);
    d.runStatusCode = i.runStatusCode;
    d.runStatusMessage = i.runStatusMessage;
    d.runNum = i.runNum;
    d.parentDocId = i.parentDocId;
    d.uploadUserDescr = i.uploadUserDescr;
    if (i.assetType) d.assetType = new DataElement(i.assetType, i.assetDescr);

    let vArr = new Array().concat(i.versions);
    vArr.forEach((v) => {
      let version = new RevDoc();
      version.docId = v.docId;
      version.versionId = v.versionId;
      version.statusCode = v.revDocStatusCode;
      version.statusDescr = v.revDocStatusDescr;
      version.statusDate = this.authService.formatDBDateTime(
        v.revDocStatusDate
      );
      version.fileName = v.fileName;
      version.docType = v.docType;
      version.docS3Key = v.docS3Key;
      version.inddS3Key = v.inddS3Key;
      version.pagesNum = v.pageNums;
      version.docName = v.docName;
      version.createDateFormatted = this.authService.formatDBDateTime(v.crDate);
      version.productId = v.productId;
      version.productDescr = v.productDescr;
      version.catId = v.catId;
      version.catDescr = v.catDescr;
      //version.catVersionId = v.catVersionId;
      if (v.catVersion) {
        version.catVersionId = v.catVersion.catVersionId;
        version.actCatVersionId = v.catVersion.catVersionId;
      }
      version.runStatusCode = v.runStatusCode;
      version.runNum = v.runNum;
      version.uploadUserDescr = v.uploadUserDescr;
      if (v.assetType)
        version.assetType = new DataElement(v.assetType, v.assetDescr);

      if (v.pages) {
        version.pages = [];
        let pArr = new Array().concat(v.pages);
        pArr.forEach((p) => {
          let page = new DocPage();
          page.bucket = p.bucket;
          page.pageNum = p.dispPageNum;
          page.s3Key = p.s3Key;
          page.url = p.url;

          version.pages.push(page);
        });
      }

      d.docVersions.push(version);
    });

    d.docVersions.srp_sortByKey('versionId', -1);
    return d;
  }

  getRevDocStatusList(): Observable<DataElement[]> {
    let serviceName = 'get_rev_doc_status_list_func';

    /*if (this.mockupSw) {
      return this.http
        .get(this.mockupDataUrl + 'get_rev_doc_status_list.json')
        .pipe(
          map((res) => this.extractRevDocStatusList(res)),
          catchError((error) => {
            return this.authService.handleError(error, info);
          })
        );
    }*/

    let response = new Observable<DataElement[]>();
    let params = new ServiceOptions();
    params.serviceName = serviceName;

    let url = this.authService.getRequestUrl(serviceName);
    let body = this.authService.getRequestBody(params);
    let info = new ServiceCallInfo(serviceName, url, body);

    response = this.http
      .post(url, body, {
        headers: this.authService.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json',
      })
      .pipe(
        take(1),
        map((data) => {
          return this.extractRevDocStatusList(data, info);
        }),
        catchError((err) => {
          return this.authService.handleError(err, info);
        })
      );
    return response;
  }

  private extractRevDocStatusList(res, info: ServiceCallInfo): DataElement[] {
    const results: DataElement[] = this.authService.mapToDataElement(
      res,
      'revDocStatusCode',
      'descr',
      'revdocStatus',
      info
    );

    this.ngRedux.dispatch({
      type: actionsList.GET_REV_DOC_STATUS_LIST,
      data: results,
      reply: res,
    });

    return results;
  }

  getRevRules(
    docId: number,
    pageNum: number,
    searchParams: RevRulesSearchParams
  ): Observable<number> {
    let serviceName = 'get_rev_rules_func';

    let response = new Observable<number>();
    let params = new ServiceOptions();
    params.serviceName = serviceName;
    params.urldecodeSw = false;

    params['docId'] = docId;
    if (pageNum) params['dispPageNum'] = pageNum;

    if (searchParams) {
      if (searchParams.phrase) params['phraseText'] = searchParams.phrase;
      if (searchParams.ruleStatus)
        params['revRuleStatusCode'] = searchParams.ruleStatus.id;
      if (searchParams.contentType)
        params['revRuleContentType'] = searchParams.contentType.id;
      if (searchParams.matchType)
        params['revRuleMatchType'] = searchParams.matchType.id;
      if (searchParams.ruleType)
        params['revRuleTypeCode'] = searchParams.ruleType.id;
      if (searchParams.subTypeList)
        params['subTypeList'] = searchParams.subTypeList;
    }

    let url = this.authService.getRequestUrl(serviceName);
    let body = this.authService.getRequestBody(params);

    return this.authService.runGeneralSearch(body);
  }

  getRevRulesSearch(searchId: number): Observable<RevRule[]> {
    let serviceName = 'get_search_results_func';
    let response = new Observable<RevRule[]>();
    let params = new ServiceOptions();
    params.serviceName = serviceName;

    params['searchId'] = searchId;

    let url = this.authService.getRequestUrl(serviceName);
    let body = this.authService.getRequestBody(params);
    let info = new ServiceCallInfo(serviceName, url, body);
    response = this.http
      .post(url, body, {
        headers: this.authService.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json',
      })
      .pipe(
        map((data) => this.extractRevRules(data, info)),
        catchError((error) => {
          return this.authService.handleError(error, info);
        })
      );

    return response;
  }

  getRevRulesSearchWithoutDuplicates(searchId: number): Observable<RevRule[]> {
    return this.getRevRulesSearch(searchId).pipe(
      map((rules: RevRule[]) =>
        rules.map((rule: any) => {
          if (rule.referencedBy) {
            rule.referencedBy = this.removeDuplicatesFromPhrases(rule.referencedBy);
          }
          if (rule.references) {
            rule.references = this.removeDuplicatesFromPhrases(rule.references);
          }
          return rule;
        })
      )
    );
  }

  private removeDuplicatesFromPhrases(phrases: any[] = []): any[] {
    const uniqueRefTxt = new Set();
    return phrases.filter(phrase => {
      const isDuplicate = uniqueRefTxt.has(phrase.refPhraseText);
      uniqueRefTxt.add(phrase.refPhraseText);
      return !isDuplicate;
    });
  }

  private extractRevRules(res: any, info: ServiceCallInfo): RevRule[] {
    this.authService.extractRequestStatus(info, res);
    let result: RevRule[] = [];
    let data = res.reply.requestResult.data;

    if (data) {
      let dataTag = data.revRule;
      if (dataTag) {
        let arr = new Array().concat(dataTag);
        arr.forEach((i) => {
          result.push(this.catService._extractRevRuleTag(i));
        });
      }

      let statsTag = data.revStats;
      if (statsTag) {
        this.updRevDocStats(statsTag);
      }
    }

    result.srp_sortByKey('revRuleId');

    this.ngRedux.dispatch({
      type: actionsList.GET_REV_DOC_RULES,
      data: result,
      reply: res,
    });

    return result;
  }

  getRevRuleTypeList(): Observable<DataElement[]> {
    let serviceName = 'get_rev_rule_type_list_func';

    let response = new Observable<DataElement[]>();
    let params = new ServiceOptions();
    params.serviceName = serviceName;

    let url = this.authService.getRequestUrl(serviceName);
    let body = this.authService.getRequestBody(params);
    let info = new ServiceCallInfo(serviceName, url, body);

    response = this.http
      .post(url, body, {
        headers: this.authService.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json',
      })
      .pipe(
        take(1),
        map((data) => {
          return this.extractRevRuleTypes(data, info);
        }),
        catchError((err) => {
          return this.authService.handleError(err, info);
        })
      );
    return response;
  }

  private extractRevRuleTypes(res, info: ServiceCallInfo): DataElement[] {
    const results: DataElement[] = this.authService.mapToDataElement(
      res,
      'revDocRuleTypeCode',
      'descr',
      'revRuleType',
      info
    );

    this.ngRedux.dispatch({
      type: actionsList.GET_REV_RULE_TYPES,
      data: results,
      reply: res,
    });

    return results;
  }

  getRevCatalogList(product: Product): Observable<RevCatalog[]> {
    let serviceName = 'get_rev_catalog_list_func';

    /*if (this.mockupSw) {
      return this.http
        .get(this.mockupDataUrl + 'get_rev_catalog_list.json')
        .pipe(
          map((res) => this.extractRevCatalogList(res)),
          catchError((error) => {
            return this.authService.handleError(error, info);
          })
        );
    }*/

    let response = new Observable<RevCatalog[]>();
    let params = new ServiceOptions();
    params.serviceName = serviceName;

    params['envId'] = this.ngRedux.getState().curEnv.id;
    params['productId'] = Number.parseInt(product.id);

    let url = this.authService.getRequestUrl(serviceName);
    let body = this.authService.getRequestBody(params);
    let info = new ServiceCallInfo(serviceName, url, body);
    response = this.http
      .post(url, body, {
        headers: this.authService.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json',
      })
      .pipe(
        take(1),
        map((data) => {
          return this.extractRevCatalogList(data, info);
        }),
        catchError((err) => {
          return this.authService.handleError(err, info);
        })
      );
    return response;
  }

  private extractRevCatalogList(res, info: ServiceCallInfo): RevCatalog[] {
    this.authService.extractRequestStatus(info, res);
    let result: RevCatalog[] = [];
    let data = res.reply.requestResult.data;

    if (data && data.cat) {
      let arr = new Array().concat(data.cat);
      arr.forEach((i) => {
        let c = new RevCatalog();
        c.catId = i.catId;
        c.catDescr = i.descr;
        c.lastVersionId = i.lastVersionId;
        c.catVersionId = i.catVersionId;
        c.catType = i.catType;
        c.catTypeDescr = i.catTypeDescr;

        c.catAssetTypeList = [];
        if (i.catAssetTypes) {
          let assetTypeArr = new Array().concat(i.catAssetTypes);
          assetTypeArr.forEach((t) => {
            c.catAssetTypeList.push(new DataElement(t.assetType, t.assetDescr));
          });
        }

        result.push(c);
      });
    }

    return result;
  }

  updRevDoc(revDoc: RevDoc, statusCode: string): Observable<RevDoc> {
    let serviceName = 'upd_rev_doc_func';

    /*if (this.mockupSw) {
      return this.http.get(this.mockupDataUrl + 'update_result.json').pipe(
        map((res) => this.authService.extractRequestStatus(res, serviceName)),
        catchError((error) => {
          return this.authService.handleError(error, info);
        })
      );
    }*/

    let response = new Observable<RevDoc>();
    let params = new ServiceOptions();
    params.serviceName = serviceName;
    params['docId'] = revDoc.docId;
    params['revDocStatusCode'] = statusCode;

    let url = this.authService.getRequestUrl(serviceName);
    let body = this.authService.getRequestBody(params);
    let info = new ServiceCallInfo(serviceName, url, body);
    response = this.http
      .post(url, body, {
        headers: this.authService.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json',
      })
      .pipe(
        map((data) => {
          return this.extractUpdRevDoc(data, info);
        }),
        catchError((err) => {
          return this.authService.handleError(err, info);
        })
      );
    return response;
  }

  extractUpdRevDoc(res: any, info: ServiceCallInfo): RevDoc {
    this.authService.extractRequestStatus(info, res);
    let data = res.reply.requestResult.data;
    let result: RevDoc;

    if (data && data.revDoc) {
      result = this._extractRevDocTag(data.revDoc);
    }

    this.ngRedux.dispatch({
      type: actionsList.UPD_REV_DOC,
      data: result,
      reply: res,
    });

    return result;
  }

  updRevRule(
    revDoc: RevDoc,
    revRules: RevRule[],
    status: RevStatus,
    variantOrigPhraseId: number = null
  ): Observable<RevRule[]> {
    let serviceName = 'upd_rev_rule_func';

    /*if (this.mockupSw) {
      return this.http.get(this.mockupDataUrl + 'update_result.json').pipe(
        map((res) => this.authService.extractRequestStatus(res, serviceName)),
        catchError((error) => {
          return this.authService.handleError(error, info);
        })
      );
    }*/

    let response = new Observable<RevRule[]>();
    let params = new ServiceOptions();
    params.serviceName = serviceName;
    params['docId'] = revDoc.docId;
    params['revRuleIds'] = revRules.map((r) => r.revRuleId);
    params['revRuleStatusCode'] = status;
    params['variantOrigPhraseId'] = variantOrigPhraseId;
    if (revRules.length == 1) {
      params['revRuleComment'] = revRules[0].comment;
      if (
        revRules[0].revRuleType == 'CAT_MATCH' &&
        revRules[0].ruleCatMatches.length > 0
      ) {
        let idx = revRules[0].ruleCatMatches.findIndex(
          (m) => m.reportDisplaySw
        );
        if (idx != -1)
          params['reportDisplayCatMatchSeq'] =
            revRules[0].ruleCatMatches[idx].catMatchSeq;
      }
    }

    params.urldecodeSw = true;

    // let cards: Card[] = [];
    // revRules.forEach((r) => {
    //   if (
    //     r.cardEntryMatch &&
    //     cards.findIndex((c) => c.cardId == r.cardEntryMatch.card.cardId) == -1
    //   ) {
    //     cards.push(r.cardEntryMatch.card);
    //   }
    // });

    let url = this.authService.getRequestUrl(serviceName);
    let body = this.authService.getRequestBody(params);
    let info = new ServiceCallInfo(serviceName, url, body);
    response = this.http
      .post(url, body, {
        headers: this.authService.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json',
      })
      .pipe(
        map((data) => {
          return this.extractUpdRevRule(data, info);
        }),
        catchError((err) => {
          return this.authService.handleError(err, info);
        })
      );
    return response;
  }

  private extractUpdRevRule(
    res,
    //cards: Card[],
    info: ServiceCallInfo
  ): RevRule[] {
    this.authService.extractRequestStatus(info, res);
    let data = res.reply.requestResult.data;
    let results: RevRule[] = [];

    if (data) {
      let rulesTag = data.revRule;
      if (rulesTag) {
        let arr = new Array().concat(rulesTag);
        arr.forEach((i) => {
          results.push(this.catService._extractRevRuleTag(i));
        });
      }

      let statsTag = data.revStats;
      if (statsTag) {
        this.updRevDocStats(statsTag);
      }
    }

    return results;
  }

  updRevDocStats(statsTag: any) {
    let passedCnt = 0;
    let failedCnt = 0;
    let dismissedCnt = 0;
    let markedForChangesCnt = 0;
    let notPassedVarCnt = 0;
    let newContentCnt = 0;
    let stetCnt = 0;
    let newClaimCnt = 0;
    let claimVariantCnt = 0;
    let progressPct;
    let totCnt = statsTag.totCnt;

    let statsArr = new Array().concat(statsTag.revStatusStats);
    statsArr.forEach((s) => {
      switch (s.revRuleStatusCode) {
        case RevStatus.PASSED:
          passedCnt = s.cnt;
          break;
        case RevStatus.NOT_PASSED:
          failedCnt = s.cnt;
          break;
        case RevStatus.DISMISSED:
          dismissedCnt = s.cnt;
          break;
        case RevStatus.MARKED_FOR_CHANGES:
          markedForChangesCnt = s.cnt;
          break;
        case RevStatus.NOT_PASSED_VAR:
          notPassedVarCnt = s.cnt;
          break;
        case RevStatus.NEW_CONTENT:
          newContentCnt = s.cnt;
          break;
        case RevStatus.STET:
          stetCnt = s.cnt;
          break;
        case RevStatus.NEW_CLAIM:
          newClaimCnt = s.cnt;
          break;
        case RevStatus.CLAIM_VARIANT:
          claimVariantCnt = s.cnt;
          break;
      }
    });

    progressPct = statsTag.progressPct;

    this.ngRedux.dispatch({
      type: actionsList.UPD_REV_DOC_STATS,
      data: {
        passedCnt: passedCnt,
        failedCnt: failedCnt,
        dismissedCnt: dismissedCnt,
        markedCnt: markedForChangesCnt,
        stetCnt: stetCnt,
        notPassedVarCnt: notPassedVarCnt,
        newContentCnt: newContentCnt,
        progressPct: progressPct,
        totCnt: totCnt,
        newClaimCnt: newClaimCnt,
        claimVariantCnt: claimVariantCnt
      },
    });
  }

  createRevDoc(revDoc: RevDoc): Observable<RevDoc> {
    let serviceName = 'create_rev_doc_func';

    let response = new Observable<RevDoc>();
    let params = new ServiceOptions();
    params.serviceName = serviceName;
    params['envId'] = this.ngRedux.getState().curEnv.id;
    params['docName'] = revDoc.docName;
    params['parentDocId'] = revDoc.parentDocId;
    params['productId'] = Number.parseInt(revDoc.productId);
    params['catId'] = revDoc.catId;
    params['catVersionId'] = revDoc.catVersionId;
    params['docS3Key'] = revDoc.docS3Key;
    params['fileName'] = revDoc.fileName;
    if (revDoc.assetType) {
      params['assetType'] = Number.parseInt(revDoc.assetType.id);
    }
    // params['algRunType'] = 'APRIMO_TEST';

    let url = this.authService.getRequestUrl(serviceName);
    let body = this.authService.getRequestBody(params);
    let info = new ServiceCallInfo(serviceName, url, body);
    response = this.http
      .post(url, body, {
        headers: this.authService.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json',
      })
      .pipe(
        map((data) => {
          return this.extractCreateRevDoc(data, info);
        }),
        catchError((err) => {
          return this.authService.handleError(err, info);
        })
      );
    return response;
  }

  extractCreateRevDoc(res: any, info: ServiceCallInfo): RevDoc {
    this.authService.extractRequestStatus(info, res);
    let data = res.reply.requestResult.data;
    let result: RevDoc;

    if (data) {
      result = this._extractRevDocTag(data);
    }

    return result;
  }

  getRevRuleStatusList(): Observable<DataElement[]> {
    let serviceName = 'get_rev_rule_status_list_func';

    let response = new Observable<DataElement[]>();
    let params = new ServiceOptions();
    params.serviceName = serviceName;

    let url = this.authService.getRequestUrl(serviceName);
    let body = this.authService.getRequestBody(params);
    let info = new ServiceCallInfo(serviceName, url, body);

    response = this.http
      .post(url, body, {
        headers: this.authService.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json',
      })
      .pipe(
        take(1),
        map((data) => {
          return this.extractRevRuleStatusList(data, info);
        }),
        catchError((err) => {
          return this.authService.handleError(err, info);
        })
      );
    return response;
  }

  private extractRevRuleStatusList(res, info: ServiceCallInfo): DataElement[] {
    const results: DataElement[] = this.authService.mapToDataElement(
      res,
      'revRuleStatusCode',
      'descr',
      'revRuleStatus',
      info
    );

    this.ngRedux.dispatch({
      type: actionsList.GET_REV_RULE_STATUS_LIST,
      data: results,
      reply: res,
    });

    this.translateService.set(
      'translate.general.passedCnt',
      this.ngRedux
        .getState()
        .revRuleStatusList.find((s) => s.id == RevStatus.PASSED).text,
      this.ngRedux.getState().ui_lang
    );
    this.translateService.set(
      'translate.general.failedCnt',
      this.ngRedux
        .getState()
        .revRuleStatusList.find((s) => s.id == RevStatus.NOT_PASSED).text,
      this.ngRedux.getState().ui_lang
    );
    this.translateService.set(
      'translate.general.dismissedCnt',
      this.ngRedux
        .getState()
        .revRuleStatusList.find((s) => s.id == RevStatus.DISMISSED).text,
      this.ngRedux.getState().ui_lang
    );
    this.translateService.set(
      'translate.general.markedCnt',
      this.ngRedux
        .getState()
        .revRuleStatusList.find((s) => s.id == RevStatus.MARKED_FOR_CHANGES)
        .text,
      this.ngRedux.getState().ui_lang
    );
    this.translateService.set(
      'translate.general.stetCnt',
      this.ngRedux
        .getState()
        .revRuleStatusList.find((s) => s.id == RevStatus.STET).text,
      this.ngRedux.getState().ui_lang
    );
    this.translateService.set(
      'translate.general.notPassedVarCnt',
      this.ngRedux
        .getState()
        .revRuleStatusList.find((s) => s.id == RevStatus.NOT_PASSED_VAR).text,
      this.ngRedux.getState().ui_lang
    );
    this.translateService.set(
      'translate.general.newClaimCnt',
      this.ngRedux
        .getState()
        .revRuleStatusList.find((s) => s.id == RevStatus.NEW_CLAIM).text,
      this.ngRedux.getState().ui_lang
    );
    this.translateService.set(
      'translate.general.claimVariantCnt',
      this.ngRedux
        .getState()
        .revRuleStatusList.find((s) => s.id == RevStatus.CLAIM_VARIANT).text,
      this.ngRedux.getState().ui_lang
    );
    return results;
  }

  createAnnotateFileReq(doc: RevDoc): Observable<number> {
    let serviceName = 'create_annotate_file_req_func';

    let response = new Observable<number>();
    let params = new ServiceOptions();
    params.serviceName = serviceName;

    params['docId'] = doc.docId;
    params['catVersionId'] = doc.catVersionId;
    params['docS3Key'] = doc.docS3Key;

    let url = this.authService.getRequestUrl(serviceName);
    let body = this.authService.getRequestBody(params);
    let info = new ServiceCallInfo(serviceName, url, body);
    response = this.http
      .post(url, body, {
        headers: this.authService.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json',
      })
      .pipe(
        map((data) => {
          return this.extractAnnotateFileReq(data, info);
        }),
        catchError((err) => {
          return this.authService.handleError(err, info);
        })
      );
    return response;
  }

  extractAnnotateFileReq(res: any, info: ServiceCallInfo): number {
    this.authService.extractRequestStatus(info, res);
    let data = res.reply.requestResult.data;
    if (data) {
      return data.runNum;
    }

    return 0;
  }

  processAnnotatePrecheck(runNum: number): Observable<boolean> {
    let response = new Observable<boolean>();
    let serviceName = 'ScaiPyAdmin.StartDocProcess';
    let params = new ServiceOptions();
    params.serviceName = serviceName;

    params['runNum'] = runNum;
    //params['username'] = this.ngRedux.getState().user.username;
    params['userId'] = this.ngRedux.getState().user.userId;
    params['token'] = this.ngRedux.getState().user.token;

    let url = this.authService.getRequestUrl(serviceName, true);
    let body = this.authService.getRequestBody(params);
    let info = new ServiceCallInfo(serviceName, url, body);
    response = this.http
      .post(url, body, {
        headers: this.authService.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json',
      })
      .pipe(
        map((data) => this.authService.extractRequestStatus(info, data)),
        catchError((error) => {
          return this.authService.handleError(error, info);
        })
      );

    return response;
  }

  getRevCards(docId: number): Observable<number> {
    let serviceName = 'get_rev_cards_func';
    let params = new ServiceOptions();
    params.serviceName = serviceName;
    params.urldecodeSw = false;
    params['docId'] = docId;
    let body = this.authService.getRequestBody(params);
    return this.authService.runGeneralSearch(body);
  }

  getRevCardsSearch(searchId: number): Observable<void> {
    let serviceName = 'get_search_results_func';
    let response = new Observable<void>();
    let params = new ServiceOptions();
    params.serviceName = serviceName;

    params['searchId'] = searchId;

    let url = this.authService.getRequestUrl(serviceName);
    let body = this.authService.getRequestBody(params);
    let info = new ServiceCallInfo(serviceName, url, body);
    response = this.http
      .post(url, body, {
        headers: this.authService.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json',
      })
      .pipe(
        map((data) => this.extractRevCards(data, info)),
        catchError((error) => {
          return this.authService.handleError(error, info);
        })
      );

    return response;
  }

  private extractRevCards(res: any, info: ServiceCallInfo) {
    this.authService.extractRequestStatus(info, res);
    let data = res.reply.requestResult.data;

    if (data) {
      let mcCards: Card[] = [];
      let mcTag = data.catCards;
      if (mcTag) {
        let arr = new Array().concat(mcTag);
        arr.forEach((i) => {
          let card = this.catService._extractCardTag(i);
          mcCards.push(card);
        });
      }
      this.ngRedux.dispatch({
        type: actionsList.GET_REV_DOC_MATCHED_CARDS,
        data: mcCards,
        reply: res,
      });

      let revCards: Card[] = [];
      let rcTag = data.revCards;
      if (rcTag) {
        let arr = new Array().concat(rcTag);
        arr.forEach((i) => {
          let card = this.catService._extractCardTag(i);
          card.matchedCard = mcCards.find(
            (c) => card.matchedCardId == c.cardId
          );
          revCards.push(card);
        });
      }
      this.ngRedux.dispatch({
        type: actionsList.GET_REV_DOC_CARDS,
        data: revCards,
        reply: res,
      });

      let cardMatches: CardMatch[] = [];
      let cmTag = data.cardMatch;
      if (cmTag) {
        let arr = new Array().concat(cmTag);
        arr.forEach((i) => {
          let match = this.catService._extractRevCardMatchTag(i);
          cardMatches.push(match);
        });
      }

      this.ngRedux.dispatch({
        type: actionsList.GET_REV_CARD_MATCHES,
        data: cardMatches,
        reply: res,
      });

      this.ngRedux.getState().revDocRules.forEach((r) => {
        if (r.cardMatchId) this.catService._setRuleCardMatch(r);
      });

      this.ngRedux.dispatch({
        type: actionsList.GET_REV_DOC_CARDS_END,
        data: cardMatches,
        reply: res,
      });
    }
  }

  getRevRuleSingle(docId: number, phraseId: number): Observable<RevRule> {
    let serviceName = 'get_rev_rules_func';

    let response = new Observable<RevRule>();
    let params = new ServiceOptions();
    params.serviceName = serviceName;
    params.urldecodeSw = false;

    params['docId'] = docId;
    params['phraseId'] = phraseId;

    let url = this.authService.getRequestUrl(serviceName);
    let body = this.authService.getRequestBody(params);
    let info = new ServiceCallInfo(serviceName, url, body);
    response = this.http
      .post(url, body, {
        headers: this.authService.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json',
      })
      .pipe(
        map((res: any) => {
          this.authService.extractRequestStatus(info, res);
          let result: RevRule[] = [];
          let data = res.reply.requestResult.data;
          if (data) {
            let dataTag = data.revRule;
            if (dataTag) {
              let arr = new Array().concat(dataTag);
              arr.forEach((i) => {
                result.push(this.catService._extractRevRuleTag(i));
              });
            }
          }
          return result[0];
        }),
        catchError((error) => {
          return this.authService.handleError(error, info);
        })
      );

    return response;
  }

  renameRevDoc(docId: number, docName: string): Observable<boolean> {
    let url = `${this.scaiServiceUrl}rev-docs/${docId}`;
    return this.http
      .patch(url, { docName }, {
        headers: this.authService.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json',
      })
      .pipe(
        take(1),
        map((data: any) => {
          return true;
        }),
        catchError((err) => {
          return this.handleError(err, url);
        })
      );
  }

  private handleError(error: any, url: string): Observable<never> {
    const info = new ServiceCallInfo(url, url, null);
    error.message = error.error.description || error.statusText;

    return this.authService.handleError(error, info);
  }
}
