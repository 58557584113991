<div class="l-align-spaceBetween">
  <h3>
    {{ title | translate }}
  </h3>

  <p class="similarity">
    {{ match.similarityPct | percent }}
    {{ "translate.general.similarity" | translate }}
  </p>
</div>

<div *ngIf="match.catPhrase">
  <div class="template-phrase-txt-diff-wrap">
    <!-- phrase text -->
    <p
      *ngIf="match.catPhrase.phraseWords.length == 0"
      class="template-phrase-txt"
    >
      {{ match.catPhrase.phraseText }}
    </p>
    <div
      *ngIf="match.catPhrase.phraseWords.length > 0"
      class="template-phrase-txt"
    >
      <formatted-phrase
        [phraseWords]="match.catPhrase.phraseWords"
      ></formatted-phrase>
    </div>

    <!--diff-->
    <div
      *ngIf="match.diffParts && match.diffParts.length > 0"
      class="diff-container"
    >
      <div class="diff-title">
        <i class="fas fa-not-equal"></i>
        &nbsp; &nbsp;
        <p>{{ "translate.general.diff" | translate }}</p>
      </div>
      <p class="template-phrase-txt">
        <span
          *ngFor="let word of match.diffParts"
          class="match-diff"
          [class.missing]="word.diffType == 'MISSING'"
          [class.extra]="word.diffType == 'EXTRA'"
          >{{ word.text }}</span>
      </p>
    </div>
  </div>
  <!--references-->
  <div *ngIf="!match.catPhrase.isRefSw">
    <h3
      *ngIf="match.catPhrase.references.length > 0 && !match.catPhrase.isRefSw"
    >
      {{ "translate.rev.reference" | translate }}
    </h3>
    <div *ngIf="referenceIssues">
      <cat-match-phrase-ref
        *ngFor="let ref of match.catPhrase.references"
        class="ref"
        [ref]="ref"
        [mainPhrase]="match.catPhrase"
        [referenceIssue]="getReferenceIssue(match.catPhrase.phraseId, ref.refPhraseId)"
      ></cat-match-phrase-ref>
      <cat-match-phrase-ref
        *ngFor="let issue of getAddedReferenceIssue(match.catPhrase.phraseId)"
        class="ref"
        [mainPhrase]="match.catPhrase"
        [referenceIssue]="issue"
      ></cat-match-phrase-ref>
    </div>
    <div *ngIf="!referenceIssues">
      <cat-phrase-ref
        *ngFor="let ref of match.catPhrase.references"
        class="ref"
        [ref]="ref"
        [mainPhrase]="match.catPhrase"
        (onDeleteRef)="onDeleteRef(ref)"
        [editable]="false"
      ></cat-phrase-ref>
    </div>
  </div>

  <!--referenced by -->
  <div
    *ngIf="match.catPhrase.referencedBy.length > 0 && match.catPhrase.isRefSw"
    class="reference"
  >
    <p class="title">{{ "translate.rev.referencedBy" | translate }}:</p>
    <div *ngFor="let ref of match.catPhrase.referencedBy" class="ref-container">
      <p class="text">{{ ref.refPhraseText }}</p>
    </div>
  </div>

  <!--instructions-->
  <div
    *ngIf="match.catPhrase.addInstr && match.catPhrase.addInstr.length > 0"
    class="instructions"
  >
    <p class="title">{{ "translate.rev.instructions" | translate }}:</p>
    <cat-add-instr
      *ngFor="let instr of match.catPhrase.addInstr"
      [instr]="instr"
      [editable]="false"
    ></cat-add-instr>
  </div>
</div>

<!-- cat image -->
<div *ngIf="match.catImage">
  <!-- <img class="ind-img" [src]="match.catImage.imgUrl" /> -->

  <async-img
    *ngIf="match.catImage.imgS3Key"
    [className]="'inner-img'"
    [url]="match.catImage.imgUrl"
  ></async-img>

  <!--instructions-->
  <div
    *ngIf="match.catImage.addInstr && match.catImage.addInstr.length > 0"
    class="instructions"
  >
    <p class="title">{{ "translate.rev.instructions" | translate }}:</p>
    <cat-add-instr
      *ngFor="let instr of match.catImage.addInstr"
      [instr]="instr"
      [editable]="false"
    ></cat-add-instr>
  </div>
</div>

<!--table diff-->
<div *ngIf="match.tblRowDiff">
  <br />
  <div class="diff-title">
    <i class="fas fa-not-equal"></i>
    &nbsp;
    <span class="fw-bold">{{ "translate.general.diff" | translate }}</span>
  </div>

  <div *ngFor="let diff of match.tblRowDiff" class="tbl-diff-container">
    <table class="tbl-diff-tbl">
      <tr>
        <td class="row-title">Library row</td>
        <td
          *ngFor="let cell of diff.catRow.cells"
          [class.diff]="cell.diffType != 'FULL_MATCH'"
        >
          {{ cell.txt }}
        </td>
      </tr>
      <tr>
        <td class="row-title">Document row</td>
        <td
          *ngFor="let cell of diff.docRow.cells"
          [class.diff]="cell.diffType != 'FULL_MATCH'"
        >
          {{ cell.txt }}
        </td>
      </tr>
    </table>
  </div>
</div>

<div class="l-align-spaceBetween">
  <!--type-->
  <div *ngIf="match.catPhrase" class="cat-phrase-type">
    <span>
      {{ match.catPhrase.typeDescr }}
      <span class="sub-type" *ngIf="match.catPhrase.subTypeDescr"
        >&middot; {{ match.catPhrase.subTypeDescr }}</span
      >
    </span>
  </div>
  <div *ngIf="!match.catPhrase">&nbsp;</div>

  <p class="match-id">
    <span *ngIf="match.catPhrase">
      {{ "translate.cat.phraseId" | translate }}:
      {{ match.catPhrase.phraseId }}</span
    >
    <span *ngIf="match.catImage">
      {{ "translate.cat.imageId" | translate }}:
      {{ match.catImage.imageId }}</span
    >
    &nbsp;
    {{ "translate.cat.matchSeq" | translate }}: {{ match.catMatchSeq }}
  </p>
</div>

<!--docs-->
<div
  *ngIf="
    (match.catPhrase &&
      match.catPhrase.docs &&
      match.catPhrase.docs.length > 0) ||
    (match.catImage && match.catImage.docs && match.catImage.docs.length > 0)
  "
  class="docs-container t-small"
>
  <a (click)="displayDocsSw = !displayDocsSw">
    {{ "translate.cat.matchSourceDocs" | translate }}
  </a>
  <slide-in-out [isOpen]="displayDocsSw">
    <div class="docs-tbl">
      <cat-entry-docs
        *ngIf="match.catPhrase"
        [docs]="match.catPhrase.docs"
      ></cat-entry-docs>
      <cat-entry-docs
        *ngIf="match.catImage"
        [docs]="match.catImage.docs"
      ></cat-entry-docs>
    </div>
  </slide-in-out>
</div>

<div *ngIf="displayReportMatch" class="l-align-end">
  <p *ngIf="match.reportDisplaySw" class="t-small report-display-text">
    <i class="fas fa-flag"></i> &nbsp;
    {{ "translate.rev.matchReportSwTrue" | translate }}
  </p>
  <a
    *ngIf="!match.reportDisplaySw"
    class="t-small"
    (click)="onReportDisplaySwChanged()"
  >
    {{ "translate.rev.matchReportSwFalse" | translate }}
  </a>
</div>
