import { NgRedux } from '@redux/redux-compatibility.service';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Card, Catalog } from 'src/app/shared/models/cat.model';
import { DataElement } from 'src/app/shared/models/common';
import { AppState } from 'src/app/shared/redux/store';
import { AuthService } from 'src/app/shared/services/auth.service';
import { CatService } from 'src/app/shared/services/cat.service';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'app-card-trans',
  templateUrl: './card-trans.component.html',
  styleUrls: ['./card-trans.component.scss'],
})
export class CardTransComponent implements OnInit {
  loading: boolean;
  selectedLang: DataElement;
  transCard: Card;
  saving: boolean;

  get card(): Card {
    return this.ngRedux.getState().curCard;
  }

  get curCat(): Catalog {
    return this.ngRedux.getState().curCat;
  }

  constructor(
    private ngRedux: NgRedux<AppState>,
    private catService: CatService,
    private authService: AuthService,
    private translateService: TranslateService,
    private sharedService: SharedService
  ) {}

  ngOnInit(): void {
    this.selectedLang = this.card.transLang[0];
    this.getData();
  }

  getData() {
    this.loading = true;
    this.catService
      .getCardTrans(this.card.cardId, this.selectedLang.id)
      .subscribe(
        (data) => {
          this.transCard = data;
          this.loading = false;
        },
        (err) => (this.loading = false)
      );
  }

  save() {
    this.saving = true;
    this.catService
      .updCardTrans(this.transCard, this.selectedLang.id)
      .subscribe(
        (data) => {
          this.saving = false;
          this.getData();
        },
        (err) => (this.saving = false)
      );
  }
}
