import { Injectable, Injector } from '@angular/core';
import { createStore, DeepPartial } from 'redux';
import { AppState, initAppState, rootReducer } from './store';
import { NgRedux } from './redux-compatibility.service';

@Injectable({ providedIn: 'root' })
export class NgReduxInitService {

  ngRedux: NgRedux<AppState>;

  constructor(private injector: Injector) {}

  initialize(): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.ngRedux = this.injector.get(NgRedux);
      const store = createStore(rootReducer, initAppState as DeepPartial<any>);
      this.ngRedux.provideStore(store);
      resolve(true);
    });
  }
}
