<div *ngIf="cat">
  <section class="page-header">
    <loader *ngIf="loading"></loader>
    <div class="title-container">
      <h1>
        <a class="icon" (click)="goBack()"
          ><fa-icon [icon]="['fas', 'arrow-left']"></fa-icon
        ></a>
        &nbsp;
        {{ cat.catDescr }}

        <div [ngClass]="['status-tag', 'cat-status-tag', cat.statusCode]">
          {{ cat.statusDescr }}
        </div>
      </h1>

      <div class="l-align-center">
        <spinner *ngIf="exporting"></spinner>
        <button
          class="t-button-outline"
          (click)="exportPhraseReport()"
          [disabled]="exporting"
        >
          {{ "translate.cat.exportPhraseReport" | translate }}
        </button>
        &nbsp;

        <button *ngIf="isImportClaimsExcelEnabled"
          class="t-button-outline"
          (click)="displayImportClaimsDialog=true"
        >
          {{ "translate.cat.importClaimsExcel" | translate }}
        </button>
        &nbsp;

        <div *ngIf="cat.statusCode == 'PUBLISHED'">
          <button
            (click)="unpublish()"
            class="t-button-outline"
            [disabled]="loading"
          >
            {{ "translate.cat.unpublish" | translate }}
          </button>
        </div>

        <div *ngIf="cat.statusCode == 'DRAFT'">
          <!-- <button
          (click)="displaySaveToBackUpDialog = true"
          class="t-button-outline"
          [disabled]="loading"
        >
          {{ "translate.cat.saveToBackup" | translate }}
        </button>
        &nbsp;
        <button
          (click)="displayRestoreVersionDialog = true"
          class="t-button-outline"
          [disabled]="loading"
        >
          {{ "translate.cat.reloadFromBackup" | translate }}
        </button>
        &nbsp; -->

          <span class="tooltip-wrapper"
            title="{{!curEnvConfiguredIntegrationType ?
                        ('translate.cat.configureIntegration.noEnvironmentLevelIntegration' | translate) :
                          loadingIntegrationConfiguration ? ('translate.general.loading' | translate) : ('translate.cat.configureIntegration' | translate)}}">
            <button
              class="t-button-outline"
              [class.loading]="loadingIntegrationConfiguration"
              (click)="displayConfigureIntegration = true"
              [disabled]="exporting || !curEnvConfiguredIntegrationType || !curCatIntegrationConfig"
            >
              {{ "translate.cat.configureIntegration" | translate }}
            </button>
          </span>

          <span class="t-buttonSet-child t-space--smallest"></span>

          <span class="tooltip-wrapper"
            title="{{!curEnvConfiguredIntegrationType ?
                        ('translate.cat.configureIntegration.noEnvironmentLevelIntegration' | translate) :
                          loadingIntegrationConfiguration ? ('translate.general.loading' | translate) : ('translate.cat.configureIntegration' | translate)}}">
            <button
              class="t-button-outline"
              [class.loading]="loadingInitClaims"
              (click)="pullExternal()"
              [disabled]="loadingInitClaims || exporting || !curEnvConfiguredIntegrationType || !curCatIntegrationConfig"
            >
              {{ "translate.cat.pullExternal" | translate }}
            </button>
          </span>  

          <span class="t-buttonSet-child t-space--smallest"></span>

          <button (click)="publish()" class="t-button" [disabled]="loading">
            {{ "translate.cat.publish" | translate }}
          </button>
        </div>
      </div>
    </div>
  </section>

  <section>
    <data-box
      [title]="'translate.cat.catDetails'"
      class="w-full"
      [data]="cat"
      [displayFilter]="[
        'catId',
        'versionId',
        'catVersionId',
        'versionName',
        'createDate',
        'productDescr',
        'typeDescr',
        'langDescr'
      ]"
      [columns]="3"
    ></data-box>
  </section>

  <div class="t-row"></div>

  <section class="tabs">
    <tabs #catTabs>
      <tab-single
        *ngFor="let tab of tabs"
        [tabTitle]="tab.tabTitle"
        [tabId]="tab.tabId"
        [link]="tab.link"
        [icon]="tab.icon"
      >
      </tab-single>
    </tabs>
  </section>
</div>

<!-- save to back up version -->
<modal-popup
  *ngIf="displaySaveToBackUpDialog"
  [flexible]="true"
  [hideHeader]="false"
  [title]="'translate.cat.saveToBackup'"
  [isOpen]="displaySaveToBackUpDialog"
  (onClose)="displaySaveToBackUpDialog = false"
>
  <div class="dialog-container">
    <input-wrap
      label="{{ 'translate.cat.saveToBackupVersionName' | translate }}"
      [required]="true"
      cssClass="l-cell-spread"
    >
      <input type="text" [(ngModel)]="saveToVersionDescr" class="t-input" />
    </input-wrap>

    <div class="actions">
      <button
        class="t-button-outline"
        (click)="displaySaveToBackUpDialog = false"
      >
        {{ "translate.general.cancel" | translate }}
      </button>
      &nbsp;
      <button
        class="t-button"
        [disabled]="!enableSubmitSaveToBackup"
        (click)="saveToBackup()"
      >
        {{ "translate.general.ok" | translate }}
      </button>
    </div>
  </div>
</modal-popup>

<!-- restore back up version -->
<modal-popup
  *ngIf="displayRestoreVersionDialog"
  [flexible]="true"
  [hideHeader]="false"
  [title]="'translate.cat.reloadFromBackup'"
  [isOpen]="displayRestoreVersionDialog"
  (onClose)="displayRestoreVersionDialog = false"
>
  <div class="dialog-container">
    <input-wrap
      label="{{ 'translate.cat.restoreFromVersionName' | translate }}"
      [required]="true"
      cssClass="l-cell-spread"
    >
      <p-dropdown
        [options]="backupVersions"
        optionLabel="versionName"
        [(ngModel)]="restoreFromVersion"
        class="l-cell-spread-x"
        [placeholder]="'translate.general.select' | translate"
        appendTo="body"
        baseZIndex="100"
      >
        <ng-template let-el pTemplate="item">
          <div class="l-align-spaceBetween">
            <p>{{ el.label }}</p>
            <span class="t-footnote">
              {{ "translate.general.versionId" | translate }}
              {{ el.value.versionId }}</span
            >
          </div>
        </ng-template>
      </p-dropdown>
    </input-wrap>

    <div class="actions">
      <button
        class="t-button-outline"
        (click)="displayRestoreVersionDialog = false"
      >
        {{ "translate.general.cancel" | translate }}
      </button>
      &nbsp;
      <button
        class="t-button"
        [disabled]="!enableSubmitRestoreVersion"
        (click)="restoreVersion()"
      >
        {{ "translate.general.ok" | translate }}
      </button>
    </div>
  </div>
</modal-popup>

<scai-configure-integration
  *ngIf="displayConfigureIntegration"
  (closeDialog)="displayConfigureIntegration = false"
  [configScope]="integrationConfigurationScope"
  [integrationTypes]="curEnvIntegrationTypes"
  [isIntegrationTypesDropdownDisabled]="true"
  [integrationConfig]="curCatIntegrationConfig"
  (configuredIntegration)="onConfiguredIntegration($event)"
></scai-configure-integration>

<import-claims
  *ngIf="displayImportClaimsDialog"
  (onClose)="displayImportClaimsDialog = false"
  (onImportCompleted)="onClaimsExcelImported($event)"
></import-claims>