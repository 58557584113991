import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DocViewerComponent } from 'src/app/shared/components/doc-viewer/doc-viewer.component';
import { BoundingBox } from 'src/app/shared/models/cat.model';
import { Doc, DocPage } from 'src/app/shared/models/common';
import { CardMatch } from 'src/app/shared/models/rev.model';

@Component({
  selector: 'card-match',
  templateUrl: './card-match.component.html',
  styleUrls: ['./card-match.component.scss'],
})
export class CardMatchComponent implements OnInit {
  @Input() cardMatch: CardMatch;

  constructor() {}

  ngOnInit(): void {
    console.log(this.cardMatch);
  }
}
