<modal-popup
  [flexible]="true"
  [focusOnOpen]="true"
  [alignLeft]="true"
  [isOpen]="true"
  (onClose)="close()"
  [title]="modalTitle"
>
  <div class="container">
    <div *ngIf="isNew">
      <p-selectButton
        *ngIf="!lcSw"
        class="spread-width default-buttonset"
        [options]="bbElementTypes"
        [(ngModel)]="selectedBBType"
      ></p-selectButton>

      <!--figure-->
      <div *ngIf="selectedBBType.id == 'IND_IMG'" class="form-container">
        <p class="fs-small t-row--small fw-semibold">
          {{ "translate.general.phraseType" | translate }}
        </p>
        <p-tree
          class="fs-small"
          [value]="catPhraseTypesTree"
          [(selection)]="selectedPhraseType"
          scrollHeight="250px"
          selectionMode="single"
          filter="true"
          (onNodeSelect)="onTypeSelected($event)"
        ></p-tree>
        <div class="t-row--med"></div>

        <input-wrap
          [loading]="figureTypes && figureTypes.loading"
          label="{{ 'translate.general.figureTypeDescr' | translate }}"
          [required]="true"
          cssClass="l-cell-spread"
          [customValidation]="selectedFigureType"
        >
          <p-dropdown
            [options]="figureTypes"
            optionLabel="label"
            [(ngModel)]="selectedFigureType"
            class="l-cell-spread-x"
            [placeholder]="'translate.general.select' | translate"
            (onChange)="selectedFigureSubType = null"
            appendTo="body"
            baseZIndex="100"
          >
          </p-dropdown>
        </input-wrap>

        <input-wrap
          *ngIf="selectedFigureType"
          label="{{ 'translate.general.figureSubTypeDescr' | translate }}"
          [required]="true"
          cssClass="l-cell-spread"
          [customValidation]="selectedFigureSubType"
        >
          <p-dropdown
            [options]="selectedFigureType.children"
            optionLabel="label"
            [(ngModel)]="selectedFigureSubType"
            class="l-cell-spread-x"
            [placeholder]="'translate.general.select' | translate"
            appendTo="body"
            baseZIndex="100"
          >
          </p-dropdown>
        </input-wrap>
      </div>

      <!--box-->

      <div *ngIf="selectedBBType.id == 'CARD_BOX'" class="form-container">
        <!--card-->
        <div *ngIf="!addCardSw">
          <input-wrap
            [loading]="loading"
            label="{{ 'translate.general.card' | translate }}"
            [required]="true"
            cssClass="l-cell-spread"
            [customValidation]="box.card"
          >
            <p-dropdown
              [options]="displayedCards"
              optionLabel="title"
              [(ngModel)]="box.card"
              class="l-cell-spread-x"
              [placeholder]="'translate.general.select' | translate"
              appendTo="body"
              baseZIndex="100"
              (onChange)="filterMcBoxTypes()"
            >
            </p-dropdown>

            <a
              *ngIf="!lcSw"
              class="icon-btn"
              (click)="addCardSw = true; filterMcBoxTypes()"
            >
              &nbsp; &nbsp;<i class="fas c1 fa-plus"></i
            ></a>
          </input-wrap>
        </div>

        <div *ngIf="addCardSw" class="new-card-container">
          <!--type-->
          <input-wrap
            [loading]="cardTypes && cardTypes.loading"
            label="{{ 'translate.general.cardType' | translate }}"
            [required]="true"
            cssClass="l-cell-spread"
            [customValidation]="newCard.type"
          >
            <p-dropdown
              [options]="cardTypes"
              optionLabel="label"
              [(ngModel)]="newCard.type"
              class="l-cell-spread-x"
              [placeholder]="'translate.general.select' | translate"
              (onChange)="onCardTypeChanged($event)"
              appendTo="body"
              baseZIndex="100"
            >
            </p-dropdown>
          </input-wrap>

          <!--card title-->
          <input-wrap
            label="{{ 'translate.general.cardTitle' | translate }}"
            cssClass="l-cell-spread"
            [required]="true"
          >
            <input
              type="text"
              [(ngModel)]="newCard.title"
              class="t-input l-cell-spread-x"
            />
            &nbsp; &nbsp;
            <a class="icon-btn" (click)="addCardSw = false; filterMcBoxTypes()"
              ><i class="fas c1 fa-minus"></i
            ></a>
          </input-wrap>

          <!--mc type-->
          <input-wrap
            *ngIf="mcSw"
            [loading]="mcTypes && mcTypes.loading"
            label="{{ 'translate.general.mcType' | translate }}"
            [required]="true"
            cssClass="l-cell-spread"
            [customValidation]="newCard.mcType"
          >
            <p-dropdown
              [options]="mcTypes"
              optionLabel="label"
              [(ngModel)]="newCard.mcType"
              class="l-cell-spread-x"
              [placeholder]="'translate.general.select' | translate"
              (onChange)="onMcTypeChanged($event)"
              appendTo="body"
              baseZIndex="100"
            >
            </p-dropdown>
          </input-wrap>

          <!--mc sub type-->
          <input-wrap
            *ngIf="mcSw"
            [loading]="mcTypes && mcTypes.loading"
            label="{{ 'translate.general.mcSubType' | translate }}"
            cssClass="l-cell-spread"
            [required]="displayMcSubTypes.length > 0"
            [customValidation]="newCard.mcSubType"
          >
            <p-dropdown
              [options]="displayMcSubTypes"
              optionLabel="label"
              [(ngModel)]="newCard.mcSubType"
              class="l-cell-spread-x"
              [placeholder]="'translate.general.select' | translate"
              (onChange)="filterMcBoxTypes()"
              appendTo="body"
              baseZIndex="100"
            >
            </p-dropdown>
          </input-wrap>
        </div>

        <!--mc box type-->
        <input-wrap
          *ngIf="displayMcBoxTypes && displayMcBoxTypes.length > 0"
          label="{{ 'translate.general.mcBoxType' | translate }}"
          cssClass="l-cell-spread"
          [required]="true"
          [customValidation]="box.mcBoxType"
        >
          <p-dropdown
            [options]="displayMcBoxTypes"
            optionLabel="label"
            [(ngModel)]="box.mcBoxType"
            class="l-cell-spread-x"
            [placeholder]="'translate.general.select' | translate"
            appendTo="body"
            baseZIndex="100"
          >
          </p-dropdown>
        </input-wrap>

        <!--descr-->
        <input-wrap
          *ngIf="!lcMode"
          label="{{ 'translate.cat.blockInstr' | translate }}"
          cssClass="l-cell-spread"
        >
          <input type="text" [(ngModel)]="box.descr" class="t-input" />
        </input-wrap>
      </div>
    </div>

    <div *ngIf="!isNew">
      <data-box
        [data]="box"
        [columns]="1"
        [displayFilter]="['boxId', 'boxType', 'card', 'descr']"
        [templateRefs]="{
          boxType: boxType,
          card: card
        }"
      ></data-box>

      <ng-template #boxType>
        {{ box.boxType.text }}
      </ng-template>

      <ng-template #card>
        {{ box.card.title }}
      </ng-template>
    </div>

    <div class="t-panel-cell t-buttonSet l-align-end l-mar-top">
      <div *ngIf="isNew">
        <button
          [disabled]="saving"
          (click)="discard()"
          class="t-button-outline t-button-warn"
        >
          {{ "translate.general.discard" | translate }}
        </button>
        &nbsp;
        <button
          [disabled]="!allowSubmit() || saving"
          (click)="save()"
          class="t-button-outline"
        >
          {{ "translate.general.save" | translate }}
        </button>
      </div>

      <div *ngIf="!isNew">
        <button
          [disabled]="saving"
          (click)="deleteClicked()"
          class="t-button-outline t-button-warn"
        >
          {{ "translate.general.delete" | translate }}
        </button>
      </div>
    </div>

    <loader *ngIf="saving"></loader>
  </div>
</modal-popup>
