<div class="header">
  <button *ngIf="allowEdit" class="t-button-outline" (click)="addType()">
    {{ "translate.cat.mngPhraseTypeAddType" | translate }}
  </button>
  &nbsp;
  <button
    *ngIf="allowEdit"
    class="t-button-outline"
    (click)="importFromCatalog()"
  >
    {{ "translate.cat.mngPhraseTypeImportFromCat" | translate }}
  </button>
</div>

<loader *ngIf="loading"></loader>

<cat-phrase-type-list
  [catPhraseTypes]="catPhraseTypes"
  [allowEdit]="allowEdit"
  (onEdit)="edit($event)"
  (onAddSubType)="addSubType($event)"
  (onDelete)="onDeleteClicked($event)"
>
</cat-phrase-type-list>

<edit-phrase-sub-type
  *ngIf="editSubTypeOpenSw"
  [subType]="curSubType"
  (onClose)="editSubTypeOpenSw = false"
  (onSave)="editSubTypeOpenSw = false; reloadCatTypes()"
>
</edit-phrase-sub-type>

<edit-phrase-type
  *ngIf="editTypeOpenSw"
  [type]="curType"
  (onClose)="editTypeOpenSw = false"
  (onSave)="editTypeOpenSw = false; reloadCatTypes()"
>
</edit-phrase-type>

<import-type-from-cat
  *ngIf="importOpenSw"
  (onClose)="importOpenSw = false"
  (onSave)="importOpenSw = false; reloadCatTypes()"
>
</import-type-from-cat>
