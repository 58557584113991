import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PhraseWord, ReferenceIssue, ReferencePhrase } from 'src/app/shared/models/common';
import { IndPhrase } from 'src/app/shared/models/cat.model';

@Component({
  selector: 'cat-match-phrase-ref',
  templateUrl: './cat-match-phrase-ref.component.html',
  styleUrls: ['./cat-match-phrase-ref.component.scss'],
})
export class CatMatchPhraseRefComponent implements OnInit {
  @Input() ref?: ReferencePhrase;
  @Input() mainPhrase: IndPhrase;
  @Input() referenceIssue?: ReferenceIssue;
  showOriginal: boolean = false;

  ngOnInit(): void {}

}
