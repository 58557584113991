<section class="page-header">
  <div class="title-container">
    <h1>{{ "translate.mng.mngAssetTypes" | translate }}</h1>
  </div>
</section>

<div class="header-actions">
  <button class="t-button-outline" (click)="create()">
    {{ "translate.general.create" | translate }}
  </button>
</div>

<loader *ngIf="!assetTypeList || assetTypeList.loading"></loader>

<ui-table
  [isWrapped]="false"
  [data]="assetTypeList"
  [numberOfRows]="10"
  [displayFilter]="['id', 'text']"
  [templates]="[
    {
      title: 'translate.general.actions',
      template: actions
    }
  ]"
></ui-table>

<ng-template #actions let-row="row">
  <a (click)="edit(row)">{{ "translate.general.edit" | translate }}</a>
</ng-template>

<edit-asset-type
  *ngIf="editOpenSw"
  [row]="curRow"
  (onClose)="editOpenSw = false"
  (onSave)="editOpenSw = false; refreshAssetTypeList()"
>
</edit-asset-type>
