import { CatDoc } from './cat.model';
import { PaginationParams } from './common';
import { RevDoc } from './rev.model';
import { Env } from './user.model';

export class TaggedIndDoc extends CatDoc {
  env?: Env[] = [];
  refTagSw?: boolean;
  matchTagSw?: boolean;
  replicatedDoc?: CatDoc;
  processing?: boolean;
}

export class TaggedRevDoc extends RevDoc {
  env: Env[] = [];
  refTagSw: boolean;
  matchTagSw: boolean;
  replicatedDoc: RevDoc;
}

export class TaggedDocsSearchParams extends PaginationParams {
  docType: 'IND' | 'REV';
  envId?: number;
  catId?: number;
  docIdName?: string;

  constructor() {
    super();
  }
}
