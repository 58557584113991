<modal-popup
  [flexible]="true"
  [focusOnOpen]="false"
  [isOpen]="true"
  (onClose)="close()"
  [title]="'translate.cat.selectCardTemplate'"
>
  <div class="container">
    <loader *ngIf="loading"></loader>
    <input-wrap
      *ngIf="!loading"
      label="{{ 'translate.cat.cardAssetTemplate' | translate }}"
      cssClass="l-cell-spread"
    >
      <p-dropdown
        [options]="data"
        optionLabel="name"
        [(ngModel)]="selectedCardTemplate"
        class="l-cell-spread-x"
        [placeholder]="'translate.general.select' | translate"
        appendTo="body"
        baseZIndex="100"
      >
      </p-dropdown>
    </input-wrap>

    <div class="actions">
      <button class="t-button-outline" (click)="close()">
        {{ "translate.general.cancel" | translate }}
      </button>
      &nbsp;
      <button
        class="t-button"
        [disabled]="!enableSubmit || saving"
        (click)="save()"
      >
        {{ "translate.general.ok" | translate }}
      </button>
    </div>
  </div>

  <loader *ngIf="saving"></loader>
</modal-popup>
