import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { NgRedux } from '@redux/redux-compatibility.service';
import { AppState } from 'src/app/shared/redux/store';
import { RevService } from 'src/app/shared/services/rev.service';
import { RevRule, RevDocStatusEnum } from 'src/app/shared/models/rev.model';
import { CatMatch, DataElement, ProcessRunStatusEnum, ReferenceIssue } from 'src/app/shared/models/common';
import { SharedService } from 'src/app/shared/services/shared.service';
import { TranslateService } from '@ngx-translate/core';
import Utils from 'src/app/shared/components/utils/utils';
import { merge, Observable } from 'rxjs';
import { GrammarSpellingCheckService } from '@shared/services/grammar-spelling-check.service';
import { CommonService } from '@shared/services/common.service';
import { AuthService } from '@shared/services/auth.service';
import { Card } from '@shared/models/cat.model';

@Component({
  selector: 'phrase-rev-rule-details',
  templateUrl: './phrase-rev-rule-details.component.html',
  styleUrls: ['./phrase-rev-rule-details.component.scss'],
})
export class PhraseRevRuleDetailsComponent implements OnInit {
  @Input() focusOnOpen: boolean = false;
  @Input() card: Card | undefined;
  @Output() onClose = new EventEmitter();
  @Output() onStatusChange = new EventEmitter();
  @Output() onUpdateSpellingIssues = new EventEmitter();

  loading: boolean;
  ruleStatusOptions: DataElement[] = [];
  dirtySw: boolean;
  formatMatchDifferences: any;
  formatMatchPhrase: any;
  formatMatchEntry: any;
  highlightedIds: number[] = [];
  wordsToWhitelist: string[] = [];
  processStatusCheckIntervalMillis = 1000;

  get curRule(): RevRule {
    return this.ngRedux.getState().curRevRule;
  }

  get editable(): boolean {
    return (
      this.ngRedux.getState().curRevDoc.statusCode == RevDocStatusEnum.UNDERWAY
    );
  }

  constructor(
    private ngRedux: NgRedux<AppState>,
    private revService: RevService,
    private sharedService: SharedService,
    private translateService: TranslateService,
    private grammarSpellingService: GrammarSpellingCheckService,
    private authService: AuthService,
    private commonService: CommonService
  ) {}

  ngOnInit(): void {
    // populate concatSw from text to format words before further processing
    this.formatMatchEntry = this.curRule.formatMatchEntry;
    if (this.curRule.ruleDispWords.length != this.formatMatchEntry.length) {
      if (this.card && this.card.cardId) {
        this.formatMatchEntry = this.formatMatchEntry.filter(entry => entry.mCardId === this.card.cardId);
        const allFormatDescrEmpty = this.formatMatchEntry.every(entry => !entry.formatDescr || entry.formatDescr.trim() === '');
        if (allFormatDescrEmpty) {
          this.formatMatchEntry = [];
        }
      }
      else {
        this.formatMatchEntry = this._getUniqueEntries(this.curRule.formatMatchEntry);
      }
    }

    this.formatMatchPhrase = this.curRule.formatMatchPhrase;
    if (this.curRule.ruleDispWords.length != this.formatMatchPhrase.length) {
      if (this.card && this.card.cardId) {
        this.formatMatchPhrase = this.formatMatchPhrase.filter(entry => entry.mCardId === this.card.cardId);
      }
      else {
        this.formatMatchPhrase = this._getUniqueEntries(this.curRule.formatMatchPhrase);
      }
    }
    this._populateConcatSw(this.formatMatchEntry);
    this._populateConcatSw(this.formatMatchPhrase);

    this.formatMatchDifferences = this.compareTextObjects(this.formatMatchEntry, this.formatMatchPhrase);
    this.curRule.saveOriginal();

    let actions = [];
    if (this.curRule) {
      actions = this.curRule.revRuleActions.map(
        a => (a == 'APPLY_CHANGE') ? 'MARKED_FOR_CHANGES' : a
      );
    }
    // show all actions on old docs
    let isNewRuleDoc = !!this.curRule.revRuleSimilarityEvent;
    if (isNewRuleDoc) {
      this.ruleStatusOptions =
      this.sharedService.getRevRuleUpdStatusOptions().filter(
        s => {
          return actions.indexOf(s.id) >= 0;
        }
      );
    } else {
      this.ruleStatusOptions = this.sharedService.getRevRuleUpdStatusOptions();
    }
  }

  _populateConcatSw(entries) {
    entries.map((entry, ind) => entry.concatSw = this.curRule.ruleDispWords[ind].concatSw);
  }

  _getUniqueEntries(entries: any[]): any[] {
    const uniqueMap = new Map();
    for (const entry of entries) {
      if (!uniqueMap.has(entry.id)) {
        uniqueMap.set(entry.id, entry);
      }
    }
    return Array.from(uniqueMap.values());
  }

  close() {
    if (this.dirtySw || this.hasWhitelistedWords()) {
      let validationStr = this.translateService.instant(
        'translate.cat.discardEditChanges'
      );

      this.sharedService.emitPopupchReceivedEvent({
        title: 'translate.validation.confirm',
        icon: 'exclamation-triangle',
        subTitle: validationStr,
        keep: false,
        approve: () => {
          this.discardChanges();
          this.onClose.emit();
        },
      });
    } else {
      this.onClose.emit();
    }
  }

  onRevStatusChange() {
    if (this.ruleCatMatches.length > 0 && this.curRule.ruleUserStatus && this.curRule.ruleUserStatus.value.id === 'CLAIM_VARIANT') {
      this.curRule.variantOrigPhraseId = this.ruleCatMatches[this.currentIndex].catPhrase.phraseId;
    } else {
      this.curRule.variantOrigPhraseId = null;
    }
    this.onStatusChange.emit();
  }

  onSaveClicked() {
    this.loading = true;
    //if (this.phrase) this.phrase.phraseText = this.editPhraseText;

    if (this.hasWhitelistedWords()) {
      this.processWhitelistedWords().subscribe(
        _ => {
          const docId = this.ngRedux.getState().curRevDoc.docId;
          this.commonService.addAlgRunHead({doc_id: docId, run_type: 'REV_SPELLING_LIGHT'}).subscribe(
            runNum => {
              this.grammarSpellingService.updateSpellingLight(runNum, this.wordsToWhitelist).subscribe(
                _ => {
                  let timer = setInterval(() => {
                    this.authService.getProcessStatus(runNum).subscribe(
                      (data) => {
                        if (data.status == ProcessRunStatusEnum.FINISHED || data.status == ProcessRunStatusEnum.FAILED) {
                          clearInterval(timer);

                          // TODO refresh data properly
                          this.onUpdateSpellingIssues.emit();
                          
                          if (this.dirtySw) {
                            this.saveRuleChanges();
                          } else {
                            this.loading = false;
                            this.onClose.emit();
                          }
                        }
                      },
                      (err) => {
                        clearInterval(timer);
                      }
                    );
                  }, this.processStatusCheckIntervalMillis);
                }
              )
            },
            err => {
              this.loading = false;
            }
          );
        },
        err => {
          this.loading = false;
        }
      );
    } else {
      if (this.dirtySw) {
        this.saveRuleChanges();
      } else {
        this.loading = false;
        this.onClose.emit();
      }
    }
  }

  saveRuleChanges() {
    this.loading = true;

    this.revService
      .updRevRule(
        this.ngRedux.getState().curRevDoc,
        [this.curRule],
        this.curRule.revStatusCode
      )
      .subscribe(
        (data) => {
          this.loading = false;
          this.dirtySw = false;
        },
        (err) => {
          this.loading = false;
        }
      );
  }

  hasWhitelistedWords() {
    return this.wordsToWhitelist.length > 0;
  }

  processWhitelistedWords(): Observable<DataElement> {
    const observables: Observable<DataElement>[] = [];

    for (let word of this.wordsToWhitelist) {
      const catId = this.ngRedux.getState().curRevDoc.catId;
      const observable = this.grammarSpellingService.addCatSpellingCheckIgnoredWord(catId, word);
      observables.push(observable);
    }

    return merge(...observables);
  }

  discardChanges() {
    this.curRule.restoreOriginal();
    this.dirtySw = false;
    this.wordsToWhitelist = [];
    this.curRule.grammarSpellingIssues?.forEach(i => i.isWhitelisted = false);
  }

  onReportDisplaySwChange(match: CatMatch) {
    this.curRule.ruleCatMatches
      .filter((m) => m.catMatchSeq != match.catMatchSeq)
      .forEach((r) => {
        r.reportDisplaySw = false;
      });
    //match.reportDisplaySw = true;
    this.saveRuleChanges();
  }

  compareTextObjects(original, modified): any {
    // original = this.phrase;
    // modified = this.match;
    const differences = [];
    let previousDiff = null;

    original.forEach((originalItem, index) => {
      const diffJson = {
        text: null,
        ids: []
      };
      const modifiedItem = modified[index];
      if (originalItem.formatDescr !== modifiedItem.formatDescr) {
        diffJson.text = originalItem.text;
        diffJson.ids.push(originalItem.id);

        const originalFormat = originalItem.formatDescr.split(', ');
        const modifiedFormat = modifiedItem.formatDescr.split(', ');

        originalFormat.forEach((originalAttr, attrIndex) => {
          const [originalAttrKey, originalAttrVal] = originalAttr.split(':');
          const [modifiedAttrKey, modifiedAttrVal] = modifiedFormat[attrIndex].split(':');

          if (originalAttrVal !== modifiedAttrVal) {
            diffJson[originalAttrKey] = [originalAttrVal, modifiedAttrVal];
          }
        });
        if (this.isSameDifference(diffJson, previousDiff)) {
          differences[differences.length - 1].text += ' ' + diffJson.text;
          differences[differences.length - 1].ids.push(diffJson.ids[0]);
        } else {
          differences.push(diffJson);
        }
        previousDiff = diffJson;
      }
    });

    return differences;
  }

  getFriendlyDifferenceName(text: string): string {
    return text.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase());
  }

  getFriendlyFontName(text: string): string {
    return text.replace(/['{}]/g, '');
  }

  isHighlighted(ids: number[]): boolean {
    return this.highlightedIds.some(id => ids.includes(id));
  }

  highlightDiff(ids: number[]): void {
    this.highlightedIds = ids;
  }

  clearHighlight(): void {
    this.highlightedIds = [];
  }

  isSameDifference(diff1, diff2): boolean {
    const stripText = ({ text, ids, ...obj }: any) => obj;

    const strippedDiff1 = stripText(diff1);
    if (diff2) {
      const strippedDiff2 = stripText(diff2);
      return Utils.areEqual(strippedDiff1, strippedDiff2);
    } else {
      return false;
    }
  }

  getMatchStatusDescr(): string {
    if(this.card) {
      const revCardMatches = this.ngRedux.getState().revCardMatches;
      const match = revCardMatches.find((match: any) => match.phraseId === this.curRule.phraseId && match.mCardId === this.card.cardId);
      return match ? match.matchStatusDescr : this.curRule.revStatusDescr;
    }
    return this.curRule.revStatusDescr;
  }

  getMatchStatusCode(): string {
    let code = this.curRule.revStatusCode;
    if(this.card) {
      const revCardMatches = this.ngRedux.getState().revCardMatches;
      const match = revCardMatches.find((match: any) => match.phraseId === this.curRule.phraseId && match.mCardId === this.card.cardId);
      code = match ? match.matchStatusCode : this.curRule.revStatusCode;
    }
    return code == 'FULL_MATCH' ? 'PASSED' : 'NOT_PASSED_NEW_CONTENT';
  }

  currentIndex: number = 0;

  getCurrentMatch(): CatMatch {
    return this.ruleCatMatches[this.currentIndex];
  }

  prevMatch() {
    if (this.currentIndex > 0) {
      this.currentIndex--;
    }
  }

  nextMatch() {
    if (this.currentIndex < this.ruleCatMatches.length - 1) {
      this.currentIndex++;
    }
  }

  get ruleCatMatches(): any[] {
    return this.curRule.ruleCatMatches || [];
  }

  getCurrentSimilarity(): number {
    return this.ruleCatMatches[this.currentIndex]?.similarityPct || 0;
  }

  getPrevSimilarity(): number {
    return this.ruleCatMatches[this.currentIndex - 1]?.similarityPct || 0;
  }

  getNextSimilarity(): number {
    return this.ruleCatMatches[this.currentIndex + 1]?.similarityPct || 0;
  }

  getReferenceIssue(phraseId: number, refPhraseId: number): any {
    return this.curRule.referenceIssues?.find(
      (issue => issue.catPhraseId === phraseId && issue.refPhraseId === refPhraseId) ||
      (issue => issue.catPhraseId === phraseId && issue.refFinding === 'ADDED_REFERENCE')
    );
  }

  getAddedReferenceIssue(phraseId: number): any[] {
    return this.curRule.referenceIssues?.filter(
      issue => issue.catPhraseId === phraseId && issue.refFinding === 'ADDED_REFERENCE'
    ) || [];
  }

  getMissingReferenceIssue(phraseId: number): any[] {
    return this.curRule.referenceIssues?.filter(
      issue => issue.catPhraseId === phraseId && issue.refFinding === 'MISSING_REFERENCE'
    ) || [];
  }

  getCatPhraseReference(catRefPhraseId: number): any {
    return this.curRule.ruleCatMatches[this.currentIndex]?.catPhrase?.references?.find(
      ref => ref.refPhraseId === catRefPhraseId
    );
  }

  addToWhitelist(word: string) {
    this.wordsToWhitelist.push(word);
  }

}