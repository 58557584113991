import { AnyAction, Store } from 'redux';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { Injectable, NgZone } from '@angular/core';

export type Comparator = (x: any, y: any) => boolean;

@Injectable({ providedIn: 'root' })
export class NgRedux<AppState> {
  private reduxStore: Store<AppState>;
  private state: BehaviorSubject<AppState>;

  constructor(private ngZone: NgZone) {
    this.state = new BehaviorSubject<AppState>({} as AppState); // Initialize with an empty object
  }

  provideStore(store: Store<AppState>): void {
    this.reduxStore = store;
    this.reduxStore.subscribe(() => this.state.next(this.reduxStore.getState()));
  }

  getState(): AppState {
    return this.state.value;
  }

  // Trigger Change Detection if action is dispatched out of zone
  dispatch(action: AnyAction): AnyAction {
    if (NgZone.isInAngularZone()) {
      this.reduxStore.dispatch(action);
    } else {
      this.ngZone.run(() => this.reduxStore.dispatch(action));
    }
    return action;
  }

  subscribe(listener: () => void): () => void {
    return this.reduxStore.subscribe(listener);
  }
}
