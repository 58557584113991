import { NgRedux } from '@redux/redux-compatibility.service';
import { Component, OnInit } from '@angular/core';
import {
  Catalog,
  CatalogSearchParams,
  CatalogStatusEnum,
} from 'src/app/shared/models/cat.model';
import { AppState } from 'src/app/shared/redux/store';
import { CatService } from 'src/app/shared/services/cat.service';

@Component({
  selector: 'app-cat-ext-libs',
  templateUrl: './cat-ext-libs.component.html',
  styleUrls: ['./cat-ext-libs.component.scss'],
})
export class CatExtLibsComponent implements OnInit {
  loading: boolean;
  addModeSw: boolean;
  addCatalogs: Catalog[] = [];

  get curCat(): Catalog {
    return this.ngRedux.getState().curCat;
  }

  constructor(
    private catService: CatService,
    private ngRedux: NgRedux<AppState>
  ) {}

  ngOnInit(): void {
    console.log(this.curCat);
  }

  onAdd() {
    this.addModeSw = true;
    this.getData();
  }

  getData() {
    this.loading = true;
    let params = new CatalogSearchParams();
    params.extSw = this.addModeSw;
    params.envId = this.ngRedux.getState().curEnv.id;

    this.catService.getCatList(params).subscribe(
      (data) => {
        this.addCatalogs = data;
        this.loading = false;
      },
      (err) => (this.loading = false)
    );
  }

  remove(cat: Catalog) {
    this.catService
      .updCatExtLib(this.curCat.catVersionId, cat.catVersionId, true)
      .subscribe(
        (data) => {
          let idx = this.curCat.extCat.findIndex(
            (c) => c.catVersionId == cat.catVersionId
          );
          if (idx > -1) this.curCat.extCat.splice(idx, 1);
        },
        (err) => (this.loading = false)
      );
  }

  addCat(cat: Catalog) {
    let published = this.getPublishedVersion(cat);
    this.catService
      .updCatExtLib(this.curCat.catVersionId, published.catVersionId)
      .subscribe(
        (data) => {
          this.curCat.extCat.push(published);
          this.addModeSw = false;
        },
        (err) => (this.loading = false)
      );
  }

  getPublishedVersion(cat: Catalog) {
    let version = cat.catVersions.find(
      (v) => v.statusCode == CatalogStatusEnum.PUBLISHED
    );
    return version;
  }
}
