import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { DataElement, Product } from 'src/app/shared/models/common';
import { NgRedux } from '@redux/redux-compatibility.service';
import { AppState } from 'src/app/shared/redux/store';
import { CatService } from 'src/app/shared/services/cat.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { Catalog } from 'src/app/shared/models/cat.model';
import { IoTThingsGraph } from 'aws-sdk';

@Component({
  selector: 'create-cat',
  templateUrl: './create-cat.component.html',
  styleUrls: ['./create-cat.component.scss'],
})
export class CreateCatComponent implements OnInit {
  @Input() extSw: boolean = false;
  @Output() onClose = new EventEmitter();
  @Output() onCreate = new EventEmitter();

  catDescr: string;
  product: DataElement;
  type: DataElement;
  refMatchType: DataElement;
  lang: DataElement;
  saving: boolean;

  get productList(): Product[] {
    return this.ngRedux.getState().productList;
  }

  get typeList(): DataElement[] {
    return this.ngRedux.getState().catTypeList;
  }

  get refMatchTypeList(): DataElement[] {
    return this.ngRedux.getState().refMatchTypeList;
  }

  get langList(): DataElement[] {
    return this.ngRedux.getState().catLangList;
  }

  get enableSubmit(): boolean {
    return (
      this.catDescr != null &&
      this.product != null &&
      (this.type != null || this.extSw) &&
      this.refMatchType != null &&
      this.lang != null
    );
  }

  constructor(
    private ngRedux: NgRedux<AppState>,
    private catService: CatService,
    private authService: AuthService,
    private sharedService: SharedService
  ) {}

  ngOnInit(): void {}

  close() {
    this.onClose.emit();
  }

  save() {
    this.saving = true;

    let cat = new Catalog();
    cat.catDescr = this.catDescr;
    cat.productId = this.product.id;
    cat.typeCode = this.extSw ? 'EXT' : this.type?.id;
    cat.refMatchTypeCode = this.refMatchType.id;
    cat.extSw = this.extSw;
    cat.langCode = this.lang.id;

    this.catService.createCat(cat).subscribe(
      (data) => {
        this.saving = false;
        this.onCreate.emit();
      },

      (err) => (this.saving = false)
    );
  }
}
