import { Component, OnInit, Input} from '@angular/core';
import { CatMatch } from '@shared/models/common';

@Component({
  selector: 'cat-match-phrase',
  templateUrl: './cat-match-phrase.component.html',
  styleUrls: ['./cat-match-phrase.component.scss'],
})
export class CatMatchPhraseComponent implements OnInit {
  @Input() catMatch: CatMatch;
  displayDocsSw: boolean= false;
  showOriginal: boolean = false;

  ngOnInit(): void {
    console.log(this.catMatch);
  }

}
