import { NgRedux } from '@redux/redux-compatibility.service';
import { Component, OnInit } from '@angular/core';
import { Catalog } from 'src/app/shared/models/cat.model';
import { DataElement } from 'src/app/shared/models/common';
import { AppState } from 'src/app/shared/redux/store';
import { CatService } from 'src/app/shared/services/cat.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { threadId } from 'worker_threads';

@Component({
  selector: 'app-mng-cat-asset-types',
  templateUrl: './mng-cat-asset-types.component.html',
  styleUrls: ['./mng-cat-asset-types.component.scss'],
})
export class MngCatAssetTypesComponent implements OnInit {
  origAssetTypes: DataElement[];
  loading: boolean;
  addTypeOpenSw: boolean;

  get cat(): Catalog {
    return this.ngRedux.getState().curCat;
  }

  constructor(
    private ngRedux: NgRedux<AppState>,
    private catService: CatService,
    private sharedService: SharedService
  ) {}

  ngOnInit(): void {}

  delete(row: DataElement) {
    this.origAssetTypes = JSON.parse(JSON.stringify(this.cat.catAssetTypeList));
    this.cat.catAssetTypeList.splice(this.cat.catAssetTypeList.indexOf(row), 1);
    this.save();
  }

  add(row: DataElement) {
    this.origAssetTypes = JSON.parse(JSON.stringify(this.cat.catAssetTypeList));
    this.cat.catAssetTypeList.push(row);
    this.addTypeOpenSw = false;
    this.save();
  }

  save() {
    this.loading = true;
    this.catService.updCat(this.cat).subscribe(
      (data) => {
        this.loading = false;
      },
      (err) => {
        this.loading = false;
        this.cat.catAssetTypeList = this.origAssetTypes;
      }
    );
  }
}
