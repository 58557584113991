<div class="container">
  <doc-toolbar
   #docToolbar
    [doc]="doc"
    [initialPage]="initialPage"
    [displayPaging]="displayPaging"
    [displayRefreshingIcon]="displayRefreshingIcon"
    [displayDrawMode]="displayDrawMode"
    [displayToggleBoundingBoxes]="displayToggleBoundingBoxes"
    (onPageChanged)="onPageSelected($event)"
    (onZoomOut)="zoomOut()"
    (onZoomIn)="zoomIn()"
    (onFitToWidth)="fitToWidth()"
    (onFitToPage)="fitToPage()"
    (onToggleAnnotations)="toggleAnnotations($event)"
    (onToggleBoundingBoxes)="toggleBoundingBoxes($event)"
    (onToggleDrawMode)="onToggleDrawMode($event)"
  ></doc-toolbar>
  <iframe #iframe [src]="iFrameUrl"></iframe>
</div>
