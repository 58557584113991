import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  AnnotationElement,
  DataElement,
  ProcessRunStatusEnum,
  SearchRunStatusEnum,
} from '../shared/models/common';
import { NgRedux } from '@redux/redux-compatibility.service';
import { AppState, actionsList } from '../shared/redux/store';
import { RevService } from '../shared/services/rev.service';
import { Router } from '@angular/router';
import { SharedService } from '../shared/services/shared.service';
import {
  RevDoc,
  RevRulesSearchParams,
  RevRule,
  RevDocStatusEnum,
  RevDocsSearchParams,
  RevStatus,
  DirectLinkDoc,
} from '../shared/models/rev.model';
import { Location } from '@angular/common';
import { DocViewerComponent } from '../shared/components/doc-viewer/doc-viewer.component';
import { keyCodes } from '../shared/models/key-codes.model';
import * as Chart from 'chart.js';
import { Unsubscribe } from 'redux';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../shared/services/auth.service';
import { RevDocRulesListComponent } from './rev-doc-rules-list/rev-doc-rules-list.component';
import { TreeNode } from 'primeng/api';
import { CatService } from '../shared/services/cat.service';
import { IntegrationService } from '../shared/services/integration.service';
import { Card, CatPhraseSubType } from '../shared/models/cat.model';
import { PdfViewerComponent } from '../shared/components/pdf-viewer/pdf-viewer.component';
import { Subscription } from 'rxjs';
import { TabIdList } from '../shared/models/tab.model';

@Component({
  selector: 'app-rev-doc',
  templateUrl: './rev-doc.component.html',
  styleUrls: ['./rev-doc.component.scss'],
})
export class RevDocComponent implements OnInit {
  @ViewChild('docViewer') docViewer: PdfViewerComponent;
  @ViewChild('revRulesList') revRulesList: RevDocRulesListComponent;
  @ViewChild('page', { static: false }) public pageDiv: ElementRef;

  loading: boolean;
  filterPhrase: string;
  filterRuleType: DataElement;
  filterStatus: DataElement;
  filterContentType: DataElement;
  filterMatchType: DataElement;
  selectedTypeNodes: TreeNode[];
  filterSubTypes: string[];
  canvasStatus: any;
  canvasBarStatus: any;
  ctxStatus: any;
  ctxBarStatus: any;
  un: Unsubscribe;
  initAnnotationsloadedPageNum: number;
  docFrameLoadedPageNum: number;
  rulesListLoadedPageNum: number;
  toggleAllSw: boolean;
  downloadingFile: boolean;
  processStatusTimer: NodeJS.Timeout;
  getProcessStatusIntervalSeconds: number = 3;
  panelResizing: boolean;
  showMoreDocDetailsSw: boolean;
  showBarStatusChart: boolean;
  docCardsOpenSw: boolean;
  getCardsSub: Subscription;
  searchStatusTimer: any;
  searchStatusIntervalSec: number = 2;
  sub: Subscription;
  cardsLoading: boolean;
  toggleImageAnnotationsSw: boolean;
  downloadingZip: boolean;
  sub2: Subscription;
  tabIdList: TabIdList;
  docFrameLoaded: boolean = false;

  get dlRevDoc(): DirectLinkDoc {
    return this.ngRedux.getState().dlRevDoc;
  }

  get doc(): RevDoc {
    return this.ngRedux.getState().curRevDoc;
  }

  get ruleTypeList(): DataElement[] {
    return this.ngRedux.getState().revRuleTypeList;
  }

  get ruleStatusList(): DataElement[] {
    return this.ngRedux.getState().revRuleStatusList;
  }

  get contentTypeList(): DataElement[] {
    return this.ngRedux.getState().contentTypeList;
  }

  get matchTypeList(): DataElement[] {
    return this.ngRedux.getState().revRuleSimilarityEventList;
  }

  get docCards(): Card[] {
    return this.ngRedux.getState().revDocCards;
  }

  get curPage(): number {
    return (this.dlRevDoc && !this.dlRevDoc.dlDocHandled)
      ? this.dlRevDoc.page
      : this.ngRedux.getState().curRevDocPageNum;
  }

  get displayClearFilter(): boolean {
    return (
      this.filterPhrase != null ||
      this.filterRuleType != null ||
      this.filterStatus != null ||
      this.filterContentType != null ||
      this.filterMatchType != null ||
      this.filterSubTypes != null
    );
  }

  get selectedRules(): RevRule[] {
    return this.ngRedux.getState().revDocRules.filter((r) => r.checkedSw);
  }

  get displayedRules(): RevRule[] {
    return this.ngRedux.getState().revDocRules;
  }

  get editable(): boolean {
    return this.doc.statusCode == RevDocStatusEnum.UNDERWAY;
  }

  get allowFinalize(): boolean {
    return (
      this.ngRedux.getState().curRevDoc.statusCode ==
        RevDocStatusEnum.UNDERWAY ||
      this.ngRedux.getState().curRevDoc.statusCode == RevDocStatusEnum.COMPLETED
    );
  }

  get allowComplete(): boolean {
    return (
      this.ngRedux.getState().curRevDoc.statusCode == RevDocStatusEnum.UNDERWAY
    );
  }

  get allowReopen(): boolean {
    return (
      this.ngRedux.getState().curRevDoc.statusCode != RevDocStatusEnum.UNDERWAY
    );
  }

  get allowInactivate(): boolean {
    return (
      this.ngRedux.getState().curRevDoc.statusCode != RevDocStatusEnum.INACTIVE
    );
  }

  get catPhraseTypes(): TreeNode[] {
    if (!this.ngRedux.getState().catPhraseTypeModel) return [];
    return this.ngRedux.getState().catPhraseTypeModel.treeTypes;
  }

  get pageLoading(): boolean {
    return this.ngRedux.getState().docPageLoading;
  }

  get displayFilterOptions(): string[] {
    let displayFilterOptions = ['passedCnt'];
    if (this.doc.newContentCnt > 0) {
      displayFilterOptions.push('newContentCnt');
    };
    displayFilterOptions = displayFilterOptions.concat([
      'notPassedVarCnt',
      'failedCnt'
    ]);
    if (this.doc.newContentCnt > 0) {
      displayFilterOptions.push('newContentCnt');
    };
    displayFilterOptions.push('markedCnt');
    if (this.doc.dismissedCnt > 0) {
      displayFilterOptions.push('dismissedCnt');
    };
    displayFilterOptions = displayFilterOptions.concat(
      [
        'newClaimCnt',
        'claimVariantCnt',
        'stetCnt'
      ]
    );
    return displayFilterOptions;
  }

  get filteredRuleStatusList(): DataElement[] {
    return this.ruleStatusList.filter(s => {
      if (s.id == 'NOT_PASSED_NEW_CONTENT') {
        return this.doc.newContentCnt > 0;
      }
      if (s.id == 'DISMISSED') {
        return this.doc.dismissedCnt > 0;
      }
      return true;
    })
  }

  constructor(
    private ngRedux: NgRedux<AppState>,
    private revService: RevService,
    private router: Router,
    private sharedService: SharedService,
    private location: Location,
    private authService: AuthService,
    private translateService: TranslateService,
    private catService: CatService,
    private integrationService: IntegrationService
  ) {
    this.tabIdList = this.ngRedux.getState().ui_tabIdList;

    this.un = this.ngRedux.subscribe(() => {
      const appState = this.ngRedux.getState();
      switch (appState._currentAction) {
        case actionsList.UPD_REV_DOC_STATS:
          this.drawStatusChart();

          break;
        case actionsList.GET_REV_DOC_RULES:
          this.rulesListLoadedPageNum = this.curPage;
          if (
            this.initAnnotationsloadedPageNum !== this.curPage &&
            this.docFrameLoadedPageNum == this.curPage
          ) {
            this.addAllAnnotationsToDoc();
            setTimeout(() => {
              this.toggleFailedAnnotations();
            }, 500);
          }
          break;
      }
    });
  }

  ngOnDestroy(): void {
    this.ngRedux.dispatch({
      type: actionsList.SET_DL_REV_DOC,
      data: null,
    });

    if (this.un) this.un();
    this.sub2?.unsubscribe();
    if (this.processStatusTimer) {
      clearInterval(this.processStatusTimer);
    }
  }

  ngOnInit(): void {
    if (this.dlRevDoc) {
      this.dlRevDoc.dlDocHandled = true;
    }

    this.getRevCards();
    this.getDocObjects();

    this.ngRedux.dispatch({
      type: actionsList.FILTER_REV_RULES,
      data: null,
    });

    this.ngRedux.dispatch({
      type: actionsList.SELECT_REV_DOC_PAGE_NUM,
      data: this.dlRevDoc ? this.dlRevDoc.page : 1,
    });
  }

  ngAfterViewInit(): void {
    let main = document.getElementById('main');
    console.log(main);
    main.addEventListener(
      'scroll',
      (event) => {
        this.showBarStatusChart = main.scrollTop > 230;
      },
      { passive: true }
    );
  }

  getDocObjects() {
    this.catService.getDocObjects(this.doc.docId, 'REV').subscribe(
      (data) => {
        setTimeout(() => {
          // this will pick up presigned URLs for any of remaining rev doc cards bounding boxes' thumbnails
          this.catService.resolveAnyRemainingPresignedUrl();
        }, 2000);        
      },
      (err) => (console.log('An error occurred geting REV doc objects', this.doc.docId, err))
    );
  }

  getStatusDataset() {
    let doneCnt = Math.round(this.doc.progressPct * this.doc.totCnt);
    let newContentCnt = this.doc.newContentCnt;
    let notPassedCnt = this.doc.failedCnt;
    let notPassedVarCnt = this.doc.notPassedVarCnt;

    return {
      data: [
        doneCnt,
        //newContentCnt,
        notPassedCnt,
        //notPassedVarCnt
      ],
      // backgroundColor: ['#62bd60', '#dbab23', '#f35f3e', '#0978a2'],
      // hoverBackgroundColor: ['#62bd60', '#dbab23', '#f35f3e', '#0978a2'],
      backgroundColor: ['#62bd60', '#f35f3e'],
      hoverBackgroundColor: ['#62bd60', '#f35f3e'],
      labels: [
        this.translateService.instant('translate.general.done'),
        // this.translateService.instant('translate.general.newContentCnt'),
        this.translateService.instant('translate.general.failedCnt'),
        // this.translateService.instant('translate.general.notPassedVarCnt'),
      ],
    };
  }

  drawStatusChart() {
    this.drawBarStatusChart();

    this.canvasStatus = document.getElementById('chart-status-cnv');
    if (!this.canvasStatus) return;
    this.ctxStatus = this.canvasStatus.getContext('2d');
    let dataset = this.getStatusDataset();
    let myChart = new Chart(this.ctxStatus, {
      type: 'doughnut',
      data: {
        datasets: [dataset],
        labels: dataset.labels,
      },
      options: {
        tooltips: {
          enabled: true,
          callbacks: {
            label: (tooltipItems, data) => {
              return (
                data.labels[tooltipItems.index] +
                ' ' +
                data.datasets[0].data[tooltipItems.index]
              );
            },
          },
        },
        cutoutPercentage: 70,
        legend: {
          display: false,
        },
        layout: {
          padding: {
            right: 20,
            left: 20,
          },
        },
        elements: {
          center: {
            text: Math.round(this.doc.progressPct * 100) + '%',
            color: '#0978A2',
            sidePadding: 40,
          },
        },
      },
    });
  }

  getBarStatusDataset() {
    let doneCnt = Math.round(this.doc.progressPct * this.doc.totCnt);
    let newContentCnt = this.doc.newContentCnt;
    let notPassedCnt = this.doc.failedCnt;
    let notPassedVarCnt = this.doc.notPassedVarCnt;

    return [
      {
        data: [doneCnt],
        backgroundColor: ['#62bd60'],
        hoverBackgroundColor: ['#62bd60'],
        label: this.translateService.instant('translate.general.done'),
      },
      // {
      //   data: [newContentCnt],
      //   backgroundColor: ['#dbab23'],
      //   hoverBackgroundColor: ['#dbab23'],
      //   label: this.translateService.instant('translate.general.newContentCnt'),
      // },
      {
        data: [notPassedCnt],
        backgroundColor: ['#f35f3e'],
        hoverBackgroundColor: ['#f35f3e'],
        label: this.translateService.instant('translate.general.failedCnt'),
      },
      // {
      //   data: [notPassedVarCnt],
      //   backgroundColor: ['#0978a2'],
      //   hoverBackgroundColor: ['#0978a2'],
      //   label: this.translateService.instant(
      //     'translate.general.notPassedVarCnt'
      //   ),
      // },
    ];
  }

  drawBarStatusChart() {
    this.canvasBarStatus = document.getElementById('chart-bar-status-cnv');
    if (!this.canvasBarStatus) return;
    this.ctxBarStatus = this.canvasBarStatus.getContext('2d');
    let datasets = this.getBarStatusDataset();
    let myChart = new Chart(this.ctxBarStatus, {
      type: 'horizontalBar',
      data: {
        datasets: datasets,
        labels: datasets.map((d) => d.label),
      },
      options: {
        animation: {
          duration: 0,
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false,
              },
              stacked: true,
              scaleLabel: {
                display: false,
              },
              ticks: {
                display: false,
              },
            },
          ],
          yAxes: [
            {
              barThickness: 20,
              gridLines: {
                display: false,
              },
              stacked: true,
              scaleLabel: {
                display: false,
              },
              ticks: {
                display: false,
              },
            },
          ],
        },
        tooltips: {
          enabled: true,
          mode: 'dataset',
          callbacks: {
            title: (tooltipItems, data) => {
              return '';
            },
            label: (tooltipItems, data) => {
              return (
                data.datasets[tooltipItems.datasetIndex].label +
                ': ' +
                tooltipItems.value
              );
            },
          },
        },
        legend: {
          display: false,
        },
      },
    });
  }

  roundNumber(value: number) {
    return Math.round((value + Number.EPSILON) * 100) / 100;
  }

  goBack() {
    this.ngRedux.dispatch({
      type: actionsList.SET_DL_REV_DOC,
      data: null,
    });

    this.ngRedux.dispatch({
      type: actionsList.SELECT_REV_DOC,
      data: null,
    });
    this.router.navigate([this.sharedService.routeEnum.URL_REV_DOC_LIST]);
  }

  onFilterKeyPress(e: any) {
    if (e.keyCode == keyCodes.enter) {
      this.filterRules();
    }
  }

  onFieldValueChanged(e: any) {
    (e.originalEvent as MouseEvent).stopPropagation();
    this.filterRules();
  }

  resetFilterParams() {
    this.filterPhrase = null;
    this.filterRuleType = null;
    this.filterStatus = null;
    this.filterContentType = null;
    this.filterMatchType = null;
    this.filterSubTypes = null;
    this.selectedTypeNodes = null;
    this.ngRedux.getState().revRulesSearchParams = this.getSearchParams();
  }

  clearFilter() {
    this.resetFilterParams();

    this.ngRedux.dispatch({
      type: actionsList.SELECT_REV_DOC_PAGE_NUM,
      data: this.curPage,
    });
  }

  getSearchParams(): RevRulesSearchParams {
    let params = new RevRulesSearchParams();
    params.phrase = this.filterPhrase;
    params.ruleType = this.filterRuleType;
    params.ruleStatus = this.filterStatus;
    params.contentType = this.filterContentType;
    params.matchType = this.filterMatchType;
    params.subTypeList = this.filterSubTypes;
    return params;
  }

  filterRules() {
    this.ngRedux.dispatch({
      type: actionsList.FILTER_REV_RULES,
      data: this.getSearchParams(),
    });
  }

  finalizeDoc() {
    this.finalizeDocStatus(RevDocStatusEnum.FINAL);
  }

  completeDoc() {
    this.updateDocStatus(RevDocStatusEnum.COMPLETED);
  }

  reopenDoc() {
    this.updateDocStatus(RevDocStatusEnum.UNDERWAY);
  }

  inactivateDoc() {
    this.updateDocStatus(RevDocStatusEnum.INACTIVE);
  }

  updateDocStatus(statusCode: string) {
    this.loading = true;
    this.revService.updRevDoc(this.doc, statusCode).subscribe(
      (data) => {
        this.drawStatusChart();
        this.loading = false;
      },
      (err) => (this.loading = false)
    );
  }

  finalizeDocStatus(statusCode: string) {
    this.loading = true;
    this.revService.updRevDoc(this.doc, statusCode).subscribe(
      (data) => {
        this.drawStatusChart();
        this.integrationService.finalizePrecheck(this.doc.docId).subscribe(
          (data) => {
            this.loading = false;
          },
          (err) => (this.loading = false)
        );
      },
      (err) => (this.loading = false)
    );
  }

  onPageChanged(pageNum: number) {
    this.resetFilterParams();
    this.ngRedux.dispatch({
      type: actionsList.SELECT_REV_DOC_PAGE_NUM,
      data: pageNum,
    });
    this.toggleAllSw = false;
  }

  dismissSelected() {
    this.updateStatusSelected(RevStatus.DISMISSED);
  }

  markSelected() {
    this.updateStatusSelected(RevStatus.MARKED_FOR_CHANGES);
  }

  updateStatusSelected(status: RevStatus) {
    this.loading = true;
    this.revService
      .updRevRule(this.ngRedux.getState().curRevDoc, this.selectedRules, status)
      .subscribe(
        (data) => {
          this.selectedRules.forEach((r) => {
            let updRule = data.find((d) => d.revRuleId == r.revRuleId);
            r.revStatusCode = updRule.revStatusCode;
            r.revStatusDescr = updRule.revStatusDescr;
            r.ruleUserStatus = updRule.ruleUserStatus;
            this.onToggleRuleView(r);
          });

          this.selectedRules.forEach((r) => {
            r.checkedSw = false;
          });

          this.loading = false;
        },
        (err) => {
          this.loading = false;
        }
      );
  }

  onToggleRuleView(rule: RevRule, changePageSw: boolean = true) {
    let toggleElements = () => {
      if (rule.viewSw) {
        this.showRuleAnnotation(rule);
      } else {
        this.hideRuleAnnotation(rule);
      }
    };
    if (rule.pageNum !== this.curPage && changePageSw) {
      this.ngRedux.dispatch({
        type: actionsList.SELECT_REV_DOC_PAGE_FROM_SEARCH,
        data: rule.pageNum,
      });
      this.docViewer.goToPage(rule.pageNum);
    } else {
      toggleElements();
    }
  }

  hideRuleAnnotation(rule: RevRule) {
    rule.annId = rule.revRuleId;
    this.docViewer.hideAnnotation(rule, false);
  }

  showRuleAnnotation(rule: RevRule, visible: boolean = true) {
    if (rule.pageNum != this.docViewer.displayedPage) return;

    switch (rule.revStatusCode) {
      case RevStatus.PASSED:
        rule.annColor = '#30bb30';
        break;
      case RevStatus.NOT_PASSED:
        rule.annColor = '#f35f3e';
        break;
      case RevStatus.NOT_PASSED_VAR:
        rule.annColor = '#0978a2';
        break;
      case RevStatus.NEW_CONTENT:
        rule.annColor = '#dbab23';
        break;
      case RevStatus.STET:
        rule.annColor = '#30bb30';
        break;
      case RevStatus.DISMISSED:
        rule.annColor = '#d86c06';
        break;
      case RevStatus.MARKED_FOR_CHANGES:
        rule.annColor = '#ff2229';
        break;
      default:
        rule.annColor = '#0978A2';
        break;
    }
    rule.annId = rule.revRuleId;
    this.docViewer.showAnnotation(rule, visible);
  }

  onToggleAnnotations(viewSw: boolean) {
    this.displayedRules.forEach((r) => {
      if (r.pageNum == this.ngRedux.getState().curRevDocPageNum) {
        r.viewSw = viewSw;
        this.onToggleRuleView(r);
      }
    });
  }

  onToggleImageAnnotations(viewSw: boolean) {
    this.toggleImageAnnotationsSw = viewSw;
    let elements: RevRule[] = this.displayedRules.filter((r) => r.imageId);
    elements.forEach((r) => {
      if (r.pageNum == this.curPage) {
        r.viewSw = viewSw;
        this.onToggleRuleView(r);
      }
    });
  }

  onDocIframeLoaded() {
    this.docFrameLoadedPageNum = this.curPage;
    console.log('frame loaded: ' + this.docFrameLoadedPageNum);

    if (
      this.initAnnotationsloadedPageNum !== this.curPage &&
      this.rulesListLoadedPageNum == this.curPage
    ) {
      this.addAllAnnotationsToDoc();
      setTimeout(() => {
        this.toggleFailedAnnotations();
      }, 500);
    }

    this.docFrameLoaded = true;
  }

  addAllAnnotationsToDoc() {
    this.ngRedux.getState().revDocRules.forEach((r) => {
      this.showRuleAnnotation(r, false);
    });
  }

  toggleFailedAnnotations() {
    this.initAnnotationsloadedPageNum = this.curPage;
    this.ngRedux.getState().revDocRules.forEach((r) => {
      r.viewSw = r.revStatusCode == RevStatus.NOT_PASSED;
      this.onToggleRuleView(r, false);
    });
  }

  onToggleSelectAll() {
    this.displayedRules.forEach((p) => (p.checkedSw = this.toggleAllSw));
  }

  downloadAnnotatedDoc() {
    this.downloadingFile = true;
    this.revService.createAnnotateFileReq(this.doc).subscribe(
      (runNum) => {
        this.revService.processAnnotatePrecheck(runNum).subscribe(
          (data) => {},
          (err) => {
            this.downloadFileError(err);
          }
        );

        this.processStatusTimer = setInterval(() => {
          this.authService.getProcessStatus(runNum).subscribe(
            (data) => {
              if (data.status == ProcessRunStatusEnum.FINISHED) {
                clearInterval(this.processStatusTimer);
                this.catService
                  .getS3Objects([data.outDocS3Key], true)
                  .subscribe(
                    async (data2) => {
                      let url = this.catService.getS3TempObjectPresignedUrl(data.outDocS3Key);
                      console.log(url);
                      
                      let blob = await fetch(url).then(r => r.blob());
                      this.downloadingFile = false;
                      
                      var element = document.createElement('a');
                      element.href = URL.createObjectURL(blob);
                      const fileExt = `${this.doc.fileName.slice(this.doc.fileName.lastIndexOf(".") + 1)}`;
                      let fileName = this.doc.docName;
                      if (!this.doc.docName.endsWith(`.${fileExt}`)) {
                        fileName = `${this.doc.docName}.${fileExt}`;
                      }
                      element.download = `${fileName}`;
                      
                      // element.href =
                      //    'https://s3.eu-central-1.amazonaws.com/scai.qa.tmpdocs.com/db4ab5f0-7f19-4940-900d-4db9f3c13838/annotate_precheck/218a8b1c-90ff-11ed-9641-f9f98cb65d8b.pdf';

                      element.setAttribute('target', '_blank');
                      document.body.appendChild(element);
                      element.click();
                      document.body.removeChild(element);
                    },
                    (err) => {
                      this.downloadFileError(err);
                    }
                  );
              } else if (data.status == ProcessRunStatusEnum.FAILED) {
                this.downloadFileError(
                  this.translateService.instant(
                    'translate.cat.annotateProcessFailed'
                  )
                );
              }
            },
            (err) => {
              this.downloadFileError(err);
            }
          );
        }, this.getProcessStatusIntervalSeconds * 1000);
      },
      (err) => {
        this.downloadFileError(err);
      }
    );
  }

  downloadFileError(err) {
    clearInterval(this.processStatusTimer);
    this.downloadingFile = false;
    this.sharedService.alertError(err);
  }

  onAnnMouseEnter(ann: AnnotationElement) {
    let rule = this.ngRedux
      .getState()
      .revDocRules.find((r) => r.phraseId == ann.annId || r.revRuleId == ann.annId);
    if (rule) this.revRulesList.onMouseEnter(rule);
  }

  onAnnMouseLeave(ann: AnnotationElement) {
    let rule = this.ngRedux
      .getState()
      .revDocRules.find((r) => r.phraseId == ann.annId || r.revRuleId == ann.annId);
    if (rule) this.revRulesList.onMouseLeave(rule);
  }

  onAnnClicked(ann: AnnotationElement) {
    let rule = this.ngRedux
      .getState()
      .revDocRules.find((r) => r.phraseId == ann.annId || r.revRuleId == ann.annId);
    if (rule) {
      this.ngRedux.dispatch({
        type: actionsList.REV_RULE_ANN_CLICKED,
        data: rule,
      });
    }
  }

  onTypesPanelHide() {
    if (!this.selectedTypeNodes || this.selectedTypeNodes.length == 0) {
      this.filterSubTypes = null;
    } else {
      this.filterSubTypes = this.selectedTypeNodes
        .filter((n) => n.data['treeLevel'] == 1)
        .map((n) => n.key);
    }

    let prevSearchValues =
      this.ngRedux.getState().revRulesSearchParams == null
        ? []
        : this.ngRedux.getState().revRulesSearchParams.subTypeList ?? [];
    let curSearchValues = this.filterSubTypes ?? [];
    if (curSearchValues.join(',') !== prevSearchValues.join(','))
      this.filterRules();
  }

  onPanelResizeStart(event: any) {
    this.panelResizing = true;
  }

  onPanelResizeEnd(event: any) {
    this.panelResizing = false;
  }

  getRevCards() {
    this.getCardsSub?.unsubscribe();
    this.sub?.unsubscribe();

    this.cardsLoading = true;
    this.ngRedux.getState().revDocRules = [];

    this.getCardsSub = this.revService.getRevCards(this.doc.docId).subscribe(
      (searchId) => {
        console.log(searchId);
        this.pullSearchStatus(
          searchId,
          () => {
            this.revService.getRevCardsSearch(searchId).subscribe(
              (data) => {
                this.cardsLoading = false;

                if (
                  this.ngRedux.getState().dlRevDoc &&
                  this.ngRedux.getState().dlRevDoc.cardId
                ) {
                  this.ngRedux.dispatch({
                    type: actionsList.UI__NAVIGATE_TO_TAB,
                    data: this.tabIdList.REV_DOC__CARDS,
                  });
                }
              },
              (err) => {
                this.cardsLoading = false;
              }
            );
          },
          () => {
            this.cardsLoading = false;
            this.sharedService.alertError(
              this.translateService.instant('translate.alert.searchError'),
              null,
              null,
              'get_rev_cards_func'
            );
          }
        );
      },
      (err) => (this.cardsLoading = false)
    );
  }

  pullSearchStatus(searchId: number, onFinished, onError) {
    this.sub?.unsubscribe();
    this.sub = this.authService.getSearchStatus(searchId).subscribe(
      (status) => {
        if (status == SearchRunStatusEnum.FINISHED) {
          onFinished();
        } else if (status == SearchRunStatusEnum.ERROR) {
          onError();
        } else {
          setTimeout(() => {
            this.pullSearchStatus(searchId, onFinished, onError);
          }, this.searchStatusIntervalSec * 1000);
        }
      },
      (err) => {
        onError();
      }
    );
  }

  downloadIndd() {
    this.downloadingZip = true;
    this.catService.getS3Objects([this.doc.inddS3Key]).subscribe(
      () => {
        let url = this.catService.getS3TempObjectPresignedUrl(this.doc.inddS3Key);
        this.tryDownloadFile(url);
      },
      (err) => {
        this.downloadingZip = false;
      }
    );
  }

  tryDownloadFile(url: string) {
    this.sub2?.unsubscribe();
    this.sub2 = this.catService.checkFileExists(url).subscribe(
      (exists) => {
        if (exists) {
          console.log(url);
          var element = document.createElement('a');
          element.href = url;
          element.setAttribute('download', this.doc.fileName);
          document.body.appendChild(element);
          element.click();
          document.body.removeChild(element);
          this.downloadingZip = false;
        } else {
          setTimeout(() => {
            this.tryDownloadFile(url);
          }, 100);
        }
      },
      (err) => {
        this.downloadingZip = false;
      }
    );
  }
}
