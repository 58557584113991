<modal-popup
  [flexible]="true"
  [focusOnOpen]="true"
  [isOpen]="true"
  (onClose)="close()"
  [title]="modalTitle"
  [afterTitle]="dirtyBtns"
>
  <div class="container">
    <!-- parent type descr --->
    <input-wrap
      label="{{ 'translate.cat.mngPhraseParentType' | translate }}"
      [readonly]="true"
      cssClass="l-cell-spread"
    >
      <input
        type="text"
        [(ngModel)]="subType.typeDescr"
        class="t-input"
        [disabled]="true"
      />
    </input-wrap>

    <!--descr-->
    <input-wrap
      label="{{ 'translate.cat.mngPhraseTypeDescr' | translate }}"
      [required]="true"
      cssClass="l-cell-spread"
    >
      <input type="text" [(ngModel)]="subType.subTypeDescr" class="t-input" />
    </input-wrap>

    <!--code-->
    <input-wrap
      label="{{ 'translate.cat.mngPhraseTypeCode' | translate }}"
      [required]="true"
      cssClass="l-cell-spread"
    >
      <input
        type="text"
        [(ngModel)]="subType.subTypeCode"
        class="t-input"
        [disabled]="!isNew"
      />
    </input-wrap>

    <!--error level -->
    <input-wrap
      label="{{ 'translate.cat.mngPhraseTypeErrorLevel' | translate }}"
      cssClass="l-cell-spread"
    >
      <input type="number" [(ngModel)]="subType.errorLevel" class="t-input" />
    </input-wrap>

    <!-- level0 -->
    <input-wrap
      label="{{ 'translate.cat.mngPhraseSubTypeLevel0' | translate }}"
      cssClass="l-cell-spread"
    >
      <input type="text" [(ngModel)]="subType.level0" class="t-input" />
    </input-wrap>

    <!--level1 -->
    <input-wrap
      label="{{ 'translate.cat.mngPhraseSubTypeLevel1' | translate }}"
      cssClass="l-cell-spread"
    >
      <input type="text" [(ngModel)]="subType.level1" class="t-input" />
    </input-wrap>

    <!--algKeyWords-->
    <input-wrap
      label="{{ 'translate.cat.algKeyWords' | translate }}"
      cssClass="l-cell-spread"
    >
      <textarea
        pInputTextarea
        [(ngModel)]="subType.algKeyWords"
        autoResize="true"
      ></textarea>
    </input-wrap>

    <div class="l-align-end l-mar-top t-row--small" *ngIf="!isNew">
      <button
        class="t-button-outline t-button-warn"
        [disabled]="loading"
        (click)="onDeleteClicked()"
      >
        {{ "translate.general.delete" | translate }}
      </button>
    </div>
  </div>
</modal-popup>

<ng-template #dirtyBtns>
  <div *ngIf="isDirty()" class="t-small">
    <button
      class="t-button t-button-warn"
      [disabled]="loading || !validateBeforeSave()"
      (click)="saveChanges()"
    >
      {{ "translate.general.saveChanges" | translate }}
    </button>
    &nbsp;
    <button
      class="t-button-outline t-button-warn"
      [disabled]="loading"
      (click)="discardChanges()"
    >
      {{ "translate.general.discardChanges" | translate }}
    </button>
  </div>
</ng-template>
