import { NgModule, ComponentRef } from "@angular/core";
import {
  RouterModule,
  DetachedRouteHandle,
  ActivatedRouteSnapshot,
  RouteReuseStrategy,
} from "@angular/router";

@NgModule({
  exports: [RouterModule],
})
export class AppRoutingModule {}

export class CustomRouteReuseStrategy implements RouteReuseStrategy {
  private handlers: Map<string, DetachedRouteHandle> = new Map();

  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    if (!route.routeConfig || route.routeConfig.loadChildren) {
      return false;
    }

    if (route.queryParams){
      if (route.queryParams.reuse){
        return false;
      }
    }
    
    let shouldReuse = false;
    if (route.routeConfig.data) {
      route.routeConfig.data.reuse
        ? (shouldReuse = true)
        : (shouldReuse = false);
    }
    return shouldReuse;
  }

  store(route: ActivatedRouteSnapshot, handler: DetachedRouteHandle): void {
    if (handler) {
      //this.handlers[this.getUrl(route)] = handler;
      this.handlers.set(this.getUrl(route), handler);
    }
  }

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    if (this.shouldResetReuseStrategy(route)) {
      this.deactivateAllHandles();
      return false;
    }

    if (route.queryParams){
      if (route.queryParams.reuse){
        return false;
      }
    }

    //return !!this.handlers[this.getUrl(route)];
    return !!this.handlers.has(this.getUrl(route));
  }

  private deactivateAllHandles(): void {
    this.handlers.forEach((handle: DetachedRouteHandle) =>
      this.destroyComponent(handle)
    );
    this.handlers.clear();
  }

  private destroyComponent(handle: DetachedRouteHandle): void {
    const componentRef: ComponentRef<any> = handle["componentRef"];

    if (componentRef) {
      componentRef.destroy();
    }
  }

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
    if (!route.routeConfig || route.routeConfig.loadChildren) {
      return null;
    }

    return this.handlers.get(this.getUrl(route));
  }

  shouldReuseRoute(
    future: ActivatedRouteSnapshot,
    current: ActivatedRouteSnapshot
  ): boolean {
    let reUseUrl: string;

    if (future.routeConfig) {
      if (future.routeConfig.data) {
        reUseUrl = future.routeConfig.data.reuse;
      }
    }

    const defaultReuse = future.routeConfig === current.routeConfig;

    if (current.routeConfig)
      return reUseUrl == current.routeConfig.path || defaultReuse;
    else return defaultReuse;
  }

  getUrl(route: ActivatedRouteSnapshot): string {
    if (route.routeConfig) {
      const url = route.routeConfig.path;
      return url;
    }
  }

  private shouldResetReuseStrategy(route: ActivatedRouteSnapshot): boolean {
    let snapshot: ActivatedRouteSnapshot = route;

    while (snapshot.children && snapshot.children.length) {
      snapshot = snapshot.children[0];
    }

    return snapshot.data && snapshot.data.resetReuseStrategy;
  }
}






interface RouteStates {
  max: number;
  handles: {[handleKey: string]: DetachedRouteHandle};
  handleKeys: string[];
}

function getResolvedUrl(route: ActivatedRouteSnapshot): string {
  return route.pathFromRoot
      .map(v => v.url.map(segment => segment.toString()).join('/'))
      .join('/');
}

function getConfiguredUrl(route: ActivatedRouteSnapshot): string {
  return '/' + route.pathFromRoot
      .filter(v => v.routeConfig)
      .map(v => v.routeConfig!.path)
      .join('/');
}

export class ReuseStrategy implements RouteReuseStrategy {


  /** Determines if this route (and its subtree) should be detached to be reused later */
  shouldDetach(route: ActivatedRouteSnapshot): boolean {
     // return !!this.routes[getConfiguredUrl(route)];

     if (!route.routeConfig || route.routeConfig.loadChildren) {
      return false;
    }

    if (route.queryParams){
      if (route.queryParams.reuse){
        return false;
      }
    }
    
    let shouldReuse = false;
    if (route.routeConfig.data) {
      route.routeConfig.data.reuse
        ? (shouldReuse = true)
        : (shouldReuse = false);
    }
    return shouldReuse;
  }

  private getStoreKey(route: ActivatedRouteSnapshot) {
      const baseUrl = getResolvedUrl(route);

      //this works, as ActivatedRouteSnapshot has only every one children ActivatedRouteSnapshot
      //as you can't have more since urls like `/project/1,2` where you'd want to display 1 and 2 project at the
      //same time
      const childrenParts = [];
      let deepestChild = route;
      while (deepestChild.firstChild) {
          deepestChild = deepestChild.firstChild;
          childrenParts.push(deepestChild.url.join('/'));
      }

      //it's important to separate baseUrl with childrenParts so we don't have collisions.
      return baseUrl + '////' + childrenParts.join('/');
  }

  /**
   * Stores the detached route.
   *
   * Storing a `null` value should erase the previously stored value.
   */
  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle | null): void {
      if (route.routeConfig) {
          const config = route.routeConfig.data; //this.routes[getConfiguredUrl(route)];
          if (config) {
              const storeKey = this.getStoreKey(route);
              if (handle) {
                  if (!config.handles[storeKey]) {
                      //add new handle
                      if (config.handleKeys.length >= config.max) {
                          const oldestUrl = config.handleKeys[0];
                          config.handleKeys.splice(0, 1);

                          //this is important to work around memory leaks, as Angular will never destroy the Component
                          //on its own once it got stored in our router strategy.
                          const oldHandle = config.handles[oldestUrl] as { componentRef: ComponentRef<any> };
                          oldHandle.componentRef.destroy();

                          delete config.handles[oldestUrl];
                      }
                      config.handles[storeKey] = handle;
                      config.handleKeys.push(storeKey);
                  }
              } else {
                  //we do not delete old handles on request, as we define when the handle dies
              }
          }
      }
  }

  /** Determines if this route (and its subtree) should be reattached */
  shouldAttach(route: ActivatedRouteSnapshot): boolean {
      if (route.routeConfig) {
          const config = route.routeConfig.data; //this.routes[getConfiguredUrl(route)];

          if (config) {
              const storeKey = this.getStoreKey(route);
              return !!config.handles[storeKey];
          }
      }

      return false;
  }

  /** Retrieves the previously stored route */
  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
      if (route.routeConfig) {
          const config = route.routeConfig.data; // this.routes[getConfiguredUrl(route)];

          if (config) {
              const storeKey = this.getStoreKey(route);
              return config.handles[storeKey];
          }
      }

      return null;
  }

  /** Determines if `curr` route should be reused */
  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
      return getResolvedUrl(future) === getResolvedUrl(curr) && future.routeConfig === curr.routeConfig;
  }
}