import { Component, Input, OnInit } from '@angular/core';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'async-img',
  templateUrl: './async-img.component.html',
  styleUrls: ['./async-img.component.scss'],
})
export class AsyncImgComponent implements OnInit {
  @Input() url: string;
  @Input() className: string;

  errCnt: number = 0;

  constructor() {}

  ngOnInit(): void {}

  onImgError(event) {
    const originalSrc = event.target.src;
    this.errCnt++;
    if (this.errCnt > 50) {
      return;
    }
    event.target.src = SharedService.loadingImg;
    setTimeout(() => {
      if (!event.target) return;
      // DEV NOTE: we're not altering Amazon presigned URLs in any way, otherwise it gets broken
      event.target.src = this.url + (!SharedService.isPresignedUrl(this.url) ? '?' + new Date().getTime() : '');
    }, 2000);
  }
}
