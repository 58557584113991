import { NgRedux } from '@redux/redux-compatibility.service';
import { Component, OnInit } from '@angular/core';
import { DataElement } from 'src/app/shared/models/common';
import { AppState } from 'src/app/shared/redux/store';
import { CatService } from 'src/app/shared/services/cat.service';

@Component({
  selector: 'app-mng-asset-types',
  templateUrl: './mng-asset-types.component.html',
  styleUrls: ['./mng-asset-types.component.scss'],
})
export class MngAssetTypesComponent implements OnInit {
  loading: boolean;
  editOpenSw: boolean;
  curRow: DataElement;

  get assetTypeList(): DataElement[] {
    return this.ngRedux.getState().assetTypes;
  }

  constructor(
    private ngRedux: NgRedux<AppState>,
    private catService: CatService
  ) {}

  ngOnInit(): void {}

  refreshAssetTypeList() {
    this.loading = true;
    this.catService.getAssetTypes().subscribe(
      (data) => {
        this.loading = false;
      },
      (err) => {
        this.loading = false;
      }
    );
  }

  create() {
    this.curRow = new DataElement();
    this.editOpenSw = true;
  }

  edit(row: DataElement) {
    this.curRow = row;
    this.editOpenSw = true;
  }
}
