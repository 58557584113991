<ui-table
  [isWrapped]="false"
  [data]="docs"
  [displayFilter]="['docId', 'docName', 'versionId', 'singlePhrasePageNum']"
  [templates]="[
    {
      title: 'translate.general.view',
      template: viewDoc
    }
  ]"
></ui-table>

<ng-template #viewDoc let-row="row">
  <a (click)="viewPhraseDoc(row, true)"
    >{{ "translate.general.viewPage" | translate }}
  </a>
  <span class="action-divider">|</span>

  <a (click)="viewPhraseDoc(row)"
    >{{ "translate.general.viewDoc" | translate }}
  </a>
</ng-template>

<modal-popup
  *ngIf="displayDocViewer"
  [flexible]="false"
  [hideHeader]="false"
  [title]="curDoc.docName"
  [isOpen]="displayDocViewer"
  (onClose)="displayDocViewer = false"
>
  <div *ngIf="!docLoading" class="pdf-container">
    <pdf-viewer
      #docSinglePhraseViewer
      [doc]="curDoc"
      [initialPage]="curPage"
      [displayDrawMode]="false"
      [displayToggleBoundingBoxes]="false"
      [displayPaging]="!singlePageSw"
      [singlePage]="singlePageSw"
      (onToggleAnnotations)="onToggleAnnotations($event)"
      (onIframeLoaded)="onDocIframeLoaded()"
    ></pdf-viewer>
  </div>
</modal-popup>
