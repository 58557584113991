import { TemplateRef } from '@angular/core';

export class Tab {
  tabId: string = undefined;
  tabTitle?: string = undefined;
  tabIcon?: string = undefined;
  tabClass?: string = undefined;
  icon?: string = undefined;
  link?: string = undefined;
  action?: string;
  isActive?: boolean = undefined;
  disabled?: boolean = undefined;
  current?: boolean;
  onSelect?: Function;
  onUnSelect?: Function;
  onMouseEnter?: Function;
  onMouseLeave?: Function;
  isHidden?: boolean;
  hFull?: boolean;
  subMenu?: TemplateRef<any>;
  tabBadgeCounter?: number;
  tabBadgeColor?: string;
  img?: string = undefined;
}

export class TabIdList {
  CAT__ENTRIES = 'cat.entries';
  CAT__IMAGES = 'cat.images';
  CAT__DOCS = 'cat.docs';
  CAT__MNG_PHRASE_TYPES = 'cat.mng_phrase_types';
  CAT__MNG_ASSET_TYPES = 'cat.mng_asset_types';
  CAT__ENTRIES__REF = 'cat.entries.ref';
  CAT__ENTRIES__INSTR = 'cat.entries.instr';
  CAT__ENTRIES__DOCS = 'cat.entries.docs';
  CAT__CARDS = 'cat.cards';
  CAT__CARD_ASSET_TEMPLATES = 'cat.card_asset_templates';
  CAT__CARDS__ENTRIES = 'cat.cards.card_entries';
  CAT__CARDS__ADD_ENTRIES = 'cat.cards.add_entries';
  CAT__CARDS__TRANS = 'cat.cards.trans';
  CAT__CARDS__SUMMARY = 'cat.cards.summary';
  CAT__EXT_LIBS = 'cat.ext_libs';
  CAT__EXT_REFS = 'cat.ext_refs';
  CAT__ALT_REFS = 'cat.alt_refs';
  CAT__ALT_REFS__REF = 'cat.entries.ref.phrases';
  CAT__SPELLING_CHECK_IGNORED = 'cat.spelling_check_ignored';
  CAT__REF_MAP = 'cat.ref_map';

  LC_AC = 'lc.ac';
  LC_BC = 'lc.bc';
  LC_BC_DOCS = 'lc.bc_docs';
  LC_AC_DOCS = 'lc.ac_docs';
  LC_BC_CARDS = 'lc.bc_cards';
  LC_AC_CARDS = 'lc.ac_cards';
  LC_BC_PHRASES = 'lc.bc_phrases';
  LC_AC_PHRASES = 'lc.ac_phrases';
  LC_DOC_SUMMARY_CARDS_ac = 'lc.doc_cards_ac';
  LC_DOC_SUMMARY_CARDS_bc = 'lc.doc_cards_bc';
  LC_DOC_SUMMARY_PHRASES_ac = 'lc.doc_phrases_ac';
  LC_DOC_SUMMARY_PHRASES_bc = 'lc.doc_phrases_bc';

  TAG_DOCS_IND = 'tag.docs.ind';
  TAG_DOCS_REV = 'tag.docs.rev';

  CAT_DOC__VIEWER = 'cat.doc.viewer';
  CAT_DOC__CARDS = 'cat.doc.cards';

  REV_DOC__VIEWER = 'cat.rev.viewer';
  REV_DOC__CARDS = 'cat.rev.cards';
}

export const tabList = Object.freeze(new TabIdList());
