<div *ngIf="curCat">
  <section *ngIf="selectMode" class="page-header">
    <div class="title-container">
      <h1>
        <a class="icon" (click)="goBack()"
          ><fa-icon [icon]="['fas', 'arrow-left']"></fa-icon
        ></a>
        &nbsp;
        {{ curCat.catDescr }}
      </h1>
    </div>
  </section>
  <loader *ngIf="loading"></loader>

  <ui-table
    #tblDocs
    [isWrapped]="false"
    [data]="docs"
    [numberOfRows]="10"
    [checkboxSelection]="allowParentSelect"
    [totalRecords]="docsMetaData.totalRecords"
    (requestUpdate)="onPagination($event)"
    [selectedRow]="selectedDoc"
    [displayFilter]="['origDocId', 'lastVersionId', 'createDate']"
    [templates]="[
      {
        title: 'translate.general.docName',
        template: docName,
        afterColumn: 1,
        permission: true
      },
      {
        title: 'translate.general.statusDescr',
        template: status
      },
      {
        title: 'translate.general.actions',
        template: actions
      },
      {
        template: icons,
        permission: allowTagDocs && !selectMode
      }
    ]"
    [more]="selectMode ? null : expandRow"
    [topEnd]="filterArea"
    (onSelectionChanged)="onDocSelectionChanged($event)"
  ></ui-table>

  <ng-template #docName let-row="row">
    <div *ngIf="!docsBeingEdited[row.origDocId]" class="l-align-spaceBetween">
      <span style="margin-right: 1rem; word-break: break-all;">{{ row.docName }}</span>
      <a class="icon" (click)="onClickEditDocName($event, row)">
        <i class="fa-solid fa-pen-to-square" [tooltip]="'translate.general.renameDoc' | translate"></i>
      </a>
    </div>
  
    <div *ngIf="docsBeingEdited[row.origDocId]" class="l-align-spaceBetween">
      <input 
        type="text"
        [(ngModel)]="docsBeingEdited[row.origDocId].docName" 
        class="t-input" style="margin-right: 1rem;" 
        (click)="$event.stopPropagation()" 
        [disabled]="docsBeingUpdated[row.origDocId]" />
      <a *ngIf="!docsBeingUpdated[row.origDocId]" class="icon" style="margin-right: 1rem;" (click)="onClickUpdateDocName($event, row.origDocId)">
        <i class="fa-solid fa-check" [tooltip]="'translate.general.saveChanges' | translate"></i>
      </a>
  
      <a *ngIf="!docsBeingUpdated[row.origDocId]" class="icon" (click)="onClickCancelEditDocName($event, row.origDocId)">
        <i class="fa-solid fa-xmark" [tooltip]="'translate.general.cancel' | translate"></i>
      </a>
  
      <a *ngIf="docsBeingUpdated[row.origDocId]" class="icon">
        <i class="fa-solid fa-spinner"></i>
      </a>
    </div>
  </ng-template>

  <ng-template #assetType let-row="row">
    <span *ngIf="row.assetType">{{ row.assetType.text }}</span>
  </ng-template>

  <!-- status -->
  <ng-template #status let-row="row">
    <p
      *ngIf="!row.isProcessRunning"
      [class.fw-bold]="
        row.statusCode == 'DRAFT' || row.statusCode == 'ADDED_TO_CATALOG'
      "
      [class.c-success]="row.statusCode == 'ADDED_TO_CATALOG'"
    >
      {{ row.statusDescr }}
    </p>

    <p *ngIf="row.runStatusCode == 'FAILED'" class="processing-status failed">
      {{ "translate.general.processingFailed" | translate }}
      &nbsp;
      <i
        *ngIf="row.runStatusMessage"
        class="fa fa-exclamation-circle icon"
        [tooltip]="row.runStatusMessage"
      >
      </i>
    </p>

    <div *ngIf="row.isProcessRunning">
      <div *ngIf="row.processStatus" (click)="openProcessStatus(row)">
        <circle-progress
          [percent]="row.processStatus.completedPct"
        ></circle-progress>
      </div>

      <spinner *ngIf="!row.processStatus"></spinner>

      <p class="processing-status">
        <processing-label></processing-label>
      </p>
    </div>
  </ng-template>

  <ng-template #progress let-row="row">
    <span>{{ row.progressPct | percent }}</span>
  </ng-template>

  <ng-template #actions let-row="row">
    <div class="actions-container">
      <div *ngIf="row.runStatusCode == 'FINISHED'">
        <a (click)="$event.stopPropagation(); view(row)">{{
          "translate.general.view" | translate
        }}</a>
      </div>

      <div *ngIf="allowEdit && allowAddVersion(row)">
        <span *ngIf="row.runStatusCode == 'FINISHED'" class="action-divider"
          >|</span
        >
        <a
          (click)="
            $event.stopPropagation();
            createVersionParentDoc = row;
            displayAddCatDoc = true
          "
          >{{ "translate.cat.addNewVersion" | translate }}</a
        >
      </div>

      <div *ngIf="allowEdit && allowDetach(row)">
        <span class="action-divider">|</span>
        <a (click)="$event.stopPropagation(); detach(row)">{{
          "translate.cat.detach" | translate
        }}</a>
      </div>

      <div *ngIf="allowEdit && allowReopen(row)">
        <span class="action-divider">|</span>

        <a
          *ngIf="allowEdit && allowReopen(row)"
          (click)="$event.stopPropagation(); reopen(row)"
          >{{ "translate.cat.reopen" | translate }}</a
        >
      </div>
    </div>
  </ng-template>

  <ng-template #icons let-row="row">
    <i
      *ngIf="row.taggedSw"
      class="fas fa-check c-grey-accent"
      [tooltip]="'translate.tagged.taggedDoc' | translate"
    ></i>
  </ng-template>

  <ng-template #filterArea>
    <div class="filter-bar l-align-spaceBetween">
      <div class="filter-container">
        <!--filterDocIdName-->
        <input-wrap class="t-input-search-wrap">
          <input
            class="t-input"
            type="text"
            [(ngModel)]="filterDocIdName"
            placeholder="{{ 'translate.general.filterDocIdName' | translate }}"
            (keypress)="onFilterKeyPress($event)"
          />
        </input-wrap>

        <!--status-->
        <input-wrap [loading]="catDocStatusList && catDocStatusList.loading">
          <p-dropdown
            [options]="catDocStatusList"
            optionLabel="label"
            [(ngModel)]="filterStatus"
            [resetFilterOnHide]="true"
            [filterPlaceholder]="'translate.general.search' | translate"
            [placeholder]="'translate.general.statusDescr' | translate"
            [filter]="true"
            (onChange)="onFieldValueChanged($event)"
          >
          </p-dropdown>
        </input-wrap>

        <!--inactive-->
        <checkbox-wrap
          class="checkbox"
          [label]="'translate.general.includeInactive' | translate"
        >
          <input
            type="checkbox"
            [(ngModel)]="filterInactive"
            (ngModelChange)="onFieldValueChanged()"
          />
        </checkbox-wrap>

        <a *ngIf="displayClearFilter" (click)="clearFilter()">{{
          "translate.general.clear" | translate
        }}</a>
      </div>

      <div *ngIf="allowEdit" class="l-align-center">
        <button
          class="t-button"
          [disabled]="loading || curCat.isMaster"
          (click)="createVersionParentDoc = null; displayAddCatDoc = true"
        >
          {{ "translate.cat.addDocument" | translate }}
        </button>
        &nbsp; &nbsp;
        <button
          class="t-button-outline"
          [disabled]="loading || curCat.isMaster"
          (click)="createVersionParentDoc = null; displayAddBulkDoc = true"
        >
          {{ "translate.cat.addBulkDocuments" | translate }}
        </button>
        <div *ngIf="allowTagDocs">
          &nbsp;
          <button
            class="t-button-outline"
            [disabled]="loading || curCat.isMaster"
            (click)="tagDocs()"
          >
            {{ "translate.tagged.tagDocs" | translate }}
          </button>
        </div>
      </div>

      <div *ngIf="selectMode">
        <button
          class="t-button"
          (click)="docsSelected()"
          [disabled]="selectedDocs.length == 0"
        >
          {{ "translate.general.addSelectedDocs" | translate }}
          ({{ selectedDocs.length }})
        </button>
        &nbsp;
        <button
          class="t-button-outline"
          [disabled]="loading"
          (click)="createVersionParentDoc = null; displayAddBulkDoc = true"
        >
          {{ "translate.cat.addNewDocuments" | translate }}
        </button>
      </div>
    </div>
  </ng-template>

  <ng-template #expandRow let-row="row">
    <ui-table
      #tblVersions
      [checkboxSelection]="allowVersionSelect"
      class="t-small"
      [isWrapped]="false"
      [data]="row.histDocs"
      [displayFilter]="['versionId', 'fileName', 'createDate']"
      [templates]="[
        {
          title: 'translate.general.statusDescr',
          template: status
        },
        {
          title: 'translate.general.actions',
          template: histActions
        },
        {
          template: icons,
          permission: allowTagDocs
        }
      ]"
      (onSelectionChanged)="onVersionSelectionChanged($event)"
    ></ui-table>
  </ng-template>

  <ng-template #histActions let-row="row">
    <a *ngIf="row.runStatusCode == 'FINISHED'" (click)="view(row)">{{
      "translate.general.view" | translate
    }}</a>
  </ng-template>
</div>

<create-cat-doc
  *ngIf="displayAddCatDoc"
  (onClose)="displayAddCatDoc = false"
  [parentDoc]="createVersionParentDoc"
  (onDocCreated)="onDocCreated($event)"
></create-cat-doc>

<create-bulk-cat-doc
  *ngIf="displayAddBulkDoc"
  [createDocType]="createDocType"
  (onClose)="displayAddBulkDoc = false"
  (onDocCreated)="onDocCreated($event)"
  (onAllDocsCreated)="onAllDocsCreated($event)"
></create-bulk-cat-doc>

<process-run-status
  *ngIf="displayProcessStatus"
  (onClose)="displayProcessStatus = false"
  [doc]="curDoc"
  [status]="curDoc.processStatus"
>
</process-run-status>
