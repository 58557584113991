import { Component, OnInit, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'tab-single',
  template: `

  <ng-container *ngIf="! isActive else tabTemplate"></ng-container>

  <ng-template #tabTemplate>
    <div class="l-contents t-tabPanel" [attr.id]="'tabContent-' + tabId" role="tabpanel">
      <ng-content></ng-content>
    </div>
  </ng-template>
  `
} )
export class TabSingleComponent implements OnInit {

  constructor() {}

  @Input() tabId: string;
  @Input() tabTitle: string;
  @Input() tabBadgeCounter: number;
  @Input() tabBadgeColor: string;
  @Input() tabClass: string;
  @Input() icon: string;
  @Input() disabled: boolean;
  @Input() link: string;
  @Input() hFull: boolean;
  @Input() subMenu: TemplateRef<any>;
  @Input() onSelect: Function;
  @Input() onUnSelect: Function;
  @Input() onMouseEnter: Function;
  @Input() onMouseLeave: Function;

  isActive: boolean = false;

  ngOnInit() {}

}
