<modal-popup
  [flexible]="true"
  [focusOnOpen]="true"
  [isOpen]="true"
  (onClose)="close()"
  title="{{ 'translate.cat.addSpellingCheckIgnored' | translate }}"
>
  <div class="container">
    <div>
      <input-wrap
        label="{{ 'translate.general.spellingCheckIgnoredWord' | translate }}"
        cssClass="l-cell-spread"
        [required]="true"
      >
      <input
        type="text"
        [(ngModel)]="spellingCheckIgnoredWord"
        class="t-input"
      />
      </input-wrap>

      <div class="t-panel-cell t-buttonSet l-align-end l-mar-top">
        <button
          [disabled]="!spellingCheckIgnoredWord"
          (click)="add()"
          class="t-button-outline"
        >
          {{ "translate.general.add" | translate }}
        </button>
      </div>
    </div>
  </div>
</modal-popup>
