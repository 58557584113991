import { NgRedux } from '@redux/redux-compatibility.service';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
  BoundingBox,
  Card,
  CardSearchParams,
} from 'src/app/shared/models/cat.model';
import {
  CombinedCard,
  LabelChange,
  LCCardTypeEnum,
  LCDoc,
  LCSearchParams,
  LCStats,
} from 'src/app/shared/models/lc.model';
import { actionsList, AppState } from 'src/app/shared/redux/store';
import { AuthService } from 'src/app/shared/services/auth.service';
import { CatService } from 'src/app/shared/services/cat.service';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'lc-doc-cards',
  templateUrl: './lc-doc-cards.component.html',
  styleUrls: ['./lc-doc-cards.component.scss'],
})
export class LcDocCardsComponent implements OnInit {
  @Input() type: 'ac' | 'bc';
  @Input() doc: LCDoc;
  @Input() cardType: LCCardTypeEnum;
  @Input() title: string;

  cards: Card[] = [];
  bb: BoundingBox[] = [];

  get lc(): LabelChange {
    return this.ngRedux.getState().curLabelChange;
  }

  constructor(
    private ngRedux: NgRedux<AppState>,
    private catService: CatService,
    private router: Router,
    private sharedService: SharedService,
    private authService: AuthService,
    private translateService: TranslateService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.getData();
  }

  getData() {
    this.cards = this.lc.cards.filter((c) => c.lcCardType.id == this.cardType);

    this.cards.forEach((c) => {
      c.cardDocs
        .filter((d) => (this.doc ? d.docId == this.doc.docId : true))
        .forEach((d) => {
          this.bb = this.bb.concat(d.boundingBoxes);
        });
    });

    let bbKeys = [];
    this.bb.forEach((bb) => bbKeys.push(bb.s3Key));
    this.catService.getS3Objects(bbKeys).subscribe();
  }
}
