<modal-popup
  [flexible]="true"
  [focusOnOpen]="false"
  [isOpen]="true"
  (onClose)="close()"
  [title]="'translate.cat.addToAltRef'"
>
  <div class="container">
    <div class="list-container t-small">
      <cat-alt-ref
        [selectMode]="true"
        (onRowSelected)="onRowSelected($event)"
      ></cat-alt-ref>
    </div>

    <div class="actions">
      <button class="t-button-outline" (click)="close()">
        {{ "translate.general.cancel" | translate }}
      </button>
      &nbsp;
      <button
        class="t-button"
        [disabled]="!enableSubmit || saving"
        (click)="save()"
      >
        {{ "translate.general.ok" | translate }}
      </button>
    </div>
  </div>

  <loader *ngIf="saving"></loader>
</modal-popup>
