import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { BoundingBox } from '../../models/cat.model';
import { Doc } from '../../models/common';
import { DocToolbarComponent } from './doc-toolbar/doc-toolbar.component';

@Component({
  selector: 'doc-viewer',
  templateUrl: './doc-viewer.component.html',
  styleUrls: ['./doc-viewer.component.scss'],
})
export class DocViewerComponent implements OnInit {
  @Input() doc: Doc;
  @Input() initialPage: number;
  @Input() displayPaging: boolean = true;
  @Input() displayDrawMode: boolean;
  @Input() displayToggleBoundingBoxes: boolean;
  @Input() displayRefreshingIcon: boolean;

  @Output() onPageChanged = new EventEmitter<number>();
  @Output() onToggleAnnotations = new EventEmitter<boolean>();
  @Output() onToggleBoundingBoxes = new EventEmitter<boolean>();
  @Output() onIframeLoaded = new EventEmitter();
  @Output() onBoundingBoxDraw = new EventEmitter<BoundingBox>();
  @Output() onBoundingBoxClicked = new EventEmitter<String>();
  @Output() onAnnClicked = new EventEmitter<number>();
  @Output() onAnnMouseEnter = new EventEmitter<number>();
  @Output() onAnnMouseLeave = new EventEmitter<number>();

  @ViewChild('iframe') iframe: ElementRef;
  @ViewChild('docToolbar') docToolbar: DocToolbarComponent;

  iFrameUrl;
  curPageUrl: String;

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit() {
    this.curPageUrl = this.doc.pages.find(
      (p) => p.pageNum == this.initialPage
    ).url;
    //this.curPageUrl = '../../../../assets/mock/html/brochure1_1_0.0.html';
    //pageUrl = "../../../../assets/mock/html/wide.html";
    this.setPageUrl(this.curPageUrl);
  }

  setPageUrl(url: String, useDummy: boolean = false) {
    this.iFrameUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
      url + (useDummy ? '?dummyVar=' + new Date().getTime() : '')
    );
  }

  ngAfterViewInit(): void {
    var eventMethod = window.addEventListener
      ? 'addEventListener'
      : 'attachEvent';
    var eventer = window[eventMethod];
    var messageEvent = eventMethod == 'attachEvent' ? 'onmessage' : 'message';

    // Listen to message from child window
    eventer(
      messageEvent,
      (e) => {
        let eventType = e.data.eventType;
        if (eventType == 'IFRAME_LOADED') {
          this.onIframeLoaded.emit();
          this.fitToPage();
        } else if (eventType == 'BOX_DRAW') {
          let box = new BoundingBox();
          box.x1 = e.data.box.x1;
          box.y1 = e.data.box.y1;
          box.x2 = e.data.box.x2;
          box.y2 = e.data.box.y2;
          box.elementId = e.data.id;
          if (e.data.box.pageHeightPx) {
            box.pageHeight = Number.parseInt(
              e.data.box.pageHeightPx.replace('px', '')
            );
            box.pageWidth = Number.parseInt(
              e.data.box.pageWidthPx.replace('px', '')
            );
          }
          this.onBoundingBoxDraw.emit(box);
          this.onToggleDrawMode(false);
          this.docToolbar.toggleDrawModeSw = false;
        } else if (eventType == 'BOUNDING_BOX_CLICKED') {
          console.log(e);
          this.onBoundingBoxClicked.emit(e.data.elementId);
        } else if (eventType == 'ANN_CLICKED') {
          this.onAnnClicked.emit(e.data.ruleId);
        } else if (eventType == 'ANN_MOUSE_ENTER') {
          this.onAnnMouseEnter.emit(e.data.ruleId);
        } else if (eventType == 'ANN_MOUSE_LEAVE') {
          this.onAnnMouseLeave.emit(e.data.ruleId);
        }
      },
      false
    );
  }

  toggleElements(hideElements: string[], displayElements: string[]) {
    var doc = this.iframe.nativeElement.contentWindow;
    doc.postMessage(
      {
        eventType: 'TOGGLE_ELEMENTS',
        displayElements: displayElements,
        hideElements: hideElements,
      },
      '*'
    );
  }

  onPageSelected(page: number) {
    this.onPageChanged.emit(page);
    this.changePage(page);
  }

  changePage(page: number) {
    this.docToolbar.curPageNum = page;
    this.curPageUrl = this.doc.pages.find((p) => p.pageNum == page).url;
    this.setPageUrl(this.curPageUrl);
  }

  zoomOut() {
    var doc = this.iframe.nativeElement.contentWindow;
    doc.postMessage(
      {
        eventType: 'ZOOM_OUT',
      },
      '*'
    );
  }

  zoomIn() {
    var doc = this.iframe.nativeElement.contentWindow;
    doc.postMessage(
      {
        eventType: 'ZOOM_IN',
      },
      '*'
    );
  }

  fitToWidth() {
    var doc = this.iframe.nativeElement.contentWindow;
    doc.postMessage(
      {
        eventType: 'FIT_TO_WIDTH',
        width: this.iframe.nativeElement.clientWidth,
        height: this.iframe.nativeElement.clientHeight,
      },
      '*'
    );
  }

  fitToPage() {
    var doc = this.iframe.nativeElement.contentWindow;
    if (doc)
      doc.postMessage(
        {
          eventType: 'FIT_PAGE',
          width: this.iframe.nativeElement.clientWidth,
          height: this.iframe.nativeElement.clientHeight,
        },
        '*'
      );
  }

  toggleAnnotations(viewSw: boolean) {
    this.onToggleAnnotations.emit(viewSw);
  }

  toggleBoundingBoxes(viewSw: boolean) {
    this.onToggleBoundingBoxes.emit(viewSw);
  }

  onToggleDrawMode(drawModeSw: boolean) {
    var doc = this.iframe.nativeElement.contentWindow;
    if (doc)
      doc.postMessage(
        {
          eventType: 'TOGGLE_DRAW_MODE',
          drawModeSw: drawModeSw,
        },
        '*'
      );
  }

  removeBoundingBox(box: BoundingBox) {
    var doc = this.iframe.nativeElement.contentWindow;
    if (doc)
      doc.postMessage(
        {
          eventType: 'REMOVE_BOUNDING_BOX',
          elementId: box.elementId,
        },
        '*'
      );
  }

  displayBoundingBox(box: BoundingBox) {
    var doc = this.iframe.nativeElement.contentWindow;
    let tooltip = '<strong>' + box.boxType.text + '</strong>';
    if (box.descr) {
      tooltip += '<br>' + (box.descr || '');
    }

    if (doc)
      doc.postMessage(
        {
          eventType: 'DRAW_BOUNDING_BOX',
          elementId: box.elementId,
          x1: box.x1,
          y1: box.y1,
          x2: box.x2,
          y2: box.y2,
          tooltip: tooltip,
        },
        '*'
      );
  }

  reloadDoc() {
    this.setPageUrl(this.curPageUrl, true);
  }
}
