import { NgRedux } from '@redux/redux-compatibility.service';
import { Location } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Unsubscribe } from 'redux';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';
import {
  Card,
  Catalog,
  CatalogStatusEnum,
  McSubType,
} from 'src/app/shared/models/cat.model';
import { DataElement } from 'src/app/shared/models/common';
import { RouteEnum } from 'src/app/shared/models/routes.model';
import { Tab, TabIdList } from 'src/app/shared/models/tab.model';
import { actionsList, AppState } from 'src/app/shared/redux/store';
import { AuthService } from 'src/app/shared/services/auth.service';
import { CatService } from 'src/app/shared/services/cat.service';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent implements OnInit {
  loading: boolean;
  tabIdList: TabIdList;
  tabs: Tab[];
  editHeaderMode: boolean;
  mcSw: boolean;
  displayMcSubTypes: DataElement[] = [];
  un: Unsubscribe;

  get card(): Card {
    return this.ngRedux.getState().curCard;
  }

  get isNew(): boolean {
    return this.card.cardId == null;
  }

  get cardTypes(): DataElement[] {
    return this.ngRedux.getState().cardTypes;
  }

  get mcTypes(): DataElement[] {
    return this.ngRedux.getState().mcTypes;
  }

  get allMcSubTypes(): McSubType[] {
    return this.ngRedux.getState().mcSubTypes;
  }

  get curCat(): Catalog {
    return this.ngRedux.getState().curCat;
  }

  get allowEdit(): boolean {
    if (!this.curCat) return false;
    return this.curCat.statusCode == CatalogStatusEnum.DRAFT;
  }

  constructor(
    private ngRedux: NgRedux<AppState>,
    private catService: CatService,
    private sharedService: SharedService,
    private router: Router,
    private translateService: TranslateService,
    private location: Location,
    private route: ActivatedRoute,
    private cd: ChangeDetectorRef
  ) {
    this.tabIdList = this.ngRedux.getState().ui_tabIdList;

    // this.un = this.ngRedux.subscribe(() => {
    //   const appState = this.ngRedux.getState();
    //   switch (appState._currentAction) {
    //     case actionsList.CARD_TRANSLATE_FINISHED:
    //       if (
    //         !this.tabs.find((t) => t.tabId == this.tabIdList.CAT__CARDS__TRANS)
    //       ) {
    //         this.addTransTab();
    //       }
    //       break;
    //   }
    // });
  }

  ngOnInit(): void {
    if (this.card.type.id == 'MODULAR_CONTENT') {
      this.mcSw = true;
      if (this.card.mcType) this.onMcTypeChanged(false);
    }

    this.card.saveOriginal();
    this.tabs = [];

    this.tabs.push({
      tabId: this.tabIdList.CAT__CARDS__SUMMARY,
      tabTitle: 'translate.cat.cardBoundingBoxes',
      link: this.sharedService.routeEnum.URL_CATALOG_CARD_SUMMARY,
      isHidden: false,
    });

    if (this.card.transLang.length > 0) {
      this.addTransTab();
    }
  }

  ngOnDestroy(): void {
    if (this.un) this.un();
  }

  addTransTab() {
    this.tabs.push({
      tabId: this.tabIdList.CAT__CARDS__TRANS,
      tabTitle: 'translate.cat.cardTrans',
      link: this.sharedService.routeEnum.URL_CATALOG_CARD_TRANS,
      isHidden: false,
    });
  }

  goBack() {
    this.ngRedux.dispatch({
      type: actionsList.SELECT_CAT_CARD,
      data: null,
    });

    if (
      this.router.url ==
      '/' + this.sharedService.routeEnum.URL_CATALOG_CARD_TRANS
    ) {
      this.router.navigate([this.sharedService.routeEnum.URL_CATALOG_CARDS]);
    } else {
      this.location.back();
    }
  }

  validateBeforeSave(): boolean {
    return (
      this.card.title &&
      this.card.type != null &&
      (this.card.type.id != 'MODULAR_CONTENT' ||
        (this.card.mcType != null &&
          (this.displayMcSubTypes.length == 0 || this.card.mcSubType != null)))
    );
  }

  save() {
    this.loading = true;
    this.catService.updateCard(this.card).subscribe(
      (data) => {
        this.card.saveOriginal();
        this.editHeaderMode = false;
        this.loading = false;
      },
      (err) => (this.loading = false)
    );
  }

  deleteClicked() {
    let validationStr = this.translateService.instant(
      'translate.cat.deleteCardValidation'
    );

    this.sharedService.emitPopupchReceivedEvent({
      title: 'translate.validation.confirm',
      icon: 'exclamation-triangle',
      subTitle: validationStr,
      keep: false,
      approve: () => {
        this.delete();
      },
    });
  }

  delete() {
    this.loading = true;
    this.catService.updateCard(this.card, true).subscribe(
      (data) => {
        this.loading = false;
        this.goBack();
      },
      (err) => (this.loading = false)
    );
  }

  isDirty(): boolean {
    return this.card.isDirty();
  }

  discardChanges() {
    this.card.restoreOriginal();
    this.editHeaderMode = false;
  }

  onCardTypeChanged() {
    if (this.card.type.id == 'MODULAR_CONTENT') {
      this.mcSw = true;
    } else {
      this.mcSw = false;
      this.card.mcSubType = null;
      this.card.mcType = null;
    }
  }

  onMcTypeChanged(clearSubType: boolean = true) {
    this.displayMcSubTypes = this.allMcSubTypes
      .filter((r) => r.mcTypeCode == this.card.mcType.id)
      .map((e) => e.subType);
    if (clearSubType) this.card.mcSubType = null;
  }
}
