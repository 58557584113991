import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { LayoutComponent } from './layout/layout.component';
import { AuthService } from './shared/services/auth.service';
import { RevDocListComponent } from './rev-doc-list/rev-doc-list.component';
import { CanDeactivateGuardService } from './shared/services/can-deactivate-guard.service';
import { RevDocComponent } from './rev-doc/rev-doc.component';
import { CatalogListComponent } from './catalog-list/catalog-list.component';
import { CatalogComponent } from './catalog/catalog.component';
import { CatDocsComponent } from './catalog/cat-docs/cat-docs.component';
import { CatEntriesComponent } from './catalog/cat-entries/cat-entries.component';
import { CatDocComponent } from './cat-doc/cat-doc.component';
import { MngCatPhraseTypesComponent } from './catalog/mng-cat-phrase-types/mng-cat-phrase-types.component';
import { MngEnvComponent } from './manage/mng-env/mng-env.component';
import { MngUsersComponent } from './manage/mng-users/mng-users.component';
import { MngProductsComponent } from './manage/mng-products/mng-products.component';
import { CardsComponent } from './catalog/cards/cards.component';
import { CardComponent } from './catalog/cards/card/card.component';
import { MngCatAssetTypesComponent } from './catalog/mng-cat-asset-types/mng-cat-asset-types.component';
import { MngAssetTypesComponent } from './manage/mng-asset-types/mng-asset-types.component';
import { CatImagesComponent } from './catalog/cat-images/cat-images.component';
import { CardSummaryComponent } from './catalog/cards/card/card-summary/card-summary.component';
import { MngTaggedDocsComponent } from './manage/mng-tagged-docs/mng-tagged-docs.component';
import { LabelChangeComponent } from './lc/label-change/label-change.component';
import { LcDetailsComponent } from './lc/lc-details/lc-details.component';
import { SelectLCCatComponent } from './lc/add-docs/select-cat/select-cat.component';
import { SelectLCDocsComponent } from './lc/add-docs/select-docs/select-docs.component';
import { LcDocsComponent } from './lc/lc-details/lc-docs/lc-docs.component';
import { LcCardsComponent } from './lc/lc-details/lc-cards/lc-cards.component';
import { LcPhrasesComponent } from './lc/lc-details/lc-phrases/lc-phrases.component';
import { SelectPhrasesComponent } from './lc/lc-details/lc-phrases/select-phrases/select-phrases.component';
import { SelectLcCardsComponent } from './lc/lc-details/lc-cards/select-lc-cards/select-lc-cards.component';
import { CardAssetTemplatesComponent } from './catalog/card-asset-templates/card-asset-templates.component';
import { CardAssetTemplateDetailsComponent } from './catalog/card-asset-templates/card-asset-template-details/card-asset-template-details.component';
import { CardTransComponent } from './catalog/cards/card/card-trans/card-trans.component';
import { CatExtLibsComponent } from './catalog/cat-ext-libs/cat-ext-libs.component';
import { CatExtRefsComponent } from './catalog/cat-ext-refs/cat-ext-refs.component';
import { DirectlinkComponent } from './directlink/directlink.component';
import { DlRevAnnComponent } from './directlink/dl-rev-ann/dl-rev-ann.component';
import { CatAltRefComponent } from './catalog/cat-alt-ref/cat-alt-ref.component';
import { SpellingCheckIgnoredComponent } from './catalog/spelling-check-ignored/spelling-check-ignored.component';

const initialLang = 'en';
const routes: Routes = [
  // { path: 'dl', component: DirectlinkComponent },
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      lang: initialLang,
      resetReuseStrategy: true,
      max: 0,
      handles: {},
      handleKeys: [],
    },
  },
  {
    path: 'main',
    component: LayoutComponent,
    canActivate: [AuthService],
    children: [
      {
        path: '',
        redirectTo: 'rev-doc-list',
        pathMatch: 'full',
        //canActivate: [AuthService],
      },
      {
        path: 'rev-doc-list',
        component: RevDocListComponent,
        canActivate: [AuthService],
        canDeactivate: [CanDeactivateGuardService],
        data: {
          reuse: false,
          max: 5,
          handles: {},
          handleKeys: [],
        },
      },
      {
        path: 'rev-doc',
        component: RevDocComponent,
        canActivate: [AuthService],
        canDeactivate: [CanDeactivateGuardService],
        data: {
          reuse: false,
          max: 0,
          handles: {},
          handleKeys: [],
        },
      },
      {
        path: 'catalog-list',
        component: CatalogListComponent,
        canActivate: [AuthService],
        canDeactivate: [CanDeactivateGuardService],
        data: {
          reuse: 'catalog-list',
          max: 5,
          handles: {},
          handleKeys: [],
        },
      },
      {
        path: 'catalog-list/:cat',
        component: CatalogListComponent,
        canActivate: [AuthService],
        canDeactivate: [CanDeactivateGuardService],
        data: {
          reuse: 'catalog-list',
          max: 5,
          handles: {},
          handleKeys: [],
        },
      },
      {
        path: 'catalog',
        component: CatalogComponent,
        canActivate: [AuthService],
        canDeactivate: [CanDeactivateGuardService],
        data: {
          reuse: false,
          max: 5,
          handles: {},
          handleKeys: [],
        },
        children: [
          {
            path: '',
            redirectTo: 'docs',
            pathMatch: 'full',
          },
          {
            path: 'entries',
            component: CatEntriesComponent,
            data: {
              reuse: false,
              max: 5,
              handles: {},
              handleKeys: [],
            },
          },
          {
            path: 'images',
            component: CatImagesComponent,
            data: {
              reuse: false,
              max: 5,
              handles: {},
              handleKeys: [],
            },
          },
          {
            path: 'docs',
            component: CatDocsComponent,
            data: {
              reuse: false,
              max: 5,
              handles: {},
              handleKeys: [],
            },
          },
          {
            path: 'mng-phrase-types',
            component: MngCatPhraseTypesComponent,
            data: {
              reuse: false,
              max: 5,
              handles: {},
              handleKeys: [],
            },
          },
          {
            path: 'mng-cat-asset-types',
            component: MngCatAssetTypesComponent,
            data: {
              reuse: false,
              max: 5,
              handles: {},
              handleKeys: [],
            },
          },
          {
            path: 'spelling-check-ignored',
            component: SpellingCheckIgnoredComponent,
            data: {
              reuse: false,
              max: 5,
              handles: {},
              handleKeys: [],
            },
          },
          {
            path: 'cards',
            component: CardsComponent,
            data: {
              reuse: false,
              max: 5,
              handles: {},
              handleKeys: [],
            },
          },
          {
            path: 'card-asset-templates',
            component: CardAssetTemplatesComponent,
            data: {
              reuse: false,
              max: 5,
              handles: {},
              handleKeys: [],
            },
          },
          {
            path: 'card-asset-template-det',
            component: CardAssetTemplateDetailsComponent,
            data: {
              reuse: false,
              max: 5,
              handles: {},
              handleKeys: [],
            },
          },
          {
            path: 'ext-libs',
            component: CatExtLibsComponent,
            data: {
              reuse: false,
              max: 5,
              handles: {},
              handleKeys: [],
            },
          },
          {
            path: 'ext-refs',
            component: CatExtRefsComponent,
            data: {
              reuse: false,
              max: 5,
              handles: {},
              handleKeys: [],
            },
          },
          {
            path: 'alt-ref',
            component: CatAltRefComponent,
            data: {
              reuse: false,
              max: 5,
              handles: {},
              handleKeys: [],
            },
          },
          {
            path: 'card',
            component: CardComponent,
            data: {
              reuse: false,
              max: 5,
              handles: {},
              handleKeys: [],
            },
            children: [
              {
                path: '',
                redirectTo: 'summary',
                pathMatch: 'full',
              },
              {
                path: 'summary',
                component: CardSummaryComponent,
                data: {
                  reuse: false,
                  max: 1,
                  handles: {},
                  handleKeys: [],
                },
              },
              {
                path: 'trans',
                component: CardTransComponent,
                data: {
                  reuse: false,
                  max: 1,
                  handles: {},
                  handleKeys: [],
                },
              },
            ],
          },
        ],
      },
      {
        path: 'cat-doc',
        component: CatDocComponent,
        canActivate: [AuthService],
        canDeactivate: [CanDeactivateGuardService],
        data: {
          reuse: false,
          max: 0,
          handles: {},
          handleKeys: [],
        },
      },
      {
        path: 'mng',
        children: [
          {
            path: '',
            redirectTo: 'users',
            pathMatch: 'full',
          },
          {
            path: 'users',
            component: MngUsersComponent,
            data: {
              reuse: false,
              max: 5,
              handles: {},
              handleKeys: [],
            },
          },
          {
            path: 'env',
            component: MngEnvComponent,
            data: {
              reuse: false,
              max: 5,
              handles: {},
              handleKeys: [],
            },
          },
          {
            path: 'products',
            component: MngProductsComponent,
            data: {
              reuse: false,
              max: 5,
              handles: {},
              handleKeys: [],
            },
          },
          {
            path: 'asset-types',
            component: MngAssetTypesComponent,
            data: {
              reuse: false,
              max: 5,
              handles: {},
              handleKeys: [],
            },
          },
          {
            path: 'tagged-docs',
            component: MngTaggedDocsComponent,
            data: {
              reuse: false,
              max: 5,
              handles: {},
              handleKeys: [],
            },
          },
        ],
      },
      {
        path: 'lc-list',
        component: LabelChangeComponent,
        canActivate: [AuthService],
        canDeactivate: [CanDeactivateGuardService],
        data: {
          reuse: false,
          max: 5,
          handles: {},
          handleKeys: [],
        },
      },
      {
        path: 'lc',
        component: LcDetailsComponent,
        canActivate: [AuthService],
        canDeactivate: [CanDeactivateGuardService],
        data: {
          reuse: false,
          max: 5,
          handles: {},
          handleKeys: [],
        },
        children: [
          {
            path: 'lc-docs/:type',
            component: LcDocsComponent,
            data: {
              reuse: false,
              max: 1,
              handles: {},
              handleKeys: [],
            },
          },
          {
            path: 'lc-cards/:type',
            component: LcCardsComponent,
            data: {
              reuse: false,
              max: 1,
              handles: {},
              handleKeys: [],
            },
          },
          {
            path: 'lc-phrases/:type',
            component: LcPhrasesComponent,
            data: {
              reuse: false,
              max: 0,
              handles: {},
              handleKeys: [],
            },
          },
          {
            path: 'select-docs/:type',
            component: SelectLCDocsComponent,
            data: {
              reuse: false,
              max: 1,
              handles: {},
              handleKeys: [],
            },
          },
          {
            path: 'cat-doc/:mode',
            component: CatDocComponent,
            canActivate: [AuthService],
            canDeactivate: [CanDeactivateGuardService],
            data: {
              reuse: false,
              max: 0,
              handles: {},
              handleKeys: [],
            },
          },
          {
            path: 'select-phrases/:type',
            component: SelectPhrasesComponent,
            data: {
              reuse: false,
              max: 1,
              handles: {},
              handleKeys: [],
            },
          },
          {
            path: 'select-cards/:type',
            component: SelectLcCardsComponent,
            data: {
              reuse: false,
              max: 1,
              handles: {},
              handleKeys: [],
            },
          },
        ],
      },
      {
        path: 'ext-cat-list',
        component: CatalogListComponent,
        canActivate: [AuthService],
        canDeactivate: [CanDeactivateGuardService],
        data: {
          reuse: 'ext-cat-list',
          max: 5,
          handles: {},
          handleKeys: [],
        },
      },
      {
        path: 'dl-rev-ann',
        component: DlRevAnnComponent,
        canActivate: [AuthService],
        canDeactivate: [CanDeactivateGuardService],
        data: {
          reuse: false,
          max: 5,
          handles: {},
          handleKeys: [],
        },
      },
    ],
  },
  { path: '**', redirectTo: 'login' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
