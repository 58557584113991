<div [translate] class="login-container">
  <div id="content" class="login-box">
    <div class="l-align-x login-panel">
      <spinner *ngIf="showSpinner"></spinner>
      <section class="t-widget login-box" *ngIf="showLogin">
        <!-- <h1 class="l-align-x login-top">{{ 'translate.login.appName' | translate }}</h1> -->

        <div class="l-align-x login-top logo">
          <img src="assets\img\logo.png" />
        </div>

        <div
          aria-labelledby="login_details_label"
          autocomplete="off"
          class="login-details"
        >
          <div class="t-row--med">
            <input
              #userNameInput
              name="userName"
              type="text"
              [(ngModel)]="typedUserName"
              class="t-row--med t-input t-input--colored"
              placeholder="{{ 'translate.login.userName' | translate }}"
              attr.aria-label="{{ 'translate.login.userName' | translate }}"
            />

            <div class="l-pos-relative t-row--med">
              <input
                #loginIputPassword
                name="password"
                type="password"
                [(ngModel)]="password"
                class="l-cell-spread-x t-input t-input--colored"
                placeholder="{{
                  'translate.login.passwordPlaceholder' | translate
                }}"
                attr.aria-label="{{
                  'translate.login.passwordPlaceholder' | translate
                }}"
                (keyup)="allowShowPassBtn = true"
              />

              <ng-container *ngIf="allowShowPassBtn">
                <a
                  tabIndex="0"
                  class="l-align-center h-full c-grey-med login-btn-showPassword"
                  (click)="showPassword($event)"
                >
                  <fa-icon [icon]="['fas', 'eye']"></fa-icon>
                </a>
              </ng-container>
            </div>

            <div class="remember-me-container l-align-spaceBetween t-small">
              <checkbox-wrap
                label="{{ 'translate.login.rememberMe' | translate }}"
                *ngIf="isShowRememberMe"
              >
                <input
                  type="checkbox"
                  name="rememberMe"
                  [(ngModel)]="rememberMe"
                />
              </checkbox-wrap>

              <div class="t-buttonSet--med login-password-forgot" *ngIf="!isShowRememberMe">&nbsp;</div>

              <div class="t-buttonSet--med login-password-forgot">
                <a class="t-small fw-bold" (click)="forgotPassword()">
                  {{ "translate.login.forgotPassword" | translate }}
                </a>
              </div>
            </div>
          </div>

          <button
            (click)="login($event)"
            class="login-btn t-button t-button--flat l-ta-center"
          >
            {{ "translate.login.loginButton" | translate }}
          </button>
        </div>
      </section>
    </div>
  </div>
  <custom-popup></custom-popup>
  <loader *ngIf="generalLoader"></loader>
  <select-env
    *ngIf="displaySelectEnv"
    (onClose)="displaySelectEnv = false"
    (onSelectEnv)="onSelectEnv()"
  ></select-env>
</div>
