import { SelectItem } from 'primeng/api';
import { BoundingBox, Card, IndImage, IndPhrase } from './cat.model';
import { Env } from './user.model';

export class DataElement implements SelectItem {
  id: any;
  text: string;
  selected?: boolean;
  children: DataElement[] = [];

  constructor(id: string = undefined, text: string = undefined) {
    this.id = id ? id.toString() : id;
    this.text = text;
  }

  get label(): string {
    return this.text;
  }

  get value() {
    return this;
  }
}

export class Product implements SelectItem {
  id?: any;
  productDescr?: string;
  selected?: boolean;
  envList?: Env[];
  original?: string;

  constructor(id: string = undefined, productDescr: string = undefined) {
    this.id = id ? id.toString() : id;
    this.productDescr = productDescr;
    this.envList = [];
  }

  saveOriginal() {
    this.original = JSON.stringify(this);
  }

  restoreOriginal() {
    let original = JSON.parse(this.original) as Product;
    Object.keys(this).forEach((k) => {
      if (k !== 'original') this[k] = original[k];
    });
  }

  isDirty(): boolean {
    let dirty = false;

    let original = JSON.parse(this.original) as Product;
    Object.keys(this).forEach((k) => {
      if (k !== 'original' && this[k] !== original[k]) dirty = true;
    });

    return dirty;
  }

  get label(): string {
    return this.productDescr;
  }

  get value() {
    return this;
  }

  get envListStr(): string {
    return this.envList.map((e) => e.descr).join(', ');
  }
}

export class PaginationParams {
  rangeTo?: number;
  rangeFrom?: number;
  searchId?: number;
  numberOfRows?: number;
}
export class PaginationModel {
  filters?: any[];
  first?: number;
  globalFilter?: any;
  rows?: number;
  sortField?: string;
  sortOrder?: number;
}

export class FileUpload {
  file?: File;
  fileType?: string;
  fileUuid?: string;
  subFolder?: string;
  entityType?: 'doc' | 'instr' = 'doc';
}

export class PresignedUrlResult {
  uploadUrl: string;
  headers: Map<string, string>;
  destFileUrl: string;
  s3Key: string;
}

export class Doc {
  docName: string;
  docS3Key: string;
  pagePdfS3Key: string;
  pagesNum: number;
  pages: DocPage[] = [];
  processStatus: ProcessRunStatusResult;
  docId: number;
  docObjectType: string;
  runNum: number;
  inddS3Key: string;
}

export class DocPage {
  pageNum: number;
  bucket: string;
  s3Key: string;
  url: string;
}

export class FormattedBase {
  dispFormat: string;
  dispFormatList: string[] = [];
  isBold: boolean;
  isItalic: boolean;
  formatDescr: string;
  markDiffSw: boolean;

  get boldSw(): boolean {
    return this.dispFormatList.includes('BOLD') || this.isBold;
  }

  get superscriptSw(): boolean {
    return this.dispFormatList.includes('SUPERSCRIPT');
  }

  get underlineSw(): boolean {
    return this.dispFormatList.includes('UNDERLINE');
  }

  get italicSw(): boolean {
    return this.dispFormatList.includes('ITALIC') || this.isItalic;
  }
}

export class PhraseWord extends FormattedBase {
  id: number;
  text: string;
  chars: WordChar[];
  concatSw: boolean;
  checked: boolean;
  mCardId: number;
}

export class GrammarSpellingIssue {
  id: number;
  type: string;
  description: string;
  replacements: string[];
  offset: number;
  length: number;
  isWhitelisted: boolean;
}

export class ReferenceIssue {
  phraseId: number;
  refPhraseId: number;
  docId: number;
  catPhraseId: number;
  catRefPhraseId: number;
  refPointerWordId: number | null;
  catRefPointerWordId: number | null;
  refFinding: string;
  isPrimary: boolean;
  refChar: string | null;
  similarity: number | null;
  refDiffWords: MatchDiff[] | null;
  refDispWords: PhraseWord[] = [];
  refText: string | null;
}

export class WordChar extends FormattedBase {
  id: number;
  text: string;
  dispFormat: string;
}

export class ReferencePhrase {
  refPhraseId: number;
  refPhraseText: string;
  refType: string;
  phraseId: number;
  similarityPct: number;
  matchSimilarityCode: string;
  extraRefSw: boolean;
  newSw: boolean;
  deleteSw: boolean;
  phraseDispWords: PhraseWord[] = [];
  pointerWordId: number;
  origPointerWordId: number;
  altCatRefPhraseId: number;

  original: string;

  saveOriginal() {
    this.original = JSON.stringify(this);
  }

  restoreOriginal() {
    let original = JSON.parse(this.original) as ReferencePhrase;
    this.refPhraseText = original.refPhraseText;
  }

  isDirty(): boolean {
    if (!this.original) return false;

    let original = JSON.parse(this.original) as ReferencePhrase;
    let textDiff = this.refPhraseText !== original.refPhraseText;
    let pointerWordDiff = this.pointerWordId !== original.pointerWordId;
    return textDiff || pointerWordDiff;
  }
}

export class AddInstr {
  id: number;
  text: string;
  imgUrl: string;
  imgS3Key: string;
  type: any;

  original: string;

  constructor() {
    this.type = { value: 'TXT' };
  }

  saveOriginal() {
    this.original = JSON.stringify(this);
  }

  restoreOriginal() {
    let original = JSON.parse(this.original) as AddInstr;
    this.text = original.text;
  }

  isDirty(): boolean {
    if (!this.original) return false;

    let original = JSON.parse(this.original) as AddInstr;
    let textDiff = this.text !== original.text;
    return textDiff;
  }
}

export class CatMatch {
  catMatchSeq: number;
  catId: number;
  similarityPct: number;
  diffParts: MatchDiff[];
  catPhrase: IndPhrase;
  catImage: IndImage;
  refMatch: RefMatch[] = [];
  extraReferences: ReferencePhrase[] = [];
  reportDisplaySw: boolean;
  tblRowDiff: TblRowDiff[];
}

export class TblRowDiff {
  catRow: TblRow = new TblRow();
  docRow: TblRow = new TblRow();
}

export class TblRow {
  rowId: number;
  cells: TblCell[] = [];
}

export class TblCell {
  cellId: number;
  txt: string;
  diffType: string;
}

export class RefMatch {
  refPhraseId: number;
  catRefPhraseId: number;
  similarityPct: number;
  matchSimilarityCode: string;
  altCatRefPhraseId: number;
}

export class CardEntryMatch {
  card: Card;
  cardEntryId: number;
  catEntry: CatMatch;
}

export class BoundingBoxMatch {
  card: Card;
  boundingBox: BoundingBox;
}

export class MatchDiff {
  id: number;
  text: string;
  diffType: string;
}

export class ProcessRunStatusResult {
  status: ProcessRunStatusEnum;
  errorCode: string;
  errorMsg: string;
  outDocUrl: string;
  outDocS3Key: string;
  completedPct: number;
  completedSteps: ProcessStep[] = [];
  runNum: number;
  createDate: string;

  get isProcessRunning(): boolean {
    return (
      this.status == ProcessRunStatusEnum.RUNNING ||
      this.status == ProcessRunStatusEnum.PENDING
    );
  }
}

export class ProcessStep {
  stepId: number;
  stepCode: string;
  stepDescr: string;
}

export class PhraseBorderLine {
  badgeSw: boolean;
  borderLineId: number;
  endX: number;
  endY: number;
  startX: number;
  startY: number;
}

export class PolygonCorner {
  badgeSw: boolean;
  id: number;
  x: number;
  y: number;
}

export class AnnotationElement {
  x1: number;
  x2: number;
  y1: number;
  y2: number;
  pageWidth: number;
  pageHeight: number;
  annColor: string;
  annId: number;
  elements: HTMLElement[] = [];
  annSvg: SVGSVGElement;
  annPolygon: SVGPolygonElement;
  annBadge: HTMLElement;
  visible: boolean;
  bgImgSw: boolean;
  borderLines: PhraseBorderLine[] = [];
  polygon: PolygonCorner[] = [];
  
  private _annIdPrefix: string;
  public get annIdPrefix(): string {
    return this._annIdPrefix;
  }
  public set annIdPrefix(value: string) {
    this._annIdPrefix = value;
  }
}

export class ServerError {
  errorCode?: string;
  message?: string;
  serviceName?: string;
  url?: string;
  req?: string;
  rep?: string;
  userId?: string;
  networkError?: boolean = false;
  isServerError?: boolean = true;
}

export class ServiceCallInfo {
  serviceName?: string;
  url?: string;
  req?: string;
  algServicesSw?: boolean;

  constructor(
    serviceName: string,
    url: string,
    req: string,
    algServicesSw: boolean = false
  ) {
    this.serviceName = serviceName;
    this.url = url;
    this.req = req;
    this.algServicesSw = algServicesSw;
  }
}

/* == ENUMS == */

export class ProcessRunStatusEnum {
  public static PENDING: string = 'PENDING';
  public static RUNNING: string = 'RUNNING';
  public static FINISHED: string = 'FINISHED';
  public static FAILED: string = 'FAILED';
}

export class MenuItemsEnum {
  static CATALOG: string = 'CATALOG';
  static REVIEW: string = 'REVIEW';
}

export class SearchRunStatusEnum {
  public static STARTED: string = 'STARTED';
  public static RUNNING: string = 'RUNNING';
  public static FINISHED: string = 'FINISHED';
  public static ERROR: string = 'ERROR';
}
