import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { NgRedux } from '@redux/redux-compatibility.service';
import { AppState, actionsList } from 'src/app/shared/redux/store';
import { RevService } from 'src/app/shared/services/rev.service';
import { RevRule, RevDocStatusEnum } from 'src/app/shared/models/rev.model';
import { CatMatch, DataElement } from 'src/app/shared/models/common';
import { SharedService } from 'src/app/shared/services/shared.service';
import { TranslateService } from '@ngx-translate/core';
import Utils from 'src/app/shared/components/utils/utils';
import { Card } from '@shared/models/cat.model';

@Component({
  selector: 'rev-rule-details',
  templateUrl: './rev-rule-details.component.html',
  styleUrls: ['./rev-rule-details.component.scss'],
})
export class RevRuleDetailsComponent implements OnInit {
  @Input() focusOnOpen: boolean = false;
  @Input() card: Card | undefined;
  @Output() onClose = new EventEmitter();
  @Output() onStatusChange = new EventEmitter();

  loading: boolean;
  ruleStatusOptions: DataElement[] = [];
  dirtySw: boolean;
  formatMatchDifferences: any;
  formatMatchPhrase: any;
  formatMatchEntry: any;
  highlightedIds: number[] = [];
  showIssuesOnFullMatchEnabled: boolean;

//   phrase = [
//     {
//         dispFormatList: [],
//         id: 1455,
//         text: 'Do',
//         isBold: false,
//         isItalic: false,
//         markDiffSw: true,
//         formatDescr: 'bold:False, italic:False, font_size:22.01, color_hex:#000000, superscript:False, font_name:{\'HelveticaNeue-Medium\'}'
//     },
//     {
//         dispFormatList: [],
//         id: 1456,
//         text: 'not',
//         isBold: false,
//         isItalic: false,
//         markDiffSw: true,
//         formatDescr: 'bold:False, italic:False, font_size:22.01, color_hex:#000000, superscript:False, font_name:{\'HelveticaNeue-Medium\'}'
//     },
//     {
//         dispFormatList: [],
//         id: 1457,
//         text: 'change',
//         isBold: false,
//         isItalic: false,
//         markDiffSw: true,
//         formatDescr: 'bold:False, italic:False, font_size:22.01, color_hex:#000000, superscript:False, font_name:{\'HelveticaNeue-Medium\'}'
//     },
//     {
//         dispFormatList: [],
//         id: 1458,
//         text: 'or',
//         isBold: false,
//         isItalic: false,
//         markDiffSw: true,
//         formatDescr: 'bold:False, italic:False, font_size:22.01, color_hex:#000000, superscript:False, font_name:{\'HelveticaNeue-Medium\'}'
//     },
//     {
//         dispFormatList: [],
//         id: 1459,
//         text: 'stop',
//         isBold: false,
//         isItalic: false,
//         markDiffSw: true,
//         formatDescr: 'bold:False, italic:False, font_size:22.01, color_hex:#000000, superscript:False, font_name:{\'HelveticaNeue-Medium\'}'
//     },
//     {
//         dispFormatList: [],
//         id: 1460,
//         text: 'taking',
//         isBold: false,
//         isItalic: false,
//         markDiffSw: true,
//         formatDescr: 'bold:False, italic:False, font_size:22.01, color_hex:#000000, superscript:False, font_name:{\'HelveticaNeue-Medium\'}'
//     },
//     {
//         dispFormatList: [],
//         id: 1461,
//         text: 'your',
//         isBold: false,
//         isItalic: false,
//         markDiffSw: true,
//         formatDescr: 'bold:False, italic:False, font_size:22.01, color_hex:#000000, superscript:False, font_name:{\'HelveticaNeue-Medium\'}'
//     },
//     {
//         dispFormatList: [],
//         id: 1462,
//         text: 'other',
//         isBold: false,
//         isItalic: false,
//         markDiffSw: true,
//         formatDescr: 'bold:False, italic:False, font_size:22.01, color_hex:#000000, superscript:False, font_name:{\'HelveticaNeue-Medium\'}'
//     },
//     {
//         dispFormatList: [],
//         id: 1463,
//         text: 'asthma',
//         isBold: false,
//         isItalic: false,
//         markDiffSw: true,
//         formatDescr: 'bold:False, italic:False, font_size:22.01, color_hex:#000000, superscript:False, font_name:{\'HelveticaNeue-Medium\'}'
//     },
//     {
//         dispFormatList: [],
//         id: 1464,
//         text: 'medicines',
//         isBold: false,
//         isItalic: false,
//         markDiffSw: true,
//         formatDescr: 'bold:False, italic:False, font_size:22.01, color_hex:#000000, superscript:False, font_name:{\'HelveticaNeue-Medium\'}'
//     },
//     {
//         dispFormatList: [],
//         id: 1465,
//         text: 'unless',
//         isBold: false,
//         isItalic: false,
//         markDiffSw: true,
//         formatDescr: 'bold:False, italic:False, font_size:22.01, color_hex:#000000, superscript:False, font_name:{\'HelveticaNeue-Medium\'}'
//     },
//     {
//         dispFormatList: [],
//         id: 1466,
//         text: 'instructed',
//         isBold: false,
//         isItalic: false,
//         markDiffSw: true,
//         formatDescr: 'bold:False, italic:False, font_size:22.01, color_hex:#000000, superscript:False, font_name:{\'HelveticaNeue-Medium\'}'
//     },
//     {
//         dispFormatList: [],
//         id: 1467,
//         text: 'to',
//         isBold: false,
//         isItalic: false,
//         markDiffSw: true,
//         formatDescr: 'bold:False, italic:False, font_size:22.01, color_hex:#000000, superscript:False, font_name:{\'HelveticaNeue-Medium\'}'
//     },
//     {
//         dispFormatList: [],
//         id: 1468,
//         text: 'by',
//         isBold: false,
//         isItalic: false,
//         markDiffSw: true,
//         formatDescr: 'bold:False, italic:False, font_size:22.01, color_hex:#000000, superscript:False, font_name:{\'HelveticaNeue-Medium\'}'
//     },
//     {
//         dispFormatList: [],
//         id: 1469,
//         text: 'your',
//         isBold: false,
//         isItalic: false,
//         markDiffSw: true,
//         formatDescr: 'bold:False, italic:False, font_size:22.01, color_hex:#000000, superscript:False, font_name:{\'HelveticaNeue-Medium\'}'
//     },
//     {
//         dispFormatList: [],
//         id: 1470,
//         text: 'healthcare',
//         isBold: false,
//         isItalic: false,
//         markDiffSw: true,
//         formatDescr: 'bold:False, italic:False, font_size:22.01, color_hex:#000000, superscript:False, font_name:{\'HelveticaNeue-Medium\'}'
//     },
//     {
//         dispFormatList: [],
//         id: 1471,
//         text: 'provider',
//         isBold: false,
//         isItalic: false,
//         markDiffSw: true,
//         formatDescr: 'bold:False, italic:False, font_size:22.01, color_hex:#000000, superscript:False, font_name:{\'HelveticaNeue-Medium\'}'
//     },
//     {
//         dispFormatList: [],
//         id: 1472,
//         text: '.',
//         isBold: false,
//         isItalic: false,
//         markDiffSw: true,
//         formatDescr: 'bold:False, italic:False, font_size:22.01, color_hex:#000000, superscript:False, font_name:{\'HelveticaNeue-Medium\'}'
//     }
// ];
// match = [
//   {
//       dispFormatList: [],
//       id: 1455,
//       text: 'Do',
//       isBold: false,
//       isItalic: false,
//       formatDescr: 'bold:False, italic:False, font_size:21.0, color_hex:#000000, superscript:False, font_name:{\'HelveticaNeue-Medium\'}'
//   },
//   {
//       dispFormatList: [],
//       id: 1456,
//       text: 'not',
//       isBold: false,
//       isItalic: false,
//       formatDescr: 'bold:False, italic:False, font_size:21.0, color_hex:#000000, superscript:False, font_name:{\'HelveticaNeue-Medium\'}'
//   },
//   {
//       dispFormatList: [],
//       id: 1457,
//       text: 'change',
//       isBold: false,
//       isItalic: false,
//       formatDescr: 'bold:False, italic:False, font_size:21.0, color_hex:#000000, superscript:False, font_name:{\'HelveticaNeue-Medium\'}'
//   },
//   {
//       dispFormatList: [],
//       id: 1458,
//       text: 'or',
//       isBold: false,
//       isItalic: false,
//       formatDescr: 'bold:False, italic:False, font_size:21.0, color_hex:#100000, superscript:False, font_name:{\'HelveticaNeue-Medium\'}'
//   },
//   {
//       dispFormatList: [],
//       id: 1459,
//       text: 'stop',
//       isBold: false,
//       isItalic: false,
//       formatDescr: 'bold:False, italic:False, font_size:21.0, color_hex:#100000, superscript:False, font_name:{\'HelveticaNeue-Medium\'}'
//   },
//   {
//       dispFormatList: [],
//       id: 1460,
//       text: 'taking',
//       isBold: false,
//       isItalic: false,
//       formatDescr: 'bold:False, italic:False, font_size:22.01, color_hex:#000000, superscript:False, font_name:{\'HelveticaNeue-Medium\'}'
//   },
//   {
//       dispFormatList: [],
//       id: 1461,
//       text: 'your',
//       isBold: false,
//       isItalic: false,
//       formatDescr: 'bold:False, italic:False, font_size:22.01, color_hex:#000000, superscript:False, font_name:{\'HelveticaNeue-Medium\'}'
//   },
//   {
//       dispFormatList: [],
//       id: 1462,
//       text: 'other',
//       isBold: false,
//       isItalic: false,
//       formatDescr: 'bold:True, italic:True, font_size:21.0, color_hex:#000000, superscript:True, font_name:{\'HelveticaNeue-Medium\'}'
//   },
//   {
//       dispFormatList: [],
//       id: 1463,
//       text: 'asthma',
//       isBold: false,
//       isItalic: false,
//       formatDescr: 'bold:True, italic:True, font_size:21.0, color_hex:#000000, superscript:True, font_name:{\'HelveticaNeue-Medium-Sans\'}'
//   },
//   {
//       dispFormatList: [],
//       id: 1464,
//       text: 'medicines',
//       isBold: false,
//       isItalic: false,
//       formatDescr: 'bold:False, italic:False, font_size:21.0, color_hex:#000000, superscript:False, font_name:{\'HelveticaNeue-Medium-Sans\'}'
//   },
//   {
//       dispFormatList: [],
//       id: 1465,
//       text: 'unless',
//       isBold: false,
//       isItalic: false,
//       formatDescr: 'bold:False, italic:False, font_size:21.0, color_hex:#000000, superscript:False, font_name:{\'HelveticaNeue-Medium\'}'
//   },
//   {
//       dispFormatList: [],
//       id: 1466,
//       text: 'instructed',
//       isBold: false,
//       isItalic: false,
//       formatDescr: 'bold:False, italic:False, font_size:21.0, color_hex:#000000, superscript:False, font_name:{\'HelveticaNeue-Medium\'}'
//   },
//   {
//       dispFormatList: [],
//       id: 1467,
//       text: 'to',
//       isBold: false,
//       isItalic: false,
//       formatDescr: 'bold:False, italic:False, font_size:21.0, color_hex:#000000, superscript:False, font_name:{\'HelveticaNeue-Medium\'}'
//   },
//   {
//       dispFormatList: [],
//       id: 1468,
//       text: 'by',
//       isBold: false,
//       isItalic: false,
//       formatDescr: 'bold:False, italic:False, font_size:21.0, color_hex:#000000, superscript:False, font_name:{\'HelveticaNeue-Medium\'}'
//   },
//   {
//       dispFormatList: [],
//       id: 1469,
//       text: 'your',
//       isBold: false,
//       isItalic: false,
//       formatDescr: 'bold:False, italic:False, font_size:21.0, color_hex:#000000, superscript:False, font_name:{\'HelveticaNeue-Medium\'}'
//   },
//   {
//       dispFormatList: [],
//       id: 1470,
//       text: 'healthcare',
//       isBold: false,
//       isItalic: false,
//       formatDescr: 'bold:False, italic:False, font_size:21.0, color_hex:#000000, superscript:False, font_name:{\'HelveticaNeue-Medium\'}'
//   },
//   {
//       dispFormatList: [],
//       id: 1471,
//       text: 'provider',
//       isBold: false,
//       isItalic: false,
//       formatDescr: 'bold:False, italic:False, font_size:21.0, color_hex:#000000, superscript:False, font_name:{\'HelveticaNeue-Medium\'}'
//   },
//   {
//       dispFormatList: [],
//       id: 1472,
//       text: '.',
//       isBold: false,
//       isItalic: false,
//       formatDescr: 'bold:False, italic:False, font_size:21.0, color_hex:#000000, superscript:False, font_name:{\'HelveticaNeue-Medium\'}'
//   }
// ];

  get curRule(): RevRule {
    return this.ngRedux.getState().curRevRule;
  }

  get editable(): boolean {
    return (
      this.ngRedux.getState().curRevDoc.statusCode == RevDocStatusEnum.UNDERWAY
    );
  }

  get isPhrase(): boolean {
    return !this.curRule.isImage;
  }

  get isImage(): boolean {
    return this.curRule.isImage;
  }

  get isPotentialNew(): boolean {
    return this.curRule.revRuleSimilarityEvent == 'POTENTIAL_NEW' 
  }

  get grammarSpellingIssues() {
    if (!this.showIssuesOnFullMatchEnabled && this.curRule.revRuleSimilarityEvent == 'FULL_MATCH') {
      return [];
    }
    return this.curRule.grammarSpellingIssues;
  }

  get referenceIssues() {
    if (!this.showIssuesOnFullMatchEnabled && this.curRule.revRuleSimilarityEvent == 'FULL_MATCH') {
      return [];
    }
    return this.curRule.referenceIssues;
  }

  constructor(
    private ngRedux: NgRedux<AppState>,
    private revService: RevService,
    private sharedService: SharedService,
    private translateService: TranslateService
  ) {
    this.showIssuesOnFullMatchEnabled = this.sharedService.isShowIssuesOnFullMatchEnabled();
  }

  ngOnInit(): void {
    // populate concatSw from text to format words before further processing
    this.formatMatchEntry = this.curRule.formatMatchEntry;
    if (this.curRule.ruleDispWords.length != this.formatMatchEntry.length) {
      if (this.card && this.card.cardId) {
        this.formatMatchEntry = this.formatMatchEntry.filter(entry => entry.mCardId === this.card.cardId);
        const allFormatDescrEmpty = this.formatMatchEntry.every(entry => !entry.formatDescr || entry.formatDescr.trim() === '');
        if (allFormatDescrEmpty) {
          this.formatMatchEntry = [];
        }
      }
      else {
        this.formatMatchEntry = this._getUniqueEntries(this.curRule.formatMatchEntry);
      }
    }

    this.formatMatchPhrase = this.curRule.formatMatchPhrase;
    if (this.curRule.ruleDispWords.length != this.formatMatchPhrase.length) {
      if (this.card && this.card.cardId) {
        this.formatMatchPhrase = this.formatMatchPhrase.filter(entry => entry.mCardId === this.card.cardId);
      }
      else {
        this.formatMatchPhrase = this._getUniqueEntries(this.curRule.formatMatchPhrase);
      }
    }

    this._populateConcatSw(this.formatMatchEntry);
    this._populateConcatSw(this.formatMatchPhrase);

    this.formatMatchDifferences = this.compareTextObjects(this.formatMatchEntry, this.formatMatchPhrase);
    this.curRule.saveOriginal();

    let actions = [];
    if (this.curRule) {
      actions = this.curRule.revRuleActions.map(
        a => (a == 'APPLY_CHANGE') ? 'MARKED_FOR_CHANGES' : a
      );
    }
    // show all actions on old docs
    let isNewRuleDoc = !!this.curRule.revRuleSimilarityEvent;
    if (isNewRuleDoc) {
      this.ruleStatusOptions =
      this.sharedService.getRevRuleUpdStatusOptions().filter(
        s => {
          return actions.indexOf(s.id) >= 0;
        }
      );
    } else {
      this.ruleStatusOptions = this.sharedService.getRevRuleUpdStatusOptions();
    }
  }

  _populateConcatSw(entries) {
    entries.map((entry, ind) => entry.concatSw = this.curRule.ruleDispWords[ind].concatSw);
  }

  _getUniqueEntries(entries: any[]): any[] {
    const uniqueMap = new Map();
    for (const entry of entries) {
      if (!uniqueMap.has(entry.id)) {
        uniqueMap.set(entry.id, entry);
      }
    }
    return Array.from(uniqueMap.values());
  }

  close() {
    if (this.dirtySw) {
      let validationStr = this.translateService.instant(
        'translate.cat.discardEditChanges'
      );

      this.sharedService.emitPopupchReceivedEvent({
        title: 'translate.validation.confirm',
        icon: 'exclamation-triangle',
        subTitle: validationStr,
        keep: false,
        approve: () => {
          this.discardChanges();
          this.onClose.emit();
        },
      });
    } else {
      this.onClose.emit();
    }
  }

  onRevStatusChange() {
    this.onStatusChange.emit();
  }

  saveChanges() {
    this.loading = true;

    this.revService
      .updRevRule(
        this.ngRedux.getState().curRevDoc,
        [this.curRule],
        this.curRule.revStatusCode
      )
      .subscribe(
        (data) => {
          this.loading = false;
          this.dirtySw = false;
        },
        (err) => {
          this.loading = false;
        }
      );
  }

  discardChanges() {
    this.curRule.restoreOriginal();
    this.dirtySw = false;
  }

  onReportDisplaySwChange(match: CatMatch) {
    this.curRule.ruleCatMatches
      .filter((m) => m.catMatchSeq != match.catMatchSeq)
      .forEach((r) => {
        r.reportDisplaySw = false;
      });
    //match.reportDisplaySw = true;
    this.saveChanges();
  }

  compareTextObjects(original, modified): any {
    // original = this.phrase;
    // modified = this.match;
    const differences = [];
    let previousDiff = null;

    original.forEach((originalItem, index) => {
      const diffJson = {
        text: null,
        ids: []
      };
      const modifiedItem = modified[index];
      if (originalItem.formatDescr !== modifiedItem.formatDescr) {
        diffJson.text = originalItem.text;
        diffJson.ids.push(originalItem.id);

        const originalFormat = originalItem.formatDescr.split(', ');
        const modifiedFormat = modifiedItem.formatDescr.split(', ');

        originalFormat.forEach((originalAttr, attrIndex) => {
          const [originalAttrKey, originalAttrVal] = originalAttr.split(':');
          const [modifiedAttrKey, modifiedAttrVal] = modifiedFormat[attrIndex].split(':');

          if (originalAttrVal !== modifiedAttrVal) {
            diffJson[originalAttrKey] = [originalAttrVal, modifiedAttrVal];
          }
        });
        if (this.isSameDifference(diffJson, previousDiff)) {
          differences[differences.length - 1].text += ' ' + diffJson.text;
          differences[differences.length - 1].ids.push(diffJson.ids[0]);
        } else {
          differences.push(diffJson);
        }
        previousDiff = diffJson;
      }
    });

    return differences;
  }

  getFriendlyDifferenceName(text: string): string {
    return text.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase());
  }

  getFriendlyFontName(text: string): string {
    return text.replace(/['{}]/g, '');
  }

  isHighlighted(ids: number[]): boolean {
    return this.highlightedIds.some(id => ids.includes(id));
  }

  highlightDiff(ids: number[]): void {
    this.highlightedIds = ids;
  }

  clearHighlight(): void {
    this.highlightedIds = [];
  }

  isSameDifference(diff1, diff2): boolean {
    const stripText = ({ text, ids, ...obj }: any) => obj;

    const strippedDiff1 = stripText(diff1);
    if (diff2) {
      const strippedDiff2 = stripText(diff2);
      return Utils.areEqual(strippedDiff1, strippedDiff2);
    } else {
      return false;
    }
  }

  getMatchStatusDescr(): string {
    if(this.card) {
      const revCardMatches = this.ngRedux.getState().revCardMatches;
      const match = revCardMatches.find((match: any) => match.phraseId === this.curRule.phraseId && match.mCardId === this.card.cardId);
      return match ? match.matchStatusDescr : this.curRule.revStatusDescr;
    }
    return this.curRule.revStatusDescr;
  }

  getMatchStatusCode(): string {
    let code = this.curRule.revStatusCode;
    if(this.card) {
      const revCardMatches = this.ngRedux.getState().revCardMatches;
      const match = revCardMatches.find((match: any) => match.phraseId === this.curRule.phraseId && match.mCardId === this.card.cardId);
      code = match ? match.matchStatusCode : this.curRule.revStatusCode;
    }
    return code == 'FULL_MATCH' ? 'PASSED' : 'NOT_PASSED_NEW_CONTENT';
  }
}