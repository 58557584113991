<div *ngIf="curCat">
  <section *ngIf="selectMode" class="page-header">
    <div class="title-container">
      <h1>
        <a class="icon" (click)="goBack()"
          ><fa-icon [icon]="['fas', 'arrow-left']"></fa-icon
        ></a>
        &nbsp;
        {{ curCat.catDescr }}
      </h1>

      <button
        class="t-button"
        [disabled]="selectedCards.length == 0"
        (click)="cardsSelected()"
      >
        {{ "translate.lc.addBlocks" | translate }}
        ({{ selectedCards.length }})
      </button>
    </div>
  </section>

  <loader *ngIf="loading"></loader>

  <ui-table
    #tbl
    [isWrapped]="false"
    [data]="cards"
    [numberOfRows]="50"
    [showPaginator]="true"
    [displayFilter]="['cardId', 'title']"
    [checkboxSelection]="allowSelection"
    (onSelectionChanged)="onSelectionChanged($event)"
    [templates]="[
      {
        title: 'translate.general.typeDescr',
        template: typeDescr
      },
      {
        title: 'translate.general.createDate',
        template: createDate
      },
      {
        title: 'translate.general.actions',
        template: actions
      }
    ]"
    [topEnd]="filterArea"
  ></ui-table>

  <ng-template #typeDescr let-row="row">
    {{ row.type.text }}
  </ng-template>

  <ng-template #createDate let-row="row">
    {{ row.createDate }}
  </ng-template>

  <ng-template #actions let-row="row">
    <div class="actions-container">
      <a (click)="view(row)">{{ "translate.general.view" | translate }}</a>
    </div>
  </ng-template>

  <ng-template #filterArea>
    <div *ngIf="!selectMode" class="filter-bar l-align-spaceBetween">
      <div class="filters">
        <!--filterTitle-->
        <input-wrap class="t-input-search-wrap">
          <input
            class="t-input"
            type="text"
            [(ngModel)]="filterTitle"
            placeholder="{{ 'translate.general.title' | translate }}"
            (keypress)="onFilterKeyPress($event)"
          />
        </input-wrap>

        <a *ngIf="displayClearFilter" (click)="clearFilter()">{{
          "translate.general.clear" | translate
        }}</a>
      </div>

      <div>
        <button *ngIf="allowEdit" class="t-button" (click)="createCard()">
          {{ "translate.cat.createCard" | translate }}
        </button>
        &nbsp; &nbsp;
        <button
          *ngIf="allowEdit"
          class="t-button-outline"
          (click)="addToTemplate()"
          [disabled]="selectedCards.length == 0"
        >
          {{ "translate.cat.addCardsToTemplate" | translate }}
          ({{ selectedCards.length }})
        </button>
      </div>
    </div>
  </ng-template>
</div>

<add-cards-to-template
  *ngIf="displayAddToTemplate"
  [cards]="selectedCards"
  (onClose)="afterAddToTemplate()"
></add-cards-to-template>
