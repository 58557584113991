import { NgRedux } from '@redux/redux-compatibility.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Unsubscribe } from 'redux';
import { TableComponent } from 'src/app/shared/components/ui/table/table.component';
import {
  Catalog,
  CatalogStatusEnum,
  CatEntriesSearchParams,
  CatImagesSearchParams,
  IndElement,
  IndImage,
} from 'src/app/shared/models/cat.model';
import { DataElement, PaginationParams } from 'src/app/shared/models/common';
import { ServiceMetaData } from 'src/app/shared/models/service-meta.model';
import { TabIdList } from 'src/app/shared/models/tab.model';
import { actionsList, AppState } from 'src/app/shared/redux/store';
import { CatService } from 'src/app/shared/services/cat.service';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'app-cat-images',
  templateUrl: './cat-images.component.html',
  styleUrls: ['./cat-images.component.scss'],
})
export class CatImagesComponent implements OnInit {
  @ViewChild('imagesTbl') table: TableComponent;
  loading: boolean;
  rangeFrom: number;
  rangeTo: number;
  totalRecords: number;
  searchParams: CatImagesSearchParams = {};
  selectedImage: IndImage;
  tabIdList: TabIdList;
  imageDetailsOpenSw: boolean;
  reduxSubscribe: Unsubscribe;
  displayImages: IndImage[];
  rowsPerPage = 50;

  get images(): IndImage[] {
    return this.ngRedux.getState().catImages;
  }

  get curCat(): Catalog {
    return this.ngRedux.getState().curCat;
  }

  get allowEdit(): boolean {
    if (!this.curCat) return false;
    return this.curCat.statusCode == CatalogStatusEnum.DRAFT;
  }

  get entriesMetaData(): ServiceMetaData {
    const metaData: ServiceMetaData = this.ngRedux.getState().catImagesMetadata;
    return metaData || {};
  }

  constructor(
    private ngRedux: NgRedux<AppState>,
    private catService: CatService,
    private router: Router,
    private sharedService: SharedService,
    private translateService: TranslateService
  ) {
    this.tabIdList = this.ngRedux.getState().ui_tabIdList;
  }

  ngOnInit(): void {
    this.loadData(true);
  }

  ngOnDestroy(): void {
    if (this.reduxSubscribe) this.reduxSubscribe();
  }

  loadData(newSearch: boolean) {
    this.loading = true;

    if (newSearch) {
      this.searchParams.searchId = null;
      this.searchParams.rangeFrom = 0;
      this.searchParams.rangeTo = this.rowsPerPage;
    }

    this.catService.getCatImages(this.searchParams).subscribe(
      (data) => {
        this.catService.getS3Objects(data.map((i) => i.imgS3Key)).subscribe();
        this.updateDisplayedEntries();
        this.loading = false;
      },
      (err) => (this.loading = false)
    );
  }

  updateDisplayedEntries() {
    this.displayImages = this.images.slice(
      this.searchParams.rangeFrom - 1,
      this.searchParams.rangeTo
    );
  }

  onEntryEdited() {
    this.updateDisplayedEntries();
  }

  onPagination(event: PaginationParams) {
    if (
      !this.images ||
      (event.rangeFrom == this.searchParams.rangeFrom &&
        event.rangeTo == this.searchParams.rangeTo)
    )
      return;
    this.searchParams.rangeFrom = event.rangeFrom;
    this.searchParams.rangeTo = event.rangeTo;
    this.searchParams.searchId =
      this.ngRedux.getState().catImagesMetadata.searchId;
    this.updateDisplayedEntries();
  }

  edit(event: any, image: IndImage) {
    if (event) event.stopPropagation();

    this.ngRedux.dispatch({
      type: actionsList.SELECT_CAT_ELEMENT,
      data: image,
    });
    this.imageDetailsOpenSw = true;
  }

  delete(event: any, entry: IndElement) {
    if (event) event.stopPropagation();

    let validationStr = this.translateService.instant(
      'translate.cat.deleteEntry'
    );

    this.sharedService.emitPopupchReceivedEvent({
      title: 'translate.validation.confirm',
      icon: 'exclamation-triangle',
      subTitle: validationStr,
      keep: false,
      approve: () => {
        this.imageDetailsOpenSw = false;
        this.loading = true;
        this.catService.updCatEntry(entry, true).subscribe(
          (data) => {
            this.onEntryDeleted(entry);
            this.loading = false;
          },
          (err) => (this.loading = false)
        );
      },
    });
  }

  onEntryDeleteClicked() {
    this.delete(null, this.ngRedux.getState().curCatElement);
  }

  onEntrySaved() {
    this.imageDetailsOpenSw = false;
    this.loadData(false);
  }

  onEntryDeleted(entry: IndElement = null) {
    if (!entry) {
      entry = this.ngRedux.getState().curCatElement;
    }
    this.imageDetailsOpenSw = false;
    let idx = this.images.findIndex((e) => e.imageId == entry.elementId);
    if (idx > -1) {
      this.images.splice(idx, 1);
      this.entriesMetaData.totalRecords--;
      this.updateDisplayedEntries();
    }
  }
}
