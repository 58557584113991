<div *ngIf="data">
  <section class="page-header">
    <div class="title-container">
      <h1>
        <a class="icon" (click)="goBack()"
          ><fa-icon [icon]="['fas', 'arrow-left']"></fa-icon
        ></a>
        &nbsp;
        {{ data.name }}
      </h1>

      <div class="l-align-center">
        <button
          (click)="preview()"
          class="t-button-outline"
          [disabled]="loading || !data.previewUrl || running"
        >
          {{ "translate.general.preview" | translate }}
        </button>
        &nbsp;&nbsp;
        <button class="t-button-outline" (click)="editOrderOpenSw = true">
          {{ "translate.cat.editPhraseOrder" | translate }}
        </button>
        &nbsp;&nbsp;
        <button
          (click)="export()"
          class="t-button-outline"
          [disabled]="loading || !data.url || running"
        >
          {{ "translate.general.export" | translate }}
        </button>
        &nbsp;&nbsp;
        <button
          (click)="extract()"
          class="t-button"
          [disabled]="loading || running || !extractEnabled()"
        >
          {{ "translate.general.extract" | translate }}
        </button>
        <spinner *ngIf="running"></spinner>
      </div>
    </div>
  </section>

  <section>
    <data-box
      class="w-half"
      [data]="data"
      [displayFilter]="['id', 'assetType', 'descr']"
      [columns]="1"
      [templateRefs]="{
        assetType: assetType
      }"
    ></data-box>
  </section>

  <ng-template #assetType>
    {{ data.assetType.text }}
  </ng-template>

  <div class="t-row"></div>

  <loader *ngIf="loading"></loader>

  <ui-table
    *ngIf="data.cards"
    [isWrapped]="false"
    [multipleExpand]="true"
    [showExpandAll]="true"
    [data]="data.cards"
    [displayFilter]="['cardId', 'title']"
    [templates]="[
      {
        title: 'translate.general.typeDescr',
        template: typeDescr
      },
      {
        title: 'translate.cat.srcType',
        template: srcType
      },
      {
        title: 'translate.general.actions',
        template: actions
      }
    ]"
    [more]="expandRow"
  ></ui-table>

  <ng-template #typeDescr let-row="row">
    {{ row.type.text }}
  </ng-template>

  <ng-template #srcType let-row="row">
    {{ row.srcType }}
  </ng-template>

  <ng-template #actions let-row="row">
    <div class="actions-container">
      <a (click)="viewCard(row)">{{ "translate.general.view" | translate }}</a>

      <span class="action-divider">|</span>

      <a (click)="removeCard(row)">{{
        "translate.general.remove" | translate
      }}</a>
    </div>
  </ng-template>

  <ng-template #expandRow let-row="row">
    <div *ngFor="let doc of row.cardDocs">
      <div *ngFor="let box of doc.boundingBoxes" class="box-tile">
        <bb-tile-new
          id="{{ box.boxId }}"
          [box]="box"
          [doc]="doc"
          [allowEdit]="false"
          [displayIndPhrases]="true"
        >
        </bb-tile-new>
      </div>
    </div>
  </ng-template>
</div>

<edit-card-order
  *ngIf="editOrderOpenSw"
  [data]="data"
  (onClose)="editOrderOpenSw = false"
>
</edit-card-order>
