<modal-popup
  [flexible]="true"
  [focusOnOpen]="true"
  [isOpen]="true"
  (onClose)="close()"
  [title]="modalTitle"
  [afterTitle]="dirtyBtns"
>
  <div class="container">
    <!--descr-->
    <input-wrap
      label="{{ 'translate.cat.mngPhraseTypeDescr' | translate }}"
      [required]="true"
      cssClass="l-cell-spread"
    >
      <input
        type="text"
        [(ngModel)]="type.typeDescr"
        class="t-input"
      />
    </input-wrap>

    <!--code-->
    <input-wrap
      label="{{ 'translate.cat.mngPhraseTypeCode' | translate }}"
      [required]="true"
      cssClass="l-cell-spread"
    >
      <input
        type="text"
        [(ngModel)]="type.typeCode"
        class="t-input"
        [disabled]="!isNew"
      />
    </input-wrap>

    <div class="l-align-end l-mar-top t-row--small" *ngIf="!isNew">
      <button
        class="t-button-outline t-button-warn"
        [disabled]="loading"
        (click)="onDeleteClicked()"
      >
        {{ "translate.general.delete" | translate }}
      </button>
    </div>
  </div>

  <loader *ngIf="loading"></loader>
</modal-popup>

<ng-template #dirtyBtns>
  <div *ngIf="isDirty()" class="t-small">
    <button
      class="t-button t-button-warn"
      [disabled]="loading || !validateBeforeSave()"
      (click)="saveChanges()"
    >
      {{ "translate.general.saveChanges" | translate }}
    </button>
    &nbsp;
    <button
      class="t-button-outline t-button-warn"
      [disabled]="loading"
      (click)="discardChanges()"
    >
      {{ "translate.general.discardChanges" | translate }}
    </button>
  </div>
</ng-template>
