<a
  *ngIf="tab"
  class="t-tab{{ tab.tabClass ? ' ' + tab.tabClass : '' }}"
  [class.disabled]="tab.disabled"
  role="tab"
  [attr.aria-controls]="'tabContent-' + tab.tabId"
  [ngClass]="{ 'is-active': tab.isActive || isActive }"
  (click)="setActiveTabOnClick(tab)"
  [disabled]="tab.disabled"
>
  <i *ngIf="tab.icon" class="tab-icon {{ tab.icon }}"></i>
  <span *ngIf="tab.icon" class="t-space--med"></span>
  {{ tab.tabTitle | translate }}</a
>
