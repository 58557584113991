<modal-popup
  [flexible]="true"
  [focusOnOpen]="false"
  [isOpen]="true"
  (onClose)="close()"
  [title]="'translate.general.selectEnvTitle'"
>
  <div class="container">
    <input-wrap class="t-input-search-wrap">
      <input
        class="t-input"
        type="text"
        [(ngModel)]="searchTxt"
        placeholder="{{ 'translate.general.search' | translate }}"
        (ngModelChange)="onSearchChange()"
      />
    </input-wrap>

    <loader *ngIf="loading"></loader>

    <div *ngIf="displayEnvList" class="env-list">
      <div
        class="env"
        *ngFor="let env of displayEnvList"
        (click)="onSelect(env)"
      >
        <p>{{ env.descr }}</p>
      </div>
    </div>
  </div>
</modal-popup>
