import { NgRedux } from '@redux/redux-compatibility.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DataElement } from 'src/app/shared/models/common';
import { AppState } from 'src/app/shared/redux/store';
import { AuthService } from 'src/app/shared/services/auth.service';
import { CatService } from 'src/app/shared/services/cat.service';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'edit-asset-type',
  templateUrl: './edit-asset-type.component.html',
  styleUrls: ['./edit-asset-type.component.scss'],
})
export class EditAssetTypeComponent implements OnInit {
  @Input() row: DataElement;
  @Output() onClose = new EventEmitter();
  @Output() onSave = new EventEmitter();
  isNew: boolean;
  saving: boolean;

  get modalTitle(): string {
    if (this.isNew) return 'translate.mng.addAssetType';
    else return 'translate.mng.editAssetType';
  }

  constructor(
    private ngRedux: NgRedux<AppState>,
    private catService: CatService,
    private sharedService: SharedService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.isNew = this.row.id == null;
  }

  close() {
    this.onClose.emit();
  }

  deleteClicked() {
    let validationStr = this.translateService.instant(
      'translate.mng.deleteValidation'
    );

    this.sharedService.emitPopupchReceivedEvent({
      title: 'translate.validation.confirm',
      icon: 'exclamation-triangle',
      subTitle: validationStr,
      keep: false,
      approve: () => {
        this.updRow(true);
      },
    });
  }

  public allowSubmit(): boolean {
    return this.row.text != null;
  }

  public updRow(deleteSw: boolean = false) {
    this.saving = true;
    this.catService.updAssetType(this.row, deleteSw).subscribe(
      () => {
        this.saving = false;
        this.onSave.emit();
      },
      () => (this.saving = false)
    );
  }
}
