/**
 * Convert object property names to camelCase
 * @param obj - Object with properties in snake_case
 * @returns Object with properties in camelCase
 */
export function toCamelCaseProperties(obj: any): any {
  // Handle non-objects
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  // Recursively convert arrays
  if (Array.isArray(obj)) {
    return obj.map(toCamelCaseProperties);
  }

  // Transform key and recursively handle value
  return Object.assign({}, ...Object.entries(obj).map(([key, value]) => ({
    [toCamelCase(key)]: toCamelCaseProperties(value)
  })));
}

// Regex to convert snake_case to camelCase
function toCamelCase(str: string): string {
  return str.replace(/_(.)/g, (match, group) => group.toUpperCase());
}


/**
 * Convert object property names to snake_case
 * @param obj - Object with properties in camelCase
 * @returns Object with properties in snake_case
 */
export function toSnakeCaseProperties(obj: any): any {
  // Handle non-objects
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  // Recursively convert arrays
  if (Array.isArray(obj)) {
    return obj.map(toSnakeCaseProperties);
  }

  // Transform key and recursively handle value
  return Object.assign({}, ...Object.entries(obj).map(([key, value]) => ({
    [toSnakeCase(key)]: toSnakeCaseProperties(value)
  })));
}

// Regex to convert camelCase to snake_case
function toSnakeCase(str: string): string {
  return str.replace(/([A-Z])([a-z]+)/g, (match, group1, group2) => `_${group1.toLowerCase()}${group2}`);
}
