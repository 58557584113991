import {
  Input,
  Component,
  OnInit,
  HostBinding,
  ElementRef,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ValidationPipe } from './validation.pipe';
import { ValidationTypes } from '../../models/validation.model';

@Component({
  selector: 'input-wrap',
  host: { class: 't-panel-cell' },
  template: `
    <div
      [ngClass]="{
        'is-loading': loading,
        'is-invalid': displayedValidationString,
        'is-readonly': readonly,
        'is-singleLine': singleLine,
        'is-label-after': labelAfter
      }"
      class="l-pos-relative w-full t-inputWrap-in{{ cssClassIn }}"
    >
      <div class="l-align-y t-inputWrap-header">
        <h4
          *ngIf="label"
          class="norm-title fs-small t-row--small  t-buttonSet-child"
        >
          {{ label | translate }}
          <ng-container *ngIf="required">
            <span
              *ngIf="!isValid"
              class="c-warn"
              [tooltip]="'translate.general.required' | translate"
            >
              (*)
            </span>
            <!-- <span
              *ngIf="isValid"
              class="c-success"
              [tooltip]="'translate.general.required' | translate"
            >
              <i class="fas fa-check"></i>
            </span> -->
          </ng-container>

          <ng-container *ngIf="!required && customValidation !== undefined">
            <span
              *ngIf="isValid || customValidation === true"
              class="c-success"
              [tooltip]="'translate.general.required' | translate"
            >
              <i class="fas fa-check"></i>
            </span>
            <span
              *ngIf="!isValid"
              class="c-warn"
              [tooltip]="'translate.general.required' | translate"
            >
              <i class="fas fa-times"></i>
            </span>
          </ng-container>
        </h4>

        <!--l-cell-spread-x-->
        <div class=" t-buttonSet-child t-inputWrap-afterTitle">
          <ng-container *ngTemplateOutlet="afterTitle"></ng-container>
        </div>

        <ng-container *ngTemplateOutlet="topEnd"></ng-container>
      </div>

      <div class="l-align-y t-inputWrap-table">
        <ng-content></ng-content>
      </div>

      <ng-container *ngTemplateOutlet="belowContent"></ng-container>

      <loader *ngIf="loading"></loader>

      <div
        *ngIf="displayedValidationString"
        role="alert"
        class="t-invalid-text"
      >
        {{ displayedValidationString | translate }}
      </div>
      <div
        *ngIf="customValidation === false && customValidationWarn"
        role="alert"
        class="t-invalid-text"
      >
        {{ customValidationWarn }}
      </div>
    </div>
  `,
})
export class InputWrapComponent implements OnInit {
  constructor(
    private el: ElementRef,
    private translateService: TranslateService,
    private validationPipe: ValidationPipe
  ) {}

  @Input() label: string;
  @Input() loading: boolean;
  @Input() cssClass: string;
  @Input() cssClassIn: string;
  @Input() validation: ValidationTypes;
  @Input() required: boolean;
  @Input() readonly: boolean;
  @Input() singleLine: boolean;
  @Input() labelAfter: boolean = false;
  @Input() topEnd: TemplateRef<any>;
  @Input() afterTitle: TemplateRef<any>;
  @Input() belowContent: TemplateRef<any>;
  @Input() customValidation: boolean;
  @Input() customValidationWarn: string;
  inputElement: HTMLFormElement;
  //isFileType: boolean;

  displayedValidationString: string;
  isValid: boolean = false;
  validationText = {
    tel: 'translate.validation.invalidPhone',
    email: 'translate.validation.invalidEmail',
    latin: 'translate.validation.onlyLatinLettersAndNumbers',
    number: 'translate.validation.onlyNumbers',
  };

  @HostBinding('class') classString: string;

  ngOnInit() {
    if (this.cssClass) {
      this.classString = this.cssClass + ' t-inputWrap'; //t-panel-cell
    } else {
      this.classString = ' t-inputWrap'; //t-panel-cell
    }

    if (this.labelAfter) {
      this.classString += ' is-short';
    }

    if (this.cssClassIn) this.cssClassIn = ' ' + this.cssClassIn;
  }

  ngAfterViewInit() {
    this.doValidation(this.el.nativeElement);
  }

  doValidation(el: HTMLElement) {
    this.inputElement = el.querySelector(
      'input[type="text"], input[type="email"], input[type="tel"], input[type="file"], .t-input, input[aria-haspopup="listbox"], .validate'
    );
    if (!this.inputElement) return;

    this.initValidation();

    this.inputElement.addEventListener('keyup', (event: MouseEvent) => {
      this.validateInput(this.inputElement);
    });

    this.inputElement.type === 'file' &&
      this.inputElement.addEventListener('change', (event: MouseEvent) => {
        this.validateInput(this.inputElement);
      });

    document.addEventListener('mouseup', this.initValidation.bind(this));

    //this.isFileType = this.inputElement.type === "file";
  }

  ngOnDestroy() {
    document.removeEventListener('mouseup', this.initValidation.bind(this));
  }

  initValidation() {
    requestAnimationFrame(() => {
      this.validateInput(this.inputElement);
    });
  }

  validateInput(input: HTMLFormElement) {
    if (!input) return;
    if (this.customValidation !== undefined) {
      this.isValid = this.customValidation;
      return;
    }
    const test = this.validationPipe.transform(input.value, input.type);
    this.isValid = test;
    if (!test) {
      this.displayedValidationString = this.validationText[input.type];
    } else {
      this.displayedValidationString = '';
    }
    if (input.value === '') {
      this.displayedValidationString = '';
    }

    if (
      input.hasAttribute('aria-haspopup') &&
      input.hasAttribute('aria-label')
    ) {
      this.isValid = !!input.getAttribute('aria-label').trim();
    }
  }
}
