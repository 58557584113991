import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TreeNode } from 'primeng/api';

@Component({
  selector: 'cat-phrase-type-list',
  templateUrl: './cat-phrase-type-list.component.html',
  styleUrls: ['./cat-phrase-type-list.component.scss'],
})
export class CatPhraseTypeListComponent implements OnInit {
  @Input() catPhraseTypes: TreeNode[];
  @Input() allowEdit: boolean = false;
  @Input() allowSelect: boolean = false;
  @Output() onAddSubType = new EventEmitter<TreeNode>();
  @Output() onEdit = new EventEmitter<TreeNode>();
  @Output() onDelete = new EventEmitter<TreeNode>();
  @Output() onSelection = new EventEmitter<TreeNode[]>();

  selectedNodes: TreeNode[];
  selectionMode: string;

  constructor() {}

  ngOnInit(): void {
    if (this.allowSelect) {
      this.selectionMode = 'checkbox';
      this.selectedNodes = [];
    } else {
      this.selectionMode = null;
      this.selectedNodes = null;
    }
  }

  addSubType(node: TreeNode) {
    this.onAddSubType.emit(node);
  }

  edit(node: TreeNode) {
    this.onEdit.emit(node);
  }

  onDeleteClicked(node: TreeNode) {
    this.onDelete.emit(node);
  }

  nodeSelect(event) {
    this.onSelection.emit(this.selectedNodes);
  }

  nodeUnselect(event) {
    this.onSelection.emit(this.selectedNodes);
  }
}
