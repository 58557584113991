import { NgRedux } from '@redux/redux-compatibility.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { InputWrapComponent } from 'src/app/shared/components/ui/input-wrap.component';
import { Catalog, CatalogSearchParams } from 'src/app/shared/models/cat.model';
import { DataElement, Product } from 'src/app/shared/models/common';
import { LabelChange } from 'src/app/shared/models/lc.model';
import { AppState } from 'src/app/shared/redux/store';
import { CatService } from 'src/app/shared/services/cat.service';

@Component({
  selector: 'create-lc',
  templateUrl: './create-lc.component.html',
  styleUrls: ['./create-lc.component.scss'],
})
export class CreateLcComponent implements OnInit {
  @Output() onClose = new EventEmitter();
  @Output() onCreate = new EventEmitter();

  @Input() lc: LabelChange;

  saving: boolean;
  productList: DataElement[];
  catalogList: Catalog[] = [];

  get enableSubmit(): boolean {
    return (
      this.lc.title != null &&
      this.lc.product != null &&
      this.lc.catalog != null
    );
  }

  constructor(
    private ngRedux: NgRedux<AppState>,
    private catService: CatService
  ) {}

  ngOnInit(): void {
    this.productList = this.ngRedux
      .getState()
      .productList.map((p) => new DataElement(p.id, p.productDescr));

    if (this.lc.lcId) {
      this.getCatalogList();
    }
  }

  close() {
    this.onClose.emit();
  }

  save() {
    this.saving = true;
    this.catService.updateLabelChange(this.lc).subscribe(
      (data) => {
        this.saving = false;
        this.onCreate.emit();
      },
      (err) => (this.saving = false)
    );
  }

  onProductChange() {
    this.getCatalogList();
  }

  getCatalogList() {
    this.catalogList = [];
    if (!this.lc.product) return;

    this.catalogList.loading = true;

    let sp = new CatalogSearchParams();
    sp.products = [this.lc.product];
    sp.envId = this.ngRedux.getState().curEnv.id;

    this.catService.getCatList(sp, false).subscribe(
      (data) => {
        let drafts = [];
        data.forEach((c) => {
          c.catVersions.forEach((v) => {
            if (v.versionId == 0) {
              drafts.push(v);
            }
          });
        });
        this.catalogList = drafts;

        if (this.lc.lcId) {
          this.lc.catalog = this.catalogList.find(
            (c) => c.catVersionId == this.lc.catalog.catVersionId
          );
        }
      },
      (err) => (this.catalogList.loading = false)
    );
  }
}
