<modal-popup
  [flexible]="true"
  [focusOnOpen]="false"
  [isOpen]="true"
  (onClose)="close()"
  [title]="'translate.cat.createCat'"
>
  <div class="container">
    <!--cat name-->
    <input-wrap
      label="{{ 'translate.general.catDescr' | translate }}"
      [required]="true"
      cssClass="l-cell-spread"
    >
      <input type="text" [(ngModel)]="catDescr" class="t-input" />
    </input-wrap>

    <!--product-->
    <input-wrap
      [loading]="productList && productList.loading"
      label="{{ 'translate.general.productDescr' | translate }}"
      [required]="true"
      cssClass="l-cell-spread"
    >
      <p-dropdown
        [options]="productList"
        optionLabel="label"
        [(ngModel)]="product"
        class="l-cell-spread-x"
        [resetFilterOnHide]="true"
        [filterPlaceholder]="'translate.general.search' | translate"
        [placeholder]="'translate.general.select' | translate"
        [filter]="true"
        appendTo="body"
        baseZIndex="100"
      >
      </p-dropdown>
    </input-wrap>

    <!--type-->
    <input-wrap
      *ngIf="!extSw"
      [loading]="typeList && typeList.loading"
      label="{{ 'translate.general.typeDescr' | translate }}"
      [required]="true"
      cssClass="l-cell-spread"
    >
      <p-dropdown
        [options]="typeList"
        optionLabel="label"
        [(ngModel)]="type"
        class="l-cell-spread-x"
        [resetFilterOnHide]="true"
        [filterPlaceholder]="'translate.general.search' | translate"
        [placeholder]="'translate.general.select' | translate"
        [filter]="true"
        appendTo="body"
        baseZIndex="100"
      >
      </p-dropdown>
    </input-wrap>

    <input-wrap
      [loading]="refMatchTypeList && refMatchTypeList.loading"
      label="{{ 'translate.general.refMatchType' | translate }}"
      [required]="true"
      cssClass="l-cell-spread"
    >
      <p-dropdown
        [options]="refMatchTypeList"
        optionLabel="label"
        [(ngModel)]="refMatchType"
        class="l-cell-spread-x"
        [resetFilterOnHide]="true"
        [filterPlaceholder]="'translate.general.search' | translate"
        [placeholder]="'translate.general.select' | translate"
        [filter]="true"
        appendTo="body"
        baseZIndex="100"
      >
      </p-dropdown>
    </input-wrap>

    <input-wrap
       [loading]="langList && langList.loading"
       label="{{ 'translate.general.langDescr' | translate }}"
       [required]="true"
       cssClass="l-cell-spread"
     >
       <p-dropdown
         [options]="langList"
         optionLabel="label"
         [(ngModel)]="lang"
         class="l-cell-spread-x"
         [resetFilterOnHide]="true"
         [filterPlaceholder]="'translate.general.search' | translate"
         [placeholder]="'translate.general.select' | translate"
         [filter]="true"
         appendTo="body"
         baseZIndex="100"
       >
       </p-dropdown>
     </input-wrap>

     <checkbox-wrap class="checkbox" label="{{ 'translate.general.isMaster' | translate }}">
      <input type="checkbox" [(ngModel)]="isMaster" />
    </checkbox-wrap>

    <div class="actions">
      <button class="t-button-outline" (click)="close()">
        {{ "translate.general.cancel" | translate }}
      </button>
      &nbsp;
      <button class="t-button" [disabled]="!enableSubmit" (click)="save()">
        {{ "translate.general.ok" | translate }}
      </button>
    </div>
  </div>

  <loader *ngIf="saving"></loader>
</modal-popup>
