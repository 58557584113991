<div class="box-container">
  <div class="thumbnail" [class.selected]="selected">
    <async-img
      *ngIf="box.imgUrl"
      [url]="box.imgUrl"
      (click)="viewBox(true)"
    ></async-img>
  </div>

  <p *ngIf="box.mcBoxType" class="box-type fw-bold">{{ box.mcBoxType.text }}</p>
  <p *ngIf="!box.mcBoxType" class="box-type fw-bold">{{ box.boxType.text }}</p>

  <div *ngIf="box.descr || box.blockRules" class="descr t-row--med">
    <p *ngIf="box.descr" class="block-rules">
      <span class="fw-bold">{{ "translate.cat.blockInstr" | translate }}:</span>
      {{ box.descr }}
    </p>
    <p *ngIf="box.blockRules" class="block-rules">
      <span class="fw-bold">Block rules:</span> {{ box.blockRules }}
    </p>
  </div>

  <div class="page footnote">
    <p>{{ "translate.general.page" | translate }}: {{ box.displayPageNum }}</p>
    <p>{{ "translate.general.bbId" | translate }}: {{ box.boxId }}</p>
  </div>

  <div class="action-container">
    <div class="actions" [class.disabled]="box.deleting">
      <a (click)="viewBox(true)">{{
        "translate.general.viewPage" | translate
      }}</a>
      <span class="action-divider">|</span>
      <a (click)="viewBox()">{{ "translate.general.viewDoc" | translate }}</a>

      <div *ngIf="card?.cardStatus !== 'Approved' && (allowEdit || forceDeleteAction)">
        <span class="action-divider">|</span>
        <a (click)="deleteBoxClicked()">{{
          "translate.general.delete" | translate
        }}</a>
      </div>
    </div>
  </div>

  <div
    *ngIf="box.indPhrases"
    class="box-phrases"
    [class.has-content]="box.indPhrases"
  >
    <div
      *ngFor="let phrase of box.indPhrases"
      class="phrase"
      [class.selected]="phrase.phraseId == selectedPhraseId"
    >
      <div *ngIf="!transSw" class="l-align-spaceBetween">
        <formatted-phrase [phraseWords]="phrase.phraseWords"></formatted-phrase>

        <div *ngIf="displayMatchStatus" class="match-info l-align-center">
          <span
            class="card-match-similarity"
            [ngClass]="[getPhraseMatchStatusCode(box, phrase)]"
          >
            {{ getPhraseMatchStatus(box, phrase) }}
          </span>
          <button
            *ngIf="displayPhraseLink"
            class="phrase-link-btn t-button t-button--pill t-btn-more bg-white"
            (click)="viewPhraseDetails(box, phrase)"
            [disabled]="
              phraseDetailsLoading ||
              getCardMatch(box, phrase) == null ||
              getCardMatch(box, phrase).phraseId == -1
            "
          >
            <i class="fa-solid fa-ellipsis"></i>
          </button>
        </div>
      </div>

      <div *ngIf="transSw" class="l-align-center">
        <div class="w-half l-ta-start">
          <formatted-phrase
            [phraseWords]="phrase.phraseWords"
          ></formatted-phrase>
        </div>
        &nbsp;&nbsp;
        <div class="w-half">
          <textarea
            class="edit-area"
            [class.dirty]="phrase.dirtySw"
            [class.is-rtl]="transLang == 'he'"
            pInputTextarea
            [(ngModel)]="phrase.transTxt"
            autoResize="true"
            (ngModelChange)="phrase.dirtySw = true"
          ></textarea>
        </div>
      </div>
    </div>
  </div>

  <div
    *ngIf="box.revPhrases"
    class="box-phrases"
    [class.has-content]="box.revPhrases"
  >
    <div
      *ngFor="let phrase of box.revPhrases"
      class="phrase"
      [class.selected]="phrase.phraseId == selectedPhraseId"
    >
      <formatted-phrase [phraseWords]="phrase.ruleDispWords"></formatted-phrase>
    </div>
  </div>

  <div class="box-image" [class.has-content]="box.cardImages">
    <div *ngIf="box.cardImages && box.cardImages.length > 0">
      <div *ngFor="let img of box.cardImages" class="image-row l-align-spaceBetween t-row--med">
        <div class="img-thumbnail">
          <async-img *ngIf="img.s3Key" [className]="'inner-img-thumbnail'" (click)="viewBox(true)" [url]="img.imgUrl"></async-img>
        </div>
        <div *ngIf="displayMatchStatus" class="match-info l-align-center">
          <span
            class="card-match-similarity"
            [ngClass]="[getImageMatchStatusCode(box, img) || '']"
          >
            {{ getImageMatchStatus(box, img) }}
          </span>
          <button
            *ngIf="displayPhraseLink"
            class="phrase-link-btn t-button t-button--pill t-btn-more bg-white"
            (click)="viewImageDetails(box, img)"
            [disabled]="
              phraseDetailsLoading ||
              getImageCardMatch(box, img) == null ||
              getImageCardMatch(box, img).imageId == -1
            "
          >
            <i class="fa-solid fa-ellipsis"></i>
          </button>
        </div>
    </div>
  </div>

</div>

<modal-popup
  *ngIf="displayDocViewer"
  [flexible]="false"
  [hideHeader]="false"
  [title]="doc.docName"
  [isOpen]="displayDocViewer"
  (onClose)="displayDocViewer = false"
>
  <div *ngIf="!docLoading" class="pdf-container">
    <pdf-viewer
      #docSinglePhraseViewer
      [doc]="curDoc"
      [initialPage]="box.displayPageNum"
      [displayDrawMode]="false"
      [displayToggleBoundingBoxes]="false"
      [displayToggleAnnotations]="false"
      [displayPaging]="false"
      [singlePage]="singlePageSw"
      (onIframeLoaded)="onDocIframeLoaded()"
    ></pdf-viewer>
  </div>
</modal-popup>

<phrase-rev-rule-details
  *ngIf="ruleDetailsOpenSw"
  (onClose)="ruleDetailsOpenSw = false"
  [card]="card"
></phrase-rev-rule-details>

<rev-rule-details *ngIf="ruleImageDetailsOpenSw" (onClose)="ruleImageDetailsOpenSw = false"
(onStatusChange)="onRevStatusChange()" [focusOnOpen]="focusRuleDetailsOnOpen"></rev-rule-details>