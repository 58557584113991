import { NgRedux } from '@redux/redux-compatibility.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CardAssetTemplate } from 'src/app/shared/models/cat.model';
import { actionsList, AppState } from 'src/app/shared/redux/store';
import { CatService } from 'src/app/shared/services/cat.service';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'app-card-asset-templates',
  templateUrl: './card-asset-templates.component.html',
  styleUrls: ['./card-asset-templates.component.scss'],
})
export class CardAssetTemplatesComponent implements OnInit {
  loading: boolean;
  displayCreate: boolean;
  editData: CardAssetTemplate;

  get data(): CardAssetTemplate[] {
    return this.ngRedux.getState().cardAssetTemplates;
  }

  constructor(
    private ngRedux: NgRedux<AppState>,
    private router: Router,
    private sharedService: SharedService,
    private catService: CatService
  ) {}

  ngOnInit(): void {
    this.getData();
  }

  getData() {
    this.loading = true;
    this.catService.getCardAssetTemplates(true).subscribe(
      (data) => {
        this.loading = false;
      },
      (err) => (this.loading = false)
    );
  }

  create() {
    this.editData = new CardAssetTemplate();
    this.displayCreate = true;
  }

  view(row: CardAssetTemplate) {
    this.ngRedux.dispatch({
      type: actionsList.SELECT_CARD_ASSET_TEMPLATE,
      data: row,
    });

    this.router.navigate([
      this.sharedService.routeEnum.URL_CARD_ASSET_TEMPLATE_DET,
    ]);
  }

  edit(row: CardAssetTemplate) {
    this.editData = Object.assign(new CardAssetTemplate(), row);
    this.displayCreate = true;
  }

  onCreated() {
    this.displayCreate = false;
    this.getData();
  }
}
