<modal-popup
  [flexible]="true"
  [focusOnOpen]="false"
  [isOpen]="true"
  (onClose)="close()"
  [title]="doc.docName"
>
  <div class="container">
    <tabs>
      <tab-single
        tabTitle="{{ 'translate.lc.cards' | translate }}"
        tabId="tabIdList.LC_DOC_SUMMARY_CARDS_{{ type }}"
      >
        <div class="split-container">
          <div class="w-half">
            <lc-doc-cards
              [type]="type"
              [cardType]="taggedCardType"
              [title]="'translate.lc.tagged' | translate"
            ></lc-doc-cards>
          </div>
          <div class="divider"></div>
          <div class="w-half">
            <lc-doc-cards
              [type]="type"
              [doc]="doc"
              [cardType]="detectedCardType"
              [title]="'translate.lc.detected' | translate"
            ></lc-doc-cards>
          </div>
        </div>
      </tab-single>

      <tab-single
        tabTitle="{{ 'translate.lc.phrases' | translate }}"
        tabId="tabIdList.LC_DOC_SUMMARY_PHRASES_{{ type }}"
      >
        <div class="split-container">
          <div class="w-half">
            <lc-doc-phrases
              [type]="type"
              [doc]="doc"
              [taggedSw]="true"
              [title]="'translate.lc.tagged' | translate"
            ></lc-doc-phrases>
          </div>
          <div class="divider"></div>
          <div class="w-half">
            <lc-doc-phrases
              [type]="type"
              [doc]="doc"
              [taggedSw]="false"
              [title]="'translate.lc.detected' | translate"
            ></lc-doc-phrases>
          </div>
        </div>
      </tab-single>
    </tabs>
  </div>
</modal-popup>
