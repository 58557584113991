import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {
  AddInstr,
  DataElement,
  FileUpload,
} from 'src/app/shared/models/common';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from 'src/app/shared/services/shared.service';
import { UploadService } from 'src/app/shared/services/upload.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { CatService } from 'src/app/shared/services/cat.service';
import { AppState } from 'src/app/shared/redux/store';
import { NgRedux } from '@redux/redux-compatibility.service';

@Component({
  selector: 'cat-add-instr',
  templateUrl: './cat-add-instr.component.html',
  styleUrls: ['./cat-add-instr.component.scss'],
})
export class CatAddInstrComponent implements OnInit {
  @Input() instr: AddInstr;
  @Input() editable: boolean;
  @Output() onDelete = new EventEmitter();

  editMode: boolean;
  displayEditBtn: boolean;
  instrTypeOptions: any[] = [];
  isUploading: boolean;
  imgFileName: string;
  isValidFile: boolean;
  fileName: string;
  dispayImgPreview: boolean;

  constructor(
    private translateService: TranslateService,
    private sharedService: SharedService,
    private uploadService: UploadService,
    private authService: AuthService,
    private catService: CatService,
    private ngRedux: NgRedux<AppState>
  ) {
    this.instrTypeOptions = [
      { icon: 'pi pi-align-left', value: 'TXT' },
      { icon: 'pi pi-image', value: 'IMG' },
    ];
  }

  ngOnInit(): void {
    this.instr.saveOriginal();
    if (this.editable && !this.instr.id) {
      this.editMode = true;
    }

    if (this.instr.imgS3Key) {
      this.catService.assignOrSubscribeObjectForPresignedImgUrl(this.instr, this.instr.imgS3Key);
      this.catService.getS3Objects([this.instr.imgS3Key]).subscribe();
    }
  }

  discardChanges() {
    this.instr.restoreOriginal();
    this.editMode = false;
    this.instr.saveOriginal();
  }

  onDeleteClicked() {
    if (!this.instr.id) {
      this.onDelete.emit();
      return;
    }

    let validationStr = this.translateService.instant(
      'translate.cat.deleteInstr'
    );

    this.sharedService.emitPopupchReceivedEvent({
      title: 'translate.validation.confirm',
      icon: 'exclamation-triangle',
      subTitle: validationStr,
      keep: false,
      approve: () => {
        this.onDelete.emit();
      },
    });
  }

  uploadFile($event) {
    const fileData: File = $event.target.files[0];
    if (!fileData) return;
    this.isValidFile = undefined;
    this.imgFileName = null;
    this.fileName = null;

    this.isValidFile = this.uploadService.validateFile(
      fileData,
      SharedService.instrImgFileTypes
    );
    if (!this.isValidFile) return;

    this.isUploading = true;

    const file: FileUpload = new FileUpload();
    file.fileType = this.uploadService.getFileType(fileData);
    file.subFolder = this.ngRedux.getState().curEnv.docS3Bucket;
    this.fileName = fileData.name;

    (async () => {
      await this.uploadService.uploadFile(fileData);
      file.file = fileData;
      file.entityType = 'instr';
      this.imgFileName = null;

      this.uploadService
        .getPresignedUrl(file, this.authService.getPresignedUrl)
        .subscribe(
          (data) => {
            this.uploadService.putFile(file, data).subscribe(
              (res) => {
                this.imgFileName = file.file.name;
                this.instr.imgS3Key = data.s3Key;
                //this.catService.getS3Objects([this.instr.imgS3Key]).subscribe();

                this.uploadService
                  .encryptFile(
                    null,
                    null,
                    this.ngRedux.getState().curEnv.tmpS3Bucket,
                    this.ngRedux.getState().curEnv.docS3Bucket,
                    this.instr.imgS3Key
                  )
                  .subscribe(
                    (res) => {
                      this.isUploading = false;
                      this.catService.assignOrSubscribeObjectForPresignedImgUrl(this.instr, this.instr.imgS3Key);
                      this.catService.getS3Objects([this.instr.imgS3Key]).subscribe();
                    },
                    (err) => {
                      this.sharedService.alertError(err);
                      this.isUploading = false;
                    }
                  );
              },
              (err) => {
                this.sharedService.alertError(err);
                this.isUploading = false;
              }
            );
          },
          (err) => {
            this.sharedService.alertError(err);
            this.isUploading = false;
          }
        );
    })();

    $event.target.value = '';
  }

  removeFile() {
    this.imgFileName = null;
    this.fileName = null;
  }
}
