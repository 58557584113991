<div class="container">
  <loader *ngIf="loading"></loader>
  <ui-table
    *ngIf="!loading"
    class="t-small"
    [isWrapped]="false"
    [data]="refOptions"
    [displayFilter]="['pageNum']"
    [templates]="[
      {
        title: 'translate.general.text',
        template: phraseText
      },
      {
        title: 'translate.general.actions',
        template: actions
      }
    ]"
  ></ui-table>
</div>

<ng-template #phraseText let-row="row">
  <!-- {{ row.phraseText }} -->
  <p *ngIf="row.phraseWords.length == 0">
    {{ row.phraseText }}
  </p>
  <formatted-phrase
    *ngIf="row.phraseWords.length > 0"
    [phraseWords]="row.phraseWords"
  ></formatted-phrase>
</ng-template>

<ng-template #actions let-row="row">
  <a *ngIf="!isSelected(row)" (click)="add(row)"
    >{{ "translate.general.add" | translate }}
  </a>
  <a *ngIf="isSelected(row)" class="c-warn" (click)="remove(row)"
    >{{ "translate.general.remove" | translate }}
  </a>
</ng-template>
