import { NgRedux } from '@redux/redux-compatibility.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { IndPhrase } from 'src/app/shared/models/cat.model';
import { LabelChange } from 'src/app/shared/models/lc.model';
import { actionsList, AppState } from 'src/app/shared/redux/store';
import { AuthService } from 'src/app/shared/services/auth.service';
import { CatService } from 'src/app/shared/services/cat.service';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'app-lc-phrases',
  templateUrl: './lc-phrases.component.html',
  styleUrls: ['./lc-phrases.component.scss'],
})
export class LcPhrasesComponent implements OnInit {
  type: 'ac' | 'bc';

  get lc(): LabelChange {
    return this.ngRedux.getState().curLabelChange;
  }

  get phrases(): IndPhrase[] {
    return this.type == 'ac' ? this.lc.acPhrases : this.lc.bcPhrases;
  }

  constructor(
    private ngRedux: NgRedux<AppState>,
    private catService: CatService,
    private router: Router,
    private sharedService: SharedService,
    private authService: AuthService,
    private translateService: TranslateService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.ngRedux.dispatch({
      type: actionsList.SELECT_CATALOG,
      data: this.lc.catalog,
    });

    this.route.params.subscribe((params: Params) => {
      this.type = params['type'];
    });
  }
  addPhrases() {
    this.router.navigate([
      this.sharedService.routeEnum.URL_LC_SELECT_PHRASES,
      this.type,
    ]);
  }
}
