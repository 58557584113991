<div class="container">
  <loader *ngIf="loading"></loader>

  <div *ngFor="let card of docCards" class="w-full">
    <div class="card-title">
      <strong>{{ card.type.text }}</strong>
      &nbsp; &middot;
      {{ card.title }}
    </div>
    <div class="template-container">
      <div *ngFor="let doc of card.cardDocs">
        <div *ngFor="let box of doc.boundingBoxes" class="box-tile">
          <bb-tile-new
            id="{{ box.boxId }}"
            [box]="box"
            [doc]="doc"
            [allowEdit]="allowEditCard"
            [displayIndPhrases]="true"
            [selected]="curBox != null && curBox.boxId == box.boxId"
            (onDelete)="deleteBox(box, card)"
          >
          </bb-tile-new>
        </div>
      </div>
    </div>
  </div>
</div>
