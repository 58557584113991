import { NgRedux } from '@redux/redux-compatibility.service';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { DataElement } from 'src/app/shared/models/common';
import { AppState } from 'src/app/shared/redux/store';

@Component({
  selector: 'add-spelling-check-ignored',
  templateUrl: './add-spelling-check-ignored.component.html',
  styleUrls: ['./add-spelling-check-ignored.component.scss']
})
export class AddSpellingCheckIgnoredComponent implements OnInit {

  @Output() onAdd = new EventEmitter<DataElement>();
  @Output() onClose = new EventEmitter();
  spellingCheckIgnoredWord: DataElement;

  ngOnInit(): void {}

  add() {
    this.onAdd.emit(this.spellingCheckIgnoredWord);
  }

  close() {
    this.onClose.emit();
  }

}
