import { NgRedux } from '@redux/redux-compatibility.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Unsubscribe } from 'redux';
import { Observable, Subscription } from 'rxjs';
import {
  LabelChange,
  LCCardTypeEnum,
  LCSearchParams,
} from 'src/app/shared/models/lc.model';
import { Tab, TabIdList } from 'src/app/shared/models/tab.model';
import { actionsList, AppState } from 'src/app/shared/redux/store';
import { CatService } from 'src/app/shared/services/cat.service';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'app-lc-details',
  templateUrl: './lc-details.component.html',
  styleUrls: ['./lc-details.component.scss'],
})
export class LcDetailsComponent implements OnInit {
  loading: boolean;
  tabs: Tab[];
  tabIdList: TabIdList;
  reduxSubscribe: Unsubscribe;

  get lc(): LabelChange {
    return this.ngRedux.getState().curLabelChange;
  }

  get acCardsCnt(): string {
    let cnt = this.lc.cards.filter(
      (c) =>
        c.lcCardType.id == LCCardTypeEnum.LC_DONE_TAG && c.cardDocs.length > 0
    ).length;
    if (cnt > 0) {
      return '(' + cnt + ')';
    } else return '';
  }

  get bcCardsCnt(): string {
    let cnt = this.lc.cards.filter(
      (c) =>
        c.lcCardType.id == LCCardTypeEnum.LC_NEED_TAG && c.cardDocs.length > 0
    ).length;
    if (cnt > 0) {
      return '(' + cnt + ')';
    } else return '';
  }

  get acDocsCnt(): string {
    if (this.lc.acDocs.length > 0) {
      return '(' + this.lc.acDocs.length + ')';
    } else return '';
  }

  get bcDocsCnt(): string {
    if (this.lc.bcDocs.length > 0) {
      return '(' + this.lc.bcDocs.length + ')';
    } else return '';
  }

  get bcPhrasesCnt(): string {
    if (this.lc.bcPhrases.length > 0) {
      return '(' + this.lc.bcPhrases.length + ')';
    } else return '';
  }

  get acPhrasesCnt(): string {
    if (this.lc.acPhrases.length > 0) {
      return '(' + this.lc.acPhrases.length + ')';
    } else return '';
  }

  constructor(
    private ngRedux: NgRedux<AppState>,
    private catService: CatService,
    private router: Router,
    private sharedService: SharedService,
    private translateService: TranslateService
  ) {
    this.tabIdList = this.ngRedux.getState().ui_tabIdList;

    this.reduxSubscribe = this.ngRedux.subscribe(() => {
      const appState = this.ngRedux.getState();
      switch (appState._currentAction) {
        case actionsList.RELOAD_CUR_LC_REQ:
          this.loadCurLC();
          let ob = this.ngRedux.getState()
            ._currentActionData as Observable<any>;
          if (ob) {
            ob.subscribe({
              next: () => {
                this.loadCurLC();
              },
            });
          }
          break;
      }
    });
  }

  ngOnDestroy() {
    this.reduxSubscribe();
  }

  ngOnInit(): void {
    this.loading = true;
    this.loadCurLC();
  }

  goBack() {
    this.router.navigate([this.sharedService.routeEnum.URL_LC]);

    this.ngRedux.dispatch({
      type: actionsList.SELECT_LC,
      data: null,
    });
  }

  loadCurLC() {
    this.catService.getLCDetails(this.lc.lcId).subscribe(
      (data) => {
        this.ngRedux.dispatch({
          type: actionsList.SELECT_LC,
          data: data,
        });
        this.loading = false;
      },
      (err) => {
        this.loading = false;
      }
    );
  }

  publish() {
    this.loading = true;
    this.catService.publishLC(this.lc).subscribe((data) => {
      this.loading = false;
    });
  }
}
