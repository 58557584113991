import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DocViewerComponent } from 'src/app/shared/components/doc-viewer/doc-viewer.component';
import { PdfViewerComponent } from 'src/app/shared/components/pdf-viewer/pdf-viewer.component';
import { InputWrapComponent } from 'src/app/shared/components/ui/input-wrap.component';
import { BoundingBox, CatDoc } from 'src/app/shared/models/cat.model';
import { DocPage } from 'src/app/shared/models/common';
import { CatService } from 'src/app/shared/services/cat.service';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'bb-card',
  templateUrl: './bb-card.component.html',
  styleUrls: ['./bb-card.component.scss'],
})
export class BbCardComponent implements OnInit {
  @ViewChild('docSinglePhraseViewer') docViewer: PdfViewerComponent;

  @Input() box: BoundingBox;
  @Input() doc: CatDoc;

  @Output() onViewBoxPage = new EventEmitter();
  @Output() onDelete = new EventEmitter();

  displayDocViewer: boolean;
  curDoc: CatDoc;
  docLoading: boolean;
  boxAdded: boolean;
  singlePageSw: boolean;

  constructor(
    private sharedService: SharedService,
    private translateService: TranslateService,
    private catService: CatService
  ) {}

  ngOnInit(): void {}

  viewBox(singlePage: boolean = false) {
    this.docLoading = true;
    this.singlePageSw = singlePage;
    this.curDoc = new CatDoc();
    this.curDoc.docName = this.doc.docName;
    this.curDoc.docId = this.doc.docId;
    this.docLoading = false;
    this.boxAdded = false;
    this.displayDocViewer = true;
  }

  onDocIframeLoaded(doc: CatDoc) {
    if (!this.boxAdded) {
      if (this.docViewer.displayedPage == this.box.displayPageNum) {
        this.docViewer.displayBoundingBox(this.box);
        this.boxAdded = true;
      } else {
        this.docViewer.goToPage(this.box.displayPageNum);
      }
    }
  }
}
