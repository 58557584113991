<div *ngIf="curCat">
  <ng-container *ngTemplateOutlet="filterArea"></ng-container>

  <loader *ngIf="loading"></loader>

  <ui-table
    *ngIf="data"
    [isWrapped]="false"
    [data]="data"
    [displayFilter]="['extRefId']"
    [templates]="[
      {
        title: 'translate.general.docIdLong',
        template: docId
      },
      {
        title: 'translate.general.docName',
        template: docName
      },
      {
        title: 'translate.general.source',
        template: source
      },
      {
        title: 'translate.general.extDocId',
        template: extDocId
      },
      {
        title: 'translate.general.extDocName',
        template: extDocName
      },
      {
        title: 'translate.general.ref',
        template: ref
      }
    ]"
    [more]="expandRow"
  ></ui-table>
  <!-- 
  <ng-template #docId let-row="row">
    {{ row.docId }}
  </ng-template> -->

  <ng-template #docName let-row="row">
    {{ row.docName }}
  </ng-template>

  <!-- <ng-template #phraseId let-row="row">
    {{ row.phraseId }}
  </ng-template> -->

  <ng-template #source let-row="row">
    <div class="l-align-spaceBetween">
      <formatted-phrase
        *ngIf="row.phraseDispWords"
        [phraseWords]="row.phraseDispWords"
      ></formatted-phrase>
    </div>
  </ng-template>

  <!-- <ng-template #extDocId let-row="row">
    {{ row.extDocId }}
  </ng-template> -->

  <ng-template #extDocName let-row="row">
    {{ row.extDocName }}
  </ng-template>

  <!-- <ng-template #extPhraseId let-row="row">
    {{ row.extPhraseId }}
  </ng-template> -->

  <ng-template #ref let-row="row">
    <div class="l-align-spaceBetween">
      <formatted-phrase
        *ngIf="row.extPhraseDispWords"
        [phraseWords]="row.extPhraseDispWords"
      ></formatted-phrase>
    </div>
  </ng-template>

  <ng-template #expandRow let-row="row">
    <div class="l-align-spaceBetween">
      <data-box
        class="w-third"
        [data]="row"
        [displayFilter]="['srcDocId', 'srcPhraseId']"
        [columns]="1"
        [belowData]="actionsSrc"
      ></data-box>

      <data-box
        class="w-third"
        [data]="row"
        [displayFilter]="['extDocId', 'extPhraseId']"
        [columns]="1"
        [belowData]="actionsTarget"
      ></data-box>
    </div>

    <ng-template #actionsSrc>
      <br />
      <a (click)="viewPhraseDoc(row.srcDocId, row.ann, true, row.pageNum)"
        >{{ "translate.general.viewPage" | translate }}
      </a>
      <span class="action-divider">|</span>
      <a (click)="viewPhraseDoc(row.srcDocId, row.ann)"
        >{{ "translate.general.viewDoc" | translate }}
      </a>
    </ng-template>

    <ng-template #actionsTarget>
      <br />
      <a (click)="viewPhraseDoc(row.extDocId, row.extAnn, true, row.extPageNum)"
        >{{ "translate.general.viewPage" | translate }}
      </a>
      <span class="action-divider">|</span>
      <a (click)="viewPhraseDoc(row.extDocId, row.extAnn)"
        >{{ "translate.general.viewDoc" | translate }}
      </a>
    </ng-template>
  </ng-template>

  <ng-template #filterArea>
    <div class="filter-bar l-align-spaceBetween">
      <div class="filters">
        <input-wrap class="t-input-search-wrap">
          <input
            class="t-input"
            type="text"
            [(ngModel)]="filterPhrase"
            placeholder="{{ 'translate.general.phraseText' | translate }}"
            (keypress)="onFilterKeyPress($event)"
          />
        </input-wrap>

        <!-- docs -->
        <input-wrap>
          <p-dropdown
            [options]="catDocs"
            optionLabel="label"
            [(ngModel)]="filterDoc"
            (onChange)="onFieldValueChanged($event)"
            [autoDisplayFirst]="false"
            [placeholder]="'translate.general.srcDoc' | translate"
          >
          </p-dropdown>
        </input-wrap>

        <input-wrap class="t-input-search-wrap">
          <input
            class="t-input"
            type="text"
            [(ngModel)]="filterExtPhrase"
            placeholder="{{ 'translate.cat.extPhrase' | translate }}"
            (keypress)="onFilterKeyPress($event)"
          />
        </input-wrap>

        <!-- ext docs -->
        <input-wrap>
          <p-dropdown
            [options]="extDocs"
            optionLabel="label"
            [(ngModel)]="filterExtDoc"
            (onChange)="onFieldValueChanged($event)"
            [autoDisplayFirst]="false"
            [placeholder]="'translate.general.extDocName' | translate"
          >
          </p-dropdown>
        </input-wrap>

        <a *ngIf="displayClearFilter" (click)="clearFilter()">{{
          "translate.general.clear" | translate
        }}</a>
      </div>
    </div>
  </ng-template>
</div>

<modal-popup
  *ngIf="displayDocViewer"
  [flexible]="false"
  [hideHeader]="false"
  [title]="curDoc.docName"
  [isOpen]="displayDocViewer"
  (onClose)="displayDocViewer = false"
>
  <div class="pdf-container">
    <pdf-viewer
      #docSinglePhraseViewer
      [doc]="curDoc"
      [initialPage]="curPage"
      [displayDrawMode]="false"
      [displayToggleBoundingBoxes]="false"
      [displayPaging]="!singlePageSw"
      [singlePage]="singlePageSw"
      (onToggleAnnotations)="onToggleAnnotations($event)"
      (onIframeLoaded)="onDocIframeLoaded()"
    ></pdf-viewer>
  </div>
</modal-popup>
