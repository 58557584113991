<div class="l-align-spaceBetween t-row--med">
  <p-dropdown
    [options]="card.transLang"
    optionLabel="text"
    [(ngModel)]="selectedLang"
    [placeholder]="'translate.general.lang' | translate"
    appendTo="body"
    baseZIndex="100"
    (onChange)="getData()"
  >
  </p-dropdown>

  <button
    *ngIf="transCard"
    (click)="save()"
    class="t-button"
    [disabled]="saving"
  >
    {{ "translate.general.save" | translate }}
  </button>
</div>

<loader *ngIf="loading"></loader>

<div *ngIf="!loading && transCard">
  <div *ngFor="let doc of transCard.cardDocs" class="card-summary doc-wrapper">
    <p-panel>
      <p-header style="width: 100%">
        <div class="doc-container">
          <div class="doc-title">
            <p class="fw-bold">{{ doc.docName }}</p>
            <div class="date">
              <span
                >{{ "translate.general.documentId" | translate }}:
                {{ doc.docId }}</span
              >
              &nbsp;|&nbsp;
              <span>{{ doc.createDate }}</span>
            </div>
          </div>
        </div>
      </p-header>
      <div>
        <div *ngFor="let box of doc.boundingBoxes" class="box-tile">
          <bb-tile-new
            [box]="box"
            [doc]="doc"
            [allowEdit]="false"
            [transSw]="true"
            [transLang]="selectedLang.id"
          >
          </bb-tile-new>
        </div>
      </div>
    </p-panel>
  </div>
</div>
