import { Component, Input, OnInit } from '@angular/core';
import { IndPhrase } from '@shared/models/cat.model';
import { AssociatedImage, AssociatedImageFinding, AssociatedTextFinding, AssociatedTextPhrase } from '@shared/models/common';

@Component({
  selector: 'cat-match-phrase-assoc-image',
  templateUrl: './cat-match-phrase-assoc-image.component.html',
  styleUrl: './cat-match-phrase-assoc-image.component.scss'
})
export class CatMatchPhraseAssocImageComponent implements OnInit {
  @Input() associatedImage?: AssociatedImage;
  @Input() mainPhrase: IndPhrase;
  @Input() associatedImageFinding?: AssociatedImageFinding;

  ngOnInit(): void {
    // console.log("associated text: ", this.associatedImage)
    // console.log("main phrase: ", this.mainPhrase)
    // console.log("assoc text finding: ", this.associatedImageFinding)
  }

  catPhraseAssocImage(catAssocImageId): AssociatedImage {
    return this.mainPhrase.associatedImages.find(a => a.assocImageId === catAssocImageId);
  }

}
