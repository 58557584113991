import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { config, CognitoIdentityCredentials } from 'aws-sdk';
import { CognitoUserPool, CognitoUser, AuthenticationDetails, CognitoUserSession } from 'amazon-cognito-identity-js';
import * as QRCode from 'qrcodejs2-fix';

import { SharedService } from '../services/shared.service';
import { TranslateService } from '@ngx-translate/core';

export interface Callback {
  cognitoCallback(message: string, result: any): void;
  cognitoCallbackWithCreds(
    message: string,
    result: any,
    creds: any,
    data: any
  ): void;
  googleCallback(creds: any, profile: any);
  googleCallbackWithData(data: any);
  testCallback(result: any, err: any);
}

@Injectable()
export class AwsService {
  token: any;
  googleCreds: any;
  googleProfile: any;
  googleData: any;
  userData: any;

  /*********** RESOURCE IDENTIFIERS ************/

  // prodPoolData = {
  //   UserPoolId: "eu-central-1_Gyptu4OFh",
  //   ClientId: "63bnv3jlvllbvogpu4ijh9nros",
  // };
  // region: string = "eu-central-1";

  prodPoolData = {
    UserPoolId: SharedService.cognitoUserPoolId,
    ClientId: SharedService.cognitoClientId,
  };
  region: string = SharedService.cognitoRegion;

  /*********************************************/

  constructor(private _http: HttpClient, private sharedService: SharedService, private translateService: TranslateService) {
    config.update({
      region: this.region,
      credentials: new CognitoIdentityCredentials({
        IdentityPoolId: '',
      }),
    });

    config.region = this.region;
  }

  refreshTokens(user: any, refreshToken: any, refreshCallback: any) {
    user.refreshSession(refreshToken, (err, session) => {
      refreshCallback(err, session);
    });
  }

  signOutUser(cognitoUser: any, callback) {
    if (!cognitoUser) {
      callback();
      return;
    }

    cognitoUser.globalSignOut({
      onSuccess: function (result) {
        console.log(result);
        callback(result);
      },
      onFailure: function (err) {
        console.log(err);
        callback(err);
      },
    });
  }

  authenticateUserPool(user, password, authCallback) {
    let authenticationData = {
      Username: user,
      Password: password,
    };
    let authenticationDetails = new AuthenticationDetails(
      authenticationData
    );

    let userPool = new CognitoUserPool(this.prodPoolData);

    let userData = {
      Username: user,
      Pool: userPool,
    };

    let cognitoUser = new CognitoUser(userData) as any;

    const aws = this;

    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: function (result) {
        console.log('onSuccess');
        let cognitoGetUser = userPool.getCurrentUser();
        if (cognitoGetUser != null) {
          cognitoGetUser.getSession(function (err, result) {
            if (result) {
              console.log('Authenticated to Cognito User Pools!');
            }
          });
        }

        console.log(this);
        authCallback(null, result, cognitoGetUser);
      },

      onFailure: function (err) {
        console.log('onFailure');
        console.log(err);
        authCallback(err, null);
      },

      mfaSetup: function (challengeName, challengeParameters) {
        console.log('mfaSetup');
        cognitoUser.associateSoftwareToken(this);
      },

      associateSecretCode: function (secretCode) {
        console.log('associateSecretCode');
        let qrCode = 'otpauth://totp/SCAI?secret=' + secretCode;

        let callbackObj = this;

        aws.sharedService.emitPopupchReceivedEvent({
          title: 'translate.alert.initialIdCode',
          subTitle: ['translate.alert.scanCode', secretCode],
          approveLabel: 'translate.alert.continue',
          approve: () => {
            callbackObj.iscsEnterFirstTokenCode();
          },
          keep: true,
          onload(el: HTMLElement) {
            const popupMain: HTMLElement = el.querySelector('.popup-main');
            const codeHere = document.createElement('div');
            codeHere.innerHTML = '';
            codeHere.className = 'qr_code_holder';

            var qrcode = new QRCode(codeHere, {
              text: qrCode,
              width: 128,
              height: 128,
              colorDark: '#000000',
              colorLight: '#ffffff',
              correctLevel: QRCode.CorrectLevel.M,
            });

            popupMain && popupMain.appendChild(codeHere);
            console.log(qrcode);


            popupMain && popupMain.appendChild(codeHere);
          },
        });
      },

      selectMFAType: function (challengeName, challengeParameters) {
        console.log('selectMFAType');
        var mfaType = 'SOFTWARE_TOKEN_MFA'; // prompt("Please select the MFA method.", "");
        cognitoUser.sendMFASelectionAnswer(mfaType, this);
      },

      totpRequired: function (secretCode) {
        console.log('totpRequired');
        console.log(authenticationDetails.getValidationData());
        aws.sharedService.emitPopupchReceivedEvent({
          /*יש להקליד קוד חד-פעמי לזיהוי:*/
          title: 'translate.alert.fillAuthCode',
          subTitle: 'translate.validation.putCodeHere',
          form: [
            {
              label: 'translate.validation.putCodeHere',
              cssClass: 'input-sent-code',
            },
          ],

          approve: (el: HTMLElement) => {
            let newPass: string;
            const popupField: HTMLInputElement =
              el.querySelector('.input-sent-code');
            if (popupField) {
              newPass = popupField.value;
              cognitoUser.sendMFACode(newPass, this, 'SOFTWARE_TOKEN_MFA');
            } else {
              aws.sharedService.alertError({
                message: 'Flow issue in totpRequired',
              });
            }
          },
        });
      },

      iscsEnterFirstTokenCode: function () {
        console.log('iscsEnterFirstTokenCode');

        aws.sharedService.emitPopupchReceivedEvent({});

        aws.sharedService.emitPopupchReceivedEvent({
          /* יש להקליד קוד חד-פעמי לזיהוי: */
          title: 'translate.alert.fillAuthCode',
          subTitle: 'translate.validation.putCodeHere',
          form: [
            {
              label: 'translate.validation.putCodeHere',
              cssClass: 'input-sent-code',
            },
          ],
          keep: true,
          onload: (el: HTMLElement) => {
            const qr_code_holder = el.querySelector('.qr_code_holder');
            console.log(qr_code_holder);
            if (qr_code_holder) {
              qr_code_holder.parentNode.removeChild(qr_code_holder);
            }
          },
          approve: (el: HTMLElement) => {
            let newPass: string;
            const popupField: HTMLInputElement =
              el.querySelector('.input-sent-code');
            if (popupField) {
              newPass = popupField.value;
              cognitoUser.verifySoftwareToken(newPass, 'My TOTP device', this);
              aws.sharedService.emitPopupchReceivedEvent({});
            } else {
              aws.sharedService.alertError({
                message: 'Flow issue in iscsEnterFirstTokenCode',
              });
            }
          },
        });
      },

      newPasswordRequired: function (userAttributes, requiredAttributes) {
        console.log('newPasswordRequired');
        console.log(userAttributes);

        //userAttributes.email_verified = userAttributes.email_verified;
        //userAttributes.phone_number_verified = userAttributes.phone_number_verified;

        aws.sharedService.emitPopupchReceivedEvent({
          title: 'translate.alert.choosePassword',
          subTitle: aws.sharedService.passwordRequirementsMessage(),
          form: [
            {
              label: 'translate.alert.choosePassword', // "יש לבחור סיסמא קבועה שתשמש אותך להתחברות"//
              cssClass: 'input-sent-code',
              type:'password'
            },
          ],

          approve: (el: HTMLElement) => {
            let newPass: string;
            const popupField: HTMLInputElement =
              el.querySelector('.input-sent-code');
            if (popupField) {
              newPass = popupField.value;

              let validationMessage = aws.sharedService.validatePassword(newPass)
              if (validationMessage) {
                throw {title: aws.translateService.instant('translate.alert.passwordRequirementsIssue'), message: validationMessage};
              }

              cognitoUser.completeNewPasswordChallenge(newPass, null, this);
            } else {
              aws.sharedService.alertError({
                message: 'Flow issue in newPasswordRequired',
              });
            }
          },
        });
      },

      mfaRequired: function (codeDeliveryDetails) {
        console.log('mfaRequired');
        aws.sharedService.emitPopupchReceivedEvent({
          title: 'translate.alert.inputSMSIdCode',
          form: [
            {
              label: 'translate.alert.inputSMSIdCode',
              cssClass: 'input-sent-code',
            },
          ],
          keep: true,
          approve: (el: HTMLElement) => {
            let verificationCode: string;
            const popupField: HTMLInputElement =
              el.querySelector('.input-sent-code');
            if (popupField) verificationCode = popupField.value;
            console.log(verificationCode);

            if (verificationCode) {
              cognitoUser.sendMFACode(verificationCode, {
                onSuccess: function (result) {
                  // User authentication was successful
                  console.log('result', result);
                  let cognitoGetUser = userPool.getCurrentUser();
                  authCallback(null, result, cognitoGetUser);
                },

                onFailure: function (err) {
                  // User authentication was not successful
                  console.log('err', err);
                  authCallback(err, null);
                },
              });
            } else {
              authCallback('EMPTY_TOKEN', null);
            }

            aws.sharedService.emitPopupchReceivedEvent({});
          },
        });
      },
    });
  }
  newAuthentication(userName: string, password: string, callback: any) {
    let userPool = new CognitoUserPool(this.prodPoolData);

    let userData = {
      Username: userName,
      Pool: userPool,
    };
    let cognitoUser = new CognitoUser(userData) as any;

    cognitoUser.associateSecretCode();
  }
  forgotPassword(username: string, callback: any) {
    let authenticationData = {
      Username: username,
      Password: '',
    };
    let authenticationDetails = new AuthenticationDetails(
      authenticationData
    );

    let userPool = new CognitoUserPool(this.prodPoolData);

    let userData = {
      Username: username,
      Pool: userPool,
    };

    let cognitoUser = new CognitoUser(userData);

    const aws = this;

    console.log('forgotPassword');
    console.log(cognitoUser);

    cognitoUser.forgotPassword({
      onSuccess: (result) => {
        console.log('call result: ');
        console.log(result);
        callback('SUCCESS', result, cognitoUser);
      },
      onFailure: function (err) {
        console.log(err);
        callback('ERROR', err, cognitoUser);
      },
      inputVerificationCode() {
        aws.sharedService.emitPopupchReceivedEvent({
          //"יש להזין קוד אימות שנשלח אליך לכתובת המייל:"
          title: 'translate.alert.inputEmailIdCodeTitle',
          subTitle: 'translate.alert.inputEmailIdCode',
          form: [
            {
              label: 'translate.alert.inputEmailIdCode',
              cssClass: 'input-sent-code',
            },
          ],
          keep: true,
          approve: (el: HTMLElement) => {
            let token: string;
            const popupField: HTMLInputElement =
              el.querySelector('.input-sent-code');
            if (popupField) token = popupField.value;
            console.log(token);
            if (token) {
              aws.sharedService.emitPopupchReceivedEvent({
                title: 'translate.alert.choosePassword',
                form: [
                  {
                    label: 'translate.alert.inputEmailIdCode',
                    cssClass: 'input-sent-code',
                  },
                ],
                keep: true,
                approve: (el: HTMLElement) => {
                  let newPass: string;
                  const popupField: HTMLInputElement =
                    el.querySelector('.input-sent-code');
                  if (popupField) newPass = popupField.value;
                  aws.sharedService.capturedUserPassword = newPass;
                  cognitoUser.confirmPassword(token, newPass, this);
                },
              });
            }
          },
        });
      },
    });
  }

  private _serverError(err: any) {
    console.log('sever error:', JSON.stringify(err)); // debug
    if (err.status === 0) {
      return Observable.throw(err['error'] || 'UNAUTHORIZED!!!');
    }
    if (err instanceof Response) {
      return Observable.throw(err['error'] || 'Backend Server Error');
    }
  }

  retrieveUserSession(callback) {
    let userPool = new CognitoUserPool(this.prodPoolData);
    var cognitoUser = userPool.getCurrentUser();

    if (cognitoUser != null) {
      cognitoUser.getSession(function (err, session) {
        if (err) {
          //alert(err.message || JSON.stringify(err));
          console.log(err);
          callback(false, cognitoUser);
          return;
        }
        callback(session.isValid(), cognitoUser);
      });
    } else {
      callback(false, null);
    }
  }

  getCognitoToken(): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      let userPool = new CognitoUserPool(this.prodPoolData); // TODO check if this was time-consuming/candidate for caching?
      const currentUser = userPool.getCurrentUser();
      if (currentUser != null) {
        currentUser.getSession((err: Error, session: CognitoUserSession) => {
          if (err) {
            console.log('Error getting Cognito user session:', err);
            resolve(undefined);
          }
          if (session.isValid()) {
            resolve(session.getIdToken().getJwtToken());
          } else {
            currentUser.refreshSession(session.getRefreshToken(), (err: Error, session: CognitoUserSession) => {
              if (err) {
                console.log('Error refreshing Cognito user session:', err);
                resolve(undefined);
              }
              console.log('Cognito user session successfully refreshed');
              resolve(session.getIdToken().getJwtToken());
            });
          }
        });
      } else {
        console.log('No Cognito user session found!');
        resolve(undefined);
      }
    })
  }
}
