<h2 class="t-widgetTitle">{{ title }} ({{ phrases.length }})</h2>

<div *ngFor="let phrase of phrases" class="tile-container">
  <div class="phrase">
    <formatted-phrase [phraseWords]="phrase.phraseWords"></formatted-phrase>
  </div>

  <div class="l-align-spaceBetween">
    <div class="cat-phrase-type">
      {{ phrase.typeDescr }}
      <span class="sub-type">&middot; {{ phrase.subTypeDescr }}</span>
    </div>
    <span class="id">
      {{ "translate.general.id" | translate }}: {{ phrase.elementId }}</span
    >
  </div>
</div>
