<loader *ngIf="loading"></loader>

<ui-table
 *ngIf="data" 
 [isWrapped]="false" 
 [data]="data" 
 [templates]="[
  {
    title: 'translate.general.refDocName',
    template: documentId
  },
  {
    title: 'translate.general.refText',
    template: referenceText
  }
]">
</ui-table>

<ng-template #documentId let-row="row">
  {{row.document_name}}
 
</ng-template>

<ng-template #referenceText let-row="row">
  {{ row.reference_text }}
</ng-template>
