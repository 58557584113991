<modal-popup [flexible]="true" [focusOnOpen]="focusOnOpen" [isOpen]="true" (onClose)="close()"
  [title]="'translate.general.revRuleDetails'" [afterTitle]="dirtyBtns">
  <div *ngIf="curRule">
    <div [ngClass]="{ 'phrase-rev-rule-container': ruleCatMatches.length > 0,
                      'phrase-rev-rule-single-container': !(ruleCatMatches.length > 0) }">
      <div class="l-align-spaceBetween">
        <h3 *ngIf="curRule.similarityCode !== 'MISSING'">
          {{ "translate.general.workingPhrase" | translate }}
        </h3>
        <h3 *ngIf="curRule.similarityCode == 'MISSING'">
          {{ "translate.rev.missing" | translate }}:
        </h3>
        <p *ngIf="getMatchStatusCode()" [ngClass]="['rev-rule-status', getMatchStatusCode()]">
          {{ getMatchStatusDescr() }}
        </p>
        <spinner *ngIf="!curRule.revStatusCode" class="rev-rule-status-spinner"></spinner>
      </div>

      <div *ngIf="ruleCatMatches.length > 0" class="phrase-rev-rule-navigation">
        <div class="phrase-rev-rule-nav-button-continer">
          <button (click)="prevMatch()" [disabled]="currentIndex === 0" class="phrase-rev-rule-nav-button phrase-rev-rule-nav-button-left">
            <i class="fas fa-arrow-left"></i>
            &nbsp;{{ "translate.cat.prev" | translate }}
            <span *ngIf="currentIndex > 0"></span>
          </button>
        </div>

        <div class="phrase-rev-rule-current">Current match</div>

        <div class="phrase-rev-rule-nav-button-continer">
          <button (click)="nextMatch()" [disabled]="currentIndex === ruleCatMatches.length - 1"
            class="phrase-rev-rule-nav-button phrase-rev-rule-nav-button-right">
            &nbsp;{{ "translate.cat.next" | translate }}
            <span *ngIf="currentIndex < ruleCatMatches.length - 1"></span>
            <i class="fas fa-arrow-right"></i>
          </button>
        </div>
      </div>

      <div class="phrase-rev-rule-phrase-txt">
        <p class="phrase-txt">
          <formatted-phrase [phraseWords]="curRule.ruleDispWords"
            [grammarSpellingIssues]="curRule.grammarSpellingIssues"
            (onAddToWhitelist)="addToWhitelist($event)"
            [referenceIssues]="curRule.referenceIssues"></formatted-phrase>
        </p>
      </div>

      <div *ngIf="ruleCatMatches.length > 0">
        <cat-match-phrase [catMatch]="ruleCatMatches[currentIndex]"></cat-match-phrase>
      </div>
    </div>

    <div [ngClass]="{
      'scrollable-grid': true,
      'phrase-rev-rule-container': ruleCatMatches.length > 0,
      'phrase-rev-rule-single-container': !(ruleCatMatches.length > 0)}">

      <ng-container *ngIf="curRule.references">
        <p class="phrase-rev-rule-ref-title">{{ "translate.rev.reference" | translate }}:</p>
        <ng-container *ngIf="ruleCatMatches.length > 0">
          <p class="phrase-rev-rule-ref-title">{{ "translate.rev.reference" | translate }}:</p>
        </ng-container>
        <ng-container *ngFor="let ref of curRule.references; let i = index">
          <div class="phrase-rev-rule-ref-container">
            <p *ngIf="ref.phraseDispWords.length == 0" class="text">
              {{ ref.refPhraseText }}
            </p>
            <div [ngClass]="{'ref-match-container': ruleCatMatches.length > 0 && getReferenceIssue(ruleCatMatches[currentIndex].catPhrase.phraseId, ref.refPhraseId)?.refFinding === 'ADDED_REFERENCE'}">
              <div>
                <formatted-phrase *ngIf="ref.phraseDispWords.length > 0" class="text"
                  [phraseWords]="ref.phraseDispWords"></formatted-phrase>
                  <p class="ref-id">
                    {{ "translate.cat.phraseId" | translate }}: {{ ref.refPhraseId }}
                  </p>
              </div>
              <div *ngIf="ruleCatMatches.length > 0 && getReferenceIssue(ruleCatMatches[currentIndex].catPhrase.phraseId, ref.refPhraseId)?.refFinding === 'ADDED_REFERENCE'" class="ref-similarity-code">
                <p class="extra">
                  {{ "translate.rev.refAdded" | translate }}
                </p>
              </div>
            </div>
          </div>

          <ng-container *ngIf="ruleCatMatches.length > 0">
            <div class="issues-column">
              <ng-container *ngIf="getReferenceIssue(ruleCatMatches[currentIndex].catPhrase.phraseId, ref.refPhraseId)?.refFinding === 'ADDED_REFERENCE'">
                <div class="ref missing-reference"></div>
              </ng-container>
              <ng-container>
                <ng-container *ngIf="!(getReferenceIssue(ruleCatMatches[currentIndex].catPhrase.phraseId, ref.refPhraseId)?.refFinding === 'ADDED_REFERENCE')">
                  <cat-match-phrase-ref class="ref" [ref]="ref" [mainPhrase]="ruleCatMatches[currentIndex].catPhrase"
                    [referenceIssue]="getReferenceIssue(ruleCatMatches[currentIndex].catPhrase.phraseId, ref.refPhraseId)">
                  </cat-match-phrase-ref>
                </ng-container>
              </ng-container>
            </div>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="ruleCatMatches.length > 0">
          <ng-container *ngFor="let issue of getMissingReferenceIssue(ruleCatMatches[currentIndex].catPhrase.phraseId)">
            <div class="reference-column">
              <div class="ref missing-reference"></div>
            </div>
            <div class="issues-column">
              <div class="phrase-rev-rule-ref-container">
                <div class="ref-match-container">
                  <div>
                    <p class="text">
                      {{ getCatPhraseReference(issue.catRefPhraseId)?.refPhraseText }}
                    </p>
                    <p class="ref-id">
                      {{ "translate.cat.phraseId" | translate }}: {{ issue.catRefPhraseId }}
                    </p>
                  </div>
                  <div class="ref-similarity-code">
                    <p class="missing">
                      {{ "translate.rev.refMissing" | translate }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="curRule.referencedBy">
        <div>
        <p class="phrase-rev-rule-ref-title">{{ "translate.rev.referencedBy" | translate }}:</p>
        <p></p>
        <div *ngFor="let ref of curRule.referencedBy" class="phrase-rev-rule-ref-by-container">
          <p *ngIf="ref.phraseDispWords.length == 0" class="text">
            {{ ref.refPhraseText }}
          </p>
          <formatted-phrase *ngIf="ref.phraseDispWords.length > 0" [phraseWords]="ref.phraseDispWords"
            class="text"></formatted-phrase>
        </div>
        </div>
      </ng-container>
      <ng-container *ngIf="isPhrase && curRule.referencedBy"><div></div></ng-container>
    </div>

    <div *ngIf="!ruleCatMatches?.length > 0">
      <div class="template-container">
        <h3>
          {{ "translate.rev.noMatchesFound" | translate }}
        </h3>
      </div>
    </div>

    <div class="l-align-spaceBetween">
      <div class="l-align-spaceBetween">
        <p [ngClass]="['rev-rule-type-descr', curRule.revRuleType]">
          {{ curRule.revRuleSimilarityEventDesc || curRule.revRuleTypeDescr }}
        </p>
        <p class="cat-phrase-type">
          &nbsp; &middot; {{ curRule.revRuleContentTypeDesc || curRule.similarityDescr }}
        </p>
      </div>

      <p-selectButton *ngIf="editable" class="rev-status" [options]="ruleStatusOptions"
        [(ngModel)]="curRule.ruleUserStatus" (onChange)="onRevStatusChange()"></p-selectButton>
    </div>

    <div class="comment-container">
      <p class="t-footnote t-row--small">
        {{ "translate.general.comment" | translate }}
      </p>
      <textarea class="comment" tabindex="0" [disabled]="!editable" pInputTextarea [(ngModel)]="curRule.comment"
        autoResize="true" (ngModelChange)="dirtySw = true"></textarea>
    </div>

    <div>
      <ng-container *ngTemplateOutlet="formatMatchTemplate"></ng-container>

      <div *ngIf="curRule.revRuleType == 'SPELLING'">
        <ng-container *ngTemplateOutlet="spellingTemplate"></ng-container>
      </div>

      <div *ngIf="curRule.cardMatch">
        <ng-container *ngTemplateOutlet="cardMatchTemplate"></ng-container>
      </div>
    </div>

    <p class="footnote id">
      {{ "translate.rev.ruleId" | translate }}: {{ curRule.revRuleId }}
    </p>
    <loader *ngIf="loading"></loader>
  </div>
</modal-popup>

<ng-template #spellingTemplate>
  <div class="template-container">
    <h3>{{ "translate.rev.suggestedFix" | translate }}</h3>
    <p class="template-phrase-txt">
      <span *ngFor="let word of curRule.ruleDiffParts">{{ word.text }}</span>
    </p>
  </div>
</ng-template>

<ng-template #cardMatchTemplate>
  <card-match [cardMatch]="curRule.cardMatch"></card-match>
</ng-template>

<ng-template #formatMatchTemplate>
  <div *ngIf="formatMatchEntry.length" class="template-container">
    <div class="l-align-spaceBetween">
      <h3>
        {{ "translate.general.formatMatch" | translate }}
      </h3>
    </div>

    <div class="template-phrase-txt">
      <formatted-phrase [phraseWords]="formatMatchEntry" [formatType]="'formatMatchEntry'"
        [formatMatchDifferences]="formatMatchDifferences" [highlightedIds]="highlightedIds"
        (highlightedIdsChange)="highlightDiff($event)"></formatted-phrase>
    </div>

    <div class="diff-container">
      <div class="diff-title">
        <i class="fas fa-not-equal"></i>
        &nbsp; &nbsp;
        <p>{{ "translate.general.diff" | translate }}</p>
      </div>
      <p class="template-phrase-txt">
        <formatted-phrase [phraseWords]="formatMatchPhrase" [formatType]="'formatMatchPhrase'"
          [formatMatchDifferences]="formatMatchDifferences" [highlightedIds]="highlightedIds"
          (highlightedIdsChange)="highlightDiff($event)"></formatted-phrase>
      </p>
    </div>
    <table class="diffTable">
      <thead>
        <tr>
          <th>Phrase</th>
          <th>
            <i class="fas fa-not-equal"></i>&nbsp; &nbsp;{{ "translate.general.diff" | translate }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr [class.diffRowHighlight]="isHighlighted(difference.ids)" *ngFor="let difference of formatMatchDifferences"
          (mouseenter)="highlightDiff(difference.ids)" (mouseleave)="clearHighlight()">
          <td><span [tooltip]="difference.text">{{ difference.text | truncate:6:'...' }}</span></td>
          <td>
            <ng-container *ngFor="let attr of difference | keyvalue">
              <div class="diffItem" *ngIf="attr.key !== 'text' && attr.key !== 'ids'">
                <span class="diffTag">{{ getFriendlyDifferenceName(attr.key) }}</span>
                <span class="diffContent" [ngSwitch]="attr.key">
                  <ng-container *ngSwitchCase="'font_size'">
                    {{ attr.value[0] }} -> {{ attr.value[1] }}
                  </ng-container>
                  <ng-container *ngSwitchCase="'font_name'" style="font-size: 0.6em">
                    {{ getFriendlyFontName(attr.value[0]) }} -> {{ getFriendlyFontName(attr.value[1]) }}
                  </ng-container>
                  <ng-container *ngSwitchCase="'color_hex'">
                    <span class="color-box" [tooltip]="'Hex Color Code: ' + attr.value[0]"
                      [style.background-color]="attr.value[0]"></span> &nbsp; -> &nbsp;
                    <span class="color-box" [tooltip]="'Hex Color Code: ' + attr.value[1]"
                      [style.background-color]="attr.value[1]"></span>
                  </ng-container>
                  <ng-container *ngSwitchDefault style="font-family: 'Arial', sans-serif; font-weight: bolder;">
                    {{ attr.value[0].toLowerCase() === 'true' ? '✓' : '✗' }} -> {{ attr.value[1].toLowerCase() ===
                    'true' ? '✓' : '✗' }}
                  </ng-container>
                </span>
              </div>
            </ng-container>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

</ng-template>

<ng-template #dirtyBtns>
  <div *ngIf="dirtySw || hasWhitelistedWords()" class="t-small">
    <button class="t-button t-button-warn" [disabled]="loading" (click)="onSaveClicked()">
      {{ "translate.general.saveChanges" | translate }}
    </button>
    &nbsp;
    <button class="t-button-outline t-button-warn" [disabled]="loading" (click)="discardChanges()">
      {{ "translate.general.discardChanges" | translate }}
    </button>
  </div>
</ng-template>
