import { NgRedux } from '@redux/redux-compatibility.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { PdfViewerComponent } from 'src/app/shared/components/pdf-viewer/pdf-viewer.component';
import {
  Catalog,
  CatDoc,
  CatExtRefSearchParams,
  ExtRef,
} from 'src/app/shared/models/cat.model';
import { AnnotationElement, DataElement } from 'src/app/shared/models/common';
import { keyCodes } from 'src/app/shared/models/key-codes.model';
import { AppState } from 'src/app/shared/redux/store';
import { CatService } from 'src/app/shared/services/cat.service';

@Component({
  selector: 'app-cat-ext-refs',
  templateUrl: './cat-ext-refs.component.html',
  styleUrls: ['./cat-ext-refs.component.scss'],
})
export class CatExtRefsComponent implements OnInit {
  @ViewChild('docSinglePhraseViewer') docViewer: PdfViewerComponent;

  loading: boolean;
  filterPhrase: string;
  filterExtPhrase: string;
  filterDoc: DataElement;
  filterExtDoc: DataElement;
  catDocs: DataElement[] = [];
  extDocs: DataElement[] = [];
  displayDocViewer: boolean;
  curDoc: CatDoc;
  curPage: number;
  singlePageSw: boolean;
  ann: AnnotationElement;

  get data(): ExtRef[] {
    return this.ngRedux.getState().catExtRefs;
  }

  get curCat(): Catalog {
    return this.ngRedux.getState().curCat;
  }

  get displayClearFilter(): boolean {
    return (
      this.filterPhrase != null ||
      this.filterExtPhrase != null ||
      this.filterDoc != null ||
      this.filterExtDoc != null
    );
  }

  constructor(
    private ngRedux: NgRedux<AppState>,
    private catService: CatService
  ) {}

  ngOnInit(): void {
    this.getData(true);

    this.catDocs = this.ngRedux
      .getState()
      .catDocs?.map((d) => new DataElement(d.docId.toString(), d.docName));
  }

  getData(firstTime: boolean = false) {
    this.loading = true;
    let params = new CatExtRefSearchParams();
    params.phraseText = this.filterPhrase;
    params.extPhraseText = this.filterExtPhrase;
    params.docId = this.filterDoc?.id;
    params.extDocId = this.filterExtDoc?.id;

    this.catService.getCatExtRefs(params).subscribe(
      (data) => {
        if (firstTime) {
          this.data.forEach((r) => {
            if (!this.extDocs.find((c) => c.id == r.extDocId)) {
              this.extDocs.push(
                new DataElement(r.extDocId.toString(), r.extDocName)
              );
            }
          });
        }
        this.loading = false;
      },
      (err) => (this.loading = false)
    );
  }

  onFilterKeyPress(e: any) {
    if (e.keyCode == keyCodes.enter) {
      this.getData();
    }
  }

  onFieldValueChanged(e: any) {
    (e.originalEvent as MouseEvent).stopPropagation();
    this.getData();
  }

  clearFilter() {
    this.filterDoc = null;
    this.filterExtDoc = null;
    this.filterExtPhrase = null;
    this.filterPhrase = null;
    this.getData();
  }

  viewPhraseDoc(
    docId: number,
    ann: AnnotationElement,
    singlePage: boolean = false,
    pageNum: number = 1
  ) {
    this.singlePageSw = singlePage;
    this.curDoc = new CatDoc();
    this.curDoc.docId = docId;
    this.curPage = pageNum;
    this.ann = ann;
    this.displayDocViewer = true;
  }

  onDocIframeLoaded() {
    if (this.docViewer.analyzedPage !== this.curPage && !this.singlePageSw) {
      this.docViewer.goToPage(this.curPage);
    }
    if (this.ann) this.docViewer.showAnnotation(this.ann, true);
  }

  onToggleAnnotations(viewSw: boolean) {
    if (viewSw && this.ann) {
      this.docViewer.showAnnotation(this.ann, true);
    } else {
      this.docViewer.hideAnnotation(this.ann, false);
    }
  }
}
