import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'loader',
  template : `
    <div class="t-inputWrap-loader" role="alert"></div>
  `
} )
export class LoaderComponent implements OnInit {

  constructor() {}

  ngOnInit() {
  }

}
