import { Component, OnInit } from '@angular/core';
import { NgRedux } from '@redux/redux-compatibility.service';
import { AppState, actionsList } from '../shared/redux/store';
import { RevService } from '../shared/services/rev.service';
import { Unsubscribe } from 'redux';
import { DataElement } from '../shared/models/common';
import { RevStatus } from '../shared/models/rev.model';
import { TranslateService } from '@ngx-translate/core';
import { CatService } from '../shared/services/cat.service';
import { AuthService } from '../shared/services/auth.service';
import { CatPhraseStatusEnum } from '../shared/models/cat.model';
import { CommonService } from '@shared/services/common.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit {
  reduxSubscribe: Unsubscribe;

  constructor(
    private ngRedux: NgRedux<AppState>,
    private revService: RevService,
    private catService: CatService,
    private translateService: TranslateService,
    private authService: AuthService,
    private commonService: CommonService
  ) {
    this.reduxSubscribe = this.ngRedux.subscribe(() => {
      const appState = this.ngRedux.getState();
      switch (appState._currentAction) {
        case actionsList.SELECT_ENV:
          this.getProductList();
          break;
      }
    });
  }

  ngOnInit(): void {
    this.loadInitialData();

    this.reduxSubscribe = this.ngRedux.subscribe(() => {
      const appState = this.ngRedux.getState();
      switch (appState._currentAction) {
        case actionsList.SELECT_ENV:
          this.getProductList();
          break;
      }
    });
  }

  ngOnDestroy() {
    this.reduxSubscribe();
  }

  loadInitialData() {
    this.getProductList();
    this.getRuleConfigTemplateList();

    this.ngRedux.getState().timezoneList.loading = true;
    this.authService.getTimezonesList().subscribe((data) => {
      this.ngRedux.dispatch({
        type: actionsList.GET_TIMEZONE_LIST,
        data: data,
      });
    });

    if (this.authService.currentUser.authDetails.revScreenAllowed) {
      this.getRevDocStatusList();
      this.getRevRuleTypeList();
      this.getRevRuleStatusList();
      this.getContentTypeList();
      this.getRuleSimilarityEventList();
    }

    if (this.authService.currentUser.authDetails.catScreenAllowed) {
      this.getCatTypeList();
      this.getCatReferenceParsingMatchTypeList();
      this.getCatLangList();
      this.getCatPhraseStatusList();
      this.getCatDocStatusList();
      this.getCatMatchSimilarityCodes();
      this.getCardTypes();
      this.getBoundingBoxTypes();
      this.getAssetTypes();
      this.getMcTypeList();
      this.getMcSubTypeList();
      this.getMcBoxTypeList();
      this.getCatEntryStatusList();
      this.getFigureTypeList();
      this.getContentTypeList();
      this.getRuleSimilarityEventList();
    }
  }

  getFigureTypeList() {
    this.ngRedux.getState().figureTypeList.loading = true;
    this.catService.getFigureTypeList().subscribe();
  }

  getCatEntryStatusList() {
    this.ngRedux.getState().catEntryStatusList.loading = true;
    this.catService.getCatEntryStatusList().subscribe();
  }

  getRevDocStatusList() {
    this.ngRedux.getState().revDocStatusList.loading = true;
    this.revService.getRevDocStatusList().subscribe();
  }

  getProductList() {
    this.ngRedux.getState().productList.loading = true;
    this.authService.getProductList().subscribe();
  }

  getRevRuleTypeList() {
    this.ngRedux.getState().revRuleTypeList.loading = true;
    this.revService.getRevRuleTypeList().subscribe();
  }

  getRevRuleStatusList() {
    this.ngRedux.getState().revRuleStatusList.loading = true;
    this.revService.getRevRuleStatusList().subscribe();
  }

  getContentTypeList(){ 
    this.ngRedux.getState().contentTypeList.loading = true;
    this.commonService.getContentTypeList().subscribe();
  }

  getRuleSimilarityEventList() {
    this.ngRedux.getState().revRuleSimilarityEventList.loading = true;
    this.ngRedux.getState().indRuleSimilarityEventList.loading = true;
    this.commonService.getRuleSimilarityEventList().subscribe();
  }

  getCatTypeList() {
    this.ngRedux.getState().catTypeList.loading = true;
    this.catService.getCatTypeList().subscribe();
  }

  getCatReferenceParsingMatchTypeList() {
    this.ngRedux.getState().refMatchTypeList.loading = true;
    this.catService.getCatReferenceParsingMatchTypeList().subscribe();
  }

  getCatLangList() {
    this.ngRedux.getState().catLangList.loading = true;
    this.catService.getCatLangList().subscribe();
  }

  getCatDocStatusList() {
    this.ngRedux.getState().catDocStatusList.loading = true;
    this.catService.getCatDocStatusList().subscribe();
  }

  getCatMatchSimilarityCodes() {
    this.ngRedux.getState().catMatchSimilarityCodes.loading = true;
    this.catService.getCatMatchSimilarityCodes().subscribe();
  }

  getCatPhraseStatusList() {
    this.ngRedux.getState().catPhraseStatusList.loading = true;

    let options: DataElement[] = [];
    options.push(
      new DataElement(
        CatPhraseStatusEnum.PENDING,
        this.translateService.instant('translate.cat.pending')
      )
    );
    options.push(
      new DataElement(
        CatPhraseStatusEnum.APPROVE_VAR,
        this.translateService.instant('translate.cat.approveVariant')
      )
    );
    options.push(
      new DataElement(
        CatPhraseStatusEnum.APPROVE,
        this.translateService.instant('translate.cat.approved')
      )
    );
    options.push(
      new DataElement(
        CatPhraseStatusEnum.REJECT,
        this.translateService.instant('translate.cat.rejected')
      )
    );

    this.ngRedux.dispatch({
      type: actionsList.GET_CAT_PHRASE_STATUS_LIST,
      data: options,
    });
  }

  getCardTypes() {
    this.ngRedux.getState().cardTypes.loading = true;
    this.catService.getCardTypes().subscribe();
  }

  getBoundingBoxTypes() {
    this.ngRedux.getState().boundingBoxTypes.loading = true;
    this.catService.getBoundingBoxTypes().subscribe();
  }

  getAssetTypes() {
    this.ngRedux.getState().assetTypes.loading = true;
    this.catService.getAssetTypes().subscribe();
  }

  getMcTypeList() {
    this.ngRedux.getState().mcTypes.loading = true;
    this.catService.getMCTypes().subscribe();
  }

  getMcSubTypeList() {
    this.ngRedux.getState().mcSubTypes.loading = true;
    this.catService.getMCSubTypes().subscribe();
  }

  getMcBoxTypeList() {
    this.ngRedux.getState().mcBoxTypes.loading = true;
    this.catService.getMCBoxTypes().subscribe();
  }

  getRuleConfigTemplateList() {
    this.ngRedux.getState().ruleConfigTemplateList.loading = true;
    this.authService.getRuleConfigTemplateList().subscribe();
  }
}
