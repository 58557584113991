import {
  Component,
  OnInit,
  ElementRef,
  ViewContainerRef,
  ViewChild,
} from '@angular/core';
import { trigger, transition, animate, style } from '@angular/animations';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from '../../services/shared.service';
import { Popup } from '../../models/popup';

@Component({
  selector: 'custom-popup',
  inputs: ['ok'],
  animations: [
    trigger('opacity', [
      transition(':enter', [
        style({ opacity: '0' }),
        animate('100ms ease-in', style({ opacity: '1' })),
      ]),
      transition(':leave', [
        style({ opacity: '1' }),
        animate('200ms ease-in', style({ opacity: '0' })),
      ]),
    ]),
  ],
  template: `
    <div
      [ngClass]="popup.icon"
      (keyup)="onCancelByEscEey($event)"
      *ngIf="showPopup"
      [@opacity]
      class="popup-overlay"
      role="alert"
    >
      <div class="l-spread popup-overlayClose" (click)="onCancel()"></div>

      <section class="l-layer-up popup-body">
        <header class="popup-top">
          <h2 *ngIf="title" class="popup-title">
            {{ title | translate }}
          </h2>
        </header>

        <main class="popup-main pad">
          <div class="t-row--med l-flex popup-main-row">
            <i
              *ngIf="popup.icon"
              class="popup-icon fas"
              [ngClass]="'fa-' + popup.icon"
            ></i>

            <div class="l-align-x l-flex-column l-cell-spread popup-subTitle">
              <p *ngFor="let subTitle of subTitles" class="w-full">
                {{ subTitle | translate }}
              </p>
            </div>
          </div>

          <form *ngIf="popup.form">
            <div *ngFor="let field of popup.form" class="t-row--small">
              <input
                [ngClass]="field.cssClass"
                class="t-input"
                type="{{ field.type ? field.type : 'text' }}"
                value="{{ field.value }}"
                [attr.aria-label]="field.label"
              />
            </div>
          </form>

          <code *ngIf="message" class="t-code">
            {{ message | translate }}
          </code>

          <div *ngIf="popup.htmlMessage" class="t-row--med l-flex popup-main-row">
            <div [innerHtml]="popup.htmlMessage" class="l-align-x l-flex-column l-cell-spread popup-subTitle">
            </div>
          </div>
        </main>

        <footer class="popup-bottom">
          <button class="t-button popup-btn-approve" (click)="onOk()">
            {{ okBtnText | translate }}
          </button>

          <button
            *ngIf="popup.approve"
            class="t-button popup-btn-cancel"
            (click)="onCancel()"
          >
            {{ cancelBtnText | translate }}
          </button>
        </footer>
      </section>
    </div>
  `,
})
export class PopupComponent implements OnInit {
  ok: any;
  popup: Popup = {};
  title: string = '';
  subTitles: string[] = [];
  message: string = '';
  okBtnText: string = '';
  cancelBtnText: string = ';';
  showPopup: boolean = false;

  constructor(
    private sharedService: SharedService,
    private el: ElementRef,
    private translate: TranslateService,
    public viewContainerRef: ViewContainerRef
  ) {}

  ngOnInit() {
    this.sharedService.popup.subscribe((data) => {
      this.subTitles = [];

      this.popup = data;

      this.sharedService.emitGeneralLoaderEvent(false);

      this.showPopup = Object.keys(this.popup).length !== 0;

      if (this.showPopup) {
        // hangle focus for bettur ux
        requestAnimationFrame(() => {
          const firstFormField = this.el.nativeElement.querySelector(
            'input, select'
          );
          const approveBtn = this.el.nativeElement.querySelector(
            '.popup-btn-approve'
          );

          if (firstFormField) {
            firstFormField.focus();
          } else {
            approveBtn && approveBtn.focus();
          }
        });

        // support for a single string message
        if (typeof this.popup.message === 'string') {
          this.message = this.popup.message;
        } else {
          this.message = '';

          // support for non-string message
          if (this.popup.message && this.popup.message.forEach !== undefined) {
            // array of messages
            this.popup.message.forEach((item) => {
              if (typeof item === 'string') {
                // string message in iteration
                this.message += ' ' + item;
              } else {
                // non-string (e.g. object) message in iteration
                this.message += ' ' + JSON.stringify(item);
              }
            });
          } else {
            // non-string (e.g. object) single message
            this.message = JSON.stringify(this.popup.message);
          }
        }
      }

      this.popup.title && (this.title = this.popup.title);

      this.subTitles = [];

      if (this.popup.subTitle) {
        if (typeof this.popup.subTitle === 'string') {
          this.subTitles.push(this.popup.subTitle);
        } else {
          this.popup.subTitle.forEach((item) => {
            item &&
              this.translate.get(item).subscribe((res) => {
                this.subTitles.push(res);
              });
          });
        }
      } else {
        this.subTitles = [];
      }

      this.cancelBtnText = this.popup.cancelLabel || 'translate.alert.cancel';
      this.okBtnText = this.popup.approveLabel || 'translate.alert.approve';

      if (typeof this.popup.onload === 'function') {
        requestAnimationFrame(() => {
          this.popup.onload(this.el.nativeElement, this.popup);
        });
      }

      if (this.popup.fade) {
        setTimeout(() => {
          this.onCancel();
        }, this.popup.fade);
      }
    });
  }

  onOk() {
    if (typeof this.popup.approve === 'function') {
      try {
        this.popup.approve(this.el.nativeElement, this.popup);
      } catch (e) {
        let parent = this;
        let parentPopup = parent.popup;
        parentPopup.keep = true;
        this.sharedService.emitPopupchReceivedEvent({
          title: e.title,
          icon: 'exclamation-triangle',
          subTitle: e.message,
          onClose: () => {
            parent.sharedService.emitPopupchReceivedEvent(parentPopup);
          }
        });
        return;
      }
    }
    if (typeof this.popup.onClose === 'function') {
      this.popup.onClose(this.el.nativeElement, this.popup);
    }
    if (!this.popup.keep) this.sharedService.emitPopupchReceivedEvent({});
  }
  onCancel() {
    if (typeof this.popup.onClose === 'function') {
      this.popup.onClose(this.el.nativeElement, this.popup);
    }
    this.sharedService.emitPopupchReceivedEvent({});
  }

  onCancelByEscEey(e) {
    // close on escape key press
    if (e.keyCode === 27) this.onCancel();
    // approve on enter key press
    if (e.keyCode === 13) this.onOk();
  }
}
