import { Component, OnInit } from '@angular/core';
import { Catalog, CatalogStatusEnum } from '../shared/models/cat.model';
import { NgRedux } from '@redux/redux-compatibility.service';
import { AppState, actionsList } from '../shared/redux/store';
import { Router } from '@angular/router';
import { SharedService } from '../shared/services/shared.service';
import { Tab, TabIdList } from '../shared/models/tab.model';
import { TranslateService } from '@ngx-translate/core';
import { CatService } from '../shared/services/cat.service';
import { ProcessRunStatusEnum } from '../shared/models/common';
import { AuthService } from '../shared/services/auth.service';
import { IntegrationService } from '../shared/services/integration.service';
import { Env } from '../shared/models/user.model';
import { ConfigScope, IIntegrationType } from '../shared/models/integration-type.model';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-catalog',
  templateUrl: './catalog.component.html',
  styleUrls: ['./catalog.component.scss'],
})
export class CatalogComponent implements OnInit {
  loading: boolean;
  tabs: Tab[];
  tabIdList: TabIdList;
  displaySaveToBackUpDialog: boolean;
  displayConfigureIntegration: boolean;
  displayImportClaimsDialog: boolean;
  saveToVersionDescr: string;
  displayRestoreVersionDialog: boolean;
  backupVersions: Catalog[];
  restoreFromVersion: Catalog;
  exporting: boolean;
  processStatusTimer: NodeJS.Timeout;
  getProcessStatusIntervalSeconds: number = 3;
  integrationConfigurationScope: ConfigScope = ConfigScope.Library;
  loadingIntegrationConfiguration: boolean = false;
  loadingInitClaims: boolean = false;
  isImportClaimsExcelEnabled: boolean;

  get cat(): Catalog {
    return this.ngRedux.getState().curCat;
  }

  get env(): Env {
    return this.ngRedux.getState().curEnv;
  }

  get curEnvIntegrationTypes(): IIntegrationType[] {
    return this.ngRedux.getState().curEnvIntegrationTypes;
  }

  get curEnvConfiguredIntegrationType(): string {
    return this.ngRedux.getState().curEnvConfiguredIntegrationType;
  }

  get curCatIntegrationConfig(): any {
    return this.ngRedux.getState().curCatIntegrationConfig;
  }

  get publishedCat(): Catalog {
    return this.ngRedux.getState().publishedCat;
  }

  get enableSubmitSaveToBackup(): boolean {
    return this.saveToVersionDescr != null;
  }

  get enableSubmitRestoreVersion(): boolean {
    return this.restoreFromVersion != null;
  }

  constructor(
    private ngRedux: NgRedux<AppState>,
    private catService: CatService,
    private router: Router,
    private sharedService: SharedService,
    private integrationService: IntegrationService,
    private translateService: TranslateService,
    private authService: AuthService
  ) {
    this.tabIdList = this.ngRedux.getState().ui_tabIdList;
    this.isImportClaimsExcelEnabled = this.sharedService.isImportClaimsReportEnabled();
  }

  ngOnDestroy(): void {}

  ngOnInit(): void {
    this.setUpCatalogIntegration();

    this.tabs = [
      {
        tabId: this.tabIdList.CAT__DOCS,
        icon: 'far fa-file-alt',
        tabTitle: 'translate.cat.catDocs',
        link: this.sharedService.routeEnum.URL_CATALOG_DOCS,
        isHidden: false,
      },
      {
        tabId: this.tabIdList.CAT__ENTRIES,
        icon: 'far fa-list-alt',
        tabTitle: 'translate.cat.catEntries',
        link: this.sharedService.routeEnum.URL_CATALOG_ENTRIES,
        isHidden: false,
      },
      {
        tabId: this.tabIdList.CAT__IMAGES,
        icon: 'far fa-images',
        tabTitle: 'translate.cat.catImages',
        link: this.sharedService.routeEnum.URL_CATALOG_IMAGES,
        isHidden: false,
      },
      {
        tabId: this.tabIdList.CAT__CARDS,
        icon: 'fas fa-file-invoice',
        tabTitle: 'translate.cat.cards',
        link: this.sharedService.routeEnum.URL_CATALOG_CARDS,
        isHidden: false,
      },
    ];

    if (!this.cat.extSw) {
      this.tabs.push(
        ...[
          {
            tabId: this.tabIdList.CAT__CARD_ASSET_TEMPLATES,
            icon: 'fas fa-file-invoice',
            tabTitle: 'translate.cat.cardAssetTemplates',
            link: this.sharedService.routeEnum.URL_CATALOG_CARD_ASSET_TEMPLATES,
            isHidden: false,
          },
        ]
      );
    }

    this.tabs.push(
      ...[
        {
          tabId: this.tabIdList.CAT__ALT_REFS,
          icon: 'fas fa-bookmark',
          tabTitle: 'translate.cat.altRef',
          link: this.sharedService.routeEnum.URL_CAT_ALT_REF,
          isHidden: false,
        },
      ]
    );

    if (!this.cat.extSw) {
      this.tabs.push(
        ...[
          {
            tabId: this.tabIdList.CAT__EXT_REFS,
            icon: 'fas fa-bookmark',
            tabTitle: 'translate.cat.extRefs',
            link: this.sharedService.routeEnum.URL_CAT_EXT_REF,
          },
          {
            tabId: this.tabIdList.CAT__EXT_LIBS,
            icon: 'fas fa-book-medical',
            tabTitle: 'translate.cat.extLibList',
            link: this.sharedService.routeEnum.URL_CAT_EXT_LIBS,
          },
        ]
      );
    }

    this.tabs.push(
      ...[
        {
          tabId: this.tabIdList.CAT__MNG_PHRASE_TYPES,
          icon: 'fas fa-cog',
          tabTitle: 'translate.cat.mngPhraseTypes',
          link: this.sharedService.routeEnum.URL_CATALOG_MNG_PHRASE_TYPES,
          isHidden: false,
        },
      ]
    );
    //if (!this.cat.extSw)
    {
      this.tabs.push(
        ...[
          {
            tabId: this.tabIdList.CAT__MNG_ASSET_TYPES,
            icon: 'fas fa-cog',
            tabTitle: 'translate.general.assetTypes',
            link: this.sharedService.routeEnum.URL_CATALOG_MNG_ASSET_TYPES,
            isHidden: false,
          },
        ]
      );
    }

    this.tabs.push(
      ...[
        {
          tabId: this.tabIdList.CAT__SPELLING_CHECK_IGNORED,
          icon: 'fas fa-cog',
          tabTitle: 'translate.general.spellingCheckIgnored',
          link: this.sharedService.routeEnum.URL_CATALOG_SPELLING_CHECK_IGNORED,
          isHidden: false,
        },
      ]
    );
    {
      this.tabs.push(
        ...[
          {
            tabId: this.tabIdList.CAT__MNG_ASSET_TYPES,
            icon: 'fas fa-link',
            tabTitle: 'translate.general.referenceMapping',
            link: this.sharedService.routeEnum.URL_CAT_REF_MAP,
            isHidden: false,
          },
        ]
      );
    }
    this.getCatPhraseTypes();
    let parentCat = this.ngRedux
      .getState()
      .catalogList.find((c) => c.catId == this.cat.catId);

    this.backupVersions = parentCat.catVersions.filter(
      (c) => c.statusCode != CatalogStatusEnum.DRAFT
    );
  }

  getCatPhraseTypes() {
    this.catService.getCatPhraseTypes(this.cat.catId).subscribe();
  }

  goBack() {
    let extSw = this.cat.extSw;
    this.ngRedux.dispatch({
      type: actionsList.SELECT_CATALOG,
      data: null,
    });

    this.sharedService.clearLastCatCookies();

    if (extSw) {
      this.router.navigate([this.sharedService.routeEnum.URL_EXT_CATALOG_LIST]);
    } else {
      this.router.navigate([this.sharedService.routeEnum.URL_CATALOG_LIST]);
    }
  }

  unpublish() {
    let validationStr = this.translateService.instant(
      'translate.cat.unpublishCatValidation'
    );

    this.sharedService.emitPopupchReceivedEvent({
      title: 'translate.validation.confirm',
      icon: 'question-circle',
      subTitle: validationStr,
      keep: false,
      approve: () => {
        this.loading = true;
        this.catService.publishCatVersion(this.cat, true).subscribe(
          (data) => {
            this.loading = false;
            this.router.navigate([
              this.sharedService.routeEnum.URL_CATALOG_LIST,
            ]);
          },
          (err) => (this.loading = false)
        );
      },
    });
  }

  publish() {
    let validationStr = this.translateService.instant(
      'translate.cat.publishCatValidation'
    );

    this.sharedService.emitPopupchReceivedEvent({
      title: 'translate.validation.confirm',
      icon: 'question-circle',
      subTitle: validationStr,
      keep: false,
      approve: () => {
        this.loading = true;
        this.catService.publishCatVersion(this.cat).subscribe(
          (data) => {
            const catVersionId = this.resolvePublishedCatVersionId(this.publishedCat);
            this.integrationService.publishCatalogVersion(catVersionId).subscribe(
              (res) => {
                if (this.curEnvConfiguredIntegrationType) {
                  this.integrationService.publishCatalogVersionExternal(catVersionId).subscribe(
                    (response) => {
                      console.groupCollapsed(
                        '%c' + `Outcome of publishing catalog version ${response.catVersionId} to the external system:`,
                        'color: LightSlateGray'
                      );
                      console.log(response)
                      console.groupEnd();
                    },
                    (err) => console.error(`An error ocurred while publishing catalog version to an external system: ${err}`)
                  );
                }
    
                this.loading = false;
                this.router.navigate([
                  this.sharedService.routeEnum.URL_CATALOG_LIST,
                ]);
              }
            )
          },
          (err) => (this.loading = false)
        );
      },
    });
  }

  pullExternal() {
    this.loadingInitClaims = true;
    this.integrationService.pullExternal(this.cat.catVersionId, this.cat.catId).subscribe(
      (rep) => {
        this.loadingInitClaims = false;
      },
      (err) => {
        this.loadingInitClaims = false;
        this.processError(err);
      }
    );
  }

  saveToBackup() {
    this.loading = true;
    this.catService
      .backUpCatVersion(this.cat.catVersionId, this.saveToVersionDescr)
      .subscribe(
        (data) => {
          this.loading = false;
          this.displaySaveToBackUpDialog = false;
        },
        (err) => (this.loading = false)
      );
  }

  restoreVersion() {
    this.loading = true;
    this.catService
      .restoreCatVersion(this.restoreFromVersion.catVersionId)
      .subscribe(
        (data) => {
          this.loading = false;
          this.displayRestoreVersionDialog = false;
          this.router.navigate([this.sharedService.routeEnum.URL_CATALOG_LIST]);
        },
        (err) => (this.loading = false)
      );
  }

  exportPhraseReport() {
    this.exporting = true;
    this.catService.exportIndPhrasesExcel(this.cat.catVersionId).subscribe(
      (rep) => {
        this.processStatusTimer = setInterval(() => {
          this.authService.getProcessStatus(rep.runNum).subscribe(
            (data) => {
              if (data.status == ProcessRunStatusEnum.FINISHED) {
                this.catService
                .getS3Objects([rep.s3Key], true)
                .subscribe(
                  (data2) => {
                    clearInterval(this.processStatusTimer);
                    let url = this.catService.getS3TempObjectPresignedUrl(rep.s3Key);
                    var element = document.createElement('a');
                    element.href = url;
                    element.setAttribute('download', rep.s3Key);
                    document.body.appendChild(element);
                    element.click();
                    document.body.removeChild(element);
                    this.exporting = false;
                  },
                  (err) => {
                    this.exportFileError(err);
                  }
                );
              } else if (data.status == ProcessRunStatusEnum.FAILED) {
                this.processError(
                  this.translateService.instant('translate.cat.processFailed')
                );
              }
            },
            (err) => {
              this.processError(err);
            }
          );
        }, this.getProcessStatusIntervalSeconds * 1000);
      },
      (err) => {
        this.processError(err);
      }
    );
  }

  exportFileError(err) {
    clearInterval(this.processStatusTimer);
    this.exporting = false;
    this.sharedService.alertError(err);
  }

  onConfiguredIntegration(event: Event): void {
    this.displayConfigureIntegration = false;
  }

  onClaimsExcelImported(event: Event): void {
    this.displayImportClaimsDialog = false;
  }

  processError(err) {
    clearInterval(this.processStatusTimer);
    this.exporting = false;
    this.sharedService.alertError(err);
  }

  private setUpCatalogIntegration(): void {
    this.integrationService.getIntegrationTypes(this.env.id).subscribe(
      (response) => {
        if (this.curEnvConfiguredIntegrationType) {
          this.loadingIntegrationConfiguration = true;

          this.integrationService.getIntegrationConfig(
            this.curEnvConfiguredIntegrationType,
            ConfigScope.Library,
            this.env.id,
            this.cat.catId
          ).subscribe(() => this.loadingIntegrationConfiguration = false);
        }
      },
      (err) => {
        this.sharedService.alertError(err);
      }
    );
  }

  private resolvePublishedCatVersionId(catalog: Catalog): number {
    const publishedCatVersion = catalog.catVersions.find((catVersion) => catVersion.versionId == catalog.activeVersionId);

    return publishedCatVersion ? publishedCatVersion.catVersionId : null;
  }

}
