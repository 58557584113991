<modal-popup
  [flexible]="true"
  [focusOnOpen]="false"
  [isOpen]="true"
  (onClose)="close()"
  [title]="modalTitle"
>
  <div class="container">
    <!--env-->
    <input-wrap
      [loading]="envList && envList.loading"
      label="{{ 'translate.general.env' | translate }}"
      cssClass="l-cell-spread"
    >
      <p-dropdown
        [options]="envList"
        optionLabel="descr"
        [(ngModel)]="selectedEnv"
        class="l-cell-spread-x"
        [resetFilterOnHide]="true"
        [filterPlaceholder]="'translate.general.search' | translate"
        [placeholder]="'translate.general.select' | translate"
        [filter]="true"
        appendTo="body"
        baseZIndex="100"
        (onChange)="onEnvChange()"
      >
      </p-dropdown>
    </input-wrap>

    <loader *ngIf="loadingCatList"></loader>

    <!--catalog-->
    <input-wrap
      *ngIf="!loadingCatList"
      [loading]="catalogList && catalogList.loading"
      label="{{ 'translate.general.catalog' | translate }}"
      cssClass="l-cell-spread"
    >
      <p-dropdown
        [options]="catalogList"
        optionLabel="label"
        [(ngModel)]="selectedCatalog"
        class="l-cell-spread-x"
        [resetFilterOnHide]="true"
        [filterPlaceholder]="'translate.general.search' | translate"
        [placeholder]="'translate.general.select' | translate"
        [filter]="true"
        appendTo="body"
        baseZIndex="100"
        (onChange)="onCatalogChange($event)"
      >
      </p-dropdown>
    </input-wrap>

    <loader *ngIf="loading"></loader>

    <div class="l-mar-top types-container">
      <cat-phrase-type-list
        *ngIf="!loading"
        [catPhraseTypes]="catPhraseTypes"
        [allowEdit]="false"
        [allowSelect]="true"
        (onSelection)="onTypesSelection($event)"
      >
      </cat-phrase-type-list>
    </div>

    <div class="l-align-end l-mar-top t-row--small">
      <button
        class="t-button-outline t-button-warn"
        [disabled]="loading || !validateBeforeSave()"
        (click)="save()"
      >
        {{ "translate.general.save" | translate }}
      </button>
    </div>
  </div>
</modal-popup>
