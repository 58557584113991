import { NgRedux } from '@redux/redux-compatibility.service';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TreeNode } from 'primeng/api';
import {
  CatPhraseSubType,
  CatPhraseType,
} from 'src/app/shared/models/cat.model';
import { AppState } from 'src/app/shared/redux/store';
import { CatService } from 'src/app/shared/services/cat.service';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'app-mng-cat-phrase-types',
  templateUrl: './mng-cat-phrase-types.component.html',
  styleUrls: ['./mng-cat-phrase-types.component.scss'],
})
export class MngCatPhraseTypesComponent implements OnInit {
  editSubTypeOpenSw: boolean;
  editTypeOpenSw: boolean;
  curSubType: CatPhraseSubType;
  curType: CatPhraseType;
  loading: boolean;
  importOpenSw: boolean;

  get allowEdit(): boolean {
    return true; 
  }

  get catPhraseTypes(): TreeNode[] {
    if (!this.ngRedux.getState().catPhraseTypeModel) return [];
    return this.ngRedux.getState().catPhraseTypeModel.treeTypes;
  }

  constructor(
    private ngRedux: NgRedux<AppState>,
    private catService: CatService,
    private translateService: TranslateService,
    private sharedService: SharedService
  ) {}

  ngOnInit(): void {}

  edit(row: TreeNode) {
    if (row['node'].data['treeLevel'] == 0) {
      this.curType = row['node']['value'] as CatPhraseType;
      this.editTypeOpenSw = true;
    } else {
      this.curSubType = row['node']['value'] as CatPhraseSubType;
      this.editSubTypeOpenSw = true;
    }
  }

  addSubType(row: TreeNode) {
    let curType = row['node']['value'] as CatPhraseType;
    this.curSubType = new CatPhraseSubType();
    this.curSubType.typeCode = curType.typeCode;
    this.curSubType.typeDescr = curType.typeDescr;
    this.editSubTypeOpenSw = true;
  }

  onDeleteClicked(row: TreeNode) {
    let validationStr = this.translateService.instant(
      'translate.cat.deletePhraseType'
    );

    this.sharedService.emitPopupchReceivedEvent({
      title: 'translate.validation.confirm',
      icon: 'exclamation-triangle',
      subTitle: validationStr,
      keep: false,
      approve: () => {
        this.deleteRow(row);
      },
    });
  }

  deleteRow(row: TreeNode) {
    if (row['node'].data['treeLevel'] == 0) {
      let type = row['node']['value'] as CatPhraseType;
      this.deleteType(type);
    } else {
      let subType = row['node']['value'] as CatPhraseSubType;
      this.deleteSubType(subType);
    }
  }

  reloadCatTypes() {
    this.loading = true;
    this.catService
      .getCatPhraseTypes(this.ngRedux.getState().curCat.catId)
      .subscribe((data) => {
        this.loading = false;
      });
  }

  addType() {
    this.curType = new CatPhraseType();
    this.editTypeOpenSw = true;
  }

  deleteType(type: CatPhraseType) {
    this.catService.updCatPhraseType(type, 'DEL').subscribe(
      (data) => {
        this.editTypeOpenSw = false;
        this.reloadCatTypes();
      },
      (err) => (this.loading = false)
    );
  }

  deleteSubType(subType: CatPhraseSubType) {
    this.catService.updCatPhraseSubType(subType, 'DEL').subscribe(
      (data) => {
        this.editSubTypeOpenSw = false;
        this.reloadCatTypes();
      },
      (err) => (this.loading = false)
    );
  }

  importFromCatalog() {
    this.importOpenSw = true;
  }

}
