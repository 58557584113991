<div class="header">
  <div class="header-actions">
    <button class="t-button-outline" (click)="addSpellingCheckIgnoredOpenSw = true">
      {{ "translate.general.add" | translate }}
    </button>
  </div>
</div>
<loader *ngIf="loading"></loader>
<ui-table
  [isWrapped]="false"
  [data]="spellingCheckIgnored"
  [numberOfRows]="10"
  [displayFilter]="['id', 'text']"
  [templates]="[
    {
      title: 'translate.general.actions',
      template: actions
    }
  ]"
></ui-table>

<ng-template #actions let-row="row">
  <a (click)="delete(row)">{{ "translate.general.delete" | translate }}</a>
</ng-template>

<add-spelling-check-ignored
  *ngIf="addSpellingCheckIgnoredOpenSw"
  (onClose)="addSpellingCheckIgnoredOpenSw = false"
  (onAdd)="add($event)"
>
</add-spelling-check-ignored>
