<section
  *ngIf="isWrapped"
  class="t-widget--noBottomPad"
  [ngClass]="wrapperClass"
>
  <ng-container *ngTemplateOutlet="uiTableStructure"></ng-container>
</section>

<ng-container *ngIf="!isWrapped"
  ><ng-container *ngTemplateOutlet="uiTableStructure"></ng-container
></ng-container>

<ng-template #uiTableStructure>
  <header *ngIf="title || topEnd" class="l-flex-column l-align-top">
    <h2 *ngIf="title" class="t-widgetTitle">
      {{ title | translate }}
    </h2>
    <hr *ngIf="title" />
    <ng-container *ngTemplateOutlet="topEnd"></ng-container>
  </header>

  <ng-container *ngTemplateOutlet="belowTitle"></ng-container>

  <loader *ngIf="_data && _data.loading"></loader>

  <div *ngIf="_data && _data.sorry" class="t-alert-info" role="alert">
    {{ "translate.alert.noResults" | translate }}
  </div>

  <p-table
    #uiTable
    [value]="_data"
    *ngIf="_data"
    class="iscs-table iscs-table-auto"
    [paginator]="
      showPaginator &&
      (totalRecords > numberOfRows || _data.length > numberOfRows)
    "
    [lazy]="!!totalRecords"
    (onLazyLoad)="outputPaginationData($event)"
    [ngClass]="{ 'is-loading': _data.loading }"
    [sortField]="orderBy"
    [sortOrder]="sortOrder"
    [first]="first"
    (onPage)="onPagination()"
    [totalRecords]="totalRecords"
    [rows]="numberOfRows"
    [showCurrentPageReport]="paginatorTxt"
    [currentPageReportTemplate]="
      'Showing {first} to {last} of {totalRecords} entries'
    "
    [paginatorPosition]="paginatorPosition"
    [(selection)]="selectedRows"
    [selectionMode]="selectMode"
    (onRowSelect)="onRowSelectionChanged($event)"
    (onRowUnselect)="onRowSelectionChanged($event)"
    (onHeaderCheckboxToggle)="onRowSelectionChanged($event)"
  >
    <ng-template pTemplate="header">
      <ng-container *ngTemplateOutlet="aboveTableHeader"></ng-container>

      <tr>
        <th *ngIf="checkboxSelection" style="width: 3rem">
          <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
        </th>

        <th *ngIf="more" class="cell-moreInfo">
          <div *ngIf="showExpandAll">
            <button
              [ngClass]="{ 'is-open': toggleExpandAll }"
              class="t-button t-button--pill t-btn-more bg-white"
              (click)="expandAll($event)"
            >
              <fa-icon
                class="t-tr-arrow"
                [icon]="['fas', 'angle-right']"
              ></fa-icon>
            </button>
          </div>
        </th>

        <th
          *ngIf="getTemplateByColumn(-1)"
          [ngClass]="getTemplateByColumn(-1).colClass"
        >
          {{ getTemplateByColumn(-1).title }}
        </th>
        <ng-container *ngIf="prepend">
          <th
            *ngFor="let template of templatesFiltered"
            [ngClass]="template.colClass"
          >
            {{ template.title | translate }}
          </th>
        </ng-container>

        <ng-container *ngFor="let th of displayFilter; let i = index">
          <th class="cell-{{ th }}" [ngClass]="getColSet(th).colClass">
            <!-- clickable th ( start )-->
            <button
              *ngIf="sortable"
              (click)="onSort(th, i, $event, false)"
              [ngClass]="{ 'is-active': i === currentClickableTy }"
              class="t-clickableTh"
            >
              <span class="l-align-y">
                {{ "translate.general." + th | translate }}
                <i class="t-clickableTh-icon fas fa-long-arrow-alt-up"></i>
              </span>
            </button>
            <!-- clickable th ( end) -->
            <ng-container *ngIf="!sortable">{{
              "translate.general." + th | translate
            }}</ng-container>
          </th>
          <ng-container *ngIf="getTemplateByColumn(i)">
            <th>
              {{ getTemplateByColumn(i).title | translate }}
            </th>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="!prepend">
          <th
            *ngFor="let template of templatesFiltered; let j = index"
            [ngClass]="template.colClass"
          >
            <button
              *ngIf="sortable"
              (click)="onSort(template, j, $event, true)"
              [ngClass]="{ 'is-active': j === currentClickableTemplate }"
              class="t-clickableTh"
            >
              <span class="l-align-y">
                {{ template.title | translate }}
                <i class="t-clickableTh-icon fas fa-long-arrow-alt-up"></i>
              </span>
            </button>
            <ng-container *ngIf="!sortable">
              {{ template.title | translate }}</ng-container
            >
          </th>
        </ng-container>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-row>
      <tr
        (click)="rowSelected(row)"
        [class.t-hover-tr]="rowHover"
        [ngClass]="{ selected: highlightRow(row) }"
      >
        <!--checkbox selection-->
        <td *ngIf="checkboxSelection">
          <p-tableCheckbox [value]="row"></p-tableCheckbox>
        </td>

        <!-- show more button (start) -->
        <td *ngIf="more" class="l-ta-center">
          <button
            [ngClass]="{ 'is-open': isInOpenCache(row) }"
            class="t-button t-button--pill t-btn-more bg-white"
            (click)="toggleShowMore(row, $event)"
          >
            <fa-icon
              class="t-tr-arrow"
              [icon]="['fas', 'angle-right']"
            ></fa-icon>
          </button>
        </td>
        <!-- show more button (end) -->

        <!-- custom cell with specified column choice: first (start) -->
        <ng-container *ngIf="getTemplateByColumn(-1)">
          <td [ngClass]="getTemplateByColumn(-1).colClass">
            <ng-container
              *ngTemplateOutlet="
                getTemplateByColumn(-1).template;
                context: { row: row }
              "
            ></ng-container>
          </td>
        </ng-container>
        <!-- custom cell with specified column choice: first (end) -->

        <!-- custom cells (start) -->
        <ng-container *ngIf="prepend">
          <td
            *ngFor="let template of templatesFiltered"
            [ngClass]="template.colClass"
          >
            <ng-container
              *ngTemplateOutlet="template.template; context: { row: row }"
            ></ng-container>
          </td>
        </ng-container>
        <!-- custom cells (end) -->

        <ng-container *ngFor="let td of displayFilter; let i = index">
          <td [ngClass]="getColSet(td).colClass">
            <ng-container *ngIf="isBool(row[td])">
              <div
                *ngIf="row[td]"
                class="w-full l-ta-center fas fa-{{ isTrueIcon }} c1"
              ></div>
              <div
                *ngIf="!row[td]"
                class="w-full l-ta-center fas fa-{{ isFalseIcon }}"
              ></div>
            </ng-container>

            <ng-container *ngIf="isImage(row[td])">
              <figure class="t-frame--100">
                <img [attr.src]="row[td]" alt="" />
              </figure>
            </ng-container>

            <ng-container *ngIf="isString(row[td])">
              {{ row[td] }}
            </ng-container>

            <ng-container *ngIf="isTranslatable(row[td])">
              {{ row[td] | translate }}
            </ng-container>
          </td>
          <!-- custom cell with specified column choice (start) -->
          <ng-container *ngIf="getTemplateByColumn(i)">
            <td [ngClass]="getTemplateByColumn(i).colClass">
              <ng-container
                *ngTemplateOutlet="
                  getTemplateByColumn(i).template;
                  context: { row: row }
                "
              ></ng-container>
            </td>
          </ng-container>
          <!-- custom cell with specified column choice (end) -->
        </ng-container>

        <!-- custom cells (start) -->
        <ng-container *ngIf="!prepend">
          <td
            *ngFor="let template of templatesFiltered"
            [ngClass]="template.colClass"
          >
            <ng-container
              *ngTemplateOutlet="template.template; context: { row: row }"
            ></ng-container>
          </td>
        </ng-container>
        <!-- custom cells (end) -->
      </tr>

      <!-- extra content hidden by default (start) -->
      <tr [ngClass]="{ 'has-hidden-cell': !isInOpenCache(row) }">
        <td [attr.colspan]="colSpan" class="t-pad-0">
          <slide-in-out [isOpen]="isInOpenCache(row)">
            <ng-container
              *ngTemplateOutlet="
                isInOpenCache(row) ? moreItems : null;
                context: { row: row }
              "
            ></ng-container>
          </slide-in-out>
        </td>
      </tr>
      <!-- extra content hidden by default (end) -->
    </ng-template>

    <ng-template pTemplate="footer" let-columns>
      <ng-container *ngTemplateOutlet="footerTemplate"></ng-container>
    </ng-template>
  </p-table>
</ng-template>

<ng-template #moreItems let-row="row">
  <div [@delayLeave] class="t-pad--med t-table-showMore">
    <ng-container
      *ngTemplateOutlet="more; context: { row: row }"
    ></ng-container>
  </div>
</ng-template>
