import { NgRedux } from '@redux/redux-compatibility.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  CatDoc,
  Catalog,
  CatDocFileWrapper,
} from 'src/app/shared/models/cat.model';
import { DataElement, FileUpload } from 'src/app/shared/models/common';
import { AppState } from 'src/app/shared/redux/store';
import { AuthService } from 'src/app/shared/services/auth.service';
import { CatService } from 'src/app/shared/services/cat.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { UploadService } from 'src/app/shared/services/upload.service';

@Component({
  selector: 'create-bulk-cat-doc',
  templateUrl: './create-bulk-cat-doc.component.html',
  styleUrls: ['./create-bulk-cat-doc.component.scss'],
})
export class CreateBulkCatDocComponent implements OnInit {
  @Input() createDocType: string;
  @Output() onClose = new EventEmitter();
  @Output() onDocCreated = new EventEmitter<CatDoc>();
  @Output() onAllDocsCreated = new EventEmitter<CatDocFileWrapper[]>();

  assetType: DataElement;
  saving: boolean;
  bulkSeq: number;
  files: CatDocFileWrapper[] = [];

  get enableSubmit(): boolean {
    return this.files.length > 0;
  }

  get catalog(): Catalog {
    return this.ngRedux.getState().curCat;
  }

  get assetTypes(): DataElement[] {
    return this.catalog.catAssetTypeList;
  }

  get parentDocList(): CatDoc[] {
    return this.ngRedux.getState().catDocs;
  }

  constructor(
    private ngRedux: NgRedux<AppState>,
    private catService: CatService,
    private uploadService: UploadService,
    private authService: AuthService,
    private sharedService: SharedService
  ) {}

  ngOnInit(): void {}

  close() {
    this.onClose.emit();
  }

  async uploadFile(wrapper: CatDocFileWrapper): Promise<void> {
    let fileData = wrapper.file;
    if (!fileData) return;

    let isValidFile = this.uploadService.validateFile(
      fileData,
      SharedService.revDocFileTypes
    );
    if (!isValidFile) return;

    wrapper.uploading = true;

    const file: FileUpload = new FileUpload();
    file.fileType = this.uploadService.getFileType(fileData);

    await this.uploadService.uploadFile(fileData);
    file.file = fileData;

    return new Promise<void>((resolve, reject) => {
      this.uploadService
        .getPresignedUrl(file, this.authService.getPresignedUrl)
        .subscribe(
          (data) => {
            this.uploadService.putFile(file, data).subscribe(
              (res) => {
                wrapper.docS3Key = data.s3Key;
                wrapper.docFileName = wrapper.file.name;

                let doc = new CatDoc();
                doc.docName = wrapper.docName;
                if (wrapper.parentDoc) {
                  doc.parentDocId = wrapper.parentDoc.docId;
                }
                doc.catId = this.catalog.catId;
                doc.catVersionId = this.catalog.catVersionId;
                doc.docS3Key = wrapper.docS3Key;
                doc.fileName = wrapper.docFileName;
                doc.productId = this.catalog.productId;
                doc.assetType = this.assetType;
                doc.bulkSeq = this.bulkSeq;
                doc.createDocType = this.createDocType;
                this.catService.createIndDoc(doc).subscribe(
                  (savedDoc) => {
                    wrapper.doc = savedDoc;
                    this.uploadService
                      .encryptFile(
                        savedDoc.origDocId,
                        'IND',
                        this.ngRedux.getState().curEnv.tmpS3Bucket,
                        this.ngRedux.getState().curEnv.docS3Bucket,
                        doc.docS3Key
                      )
                      .subscribe(
                        (res) => {
                          this.catService
                            .processIndDocAlg(savedDoc.runNum)
                            .subscribe();
                          wrapper.uploading = false;
                          wrapper.finished = true;
                          this.onDocCreated.emit(savedDoc);

                          if (this.files.every((f) => f.finished)) {
                            this.onAllDocsCreated.emit(this.files);
                            this.onClose.emit();
                          }
                        },
                        (err) => {
                          this.sharedService.alertError(err);
                          wrapper.uploading = false;
                          resolve();
                        }
                      );
                  },
                  (err) => (this.saving = false)
                );

                wrapper.uploading = false;
                resolve();
              },
              (err) => {
                this.sharedService.alertError(err);
                wrapper.uploading = false;
                resolve();
              }
            );
          },
          (err) => {
            this.sharedService.alertError(err);
            wrapper.uploading = false;
            resolve();
          }
        );
    });
  }

  onFilesSelect(event) {
    for (let i = 0; i < (event.files as FileList).length; i++) {
      let wrapper = new CatDocFileWrapper();
      wrapper.file = (event.files as FileList).item(i);
      wrapper.docName = wrapper.file.name.split('.').slice(0, -1).join('.');
      wrapper.parentDoc = this.parentDocList.find(
        (d) => d.docName == wrapper.docName
      );
      this.files.push(wrapper);
      console.log(this.files);
    }
  }

  removeFile(file: CatDocFileWrapper) {
    this.files.splice(this.files.indexOf(file), 1);
  }

  async createDocs() {
    this.saving = true;

    this.catService.getBulkUploadSeq().subscribe(
      async (data) => {
        this.bulkSeq = data;
        for (let i = 0; i < this.files.length; i++) {
          let f = this.files[i];
          f.uploading = true;
          if (!f.docName) {
            f.docName = f.file.name.split('.').slice(0, -1).join('.');
          }
          await this.uploadFile(f);
        }
      },
      (err) => (this.saving = false)
    );
  }
}
