import {
  Directive,
  OnInit,
  ElementRef,
  HostListener,
  Input,
} from '@angular/core';

@Directive({
  selector: '[tooltip]',
})
export class TooltipDirective implements OnInit {
  @Input('tooltip') tooltipString: string;
  @Input('tooltipExtracted') extracted: boolean;
  @Input('tooltipForSvg') tooltipForSvg: boolean;

  tooltipElement: HTMLElement = document.createElement('div');
  shadow: HTMLElement = document.createElement('div');

  constructor(private el: ElementRef) {}

  ngOnInit() {
    if (!this.tooltipString) return;
    this.tooltipElement = document.createElement('div');
    this.tooltipElement.style.position = 'fixed';
    this.tooltipElement.style.zIndex = '10';
    this.tooltipElement.className = 'ui-tooltipElement';
    this.tooltipElement.setAttribute('role', 'alert');
    this.tooltipElement.innerHTML = `
      <div class="t-tooltip">
        ${this.tooltipString}
      </div>

    `;
    if (!this.el.nativeElement.hasAttribute('aria-label')) {
      this.el.nativeElement.setAttribute('aria-label', this.tooltipString);
    }
    window.addEventListener('scroll', () => {
      this.remove();
    });
  }

  present() {
    if (!this.tooltipString) return;

    const native = this.el.nativeElement;

    if (this.tooltipString && (this.extracted || this.tooltipForSvg))
      document.body.appendChild(this.tooltipElement);
    else native.appendChild(this.tooltipElement);

    this.tooltipElement.addEventListener('click', function (event) {
      event.stopPropagation();
    });
    let boundingMethod = 'getBoundingClientRect';
    requestAnimationFrame(() => {
      this.tooltipElement.style.top =
        native[boundingMethod]().top +
        native[boundingMethod]().height +
        5 +
        'px';
      this.tooltipElement.style.left =
        native[boundingMethod]().left +
        native[boundingMethod]().width / 2 -
        this.tooltipElement.offsetWidth / 2 +
        'px';
    });
  }
  remove() {
    if (!this.tooltipString) return;

    this.shadow.appendChild(this.tooltipElement);
  }
  @HostListener('mouseenter') onMouseEnter() {
    this.present();
  }
  @HostListener('mouseleave') onMouseLeave() {
    this.remove();
  }
  @HostListener('focus') onFocus() {
    this.present();
  }
  @HostListener('blur') onBlur() {
    this.remove();
  }
}
