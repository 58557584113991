<span
  *ngFor="let wrappedWord of wrappedPhraseWords; let last = last; let first = first"
  [class.missing]="wrappedWord.word.dispFormat == 'MISSING'"
  [class.superscript]="wrappedWord.word.superscriptSw"
  [class.bold]="wrappedWord.word.boldSw"
  [class.italic]="wrappedWord.word.italicSw"
  [class.underline]="wrappedWord.word.underlineSw"
  [class.diff]="wrappedWord.word.markDiffSw"
  [tooltip]="wrappedWord.word.formatDescrDiffOnly || wrappedWord.word.formatDescr"
  (mouseenter)="updateHighlightedId(wrappedWord.word.id)"
  (mouseleave)="updateHighlightedId()"
  [class.diffWordHighlight]="isHighlighted(wrappedWord.word.id)"
>

  <grammar-spelling-formatted-word
    *ngIf="!wrappedWord.word.chars"
    [wrappedWord]="wrappedWord"
    (onAddToWhiteList)="addToWhiteList($event)"
    [first]="first"
    >{{ wrappedWord.word.text }}</grammar-spelling-formatted-word>

  <grammar-spelling-formatted-word
    *ngIf="wrappedWord.word.chars"
    [wrappedWord]="wrappedWord"
    (onAddToWhiteList)="addToWhiteList($event)"
    [first]="first"
    >
    <span
        *ngFor="let char of wrappedWord.word.chars; let i = index; let lastChar = last"
        [class.missing]="char.dispFormat == 'MISSING'"
        [class.superscript]="char.superscriptSw"
        [class.bold]="char.boldSw"
        [class.italic]="char.italicSw"
        [class.underline]="char.underlineSw"
        [class.reference-issue]="wrappedWord | referenceIssueCheck:i:isReferenceIssueOnIndex"
      >{{ char.text }}</span>
    </grammar-spelling-formatted-word>
</span>
