import {
  Component,
  OnInit,
  Input,
  ContentChildren,
  QueryList,
  HostBinding,
  Output,
  EventEmitter,
} from '@angular/core';
import { Router } from '@angular/router';
// models
import { Tab } from '../../../models/tab.model';
// providers
import { TabSingleComponent } from './tab-single/tab-single.component';
import { NgRedux } from '@redux/redux-compatibility.service';
import { AppState, actionsList } from 'src/app/shared/redux/store';
/**
 * A tab can point to a component either as parent via the ng-content directive
 * or by routing via the 'link' property.
 */
@Component({
  selector: 'tabs',
  template: `
    <div class="h-full g-tabSet" [ngClass]="{ 'has-topStroke': topStroke }">
      <nav role="tablist" class="l-align-bottom l-flex-wrap t-tablist">
        <span
          *ngFor="let tab of localTabList"
          class="l-block l-pos-relative tab-button-wrapper"
          (mouseenter)="emitMouseEnter(tab)"
          (mouseleave)="emitMouseLeave(tab)"
        >
          <tab-button
            [tab]="tab"
            (onTabClick)="setActiveTabOnClick($event)"
          ></tab-button>
          <ng-container *ngTemplateOutlet="tab.subMenu"></ng-container>
        </span>
      </nav>
      <div #tabsHolder class="t-tabContainer" scrollable>
        <router-outlet></router-outlet>
        <ng-container *ngIf="!byRoute || !removeRoute">
          <ng-content></ng-content>
        </ng-container>
      </div>
    </div>
  `,
})
export class TabsComponent implements OnInit {
  activeTab: Tab;
  activeIds: string[] = [];
  localTabList: Tab[];
  activeTabList: Tab[] = [];
  tabStoreKey: string;
  tabStoreUpdate: { currentTab };
  @Input() defaultTab: string;
  @Input() hFullAll: boolean;
  @Input() topStroke: boolean;
  @Input() removeRoute: boolean = false;
  @Output() onMouseEnter = new EventEmitter<Function>();
  @Output() onMouseLeave = new EventEmitter<Function>();
  @ContentChildren(TabSingleComponent) tabs: QueryList<TabSingleComponent>;
  @HostBinding('class') get hFull() {
    if ((this.activeTab && this.activeTab.hFull) || this.hFullAll) {
      return 'l-flex-column h-full t-preventScroll';
    }
    return '';
  }
  get byRoute(): boolean {
    return this.activeTab && !!this.activeTab.link;
  }
  constructor(private ngRedux: NgRedux<AppState>, private router: Router) {}

  ngOnInit() {
    this.activeTabList = this.ngRedux.getState().ui_activeTabs;
    this.ngRedux.subscribe(() => {
      const currentState = this.ngRedux.getState();
      switch (currentState._currentAction) {
        case actionsList.UI__HIGHLIGHT_TABS:
          this.activeTabList = this.ngRedux.getState().ui_activeTabs;
          this.updateTabs();
          break;

        case actionsList.UI__SET_ACTIVE_TABS:
          this.activeTabList = this.ngRedux.getState().ui_activeTabs;
          this.navigateByTab(this.activeTabList[0]);
          if (!this.localTabList.find((e) => e.isActive)) {
            this.setDefaultTab();
          }
          break;
        case actionsList.UI__NAVIGATE_TO_TAB:
          let tab = this.localTabList.find(
            (t) => t.tabId == this.ngRedux.getState().ui_navigateToTab
          );
          if (tab) {
            this.setActiveTabOnClick(tab);
          }
          break;
      }
    });
  }

  ngAfterViewInit() {
    requestAnimationFrame(() => {
      this.addToTabStore();
      this.setDefaultTab();
    });
  }

  ngOnDestroy() {
    this.removeFromTabStore();
  }

  updateTabs() {
    this.localTabList.forEach((tab) => {
      const match = this.activeTabList.find((e) => e.tabId === tab.tabId);
      if (match) {
        Object.assign(tab, match);
      }
    });
  }

  setActiveTabOnClick(tab: Tab) {
    this.localTabList.forEach((localTab) => {
      localTab.isActive = false;
    });
    tab.isActive = true;
    this.navigateByTab(tab);
    if (tab.isActive && typeof tab.onSelect === 'function') tab.onSelect(tab);
  }

  setActiveTabByNavigation(tab: Tab) {
    this.localTabList.forEach((localTab) => {
      const cond = localTab.link === tab.link;
      localTab.isActive = cond;
      if (cond) this.activeTab = tab;
    });
    tab.disabled = false;
  }

  setActiveTabByTabId(tab: Tab) {
    this.localTabList.forEach((localTab) => {
      if (localTab.tabId === tab.tabId) {
        this.localTabList.forEach((lt) => {
          lt.isActive = false;
        });
        localTab.isActive = true;
        this.activeTab = tab;
      }
    });
  }

  navigateByTab(tab: Tab) {
    requestAnimationFrame(() => {
      this.ngRedux.dispatch({
        type: actionsList.UI__TAB_NAVIGATION,
        data: tab,
      });
    });
    if (tab.link) {
      this.router.navigate([tab.link]);
      this.setActiveTabByNavigation(tab);
    } else if (tab.tabId) {
      this.setActiveTabByTabId(tab);
    }
    this.tabStoreUpdate.currentTab = tab;
  }

  private addToTabStore() {
    // should only be called after view init
    this.localTabList = this.tabs.toArray();
    this.tabStoreKey = Math.random().toString();
    const _state = this.ngRedux.getState();
    this.tabStoreUpdate = {
      set currentTab(tab) {
        _state.ui_tabStore.currentTab = tab;
      },
    };
    this.tabStoreUpdate[this.tabStoreKey] = this.localTabList;
    this.ngRedux.dispatch({
      type: actionsList.UI__UPDATE_TAB_STORE,
      data: Object.assign(
        this.ngRedux.getState().ui_tabStore,
        this.tabStoreUpdate
      ),
    });
    this.localTabList = this.ngRedux.getState().ui_tabStore[this.tabStoreKey];
  }

  private removeFromTabStore() {
    // should only be called on destroy
    delete this.ngRedux.getState().ui_tabStore[this.tabStoreKey];
    this.ngRedux.dispatch({
      type: actionsList.UI__UPDATE_TAB_STORE,
      data: this.ngRedux.getState().ui_tabStore,
    });
  }

  setDefaultTab() {
    let defaultItem;
    if (this.defaultTab) {
      defaultItem = this.localTabList.find((item) => {
        return item.tabId === this.defaultTab;
      });
    } else {
      defaultItem = this.localTabList[0];
    }
    this.setActiveTabOnClick(defaultItem);
  }

  dispatchTab(tab: Tab) {
    this.ngRedux.dispatch({
      type: actionsList.UI__SET_ACTIVE_TABS,
      data: [tab],
    });
  }

  emitMouseEnter(tab: Tab) {
    this.onMouseEnter.emit(tab.onMouseEnter);
    tab.onMouseEnter && tab.onMouseEnter(tab);
  }

  emitMouseLeave(tab: Tab) {
    this.onMouseLeave.emit(tab.onMouseLeave);
    tab.onMouseLeave && tab.onMouseLeave(tab);
  }

  refreshTabsToStore() {
    this.removeFromTabStore();
    this.addToTabStore();
  }
}
