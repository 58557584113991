import { NgRedux } from '@redux/redux-compatibility.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AltRef, IndPhrase } from 'src/app/shared/models/cat.model';
import { AppState } from 'src/app/shared/redux/store';
import { CatService } from 'src/app/shared/services/cat.service';

@Component({
  selector: 'add-to-alt-ref',
  templateUrl: './add-to-alt-ref.component.html',
  styleUrls: ['./add-to-alt-ref.component.scss'],
})
export class AddToAltRefComponent implements OnInit {
  @Output() onClose = new EventEmitter();
  @Input() phrases: IndPhrase[];

  saving: boolean;
  loading: boolean;

  selectedAltRef: AltRef;

  get enableSubmit(): boolean {
    return this.selectedAltRef != null;
  }

  constructor(
    private ngRedux: NgRedux<AppState>,
    private catService: CatService
  ) {}

  ngOnInit(): void {}

  onRowSelected(row: AltRef) {
    this.selectedAltRef = row;
  }

  close() {
    this.onClose.emit();
  }

  save() {
    this.saving = true;
    let phrases = [
      ...this.selectedAltRef.phrases.map((p) => p.refPhraseId),
      ...this.phrases
        .filter(
          (p) =>
            !this.selectedAltRef.phrases.find(
              (x) => x.refPhraseId == p.phraseId
            )
        )
        .map((p) => p.phraseId),
    ];
    this.catService.updCatAltRef(this.selectedAltRef, phrases).subscribe(
      (data) => {
        this.saving = false;
        this.onClose.emit();
      },
      (err) => (this.saving = false)
    );
  }
}
