<modal-popup
  [flexible]="true"
  [focusOnOpen]="true"
  [isOpen]="true"
  (onClose)="close()"
  [title]="'translate.cat.editPhrases'"
>
  <div class="container">
    <p class="t-row--small fw-semibold">
      {{ "translate.cat.phraseType" | translate }}
    </p>

    <p-tree
      [value]="catPhraseTypesTree"
      [(selection)]="selectedPhraseType"
      scrollHeight="400px"
      selectionMode="single"
      filter="true"
    ></p-tree>

    <div class="l-align-end l-mar-top">
      <button
        class="t-button"
        [disabled]="loading || !selectedPhraseType"
        (click)="save()"
      >
        {{ "translate.general.save" | translate }}
      </button>
    </div>

    <loader *ngIf="loading"></loader>
  </div>
</modal-popup>
