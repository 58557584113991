import { NgRedux } from '@redux/redux-compatibility.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Card, CardAssetTemplate } from 'src/app/shared/models/cat.model';
import { AppState } from 'src/app/shared/redux/store';
import { CatService } from 'src/app/shared/services/cat.service';

@Component({
  selector: 'add-cards-to-template',
  templateUrl: './add-cards-to-template.component.html',
  styleUrls: ['./add-cards-to-template.component.scss'],
})
export class AddCardsToTemplateComponent implements OnInit {
  @Output() onClose = new EventEmitter();
  @Input() cards: Card[];

  selectedCardTemplate: CardAssetTemplate;
  saving: boolean;
  loading: boolean;

  get data(): CardAssetTemplate[] {
    return this.ngRedux.getState().cardAssetTemplates;
  }

  get enableSubmit(): boolean {
    return this.selectedCardTemplate != null;
  }

  constructor(
    private ngRedux: NgRedux<AppState>,
    private catService: CatService
  ) {}

  ngOnInit(): void {
    if (!this.data || this.data.length == 0) {
      this.loading = true;
      this.catService.getCardAssetTemplates(true).subscribe(
        (data) => {
          this.loading = false;
        },
        (err) => (this.loading = false)
      );
    }
  }

  close() {
    this.onClose.emit();
  }

  save() {
    this.saving = true;
    let edited = Object.assign(
      new CardAssetTemplate(),
      this.selectedCardTemplate
    );
    this.cards.forEach((c) => {
      if (!edited.cards.find((card) => card.cardId == c.cardId)) {
        edited.cards.push(c);
      }
    });
    this.catService.updateCardAssetTemplate(edited).subscribe(
      (data) => {
        this.saving = false;
        this.onClose.emit();
      },
      (err) => (this.saving = false)
    );
  }
}
