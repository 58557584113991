

export class Validation {
    phone: any;
    email: any;
    latin: any;
    file: any;
    number: any;
    constructor() {
        this.phone = /^[\+]?[(]?[0-9]{2,3}[)]?[-\s\.]?[0-9]{2,3}[-\s\.]?[0-9]{4,6}$/im ;
        //this.email = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        this.email = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
        this.latin = '[^A-Za-z0-9_ֿֿ:\-ֿֿֿֿֿֿֿֿֿ]';
        this.file = /[^A-Za-z0-9 :.\\\-_]/g;
        this.number = '[0-9]'
    }
}

export type  ValidationTypes = 'email'|'tel'|'latin'|'file'|'number';

export class CSVExtensions {
    xlsx = undefined;
    csv = undefined;
    xml = undefined;
    xls = undefined;
}