import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  TemplateRef,
  QueryList,
  ViewChildren,
  ViewChild,
  ChangeDetectorRef,
} from '@angular/core';
import { trigger, transition, animate, style } from '@angular/animations';

import { Table } from 'primeng/table';

import {
  PaginationModel,
  PaginationParams,
} from 'src/app/shared/models/common';
import { UiService } from 'src/app/shared/services/ui.service';

interface ColSet {
  title?: string;
  key?: string;
  colClass?: string;
  isTrueIcon?: string;
  isFalseIcon?: string;
  isImage?: boolean;
  permission?: boolean;
}

interface Templates extends ColSet {
  afterColumn?: number;
  template?: TemplateRef<any>;
}
@Component({
  selector: 'ui-table',
  templateUrl: './table.component.html',
  animations: [
    trigger('delayLeave', [
      transition(':enter', [
        style({ transform: 'translateY(0%)' }),
        animate('0ms ease-in', style({ transform: 'translateY(0%)' })),
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({ transform: 'translateY(0%)' })),
      ]),
    ]),
  ],
})
export class TableComponent implements OnInit {
  constructor(private uiService: UiService, private cdr: ChangeDetectorRef) {
    setInterval(() => {
      this.cdr.detectChanges();
    }, 1000);
  }
  tableEnhance(tbody) {
    this.tableEnhance = null;
    this.uiService.arrowNav(tbody, tbody.querySelectorAll('button, a, input'));
  }

  @ViewChild('uiTable', { static: false }) set ft(table: Table) {
    if (table) {
      this.tableRef = table;
      // table ui enhance (arrow navigation etc.)
      // const tbody = table.el.nativeElement.querySelector('.ui-table-tbody');
      // this.tableEnhance && this.tableEnhance(tbody);
      // alter p-table api on table refresh
      if (this.requestPTableApiUpdate && !this.requestUpdate.observers.length) {
        table.first = 0;
        this.requestPTableApiUpdate = false;
      }
      if (this.totalRecords) {
        table.totalRecords = this.totalRecords;
      }
    }
  }

  @Input('data') set data(data: Object[]) {
    this._data = data;
    this.requestPTableApiUpdate = true;
  }
  @Input() totalRecords: number = null;
  @Input() numberOfRows: number = 15;
  @Input() displayFilter: string[] = [];
  @Input() mobileFilter: string[] = [];
  @Input() templates: Templates[] = [];
  @Input() more: TemplateRef<any>;
  @Input() isTrueIcon: string = 'check';
  @Input() isFalseIcon: string = '';
  @Input() orderBy: string;
  @Input() sortOrder: 1 | -1 = -1;
  @Input() sorthableColumns: boolean;
  @Input() title: string;
  @Input() selectedRow: Object;
  @Input() topEnd: TemplateRef<any>;
  @Input() belowTitle: TemplateRef<any>;
  @Input() aboveTableHeader: TemplateRef<any>;
  @Input() footerTemplate: TemplateRef<any>;
  @Input() colSet: ColSet[];
  @Input() isWrapped: boolean = false;
  @Input() allowClickableRow: boolean = true;
  @Input() prepend: boolean;
  @Input() sortable: boolean;
  @Input() wrapperClass: string;
  @Input() dense: boolean;
  @Input() tblColor: boolean;
  @Input() tblRoundBottom: boolean;
  @Input() paginatorTxt: boolean = true;
  @Input() rowHover: boolean = true;
  @Input() showPaginator: boolean = false;
  @Input() paginatorPosition: string = 'both';
  @Input() selectMode: string = 'multiple';
  @Input() checkboxSelection: boolean;
  @Input() showMore: boolean;
  @Input() multipleExpand: boolean;
  @Input() showExpandAll: boolean;
  @Input() observeSelection: boolean = true;

  @Output() requestUpdate = new EventEmitter();
  @Output() onRowSelect = new EventEmitter();
  @Output() onMoreOpen = new EventEmitter<any>();
  @Output() onMoreClose = new EventEmitter();
  @Output() onColumnSortRequest = new EventEmitter<[string, boolean]>();
  @Output() onPaginate = new EventEmitter();
  @Output() onSelectionChanged = new EventEmitter<any[]>();

  _data: Object[];
  first: number = 0;
  rangeFrom: number;
  rangeTo: number;
  templatesFiltered: Templates[];
  colSpan: number = 0;
  observers: boolean;
  tableUiStartOnce: boolean = true;
  openCache: Object[] = [];
  tableDomEnhance: any;
  requestPTableApiUpdate: boolean;
  preventTableRefresh: boolean;
  currentClickableTy: number;
  currentClickableTemplate: number;
  displayFilterSorrtToggler: Map<string, boolean> = new Map();
  tableRef: Table;
  selectedRows: any[] = [];
  toggleExpandAll: boolean;

  ngOnInit() {
    this.displayFilter = this.displayFilter.filter((item) => item);
    this.templates = this.templates.filter(
      (t) => t.template && t.permission !== false
    );
    this.templatesFiltered = this.templates.filter(
      (t) => typeof t.afterColumn !== 'number'
    );
    this.colSpan = this.templates.length + this.displayFilter.length || 1;
    console.log(this.onRowSelect.observers);
    this.observers =
      !!this.onRowSelect.observers.length && this.observeSelection;
    if (this.more) this.colSpan += 1;
    if (this.checkboxSelection) this.colSpan += 1;
    if (this.colSet && this.templates)
      this.colSet = this.colSet.concat(this.templates);
    this.displayFilter.forEach((e) =>
      this.displayFilterSorrtToggler.set(e, false)
    );
    this.templatesFiltered.forEach((e) =>
      this.displayFilterSorrtToggler.set(e.key, false)
    );
  }

  ngOnChanges() {
    this.tableDomEnhance = null;
  }

  ngOnDestroy() {
    this.tableDomEnhance = null;
  }

  public isImage(cell: string): boolean {
    return this.uiService.isImage(cell);
  }

  public isString(cell: string): boolean {
    return !this.isTranslatable(cell) && this.uiService.isString(cell);
  }
  public isTranslatable(cell: string): boolean {
    return typeof cell === 'string' && cell.indexOf('translate') === 0;
  }

  isBool(cell: boolean | string): boolean {
    return typeof cell === 'boolean';
  }

  getTemplateByColumn(i: number) {
    const template = this.templates.find((t) => t.afterColumn == i);
    if (template && template.permission) return template;
    else return null;
  }

  private paginateTable(
    event: PaginationModel,
    callback: Function = null
  ): PaginationParams {
    const paginationParams: PaginationParams = {};
    let suffix = event.first === 0 ? 0 : 1;
    suffix = 1;
    paginationParams.rangeFrom = event.first + suffix;
    paginationParams.rangeTo = event.first + this.numberOfRows;
    if (typeof callback === 'function') callback();
    return paginationParams;
  }

  outputPaginationData($event) {
    this.requestUpdate.emit(Object.assign(this.paginateTable($event)));
  }

  onPagination() {
    this.tableDomEnhance = null;
    if (this.onPaginate) {
      this.onPaginate.emit();
    }
  }

  private addToOpenCache(row) {
    this.openCache.push(row);
  }

  private removeFromOpenCache(row) {
    let openCacheRowIndex: number = this.openCache.findIndex((r) => r === row);
    if (openCacheRowIndex !== -1) this.openCache.splice(openCacheRowIndex, 1);
  }

  isInOpenCache(row): boolean {
    return !!this.openCache.find((r) => r == row);
  }

  public toggleShowMore(row, event?: MouseEvent) {
    event && event.stopPropagation();

    if (!this.isInOpenCache(row)) {
      if (!this.multipleExpand) {
        this.openCache = [];
      }

      this.addToOpenCache(row);
      this.selectedRow = row;
      this.onMoreOpen.emit(row);
    } else {
      this.removeFromOpenCache(row);
      this.selectedRow = null;
      this.onMoreClose.emit(row);
    }
  }

  expandAll(event?: MouseEvent) {
    event && event.stopPropagation();

    if (!this.toggleExpandAll) {
      this.openCache = [];
      this._data.forEach((row) => {
        this.addToOpenCache(row);
      });
    } else {
      this.openCache = [];
    }

    this.toggleExpandAll = !this.toggleExpandAll;
  }

  rowSelected(row) {
    if (!this.observers) {
      if (this.more && this.allowClickableRow) {
        this.toggleShowMore(row);
      }
    } else {
      if (this.selectMode == 'single' && this.selectedRow == row) {
        this.selectedRow = null;
      } else {
        this.selectedRow = row;
      }
      this.onRowSelect.emit(this.selectedRow);
    }
  }

  highlightRow(row) {
    return row === this.selectedRow;
  }

  getColSet(key: string): ColSet {
    if (!this.colSet) return {};
    return this.colSet.find((c) => c.key === key) || {};
  }

  onSort(th, i, mouseEvent, isTemplate: boolean) {
    let bool = this.displayFilterSorrtToggler.get(th);
    const addRemove: string = bool ? 'add' : 'remove';
    mouseEvent.currentTarget.classList[addRemove]('is-descendingOrder');

    if (isTemplate) {
      this.currentClickableTemplate = i;
      this.currentClickableTy = null;
    } else {
      this.currentClickableTy = i;
      this.currentClickableTemplate = null;
    }

    this.displayFilterSorrtToggler.set(th, !bool);

    if (isTemplate) {
      this.onColumnSortRequest.emit([(th as Templates).key, bool]);
    } else {
      this.onColumnSortRequest.emit([th, bool]);
    }
  }

  onRowSelectionChanged(event: any) {
    (event.originalEvent as MouseEvent).stopPropagation();
    this.onSelectionChanged.emit(this.selectedRows);
  }

  resetSelection() {
    this.selectedRows = [];
  }
}
