import { Card, Catalog, CatDoc, IndPhrase } from './cat.model';
import {
  DataElement,
  PaginationParams,
  ProcessRunStatusResult,
  Product,
} from './common';

export class LabelChange {
  lcId?: number;
  title?: string;
  descr?: string;
  crDate?: string;
  product?: DataElement;
  catalog?: Catalog;
  bcDocs?: LCDoc[] = [];
  acDocs?: LCDoc[] = [];
  bcProcess?: ProcessRunStatusResult;
  acProcess?: ProcessRunStatusResult;
  acStats?: LCStats = new LCStats();
  bcStats?: LCStats = new LCStats();
  cards?: Card[] = [];
  acDocsCnt: number;
  bcDocsCnt: number;
  bcPhrases?: IndPhrase[] = [];
  acPhrases?: IndPhrase[] = [];
  bcDetectedPhrases?: IndPhrase[] = [];
  acDetectedPhrases?: IndPhrase[] = [];
}

export class LCSearchParams extends PaginationParams {
  product?: DataElement;
  lcId?: number;

  constructor() {
    super();
  }
}

export class LCStats {
  lcValidatedCnt: number = 0;
  lcMissingCnt: number = 0;
  lcInvalidationFoundCnt: number = 0;
}

export class LCDoc extends CatDoc {
  trainingSw: boolean;
  dirtySw: boolean;
  lcMatchStatusCode: string;
  lcMatchStatusDescr: string;

  constructor() {
    super();
  }
}

export class CombinedCard {
  cardType: string;
  cardTypeDescr: string;
  cards: Card[];
}

/*** ENUMS */

export class LCCardTypeEnum {
  public static LC_NEED_TAG: string = 'LC_NEED_TAG';
  public static LC_NEED_ALG: string = 'LC_NEED_ALG';
  public static LC_DONE_TAG: string = 'LC_DONE_TAG';
  public static LC_DONE_ALG: string = 'LC_DONE_ALG';
  public static LC_ERR_ALG: string = 'LC_ERR_ALG';
}
