export class KeyCodes {
    escape = 27;
    enter = 13;
    arrowUp = 38;
    arrowDown = 40;
    arrowLeft = 37;
    arrowRight = 39;
    tab = 9;
    shift = 16;
    backSpace = 8;
    ctrl = 17;
    alt = 18;
    meta = 91;
    capsLock = 20;
    a = 65;
    v = 86;
};
export const keyCodes = new KeyCodes();
export const focusableSelector = 'input:not([disabled]), textarea:not([disabled])';
//export const focusableSelector = 'button:not([disabled]), [href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), [tabindex]:not([tabindex="-1"])';