import { Pipe, PipeTransform } from "@angular/core";
import { Validation, ValidationTypes } from "../../models/validation.model";
@Pipe({
  name: "validation",
})
export class ValidationPipe implements PipeTransform {
  constructor() {}
  validation: Validation = new Validation();

  // ( ! new RegExp( this.validation.email ).test( user[key] ) ) {
  transform(value: string, args?: ValidationTypes): boolean {
    if (!value) return false;

    if (args === "tel") {
      // remove non numeric chars
      let valueNumOnly: string = "" + value.replace(/\D/g, "");

      // false if not starts with 0
      if (valueNumOnly.indexOf("0") !== 0) {
        return false;
      }

      // validate digit count
      let correctDigitNumber: number = 10; //( valueNumOnly.indexOf('05') === 0  ) ? 10: 9;
      if (valueNumOnly.length !== correctDigitNumber) return false;
    }
    return new RegExp(this.validation[args]).test(value);
  }
}
