import { ActionsEnum } from './actions';
import { ActionMethod } from './redux-store.model';
import { actionLogger } from './actionLogger';
import { DataElement, Product } from '../models/common';
import { UiUser } from '../models/ui-user.model';
import { ServiceMetaData } from '../models/service-meta.model';
import { Direction } from '@angular/cdk/bidi';
import { Tab, TabIdList } from '../models/tab.model';
import {
  CardMatch,
  DirectLinkDoc,
  RevDoc,
  RevRule,
  RevRulesSearchParams,
} from '../models/rev.model';
import {
  Catalog,
  CatDoc,
  CatPhraseTypeModel,
  IndPhrase,
  IndPhraseSearchParams,
  Card,
  BoundingBox,
  MergeLine,
  CatDocStatusEnum,
  IndImage,
  IndElement,
  McSubType,
  McBoxType,
  CardAssetTemplate,
  ExtRef,
  CatDocState,
  CatDocStateTagEnum,
  AltRef,
} from '../models/cat.model';
import { Env, User } from '../models/user.model';
import { LabelChange } from '../models/lc.model';
import {
  TaggedDocsSearchParams,
  TaggedIndDoc,
  TaggedRevDoc,
} from '../models/tagged_doc.model';
import { IIntegrationType } from '../models/integration-type.model';

export class Actions extends ActionsEnum {}

/**
 * for accessing the actions enum list. used all over the app.
 */
const actionsInstance = new Actions();
const actionsToArray = Object.values(actionsInstance);
export type actionStringLiteral = (typeof actionsToArray)[number];

export const actionsList = Object.freeze(new Actions());
/**
 * For performance and clarity, avoid nesting properties in AppState.
 */

export class AppState {
  _logSw?: boolean = true;
  _currentServiceCall?: Object = null;
  _currentAction?: string = '';
  _currentActionData?: any = '';
  _currentActionCatDocStateTag?: CatDocStateTagEnum;
  _errorFromServer?: any = {};
  ui_lang?: string;
  ui_requestSetLang?: string;
  ui_direction?: Direction;
  ui_navigation?: string;
  ui_sidebarNavTitle?: string;
  ui_tabStore?: { currentTab? } = new Object();
  ui_tabIdList?: TabIdList;
  ui_activeTabs?: Tab[] = [];
  ui_mobileNavOpen?: boolean = false;
  ui_tabNavigation?: Tab;
  ui_navigateToTab?: string;

  user?: UiUser = null;
  envList?: Env[] = null;
  curEnv?: Env = null;
  curEnvIntegrationTypes?: IIntegrationType[];
  curEnvConfiguredIntegrationType?: string = null;
  productList?: Product[] = [];
  allProducts?: Product[] = [];
  timezoneList?: DataElement[] = [];

  revDocs?: RevDoc[] = [];
  revDocsMetadata?: ServiceMetaData = null;
  revDocStatusList?: DataElement[] = [];
  curRevDoc?: RevDoc = null;
  revDocRules?: RevRule[] = [];
  curRevRule?: RevRule = null;
  revRulesSearchParams?: RevRulesSearchParams = null;
  revRuleTypeList?: DataElement[] = [];
  revRuleStatusList?: DataElement[] = [];
  contentTypeList?: DataElement[] = [];
  revRuleSimilarityEventList?: DataElement[] = [];
  indRuleSimilarityEventList?: DataElement[] = [];
  curRevDocPageNum?: number = 1;
  revDocCards?: Card[] = [];
  revDocMatchedCards?: Card[] = [];
  revCardMatches?: CardMatch[];

  docPageLoading?: boolean;

  figureTypeList?: DataElement[] = [];
  catalogList?: Catalog[] = [];
  extCatalogList?: Catalog[] = [];
  catalogsMetadata?: ServiceMetaData = null;
  extCatalogsMetadata?: ServiceMetaData = null;
  catTypeList?: DataElement[] = [];
  refMatchTypeList?: DataElement[] = [];
  catLangList?: DataElement[] = [];
  curCat?: Catalog = null;
  curCatIntegrationConfig?: any = null;
  catEntries?: IndPhrase[] = [];
  catImages?: IndImage[] = [];
  catEntriesMetadata?: ServiceMetaData = null;
  catImagesMetadata?: ServiceMetaData = null;
  catDocs?: CatDoc[] = [];
  catDocsMetadata?: ServiceMetaData = null;
  catDocStatusList?: DataElement[] = [];
  catPhraseTypeModel?: CatPhraseTypeModel = null;
  catPhraseStatusList?: DataElement[] = [];
  catMatchSimilarityCodes?: DataElement[] = [];
  curCatElement?: IndElement = null;
  publishedCat?: Catalog = null;
  cards?: Card[] = [];
  curCard?: Card;
  cardTypes?: DataElement[] = [];
  boundingBoxTypes?: DataElement[] = [];
  assetTypes?: DataElement[] = [];
  mcTypes?: DataElement[] = [];
  mcSubTypes?: McSubType[] = [];
  mcBoxTypes?: McBoxType[] = [];
  catEntryStatusList?: DataElement[] = [];
  cardAssetTemplates?: CardAssetTemplate[] = [];
  curCardAssetTemplate?: CardAssetTemplate;
  catExtRefs?: ExtRef[] = [];
  catAltRefs?: AltRef[] = [];
  catAltRefsMetadata?: ServiceMetaData = null;
  curCatDocStatePrime?: CatDocState = new CatDocState();
  curCatDocStateSec?: CatDocState = new CatDocState();

  ruleConfigTemplateList?: DataElement[] = [];

  usersList?: User[] = [];
  userRoles?: DataElement[] = [];

  labelChanges?: LabelChange[] = [];
  lcMetadata?: ServiceMetaData = null;
  curLabelChange?: LabelChange;

  tagIndDocs?: TaggedIndDoc[] = [];
  tagRevDocs?: TaggedRevDoc[] = [];
  tagIndDocMetadata?: ServiceMetaData = null;
  tagRevDocMetadata?: ServiceMetaData = null;
  tagDocSearchParams?: TaggedDocsSearchParams;

  dlRevDoc?: DirectLinkDoc;

  constructor() {
    this.ui_tabIdList = Object.freeze(new TabIdList());
  }
}

/**
 * This is used to initialize the store in app.modules.
 * You can also use it to reset the entire state of the app.
 * DO NOT DLETE OR CHANGE THIS LINE !
 * */

export const initAppState = Object.freeze(new AppState());

/**
 * A private function to update the state.
 * Prevents code duplicates Should be returned at
 * the end of each case in rootReducer switch statement.
 * */
const updateState = (state: AppState, action: ActionMethod, update: any) => {
  const stateUpdate = Object.assign({}, state, update, {
    _currentAction: action.type,
    _currentActionData: action.data,
    _currentActionCatDocStateTag: action.catDocSTateTag,
  });
  if (state._logSw) {
    actionLogger(stateUpdate, action, update);
  }

  return stateUpdate;
};

export const rootReducer = (state: AppState, action: ActionMethod) => {
  let update: AppState;
  switch (action.type) {
    case actionsList.GET_TIMEZONE_LIST:
      update = {
        timezoneList: action.data,
      };
      return updateState(state, action, update);
    case actionsList.USER_SIGN_OUT:
      update = {};
      return updateState(state, action, update);
    case actionsList.UI__REQUEST_SET_LANG:
      update = {
        ui_requestSetLang: action.data,
      };
      return updateState(state, action, update);
    case actionsList.UI__SET_LANG:
      update = {
        ui_lang: action.data.lang,
        ui_direction: action.data.direction,
      };
      return updateState(state, action, update);
    case actionsList.UI__ROUTE_NAVIGATION:
      update = {
        ui_navigation: action.data,
      };
      return updateState(state, action, update);
    case actionsList.UI__SIDEBAR_ROUTE_NAVIGATION:
      update = {
        ui_sidebarNavTitle: action.data,
      };
      return updateState(state, action, update);
    case actionsList.UI__SET_ACTIVE_TABS:
      update = {
        ui_activeTabs: new Array().concat(action.data) || [],
      };
      return updateState(state, action, update);
    case actionsList.UI__HIGHLIGHT_TABS:
      update = {
        ui_activeTabs: new Array().concat(action.data) || [],
      };
      return updateState(state, action, update);
    case actionsList.UI__TAB_NAVIGATION:
      update = {
        ui_tabNavigation: action.data,
      };
      return updateState(state, action, update);
    case actionsList.UI__NAVIGATE_TO_TAB:
      update = {
        ui_navigateToTab: action.data,
      };
      return updateState(state, action, update);
    case actionsList.UI__UPDATE_TAB_STORE:
      update = {
        ui_tabStore: action.data,
      };
      return updateState(state, action, update);
    case actionsList.ERROR__FROM_SERVER:
      update = {
        _errorFromServer: action.data,
      };
      return updateState(state, action, update);
    case actionsList.GET_USER_DETAILS:
      update = {
        user: action.data,
      };
      return updateState(state, action, update);
    case actionsList.APP_SIGN_IN:
      update = {
        user: action.data,
      };
      return updateState(state, action, update);
    case actionsList.SELECT_ENV:
      update = {
        curEnv: action.data,
        curEnvIntegrationTypes: null,
        curEnvConfiguredIntegrationType: null
      };
      return updateState(state, action, update);
    case actionsList.GET_REV_DOCS:
      update = {
        revDocs: action.data,
        revDocsMetadata: action.metaData,
      };
      return updateState(state, action, update);
    case actionsList.GET_REV_DOC_STATUS_LIST:
      update = {
        revDocStatusList: action.data,
      };
      return updateState(state, action, update);
    case actionsList.GET_PRODUCT_LIST:
      update = {
        productList: action.data,
      };
      return updateState(state, action, update);
    case actionsList.GET_CROSS_ENV_PRODUCTS:
      update = {
        allProducts: action.data,
      };
      return updateState(state, action, update);
    case actionsList.SELECT_REV_DOC:
      update = {
        curRevDoc: action.data,
        catPhraseTypeModel: null,
        revDocCards: [],
        revDocMatchedCards: [],
      };
      return updateState(state, action, update);
    case actionsList.GET_REV_DOC_RULES:
      update = {
        revDocRules: action.data,
      };
      return updateState(state, action, update);
    case actionsList.SELECT_REV_RULE:
      update = {
        curRevRule: action.data,
      };
      return updateState(state, action, update);
    case actionsList.FILTER_REV_RULES:
      update = {
        revRulesSearchParams: action.data,
      };
      return updateState(state, action, update);
    case actionsList.GET_REV_RULE_TYPES:
      update = {
        revRuleTypeList: action.data,
      };
      return updateState(state, action, update);
    case actionsList.GET_REV_RULE_STATUS_LIST:
      update = {
        revRuleStatusList: action.data,
      };
      return updateState(state, action, update);
    case actionsList.GET_CONTENT_TYPE_LIST:
      update = {
        contentTypeList: action.data,
      };
      return updateState(state, action, update);
    case actionsList.GET_REV_RULE_SIMILARITY_EVENT_LIST:
      update = {
        revRuleSimilarityEventList: action.data,
      };
      return updateState(state, action, update);
    case actionsList.GET_IND_RULE_SIMILARITY_EVENT_LIST:
      update = {
        indRuleSimilarityEventList: action.data,
      };
      return updateState(state, action, update);
    case actionsList.GET_ENV_LIST:
      update = {
        envList: action.data,
      };
      return updateState(state, action, update);
    case actionsList.UPD_REV_DOC:
      let updDoc = action.data as RevDoc;
      let curVersion = updDoc.docVersions.find(
        (v) => v.versionId == state.curRevDoc.versionId
      );
      state.curRevDoc.statusCode = curVersion.statusCode;
      state.curRevDoc.statusDescr = curVersion.statusDescr;
      state.curRevDoc.statusDate = curVersion.statusDate;

      let parentIdx = state.revDocs.findIndex((d) => d.docId == updDoc.docId);
      if (parentIdx !== -1) {
        state.revDocs[parentIdx] = updDoc;
      }

      update = {};
      return updateState(state, action, update);
    case actionsList.SELECT_REV_DOC_PAGE_NUM:
      update = {
        curRevDocPageNum: action.data,
      };
      return updateState(state, action, update);
    case actionsList.SELECT_REV_DOC_PAGE_FROM_SEARCH:
      update = {
        curRevDocPageNum: action.data,
      };
      return updateState(state, action, update);
    case actionsList.SELECT_CAT_DOC_PAGE_FROM_SEARCH: {
      if (action.catDocSTateTag == CatDocStateTagEnum.PRIME) {
        let copyState = state.curCatDocStatePrime;
        copyState.curCatDocPageNum = action.data;
        update = {
          curCatDocStatePrime: copyState,
        };
      } else {
        let copyState = state.curCatDocStateSec;
        copyState.curCatDocPageNum = action.data;
        update = {
          curCatDocStateSec: copyState,
        };
      }
      return updateState(state, action, update);
    }
    case actionsList.UPD_REV_DOC_STATS:
      if (state.curRevDoc) {
        state.curRevDoc.failedCnt = action.data.failedCnt;
        state.curRevDoc.passedCnt = action.data.passedCnt;
        state.curRevDoc.dismissedCnt = action.data.dismissedCnt;
        state.curRevDoc.markedCnt = action.data.markedCnt;
        state.curRevDoc.stetCnt = action.data.stetCnt;
        state.curRevDoc.notPassedVarCnt = action.data.notPassedVarCnt;
        state.curRevDoc.newContentCnt = action.data.newContentCnt;
        state.curRevDoc.progressPct = action.data.progressPct;
        state.curRevDoc.totCnt = action.data.totCnt;
        state.curRevDoc.newClaimCnt = action.data.newClaimCnt;
        state.curRevDoc.claimVariantCnt = action.data.claimVariantCnt;
      }

      update = {};
      return updateState(state, action, update);
    case actionsList.GET_CATALOG_LIST:
      update = {
        catalogList: action.data,
        catalogsMetadata: action.metaData,
      };
      return updateState(state, action, update);
    case actionsList.GET_EXT_CATALOG_LIST:
      update = {
        extCatalogList: action.data,
        extCatalogsMetadata: action.metaData,
      };
      return updateState(state, action, update);
    case actionsList.GET_CAT_TYPES:
      update = {
        catTypeList: action.data,
      };
      return updateState(state, action, update);
    case actionsList.GET_REF_MATCH_TYPES:
      update = {
        refMatchTypeList: action.data,
      };
      return updateState(state, action, update);
    case actionsList.GET_CAT_LANGUAGES:
        update = {
          catLangList: action.data,
        };
        return updateState(state, action, update);
    case actionsList.GET_RULE_CONFIG_TEMPLATES:
      update = {
        ruleConfigTemplateList: action.data,
      };
      return updateState(state, action, update);
    case actionsList.SELECT_CATALOG:
      update = {
        curCatDocStatePrime: new CatDocState(),
        curCat: action.data,
        curCatIntegrationConfig: null,
        catEntries: [],
        catEntriesMetadata: null,
        catDocs: [],
        catDocsMetadata: null,
        catPhraseTypeModel: null,
        curCatElement: null,
        curCard: null,
        cards: [],
        catAltRefs: [],
        catExtRefs: [],
      };
      return updateState(state, action, update);
    case actionsList.GET_CAT_ENTRIES:
      update = {
        catEntries: action.data,
        catEntriesMetadata: action.metaData,
      };
      return updateState(state, action, update);
    case actionsList.GET_CAT_IMAGES:
      update = {
        catImages: action.data,
        catImagesMetadata: action.metaData,
      };
      return updateState(state, action, update);
    case actionsList.GET_CAT_DOCS:
      let catDocs: CatDoc[] = action.data;
      let curCat = state.curCat;
      if (catDocs && curCat) {
        curCat.attachedDocsList = catDocs
          .filter((d) => d.statusCode == CatDocStatusEnum.ADDED_TO_CATALOG)
          .map((d) => new DataElement(d.docId.toString(), d.docName));
      }

      update = {
        catDocs: action.data,
        catDocsMetadata: action.metaData,
        curCat: curCat,
      };
      return updateState(state, action, update);
    case actionsList.SELECT_CAT_DOC:
      if (action.catDocSTateTag == CatDocStateTagEnum.PRIME) {
        let copyState = state.curCatDocStatePrime;
        copyState.curCatDoc = action.data;
        copyState.catDocRefOptions = [];
        update = {
          curCatDocStatePrime: copyState,
        };
      } else {
        let copyState = state.curCatDocStateSec;
        copyState.curCatDoc = action.data;
        copyState.catDocRefOptions = [];
        update = {
          curCatDocStateSec: copyState,
        };
      }

      return updateState(state, action, update);
    case actionsList.CLOSE_CAT_DOC:
      if (action.catDocSTateTag == CatDocStateTagEnum.PRIME) {
        update = {
          curCatDocStatePrime: new CatDocState(),
        };
      } else {
        update = {
          curCatDocStateSec: new CatDocState(),
        };
      }
      return updateState(state, action, update);
    case actionsList.GET_CAT_PHRASE_TYPES:
      update = {
        catPhraseTypeModel: action.data,
      };
      return updateState(state, action, update);
    case actionsList.UPD_CAT_DOC_STATS:
      if (state.curCatDocStatePrime.curCatDoc) {
        state.curCatDocStatePrime.curCatDoc.approvedCnt =
          action.data.approvedCnt;
        state.curCatDocStatePrime.curCatDoc.approvedVariantCnt =
          action.data.approvedVariantCnt;
        state.curCatDocStatePrime.curCatDoc.rejectedCnt =
          action.data.rejectedCnt;
        state.curCatDocStatePrime.curCatDoc.pendingCnt = action.data.pendingCnt;
        state.curCatDocStatePrime.curCatDoc.progressPct =
          action.data.progressPct;
      }
      update = {};
      return updateState(state, action, update);
    case actionsList.GET_IND_DOC_PHRASES:
      if (action.catDocSTateTag == CatDocStateTagEnum.PRIME) {
        let copyState = state.curCatDocStatePrime;
        copyState.indDocPhrases = action.data;
        update = {
          curCatDocStatePrime: copyState,
        };
      } else {
        let copyState = state.curCatDocStateSec;
        copyState.indDocPhrases = action.data;
        update = {
          curCatDocStateSec: copyState,
        };
      }
      return updateState(state, action, update);
    case actionsList.GET_IND_DOC_IMAGES:
      if (action.catDocSTateTag == CatDocStateTagEnum.PRIME) {
        let copyState = state.curCatDocStatePrime;
        copyState.indDocImages = action.data;
        update = {
          curCatDocStatePrime: copyState,
        };
      } else {
        let copyState = state.curCatDocStateSec;
        copyState.indDocImages = action.data;
        update = {
          curCatDocStateSec: copyState,
        };
      }
      return updateState(state, action, update);
    case actionsList.SELECT_CAT_DOC_PAGE_NUM:
      if (action.catDocSTateTag == CatDocStateTagEnum.PRIME) {
        let copyState = state.curCatDocStatePrime;
        copyState.curCatDocPageNum = action.data;
        copyState.indDocPhrases = [];
        update = {
          curCatDocStatePrime: copyState,
        };
      } else {
        let copyState = state.curCatDocStateSec;
        copyState.curCatDocPageNum = action.data;
        copyState.indDocPhrases = [];
        update = {
          curCatDocStateSec: copyState,
        };
      }
      return updateState(state, action, update);
    case actionsList.FILTER_CAT_DOC_PHRASES:
      if (action.catDocSTateTag == CatDocStateTagEnum.PRIME) {
        let copyState = state.curCatDocStatePrime;
        copyState.catPhrasesSearchParams = action.data;
        update = {
          curCatDocStatePrime: copyState,
        };
      } else {
        let copyState = state.curCatDocStateSec;
        copyState.catPhrasesSearchParams = action.data;
        update = {
          curCatDocStateSec: copyState,
        };
      }
      return updateState(state, action, update);
    case actionsList.GET_CAT_PHRASE_STATUS_LIST:
      update = {
        catPhraseStatusList: action.data,
      };
      return updateState(state, action, update);
    case actionsList.SELECT_CAT_ELEMENT:
      update = {
        curCatElement: action.data,
      };
      return updateState(state, action, update);
    case actionsList.GET_CAT_DOC_STATUS_LIST:
      update = {
        catDocStatusList: action.data,
      };
      return updateState(state, action, update);
    case actionsList.GET_CAT_MATCH_SIMILARITY_CODES:
      update = {
        catMatchSimilarityCodes: action.data,
      };
      return updateState(state, action, update);
    case actionsList.UPD_CAT_DOC:
      let updCatDoc = action.data as CatDoc;
      if (state.curCatDocStatePrime.curCatDoc) {
        let curDocVersion = updCatDoc.histDocs.find(
          (v) => v.versionId == state.curCatDocStatePrime.curCatDoc.versionId
        );
        state.curCatDocStatePrime.curCatDoc.statusCode =
          curDocVersion.statusCode;
        state.curCatDocStatePrime.curCatDoc.statusDescr =
          curDocVersion.statusDescr;
        state.curCatDocStatePrime.curCatDoc.statusDate =
          curDocVersion.statusDate;
      }

      let parentDocIdx = state.catDocs.findIndex(
        (d) => d.docId == updCatDoc.docId
      );
      if (parentDocIdx !== -1) {
        state.catDocs[parentDocIdx] = updCatDoc;
      }

      update = {};
      return updateState(state, action, update);
    case actionsList.UPD_IND_PHRASE:
      let updPhrase = action.data as IndPhrase;
      let idx = state.curCatDocStatePrime.indDocPhrases.findIndex(
        (p) => p.phraseId == updPhrase.phraseId
      );
      if (idx !== -1) {
        state.curCatDocStatePrime.indDocPhrases[idx] = updPhrase;
      }
      state.curCatElement = updPhrase;
      update = {};
      return updateState(state, action, update);
    case actionsList.UPD_CAT_ENTRY:
      let updEntry = action.data as IndPhrase;
      let curIdx = state.catEntries.findIndex(
        (p) => p.phraseId == updEntry.phraseId
      );
      if (curIdx !== -1) {
        state.catEntries[curIdx] = updEntry;
      }
      state.curCatElement = updEntry;
      update = {};
      return updateState(state, action, update);
    case actionsList.DELETE_CAT_DOC_PHRASE:
      if (state.curCatElement instanceof IndPhrase) {
        let delIdx = state.curCatDocStatePrime.indDocPhrases.findIndex(
          (i) => i.phraseId == state.curCatElement.elementId
        );
        if (delIdx > -1) {
          state.curCatDocStatePrime.indDocPhrases.splice(delIdx, 1);
        }
      } else {
        let delIdx = state.curCatDocStatePrime.indDocImages.findIndex(
          (i) => i.imageId == state.curCatElement.elementId
        );
        if (delIdx > -1) {
          state.curCatDocStatePrime.indDocImages.splice(delIdx, 1);
        }
      }

      update = {
        curCatElement: null,
      };
      return updateState(state, action, update);
    case actionsList.UPD_CAT_VERSION:
      // let updCat = action.data as Catalog;
      // let catIdx = state.catalogList.findIndex((v) => v.catId == updCat.catId);
      // if (catIdx !== -1) {
      //   state.catalogList[catIdx] = updCat;
      // }
      update = {};
      return updateState(state, action, update);
    case actionsList.GET_USERS:
      update = {
        usersList: action.data,
      };
      return updateState(state, action, update);
    case actionsList.GET_USER_ROLES:
      update = {
        userRoles: action.data,
      };
      return updateState(state, action, update);
    case actionsList.RELOAD_CAT_DOC_PAGE_REQ:
      update = {};
      return updateState(state, action, update);
    case actionsList.REANNOTATE_CAT_DOC_REQ:
      update = {};
      return updateState(state, action, update);
    case actionsList.SELECT_CAT_CARD:
      update = {
        curCard: action.data,
      };
      return updateState(state, action, update);
    case actionsList.GET_CARD_TYPES:
      update = {
        cardTypes: action.data,
      };
      return updateState(state, action, update);
    case actionsList.UPD_CARD:
      update = {
        curCard: action.data,
      };
      return updateState(state, action, update);
    case actionsList.GET_CARDS:
      update = {
        cards: action.data,
      };
      return updateState(state, action, update);
    case actionsList.GET_BOUNDING_BOX_TYPES:
      update = {
        boundingBoxTypes: action.data,
      };
      return updateState(state, action, update);
    case actionsList.CREATE_CAT_BOUNDING_BOX:
      state.curCatDocStatePrime.catDocBoundingBoxes.push(action.data);
      update = {};
      return updateState(state, action, update);
    case actionsList.GET_BOUNDING_BOXES:
      if (action.catDocSTateTag == CatDocStateTagEnum.PRIME) {
        let copyState = state.curCatDocStatePrime;
        copyState.catDocBoundingBoxes = action.data;
        update = {
          curCatDocStatePrime: copyState,
        };
      } else {
        let copyState = state.curCatDocStateSec;
        copyState.catDocBoundingBoxes = action.data;
        update = {
          curCatDocStateSec: copyState,
        };
      }
      return updateState(state, action, update);
    case actionsList.REV_RULE_ANN_CLICKED:
      update = {};
      return updateState(state, action, update);
    case actionsList.CAT_PHRASE_ANN_CLICKED:
      update = {};
      return updateState(state, action, update);
    case actionsList.GET_CAT_DOC_REF_OPTIONS:
      let copyState = state.curCatDocStatePrime;
      copyState.catDocRefOptions = action.data;
      update = {
        curCatDocStatePrime: copyState,
      };
      return updateState(state, action, update);
    case actionsList.GET_CAT_DOC_PAGE_LINES:
      if (action.catDocSTateTag == CatDocStateTagEnum.PRIME) {
        let copyState = state.curCatDocStatePrime;
        copyState.catDocPageLines = action.data;
        update = {
          curCatDocStatePrime: copyState,
        };
      } else {
        let copyState = state.curCatDocStateSec;
        copyState.catDocPageLines = action.data;
        update = {
          curCatDocStateSec: copyState,
        };
      }
      return updateState(state, action, update);
    case actionsList.GET_CAT_DOC_CARDS:
      if (action.catDocSTateTag == CatDocStateTagEnum.PRIME) {
        let copyState = state.curCatDocStatePrime;
        copyState.catDocCards = action.data;
        update = {
          curCatDocStatePrime: copyState,
        };
      } else {
        let copyState = state.curCatDocStateSec;
        copyState.catDocCards = action.data;
        update = {
          curCatDocStateSec: copyState,
        };
      }
      return updateState(state, action, update);
    case actionsList.GET_ASSET_TYPES:
      update = {
        assetTypes: action.data,
      };
      return updateState(state, action, update);
    case actionsList.RUN_IND_PHRASES_SEARCH:
      if (action.catDocSTateTag == CatDocStateTagEnum.PRIME) {
        let copyState = state.curCatDocStatePrime;
        copyState.indDocImages = [];
        copyState.indDocPhrases = [];
        copyState.catDocBoundingBoxes = [];
        update = {
          curCatDocStatePrime: copyState,
        };
      } else {
        let copyState = state.curCatDocStateSec;
        copyState.indDocImages = [];
        copyState.indDocPhrases = [];
        copyState.catDocBoundingBoxes = [];
        update = {
          curCatDocStateSec: copyState,
        };
      }
      return updateState(state, action, update);
    case actionsList.GET_MC_TYPES:
      update = {
        mcTypes: action.data,
      };
      return updateState(state, action, update);
    case actionsList.GET_MC_SUB_TYPES:
      update = {
        mcSubTypes: action.data,
      };
      return updateState(state, action, update);
    case actionsList.GET_MC_BOX_TYPES:
      update = {
        mcBoxTypes: action.data,
      };
      return updateState(state, action, update);
    case actionsList.GET_LC:
      update = {
        labelChanges: action.data,
        lcMetadata: action.metaData,
      };
      return updateState(state, action, update);
    case actionsList.SELECT_LC:
      update = {
        curLabelChange: action.data,
      };
      return updateState(state, action, update);
    case actionsList.GET_IND_TAGGED_DOCS:
      update = {
        tagIndDocs: action.data,
        tagIndDocMetadata: action.metaData,
      };
      return updateState(state, action, update);
    case actionsList.GET_REV_TAGGED_DOCS:
      update = {
        tagRevDocs: action.data,
        tagRevDocMetadata: action.metaData,
      };
      return updateState(state, action, update);
    case actionsList.RUN_TAG_DOCS_SEARCH:
      update = {
        tagDocSearchParams: action.data,
      };
      return updateState(state, action, update);
    case actionsList.RELOAD_CUR_LC_REQ:
      update = {};
      return updateState(state, action, update);
    case actionsList.GET_CAT_ENTRY_STATUS_LIST:
      update = {
        catEntryStatusList: action.data,
      };
      return updateState(state, action, update);
    case actionsList.GET_REV_DOC_CARDS:
      update = {
        revDocCards: action.data,
      };
      return updateState(state, action, update);
    case actionsList.GET_REV_DOC_MATCHED_CARDS:
      update = {
        revDocMatchedCards: action.data,
      };
      return updateState(state, action, update);
    case actionsList.GET_REV_CARD_MATCHES:
      update = {
        revCardMatches: action.data,
      };
      return updateState(state, action, update);
    case actionsList.GET_CARD_ASSET_TEMPLATES:
      update = {
        cardAssetTemplates: action.data,
      };
      return updateState(state, action, update);
    case actionsList.SELECT_CARD_ASSET_TEMPLATE:
      update = {
        curCardAssetTemplate: action.data,
      };
      return updateState(state, action, update);
    case actionsList.CARD_TRANSLATE_FINISHED:
      update = {};
      return updateState(state, action, update);
    case actionsList.GET_CAT_EXT_REFS:
      update = {
        catExtRefs: action.data,
      };
      return updateState(state, action, update);
    case actionsList.GET_CAT_ALT_REFS:
      update = {
        catAltRefs: action.data,
        catAltRefsMetadata: action.metaData,
      };
      return updateState(state, action, update);
    case actionsList.GET_FIGURE_TYPES:
      update = {
        figureTypeList: action.data,
      };
      return updateState(state, action, update);
    case actionsList.UPD_DOC_PAGE_LOADING:
      update = {
        docPageLoading: action.data,
      };
      return updateState(state, action, update);
    case actionsList.UI__UPD_BREADCRUMBS_LEV:
      update = {};
      return updateState(state, action, update);
    case actionsList.SET_DL_REV_DOC:
      update = {
        dlRevDoc: action.data,
      };
      return updateState(state, action, update);
    case actionsList.GET_REV_DOC_CARDS_END:
      update = {};
      return updateState(state, action, update);

    case actionsList.GET_INTEGRATION_TYPES:
      update = {
        curEnvIntegrationTypes: action.data
      };
      return updateState(state, action, update);

    case actionsList.CONFIGURED_INTEGRATION_TYPE:
      update = {
        curEnvConfiguredIntegrationType: action.data
      };
      return updateState(state, action, update);

    case actionsList.GET_CATALOG_INTEGRATION_CONFIG:
      update = {
        curCatIntegrationConfig: action.data
      };
      return updateState(state, action, update);

    case actionsList.SAVE_PUBLISHED_CATALOG:
      update = {
        publishedCat: action.data
      };
      return updateState(state, action, update);
  }
  return state;
};
