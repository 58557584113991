<modal-popup
  [flexible]="true"
  [focusOnOpen]="true"
  [isOpen]="true"
  (onClose)="close()"
  title="{{ 'translate.cat.addAssetType' | translate }}"
>
  <div class="container">
    <div>
      <!--asset type-->
      <input-wrap
        label="{{ 'translate.general.assetType' | translate }}"
        cssClass="l-cell-spread"
      >
        <p-dropdown
          [options]="assetTypes"
          optionLabel="label"
          [(ngModel)]="assetType"
          class="l-cell-spread-x"
          [placeholder]="'translate.general.select' | translate"
          appendTo="body"
          baseZIndex="100"
        >
        </p-dropdown>
      </input-wrap>

      <div class="t-panel-cell t-buttonSet l-align-end l-mar-top">
        <button
          [disabled]="!assetType"
          (click)="add()"
          class="t-button-outline"
        >
          {{ "translate.general.add" | translate }}
        </button>
      </div>
    </div>
  </div>
</modal-popup>
