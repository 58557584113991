import { NgRedux } from '@redux/redux-compatibility.service';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ElementRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ValidationPipe } from 'src/app/shared/components/ui/validation.pipe';
import { DataElement } from 'src/app/shared/models/common';
import { UsersSearchParams } from 'src/app/shared/models/ui-user.model';
import {
  Env,
  User,
  UserEnv,
  UserSearchParams,
} from 'src/app/shared/models/user.model';
import { AppState } from 'src/app/shared/redux/store';
import { AuthService } from 'src/app/shared/services/auth.service';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss'],
})
export class EditUserComponent implements OnInit {
  @Input() user: User;
  @Output() onClose = new EventEmitter();
  @Output() onSave = new EventEmitter();
  loading: boolean;
  isNew: boolean;
  existingUserSw: boolean;
  usernameValidationStr: string;
  invalidUsernameStr = 'translate.mng.usernameValidation';
  checkUsernameSubscription: Subscription;
  saving: boolean;
  @ViewChild('editUserInputPassword', { static: false })
  editUserInputPassword: ElementRef;
  
  passwordValid: boolean = false;
  originalPassword: string = null;

  get modalTitle(): string {
    if (this.isNew) return 'translate.mng.addUser';
    else return 'translate.mng.editUser';
  }

  get usernameFilled(): boolean {
    return this.user.username != null && this.validateUsername();
  }

  get envList(): Env[] {
    return this.ngRedux.getState().envList;
  }

  get roleList(): DataElement[] {
    return this.ngRedux.getState().userRoles;
  }

  get timezoneList(): DataElement[] {
    return this.ngRedux.getState().timezoneList;
  }

  isDirty(): boolean {
    return this.user.isDirty();
  }

  constructor(
    private ngRedux: NgRedux<AppState>,
    private authService: AuthService,
    private sharedService: SharedService,
    private translateService: TranslateService,
    private validationPipe: ValidationPipe
  ) {}

  ngOnInit(): void {
    this.isNew = this.user.userId == null;
    if (this.isNew) {
      this.user.activeSw = true;
      if (!this.user.password) {
        /*let min = 10000000;
        let max = 99999999;
        this.user.password = (
          Math.floor(Math.random() * (max - min + 1)) + min
        ).toString();*/

        this.user.password = this.generatePassword(this.sharedService.passwordRequirements.length, [
          { chars: 'abcdefghijklmnopqrstuvwxyz', min: this.sharedService.passwordRequirements.lowerCaseChars }, // As least 1 lowercase letters
          { chars: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ', min: this.sharedService.passwordRequirements.upperCaseChars }, // At least 1 uppercase letters
          { chars: '0123456789', min: this.sharedService.passwordRequirements.numbers }, // At least 1 digits
          { chars: '!@#$&*?|%+-_./:;=()[]{}', min: this.sharedService.passwordRequirements.specialChars }, // At least 1 special chars
        ]);
      }
    }

    this.user.saveOriginal();
  }

  close() {
    if (this.isDirty()) {
      let validationStr = this.translateService.instant(
        'translate.cat.discardEditChanges'
      );

      this.sharedService.emitPopupchReceivedEvent({
        title: 'translate.validation.confirm',
        icon: 'exclamation-triangle',
        subTitle: validationStr,
        keep: false,
        approve: () => {
          this.discardChanges();
          this.onClose.emit();
        },
      });
    } else {
      this.onClose.emit();
    }
  }

  discardChanges() {
    this.user.restoreOriginal();
  }

  validateUsername(): boolean {
    if (!this.isNew) return true;
    else if (this.existingUserSw) {
      this.usernameValidationStr = 'translate.mng.usernameTaken';
      return false;
    } else if (!this.user.username) return false;
    else {
      if (
        !this.validationPipe.transform(
          this.user.username.toLowerCase(),
          'email'
        )
      ) {
        this.usernameValidationStr = this.invalidUsernameStr;
        return false;
      } else return true;
    }
  }

  public autoCompleteUsername(event: any) {
    if (this.checkUsernameSubscription) {
      this.checkUsernameSubscription.unsubscribe();
    }

    if (
      !this.user.username ||
      !this.validationPipe.transform(this.user.username, 'email')
    ) {
      this.existingUserSw = false;
      return;
    }

    let params = new UserSearchParams();
    params.exactUsername = this.user.username.toUpperCase();
    params.activeSw = true;
    this.checkUsernameSubscription = this.authService
      .getUsers(params, false)
      .subscribe((data) => {
        if (data && data.length > 0) {
          this.existingUserSw = true;
        } else {
          this.existingUserSw = false;
        }
      });
  }

  passwordValidationMsg: string = "";

  validatePassword(): boolean {
    // if (!this.isNew) return true;
    // return this.user.password && this.user.password.toString().length >= 8;
    
    if (this.originalPassword == null || this.originalPassword == undefined) {
      this.originalPassword = this.user.password;
    }

    let passwordChanged = this.originalPassword !== this.user.password;

    if (!this.isNew && !passwordChanged){
      this.passwordValidationMsg = '';
      return true;
    }
    
    this.passwordValidationMsg = this.sharedService.validatePassword(this.user.password);
    this.passwordValid = this.passwordValidationMsg.length == 0 ? true : false;
    return this.passwordValid;
  }

  disableUserClicked() {
    let validationStr = this.translateService.instant(
      'translate.mng.deleteUserValidation'
    );

    this.sharedService.emitPopupchReceivedEvent({
      title: 'translate.validation.confirm',
      icon: 'exclamation-triangle',
      subTitle: validationStr,
      keep: false,
      approve: () => {
        this.updateUser(false);
      },
    });
  }

  resetUser() {
    this.updateUser(true, true);
  }

  public allowSubmit(): boolean {
    let usernameValid = true;
    let passwordValid = this.validatePassword();
    let envValid = true;

    if (this.isNew) {
      usernameValid = this.validateUsername();
    }

    if (this.user.userEnvList.length == 0) {
      envValid = false;
    } else {
      this.user.userEnvList.forEach((e) => {
        if (!e.env || !e.role) {
          envValid = false;
        } else if (e.env) {
          let duplicateCnt = this.user.userEnvList.filter(
            (d) => d.env && d.env.id == e.env.id
          ).length;
          if (duplicateCnt > 1) envValid = false;
        }
      });
    }

    return usernameValid && passwordValid && envValid;
  }

  public updateUser(activeSw: boolean = true, syncCongnito: boolean = false) {
    this.saving = true;
    this.user.activeSw = activeSw;
    let toSyncCognito = false;

    let original = JSON.parse(this.user.original) as User;
    if (
      this.isNew ||
      this.user.password != original.password ||
      this.user.activeSw != original.activeSw ||
      syncCongnito
    ) {
      toSyncCognito = true;
    }

    this.authService.updateUser(this.user, syncCongnito).subscribe(
      (userId) => {
        this.user.userId = userId;
        this.saving = false;
        if (toSyncCognito) this.syncCognitoUsers();
        if (this.user.userId == this.authService.currentUser.userId) {
          this.authService.getUserDetails().subscribe();
        }
        this.onSave.emit();
      },
      () => (this.saving = false)
    );
  }

  syncCognitoUsers() {
    this.authService.syncCongitoUsers(this.user).subscribe(
      (data) => {
        this.onSave.emit();
      },
      (err) => {
        this.onSave.emit();
      }
    );
  }

  addUserEnv() {
    let env = new UserEnv();
    this.user.userEnvList.unshift(env);
  }

  deleteUserEnv(row: UserEnv) {
    this.user.userEnvList.splice(this.user.userEnvList.indexOf(row), 1);
  }

  generatePassword(length, rules): string {
    var allChars = '',
      allMin = 0;
    rules.forEach((rule) => {
      allChars += rule.chars;
      allMin += rule.min;
    });
    if (length < allMin) {
      length = allMin;
    }
    rules.push({ chars: allChars, min: length - allMin });

    var pswd = '';
    rules.forEach((rule) => {
      if (rule.min > 0) {
        pswd += this.shuffleString(rule.chars, rule.min);
      }
    });

    return this.shuffleString(pswd, length);
  }

  passwordInputType: 'text' | 'password';
  showPassword($event: MouseEvent) {
    $event.preventDefault();
    const passwordInput = this.editUserInputPassword.nativeElement;
    passwordInput.type =
      passwordInput.type === 'password' ? 'text' : 'password';
    this.passwordInputType = passwordInput.type;
  }

  shuffleString(str, maxlength): string {
    var shuffledString = str
      .split('')
      .sort(() => {
        return 0.5 - Math.random();
      })
      .join('');
    if (maxlength > 0) {
      shuffledString = shuffledString.substr(0, maxlength);
    }
    return shuffledString;
  }
}
