import { NgRedux } from '@redux/redux-compatibility.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Card, CardAssetTemplate } from 'src/app/shared/models/cat.model';
import { ProcessRunStatusEnum } from 'src/app/shared/models/common';
import { actionsList, AppState } from 'src/app/shared/redux/store';
import { AuthService } from 'src/app/shared/services/auth.service';
import { CatService } from 'src/app/shared/services/cat.service';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'app-card-asset-template-details',
  templateUrl: './card-asset-template-details.component.html',
  styleUrls: ['./card-asset-template-details.component.scss'],
})
export class CardAssetTemplateDetailsComponent implements OnInit {
  loading: boolean;
  running: boolean;
  processStatusTimer: NodeJS.Timeout;
  getProcessStatusIntervalSeconds: number = 3;
  editOrderOpenSw: boolean;

  get data(): CardAssetTemplate {
    return this.ngRedux.getState().curCardAssetTemplate;
  }

  constructor(
    private ngRedux: NgRedux<AppState>,
    private catService: CatService,
    private router: Router,
    private sharedService: SharedService,
    private authService: AuthService,
    private translateService: TranslateService
  ) {}

  goBack() {
    this.router.navigate([
      this.sharedService.routeEnum.URL_CATALOG_CARD_ASSET_TEMPLATES,
    ]);

    this.ngRedux.dispatch({
      type: actionsList.SELECT_CARD_ASSET_TEMPLATE,
      data: null,
    });
  }

  ngOnInit(): void {
    this.loadCurData();
  }

  loadCurData() {
    this.catService.getCardAssetTemplates(false, this.data.id).subscribe(
      (data) => {
        this.ngRedux.dispatch({
          type: actionsList.SELECT_CARD_ASSET_TEMPLATE,
          data: data[0],
        });
        this.loading = false;
      },
      (err) => {
        this.loading = false;
      }
    );
  }

  export() {
    if (!this.data.url) {
      return;
    }
    var element = document.createElement('a');
    element.href = this.data.url;
    element.setAttribute('target', '_blank');
    element.setAttribute(
      'download',
      'block_template_' + this.data.id.toString()
    );
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

  viewCard(card: Card) {
    this.ngRedux.dispatch({
      type: actionsList.SELECT_CAT_CARD,
      data: card,
    });

    this.router.navigate([this.sharedService.routeEnum.URL_CATALOG_CARD]);
  }

  removeCard(card: Card) {
    this.data.cards.splice(this.data.cards.indexOf(card), 1);
    this.catService.updateCardAssetTemplate(this.data).subscribe((data) => {});
  }

  preview() {
    var element = document.createElement('a');
    element.href = this.data.previewUrl;
    element.setAttribute('target', '_blank');
    element.setAttribute(
      'download',
      'block_template_preview_' + this.data.id.toString()
    );
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

  extract() {
    this.running = true;
    this.catService
      .createCardTemplateExtractRun(
        this.data.id,
        this.ngRedux.getState().curCat.catVersionId
      )
      .subscribe(
        (runNum) => {
          this.catService.startProcessRun(runNum).subscribe(
            (data) => {},
            (err) => {
              this.runError(err);
            }
          );

          this.processStatusTimer = setInterval(() => {
            this.authService.getProcessStatus(runNum).subscribe(
              (data) => {
                if (data.status == ProcessRunStatusEnum.FINISHED) {
                  clearInterval(this.processStatusTimer);
                  this.running = false;
                  this.loadCurData();
                } else if (data.status == ProcessRunStatusEnum.FAILED) {
                  this.runError(
                    this.translateService.instant('translate.cat.processFailed')
                  );
                }
              },
              (err) => {
                this.runError(err);
              }
            );
          }, this.getProcessStatusIntervalSeconds * 1000);
        },
        (err) => {
          this.runError(err);
        }
      );
  }

  runError(err) {
    clearInterval(this.processStatusTimer);
    this.running = false;
    this.sharedService.alertError(err);
  }

  extractEnabled() {
    return this.data.cards.find((c) => c.cardDocs.find((d) => d.inddDocS3Key));
  }
}
