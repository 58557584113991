import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'referenceIssueCheck'
})
export class ReferenceIssueCheckPipe implements PipeTransform {

  transform(wrappedWord: any, index: number, isReferenceIssueOnIndex: Function): boolean {
    return wrappedWord.referenceIssues?.length > 0 && isReferenceIssueOnIndex(wrappedWord.referenceIssues, wrappedWord.word.chars, index);
  }
}