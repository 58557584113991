import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { CardAssetTemplate, Catalog } from 'src/app/shared/models/cat.model';
import { NgRedux } from '@redux/redux-compatibility.service';
import { AppState } from 'src/app/shared/redux/store';
import { CatService } from 'src/app/shared/services/cat.service';
import { UploadService } from 'src/app/shared/services/upload.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { DataElement } from 'src/app/shared/models/common';

@Component({
  selector: 'create-card-asset-template',
  templateUrl: './create-card-asset-template.component.html',
  styleUrls: ['./create-card-asset-template.component.scss'],
})
export class CreateCardAssetTemplateComponent implements OnInit {
  @Output() onClose = new EventEmitter();
  @Output() onCreate = new EventEmitter();
  @Input() data: CardAssetTemplate;

  saving: boolean;

  get catalog(): Catalog {
    return this.ngRedux.getState().curCat;
  }

  get assetTypes(): DataElement[] {
    return this.catalog.catAssetTypeList;
  }

  get enableSubmit(): boolean {
    return this.data.name != null && this.data.assetType != null;
  }

  constructor(
    private ngRedux: NgRedux<AppState>,
    private catService: CatService
  ) {}

  ngOnInit(): void {}

  close() {
    this.onClose.emit();
  }

  save() {
    this.saving = true;
    this.catService.updateCardAssetTemplate(this.data).subscribe(
      (data) => {
        this.saving = false;
        this.onCreate.emit();
      },
      (err) => (this.saving = false)
    );
  }
}
