<section class="page-header">
  <div class="title-container">
    <h1>{{ "translate.lc.labelChange" | translate }}</h1>
    <button class="t-button" (click)="add()">
      {{ "translate.lc.create" | translate }}
    </button>
  </div>
</section>

<loader *ngIf="loading"></loader>

<div *ngIf="!data && !loading" role="alert" class="c-alert t-alert-info">
  {{ "translate.alert.noResults" | translate }}
</div>

<ui-table
  [isWrapped]="false"
  [data]="data"
  [numberOfRows]="20"
  [totalRecords]="metadata.totalRecords"
  (requestUpdate)="onPagination($event)"
  [displayFilter]="['lcId', 'title']"
  [templates]="[
    {
      title: 'translate.general.product',
      template: products
    },
    {
      title: 'translate.general.catalog',
      template: catalog
    },
    {
      title: 'translate.general.crDate',
      template: crDate
    },
    {
      title: 'translate.lc.bcDocs',
      template: bcDocs
    },
    {
      title: 'translate.lc.acDocs',
      template: acDocs
    },
    {
      title: 'translate.general.actions',
      template: actions
    }
  ]"
  [topEnd]="filterArea"
></ui-table>

<ng-template #products let-row="row">
  {{ row.product.text }}
</ng-template>

<ng-template #catalog let-row="row">
  {{ row.catalog.catDescr }}
</ng-template>

<ng-template #crDate let-row="row">
  {{ row.crDate }}
</ng-template>

<ng-template #bcDocs let-row="row">
  {{ row.bcDocsCnt }}
</ng-template>

<ng-template #acDocs let-row="row">
  {{ row.acDocsCnt }}
</ng-template>

<ng-template #actions let-row="row">
  <a (click)="view(row)">{{ "translate.general.view" | translate }}</a>
  |
  <a (click)="edit(row)">{{ "translate.general.edit" | translate }}</a>
</ng-template>

<ng-template #filterArea>
  <div class="filter-container">
    <!--product-->
    <input-wrap [loading]="productList && productList.loading">
      <p-dropdown
        [options]="productList"
        optionLabel="label"
        [(ngModel)]="filterProduct"
        [resetFilterOnHide]="true"
        [filterPlaceholder]="'translate.general.search' | translate"
        [placeholder]="'translate.general.productDescr' | translate"
        [filter]="true"
        (onChange)="onFieldValueChanged($event)"
      >
      </p-dropdown>
    </input-wrap>

    <a *ngIf="displayClearFilter" (click)="clearFilter()">{{
      "translate.general.clear" | translate
    }}</a>
  </div>
</ng-template>

<create-lc
  *ngIf="displayCreateLC"
  [lc]="editLC"
  (onClose)="displayCreateLC = false"
  (onCreate)="onLCCreated()"
></create-lc>
