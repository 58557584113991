<div
  class="container"
  [class.type-img]="instr.type.value == 'IMG'"
  (mouseenter)="displayEditBtn = !editMode"
  (mouseleave)="displayEditBtn = false"
>
  <div *ngIf="instr.type.value == 'TXT'">
    <ng-container *ngTemplateOutlet="txtTemplate"></ng-container>
  </div>

  <div *ngIf="instr.type.value == 'IMG'">
    <ng-container *ngTemplateOutlet="imgTemplate"></ng-container>
  </div>

  <div class="footer-container" *ngIf="editable">
    <p-selectButton
      class="instr-type-select"
      *ngIf="!instr.id"
      [options]="instrTypeOptions"
      [(ngModel)]="instr.type"
      optionLabel="icon"
    >
      <ng-template let-item>
        <i [class]="item.icon"></i>
      </ng-template>
    </p-selectButton>

    <div class="icons">
      <!--edit-->
      <i
        [style.display]="
          displayEditBtn && instr.type.value == 'TXT' && !editMode
            ? 'block'
            : 'none'
        "
        (click)="editMode = true"
        class="fas fa-edit icon-edit"
      ></i>

      <!--cancel edit-->
      <i
        *ngIf="editMode && instr.id"
        (click)="discardChanges()"
        class="fas fa-undo icon-edit"
      ></i>

      <!--delete-->
      <i
        [style.display]="displayEditBtn || editMode ? 'block' : 'none'"
        (click)="onDeleteClicked()"
        class="fas fa-trash icon-edit"
      ></i>
    </div>
  </div>
</div>

<ng-template #txtTemplate>
  <div class="w-full">
    <p *ngIf="!editMode">{{ instr.text }}</p>

    <textarea
      *ngIf="editMode"
      class="edit-area"
      pInputTextarea
      [(ngModel)]="instr.text"
      autoResize="true"
    ></textarea>
  </div>
</ng-template>

<ng-template #imgTemplate>
  <!--upload-->

  <div *ngIf="!instr.imgUrl" class="upload-container">
    <input-wrap
      *ngIf="!isUploading && !imgFileName"
      [customValidation]="isValidFile"
      [customValidationWarn]="
        'translate.validation.instrImgFileTypes' | translate
      "
      [cssClass]="'l-ta-center input-wrap-hasFileSpread'"
    >
      <input
        id="uploadFileInput"
        (change)="uploadFile($event)"
        class="t-fileInput-spread"
        type="file"
        accept=".pdf,.jpg,.jpeg,.png"
      />

      <button class="t-button">
        {{ "translate.general.selectFile" | translate }}
      </button>
    </input-wrap>

    <spinner *ngIf="isUploading" class="l-ta-center"></spinner>

    <p *ngIf="imgFileName">
      <span>{{ fileName }}</span>
      <a (click)="removeFile()">
        ({{ "translate.general.remove" | translate }})</a
      >
    </p>
  </div>

  <!--img preview-->

  <div *ngIf="instr.imgUrl">
    <a (click)="dispayImgPreview = true">
      <!-- <div
        class="img-preview"
        [style.background-image]="'url(' + instr.imgUrl + ')'"
      ></div> -->
      <async-img [className]="'img-preview'" [url]="instr.imgUrl"></async-img>
    </a>
  </div>
</ng-template>

<modal-popup
  *ngIf="dispayImgPreview"
  [flexible]="true"
  [hideHeader]="false"
  [isOpen]="dispayImgPreview"
  (onClose)="dispayImgPreview = false"
>
  <img class="img-preview-large" [src]="instr.imgUrl" />
</modal-popup>
