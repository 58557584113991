<div *ngIf="curCat">
  <loader *ngIf="loading"></loader>

  <ui-table
    #imagesTbl
    *ngIf="displayImages"
    [isWrapped]="false"
    [data]="displayImages"
    [numberOfRows]="rowsPerPage"
    [totalRecords]="entriesMetaData.totalRecords"
    [selectedRow]="selectedImage"
    [showPaginator]="true"
    (requestUpdate)="onPagination($event)"
    [templates]="[
      {
        title: 'translate.general.imageId',
        template: imageId
      },
      {
        title: 'translate.general.image',
        template: image
      },
      {
        title: 'translate.general.figureTypeDescr',
        template: figureTypeDescr
      },
      {
        title: 'translate.general.figureSubTypeDescr',
        template: figureSubTypeDescr
      },
      {
        title: 'translate.general.typeDescr',
        template: type
      },
      {
        title: 'translate.cat.addInstr',
        template: addInstr,
        colClass: 'l-ta-center'
      },
      {
        title: 'translate.general.actions',
        template: actions,
        permission: allowEdit
      }
    ]"
    [more]="expandRow"
  ></ui-table>

  <ng-template #image let-row="row">
    <!-- <img class="ind-img" [src]="row.imgUrl" /> -->
    <async-img
      *ngIf="row.imgS3Key"
      [className]="'ind-img'"
      [url]="row.imgUrl"
    ></async-img>
  </ng-template>

  <ng-template #imageId let-row="row">
    {{ row.imageId }}
  </ng-template>

  <ng-template #figureTypeDescr let-row="row">
    {{ row.figureTypeDescr }}
  </ng-template>

  <ng-template #figureSubTypeDescr let-row="row">
    {{ row.figureSubType?.text }}
  </ng-template>

  <ng-template #type let-row="row">
    <div class="cat-phrase-type">
      {{ row.typeDescr }}
      <span class="sub-type">&middot; {{ row.subTypeDescr }}</span>
    </div>
  </ng-template>

  <ng-template #addInstr let-row="row">
    <i *ngIf="row.addInstr.length > 0" class="fas fa-check"></i>
  </ng-template>

  <ng-template #actions let-row="row">
    <div *ngIf="allowEdit">
      <a (click)="edit($event, row)">{{
        "translate.general.edit" | translate
      }}</a>
      <span class="action-divider">|</span>
      <a (click)="delete($event, row)">{{
        "translate.general.delete" | translate
      }}</a>
    </div>
  </ng-template>

  <ng-template #expandRow let-row="row">
    <tabs>
      <!--documents-->
      <tab-single
        tabTitle="{{ 'translate.cat.catDocs' | translate }}"
        [tabId]="tabIdList.CAT__ENTRIES__DOC"
      >
        <cat-entry-docs
          class="t-small"
          *ngIf="row.docs.length > 0"
          [docs]="row.docs"
        ></cat-entry-docs>
      </tab-single>

      <!--instructions-->
      <tab-single
        *ngIf="row.addInstr.length > 0"
        tabTitle="{{ 'translate.cat.addInstr' | translate }}"
        [tabId]="tabIdList.CAT__ENTRIES__INSTR"
      >
        <cat-add-instr
          class="t-small"
          *ngFor="let instr of row.addInstr"
          [instr]="instr"
          [editable]="false"
        ></cat-add-instr>
      </tab-single>
    </tabs>
  </ng-template>
</div>

<cat-doc-phrase-details
  *ngIf="imageDetailsOpenSw"
  (onClose)="imageDetailsOpenSw = false"
  (onDelete)="onEntryDeleted()"
  (onSave)="onEntrySaved()"
  (onEdit)="onEntryEdited()"
  [mode]="'cat'"
  [displayEntryDocs]="true"
  [editable]="allowEdit"
  [stateTag]="'PRIME'"
></cat-doc-phrase-details>
