import { NgRedux } from '@redux/redux-compatibility.service';
import { Component, OnInit } from '@angular/core';
import { Catalog } from 'src/app/shared/models/cat.model';
import { DataElement } from 'src/app/shared/models/common';
import { AppState } from 'src/app/shared/redux/store';
import { GrammarSpellingCheckService } from 'src/app/shared/services/grammar-spelling-check.service';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'spelling-check-ignored',
  templateUrl: './spelling-check-ignored.component.html',
  styleUrls: ['./spelling-check-ignored.component.scss']
})
export class SpellingCheckIgnoredComponent implements OnInit {
  spellingCheckIgnored: DataElement[];
  loading: boolean;
  addSpellingCheckIgnoredOpenSw: boolean;

  constructor(
    private ngRedux: NgRedux<AppState>,
    private grammarSpellingCheckService: GrammarSpellingCheckService,
    private sharedService: SharedService,
  ) {}

  get cat(): Catalog {
    return this.ngRedux.getState().curCat;
  }

  ngOnInit(): void {
    this.loading = true;
    this.grammarSpellingCheckService.getCatSpellingCheckIgnoredWords(this.cat.catId).subscribe(
      (data) => {
        this.spellingCheckIgnored = data;
        this.loading = false;
      },
      (err) => {
        this.loading = false;
        this.sharedService.alertError(err);
      }
    );
  }

  delete(ignoredWord: DataElement) {
    this.loading = true;
    this.grammarSpellingCheckService.deleteCatSpellingCheckIgnoredWord(this.cat.catId, ignoredWord.id).subscribe(
      () => {
        this.spellingCheckIgnored = this.spellingCheckIgnored.filter(word => word.id != ignoredWord.id);
        this.loading = false;
      },
      err => {
        this.loading = false;
        this.sharedService.alertError(err);
      }
    )
  }

  add(word: string) {
    this.addSpellingCheckIgnoredOpenSw = false;
    this.loading = true;

    this.grammarSpellingCheckService.addCatSpellingCheckIgnoredWord(this.cat.catId, word).subscribe(
      newWord => {
        if (!this.spellingCheckIgnored.find(w => w.id === newWord.id)) {
          this.spellingCheckIgnored.push(newWord);
        }
        this.loading = false;
      },
      err => {
        this.loading = false;
        this.sharedService.alertError(err);
      }
    )
  }

}
