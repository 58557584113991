import { NgRedux } from '@redux/redux-compatibility.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AltRef } from 'src/app/shared/models/cat.model';
import { ReferencePhrase } from 'src/app/shared/models/common';
import { AppState } from 'src/app/shared/redux/store';
import { CatService } from 'src/app/shared/services/cat.service';

@Component({
  selector: 'edit-alt-ref',
  templateUrl: './edit-alt-ref.component.html',
  styleUrls: ['./edit-alt-ref.component.scss'],
})
export class EditAltRefComponent implements OnInit {
  @Output() onClose = new EventEmitter();
  @Output() onCreate = new EventEmitter();
  @Input() data: AltRef;

  saving: boolean;

  get enableSubmit(): boolean {
    return this.data.txt != null;
  }

  constructor(
    private ngRedux: NgRedux<AppState>,
    private catService: CatService
  ) {}

  ngOnInit(): void {}

  close() {
    this.onClose.emit();
  }

  save() {
    this.saving = true;
    this.catService
      .updCatAltRef(
        this.data,
        this.data.phrases.map((p) => p.refPhraseId)
      )
      .subscribe(
        (data) => {
          this.saving = false;
          this.onCreate.emit();
        },
        (err) => (this.saving = false)
      );
  }

  deleteRefPhrase(phrase: ReferencePhrase) {
    this.data.phrases.splice(this.data.phrases.indexOf(phrase));
  }
}
