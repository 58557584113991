import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { Config } from 'src/app/config';
import { Subject } from 'rxjs';
import { ServiceOptions } from '../models/service-options';
import { Popup } from '../models/popup';
import { ServiceMetaData } from '../models/service-meta.model';
import { RouteEnum } from '../models/routes.model';
import { DataElement } from '../models/common';
import { TranslateService } from '@ngx-translate/core';
import { RevStatus } from '../models/rev.model';
import { CatPhraseStatusEnum } from '../models/cat.model';
import { NgRedux } from '@redux/redux-compatibility.service';
import { AppState } from '../redux/store';

const CryptoJS = require('crypto-js');

interface PasswordValidationRequirements {
  length: number,
  upperCaseChars: number,
  lowerCaseChars: number,
  numbers: number,
  specialChars: number
}

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  lang: string;
  overwriteUserLang: string;
  route: string;
  activeTab: string;
  static appDateFormat: string = 'MM/dd/yyyy h:mm a';
  routeEnum: RouteEnum = new RouteEnum();
  capturedUserPassword: string;
  static loadingImg = 'assets\\img\\Spin-1.8s-70px.svg';
  static presignedUrlQueryParams: string[] = Array.from(['X-Amz-Date', 'X-Amz-Expires', 'X-Amz-Signature']);

  passwordRequirements: PasswordValidationRequirements = {
    length: 14,
    upperCaseChars: 1,
    lowerCaseChars: 1,
    numbers: 1,
    specialChars: 1
  }

  //config params
  static revDocFileTypes: string;
  static instrImgFileTypes: string = 'png,jpg,jpeg';
  static versionCode: string = '2.3'; //TODO!
  static errorLogLamdaUrl: string;
  static dbTZ: string;
  static transLang: string[] = [];
  static cognitoUserPoolId: string;
  static cognitoClientId: string;
  static cognitoRegion: string;
  static hideRememberMeInLogin: boolean;
  static enableImportClaimsReport: boolean;
  static showIssuesOnFullMatch: boolean;

  static calLocale = {
    firstDayOfWeek: 0,
    dayNames: ['ראשון', 'שני', 'שלישי', 'רביעי', 'חמישי', 'שישי', 'שבת'],
    dayNamesShort: ['א', 'ב', 'ג', 'ד', 'ה', 'ו', 'ש'],
    dayNamesMin: ['א', 'ב', 'ג', 'ד', 'ה', 'ו', 'ש'],
    monthNames: [
      'ינואר',
      'פברואר',
      'מרץ',
      'אפריל',
      'מאי',
      'יוני',
      'יולי',
      'אוגוסט',
      'ספטמבר',
      'אוקטובר',
      'נובמבר',
      'דצמבר',
    ],
    monthNamesShort: [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ],
    today: 'היום',
    clear: 'נקה',
  };

  constructor(
    private translateService: TranslateService,
    private ngRedux: NgRedux<AppState>
  ) {
    this.cookiesGen();
    SharedService.revDocFileTypes = Config.assetConfigData['revDocFileTypes'];
    SharedService.errorLogLamdaUrl = Config.assetConfigData['errorLogLamdaUrl'];
    SharedService.dbTZ = Config.assetConfigData['dbTZ'];
    SharedService.transLang = Config.assetConfigData['transLang'];
    SharedService.cognitoUserPoolId =
      Config.assetConfigData['cognitoUserPoolId'];
    SharedService.cognitoClientId = Config.assetConfigData['cognitoClientId'];
    SharedService.cognitoRegion = Config.assetConfigData['cognitoRegion'];
    SharedService.hideRememberMeInLogin = this.resolveBooleanValue(Config.assetConfigData['hideRememberMeInLogin']);
    SharedService.enableImportClaimsReport = this.resolveBooleanValue(Config.assetConfigData['enableImportClaimsReport'], false);
    SharedService.showIssuesOnFullMatch = this.resolveBooleanValue(Config.assetConfigData['showIssuesOnFullMatch'], false);
  }

  resolveBooleanValue(value:any, defaultValue: boolean = false) {
    let ret: boolean = defaultValue;
    if(value) {
      if(typeof value === "boolean") {
        ret = value;
      } else if(value instanceof String) {
        let retStr: String = value;
        ret = retStr.toBoolean();
      }
    }
    return ret;
  }

  clearLastCatCookies() {
    this.removeCookie('cat_id');
    this.removeCookie('cat_version_id');
  }

  getRevRuleUpdStatusOptions(): DataElement[] {
    let options: DataElement[] = [];

    let markForChangesDescr = this.translateService.instant(
      'translate.rev.markForChanges'
    );
    let newClaimDescr = this.translateService.instant('translate.rev.newClaim');
    let claimVariantDescr = this.translateService.instant('translate.rev.claimVariant');
    let stetDescr = this.translateService.instant('translate.rev.stet');
    if (this.ngRedux.getState().revRuleStatusList) {
      let serviceStatusDescr = this.ngRedux
        .getState()
        .revRuleStatusList.find((s) => s.id == RevStatus.MARKED_FOR_CHANGES);
      if (serviceStatusDescr) {
        markForChangesDescr = serviceStatusDescr.text;
      }

      serviceStatusDescr = this.ngRedux
        .getState()
        .revRuleStatusList.find((s) => s.id == RevStatus.NEW_CLAIM);
      if (serviceStatusDescr) {
        newClaimDescr = serviceStatusDescr.text;
      }

      serviceStatusDescr = this.ngRedux
      .getState()
      .revRuleStatusList.find((s) => s.id == RevStatus.CLAIM_VARIANT);
      if (serviceStatusDescr) {
        claimVariantDescr = serviceStatusDescr.text;
      }

      serviceStatusDescr = this.ngRedux
        .getState()
        .revRuleStatusList.find((s) => s.id == RevStatus.STET);
      if (serviceStatusDescr) {
        stetDescr = serviceStatusDescr.text;
      }
    }

    options.push(
      new DataElement(RevStatus.MARKED_FOR_CHANGES, markForChangesDescr)
    );
    options.push(new DataElement(RevStatus.NEW_CLAIM, newClaimDescr));
    options.push(new DataElement(RevStatus.CLAIM_VARIANT, claimVariantDescr));
    options.push(new DataElement(RevStatus.STET, stetDescr));

    return options;
  }

  getCatPhraseUpdStatusOptions(): DataElement[] {
    let options: DataElement[] = [];
    options.push(
      new DataElement(
        CatPhraseStatusEnum.APPROVE,
        this.translateService.instant('translate.cat.approve')
      )
    );
    options.push(
      new DataElement(
        CatPhraseStatusEnum.APPROVE_VAR,
        this.translateService.instant('translate.cat.approveVariant')
      )
    );
    options.push(
      new DataElement(
        CatPhraseStatusEnum.REJECT,
        this.translateService.instant('translate.cat.reject')
      )
    );

    return options;
  }

  computeStyle(el: HTMLElement, style: string, toInt: boolean) {
    if (!el) return;
    if (toInt) {
      return parseInt(
        window.getComputedStyle(el, null).getPropertyValue(style)
      );
    } else {
      return window.getComputedStyle(el, null).getPropertyValue(style);
    }
  }

  // --- cookies ---

  cookies = {};
  cookiesGen() {
    let cookie: any = document.cookie;
    if (!cookie) return '';
    cookie = cookie.split('; ');
    this.cookies = {};
    cookie = cookie.forEach((item) => {
      item = item.split('=');
      this.cookies[item[0]] = item[1];
    });
  }

  getCookie(key: string, secure: boolean = false) {
    this.cookiesGen();
    return this.cookies[key] || '';
  }

  putCookie(key: string, value: string, secure: boolean = false) {
    let exp = new Date();
    exp.setDate(exp.getDate() + 30);
    this.removeCookie(key);

    let set = key + '=' + value;
    document.cookie = set + '; Path=/;expires=' + exp.toString();
  }

  removeCookie(key: string) {
    document.cookie = key + '=; Path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT;';
  }

  // events

  popup = new Subject<Popup>();
  popup$ = this.popup.asObservable();
  emitPopupchReceivedEvent(popup: Popup) {
    this.popup.next(popup);
  }

  //menu hide /reveal
  private menuBtnClicked = new Subject<boolean>();
  menuBtnClicked$ = this.menuBtnClicked.asObservable();
  emitMenuBtnClickedEvent(cond: boolean) {
    this.menuBtnClicked.next(cond);
  }

  private isSidebarOpen = new Subject<boolean>();
  isSidebarOpen$ = this.isSidebarOpen.asObservable();
  emitIsSidebarOpenEvent(cond: boolean) {
    this.isSidebarOpen.next(cond);
  }

  // alerts

  alertError(
    message: any,
    title: string = '',
    subtitle: string = '',
    serviceName?: string
  ) {
    let service = serviceName ? ' | Called by ' + serviceName : '';
    this.emitPopupchReceivedEvent({
      title: title || 'translate.alert.wrongTitle',
      subTitle: subtitle || 'translate.alert.wrongSubTitle',
      message:
        serviceName != null
          ? (message || '') + ' (' + serviceName + ')'
          : message,
      icon: 'exclamation-triangle',
    });
  }

  alertSuccess(message: string, title: string = '') {
    this.emitPopupchReceivedEvent({
      title: title,
      subTitle: [message],
      icon: 'check',
    });
  }

  alertWarning(err: string) {
    this.emitPopupchReceivedEvent({
      subTitle: err,
    });
  }

  alertInfo(msg: string) {
    this.emitPopupchReceivedEvent({
      title: 'translate.alert.infoTitle',
      subTitle: msg,
    });
  }

  // loading
  private generalLoader = new Subject<boolean>();
  generalLoader$ = this.generalLoader.asObservable();
  emitGeneralLoaderEvent(cond: boolean) {
    this.generalLoader.next(cond);
  }

  isMobile() {
    let check = false;
    (function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne( (c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor);
    return check;
  }

  isShowRememberMeInLogin(): boolean {
    return !SharedService.hideRememberMeInLogin;
  }

  isImportClaimsReportEnabled(): boolean {
    return SharedService.enableImportClaimsReport;
  }

  isShowIssuesOnFullMatchEnabled(): boolean {
    return SharedService.showIssuesOnFullMatch;
  }
  
  static isPresignedUrl(url: string): boolean {
    return url && SharedService.presignedUrlQueryParams.some(param => url.indexOf(param) !== -1);
  } 
  
  validatePassword(password: string): string {
    let regexUpperCase = new RegExp(`(?=.*?[A-Z]).{${this.passwordRequirements.upperCaseChars},}`);
    let regexLowercase = new RegExp(`(?=.*?[a-z]).{${this.passwordRequirements.lowerCaseChars},}`);
    let regexNumber = new RegExp(`(?=.*?[0-9]).{${this.passwordRequirements.numbers},}`);
    let regexSpecialChar = new RegExp(`(?=.*?[#?!@$%^&*-]).{${this.passwordRequirements.specialChars},}`);

    let msgs = [];

    if (!password || !(password.length >= this.passwordRequirements.length)) {
      msgs.push(this.translateService.instant('translate.alert.passwordRequirementsLength', {cnt: this.passwordRequirements.length}))
    }

    if (!regexUpperCase.test(password)) {
      msgs.push(this.translateService.instant('translate.alert.passwordRequirementsUppercaseChars', {cnt: this.passwordRequirements.upperCaseChars}))
    }

    if (!regexLowercase.test(password)) {
      msgs.push(this.translateService.instant('translate.alert.passwordRequirementsLowercaseChars', {cnt: this.passwordRequirements.lowerCaseChars}))
    }

    if (!regexNumber.test(password)) {
      msgs.push(this.translateService.instant('translate.alert.passwordRequirementsNumbers', {cnt: this.passwordRequirements.numbers}))
    }

    if (!regexSpecialChar.test(password)) {
      msgs.push(this.translateService.instant('translate.alert.passwordRequirementsSpecialChars', {cnt: this.passwordRequirements.specialChars}))
    }

    let validationMsg = '';

    if (msgs.length > 0) {
      validationMsg = this.translateService.instant('translate.alert.passwordRequirementsMissingDescription') + msgs.join(", ")
    }

    return validationMsg;
  }

  passwordRequirementsMessage(): string {
    const requiredMsg = this.translateService.instant('translate.alert.passwordRequirementsRequiredDescription');
    const lengthMsg = this.translateService.instant('translate.alert.passwordRequirementsLength', {cnt: this.passwordRequirements.length});
    const uppercaseCharsMsg = this.translateService.instant('translate.alert.passwordRequirementsUppercaseChars', {cnt: this.passwordRequirements.upperCaseChars})
    const lowercaseCharsMsg = this.translateService.instant('translate.alert.passwordRequirementsLowercaseChars', {cnt: this.passwordRequirements.lowerCaseChars})
    const numbersMsg = this.translateService.instant('translate.alert.passwordRequirementsNumbers', {cnt: this.passwordRequirements.numbers})
    const specialCharsMsg = this.translateService.instant('translate.alert.passwordRequirementsSpecialChars', {cnt: this.passwordRequirements.specialChars});

    return `${requiredMsg} ${lengthMsg}, ${uppercaseCharsMsg}, ${lowercaseCharsMsg}, ${numbersMsg}, ${specialCharsMsg}`;
  }
}

// custom enhancements

declare global {
  interface Array<T> {
    loading: boolean;
    sorry: boolean;
    srp_shuffle: Function;
    srp_merge: Function;
    srp_allTrue: Function;
    srp_someTrue: Function;
    srp_sortByKey: Function;
    serviceMetaData: ServiceMetaData;
  }
  interface Date {
    to_DD_MM_YYYY: Function;
    to_DD_MM_YYYY_HH_MM: Function;
    to_HH_MM_SS: Function;
    srp_getWeekNumber: Function;
    srp_addDays: Function;
    srp_today: Function;
    srp_todayTs: Function;
  }
  interface String {
    DD_MM_YYYY_toTime: any;
    SRP_trimWord: Function;
    toBoolean(): boolean;
  }
}

Array.prototype.loading = false;
Array.prototype.sorry = false;
Array.prototype.serviceMetaData;
Array.prototype.srp_shuffle = function () {
  let randomIndex: number, i: number, arrayItemStore: any;
  for (i = this.length - 1; i > 0; i--) {
    randomIndex = Math.floor(Math.random() * (i + 1));
    arrayItemStore = this[i];
    this[i] = this[randomIndex];
    this[randomIndex] = arrayItemStore;
  }
  return this;
};
Array.prototype.srp_merge = function (filterBy?: string) {
  const ar = this;
  const map = new Map();
  let i = -1;
  const l = ar.length - 1;

  if (filterBy) {
    while (i++ < l) map.set(ar[i][filterBy], ar[i]);
  } else {
    while (i++ < l) map.set(JSON.stringify(ar[i]), ar[i]);
  }

  return [...(map.values() as any)];
};

Array.prototype.srp_allTrue = function () {
  return this.length && this.reduce((a, b) => !!a && !!b);
};
Array.prototype.srp_someTrue = function () {
  return this.length && this.reduce((a, b) => !!a || !!b);
};
Array.prototype.srp_sortByKey = function (
  key: string,
  desc: boolean = false,
  customRule?: (string: string) => any
) {
  return this.sort((a, b) => {
    let _a, _b;
    if (typeof customRule === 'function') {
      _a = customRule(a[key]);
      _b = customRule(b[key]);
    } else {
      _a = typeof a[key] === 'string' ? a[key].toLowerCase() : a[key];
      _b = typeof b[key] === 'string' ? b[key].toLowerCase() : b[key];
    }

    const returnIfSmaller = desc ? 1 : -1;
    const returnIfBigger = desc ? -1 : 1;

    if (_a < _b) {
      return returnIfSmaller;
    } else {
      return returnIfBigger;
    }
  });
};

Date.prototype.to_DD_MM_YYYY = function (shortYear?: boolean) {
  let dd = this.getDate(),
    mm = this.getMonth() + 1, //January is 0!
    yyyy = this.getFullYear().toString(),
    ddStr: string = dd.toString(),
    mmStr: string = mm.toString();

  if (dd < 10) {
    ddStr = '0' + dd;
  }
  if (mm < 10) {
    mmStr = '0' + mm;
  }
  if (shortYear) {
    yyyy = yyyy.slice(-2);
  }
  return ddStr + '/' + mmStr + '/' + yyyy; //format("DD/MM/YYYY")
};

Date.prototype.to_DD_MM_YYYY_HH_MM = function () {
  let year = this.to_DD_MM_YYYY(),
    hour = this.getHours(),
    minutes = this.getMinutes();
  if (hour < 10) {
    hour = '0' + hour;
  }
  if (minutes < 10) {
    minutes = '0' + minutes;
  }
  return year + ' ' + hour + ':' + minutes;
};
Date.prototype.to_HH_MM_SS = function () {
  let hour = this.getHours(),
    minutes = this.getMinutes(),
    seconds = this.getSeconds();
  if (hour < 10) {
    hour = '0' + hour;
  }
  if (minutes < 10) {
    minutes = '0' + minutes;
  }
  return `${hour}:${minutes}:${seconds.toString()}`;
};

Date.prototype.srp_getWeekNumber = function () {
  const d: any = new Date(
    Date.UTC(this.getFullYear(), this.getMonth(), this.getDate())
  );
  const firstDayOfWeek: 0 | 7 = 0;
  const dayNum = d.getUTCDay() || firstDayOfWeek;
  d.setUTCDate(d.getUTCDate() + 4 - dayNum);
  var yearStart: any = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
  return Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
};

Date.prototype.srp_addDays = function (days: number) {
  const updated: Date = this.setDate(this.getDate() + days);
  return new Date(updated);
};
Date.prototype.srp_today = function () {
  const date = this.getDate();
  const month = this.getMonth();
  const year = this.getFullYear();
  return new Date(year, month, date);
};
Date.prototype.srp_todayTs = function () {
  return this.srp_today().getTime();
};

String.prototype.DD_MM_YYYY_toTime = function () {
  let timeArr = this.split('/').map((item) => {
    return parseInt(item);
  });
  if (timeArr[2] < 2000) {
    timeArr[2] += 2000;
  }
  return new Date(timeArr[2], timeArr[1] - 1, timeArr[0]).getTime();
};
String.prototype.SRP_trimWord = function (by: number) {
  let _by: number = by && typeof by === 'number' ? by : 10;
  if (this.length >= _by) {
    return this.substring(0, _by).trim() + '...';
  } else {
    return this;
  }
};
String.prototype.toBoolean = function (): boolean {
  switch (this) {
    case 'true':
    case '1':
    case 'on':
    case 'yes':
      return true
    default:
      return false
  }
};
