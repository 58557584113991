<span 
    *ngIf="!first && !wrappedWord.word.concatSw"
    [class.grammar-issue]="hasGrammarIssue(wrappedWord) && wrappedWord.offset > wrappedWord.grammarSpellingIssue.offset"
    [class.spelling-issue]="hasSpellingIssue(wrappedWord) && !wrappedWord.grammarSpellingIssue.isWhitelisted && wrappedWord.offset > wrappedWord.grammarSpellingIssue.offset"
    [class.word-reference-issue]="wrappedWord.referenceIssues?.length > 0"
    #grammarSpellingConcatSpan
>{{ " " }}</span>
<span
    [class.grammar-issue]="hasGrammarIssue(wrappedWord)"
    [class.spelling-issue]="hasSpellingIssue(wrappedWord) && !wrappedWord.grammarSpellingIssue.isWhitelisted"
    [class.word-reference-issue]="wrappedWord.referenceIssues?.length > 0"
    #grammarSpellingSpan
>
<ng-content />
</span>

<div *ngIf="wrappedWord.grammarSpellingIssue || wrappedWord.referenceIssues?.length > 0" class="grammar-spelling-issue-popup" role="tooltip" #grammarSpellingTooltip>
    <div class="l-align-spaceBetween">
        <div class="grammar-spelling-issue-popup-title">
            <i class="fa-solid fa-circle grammar-issue-popup-icon" *ngIf="hasGrammarIssue(wrappedWord)"></i>
            <i class="fa-solid fa-circle spelling-issue-popup-icon" *ngIf="hasSpellingIssue(wrappedWord)"></i>
            <i class="fa-solid fa-circle reference-issue-popup-icon" *ngIf="wrappedWord.referenceIssues?.length > 0"></i>
            <span *ngIf="wrappedWord.grammarSpellingIssue">
                <span>{{wrappedWord.grammarSpellingIssue.description}}</span>&nbsp;
                
            </span>
            <span *ngIf="wrappedWord.referenceIssues?.length > 0">{{ "translate.general.unresolvedReference" | translate }}</span>
        </div>
        <div class="t-small fw-semibold" style="margin-left: 1em;">
            <button 
                *ngIf="hasSpellingIssue(wrappedWord) && !wrappedWord.grammarSpellingIssue.isWhitelisted" 
                class="t-button-outline t-button-success" 
                (click)="addToWhiteList(wrappedWord.grammarSpellingIssue)"><i class="fa-solid fa-check" style="padding-right: 0.7em"></i>{{'translate.general.addToWhitelist' | translate}}</button>
        </div>
    </div>
    <div *ngIf="wrappedWord.grammarSpellingIssue" class="grammar-spelling-issue-popup-replacements-container">
        <ng-container 
            *ngFor="let replacement of wrappedWord.grammarSpellingIssue.replacements" 
        ><span *ngIf="replacement" class="grammar-spelling-issue-popup-replacement">{{replacement}}</span></ng-container>
    </div>
    
</div>
