import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Catalog } from 'src/app/shared/models/cat.model';
import { NgRedux } from '@redux/redux-compatibility.service';
import { AppState } from 'src/app/shared/redux/store';
import { CatService } from 'src/app/shared/services/cat.service';
import { UploadService } from 'src/app/shared/services/upload.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { DataElement, FileUpload } from 'src/app/shared/models/common';

@Component({
  selector: 'import-claims',
  templateUrl: './import-claims.component.html',
  styleUrls: ['./import-claims.component.scss'],
})
export class ImportClaimsComponent implements OnInit {
  @Output() onClose = new EventEmitter();
  @Output() onImportCompleted = new EventEmitter();

  docName: string;
  assetType: DataElement;
  isUploading: boolean;
  isValidFile: boolean;
  docS3Key: string;
  fileName: string;
  importing: boolean;

  get enableSubmit(): boolean {
    return this.fileName != null;
  }

  get catalog(): Catalog {
    return this.ngRedux.getState().curCat;
  }

  constructor(
    private ngRedux: NgRedux<AppState>,
    private catService: CatService,
    private uploadService: UploadService,
    private authService: AuthService,
    private sharedService: SharedService
  ) {}

  ngOnInit(): void { }

  close() {
    this.onClose.emit();
  }

  uploadFile($event) {
    const fileData: File = $event.target.files[0];
    if (!fileData) return;
    this.isValidFile = undefined;
    this.fileName = null;

    this.isValidFile = this.uploadService.validateFile(
      fileData,
      'xlsx'
    );
    if (!this.isValidFile) return;

    this.isUploading = true;

    const file: FileUpload = new FileUpload();
    file.fileType = this.uploadService.getFileType(fileData);
    this.fileName = fileData.name;

    (async () => {
      await this.uploadService.uploadFile(fileData);
      file.file = fileData;

      this.fileName = null;

      this.uploadService
        .getPresignedUrl(file, this.authService.getPresignedUrl)
        .subscribe(
          (data) => {
            this.uploadService.putFile(file, data).subscribe(
              (res) => {
                this.fileName = file.file.name;
                console.log(file.fileType);
                this.docS3Key = data.s3Key;
                this.isUploading = false;
              },
              (err) => {
                this.sharedService.alertError(err);
                this.isUploading = false;
              }
            );
          },
          (err) => {
            this.sharedService.alertError(err);
            this.isUploading = false;
          }
        );
    })();

    $event.target.value = '';
  }

  removeFile() {
    this.fileName = null;
  }

  importClaims() {
    this.importing = true;

    this.catService.importClaimsExcel(this.catalog.catVersionId, this.docS3Key).subscribe(
      (success) => {
        this.importing = false;
        this.onImportCompleted.emit();
      },
      (err) => (this.importing = false)
    );
  }
}