<section class="page-header">
  <div class="title-container">
    <h1>{{ "translate.mng.mngUsers" | translate }}</h1>
  </div>
</section>

<loader *ngIf="loading"></loader>

<ui-table
  [isWrapped]="false"
  [data]="usersList"
  [displayFilter]="['userId', 'username', 'userDescr', 'lastSeen']"
  [templates]="[
    {
      title: 'translate.mng.isUserActive',
      template: active
    },
    {
      title: 'translate.general.actions',
      template: actions
    }
  ]"
  [topEnd]="filterArea"
></ui-table>

<ng-template #active let-row="row">
  <div class="l-flex">
    <i
      class="fas"
      [class.fa-check]="row.cognitoActiveSw"
      [class.c-success]="row.cognitoActiveSw"
      [class.fa-times]="!row.cognitoActiveSw"
      [class.c-warn]="!row.cognitoActiveSw"
    ></i>

    &nbsp;

    <span
      class="processing-status"
      *ngIf="
        row.cognitoSyncStatus == 'PENDING' ||
        row.cognitoSyncStatus == 'PROCESSING'
      "
    >
      <processing-label></processing-label>
    </span>

    <div
      *ngIf="row.cognitoSyncStatus == 'FAILED'"
      class="processing-status failed"
    >
      <span> Sync failed</span>
      &nbsp;
      <i class="fas fa-info-circle c-warn" [tooltip]="row.cognitoSyncError"></i>
    </div>
  </div>
</ng-template>

<ng-template #actions let-row="row">
  <a (click)="editUser(row)">{{ "translate.general.edit" | translate }}</a>
</ng-template>

<ng-template #filterArea>
  <div class="filter-bar l-align-spaceBetween">
    <div class="filter-container">
      <!--username-->
      <input-wrap class="t-input-search-wrap">
        <input
          class="t-input"
          type="text"
          [(ngModel)]="filterUsername"
          placeholder="{{ 'translate.general.username' | translate }}"
          (keypress)="onFilterKeyPress($event)"
        />
      </input-wrap>

      <!--env-->
      <input-wrap [loading]="envList && envList.loading">
        <p-dropdown
          [options]="envList"
          optionLabel="descr"
          [(ngModel)]="filterEnv"
          [resetFilterOnHide]="true"
          [filterPlaceholder]="'translate.general.search' | translate"
          [placeholder]="'translate.general.env' | translate"
          [filter]="true"
          (onChange)="onFieldValueChanged($event)"
        >
        </p-dropdown>
      </input-wrap>

      <a *ngIf="displayClearFilter" (click)="clearFilter()">{{
        "translate.general.clear" | translate
      }}</a>
    </div>

    <div class="header-actions">
      <button class="t-button-outline" (click)="addUser()">
        {{ "translate.mng.addUser" | translate }}
      </button>
    </div>
  </div>
</ng-template>

<edit-user
  *ngIf="editUserOpenSw"
  [user]="curUser"
  (onClose)="editUserOpenSw = false"
  (onSave)="editUserOpenSw = false; getUsers(true)"
>
</edit-user>
