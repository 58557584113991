import { NgRedux } from '@redux/redux-compatibility.service';
import { Component, OnInit } from '@angular/core';
import { DataElement } from 'src/app/shared/models/common';
import { ConfigScope } from 'src/app/shared/models/integration-type.model';
import { Env } from 'src/app/shared/models/user.model';
import { AppState } from 'src/app/shared/redux/store';
import { AuthService } from 'src/app/shared/services/auth.service';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'app-mng-env',
  templateUrl: './mng-env.component.html',
  styleUrls: ['./mng-env.component.scss'],
})
export class MngEnvComponent implements OnInit {
  loading: boolean;
  editEnvOpenSw: boolean;
  integrationConfigurationScope: ConfigScope = ConfigScope.Environment;
  curEnv: Env;
  displayConfigureIntegration: boolean;

  get envList(): Env[] {
    return this.ngRedux.getState().envList;
  }

  constructor(
    private ngRedux: NgRedux<AppState>,
    private authService: AuthService
  ) {}

  ngOnInit(): void {}

  refreshEnvList() {
    this.loading = true;
    this.authService.getEnvList().subscribe(
      (data) => {
        this.loading = false;
      },
      (err) => {
        this.loading = false;
      }
    );
  }

  addEnv() {
    this.curEnv = new Env();
    this.editEnvOpenSw = true;
  }

  editEnv(env: Env) {
    this.curEnv = env;
    this.editEnvOpenSw = true;
  }

  onConfigureIntegration(env: Env): void {
    this.curEnv = env;
    this.displayConfigureIntegration = true;
  }

  onIntegrationConfigured(event: Event): void {
    this.displayConfigureIntegration = false;
  }
}
