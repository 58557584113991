import { NumberOfHumanWorkersPerDataObject } from 'aws-sdk/clients/sagemaker';

export class ServiceOptions {
  userId: number;
  originApp: string;
  serviceName: string;
  lang: string;
  token: number;
  urldecodeSw: boolean;
  reqEnv?: number;
  appVersion?: string;
  constructor() {
    this.originApp = 'SCAI';
    //this.data = {};
  }
}
