<modal-popup
  [flexible]="true"
  [focusOnOpen]="false"
  [isOpen]="true"
  (onClose)="close()"
  [title]="modalTitle"
>
  <div class="container">
    <div>
      <!--descr-->
      <input-wrap
        label="{{ 'translate.general.descr' | translate }}"
        cssClass="l-cell-spread"
      >
        <input type="text" [(ngModel)]="product.productDescr" class="t-input" />
      </input-wrap>

      <input-wrap
        label="{{ 'translate.general.environments' | translate }}"
        cssClass="l-cell-spread"
      >
        <!--env-->
        <p-listbox
          [options]="envList"
          [(ngModel)]="product.envList"
          [checkbox]="true"
          [multiple]="true"
          optionLabel="descr"
          dataKey="id"
          [listStyle]="{ 'max-height': '250px' }"
        ></p-listbox>
      </input-wrap>

      <div class="t-panel-cell t-buttonSet l-align-end l-mar-top">
        <!-- <button
          *ngIf="!isNew"
          [disabled]="saving"
          (click)="deleteClicked()"
          class="t-button-outline t-button-warn"
        >
          {{ "translate.general.delete" | translate }}
        </button>
        &nbsp; -->
        <button
          [disabled]="!allowSubmit() || saving"
          (click)="updateProduct()"
          class="t-button-outline"
        >
          {{ "translate.general.save" | translate }}
        </button>
      </div>

      <loader *ngIf="saving"></loader>
    </div>
  </div>
</modal-popup>
