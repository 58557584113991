<div *ngIf="!lcSw" class="l-align-end t-row--med">
  <spinner *ngIf="transRunning"></spinner>
  <button
    (click)="onTranslateClicked()"
    class="t-button-outline"
    [disabled]="transRunning || downloadingFile"
  >
    {{ "translate.general.translateCard" | translate }}
  </button>
</div>

<div *ngFor="let doc of card.cardDocs" class="card-summary doc-wrapper">
  <p-panel>
    <p-header style="width: 100%">
      <div class="doc-container">
        <div class="doc-title">
          <p class="fw-bold">{{ doc.docName }}</p>
          <div class="date">
            <span
              >{{ "translate.general.documentId" | translate }}:
              {{ doc.docId }}</span
            >
            &nbsp;|&nbsp;
            <span>{{ doc.createDate }}</span>
          </div>
        </div>

        <div class="status" *ngIf="!lcSw" class="l-align-end">
          <button
            (click)="export(doc)"
            class="t-button-outline"
            [disabled]="running || !doc.inddCardS3Key"
          >
            {{ "translate.general.export" | translate }}
          </button>

          &nbsp; &nbsp;
          <button
            (click)="extract()"
            class="t-button"
            [disabled]="running || !doc.inddDocS3Key"
          >
            {{ "translate.general.extract" | translate }}
          </button>
          <spinner *ngIf="running"></spinner>
        </div>
      </div>
    </p-header>
    <div>
      <div *ngFor="let box of doc.boundingBoxes" class="box-tile">
        <bb-tile-new
          [box]="box"
          [doc]="doc"
          [allowEdit]="allowEdit"
          [forceDeleteAction]="lcSw"
          (onDelete)="deleteBox(doc, box)"
        >
        </bb-tile-new>
      </div>
    </div>
  </p-panel>
</div>

<modal-popup
  *ngIf="displayTransPopup"
  [flexible]="true"
  [hideHeader]="false"
  [title]="'translate.general.translateCard' | translate"
  [isOpen]="displayTransPopup"
  (onClose)="displayTransPopup = false"
>
  <div class="trans-container">
    <input-wrap
      label="{{ 'translate.general.lang' | translate }}"
      cssClass="l-cell-spread"
      [required]="true"
    >
      <p-dropdown
        [options]="langs"
        optionLabel="text"
        [(ngModel)]="selectedLang"
        class="l-cell-spread-x"
        [placeholder]="'translate.general.select' | translate"
        appendTo="body"
        baseZIndex="100"
      >
      </p-dropdown>
    </input-wrap>

    <div class="actions">
      <button class="t-button-outline" (click)="displayTransPopup = false">
        {{ "translate.general.cancel" | translate }}
      </button>
      &nbsp;
      <button
        class="t-button"
        [disabled]="!selectedLang || loading"
        (click)="runTranslation()"
      >
        {{ "translate.general.ok" | translate }}
      </button>
    </div>
  </div>

  <loader *ngIf="loading"></loader>
</modal-popup>
