<ui-table
  [isWrapped]="false"
  [data]="data"
  [displayFilter]="[
    'docId',
    'docName',
    'productDescr',
    'catDescr',
    'createDate'
  ]"
  [templates]="[
    {
      title: 'translate.general.env',
      template: env
    },
    {
      title: 'translate.tagDocs.refTagSw',
      template: refTagSw
    },
    {
      title: 'translate.tagDocs.matchTagSw',
      template: matchTagSw
    },
    {
      title: 'translate.general.actions',
      template: actions
    },
    {
      title: 'translate.tagDocs.replicatedDoc',
      template: replicated
    }
  ]"
></ui-table>

<ng-template #refTagSw let-row="row">
  <checkbox-wrap class="checkbox" [class.dirty]="row.dirtySw">
    <input type="checkbox" [(ngModel)]="row.refTagSw" />
  </checkbox-wrap>
</ng-template>

<ng-template #matchTagSw let-row="row">
  <checkbox-wrap class="checkbox" [class.dirty]="row.dirtySw">
    <input type="checkbox" [(ngModel)]="row.matchTagSw" />
  </checkbox-wrap>
</ng-template>

<ng-template #env let-row="row">
  <span *ngFor="let p of row.env; let last = last">
    {{ p.descr }}
    <span *ngIf="!last"> ,</span>
  </span>
</ng-template>

<ng-template #actions let-row="row">
  <div class="l-align-center">
    <a (click)="view(row)">{{ "translate.general.view" | translate }}</a>
    <span class="action-divider">|</span>
    <a *ngIf="!row.processing" (click)="replicate(row)">{{
      "translate.tagDocs.replicate" | translate
    }}</a>
    <spinner *ngIf="row.processing"></spinner>
    <span class="action-divider">|</span>
    <a (click)="remove(row)">{{ "translate.general.remove" | translate }}</a>
  </div>
</ng-template>

<ng-template #replicated let-row="row">
  <div
    *ngIf="row.replicatedDoc && row.replicatedDoc?.runStatusCode == 'FINISHED'"
    class="l-align-center"
  >
    <a (click)="viewReplicated(row)"> {{ row.replicatedDoc?.docId }} </a>
  </div>

  <!--failed-->
  <p
    *ngIf="row.replicatedDoc?.runStatusCode == 'FAILED'"
    class="processing-status failed"
  >
    ({{ "translate.general.processingFailed" | translate }})
  </p>

  <!--running-->
  <div *ngIf="row.replicatedDoc?.isProcessRunning">
    <div
      *ngIf="row.replicatedDoc.processStatus"
      (click)="openProcessStatus(row.replicatedDoc)"
    >
      <circle-progress
        [percent]="row.replicatedDoc.processStatus.completedPct"
      ></circle-progress>
    </div>
    <spinner *ngIf="!row.replicatedDoc.processStatus"></spinner>
    <p class="processing-status">
      <processing-label></processing-label>
    </p>
  </div>
</ng-template>

<process-run-status
  *ngIf="displayProcessStatus"
  (onClose)="displayProcessStatus = false"
  [doc]="curDoc"
  [status]="curDoc.processStatus"
>
</process-run-status>
