import { Injectable, ViewChild, ElementRef } from "@angular/core";
import { Observable, of } from "rxjs";
import { catchError, map, take } from "rxjs/operators";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { KeyCodes } from "../models/key-codes.model";
import { AuthService } from "./auth.service";
import { NgRedux } from '@redux/redux-compatibility.service';
import { AppState, actionsList } from "../redux/store";
import { DataElement } from "../models/common";
import * as Chart from 'chart.js';

@Injectable({
  providedIn: "root"
})
export class UiService {
  keyCodes = new KeyCodes();
  fileInvalidAlert: string;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private ngRedux: NgRedux<AppState>
  ) {
    this.chartMiddleTextPlugin();
  }

  public isImage(str: string): boolean {
    if (typeof str !== "string") return false;
    return !!".png,.jpg,.gif,.jpeg".split(",").find(s => str.toLowerCase().indexOf(s) !== -1);
  }

  public isString(str: string): boolean {
    return !this.isImage(str) && "string,number".indexOf(typeof str) !== -1;
  }

  public lazyLoad(
    freshArray: any[] = [],
    originalArray: any[],
    augmentBy = 50
  ) {
    try {
      let from = 0;
      let to = augmentBy;
      const _lazyLoad = () => {
        if (!originalArray && !originalArray.length) return;
        [].push.apply(freshArray, originalArray.slice(from, to));
        from += augmentBy;
        to += augmentBy;
        if (from < originalArray.length) {
          requestAnimationFrame(_lazyLoad);
          freshArray.loading = true;
        } else {
          freshArray.loading = false;
        }
        return freshArray;
      };
      return _lazyLoad();
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  public getWeekNumber(d) {
    d = new Date(+d);
    d.setHours(0, 0, 0);
    d.setDate(d.getDate() + 4 - (d.getDay() || 0));
    var yearStart: any = new Date(d.getFullYear(), 0, 1);
    var weekNo: any = Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
    return weekNo;
  }

  public weeksInYear(year) {
    var d = new Date(year, 11, 31);
    var week = this.getWeekNumber(d);
    return week == 1 ? this.getWeekNumber(d.setDate(24)) + 1 : week;
  }

  public getDateByWeekNumber(
    weekNumber: number | string,
    year: number | string
  ): Date {
    weekNumber = parseInt(weekNumber as string);
    year = parseInt(year as string);
    var d = 1 + (weekNumber - 1) * 7; // 1st of January + 7 days for each week
    const beginningOfWeed = new Date(year, 0, d).getDay();
    return new Date(year, 0, d - beginningOfWeed);
  }

  public arrayFromRange(from: number, to: number) {
    return Array.from({ length: to }, (v, k) => k + from);
  }

  /*public setYearList(from: number, to: number): DataElement[] {
    const currentYear = new Date().getFullYear();
    const yearsRange = this.arrayFromRange(from, to).map(counter => {
      const year = currentYear + counter;
      return {
        id: new Date(year, 0, 1),
        value: new Date(year, 0, 1),
        text: "" + year,
        label: "" + year
      };
    });
    return yearsRange;
  }*/

  public monthToStart = (date: string): string => {
    // converts DD/MM/YYYY to MM/DD/YYYY
    let dateAsArray = date.split("/");
    dateAsArray.unshift(dateAsArray.splice(1, 1)[0]);
    return dateAsArray.join("/");
  };

  /*public controlPrimeNgCalendar(
    calendar: Calendar,
    val: Date,
    callback?: Function
  ) {
    if (!calendar) return;
    // set calendar value
    calendar.value = val;
    calendar.currentYear = val.getFullYear();
    calendar.currentMonth = val.getMonth();
    // force calendar to navigate to selected date
    calendar.minDate = val;
    calendar.maxDateCount = 1;
    requestAnimationFrame(() => {
      calendar.minDate = null;
      calendar.maxDateCount = null;
      if (typeof callback === "function") {
        callback(calendar);
      }
    });
  }*/

  private arrowNavCounter: number = 0;

  public arrowNav(el: HTMLElement, children?: HTMLCollection): void {
    const holder: HTMLElement = el;
    const holderItems = children || holder.children;
    const updateCounterOnMouseup = () => {
      this.arrowNavCounter = [].indexOf.call(
        holderItems,
        document.activeElement
      );
    };
    const updateFocus = () => {
      [].forEach.call(holderItems, (item: HTMLElement, index: number) => {
        const isFocused: boolean = index === this.arrowNavCounter;
        if (isFocused) {
          item.focus();
        }
      });
    };

    holder.addEventListener("keyup", ($event: KeyboardEvent) => {
      if ($event.keyCode === this.keyCodes.arrowDown) {
        this.arrowNavCounter++;
        this.arrowNavCounter = this.arrowNavCounter % holderItems.length;
      }
      if ($event.keyCode === this.keyCodes.arrowUp) {
        this.arrowNavCounter--;
        this.arrowNavCounter =
          this.arrowNavCounter < 0 ? 0 : this.arrowNavCounter;
      }
      if ($event.keyCode === this.keyCodes.tab) {
        this.arrowNavCounter = [].indexOf.call(
          holderItems,
          document.activeElement
        );
      }
      updateFocus();
    });

    holder.removeEventListener(
      "click",
      updateCounterOnMouseup.bind(this, event)
    );
    holder.addEventListener("click", updateCounterOnMouseup.bind(this, event));
  }


  chartMiddleTextPlugin() {
    Chart.pluginService.register({
      beforeDraw: function (chart) {
        if (chart.config.options.elements.center) {
          //Get ctx from string
          var ctx = chart.chart.ctx;

          //Get options from the center object in options
          var centerConfig = chart.config.options.elements.center;
          var fontStyle = centerConfig.fontStyle || 'Arial';
          var txt = centerConfig.text;
          var color = centerConfig.color || '#000';
          var sidePadding = centerConfig.sidePadding || 20;
          var sidePaddingCalculated =
            (sidePadding / 100) * (chart.innerRadius * 2);
          //Start with a base font of 30px
          ctx.font = '30px ' + fontStyle;

          //Get the width of the string and also the width of the element minus 10 to give it 5px side padding
          var stringWidth = ctx.measureText(txt).width;
          var elementWidth = chart.innerRadius * 2 - sidePaddingCalculated;

          // Find out how much the font can grow in width.
          var widthRatio = elementWidth / stringWidth;
          var newFontSize = Math.floor(30 * widthRatio);
          var elementHeight = chart.innerRadius * 2;

          // Pick a new font size so it will not be larger than the height of label.
          var fontSizeToUse = Math.min(newFontSize, elementHeight);

          //Set font settings to draw it correctly.
          ctx.textAlign = 'center';
          ctx.textBaseline = 'middle';
          var centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
          var centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2;
          ctx.font = fontSizeToUse + 'px ' + fontStyle;
          ctx.fillStyle = color;

          //Draw text in center
          ctx.fillText(txt, centerX, centerY);
        }
      },
    });
  }
}
