import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NgRedux } from '@redux/redux-compatibility.service';
import { AppState, actionsList } from 'src/app/shared/redux/store';
import { RevService } from 'src/app/shared/services/rev.service';
import {
  RevRule,
  RevDoc,
  RevStatus,
  RevDocStatusEnum,
} from 'src/app/shared/models/rev.model';
import { Unsubscribe } from 'redux';
import { Subscription } from 'rxjs';
import { SearchRunStatusEnum } from 'src/app/shared/models/common';
import { AuthService } from 'src/app/shared/services/auth.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'rev-doc-rules-list',
  templateUrl: './rev-doc-rules-list.component.html',
  styleUrls: ['./rev-doc-rules-list.component.scss'],
})
export class RevDocRulesListComponent implements OnInit {
  loading: boolean;
  @Output() onToggleRuleView = new EventEmitter<RevRule>();
  @Output() onHideRuleView = new EventEmitter<RevRule>();
  @Output() onShowRuleView = new EventEmitter<RevRule>();
  displayPageNum: boolean;
  un: Unsubscribe;
  getDataSub: Subscription;
  searchStatusTimer: any;
  searchStatusIntervalSec: number = 2;
  sub: Subscription;

  get rulesList(): RevRule[] {
    return this.ngRedux.getState().revDocRules.filter((r) => !r.isImage);
  }

  get imagesList(): RevRule[] {
    return this.ngRedux.getState().revDocRules.filter((r) => r.isImage);
  }

  get curRevDoc(): RevDoc {
    return this.ngRedux.getState().curRevDoc;
  }

  constructor(
    private ngRedux: NgRedux<AppState>,
    private revService: RevService,
    private authService: AuthService,
    private sharedService: SharedService,
    private translateService: TranslateService
  ) {
    this.un = this.ngRedux.subscribe(() => {
      const appState = this.ngRedux.getState();
      switch (appState._currentAction) {
        case actionsList.FILTER_REV_RULES:
          this.displayPageNum = true;
          this.getData(null);
          break;
        case actionsList.SELECT_REV_DOC_PAGE_NUM:
          this.displayPageNum = false;
          this.getData(this.ngRedux.getState().curRevDocPageNum);
          break;
        case actionsList.SELECT_REV_RULE:
          this.onMouseLeave(this.ngRedux.getState().curRevRule);
          break;
      }
    });
  }

  ngOnInit(): void {
    this.getData(this.ngRedux.getState().curRevDocPageNum);
  }

  ngOnDestroy(): void {
    if (this.un) this.un();
    this.sub?.unsubscribe();
  }

  getData(pageNum: number) {
    console.log('get data: ' + pageNum);
    this.getDataSub?.unsubscribe();
    this.sub?.unsubscribe();

    this.loading = true;
    this.ngRedux.dispatch({
      type: actionsList.UPD_DOC_PAGE_LOADING,
      data: true,
    });
    this.ngRedux.getState().revDocRules = [];

    this.getDataSub = this.revService
      .getRevRules(
        this.curRevDoc.docId,
        pageNum,
        this.ngRedux.getState().revRulesSearchParams
      )
      .subscribe(
        (searchId) => {
          console.log(searchId);

          this.pullSearchStatus(
            searchId,
            () => {
              this.revService.getRevRulesSearchWithoutDuplicates(searchId).subscribe(
                (data) => {
                  this.loading = false;
                  this.ngRedux.dispatch({
                    type: actionsList.UPD_DOC_PAGE_LOADING,
                    data: false,
                  });
                },
                (err) => {
                  this.loading = false;
                  this.ngRedux.dispatch({
                    type: actionsList.UPD_DOC_PAGE_LOADING,
                    data: false,
                  });
                }
              );
            },
            () => {
              this.loading = false;
              this.ngRedux.dispatch({
                type: actionsList.UPD_DOC_PAGE_LOADING,
                data: false,
              });
              this.sharedService.alertError(
                this.translateService.instant('translate.alert.searchError'),
                null,
                null,
                'get_rev_rules_func'
              );
            }
          );
        },
        (err) => {
          this.loading = false;
          this.ngRedux.dispatch({
            type: actionsList.UPD_DOC_PAGE_LOADING,
            data: false,
          });
        }
      );
  }

  pullSearchStatus(searchId: number, onFinished, onError) {
    this.sub?.unsubscribe();
    this.sub = this.authService.getSearchStatus(searchId).subscribe(
      (status) => {
        if (status == SearchRunStatusEnum.FINISHED) {
          onFinished();
        } else if (status == SearchRunStatusEnum.ERROR) {
          onError();
        } else {
          setTimeout(() => {
            this.pullSearchStatus(searchId, onFinished, onError);
          }, this.searchStatusIntervalSec * 1000);
        }
      },
      (err) => {
        onError();
      }
    );
  }

  onToggleRuleViewClicked(rule: RevRule) {
    this.onToggleRuleView.emit(rule);
  }

  onGoToRulePage(rule: RevRule) {
    this.onToggleRuleView.emit(rule);
  }

  onMouseEnter(rule: RevRule) {
    this.onShowRuleView.emit(rule);
  }

  onMouseLeave(rule: RevRule) {
    if (!rule.viewSw) this.onHideRuleView.emit(rule);
  }

  get isSearchActive() {
    let searchParams = this.ngRedux.getState().revRulesSearchParams;
    return searchParams && 
      (searchParams.phrase 
        || searchParams.ruleStatus 
        || searchParams.contentType 
        || searchParams.matchType 
        || searchParams.ruleType 
        || (searchParams.subTypeList && searchParams.subTypeList.length > 0));
  }

  reloadData() {
    this.getData(this.ngRedux.getState().curRevDocPageNum)
  }
}
