import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { NgRedux } from '@redux/redux-compatibility.service';
import { AppState } from '../redux/store';
import {
  FileUpload,
  PresignedUrlResult,
  ServiceCallInfo,
} from '../models/common';
import { take, map, catchError } from 'rxjs/operators';
import { ValidationPipe } from '../components/ui/validation.pipe';
import { v4 as uuidv4 } from 'uuid';
import { ServiceOptions } from '../models/service-options';

@Injectable({
  providedIn: 'root',
})
export class UploadService {
  fileInvalidAlert: string;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private ngRedux: NgRedux<AppState>,
    private validationPipe: ValidationPipe
  ) {}

  public uploadFile(fileData: File): Promise<string | ArrayBuffer> {
    const reader = new FileReader();
    const file: File = fileData;
    reader.readAsDataURL(file);
    return new Promise((resolve) => {
      reader.addEventListener('load', (event: any) => {
        resolve(reader.result);
      });
    });
  }

  public getPresignedUrl(
    fileData: FileUpload,
    webApiUrl: string,
    publicReadSw: boolean = false
  ): Observable<PresignedUrlResult> {
    let response = new Observable<PresignedUrlResult>();
    let serviceName = 'ScaiPyAdmin.GetPresignedUrl';
    //let params = {};
    let params = new ServiceOptions();
    params.serviceName = serviceName;
    let fileName =
      fileData.entityType == 'instr'
        ? 'instr' + fileData.fileType.toLowerCase()
        : 'doc' + fileData.fileType.toLowerCase();
    let docS3key = uuidv4() + '/' + fileName;
    params['bucket'] = this.ngRedux.getState().curEnv.tmpS3Bucket; //  fileData.subFolder;
    params['contentType'] = fileData.fileType.replace('.', '').toLowerCase();
    params['key'] = docS3key;
    params['userId'] = this.ngRedux.getState().user.userId;
    params['token'] = this.ngRedux.getState().user.token;
    params['publicSw'] = publicReadSw ? '1' : '0';

    let body = this.authService.getRequestBody(params); //JSON.stringify(params);
    console.log(body);

    let info = new ServiceCallInfo(serviceName, webApiUrl, body);

    response = this.http
      .post(webApiUrl, body, {
        headers: this.authService.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json',
      })
      .pipe(
        take(1),
        map((data: any) => {
          this.authService.extractRequestStatus(info, data);
          let dataRep = data.reply.requestResult.data;
          let res = new PresignedUrlResult();
          res.uploadUrl = dataRep.url;
          res.s3Key = docS3key;
          let headers = new Map<string, string>();
          let headersArr = new Array().concat(dataRep.headers);
          headersArr.forEach((h) => {
            if (
              h.header != 'Expect' &&
              h.header != 'Host' &&
              h.header != 'x-amz-acl'
            )
              headers.set(h.header, h.value);
          });
          res.headers = headers;
          console.log(res);
          return res;
        }),
        catchError((error) => {
          return this.authService.handleError(error, info);
        })
      );

    return response;
  }

  public putFile(
    file: FileUpload,
    uploadParams: PresignedUrlResult
  ): Observable<any> {
    let headersObject = {};
    uploadParams.headers.forEach((value, key) => {
      headersObject[key] = value;
    });
    let headers = new HttpHeaders(headersObject);
    return this.http.put(uploadParams.uploadUrl, file.file, {
      headers: headers,
    });
  }

  public validateFile(fileData: File, validationStr?: string): boolean {
    let fileName = fileData.name.toLowerCase();
    let fileType = fileName.split('.')[fileName.split('.').length - 1];

    let isValidFileType: boolean;
    if (validationStr) {
      isValidFileType = validationStr.indexOf(fileType) !== -1;
    } else {
      isValidFileType = 'jpg,jpeg,png'.indexOf(fileType) !== -1;
    }
    if (!isValidFileType) {
      this.fileInvalidAlert = 'translate.validation.pdfOrJpgOnly';
      return false;
    }
    this.fileInvalidAlert = '';
    return true;
  }

  public getFileType(fileData: File): string {
    if (!fileData) return '';
    return '.' + fileData.name.split('.')[fileData.name.split('.').length - 1];
  }

  encryptFile(
    docId: number,
    docType: string,
    sourceBucket: string,
    destBucket: string,
    s3Key: string
  ): Observable<boolean> {
    let response = new Observable<boolean>();
    let serviceName = 'ScaiPyLong.EncryptFile';
    let params = new ServiceOptions();
    params.serviceName = serviceName;

    params['docId'] = docId;
    params['docType'] = docType;
    params['sourceBucket'] = sourceBucket;
    params['destBucket'] = destBucket;
    params['s3Key'] = s3Key;
    params['userId'] = this.ngRedux.getState().user.userId;
    params['token'] = this.ngRedux.getState().user.token;

    let url = this.authService.getRequestUrl(serviceName, true);
    let body = this.authService.getRequestBody(params);
    let info = new ServiceCallInfo(serviceName, url, body, false);

    response = this.http
      .post(url, body, {
        headers: this.authService.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json',
      })
      .pipe(
        map((data) => this.authService.extractRequestStatus(info, data)),
        catchError((error) => {
          return this.authService.handleError(error, info);
        })
      );

    return response;
  }

  public loadInddFile(s3bucket: string, zipS3key: string): Observable<string> {
    let response = new Observable<string>();
    let serviceName = 'ScaiInDesign.loadIndd';
    let params = new ServiceOptions();
    params.serviceName = serviceName;

    params['s3bucket'] = s3bucket;
    params['zipS3key'] = zipS3key;
    params['userId'] = this.ngRedux.getState().user.userId;
    params['token'] = this.ngRedux.getState().user.token;

    let url = this.authService.getRequestUrl(serviceName, true);
    let body = this.authService.getRequestBody(params);
    console.log(body);

    let info = new ServiceCallInfo(serviceName, url, body);

    response = this.http
      .post(url, body, {
        headers: this.authService.getRequestHeaders(),
        withCredentials: false,
        responseType: 'json',
      })
      .pipe(
        take(1),
        map((data: any) => {
          this.authService.extractRequestStatus(info, data);
          let dataRep = data.reply.requestResult.data;
          console.log(dataRep);
          return dataRep.pdfS3key;
        }),
        catchError((error) => {
          return this.authService.handleError(error, info);
        })
      );

    return response;
  }
}
