<p-treeTable
  *ngIf="catPhraseTypes"
  [value]="catPhraseTypes"
  [selectionMode]="selectionMode"
  [(selection)]="selectedNodes"
  (onNodeSelect)="nodeSelect($event)"
  (onNodeUnselect)="nodeUnselect($event)"
>
  <!-- <ng-template pTemplate="caption">
    <p-treeTableHeaderCheckbox></p-treeTableHeaderCheckbox>
  </ng-template> -->
  <ng-template pTemplate="header">
    <tr>
      <th>
        {{ "translate.cat.mngPhraseTypeDescr" | translate }}
      </th>
      <th>{{ "translate.cat.mngPhraseTypeCode" | translate }}</th>
      <th>{{ "translate.cat.mngPhraseTypeErrorLevel" | translate }}</th>
      <th>{{ "translate.cat.mngPhraseSubTypeLevel0" | translate }}</th>
      <th>{{ "translate.cat.mngPhraseSubTypeLevel1" | translate }}</th>
      <th>{{ "translate.cat.algKeyWords" | translate }}</th>
      <th *ngIf="allowEdit">{{ "translate.general.actions" | translate }}</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowNode let-rowData="rowData">
    <tr [ttRow]="rowNode">
      <td class="l-align-y">
        <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
        <p-treeTableCheckbox
          *ngIf="allowSelect && rowData.selectable"
          [value]="rowNode"
        ></p-treeTableCheckbox>
        {{ rowData.typeDescr }}
      </td>
      <td>{{ rowData.typeCode }}</td>
      <td>{{ rowData.errorLevel }}</td>
      <td>{{ rowData.level0 }}</td>
      <td>{{ rowData.level1 }}</td>
      <td>
        <div
          *ngIf="rowData.treeLevel == 1 && rowData.algKeyWords"
          [tooltip]="rowData.algKeyWords"
        >
          <i class="fas fa-book-open"></i>
        </div>
      </td>
      <td>
        <div *ngIf="allowEdit" class="actions-container">
          <span *ngIf="rowData.treeLevel == 0">
            <a (click)="addSubType(rowNode)">{{
              "translate.cat.mngPhraseTypeAddSubType" | translate
            }}</a>
            <span class="action-divider">|</span>
          </span>

          <a (click)="edit(rowNode)">{{
            "translate.general.edit" | translate
          }}</a>
          <span class="action-divider">|</span>
          <a (click)="onDeleteClicked(rowNode)">{{
            "translate.general.delete" | translate
          }}</a>
        </div>
      </td>
    </tr>
  </ng-template>
</p-treeTable>
