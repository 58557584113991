import { NgRedux } from '@redux/redux-compatibility.service';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TreeNode } from 'primeng/api';
import {
  Catalog,
  CatalogSearchParams,
  CatPhraseSubType,
} from 'src/app/shared/models/cat.model';
import { DataElement } from 'src/app/shared/models/common';
import { Env } from 'src/app/shared/models/user.model';
import { AppState } from 'src/app/shared/redux/store';
import { CatService } from 'src/app/shared/services/cat.service';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'import-type-from-cat',
  templateUrl: './import-type-from-cat.component.html',
  styleUrls: ['./import-type-from-cat.component.scss'],
})
export class ImportTypeFromCatComponent implements OnInit {
  @Output() onClose = new EventEmitter();
  @Output() onSave = new EventEmitter();
  loading: boolean;
  loadingCatList: boolean;
  selectedCatalog: Catalog;
  catalogList: Catalog[];
  catPhraseTypes: TreeNode[];
  selectedSubTypes: CatPhraseSubType[] = [];
  selectedEnv: Env;

  get envList(): Env[] {
    return this.ngRedux.getState().envList.sort((a, b) => {
      return a.id == this.ngRedux.getState().curEnv.id ? -1 : 1;
    });
  }

  get modalTitle(): string {
    return 'translate.cat.mngPhraseTypeImportFromCat';
  }

  constructor(
    private ngRedux: NgRedux<AppState>,
    private catService: CatService,
    private sharedService: SharedService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    // this.catalogList = this.ngRedux
    //   .getState()
    //   .catalogList.filter(
    //     (c) => c.catId != this.ngRedux.getState().curCat.catId
    //   );
    if (this.envList && this.envList.length > 0) {
      this.selectedEnv = this.envList[0];
      this.onEnvChange();
    }
  }

  close() {
    if (this.selectedSubTypes.length > 0) {
      let validationStr = this.translateService.instant(
        'translate.cat.discardEditChanges'
      );

      this.sharedService.emitPopupchReceivedEvent({
        title: 'translate.validation.confirm',
        icon: 'exclamation-triangle',
        subTitle: validationStr,
        keep: false,
        approve: () => {
          this.onClose.emit();
        },
      });
    } else {
      this.onClose.emit();
    }
  }

  validateBeforeSave(): boolean {
    return this.selectedSubTypes.length > 0;
  }

  save() {
    this.loading = true;
    this.catService.addCatPhraseTypeTree(this.selectedSubTypes).subscribe(
      (data) => {
        this.loading = false;
        this.onSave.emit();
      },
      (err) => (this.loading = false)
    );
  }

  onEnvChange() {
    this.loadingCatList = true;
    this.catalogList = [];
    this.catPhraseTypes = null;
    let searchParams: CatalogSearchParams = {
      envId: this.selectedEnv.id,
    };

    this.catService.getCatList(searchParams, false).subscribe(
      (data) => {
        this.catalogList = data.filter(
          (c) => c.catId != this.ngRedux.getState().curCat.catId
        );
        this.loadingCatList = false;
      },
      (err) => {
        this.loadingCatList = false;
      }
    );
  }

  onCatalogChange(event: any) {
    this.loading = true;
    this.catService
      .getCatPhraseTypes(this.selectedCatalog.catId, false)
      .subscribe((data) => {
        this.catPhraseTypes = data.treeTypes;
        this.catPhraseTypes.forEach((t) => {
          if (t.children) {
            t.children.forEach((s) => {
              let subType = s['value'] as CatPhraseSubType;
              let subTypeExists = false;

              this.ngRedux.getState().catPhraseTypeModel.types.forEach((t) => {
                if (t.subTypes) {
                  t.subTypes.forEach((sub) => {
                    if (sub.subTypeCode == subType.subTypeCode)
                      subTypeExists = true;
                  });
                }
              });

              s.data.selectable = s.selectable = !subTypeExists;
            });
          }

          t.data.selectable = t.selectable =
            t.children && t.children.some((c) => c.selectable);
        });
        this.loading = false;
      });
  }

  onTypesSelection(nodes: TreeNode[]) {
    this.selectedSubTypes = [];
    nodes.forEach((node) => {
      if (node.data['treeLevel'] > 0 && node.selectable) {
        this.selectedSubTypes.push(node['value'] as CatPhraseSubType);
      }
    });
  }
}
