export default class Utils {
    static areEqual(obj1: any, obj2: any): boolean {
      // Get the keys of both objects
      const keys1 = Object.keys(obj1);
      const keys2 = Object.keys(obj2);
  
      // Check if the number of keys is the same
      if (keys1.length !== keys2.length) {
        return false;
      }
  
      // Iterate through keys and values
      for (const key of keys1) {
        // Check if the key exists in both objects
        if (!keys2.includes(key)) {
          return false;
        }
  
        // Compare the values for the current key
        const value1 = obj1[key];
        const value2 = obj2[key];
  
        // Deep comparison for nested objects or arrays
        if (typeof value1 === 'object' && typeof value2 === 'object') {
          if (!this.areEqual(value1, value2)) {
            return false;
          }
        } else if (value1 !== value2) {
          return false;
        }
      }
  
      // If all keys and values are equal, return true
      return true;
    }
  
  }
  