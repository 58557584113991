import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  Catalog,
  CatalogSearchParams,
  CatalogStatusEnum,
} from '../shared/models/cat.model';
import { NgRedux } from '@redux/redux-compatibility.service';
import { AppState, actionsList } from '../shared/redux/store';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { SharedService } from '../shared/services/shared.service';
import { Unsubscribe } from 'redux';
import { ServiceMetaData } from '../shared/models/service-meta.model';
import {
  PaginationParams,
  DataElement,
  Product,
} from '../shared/models/common';
import { keyCodes } from '../shared/models/key-codes.model';
import { RevDoc } from '../shared/models/rev.model';
import { CatService } from '../shared/services/cat.service';
import { Location } from '@angular/common';
import { threadId } from 'worker_threads';

@Component({
  selector: 'catalog-list',
  templateUrl: './catalog-list.component.html',
  styleUrls: ['./catalog-list.component.scss'],
})
export class CatalogListComponent implements OnInit {
  @Input() selectMode: boolean;
  @Input() limitProducts: DataElement[];
  @Output() onCatSelected = new EventEmitter();

  displayCreateCat: boolean;
  loading: boolean;
  extSw: boolean;

  selectedCatalog: Catalog;
  rangeFrom: number;
  rangeTo: number;
  totalRecords: number;
  filterCatIdName: string;
  filterProducts: DataElement[] = [];
  filterType: DataElement;
  reduxSubscribe: Unsubscribe;
  searchParams: CatalogSearchParams = {};

  catsBeingEdited: object = {};
  catsBeingUpdated: object = {};

  get catTypeList(): DataElement[] {
    return this.ngRedux.getState().catTypeList;
  }

  get productList(): Product[] {
    if (this.ngRedux.getState().productList && this.limitProducts) {
      return this.ngRedux
        .getState()
        .productList.filter(
          (p) => this.limitProducts.filter((r) => r.id == p.id).length > 0
        );
    } else return this.ngRedux.getState().productList;
  }

  get catalogsMetaData(): ServiceMetaData {
    const metaData: ServiceMetaData = this.extSw
      ? this.ngRedux.getState().extCatalogsMetadata
      : this.ngRedux.getState().catalogsMetadata;
    return metaData || {};
  }

  get displayClearFilter(): boolean {
    return (
      this.filterCatIdName != null ||
      this.filterProducts.length > 0 ||
      this.filterType != null
    );
  }

  get catList(): Catalog[] {
    return this.extSw
      ? this.ngRedux.getState().extCatalogList
      : this.ngRedux.getState().catalogList;
  }

  constructor(
    private ngRedux: NgRedux<AppState>,
    private router: Router,
    private sharedService: SharedService,
    private catService: CatService,
    private route: ActivatedRoute,
    private location: Location
  ) {}

  ngOnInit(): void {
    if (this.route.snapshot.url[0].path == 'ext-cat-list') {
      this.extSw = true;
    }
    this.route.params.subscribe((params: Params) => {
      let catId = params['cat'];
      console.log('catId: ' + catId);
      this.getCatalogs(true, catId);
    });

    this.reduxSubscribe = this.ngRedux.subscribe(() => {
      const appState = this.ngRedux.getState();
      switch (appState._currentAction) {
        case actionsList.SELECT_ENV:
          this.getCatalogs(true);
          break;
        case actionsList.UPD_CAT_VERSION:
          this.getCatalogs(false);
          break;
      }
    });
  }

  goBack() {
    this.location.back();
  }

  getCatalogs(newSearch: boolean, goToCat: number = null) {
    this.loading = true;

    if (newSearch) {
      this.searchParams.searchId = null;
      this.searchParams.rangeFrom = 1;
      this.searchParams.rangeTo = 10;
    }

    this.searchParams.catIdName = this.filterCatIdName;
    if (this.limitProducts) {
      this.searchParams.products = this.limitProducts;
    }
    if (this.filterProducts.length > 0) {
      this.searchParams.products = this.filterProducts;
    }

    this.searchParams.typeCode = this.filterType ? this.filterType.id : null;
    this.searchParams.envId = this.ngRedux.getState().curEnv.id;
    this.searchParams.extSw = this.extSw;

    this.catService.getCatList(this.searchParams).subscribe(
      (data) => {
        if (goToCat != null && data.find((c) => c.catId == goToCat)) {
          let cookieCatVersion = this.sharedService.getCookie(
            'cat_version_id',
            false
          );
          console.log('cookieCatVersion: ' + cookieCatVersion);
          if (cookieCatVersion != '') {
            let catVersion = data
              .find((c) => c.catId == goToCat)
              .catVersions.find((v) => v.catVersionId == cookieCatVersion);
            if (catVersion) this.viewVersion(catVersion);
            else this.loading = false;
          } else {
            this.loading = false;
          }
        } else this.loading = false;
      },
      (err) => (this.loading = false)
    );
  }

  onPagination(event: PaginationParams) {
    if (
      !this.catList ||
      (event.rangeFrom == this.searchParams.rangeFrom &&
        event.rangeTo == this.searchParams.rangeTo)
    )
      return;

    this.searchParams.rangeFrom = event.rangeFrom;
    this.searchParams.rangeTo = event.rangeTo;
    this.searchParams.searchId =
      this.ngRedux.getState().catalogsMetadata.searchId;

    this.getCatalogs(false);
  }

  onFilterKeyPress(e: any) {
    if (e.keyCode == keyCodes.enter) {
      this.getCatalogs(true);
    }
  }

  onFieldValueChanged(e: any) {
    (e.originalEvent as MouseEvent).stopPropagation();
    this.getCatalogs(true);
  }

  clearFilter() {
    this.filterCatIdName = null;
    this.filterProducts = [];
    this.filterType = null;
    this.getCatalogs(true);
  }

  openDraft(cat: Catalog) {
    let version = cat.catVersions.find(
      (v) => v.statusCode == CatalogStatusEnum.DRAFT
    );
    if (version) {
      this.viewVersion(version);
    }
  }

  openPublished(cat: Catalog) {
    let version = cat.catVersions.find(
      (v) => v.statusCode == CatalogStatusEnum.PUBLISHED
    );
    if (version) {
      this.viewVersion(version);
    }
  }

  viewVersion(cat: Catalog) {
    this.ngRedux.dispatch({
      type: actionsList.SELECT_CATALOG,
      data: cat,
    });
    if (!this.selectMode) {
      this.sharedService.putCookie('cat_id', cat.catId.toString(), false);
      this.sharedService.putCookie(
        'cat_version_id',
        cat.catVersionId.toString(),
        false
      );
      this.router.navigate([this.sharedService.routeEnum.URL_CATALOG]);
    } else {
      this.onCatSelected.emit();
    }
  }

  unpublish(cat: Catalog) {}

  onCatalogCreated() {
    this.displayCreateCat = false;
    this.getCatalogs(false);
  }

  onClickEditCatTitle(event: Event, cat: Catalog) {
    event.stopPropagation();
    this.catsBeingEdited[cat.catId] = { catDescr: cat.catDescr }
  }

  onClickCancelEditCatTitle(event: Event, catId: number) {
    event.stopPropagation();
    delete this.catsBeingEdited[catId];
  }

  onClickUpdateCatTitle(event: Event, catId: number) {
    event.stopPropagation();

    this.catsBeingUpdated[catId] = this.catsBeingEdited[catId];

    const catDescr = this.catsBeingUpdated[catId].catDescr;

    this.catService.changeCatTitle(catId, catDescr).subscribe(
      _ => {
        delete this.catsBeingUpdated[catId];
        delete this.catsBeingEdited[catId];

        let cat = this.catList.find(c => c.catId === catId);
        cat.catDescr = catDescr;
        cat.catVersions.forEach(cv => cv.catDescr = catDescr);
      },
      err => {
        delete this.catsBeingUpdated[catId];
        delete this.catsBeingEdited[catId];

        this.sharedService.alertError(err);
      }
    );
  }
}
