import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {
  IndPhrase,
  CatDocStatusEnum,
  CatPhraseStatusEnum,
  IndElement,
  IndImage,
  CatDocStateTagEnum,
  CatDoc,
} from 'src/app/shared/models/cat.model';
import { DataElement } from 'src/app/shared/models/common';
import { CatService } from 'src/app/shared/services/cat.service';
import { NgRedux } from '@redux/redux-compatibility.service';
import { AppState, actionsList } from 'src/app/shared/redux/store';
import { SharedService } from 'src/app/shared/services/shared.service';
import { InputWrapComponent } from 'src/app/shared/components/ui/input-wrap.component';
import { Unsubscribe } from 'redux';

@Component({
  selector: 'cat-doc-phrase-tile',
  templateUrl: './cat-doc-phrase-tile.component.html',
  styleUrls: ['./cat-doc-phrase-tile.component.scss'],
})
export class CatDocPhraseTileComponent implements OnInit {
  @Input() stateTag: CatDocStateTagEnum = CatDocStateTagEnum.PRIME;
  @Input() editable: boolean = true;
  @Input('phrase') set data(element: IndElement) {
    this.element = element;
    if (this.element instanceof IndPhrase) {
      this.phrase = this.element;
    } else if (this.element instanceof IndImage) {
      this.indImage = this.element;
    }
  }
  element: IndElement;
  phrase: IndPhrase;
  indImage: IndImage;

  @Input() displayPageNum: boolean;
  @Output() onTogglePhraseView = new EventEmitter();
  @Output() onGoToPhrasePage = new EventEmitter();
  @Output() onUpdateSpellingIssues = new EventEmitter();

  phraseStatusOptions: DataElement[] = [];
  phraseDetailsOpenSw: boolean = false;
  phraseEditModeSw: boolean;
  un: Unsubscribe;
  addExtRefOpenSw: boolean;

  get extSw(): boolean {
    return (
      this.ngRedux.getState().curCat && this.ngRedux.getState().curCat.extSw
    );
  }

  get showMatchSw(): boolean {
    return !this.extSw && this.stateTag != CatDocStateTagEnum.SEC;
  }

  get curCatDoc(): CatDoc {
    return this.stateTag == CatDocStateTagEnum.PRIME
      ? this.ngRedux.getState().curCatDocStatePrime.curCatDoc
      : this.ngRedux.getState().curCatDocStateSec.curCatDoc;
  }

  get curPage(): number {
    return this.stateTag == CatDocStateTagEnum.PRIME
      ? this.ngRedux.getState().curCatDocStatePrime.curCatDocPageNum
      : this.ngRedux.getState().curCatDocStateSec.curCatDocPageNum;
    //return this.ngRedux.getState().curCatDocPageNum;
  }

  get isShowIssuesOnFullMatchEnabled(): boolean {
    return this.sharedService.isShowIssuesOnFullMatchEnabled();
  }

  get libraryMismatch() {
    let similarityEvent = this.getSimilarityEvent();
    if (!this.isShowIssuesOnFullMatchEnabled && similarityEvent == 'FULL_MATCH') {
      return false;
    }
    if (!similarityEvent || similarityEvent == 'POTENTIAL_NEW') {
      return false;
    }
    return this.getFindingEventCategory('TEXT_MISMATCH');
  }

  get referenceIssues() {
    let similarityEvent = this.getSimilarityEvent();
    if (!this.isShowIssuesOnFullMatchEnabled && similarityEvent == 'FULL_MATCH') {
      return false;
    }
    return this.getFindingEventCategory('REFERENCE_ISSUE');
  }

  get contentPartMismatch() {
    let similarityEvent = this.getSimilarityEvent();
    if (!this.isShowIssuesOnFullMatchEnabled && similarityEvent == 'FULL_MATCH') {
      return false;
    }
    return this.getFindingEventCategory('MANDATORY_ASSOC_CONTENT_ISSUE');
  }

  get spellingGrammarIssues() {
    let similarityEvent = this.getSimilarityEvent();
    if (!this.isShowIssuesOnFullMatchEnabled && similarityEvent == 'FULL_MATCH') {
      return false;
    }
    return this.getFindingEventCategory('SPELLING_GRAMMAR_ISSUE');
  }

  get formatMismatch() {
    let similarityEvent = this.getSimilarityEvent();
    if (!this.isShowIssuesOnFullMatchEnabled && similarityEvent == 'FULL_MATCH') {
      return false;
    }
    return this.getFindingEventCategory('FORMAT_MISMATCH');
  }

  getFindingEventCategory(findingEventCategory: string) {
    let findingEventCategories = [];
    if (this.phrase) {
      findingEventCategories = this.phrase.phraseFindingCategoryDescs;
    }
    let matchFindingEventCategories = findingEventCategories.filter(
      phraseFindingCategoryDesc => findingEventCategory == phraseFindingCategoryDesc[0]
    );
    return matchFindingEventCategories.length > 0 ? matchFindingEventCategories[0] : null;
  }

  getSimilarityEvent() {
    let similarityEvent;
    if (this.phrase) {
      similarityEvent = this.phrase.phraseSimilarityEvent
    } else if (this.indImage) {
      similarityEvent = this.indImage.imageSimilarityEvent;
    }
    return similarityEvent;
  }

  constructor(
    private ngRedux: NgRedux<AppState>,
    private sharedService: SharedService,
    private catService: CatService
  ) {
    this.un = this.ngRedux.subscribe(() => {
      const appState = this.ngRedux.getState();
      switch (appState._currentAction) {
        case actionsList.CAT_PHRASE_ANN_CLICKED:
          if (
            (appState._currentActionData as IndElement).elementId ==
              this.element.elementId &&
            ((this.element instanceof IndPhrase &&
              appState._currentActionData instanceof IndPhrase) ||
              (this.element instanceof IndImage &&
                appState._currentActionData instanceof IndImage))
          ) {
            this.openPhraseDetails(null, false);
          }
          break;
      }
    });
  }

  ngOnDestroy(): void {
    if (this.un) this.un();
  }

  ngOnInit(): void {
    let actions = [];
    if (this.phrase) {
      actions = this.phrase.phraseActions;
    } else if (this.indImage) {
      actions = this.indImage.imageActions;
    }
    // show all actions on old docs
    let isNewRuleDoc = !!this.getSimilarityEvent();
    if (isNewRuleDoc) {
      this.phraseStatusOptions =
      this.sharedService.getCatPhraseUpdStatusOptions().filter(
        s => {
          return actions.indexOf(s.id) >= 0;
        }
      );
    } else {
      this.phraseStatusOptions = this.sharedService.getCatPhraseUpdStatusOptions();
    }
  }

  goToPage() {
    this.onGoToPhrasePage.emit();
  }

  openPhraseDetails(event: any, phraseEditModeSw: boolean = false) {
    if (event) event.stopPropagation();

    this.ngRedux.dispatch({
      type: actionsList.SELECT_CAT_ELEMENT,
      data: this.element,
    });
    this.phraseEditModeSw = phraseEditModeSw;
    this.phraseDetailsOpenSw = true;
  }

  onPhraseStatusChange() {
    if (this.element.statusCode == this.element.userStatus.id) {
      this.element.statusCode = CatPhraseStatusEnum.PENDING;
      this.element.variantOrigPhraseId = null;
      this.element.userStatus = this.ngRedux
        .getState()
        .catPhraseStatusList.find((s) => s.id == this.element.statusCode);
      this.element.statusDescr = this.ngRedux
        .getState()
        .catPhraseStatusList.find(
          (s) => s.id == this.element.userStatus.id
        ).text;
    } else {
      this.element.statusCode = this.element.userStatus.id;
      this.element.statusDescr = this.ngRedux
        .getState()
        .catPhraseStatusList.find(
          (s) => s.id == this.element.userStatus.id
        ).text;
    }

    this.catService
      .updIndPhraseStatus(
        this.curCatDoc,
        [this.element],
        this.element.statusCode,
        false,
        null,
        this.element.variantOrigPhraseId
      )
      .subscribe(
        (data) => {
          this.element.statusCode = data[0].statusCode;
          this.element.statusDescr = data[0].statusDescr;
          this.onTogglePhraseView.emit();
        },
        (err) => {}
      );
  }

  togglePhraseView(event: any) {
    this.element.viewSw = !this.element.viewSw;
    this.onTogglePhraseView.emit();
  }

  editPhrase() {
    this.openPhraseDetails(null, true);
  }

  onPhraseDelete() {
    this.phraseDetailsOpenSw = false;
    this.ngRedux.dispatch({
      type: actionsList.DELETE_CAT_DOC_PHRASE,
    });
  }

  onAddExtRef() {
    this.addExtRefOpenSw = true;
  }

  onAddExtRefClose(added: boolean) {
    this.addExtRefOpenSw = false;
    if (added) {
      this.ngRedux.dispatch({
        type: actionsList.RELOAD_CAT_DOC_PAGE_REQ,
        data: false,
      });
    }
  }

  notifySpellingIssuesUpdate() {
    this.onUpdateSpellingIssues.emit();
  }
}
