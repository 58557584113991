import { Component, Input, OnInit } from '@angular/core';
import { CatMatch } from 'src/app/shared/models/common';

@Component({
  selector: 'cat-tbl-match',
  templateUrl: './cat-tbl-match.component.html',
  styleUrls: ['./cat-tbl-match.component.scss'],
})
export class CatTblMatchComponent implements OnInit {
  @Input() match: CatMatch;

  constructor() {}

  ngOnInit(): void {}
}
