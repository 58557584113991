<section class="page-header">
  <div class="title-container">
    <h1 *ngIf="!selectMode">
      <span *ngIf="!extSw"> {{ "translate.cat.catalogList" | translate }}</span>
      <span *ngIf="extSw"> {{ "translate.cat.extLibList" | translate }}</span>
    </h1>

    <h1 *ngIf="selectMode">
      <a class="icon" (click)="goBack()"
        ><fa-icon [icon]="['fas', 'arrow-left']"></fa-icon
      ></a>
      &nbsp;
      {{ "translate.lc.selectCat" | translate }}
    </h1>

    <button
      *ngIf="!selectMode"
      class="t-button"
      (click)="displayCreateCat = true"
    >
      {{ "translate.cat.createCat" | translate }}
    </button>
  </div>
</section>

<loader *ngIf="loading"></loader>

<!-- <div
  *ngIf="catList && !catList.length && !loading"
  role="alert"
  class="c-alert t-alert-info"
>
  {{ "translate.alert.noResults" | translate }}
</div> -->

<ui-table
  [isWrapped]="false"
  [data]="catList"
  [numberOfRows]="10"
  [totalRecords]="catalogsMetaData.totalRecords"
  (requestUpdate)="onPagination($event)"
  [selectedRow]="selectedCatalog"
  [displayFilter]="[
    'catId',
    'productDescr',
    'typeDescr',
    'langDescr',
    'activeVersionId'
  ]"
  [templates]="[
    {
      title: 'translate.general.catDescr',
      template: catDescr,
      afterColumn: 0,
      permission: true
    },
    {
      title: 'translate.general.actions',
      template: headerActions
    }
  ]"
  [more]="catVersions"
  [topEnd]="filterArea"
></ui-table>

<ng-template #catDescr let-row="row">
  <div *ngIf="!catsBeingEdited[row.catId]" class="l-align-spaceBetween">
    <span style="margin-right: 1rem;">{{ row.catDescr }}</span>
    <a class="icon" (click)="onClickEditCatTitle($event, row)">
      <i class="fa-solid fa-pen-to-square" [tooltip]="'translate.general.changeCatTitle' | translate"></i>
    </a>
  </div>

  <div *ngIf="catsBeingEdited[row.catId]" class="l-align-spaceBetween">
    <input 
      type="text"
      [(ngModel)]="catsBeingEdited[row.catId].catDescr" 
      class="t-input" style="margin-right: 1rem;" 
      (click)="$event.stopPropagation()" 
      [disabled]="catsBeingUpdated[row.catId]" />
    <a *ngIf="!catsBeingUpdated[row.catId]" class="icon" style="margin-right: 1rem;" (click)="onClickUpdateCatTitle($event, row.catId)">
      <i class="fa-solid fa-check" [tooltip]="'translate.general.saveChanges' | translate"></i>
    </a>

    <a *ngIf="!catsBeingUpdated[row.catId]" class="icon" (click)="onClickCancelEditCatTitle($event, row.catId)">
      <i class="fa-solid fa-xmark" [tooltip]="'translate.general.cancel' | translate"></i>
    </a>

    <a *ngIf="catsBeingUpdated[row.catId]" class="icon">
      <i class="fa-solid fa-spinner"></i>
    </a>
  </div>
</ng-template>

<ng-template #headerActions let-row="row">
  <a (click)="$event.stopPropagation(); openDraft(row)">
    <span *ngIf="!selectMode">{{ "translate.cat.editDraft" | translate }}</span>
    <span *ngIf="selectMode">{{
      "translate.cat.selectDraft" | translate
    }}</span>
  </a>
  <span *ngIf="row.hasPublished" class="action-divider">|</span>
  <a
    *ngIf="row.hasPublished"
    (click)="$event.stopPropagation(); openPublished(row)"
  >
    <span *ngIf="!selectMode">{{
      "translate.cat.viewPublished" | translate
    }}</span>
    <span *ngIf="selectMode">{{
      "translate.cat.selectPublished" | translate
    }}</span>
  </a>
</ng-template>

<ng-template #catVersions let-row="row">
  <ui-table
    *ngIf="row.catVersions && row.catVersions.length"
    class="t-small"
    [rowHover]="false"
    [isWrapped]="false"
    [data]="row.catVersions"
    [displayFilter]="['versionId', 'versionName']"
    [templates]="[
      {
        title: 'translate.general.statusDescr',
        template: statusDescr
      },
      {
        title: 'translate.general.statusDate',
        template: statusDate
      },
      {
        title: 'translate.general.totDocCnt',
        template: catalog
      },
      {
        title: 'translate.general.actions',
        template: versionActions
      }
    ]"
  ></ui-table>

  <ng-template #statusDescr let-row="row">
    <p
      [class.fw-bold]="
        row.statusCode == 'DRAFT' || row.statusCode == 'PUBLISHED'
      "
      [class.c-success]="row.statusCode == 'PUBLISHED'"
    >
      {{ row.statusDescr }}
    </p>
  </ng-template>

  <ng-template #statusDate let-row="row">
    {{ row.statusDate }}
  </ng-template>

  <ng-template #totDocCnt let-row="row">
    {{ row.totDocCnt }}
  </ng-template>

  <ng-template #versionActions let-row="row">
    <a *ngIf="row.statusCode == 'DRAFT'" (click)="viewVersion(row)">{{
      "translate.cat.editDraft" | translate
    }}</a>

    <a
      *ngIf="row.statusCode != 'DRAFT' && row.statusCode != 'PUBLISHED'"
      (click)="viewVersion(row)"
    >
      <span *ngIf="!selectMode">{{ "translate.cat.view" | translate }}</span>
      <span *ngIf="selectMode">{{
        "translate.general.select" | translate
      }}</span>
    </a>

    <div *ngIf="row.statusCode == 'PUBLISHED'">
      <a (click)="viewVersion(row)">
        <span *ngIf="!selectMode">{{ "translate.cat.view" | translate }}</span>
        <span *ngIf="selectMode">{{
          "translate.general.select" | translate
        }}</span></a
      >
    </div>
  </ng-template>
</ng-template>

<ng-template #filterArea>
  <div class="filter-container">
    <!--catIdName-->
    <input-wrap class="t-input-search-wrap">
      <input
        class="t-input"
        type="text"
        [(ngModel)]="filterCatIdName"
        placeholder="{{ 'translate.general.filterCatIdName' | translate }}"
        (keypress)="onFilterKeyPress($event)"
      />
    </input-wrap>

    <!--product-->
    <input-wrap
      [loading]="productList && productList.loading"
      cssClass="l-cell-spread"
    >
      <p-multiSelect
        [options]="productList"
        [(ngModel)]="filterProducts"
        optionLabel="productDescr"
        [filter]="true"
        [resetFilterOnHide]="true"
        [autofocusFilter]="true"
        filterBy="label"
        [showToggleAll]="true"
        [maxSelectedLabels]="1"
        [placeholder]="'translate.general.productDescr' | translate"
        [selectedItemsLabel]="
          'translate.general.multiselectItemsSelected' | translate
        "
        class="l-cell-spread-x"
        (onChange)="onFieldValueChanged($event)"
      ></p-multiSelect>
    </input-wrap>

    <!--type-->
    <input-wrap *ngIf="!extSw" [loading]="catTypeList && catTypeList.loading">
      <p-dropdown
        [options]="catTypeList"
        optionLabel="label"
        [(ngModel)]="filterType"
        [resetFilterOnHide]="true"
        [filterPlaceholder]="'translate.general.search' | translate"
        [placeholder]="'translate.general.typeDescr' | translate"
        [filter]="true"
        (onChange)="onFieldValueChanged($event)"
      >
      </p-dropdown>
    </input-wrap>

    <a *ngIf="displayClearFilter" (click)="clearFilter()">{{
      "translate.general.clear" | translate
    }}</a>
  </div>
</ng-template>

<create-cat
  *ngIf="displayCreateCat"
  [extSw]="extSw"
  (onClose)="displayCreateCat = false"
  (onCreate)="onCatalogCreated()"
></create-cat>
